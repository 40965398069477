import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from "@mui/styles";
import { AccentInfo, Detail, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const Finalized = ({milestone, origin}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="milestone" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            initiated by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        {milestone.details && milestone.details.length > 0 &&
          <>
            <Typography style={{margin: '1rem 0 0.3rem 0'}}>
              Financial information provided:
            </Typography>
            <FlexWrapper className="column fitContentHeight" style={{marginBottom: '0.5rem'}}>
              {milestone.details.map((detail, index) => (
                <FlexWrapper key={detail.field} className='fitContentHeight fitContentWidth'>
                  <Typography className="fitContentHeight justifyEnd" style={{width: '16rem', margin: '0 0.5rem 0 3rem'}}>
                    {detail.label}:
                  </Typography>
                  <Typography className="fitContentHeight bold">
                    {detail.field.includes('Rate') || detail.field.includes('%') ?
                      !detail.old ?
                        <>
                          <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <span style={{fontWeight: '600'}}>{detail.new}%</span>
                        </>
                      : !detail.new ?
                        <>
                          <span style={{fontWeight: '600'}}>{detail.old}%</span>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                        </>
                      :
                        <>
                          <span style={{fontWeight: '600'}}>{detail.old}%</span>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <span style={{fontWeight: '600'}}>{detail.new}%</span>
                        </>
                    : detail.field.includes('Parcel') || detail.field.includes('Term') || detail.field.includes('Owner') || detail.field.includes('Date') || detail.field.includes('Unit') || detail.field.includes('Street') || detail.field.includes('City') || detail.field.includes('Postal') || detail.field.includes('State') ?
                      !detail.old ?
                        <>
                          <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <span style={{fontWeight: '600'}}>{detail.new}</span>
                        </>
                      : !detail.new ?
                        <>
                          <span style={{fontWeight: '600'}}>{detail.old}</span>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                        </>
                      :
                        <>
                          <span style={{fontWeight: '600'}}>{detail.old}</span>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <span style={{fontWeight: '600'}}>{detail.new}</span>
                        </>
                    :
                      !detail.old ?
                        <>
                          <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <span style={{fontWeight: '600'}}>${detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                        </>
                      : !detail.new ?
                        <>
                          <span style={{fontWeight: '600'}}>${detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                        </>
                      :
                        <>
                          <span style={{fontWeight: '600'}}>${detail.old.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                          <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                          <span style={{fontWeight: '600'}}>${detail.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                        </>
                      }
                  </Typography>
                </FlexWrapper>
              ))}
            </FlexWrapper>
          </>
        }
        {milestone.ownershipUpdates && milestone.ownershipUpdates.length > 0 &&
          <>
            <Typography style={{margin: '1rem 0 0.3rem 0'}}>
              Updates to Ownership:
            </Typography>
            <FlexWrapper className="column fitContentHeight" style={{marginBottom: '1.5rem'}}>
              {milestone.ownershipUpdates.map((update, index) => (
                <FlexWrapper key={update.field} className='fitContentHeight fitContentWidth'>
                  <Typography className="fitContentHeight justifyEnd" style={{width: '9rem', margin: '0 0.5rem 0 2rem'}}>
                    {update.label}:
                  </Typography>
                  <Typography className="fitContentHeight bold">
                    {!update.old ?
                      <>
                        <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                        <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                        <span style={{fontWeight: '600'}}>{update.new}</span>
                      </>
                    : !update.new ?
                      <>
                        <span style={{fontWeight: '600'}}>{update.old}</span>
                        <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                        <AccentInfo style={{fontWeight: '400'}}>-empty-</AccentInfo>
                      </>
                    :
                      <>
                        <span style={{fontWeight: '600'}}>{update.old}</span>
                        <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                        <span style={{fontWeight: '600'}}>{update.new}</span>
                      </>
                    }
                  </Typography>
                </FlexWrapper>
              ))}
            </FlexWrapper>
          </>
        }
        {milestone.newProfits &&
          <>
            <FlexWrapper className='fitContentHeight alignCenter'>
              <Typography className="fitContentHeight justifyEnd" style={{width: '9rem', marginRight: '0.5rem'}}>
                finalized profit:
              </Typography>
              <Typography className="fitContentHeight bold">
                {Math.sign(milestone.newProfits.profitNumber.new) !== -1 && !milestone.newProfits.profitPercent.negative ?
                  <Detail className="timelineItem green">${milestone.newProfits.profitNumber.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail> 
                : milestone.newProfits.profitPercent.negative ?
                  <Detail className="timelineItem red">-${(milestone.newProfits.profitNumber.new).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>     
                :
                  <Detail className="timelineItem red">-${(milestone.newProfits.profitNumber.new*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>     
                }
              </Typography>
            </FlexWrapper>
            <FlexWrapper className='fitContentHeight alignCenter'>
              <Typography className="fitContentHeight justifyEnd" style={{width: '9rem', marginRight: '0.5rem'}}>
                profit difference:
              </Typography>
              <Typography className="fitContentHeight bold">
                {!milestone.newProfits.profitPercent.negative ?
                  <Detail className="timelineItem green">{milestone.newProfits.profitPercent.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail> 
                :
                  <Detail className="timelineItem red">{(milestone.newProfits.profitPercent.new*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>     
                }
              </Typography>
            </FlexWrapper>
          </>
        }
      </FlexWrapper>
      {milestone.awaitingUpdate &&
        <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '0.25rem 0 1rem 0'}}>
          <FlexWrapper 
            style={{border: `0.2rem solid ${theme.palette.common.grayDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.grayText}`}}
          >
            <InfoIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span 
            style={{
              border: `0.1rem solid ${theme.palette.common.grayDark}`,
              padding: origin === 'report' ? '1rem' : '0.3rem 1rem',
              borderRadius: '0 1rem 1rem 0',
              background: `${theme.palette.common.grayBorder}`,
              borderLeft: 'none', color: `${theme.palette.common.grayDark}`,
              fontSize: origin === 'report' ? '1.2rem' : '1rem',
              width: origin === 'report' ? 'fit-content' : '27rem',
            }}
          >
            Currently awaiting the expected changes to be reflected in public records and verified.
          </span>
        </FlexWrapper>
      }
    </FlexWrapper>
  )
}

export default Finalized