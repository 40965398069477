import React, { useEffect, useState } from "react";
import Card from "./components/Card/Card";
import Controls from "./components/Controls";
import { Dialog } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import LeadActionsModal from "../../../../components/Modals/LeadActions/LeadActionsModal";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { selectUserFullName } from "../../../../slices/userInfoSlice";
import { setViewingMortgage } from "../../../../slices/viewingMortgageSlice";
import { setViewingLeadReports } from "../../../../slices/teamReportsSlice";
import handleLeadNewFilterChanges from "./functions/filterNew";
import handleLeadTagFilterChanges from "./functions/filterTag";
import handleLeadTierFilterChanges from "./functions/filterTier";
import handleLeadTypeFilterChanges from "./functions/filterType";
import handleLeadSortMethodChanges from "./functions/sortMethod";
import { useDispatch, useSelector } from "react-redux";
import handleLeadSortCategoryChanges from "./functions/sortCategory";
import { selectTeamLeadTagIds, selectTeamLeadTags } from "../../../../slices/teamAndUserSettingsSlice";
import { selectViewingLead, setViewingLead, setViewingLeadTimeline } from "../../../../slices/viewingLeadSlice";
import { CardControlsContainer, CardListContainer, CardListDetail, CardListHeader, FlexWrapper, HeaderText, PageContent, ScrollWindow } from "../../../../StyledComponents";
import { selectFilteredMemberAwaitingActionLeads, selectFilteredMemberAwaitingUpdateLeads, selectFilteredMemberClosingLeads, selectFilteredMemberInvestigatingLeads, selectMemberAwaitingActionLeads, selectMemberAwaitingUpdateLeads, selectMemberClosingLeads, selectMemberInvestigatingLeads, setFilteredMemberAwaitingActionLeads, setFilteredMemberClosingLeads, setFilteredMemberInvestigatingLeads, setSortedMemberAwaitingActionLeads, setSortedMemberClosingLeads, setSortedMemberInvestigatingLeads } from "../../../../slices/memberAssignmentsSlice";
import { selectTeamClosingLeads, selectFilteredTeamClosingLeads, selectFilteredTeamInvestigatingLeads, selectTeamInvestigatingLeads, setFilteredTeamClosingLeads, setFilteredTeamInvestigatingLeads, setSortedTeamClosingLeads, setSortedTeamInvestigatingLeads, setSortedTeamAwaitingActionLeads, setFilteredTeamAwaitingActionLeads, selectTeamAwaitingActionLeads, selectFilteredTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectFilteredTeamAwaitingUpdateLeads, removeNotificationFromLead, setFwdBckViewingLeads, setFwdBckFilteredViewingLeads } from "../../../../slices/teamActiveLeadsSlice";
import { selectDashPerspective, selectDashUserFullName, selectLeadsFilteredBoolean, selectLeadStatusFilter, selectShowLeadActionsModal, selectViewingLeadActions, setLeadActionNavMenuSelection, setLeadsFilteredBoolean, setLeadStatusFilter, setShowLeadActionsModal, setViewingLeadActions } from "../../../../slices/sessionDataSlice";
import { removeLeadNotification, removeNewLeadNotification, selectNewAwaitingActionCount, selectNewAwaitingUpdatesCount, selectNewClosingCount, selectNewInvestigatingCount } from "../../../../slices/userNotificationsSlice";
import { removeNotificationFromMortgage } from "../../../../slices/teamMortgagesSlice";

const ActiveLeads = ({ teamId, userId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const viewingLead = useSelector(selectViewingLead)
  const teamLeadTags = useSelector(selectTeamLeadTags)
  const userFullName = useSelector(selectUserFullName)
  const memberFullName = useSelector(selectDashUserFullName)
  const teamLeadTagIds = useSelector(selectTeamLeadTagIds)
  const dashPerspective = useSelector(selectDashPerspective)
  const leadStatusFilter = useSelector(selectLeadStatusFilter)
  const viewingLeadActions = useSelector(selectViewingLeadActions)
  const showLeadActionsModal = useSelector(selectShowLeadActionsModal)
  
  const teamAwaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const filteredTeamAwaitingActionLeads = useSelector(selectFilteredTeamAwaitingActionLeads)
  const teamInvestigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const filteredTeamInvestigatingLeads = useSelector(selectFilteredTeamInvestigatingLeads)
  const teamClosingLeads = useSelector(selectTeamClosingLeads)
  const filteredTeamClosingLeads = useSelector(selectFilteredTeamClosingLeads)
  const teamAwaitingUpdateLeads = useSelector(selectTeamAwaitingUpdateLeads)
  const filteredTeamAwaitingUpdateLeads = useSelector(selectFilteredTeamAwaitingUpdateLeads)
  const memberAwaitingActionLeads = useSelector(selectMemberAwaitingActionLeads)
  const filteredMemberAwaitingActionLeads = useSelector(selectFilteredMemberAwaitingActionLeads)
  const memberInvestigatingLeads = useSelector(selectMemberInvestigatingLeads)
  const filteredMemberInvestigatingLeads = useSelector(selectFilteredMemberInvestigatingLeads)
  const memberClosingLeads = useSelector(selectMemberClosingLeads)
  const filteredMemberClosingLeads = useSelector(selectFilteredMemberClosingLeads)
  const memberAwaitingUpdateLeads = useSelector(selectMemberAwaitingUpdateLeads)
  const filteredMemberAwaitingUpdateLeads = useSelector(selectFilteredMemberAwaitingUpdateLeads)
  const newAwaitingActionCount = useSelector(selectNewAwaitingActionCount)
  const newInvestigatingCount = useSelector(selectNewInvestigatingCount)
  const newClosingCount = useSelector(selectNewClosingCount)
  const newAwaitingUpdatesCount = useSelector(selectNewAwaitingUpdatesCount)
  const leadsFilteredBoolean = useSelector(selectLeadsFilteredBoolean)

  const [sortLoaded, setSortLoaded] = useState(true)
  const [leadNewFilter, setLeadNewFilter] = useState('false')
  const [leadTagFilter, setLeadTagFilter] = useState('All')
  const [leadTypeFilter, setLeadTypeFilter] = useState('All')
  const [leadTierFilter, setLeadTierFilter] = useState('All')
  const [leadSortMethod, setLeadSortMethod] = useState('High-Low')
  const [leadCategorySort, setLeadCategorySort] = useState('Date Discovered')
  
  useEffect(() => {
    setLeadTagFilter('All')
    setLeadTypeFilter('All')
    setLeadTierFilter('All')
    setLeadCategorySort('Date Discovered')
    setLeadSortMethod('High-Low')
  }, [dashPerspective])

  const handleLeadSortCategoryChange = async (event) => {
    setLeadCategorySort(event.target.value);
    setSortLoaded(false)
    let sortedLeads = {}
    let sortedFilteredLeads = {}
    if (leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false') {
      if (dashPerspective === 'team') {
        sortedLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads)
        dispatch( setSortedTeamAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedTeamInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedTeamClosingLeads(sortedLeads.closing) )
      } else {
        sortedLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads)
        dispatch( setSortedMemberAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedMemberInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedMemberClosingLeads(sortedLeads.closing) )
      }
      setSortLoaded(true)
    } else {
      if (dashPerspective === 'team') {
        sortedFilteredLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads)
        dispatch( setFilteredTeamAwaitingActionLeads(sortedFilteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(sortedFilteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(sortedFilteredLeads.closing) )
        setSortLoaded(true)
        sortedLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads)
        dispatch( setSortedTeamAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedTeamInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedTeamClosingLeads(sortedLeads.closing) )        
      } else {
        sortedFilteredLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads)
        dispatch( setFilteredMemberAwaitingActionLeads(sortedFilteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(sortedFilteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(sortedFilteredLeads.closing) )
        setSortLoaded(true)
        sortedLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads)
        dispatch( setSortedMemberAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedMemberInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedMemberClosingLeads(sortedLeads.closing) )
      }
    }
  }
  const handleLeadSortMethodChange = async (event) => {
    setLeadSortMethod(event.target.value);
    setSortLoaded(false)
    let sortedLeads = {}
    let sortedFilteredLeads = {}
    if (leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false') {
      if (dashPerspective === 'team') {
        sortedLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads)
        dispatch( setSortedTeamAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedTeamInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedTeamClosingLeads(sortedLeads.closing) )
        setSortLoaded(true)
      } else {
        sortedLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads)
        dispatch( setSortedMemberAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedMemberInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedMemberClosingLeads(sortedLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      if (dashPerspective === 'team') {
        sortedFilteredLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads)
        dispatch( setFilteredTeamAwaitingActionLeads(sortedFilteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(sortedFilteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(sortedFilteredLeads.closing) )
        setSortLoaded(true)
        sortedLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads)
        dispatch( setSortedTeamAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedTeamInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedTeamClosingLeads(sortedLeads.closing) )
      } else {
        sortedFilteredLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads)
        dispatch( setFilteredMemberAwaitingActionLeads(sortedFilteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(sortedFilteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(sortedFilteredLeads.closing) )
        setSortLoaded(true)
        sortedLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads)
        dispatch( setSortedMemberAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedMemberInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedMemberClosingLeads(sortedLeads.closing) )
      }
    }
  }
  const handleLeadTierFilterChange = async (event) => {
    setSortLoaded(false)
    if (leadTypeFilter === 'All' && leadNewFilter === 'true' && leadTierFilter === 'All') {
      dispatch( setLeadsFilteredBoolean(true) )
      if (dashPerspective === 'team') {
        setLeadTierFilter(event.target.value);
        let filteredLeads = await handleLeadTierFilterChanges(event.target.value, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads);
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTierFilter(event.target.value);
        let filteredLeads = await handleLeadTierFilterChanges(event.target.value, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      dispatch( setLeadsFilteredBoolean(false) )
      if (dashPerspective === 'team') {
        setLeadTierFilter(event.target.value);
        let filteredLeads = await handleLeadTierFilterChanges(event.target.value, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads, leadTypeFilter, leadNewFilter);
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTierFilter(event.target.value);
        let filteredLeads = await handleLeadTierFilterChanges(event.target.value, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads, leadTypeFilter, leadNewFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    }
  }
  const handleLeadTagFilterChange = async (event) => {
    setSortLoaded(false)
    if (leadTypeFilter === 'All' && leadNewFilter === 'true' && leadTierFilter === 'All' && leadTagFilter === 'All') {
        dispatch( setLeadsFilteredBoolean(true) )
      if (dashPerspective === 'team') {
        setLeadTagFilter(event.target.value);
        let filteredLeads = await handleLeadTagFilterChanges(teamLeadTagIds, event.target.value, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads, leadTypeFilter);
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTagFilter(event.target.value);
        let filteredLeads = await handleLeadTagFilterChanges(teamLeadTagIds, event.target.value, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads, leadTypeFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    } else {
        dispatch( setLeadsFilteredBoolean(false) )
      if (dashPerspective === 'team') {
        setLeadTagFilter(event.target.value);
        let filteredLeads = await handleLeadTagFilterChanges(teamLeadTagIds, event.target.value, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads, leadTagFilter, leadNewFilter, leadTypeFilter);
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTagFilter(event.target.value);
        let filteredLeads = await handleLeadTagFilterChanges(teamLeadTagIds, event.target.value, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads, leadTagFilter, leadNewFilter, leadTypeFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    }
  }
  const handleLeadStatusFilterChange = (event) => {
    dispatch( setLeadStatusFilter(event.target.value) )
    if (event.target.value === 'Awaiting Action') {
      dispatch( removeNewLeadNotification({status: 'awaitingAction', count: newAwaitingActionCount}) )
    } else if (event.target.value === 'Investigating') {
      dispatch( removeNewLeadNotification({status: 'investigating', count: newInvestigatingCount}) )
    } else if (event.target.value === 'Closing') {
      dispatch( removeNewLeadNotification({status: 'closing', count: newClosingCount}) )
    } else if (event.target.value === 'Awaiting PR Updates') {
      dispatch( removeNewLeadNotification({status: 'awaitingUpdates', count: newAwaitingUpdatesCount}) )
    }
  }
  const handleLeadNewFilterChange = async (event) => {
    setSortLoaded(false)
    if (leadTypeFilter === 'All' && leadTierFilter === 'All' && leadNewFilter === 'false') {
      dispatch( setLeadsFilteredBoolean(true) )
      if (dashPerspective === 'team') {
        setLeadNewFilter(event.target.value);
        let filteredLeads = await handleLeadNewFilterChanges(event.target.value, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads)
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadNewFilter(event.target.value);
        let filteredLeads = await handleLeadNewFilterChanges(event.target.value, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads)
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      dispatch( setLeadsFilteredBoolean(false) )
      if (dashPerspective === 'team') {
        setLeadNewFilter(event.target.value);
        let filteredLeads = await handleLeadNewFilterChanges(event.target.value, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads, leadTierFilter, leadTypeFilter)
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadNewFilter(event.target.value);
        let filteredLeads = await handleLeadNewFilterChanges(event.target.value, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads, leadTierFilter, leadTypeFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    }
  }
  const handleLeadTypeFilterChange = async (event) => {
    setSortLoaded(false)
    if (leadTierFilter === 'All' && leadNewFilter !== 'false' && leadTypeFilter === 'All') {
      dispatch( setLeadsFilteredBoolean(true) )
      if (dashPerspective === 'team') {
        setLeadTypeFilter(event.target.value);
        let filteredLeads = await handleLeadTypeFilterChanges(event.target.value, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads)
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTypeFilter(event.target.value);
        let filteredLeads = await handleLeadTypeFilterChanges(event.target.value, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      dispatch( setLeadsFilteredBoolean(false) )
      if (dashPerspective === 'team') {
        setLeadTypeFilter(event.target.value);
        let filteredLeads = await handleLeadTypeFilterChanges(event.target.value, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads, leadTierFilter, leadNewFilter)
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTypeFilter(event.target.value);
        let filteredLeads = await handleLeadTypeFilterChanges(event.target.value, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads, leadTierFilter, leadNewFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    }
  }

  const handleOpenLeadActionsModal = (destination, lead, leadMortgage) => {
    if (lead.notifCount && lead.notifCount !== 0) {
      if (lead.status === 'awaitingAction') {
        dispatch( removeLeadNotification({status: 'awaitingAction', count: 1}) )
        dispatch( removeNotificationFromLead({status: 'awaitingAction', leadId: lead._id}) )
      } else if (lead.status === 'investigating') {
        dispatch( removeLeadNotification({status: 'investigating', count: 1}) )
        dispatch( removeNotificationFromLead({status: 'investigating', leadId: lead._id}) )
      } else if (lead.status === 'closing') {
        dispatch( removeLeadNotification({status: 'closing', count: 1}) )
        dispatch( removeNotificationFromLead({status: 'closing', leadId: lead._id}) )
      } else {
        dispatch( removeLeadNotification({status: 'awaitingUpdates', count: 1}) )
        dispatch( removeNotificationFromLead({status: 'awaitingUpdates', leadId: lead._id}) )
      }
      dispatch( removeNotificationFromMortgage({mortgageId: leadMortgage._id}) )
    }
    dispatch( setViewingLeadReports(lead.reports) )
    dispatch( setViewingLead(lead) )
    if (leadsFilteredBoolean || dashPerspective !== 'team') {
      dispatch( setFwdBckFilteredViewingLeads(lead._id) )
    } else {
      dispatch( setFwdBckViewingLeads(lead._id) )
    }
    dispatch( setViewingMortgage(leadMortgage) )
    dispatch( setLeadActionNavMenuSelection(destination) )
    dispatch( setShowLeadActionsModal(true) )
    navigate(`/dashboard/investigations/${lead._id}`)
  }
  const handleCloseModal = () => {
    dispatch( setShowLeadActionsModal(false) )
    dispatch( setViewingLeadActions(false) )
    navigate(`/dashboard/investigations/`)
    dispatch( setViewingLead(null) )
    dispatch( setFwdBckViewingLeads(null) )
    dispatch( setViewingLeadTimeline(null) )
    dispatch( setFwdBckFilteredViewingLeads(null) )
  }

  useEffect(() => {
    if (viewingLeadActions) {
      dispatch( setShowLeadActionsModal(true) )
      navigate(`/dashboard/investigations/${viewingLead._id}`)
    }
  }, [viewingLead, viewingLeadActions, navigate, dispatch])

  return (
    <>
      <PageContent className="grid activeLeads">
        {leadStatusFilter === "Awaiting Action" ?
          dashPerspective === 'team' ?
            <>
              {teamAwaitingActionLeads !== undefined && sortLoaded && leadTierFilter === 'All' && leadNewFilter === 'false' ?
                teamAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team Investigations Awaiting Action
                      <CardListDetail>Total: {teamAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {teamAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              : filteredTeamAwaitingActionLeads !== undefined && sortLoaded ?
                filteredTeamAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team Investigations Awaiting Action
                      <CardListDetail>Total: {filteredTeamAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredTeamAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>  
              }
            </>        
          : dashPerspective === userId ?
            <>
              {memberAwaitingActionLeads !== undefined && sortLoaded && leadTierFilter === 'All' && leadNewFilter === 'false' ?
                memberAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Investigations Awaiting Action
                      <CardListDetail>Total: {memberAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {memberAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              : filteredMemberAwaitingActionLeads !== undefined && sortLoaded ?
                filteredMemberAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Investigations Awaiting Action
                      <CardListDetail>Total: {filteredMemberAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredMemberAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          :
            <>
              {memberAwaitingActionLeads !== undefined && sortLoaded && leadTierFilter === 'All' && leadNewFilter === 'false' ?
                memberAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Investigations Awaiting Action
                      <CardListDetail>Total: {memberAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {memberAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              : sortLoaded && filteredMemberAwaitingActionLeads !== undefined ?
                filteredMemberAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Investigations Awaiting Action
                      <CardListDetail>Filtered Total: {filteredMemberAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredMemberAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
        : leadStatusFilter === 'Investigating' ?
          dashPerspective === 'team' ?
            <>
              {teamInvestigatingLeads !== undefined && teamClosingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' && sortLoaded ?
                teamInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team's Leads Under Investigation
                      <CardListDetail>Total: {teamInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {teamInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              : filteredTeamInvestigatingLeads !== undefined && filteredTeamClosingLeads !== undefined && sortLoaded ?
                filteredTeamInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team's Leads Under Investigation
                      <CardListDetail>Filtered Total: {filteredTeamInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredTeamInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          : dashPerspective === userId ?
            <>
              {sortLoaded && memberInvestigatingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
                memberInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Leads Under Investigation
                      <CardListDetail>Total: {memberInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {memberInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              : sortLoaded && filteredMemberInvestigatingLeads !== undefined ?
                filteredMemberInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Leads Under Investigation
                      <CardListDetail>Filtered Total: {filteredMemberInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredMemberInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          :
            <>
              {sortLoaded && memberInvestigatingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
                memberInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Leads Under Investigation
                      <CardListDetail>Total: {memberInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {memberInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              : sortLoaded && filteredMemberInvestigatingLeads !== undefined ?
                filteredMemberInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Leads Under Investigation
                      <CardListDetail>Filtered Total: {filteredMemberInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredMemberInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
        : leadStatusFilter === 'Awaiting PR Updates' ?
          dashPerspective === 'team' ?
            <>
              {teamAwaitingUpdateLeads !== undefined && teamClosingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' && sortLoaded ?
                teamAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team's Leads Awaiting Public Records Update
                      <CardListDetail>Total: {teamAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {teamAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter} handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </ScrollWindow>
                  </>
              : filteredTeamAwaitingUpdateLeads !== undefined && filteredTeamInvestigatingLeads !== undefined && filteredTeamClosingLeads !== undefined && sortLoaded ?
                filteredTeamAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team's Leads Awaiting Public Records Update
                      <CardListDetail>Filtered Total: {filteredTeamAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredTeamAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          : dashPerspective === userId ?
            <>
              {sortLoaded && memberAwaitingUpdateLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
                memberAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Leads Awaiting Public Records Update
                      <CardListDetail>Total: {memberAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {memberAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </ScrollWindow>
                  </>
              : sortLoaded && filteredMemberAwaitingUpdateLeads !== undefined ?
                filteredMemberAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Leads Awaiting Public Records Update
                      <CardListDetail>Filtered Total: {filteredMemberAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredMemberAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          :
            <>
              {sortLoaded && memberAwaitingUpdateLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
                memberAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Leads Awaiting Public Records Update
                      <CardListDetail>Total: {memberAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {memberAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </ScrollWindow>
                  </>
              : sortLoaded && filteredMemberAwaitingUpdateLeads !== undefined ?
                filteredMemberAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Leads Awaiting Public Records Update
                      <CardListDetail>Filtered Total: {filteredMemberAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                      {filteredMemberAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </ScrollWindow>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
        : dashPerspective === 'team' ?
          teamInvestigatingLeads !== undefined && teamClosingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' && sortLoaded ?
            teamClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  Team's Closing Leads
                  <CardListDetail>Total: {teamClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                  {teamClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </ScrollWindow>
              </>
          : filteredTeamInvestigatingLeads !== undefined && filteredTeamClosingLeads !== undefined && sortLoaded ?
            filteredTeamClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  Team's Closing Leads
                  <CardListDetail>Filtered Total: {filteredTeamClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                  {filteredTeamClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </ScrollWindow>
              </>
          :
            <CardListContainer className="loading">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </CardListContainer>
        : dashPerspective === userId ?
          sortLoaded && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
            memberClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  My Closing Leads
                  <CardListDetail>Total: {memberClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                  {memberClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </ScrollWindow>
              </>
          : sortLoaded ?
            filteredMemberClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  My Closing Leads
                  <CardListDetail>Filtered Total: {filteredMemberClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                  {filteredMemberClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </ScrollWindow>
              </>
          :
            <CardListContainer className="loading">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </CardListContainer>
        :
          sortLoaded && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
            memberClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  {memberFullName}'s Closing Leads
                  <CardListDetail>Total: {memberClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                  {memberClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </ScrollWindow>
              </>
          : sortLoaded ?
            filteredMemberClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', margin: 'auto'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leadsre currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  {memberFullName}'s Closing Leads
                  <CardListDetail>Filtered Total: {filteredMemberClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <ScrollWindow style={{gridRow: '2', gridColumn: '1', padding: '1rem 1rem', maxHeight: '100%'}}>
                  {filteredMemberClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </ScrollWindow>
              </>
          :
            <CardListContainer className="loading">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </CardListContainer>
        }
        <CardControlsContainer>
          <Controls leadStatusFilter={leadStatusFilter} handleLeadStatusFilterChange={handleLeadStatusFilterChange} leadCategorySort={leadCategorySort} handleLeadSortCategoryChange={handleLeadSortCategoryChange} leadSortMethod={leadSortMethod} handleLeadSortMethodChange={handleLeadSortMethodChange} leadTypeFilter={leadTypeFilter} leadTierFilter={leadTierFilter} handleLeadTierFilterChange={handleLeadTierFilterChange} handleLeadTypeFilterChange={handleLeadTypeFilterChange} leadNewFilter={leadNewFilter} handleLeadNewFilterChange={handleLeadNewFilterChange} leadTagFilter={leadTagFilter} handleLeadTagFilterChange={handleLeadTagFilterChange} teamLeadTags={teamLeadTags} memberAwaitingActionAmount={memberAwaitingActionLeads.length} memberInvestigatingAmount={memberInvestigatingLeads.length} memberClosingAmount={memberClosingLeads.length} memberAwaitingUpdateAmount={memberAwaitingUpdateLeads.length} teamAwaitingActionAmount={teamAwaitingActionLeads.length} teamInvestigatingAmount={teamInvestigatingLeads.length} teamClosingAmount={teamClosingLeads.length} teamAwaitingUpdateAmount={teamAwaitingUpdateLeads.length} dashPerspective={dashPerspective} />
        </CardControlsContainer>
      </PageContent>

      <Dialog open={showLeadActionsModal} onClose={handleCloseModal}
        PaperProps={{ sx: {width: "95%", minWidth: "140rem", maxWidth: "160rem", height: "fit-content", minHeight: "66rem", maxHeight: "95%"}}}
        tabIndex={-1}
      >
        <LeadActionsModal handleCloseModal={handleCloseModal} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} teamId={teamId} />
      </Dialog>
    </>
  )
}

export default ActiveLeads;