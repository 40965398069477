import React from "react";
import { useTheme } from "@mui/styles";
import { AccentInfo, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const DiscrepancyResolvedOrRejected = ({milestone}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="new" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      {(milestone.activeDiscrepancies && milestone.activeDiscrepancies > 0 ) ?
        <Typography style={{fontWeight: milestone.activeDiscrepancies === 0 ? '700' : ''}}>
          <span style={{fontWeight: '700', marginRight: '0.3rem'}}>
            {milestone.activeDiscrepancies}
          </span>
          {milestone.activeDiscrepancies === 1 ? `Discrepancy remaining` : `Discrepancies remaining`}
        </Typography>
      :<></>}
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            updated by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            new value:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.details.new === 'Y' ?
              'YES'
            : milestone.details.new === 'N' ?
              'NO'
            : !milestone.details.new ?
              <AccentInfo>none</AccentInfo>
            : milestone.details.field.includes('Rate') 
              || milestone.details.field.includes('%') ?
              `%${milestone.details.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}`
            : milestone.details.field.includes('Value') 
              || milestone.details.field.includes('Price') 
              || milestone.details.field.includes('Amount') ?
              `${milestone.details.new.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}`
            :
              milestone.details.new
            }
          </Typography>
        </FlexWrapper>
        {!milestone.milestone.includes('Resolved') ?
          <FlexWrapper className='fitContentHeight'>
            <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
              public record:
            </Typography>
            <Typography className="fitContentHeight bold">
              {milestone.details.publicRecordValue === 'Y' ?
                'YES'
              : milestone.details.publicRecordValue === 'N' ?
                'NO'
              : !milestone.details.publicRecordValue ?
                <AccentInfo>none</AccentInfo>
              : milestone.details.field.includes('Rate')
                || milestone.details.field.includes('%') ?
                `%${milestone.details.publicRecordValue.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}`
              : milestone.details.field.includes('Value')
                || milestone.details.field.includes('Price')
                || milestone.details.field.includes('Amount') ?
                `${milestone.details.publicRecordValue.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}`
              :
                milestone.details.publicRecordValue
              }
            </Typography>
          </FlexWrapper>
        : <></>}
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default DiscrepancyResolvedOrRejected