import React, { useEffect, useRef, useState } from "react"
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from "@mui/icons-material/Error";
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from "@mui/styles";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Button, ButtonWrapper, ErrorWrapper, FlexWrapper, StyledInputElement, Typography } from "../../../../../../../StyledComponents"

const String = ({ record, handleWorkFlow, valueType, handleSubmitEdit }) => {
  const theme = useTheme()

  const customEditInput = useRef()

  const [editError, setEditError] = useState(false)
  const [noChanges, setNoChanges] = useState(false)
  const [customEditValue, setCustomEditValue] = useState('')
  const [customEditValueStr, setCustomEditValueStr] = useState('')


  const handleCancelEdit = () => {
    setEditError(null)
    setNoChanges(true)
    handleWorkFlow('', '')
  }

  const handleClearField = () => {
    setCustomEditValueStr('')
    setCustomEditValue('')
    setNoChanges(false)
    customEditInput.current?.focus()
  }

  // useEffect(() => {
  //   if (valueType === 'number') {
  //     setCustomEditValueStr(record.currentValue)
  //     setCustomEditValue(record.currentValue.replace(/[^0-9-.]/g, ''))
  //   } else {
  //     setCustomEditValueStr(record.currentValue)
  //     setCustomEditValue(record.currentValue.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
  //   }
  // }, [record.currentValue, valueType])
  useEffect(() => {
    if (!record.currentValue) {
      setNoChanges(true)
    }
  }, [record.currentValue])

  return (
    <FlexWrapper className="column fitContentHeight" style={{margin: 'auto 0', padding: '0 2rem'}}>
      <FlexWrapper className="alignEnd" style={{height: '2rem'}}>
        <Typography style={{marginBottom: '0.3rem'}}>{record.label}</Typography>
        {editError === "noChanges" && (
          <ErrorWrapper style={{margin: 'auto'}}>
            <ErrorIcon />
            No changes made
          </ErrorWrapper>
        )}
        {editError === "blank" && (
          <ErrorWrapper style={{margin: 'auto'}}>
            <ErrorIcon />
            Can't be blank
          </ErrorWrapper>
        )}
        {editError === "character" && (
          <ErrorWrapper style={{margin: 'auto'}}>
            <ErrorIcon />
            {`Can't contain the characters '<' or '>'`}
          </ErrorWrapper>
        )}
        {editError === "tooLong" && (
          <ErrorWrapper style={{margin: 'auto'}}>
            <ErrorIcon />
            Can't contain more than 50 characters
          </ErrorWrapper>
        )}
        {editError === "nonNumber" && (
          <ErrorWrapper style={{margin: 'auto'}}>
            <ErrorIcon />
            Must be a number
          </ErrorWrapper>
        )}
        {editError === "negative" && (
          <ErrorWrapper style={{margin: 'auto'}}>
            <ErrorIcon />
            Can't be negative
          </ErrorWrapper>
        )}
        {editError === "zero" && (
          <ErrorWrapper style={{margin: 'auto'}}>
            <ErrorIcon />
            Can't be zero
          </ErrorWrapper>
        )}
        {editError === "large" && (
          <ErrorWrapper style={{margin: 'auto'}}>
            <ErrorIcon />
            {`Must be < 99`}
          </ErrorWrapper>
        )}
      </FlexWrapper>
      <StyledInputElement style={{width: '100%', padding: '1rem', marginTop: '0.3rem'}}
        value={customEditValueStr}
        tabIndex="1"
        type="text"
        name="customEdit"
        id="customEdit"
        ref={customEditInput}
        autoFocus
        onChange={() => {
          if (customEditInput.current.value.toUpperCase().trim() === record.currentValue) {
            setEditError('noChanges')
            setNoChanges(true)
          } else {
            setEditError(null)
            setNoChanges(false)
          }
          if (editError === 'blank' || editError === 'character' || editError === 'tooLong' || editError === "nonNumber" || editError === "negative" || editError === "zero" || editError === "large") {
            setEditError(null)
          }
          if (valueType === 'number') {
            let negativeAmount = Math.sign(customEditInput.current.value)
            let zeroAmount = Math.sign(customEditInput.current.value)
            if (negativeAmount === -1 || zeroAmount === 0 || isNaN(customEditInput.current.value) || customEditInput.current.value > 100000000) {
              if (negativeAmount === -1) {
                setEditError('negative')
              }
              if (zeroAmount === 0) {
                setEditError('zero')
              }
              if (isNaN(customEditInput.current.value)) {
                setEditError('nonNumber')
              }
              if (customEditInput.current.value > 100000000) {
                setEditError('large')
              }
            }
            setCustomEditValueStr(customEditInput.current.value)
            setCustomEditValue(customEditInput.current.value.replace(/[^0-9-.]/g, ''))
          } else {
            if (customEditInput.current.value.length === 0 || customEditInput.current.value.length > 50 || customEditInput.current.value.includes('<') || customEditInput.current.value.includes('>')) {
              if (customEditInput.current.value.length === 0) {
                setEditError('blank')
              }
              if (customEditInput.current.value.length > 50) {
                setEditError('tooLong')
              }
              if (customEditInput.current.value.includes('<')) {
                setEditError('character')
              }
              if (customEditInput.current.value.includes('>')) {
                setEditError('character')
              }
            }
            setCustomEditValueStr(customEditInput.current.value.toUpperCase())
            setCustomEditValue(customEditInput.current.value.toUpperCase())
          }
        }}
      />
      <ButtonWrapper style={{margin: '0.5rem auto 0 auto', height: '100%'}}>
        <Button className="neutral notes" onClick={() => handleCancelEdit()} tabIndex="3">
          <ClearIcon />
        </Button>
        <Button className="neutral notes" style={{marginLeft: '0.5rem'}} onClick={() => handleClearField()} tabIndex="4">
          <BackspaceIcon />
        </Button>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.tooltip.error.color}`,
            backgroundColor: `${theme.tooltip.error.background}`,
            border: `0.2rem solid ${theme.tooltip.error.border}`,
            borderLeft: `0.1rem solid ${theme.tooltip.error.border}`,
            opacity: '0',
            width: 'fit-content',
            padding: '1rem 1.5rem',
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, 0],
            },
          }]}}
          title={noChanges ?
            <FlexWrapper className="column">
              <Typography className="justifyCenter alignCenter">
                No changes made
              </Typography>
            </FlexWrapper>
          : null
          } 
          placement="right"
          arrow
        >
          <Button 
            className={editError || noChanges ? "primaryDisabled notes" : "primary notes"}
            style={{marginLeft: '0.5rem'}} 
            onClick={editError || noChanges ? null : () => handleSubmitEdit('edit', customEditValue)}
            tabIndex="2"
          >
            <SaveIcon />
          </Button>
        </Tooltip>
      </ButtonWrapper>
    </FlexWrapper>
  )
}

export default String