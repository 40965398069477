import React from "react";
import 'react-csv-importer/dist/index.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Importer, ImporterField } from 'react-csv-importer';
import { ModalContainer, ComponentHeader, SubHeader, ComponentBorder, ActionIconWrapper, FlexWrapper } from "../../../../../../../StyledComponents";

const CSVUpload9 = ({ handleCloseUploadModal, handleUpload, mortgagePresent, isFetching, isSuccess, isError }) => {
  const theme = useTheme()
  
  return (
    <ModalContainer className="onePanel" style={{height: 'fit-content'}}>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseUploadModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2', overflowY: 'scroll'}}>
        {isSuccess ?
          <ComponentHeader className="modal success">Upload CSV File</ComponentHeader>
        :
          <ComponentHeader className="modal">Upload CSV File</ComponentHeader>
        }
        {isSuccess ?
          <SubHeader className="modal">
            <span style={{color: `${theme.palette.primary.main}`, fontSize: "1.6rem"}}>Success!</span>
            <span>All contents have been added to the mortgage repository.</span>
          </SubHeader>
        : isFetching ?
          <SubHeader className="modal">
            <span><CircularProgress/></span>
            <span>Saving file contents...</span>
          </SubHeader>
        : isError ?
          <SubHeader className="modal">
            <span>Something has gone wrong. Please reach out to solutions@lancastersweep.com</span>
          </SubHeader>
        :
          <SubHeader className="modal">
            <span>Follow the instructions below.</span>
          </SubHeader>
        }

        <div style={{width: '100%', padding: '1.5rem'}}>
          <Importer
            dataHandler={async (rows) => {
              await handleUpload(rows, 'type9')
            }}
            defaultNoHeader={false}
            restartable={false}
            onClose={() => {
              handleCloseUploadModal();
            }}
          >
            {mortgagePresent === 'true' ?
              <>
                <ImporterField name="originationDate" label="originationDate" />
                <ImporterField name="loanType" label="loanType" />
                <ImporterField name="mortgageTerm" label="mortgageTerm" />
                <ImporterField name="StreetNumber" label="StreetNumber" />
                <ImporterField name="StreetDirPrefix" label="StreetDirPrefix" />
                <ImporterField name="StreetName" label="StreetName" />
                <ImporterField name="UnitPrefix" label="UnitPrefix" />
                <ImporterField name="UnitNumber" label="UnitNumber" />
                <ImporterField name="City" label="City" />
                <ImporterField name="StateOrProvince" label="StateOrProvince" />
                <ImporterField name="PostalCode" label="PostalCode" />
              </>
            :
              <>
                <ImporterField name="StreetNumber" label="StreetNumber" />
                <ImporterField name="StreetDirPrefix" label="StreetDirPrefix" />
                <ImporterField name="StreetName" label="StreetName" />
                <ImporterField name="UnitPrefix" label="UnitPrefix" />
                <ImporterField name="UnitNumber" label="UnitNumber" />
                <ImporterField name="City" label="City" />
                <ImporterField name="StateOrProvince" label="StateOrProvince" />
                <ImporterField name="PostalCode" label="PostalCode" />
              </>
            }
            {/* <ImporterField name="Owner1IsCorporation" label="Owner1IsCorporation" />
            <ImporterField name="Owner2IsCorporation" label="Owner2IsCorporation" />
            <ImporterField name="Owner1FullName" label="Owner1FullName" />
            <ImporterField name="Owner2FullName" label="Owner2FullName" />
            <ImporterField name="OwnerOccupied" label="OwnerOccupied" />
            <ImporterField name="loanAmount" label="loanAmount" />
            <ImporterField name="originalInterestRate" label="originalInterestRate" />
            <ImporterField name="originationDate" label="originationDate" />
            <ImporterField name="loanType" label="loanType" /> */}
          </Importer>
        </div>
      </FlexWrapper>

      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ModalContainer>
  )
}

export default CSVUpload9