import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: [{
    memberId: '',
    fullName: '',
    role: '',
    email: '',
    awaitingActionLeads: [],
    investigatingLeads: [],
    closingLeads: [],
    awaitingUpdateLeads: [],
    filteredAwaitingActionLeads: [],
    filteredInvestigatingLeads: [],
    filteredClosingLeads: [],
    filteredAwaitingUpdateLeads: [],
  }],
  status: 'idle',
  error: null
}

export const membersDataArraySlice = createSlice({
  name: 'membersDataArray',
  initialState,
  reducers: {
    setMembersArrayDsiableScan(state, action) {
      if (action.payload.assigneeIds.length > 0) {
        for (let i = 0; i < state.data.length; i++) {
          let memberToUpdate = action.payload.assigneeIds.find(assignee => assignee === state.data[i].memberId)
          if (memberToUpdate) {
            let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
            if (thisLead) {
              let thisLeadIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
              state.data[i].awaitingUpdateLeads[thisLeadIndex].disableScan = true
            }
            let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex].disableScan = true
            }
          }
        }
      }
    },
    addMemberArrayUpdatedLeadsFromSweep(state, action) {
      for (let i = 0; i < state.data.length; i++) {
        let thisMember = action.payload.identifiedUpdatesByMember.find(member => member.memberId.toString() === state.data[i].memberId.toString())
        if (thisMember) {
          for (let j = 0; j < action.payload.leads.length; j++) {
            let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id.toString() === action.payload.leads[j]._id.toString())
            if (thisLead) {
              let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
              state.data[i].awaitingActionLeads[thisIndex] = action.payload.leads[j]
            } else {
              let thisLead = state.data[i].investigatingLeads.find(lead => lead._id.toString() === action.payload.leads[j]._id.toString())
              if (thisLead) {
                let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                state.data[i].investigatingLeads[thisIndex] = action.payload.leads[j]
              } else {
                let thisLead = state.data[i].closingLeads.find(lead => lead._id.toString() === action.payload.leads[j]._id.toString())
                if (thisLead) {
                  let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                  state.data[i].closingLeads[thisIndex] = action.payload.leads[j]
                } else {
                  let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leads[j]._id.toString())
                  if (thisLead) {
                    let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                    state.data[i].awaitingUpdateLeads[thisIndex] = action.payload.leads[j]
                  }
                }
              }
            }
            let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id.toString() === action.payload.leads[j]._id.toString())
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = action.payload.leads[j]
            } else {
              let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id.toString() === action.payload.leads[j]._id.toString())
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = action.payload.leads[j]
              } else {
                let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id.toString() === action.payload.leads[j]._id.toString())
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredClosingLeads[thisFilteredIndex] = action.payload.leads[j]
                } else {
                  let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leads[j]._id.toString())
                  if (thisFilteredLead) {
                    let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                    state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = action.payload.leads[j]
                  }
                }
              }
            }
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.membersDataArray
      }
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus && payload.data.leadStatus.length > 0) {
        for (let i = 0; i < state.data.length; i++) {
          let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
          if (memberToUpdate) {
            if (payload.data.leadStatus === 'awaitingAction') {
              //* awaitingAction
              let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
                state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'investigating') {
              //* investigating
              let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'closing') {
              //* closing
              let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                state.data[i].closingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else {
              //* awaitingUpdate
              let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
              }
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus.length > 0) {
        for (let i = 0; i < state.data.length; i++) {
          let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
          if (memberToUpdate) {
            if (payload.data.leadStatus === 'awaitingAction') {
              //* awaitingAction
              let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
                state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'investigating') {
              //* investigating
              let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'closing') {
              //* closing
              let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                state.data[i].closingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else {
              //* awaitingUpdate
              let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
              }
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLead && payload.data.newLead.status) {
        if (!payload.data.discardLead) {
          for (let i = 0; i < state.data.length; i++) {
            let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
            if (memberToUpdate) {
              if (payload.data.leadStatus === 'awaitingAction') {
                //* awaitingAction
                let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisLead) {
                  let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
                  state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
                }
                let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
                }
              } else if (payload.data.leadStatus === 'investigating') {
                //* investigating
                let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisLead) {
                  let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                  state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
                }
                let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
                }
              } else if (payload.data.leadStatus === 'closing') {
                //* closing
                let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisLead) {
                  let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                  state.data[i].closingLeads[thisIndex] = payload.data.newLead
                }
                let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
                }
              } else {
                //* awaitingUpdate
                let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisLead) {
                  let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                  state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
                }
                let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
                }
              }
            }
          }
        } else {
          for (let i = 0; i < state.data.length; i++) {
            let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
            if (memberToUpdate) {
              if (payload.data.leadStatus === 'awaitingAction') {
                //* awaitingAction
                let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisLead) {
                  let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
                  state.data[i].awaitingActionLeads.splice(thisIndex, 1)
                }
                let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredAwaitingActionLeads.splice(thisFilteredIndex, 1)
                }
              } else if (payload.data.leadStatus === 'investigating') {
                //* investigating
                let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisLead) {
                  let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                  state.data[i].investigatingLeads.splice(thisIndex, 1)
                }
                let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredInvestigatingLeads.splice(thisFilteredIndex, 1)
                }
              } else if (payload.data.leadStatus === 'closing') {
                //* closing
                let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisLead) {
                  let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                  state.data[i].closingLeads.splice(thisIndex, 1)
                }
                let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredClosingLeads.splice(thisFilteredIndex, 1)
                }
              } else {
                //* awaitingUpdate
                let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisLead) {
                  let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                  state.data[i].awaitingUpdateLeads.splice(thisIndex, 1)
                }
                let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
                if (thisFilteredLead) {
                  let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                  state.data[i].filteredAwaitingUpdateLeads.splice(thisFilteredIndex, 1)
                }
              }
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'awaitingAction') {
            //* awaitingAction
            let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
              state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'investigating') {
            //* investigating
            let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
              state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'closing') {
            //* closing
            let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
              state.data[i].closingLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else {
            //* awaitingUpdate
            let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
              state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageTags.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus && payload.data.leadStatus.length > 0) {
        for (let i = 0; i < state.data.length; i++) {
          let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
          if (memberToUpdate) {
            if (payload.data.leadStatus === 'awaitingAction') {
              //* awaitingAction
              let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
                state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'investigating') {
              //* investigating
              let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'closing') {
              //* closing
              let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                state.data[i].closingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else {
              //* awaitingUpdate
              let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
              }
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'awaitingAction') {
            //* awaitingAction
            let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
              state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'investigating') {
            //* investigating
            let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
              state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'closing') {
            //* closing
            let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
              state.data[i].closingLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else {
            //* awaitingUpdate
            let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
              state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus !== 'inactive') {
        for (let i = 0; i < state.data.length; i++) {
          let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
          if (memberToUpdate) {
            if (payload.data.leadStatus === 'awaitingAction') {
              //* awaitingAction
              let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
                state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
              } else {
                state.data[i].awaitingActionLeads.unshift(payload.data.newLead)
              }
              let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
              } else {
                state.data[i].filteredAwaitingActionLeads.unshift(payload.data.newLead)
              }
            } else if (payload.data.leadStatus === 'investigating') {
              //* investigatingLeads
              let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
              } else {
                state.data[i].investigatingLeads.unshift(payload.data.newLead)
              }
              let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
              } else {
                state.data[i].filteredInvestigatingLeads.unshift(payload.data.newLead)
              }
            } else if (payload.data.leadStatus === 'closing') {
              //* closingLeads
              let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                state.data[i].closingLeads[thisIndex] = payload.data.newLead
              } else {
                state.data[i].closingLeads.unshift(payload.data.newLead)
              }
              let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
              } else {
                state.data[i].filteredClosingLeads.unshift(payload.data.newLead)
              }
            } else {
              //* awaitingUpdate
              let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
              } else {
                state.data[i].awaitingUpdateLeads.unshift(payload.data.newLead)
              }
              let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
              } else {
                state.data[i].filteredAwaitingUpdateLeads.unshift(payload.data.newLead)
              }
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'awaitingAction') {
            //* awaitingAction
            let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
              state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
            } else {
              state.data[i].awaitingActionLeads.unshift(payload.data.newLead)
            }
            let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
            } else {
              state.data[i].filteredAwaitingActionLeads.unshift(payload.data.newLead)
            }
          } else if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
              state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
            } else {
              state.data[i].investigatingLeads.unshift(payload.data.newLead)
            }
            let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
            } else {
              state.data[i].filteredInvestigatingLeads.unshift(payload.data.newLead)
            }
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
              state.data[i].closingLeads[thisIndex] = payload.data.newLead
            } else {
              state.data[i].closingLeads.unshift(payload.data.newLead)
            }
            let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
            } else {
              state.data[i].filteredClosingLeads.unshift(payload.data.newLead)
            }
          } else {
            //* awaitingUpdate
            let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
              state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
            } else {
              state.data[i].awaitingUpdateLeads.unshift(payload.data.newLead)
            }
            let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
            } else {
              state.data[i].filteredAwaitingUpdateLeads.unshift(payload.data.newLead)
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetChanges.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'awaitingAction') {
            //* awaitingAction
            let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
              state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'investigating') {
            //* investigating
            let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
              state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'closing') {
            //* closing
            let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
              state.data[i].closingLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
            }
          } else {
            //* awaitingUpdate
            let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
              state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
            }
            let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.provideFinancialInformation.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadUpdated) {
        for (let i = 0; i < state.data.length; i++) {
          let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
          if (memberToUpdate) {
            if (payload.data.leadStatus === 'awaitingAction') {
              //* awaitingAction
              let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
                state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'investigating') {
              //* investigating
              let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'closing') {
              //* closing
              let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                state.data[i].closingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else {
              //* awaitingUpdate
              let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
              }
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (state.data.newLead && state.data.newLead.assigneeIds) {
        for (let i = 0; i < state.data.length; i++) {
          let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
          if (memberToUpdate) {
            if (payload.data.leadStatus === 'awaitingAction') {
              //* awaitingAction
              let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
                state.data[i].awaitingActionLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
              if (thisLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'investigating') {
              //* investigatingLeads
              let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.leadId)
              if (thisLead) {
                let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
                state.data[i].investigatingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
              if (thisLead) {
                let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else if (payload.data.leadStatus === 'closing') {
              //* closingLeads
              let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.leadId)
              if (thisLead) {
                let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
                state.data[i].closingLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
              if (thisLead) {
                let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
                state.data[i].filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
              }
            } else {
              //* awaitingUpdate
              let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
              if (thisLead) {
                let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
                state.data[i].awaitingUpdateLeads[thisIndex] = payload.data.newLead
              }
              let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
              if (thisLead) {
                let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data[i].filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
              }
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'awaitingAction') {
            //* awaitingAction
            let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
              state.data[i].awaitingActionLeads.splice(thisIndex, 1)
            }
            let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingActionLeads.splice(thisFilteredIndex, 1)
            }
          } else if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.leadId)
            if (thisLead) {
              let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
              state.data[i].investigatingLeads.splice(thisIndex, 1)
            }
            let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredInvestigatingLeads.splice(thisFilteredIndex, 1)
            }
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.leadId)
            if (thisLead) {
              let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
              state.data[i].closingLeads.splice(thisIndex, 1)
            }
            let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredClosingLeads.splice(thisFilteredIndex, 1)
            }
          } else {
            //* awaitingUpdate
            let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
              state.data[i].awaitingUpdateLeads.splice(thisIndex, 1)
            }
            let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingUpdateLeads.splice(thisFilteredIndex, 1)
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.leadAssigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          if (payload.data.leadStatus === 'awaitingAction') {
            //* awaitingAction
            let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
              state.data[i].awaitingActionLeads.splice(thisIndex, 1)
            }
            let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingActionLeads.splice(thisFilteredIndex, 1)
            }
          } else if (payload.data.leadStatus === 'investigating') {
            //* investigatingLeads
            let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.leadId)
            if (thisLead) {
              let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
              state.data[i].investigatingLeads.splice(thisIndex, 1)
            }
            let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredInvestigatingLeads.splice(thisFilteredIndex, 1)
            }
          } else if (payload.data.leadStatus === 'closing') {
            //* closingLeads
            let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.leadId)
            if (thisLead) {
              let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
              state.data[i].closingLeads.splice(thisIndex, 1)
            }
            let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
              state.data[i].filteredClosingLeads.splice(thisFilteredIndex, 1)
            }
          } else {
            //* awaitingUpdate
            let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
            if (thisLead) {
              let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
              state.data[i].awaitingUpdateLeads.splice(thisIndex, 1)
            }
            let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
              state.data[i].filteredAwaitingUpdateLeads.splice(thisFilteredIndex, 1)
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusInvestigating.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* awaitingAction
          let thisLead = state.data[i].awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data[i].awaitingActionLeads.indexOf(thisLead)
            state.data[i].awaitingActionLeads.splice(thisIndex, 1)
          }
          let thisFilteredLead = state.data[i].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisFilteredLead) {
            let thisFilteredIndex = state.data[i].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
            state.data[i].filteredAwaitingActionLeads.splice(thisFilteredIndex, 1)
          }
          //* investigatingLeads
          state.data[i].investigatingLeads.unshift(payload.data.newLead)
          state.data[i].filteredInvestigatingLeads.unshift(payload.data.newLead)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusClosing.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.newLead.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* investigatingLeads
          let thisLead = state.data[i].investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data[i].investigatingLeads.indexOf(thisLead)
            state.data[i].investigatingLeads.splice(thisIndex, 1)
          }
          let thisFilteredLead = state.data[i].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisFilteredLead) {
            let thisFilteredIndex = state.data[i].filteredInvestigatingLeads.indexOf(thisFilteredLead)
            state.data[i].filteredInvestigatingLeads.splice(thisFilteredIndex, 1)
          }
          //* closingLeads
          state.data[i].closingLeads.unshift(payload.data.newLead)
          state.data[i].filteredClosingLeads.unshift(payload.data.newLead)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* closingLeads
          let thisLead = state.data[i].closingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data[i].closingLeads.indexOf(thisLead)
            state.data[i].closingLeads.splice(thisIndex, 1)
          }
          let thisFilteredLead = state.data[i].filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisFilteredLead) {
            let thisFilteredIndex = state.data[i].filteredClosingLeads.indexOf(thisFilteredLead)
            state.data[i].filteredClosingLeads.splice(thisFilteredIndex, 1)
          }
          if (payload.data.activeDiscrepancies > 0 || payload.data.preserveLead) {
            state.data[i].awaitingUpdateLeads.unshift(payload.data.newLead)
            state.data[i].filteredAwaitingUpdateLeads.unshift(payload.data.newLead)
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < state.data.length; i++) {
        let memberToUpdate = payload.data.assigneeIds.find(assignee => assignee === state.data[i].memberId)
        if (memberToUpdate) {
          //* awaitingUpdateLeads
          let thisLead = state.data[i].awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data[i].awaitingUpdateLeads.indexOf(thisLead)
            state.data[i].awaitingUpdateLeads.splice(thisIndex, 1)
          }
          let thisFilteredLead = state.data[i].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          if (thisFilteredLead) {
            let thisFilteredIndex = state.data[i].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
            state.data[i].filteredAwaitingUpdateLeads.splice(thisFilteredIndex, 1)
          }
          state.data[i].leadsAwaitingVerification--
        }
      }
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.newLeads.length; i++) {
        for (let j = 0; j < state.data.length; j++) {
          let thisMember = payload.data.newLeads[i].assigneeIds.find(assignee => assignee.toString() === state.data[j].memberId.toString())
          if (thisMember) {
            if (payload.data.newLeads[i].status === 'awaitingAction') {
              let thisLead = state.data[j].awaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
              if (thisLead) {
                let thisIndex = state.data[j].awaitingActionLeads.indexOf(thisLead)
                state.data[j].awaitingActionLeads.splice(thisIndex, 1)
              }
              let thisFilteredLead = state.data[j].filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
              if (thisFilteredLead) {
                let thisIndexFiltered = state.data[j].filteredAwaitingActionLeads.indexOf(thisFilteredLead)
                state.data[j].filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
              }
            } else if (payload.data.newLeads[i].status === 'investigating') {
              let thisLead = state.data[j].investigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
              if (thisLead) {
                let thisIndex = state.data[j].investigatingLeads.indexOf(thisLead)
                state.data[j].investigatingLeads.splice(thisIndex, 1)
              }
              let thisFilteredLead = state.data[j].filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
              if (thisFilteredLead) {
                let thisIndexFiltered = state.data[j].filteredInvestigatingLeads.indexOf(thisFilteredLead)
                state.data[j].filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
              }
            } else if (payload.data.newLeads[i].status === 'closing') {
              let thisLead = state.data[j].closingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
              if (thisLead) {
                let thisIndex = state.data[j].closingLeads.indexOf(thisLead)
                state.data[j].closingLeads.splice(thisIndex, 1)
              }
              let thisFilteredLead = state.data[j].filteredClosingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
              if (thisFilteredLead) {
                let thisIndexFiltered = state.data[j].filteredClosingLeads.indexOf(thisFilteredLead)
                state.data[j].filteredClosingLeads.splice(thisIndexFiltered, 1)
              }
            } else {
              let thisLead = state.data[j].awaitingUpdateLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
              if (thisLead) {
                let thisIndex = state.data[j].awaitingUpdateLeads.indexOf(thisLead)
                state.data[j].awaitingUpdateLeads[thisIndex] = payload.data.newLeads[i]
              }
              let thisFilteredLead = state.data[j].filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
              if (thisFilteredLead) {
                let thisIndexFiltered = state.data[j].filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data[j].filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLeads[i]
              }
            }
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.deleteUser.matchFulfilled, (state, {payload}) => {
      let thisInstance = state.data.find(member => member.memberId === payload.data.deletedUserId)
      let thisIndex = state.data.indexOf(thisInstance)
      state.data.splice(thisIndex, 1)
    })
  }
})

export const { setMembersArrayDsiableScan, addMemberArrayUpdatedLeadsFromSweep } = membersDataArraySlice.actions


export default membersDataArraySlice.reducer

export const selectMembersDataArray = state => state.membersDataArray.data