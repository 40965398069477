import React from "react";
import styled from "@emotion/styled";
import ErrorIcon from '@mui/icons-material/Error';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useDispatch, useSelector } from "react-redux";
import { NavIndex, NavText, NavigationContainer, SideBarNavHeader } from "../../../../../../../StyledComponents";
import { selectUploadReportNavSelection, setUploadReportNavMenuSelection } from "../../../../../../../slices/sessionDataSlice";

const UploadReportsNav = () => {
  const dispatch = useDispatch()

  const navSelection = useSelector(selectUploadReportNavSelection)
  
  const changeSelection = (content) => {
    dispatch( setUploadReportNavMenuSelection(content))
  }

  return (
    <NavigationContainer className="modal" style={{gridRow: '2/4', gridColumn: '2'}}>
      <SideBarNavHeader className="modal">Actions</SideBarNavHeader>
      {navSelection ?
        <>
          {navSelection === 'summary' ?
            <ListItemNav selected>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Summary</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('summary')}>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Summary</NavText>
            </ListItemNav>
          }
          {navSelection === 'errors' ?
            <ListItemNav selected>
              <NavIndex>
                <ErrorIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Errors</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('errors')}>
              <NavIndex>
                <ErrorIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Errors</NavText>
            </ListItemNav>
          }
        </>
      :
        <>
          <ListItemNav disabled>
            <NavIndex>
              <SummarizeIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Summary</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <ErrorIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Errors</NavText>
          </ListItemNav>
        </>
      }
    </NavigationContainer>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 0.3rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.disabled ? "#4B14752B" : props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor ? "default" : props.disabled ? 'not-allowed' : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  border-top: ${(props) => props.top ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  border-bottom: ${(props) => props.bottom ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  &:hover {
    background-color: ${(props) => props.selected && !props.disabled ? "#4B147533" : props.disabled ? "#E0E0E0" : "#F2F2F2"};
  }
`

export default UploadReportsNav;