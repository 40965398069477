import React, { forwardRef, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {addDays} from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import DatePicker from 'react-datepicker';
import { useTheme } from "@mui/styles";
import { CompareLink, FadeWrapper, FlexWrapper } from "../../../../../../../StyledComponents";
import { dateConverterMMMdoyyyy } from "../../../../../../../utils/dateConverter";

const DateRecord = ({ handleWorkFlow, handleSubmitEdit, record, dateFormat }) => {
  const theme = useTheme()

  const [newDateValue, setNewDateValue] = useState(new Date())

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="styled-calendar-input-icon-left"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ))

  useEffect(() => {
    if (record.currentValue !== null) {
      if (dateFormat === 'MMM Do, yyyy') {
        let oldDate = dateConverterMMMdoyyyy(record.currentValue)
        let newDate = new Date(oldDate)
        newDate.setDate(newDate.getDate() + 1)
        setNewDateValue(newDate)
      } else {
        let newDate = new Date(record.currentValue)
        newDate.setDate(newDate.getDate() + 1)
        setNewDateValue(newDate)
      }
    }
  }, [record.currentValue, dateFormat])

  return (
    <FadeWrapper>
      <DatePicker 
        selected={newDateValue}
        onChange={(date) => setNewDateValue(date)}
        dateFormat={'yyyy-MM-dd'}
        maxDate={addDays(new Date(), 1800)}
        showMonthDropdown
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={100}
        withPortal
        portalId="root-portal"
        customInput={<ExampleCustomInput />}
        dayClassName={() => "example-datepicker-day-class"}
        calendarClassName="example-datepicker-class"
        todayButton="CLICK FOR TODAY'S DATE"
      />
      <FlexWrapper className="fitContentHeight justifyCenter" style={{marginTop: '1rem'}}>
        <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{fontSize: '1rem', marginRight: '0.3rem'}}>
          <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
        </CompareLink>
        <CompareLink className="small green" onClick={() => handleSubmitEdit('edit', newDateValue, dateFormat)} style={{fontSize: '1rem', margin: '0'}}>
          <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
        </CompareLink>
      </FlexWrapper>
    </FadeWrapper>
  )
}

export default DateRecord