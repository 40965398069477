import React, { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { Dialog } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CSVUploadmodal from "./components/CSVUploadModal";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from "react-redux";
import { selectCSVMortgageUploadPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { selectTeamRemainingMonthlyQueries } from "../../../../../slices/teamStatsSlice";
import { AccentInfo, Button, FlexWrapper, Typography  } from "../../../../../StyledComponents";
import { incFileUploadMortgagesAdded, incFileUplodDuplicateMortgages, incFileUplodErrors } from "../../../../../slices/sessionDataSlice";

const BulkUpload = ({ teamId, userFullName, userId, userRole }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [ownersChecked, setOwnersChecked] = useState(false)
  const [financialsChecked, setFinancialsChecked] = useState(false)
  const [exampleHeaderOwners, setExampleHeaderOwners] = useState(null)
  const [exampleOwnersLineOne, setExampleOwnersLineOne] = useState(null)
  const [exampleOwnersLineTwo, setExampleOwnersLineTwo] = useState(null)
  const [exampleHeaderFinancials, setExampleHeaderFinancials] = useState(null)
  const [exampleFinancialsLineOne, setExampleFinancialsLineOne] = useState(null)
  const [exampleFinancialsLineTwo, setExampleFinancialsLineTwo] = useState(null)

  const csvMortgageUploadPerm = useSelector(selectCSVMortgageUploadPerm)
  const remainingMonthlyQueries = useSelector(selectTeamRemainingMonthlyQueries)
  
  const handleOwnersChecked = (event) => {
    setOwnersChecked(event.target.checked)
    if (financialsChecked && event.target.checked) {
      setExampleHeaderOwners(', Owner One Name, Owner Two Name')
      setExampleHeaderFinancials(', Loan Amount, Interst Rate, Mortgage Term, Start Date')
      setExampleOwnersLineOne(', ex owner one, ex owner two')
      setExampleOwnersLineTwo(', ex owner one')
      setExampleFinancialsLineOne(', 1000000, 4.0, 30, 12/31/2000')
      setExampleFinancialsLineTwo(', 1000000, 4.0, 30, 12/31/2000')
    } else if (financialsChecked) {
      setExampleHeaderFinancials(', Loan Amount, Interst Rate, Mortgage Term, Start Date')
      setExampleFinancialsLineOne(', 1000000, 4.0, 30, 12/31/2000')
      setExampleFinancialsLineTwo(', 1000000, 4.0, 30, 12/31/2000')
      setExampleHeaderOwners(null)
      setExampleOwnersLineOne(null)
      setExampleOwnersLineTwo(null)
    } else if (event.target.checked) {
      setExampleHeaderOwners(', Owner One Name, Owner Two Name')
      setExampleOwnersLineOne(', ex owner one, ex owner two')
      setExampleOwnersLineTwo(', ex owner one')
      setExampleHeaderFinancials(null)
      setExampleFinancialsLineOne(null)
      setExampleFinancialsLineTwo(null)
    } else {
      setExampleOwnersLineOne(null)
      setExampleOwnersLineTwo(null)
      setExampleHeaderOwners(null)
      setExampleHeaderFinancials(null)
      setExampleFinancialsLineOne(null)
      setExampleFinancialsLineTwo(null)
    }
  }
  
  const handleFinancialsChecked = (event) => {
    setFinancialsChecked(event.target.checked)
    if (ownersChecked && event.target.checked) {
      setExampleHeaderOwners(', Owner One Name, Owner Two Name')
      setExampleHeaderFinancials(', Loan Amount, Interst Rate, Mortgage Term, Start Date')
      setExampleOwnersLineOne(', ex owner one, ex owner two')
      setExampleOwnersLineTwo(', ex owner one')
      setExampleFinancialsLineOne(', 1000000, 4.0, 30, 12/31/2000')
      setExampleFinancialsLineTwo(', 1000000, 4.0, 30, 12/31/2000')
    } else if (ownersChecked) {
      setExampleHeaderOwners(', Owner One Name, Owner Two Name')
      setExampleOwnersLineOne(', ex owner one, ex owner two')
      setExampleOwnersLineTwo(', ex owner one')
      setExampleFinancialsLineOne(null)
      setExampleFinancialsLineTwo(null)
      setExampleHeaderFinancials(null)
    } else if (event.target.checked) {
      setExampleHeaderFinancials(', Loan Amount, Interst Rate, Mortgage Term, Start Date')
      setExampleFinancialsLineOne(', 1000000, 4.0, 30, 12/31/2000')
      setExampleFinancialsLineTwo(', 1000000, 4.0, 30, 12/31/2000')
      setExampleHeaderOwners(null)
      setExampleOwnersLineOne(null)
      setExampleOwnersLineTwo(null)
    } else {
      setExampleOwnersLineOne(null)
      setExampleOwnersLineTwo(null)
      setExampleHeaderOwners(null)
      setExampleHeaderFinancials(null)
      setExampleFinancialsLineOne(null)
      setExampleFinancialsLineTwo(null)
    }
  }

  const [openCSVUploadModal, setOpenCSVUploadModal] = useState(false)
  const handleOpenUploadModal = () => {
    setOpenCSVUploadModal(true)
  }
  const handleCloseUploadModal = () => {
    dispatch( incFileUplodDuplicateMortgages(0) )
    dispatch( incFileUploadMortgagesAdded(0) )
    dispatch( incFileUplodErrors(0) )
    setOpenCSVUploadModal(false)
  }

  return (
    <>
      <FlexWrapper className="column">
        <FlexWrapper className="column fitContentHeight fitContentWidth" style={{margin: '2rem 0 1rem 10rem'}}>
          <Typography className="small fitContentWidth alignTextCenter" style={{marginBottom: '0.5rem'}}>
            To conserve queries, do your best to adhere to the format in the following example:
          </Typography>
          <FlexWrapper className="fitContentWidth fitContentHeight">
            <AccentInfo style={{margin: '0 auto 0 0'}}>
              Street Address, City, State, Postal Code
            </AccentInfo>
            <AccentInfo style={{margin: '0 auto 0 0', color: `${theme.palette.secondary.main}`}}>
              {exampleHeaderOwners}
            </AccentInfo>
            <AccentInfo style={{margin: '0 auto 0 0', color: `${theme.palette.primary.main}`}}>
              {exampleHeaderFinancials}
            </AccentInfo>
          </FlexWrapper>
          <FlexWrapper className="fitContentWidth fitContentHeight">
            <AccentInfo style={{margin: '0 auto 0 0'}}>
              1111 1st St APT 100, san francisco, ca, 00000
            </AccentInfo>
            <AccentInfo style={{margin: '0 auto 0 0', color: `${theme.palette.secondary.main}`}}>
              {exampleOwnersLineOne}
            </AccentInfo>
            <AccentInfo style={{margin: '0 auto 0 0', color: `${theme.palette.primary.main}`}}>
              {exampleFinancialsLineOne}
            </AccentInfo>
          </FlexWrapper>
          <FlexWrapper className="fitContentWidth fitContentHeight">
            <AccentInfo style={{margin: '0 auto 0 0'}}>
              2222 2nd St APT 200, san francisco, ca, 00000
            </AccentInfo>
            <AccentInfo style={{margin: '0 auto 0 0', color: `${theme.palette.secondary.main}`}}>
              {exampleOwnersLineTwo}
            </AccentInfo>
            <AccentInfo style={{margin: '0 auto 0 0', color: `${theme.palette.primary.main}`}}>
              {exampleFinancialsLineTwo}
            </AccentInfo>
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper className="column" style={{padding: '0 3rem 0 23rem'}}>
          <FlexWrapper className="fitContentWidth fitContentHeight alignEnd justifyEnd" style={{margin: '0 0 2rem 0.7rem'}}>
            <FormControl>
              <FlexWrapper style={{margin: '0.5rem 0'}}>
                <FormControlLabel
                  label="Address"
                  control={
                    <Checkbox
                      checked={true}
                      disabled={true}
                    />
                  }
                  style={{width: '13rem'}}
                />
                <AccentInfo style={{margin: '0'}}>
                  Street Address, City, State, Postal Code,
                </AccentInfo>
              </FlexWrapper>
              <FlexWrapper style={{margin: '0.5rem 0'}}>
                <FormControlLabel
                  label="Include Owners"
                  control={
                    <Checkbox
                      checked={ownersChecked}
                      onChange={handleOwnersChecked}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        color: `${theme.palette.secondary.main}`,
                        '&.Mui-checked': {
                          color: `${theme.palette.secondary.main}`,
                        },
                      }}
                    />
                  }
                  style={{width: '13rem'}}
                />
                <AccentInfo style={{margin: '0'}}>
                  + Owner One Name, Owner Two Name,
                </AccentInfo>
              </FlexWrapper>
              <FlexWrapper style={{margin: '0.5rem 0'}}>
                <FormControlLabel
                  label="Include Financials"
                  control={
                    <Checkbox
                      checked={financialsChecked}
                      onChange={handleFinancialsChecked}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        color: `${theme.palette.primary.main}`,
                        '&.Mui-checked': {
                          color: `${theme.palette.primary.main}`,
                        },
                      }}
                    />
                  }
                  style={{width: '13rem'}}
                />
                <AccentInfo style={{margin: '0'}}>
                  + Loan Amount, Interst Rate, Mortgage Term, Start Date (MM/DD/YYYY)
                </AccentInfo>
              </FlexWrapper>
            </FormControl>
          </FlexWrapper>
          <FlexWrapper className="fitContentWidth alignCenter">
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.caution.color}`,
                backgroundColor: `${theme.tooltip.caution.background}`,
                border: `0.1rem solid ${theme.tooltip.caution.border}`,
                borderBottom: `0.1rem solid ${theme.tooltip.caution.border}`,
                opacity: '0',
                borderRadius: '1rem',
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: '0.5rem 1rem',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={(remainingMonthlyQueries === 0) ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    No monthly queries remaining.
                  </Typography>
                </FlexWrapper>
              : !csvMortgageUploadPerm ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Elevated authority required
                  </Typography>
                </FlexWrapper>
              : null} 
              placement="top"
              arrow
            >
              <span>
                <Button 
                  style={{margin: '0 0.5rem 0 0'}}
                  className={(csvMortgageUploadPerm && remainingMonthlyQueries > 0) ? "primary round" : "primaryDisabled round"} 
                  onClick={(csvMortgageUploadPerm && remainingMonthlyQueries > 0) ? () => handleOpenUploadModal() : null}
                >
                  <UploadFileIcon />
                </Button>
              </span>
            </Tooltip>
            <FlexWrapper>
              <FlexWrapper className="column justifyCenter" style={{maxWidth: 'fit-content', padding: '0 0.3rem 0 0'}}>
                <span style={{fontWeight: '600', fontSize: '1.2rem'}}>Begin Upload</span>
                <FlexWrapper className="fitContentWidth fitContentHeight alignCenter">
                  <ErrorOutlineIcon style={{width: '1.2rem', height: '1.2rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
                  <span style={{color: `${theme.palette.common.grayText}`}}>Ensure all desired fields are selected before proceeding.</span>
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>

      <Dialog
        open={openCSVUploadModal} onClose={handleCloseUploadModal}
        PaperProps={{ sx: {width: "110rem", minWidth: "110rem", maxWidth: "110rem", height: "fit-content", minHeight: "20rem", maxHeight: "95%"}}}
      >
        <CSVUploadmodal teamId={teamId} userFullName={userFullName} handleCloseUploadModal={handleCloseUploadModal} ownersChecked={ownersChecked} financialsChecked={financialsChecked} userId={userId} userRole={userRole} />
      </Dialog>
    </>
  )
}

export default BulkUpload