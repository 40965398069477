import React from "react";
import { useTheme } from "@mui/styles";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useSelector } from "react-redux";
import { selectUserRole } from "../../../slices/userInfoSlice";
import DiscrepanciesDetected from "./components/DiscrepanciesDetected";
import { AccentInfo, CardButton, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const ScanPerformed = ({milestone}) => {
  const theme = useTheme()

  const userRole = useSelector(selectUserRole)

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="milestone" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      {(milestone.newResolved && milestone.newResolved > 0) ?
        <Typography>
          <span style={{fontWeight: '700', marginRight: '0.3rem'}}>
            {milestone.newResolved}
          </span>
          {milestone.newResolved === 1 ? `Discrepancy resolved` : `Discrepancies resolved`}
        </Typography>
      : <></>
      }
      {milestone.milestone === 'Public Records Updated' && milestone.activeDiscrepancies === 0 ?
        <Typography style={{fontWeight: '700'}}>
          {milestone.milestone === 'Record Scanned Successfuly' ?
            <AccentInfo>
              No discrepancies detected
            </AccentInfo>
          :
            <>
              No Discrepancies remaining
              <br/>
            </>
          }
          {milestone.leadAwaitingUpdates &&
            'Ready to verify'
          }
        </Typography>
      : milestone.activeDiscrepancies > 0 ?
        <Typography>
          <span style={{fontWeight: '700', marginRight: '0.3rem'}}>
            {milestone.activeDiscrepancies}
          </span>
          {milestone.activeDiscrepancies === 1 ? `Discrepancy ` : `Discrepancies `}
          {milestone.milestone === 'Discrepancies Detected' ? `detected` : `remaining`}
        </Typography>
      : <></>}
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        {milestone.contributor &&
          <FlexWrapper className='fitContentHeight'>
            <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
              contributor:
            </Typography>
            <Typography className="fitContentHeight bold">
              {milestone.contributor}
            </Typography>
          </FlexWrapper>
        }
        {userRole === 'super' &&
          <FlexWrapper className='fitContentHeight'>
            <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
              Queries:
            </Typography>
            <Typography className="fitContentHeight bold">
              {milestone.details}
            </Typography>
          </FlexWrapper>
        }
        {(milestone.tier1Discrepancies || milestone.tier2Discrepancies || milestone.tier3Discrepancies) &&
          <DiscrepanciesDetected type={'discrepancy'} tier1={milestone.tier1Discrepancies} tier2={milestone.tier2Discrepancies} tier3={milestone.tier3Discrepancies} />
        }
        {(milestone.tier1Updates || milestone.tier2Updates || milestone.tier3Updates) &&
          <DiscrepanciesDetected type={'update'} tier1={milestone.tier1Updates} tier2={milestone.tier2Updates} tier3={milestone.tier3Updates} />
        }
        {(milestone.tier1Resolved || milestone.tier2Resolved || milestone.tier3Resolved) &&
          <DiscrepanciesDetected type={'resolved'} tier1={milestone.tier1Resolved} tier2={milestone.tier2Resolved} tier3={milestone.tier3Resolved} />
        }
        {milestone.newAssessmentInformation &&
          <FlexWrapper className="column fitContentHeight">
            <Typography className="fitContentHeight justifyEnd" style={{width: '17rem', margin: '1rem 0 0.5rem 0'}}>
              Updated Assessment Information:
            </Typography>
            <FlexWrapper 
              className="flexWrap"
              style={{maxWidth: '35rem', marginBottom: '1rem'}}
            >
              {milestone.newAssessmentInformation.newAssessedYear &&
                <FlexWrapper className='fitContentHeight fitContentWidth'>
                  <Typography className="fitContentHeight justifyEnd" style={{width: '12rem', margin: '0 0.5rem 0 3rem'}}>
                    Assessed Year:
                  </Typography>
                  <Typography className="fitContentHeight bold">
                    <span style={{fontWeight: '600'}}>{milestone.newAssessmentInformation.oldAssessedYear}</span>
                    <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                    <span style={{fontWeight: '600'}}>{milestone.newAssessmentInformation.newAssessedYear}</span>
                    {milestone.newAssessmentInformation.newTaxYear}
                  </Typography>
                </FlexWrapper>
              }
              {milestone.newAssessmentInformation.newAssessedValue &&
                <FlexWrapper className='fitContentHeight fitContentWidth'>
                  <Typography className="fitContentHeight justifyEnd" style={{width: '12rem', margin: '0 0.5rem 0 3rem'}}>
                    Total Value:
                  </Typography>
                  <Typography className="fitContentHeight bold">
                    <span style={{fontWeight: '600'}}>${parseFloat(milestone.newAssessmentInformation.oldAssessedValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                    <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                    <span style={{fontWeight: '600'}}>${parseFloat(milestone.newAssessmentInformation.newAssessedValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                    {milestone.newAssessmentInformation.newTaxAnnualAmount}
                  </Typography>
                </FlexWrapper>
              }
              {milestone.newAssessmentInformation.newAssessedLandValue &&
                <FlexWrapper className='fitContentHeight fitContentWidth'>
                  <Typography className="fitContentHeight justifyEnd" style={{width: '12rem', margin: '0 0.5rem 0 3rem'}}>
                    Land Value:
                  </Typography>
                  <Typography className="fitContentHeight bold">
                    <span style={{fontWeight: '600'}}>${parseFloat(milestone.newAssessmentInformation.oldAssessedLandValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                    <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                    <span style={{fontWeight: '600'}}>${parseFloat(milestone.newAssessmentInformation.newAssessedLandValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                    {milestone.newAssessmentInformation.newTaxYear}
                  </Typography>
                </FlexWrapper>
              }
              {milestone.newAssessmentInformation.newAssessedImprovementValue &&
                <FlexWrapper className='fitContentHeight fitContentWidth'>
                  <Typography className="fitContentHeight justifyEnd" style={{width: '12rem', margin: '0 0.5rem 0 3rem'}}>
                    Improvements Value:
                  </Typography>
                  <Typography className="fitContentHeight bold">
                    <span style={{fontWeight: '600'}}>${parseFloat(milestone.newAssessmentInformation.oldAssessedImprovementValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                    <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                    <span style={{fontWeight: '600'}}>${parseFloat(milestone.newAssessmentInformation.newAssessedImprovementValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                  </Typography>
                </FlexWrapper>
              }
            </FlexWrapper>
          </FlexWrapper>
        }
        {milestone.newTaxInformation &&
          <FlexWrapper className="column fitContentHeight">
            <Typography className="fitContentHeight justifyEnd" style={{width: '12.8rem', margin: '1rem 0 0.5rem 0'}}>
              Updated Tax Information:
            </Typography>
            <FlexWrapper 
              className="flexWrap"
              style={{maxWidth: '35rem', marginBottom: '1rem'}}
            >
              {milestone.newTaxInformation.newTaxYear &&
                <FlexWrapper className='fitContentHeight fitContentWidth'>
                  <Typography className="fitContentHeight justifyEnd" style={{width: '12rem', margin: '0 0.5rem 0 3rem'}}>
                    Tax Year:
                  </Typography>
                  <Typography className="fitContentHeight bold">
                    <span style={{fontWeight: '600'}}>{milestone.newTaxInformation.oldTaxYear}</span>
                    <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                    <span style={{fontWeight: '600'}}>{milestone.newTaxInformation.newTaxYear}</span>
                  </Typography>
                </FlexWrapper>
              }
              {milestone.newTaxInformation.newTaxAnnualAmount &&
                <FlexWrapper className='fitContentHeight fitContentWidth'>
                  <Typography className="fitContentHeight justifyEnd" style={{width: '12rem', margin: '0 0.5rem 0 3rem'}}>
                    Tax Annual Amount:
                  </Typography>
                  <Typography className="fitContentHeight bold">
                    <span style={{fontWeight: '600'}}>${parseFloat(milestone.newTaxInformation.oldTaxAnnualAmount).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                    <span style={{margin: '0 0.4rem', fontWeight: '400'}}>to</span>
                    <span style={{fontWeight: '600'}}>${parseFloat(milestone.newTaxInformation.newTaxAnnualAmount).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 0})}</span>
                  </Typography>
                </FlexWrapper>
              }
            </FlexWrapper>
          </FlexWrapper>
        }
        {milestone.newLeadTags && milestone.newLeadTags.length > 0 &&
          <FlexWrapper className="column fitContentHeight">
            <Typography className="fitContentHeight justifyEnd" style={{width: '14rem', margin: '1rem 0 0.5rem 0'}}>
              {milestone.newLeadTags.length > 1 ?
                `New Investigation Tags:`
              :
                `New Investigation Tag:`
              }
            </Typography>
            <FlexWrapper 
              className="flexWrap justifyCenter"
              style={{maxWidth: '35rem', marginBottom: '1rem'}}
            >
              {milestone.newLeadTags.map((mortgageTag) => (
                <CardButton key={mortgageTag.label} className="thin horizontal noHover primaryDark fitContentWidth" tabIndex="-1">
                  <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem'}} />
                  <Typography className="fitContentHeight">
                    {mortgageTag.label}
                  </Typography>
                </CardButton>
              ))}
            </FlexWrapper>
          </FlexWrapper>
        }
        {milestone.newMortgageTags && milestone.newMortgageTags.length > 0 &&
          <FlexWrapper className="column fitContentHeight">
            <Typography className="fitContentHeight justifyEnd" style={{width: '14rem', margin: '1rem 0 0.5rem 0'}}>
              {milestone.newMortgageTags.length > 1 ?
                `New Mortgage Tags:`
              :
                `New Mortgage Tag:`
              }
            </Typography>
            <FlexWrapper 
              className="flexWrap justifyCenter"
              style={{maxWidth: '35rem', marginBottom: '1rem'}}
            >
              {milestone.newMortgageTags.map((mortgageTag) => (
                <CardButton key={mortgageTag.label} className="thin horizontal noHover primaryDark fitContentWidth" tabIndex="-1">
                  <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem'}} />
                  <Typography className="fitContentHeight">
                    {mortgageTag.label}
                  </Typography>
                </CardButton>
              ))}
            </FlexWrapper>
          </FlexWrapper>
        }
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default ScanPerformed