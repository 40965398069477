import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    teamMembersInfo: [{
      memberId: '',
      dateCreated: '',
      role: '',
      email: '',
      fullName: '',
      assignedMortgagesNum: 0,
      assignedLeadsNum: 0,
      awaitingActionLeadsNum: 0,
      investigatingLeadsNum: 0,
      closingLeadsNum: 0,
      awaitingUpdateLeadsNum: 0,
      awaitingVerificationLeadsNum: 0,
      lastLoginDate: '',
    }],
    teamMembersMonthlyStats: [{
      memberId: '',
      memberMonthlyStats: [],
    }],
    teamMembersByName: [{
      memberId: '',
      role: '',
      firstName: '',
      fullName: '',
    }],
  },
  status: 'idle',
  error: null
}

export const teamMembersSlice = createSlice({
  name: 'teamMembers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.teamMembersData
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.returnMemberMonthlyStats.length; i++) {
        let thisMember = state.data.teamMembersMonthlyStats.find(member => member.memberId === payload.data.returnMemberMonthlyStats[i].belongsToUser.toString())
        let thisMemberIndex = state.data.teamMembersMonthlyStats.indexOf(thisMember)
        let thisMonthlyStat = thisMember.memberMonthlyStats.find(monthlyStat => monthlyStat.sessionParsed === payload.data.returnMemberMonthlyStats[i].sessionParsed)
        if (thisMonthlyStat) {
          let thisMonthlyStatIndex = thisMember.memberMonthlyStats.indexOf(thisMonthlyStat)
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats[thisMonthlyStatIndex] = payload.data.returnMemberMonthlyStats[i]
        } else {
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats.splice(23, 1, (payload.data.returnMemberMonthlyStats[i]))
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      for (let i = 0; i < payload.data.returnMemberMonthlyStats.length; i++) {
        let thisMember = state.data.teamMembersMonthlyStats.find(member => member.memberId === payload.data.returnMemberMonthlyStats[i].belongsToUser.toString())
        let thisMemberIndex = state.data.teamMembersMonthlyStats.indexOf(thisMember)
        let thisMonthlyStat = thisMember.memberMonthlyStats.find(monthlyStat => monthlyStat.sessionParsed === payload.data.returnMemberMonthlyStats[i].sessionParsed)
        if (thisMonthlyStat) {
          let thisMonthlyStatIndex = thisMember.memberMonthlyStats.indexOf(thisMonthlyStat)
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats[thisMonthlyStatIndex] = payload.data.returnMemberMonthlyStats[i]
        } else {
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats.splice(23, 1, (payload.data.returnMemberMonthlyStats[i]))
        }
      }
    })
  }
})

export default teamMembersSlice.reducer

export const selectTeamMembersInfo = state => state.teamMembers.data.teamMembersInfo
export const selectTeamMembersByName = state => state.teamMembers.data.teamMembersByName
export const selectTeamMembersMonthlyStats = state => state.teamMembers.data.teamMembersMonthlyStats