import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useChangeTargetOutcomeMutation } from "../../../../slices/api/apiSlice";
import { selectEditLeadTargetOutcomePerm } from "../../../../slices/userActionsPermissionsSlice";
import { SubHeader, Button, ButtonWrapper, ComponentHeader, ModalContentContainer, Typography } from "../../../../StyledComponents";

const ChangeTargetOutcome = ({ userId, userFullName, dashPerspective, handleCloseModal, leadData }) => {
  const theme = useTheme();

  const editLeadTargetOutcomePerm = useSelector(selectEditLeadTargetOutcomePerm)

  const [changeOutcome, {isFetching, isSuccess, isError}] = useChangeTargetOutcomeMutation()

  const handleChangeTargetOutcome = () => {
    let newOutcome = ''
    if (leadData.targetOutcome === 'renegotiation') {
      newOutcome = 'refinance'
    } else {
      newOutcome = 'renegotiation'
    }
    changeOutcome({
      userId: userId,
      dashPerspective: dashPerspective,
      timeline: leadData.timeline,
      userFullName: userFullName,
      newOutcome: newOutcome,
      leadId: leadData._id,
      userNotifications: leadData.userNotifications,
    })
  }

  return (
      <>
        {isSuccess ?
          <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
        : isError ?
          <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.</ComponentHeader>
        : leadData.targetOutcome === "unassigned" ?
          <ComponentHeader className="modal">
            <Typography className="xLarge">
              Assign Target Outcome
            </Typography>
          </ComponentHeader>
        :
          <ComponentHeader className="modal">
            <Typography className="xLarge">
              Change Target Outcome
            </Typography>
          </ComponentHeader>
        }

        <ModalContentContainer className="centerColumn leadActions">
          <SubHeader className="modal" style={{margin: '0 auto 8rem auto'}}>
            <Typography className="small">
              {isSuccess ?
                `The investigation outcome has been set to a ${leadData.targetOutcome === 'renegotiation' ? 'renegotiation' : 'refinance'}`
              : leadData.targetOutcome === 'unassigned' ?
                `Select the intended outcome of this investigation.`
              :
                `Change the target outcome of this investigation to a ${leadData.targetOutcome === 'renegotiation' ? 'refinance' : 'renegotiation'}?`
              }
            </Typography>
          </SubHeader>
          {isSuccess || isError ?
            <ButtonWrapper>
              <Button onClick={() => handleCloseModal()} className="gray">Close</Button>
            </ButtonWrapper>
          :
            <ButtonWrapper>
              <Button onClick={() => handleCloseModal()} className="gray">Close</Button>
              {isFetching ?
                <Button className="primaryLoading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.common.cardBackground}` }}
                    size={13}
                  />
                </Button>
              :
                <Button onClick={editLeadTargetOutcomePerm ? () => handleChangeTargetOutcome() : null} className={editLeadTargetOutcomePerm ? "primary" : "primaryDisabled"}>
                  Confirm
                </Button>
              }         
            </ButtonWrapper>
          }
        </ModalContentContainer>
      </>
  )
}

export default ChangeTargetOutcome;