import React from "react";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PendingIcon from '@mui/icons-material/Pending';
import { useTheme } from "@mui/styles";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { MilestoneConnector, MilestonePathContainer } from "../../../../StyledComponents";

const LeadPathConnectors = ({milestone, mapIndex, timeline, origin}) => {
  const theme = useTheme()

  return (
    <>
      {(milestone.milestone === 'Public Records Updated' && milestone.activeDiscrepancies === 0) || milestone.milestone === 'All Discrepancies Resolved' ?
        <MilestonePathContainer>
          {mapIndex !== 0 ?
            <MilestoneConnector className='small' style={{height: milestone.notify ? '0.9rem' : '0.5rem'}} />
          :
            <></>
          }
          <TaskAltIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : '', margin: mapIndex !== 0 ? '0' : '0.5rem 0 0 0'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
      : milestone.milestone === 'Discrepancies Detected' || (milestone.milestone.includes('Manual') && milestone.milestone.includes('Investigation')) || milestone.milestone === 'Internal Records Updated' || milestone.milestone === 'Manual Renegotiation Investigation Opened' ?
        <MilestonePathContainer>
          {mapIndex !== 0 &&
            <MilestoneConnector className='small' style={{height: milestone.notify ? '0.9rem' : '0.5rem'}} />
          }
          <div style={{margin: mapIndex !== 0 ? '0 0 0 0' : '0.5rem 0 0 0'}}>
            {milestone.milestone === 'Internal Records Updated' ?
              <PendingIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
            :
              <FlagCircleIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
            }
          </div>
        </MilestonePathContainer>
      : milestone.milestone.includes('Finalized') || milestone.milestone === 'Lead Dismissed' ?
        <MilestonePathContainer>
          {mapIndex !== 0 ?
            <MilestoneConnector className='small' style={{height: milestone.notify ? '0.9rem' : '0.5rem'}} />
          :
            <></>
          }
          {milestone.awaitingUpdate || mapIndex !== 0 ?
            <RadioButtonCheckedIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : '', marginTop: (mapIndex === 0 && milestone.notify) ? '0.7rem' : mapIndex === 0 ? '0.5rem' : ''}} />
          :
            <SportsScoreIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : '', marginTop: (mapIndex === 0 && milestone.notify) ? '0.7rem' : mapIndex === 0 ? '0.5rem' : ''}} />
          }
          <MilestoneConnector />
        </MilestonePathContainer>
      : milestone.milestone === 'Public Records Verified' ?
        <MilestonePathContainer>
          <SportsScoreIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : '', margin: '0.5rem 0 0 0'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
      :
        <MilestonePathContainer>
          {mapIndex !== 0 ?
            <MilestoneConnector className='small' style={{height: milestone.notify ? '0.9rem' : '0.5rem'}} />
          :
            <></>
          }
          <RadioButtonCheckedIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : '', marginTop: (mapIndex === 0 && milestone.notify) ? '0.7rem' : mapIndex === 0 ? '0.5rem' : ''}} />
          <MilestoneConnector />
        </MilestonePathContainer>
      }
    </>
  )
}

export default LeadPathConnectors