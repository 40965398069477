import React from "react"
import { useTheme } from "@mui/styles";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { CardButton, ColumnContent, ComponentSectionRow, Detail, Divider, FlexWrapper, RowsContainer, ScrollWindow, Typography } from "../../StyledComponents";

const ParameterQueryAndPerformanceStats = ({ mortgageDataModel }) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column">
      <Divider className="darkGray" />
      <Typography className="subHeader medium alignTextCenter" style={{margin: '1rem auto'}}>
        "Parameters" are the data points used to discover changes to property or ownership status.<br/>
        When "Active", a discrepancy found in a given parameter will trigger an Investigation.<br/>
        The table below shows how each one is performing in monitoring your data.
      </Typography>
      <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 22.2rem)'}}>
        <ComponentSectionRow className="header gray" style={{display: 'grid', gridTemplateColumns: `25rem 0.1rem 5rem 0.1rem 10rem 0.1rem 8rem 0.1rem 8rem 0.1rem 9rem 0.1rem 6rem 0.1rem 10rem 0.1rem 10rem 0.1rem 9rem 0.1rem 9rem 0.1rem`, padding: '0 0 0 2rem', borderTop: `0.1rem solid ${theme.palette.common.grayText}`}}>
          <ColumnContent className="header center" style={{gridColumn: '1'}}>
            label
          </ColumnContent>
          <Divider style={{gridColumn: '2', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '3'}}>
            active
          </ColumnContent>
          <Divider style={{gridColumn: '4', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '5'}}>
            total queries
          </ColumnContent>
          <Divider style={{gridColumn: '6', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '7'}}>
            populated
          </ColumnContent>
          <Divider style={{gridColumn: '8', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '9'}}>
            no backup
          </ColumnContent>
          <Divider style={{gridColumn: '10', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '11'}}>
            backup only
          </ColumnContent>
          <Divider style={{gridColumn: '12', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '13'}}>
            empty
          </ColumnContent>
          <Divider style={{gridColumn: '14', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '15'}}>
            discrepancies
          </ColumnContent>
          <Divider style={{gridColumn: '16', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '17'}}>
            renegotiations
          </ColumnContent>
          <Divider style={{gridColumn: '18', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '19'}}>
            refinances
          </ColumnContent>
          <Divider style={{gridColumn: '20', height: '100%', background: `${theme.palette.common.grayText}`}} />
          <ColumnContent className="header center" style={{gridColumn: '21'}}>
            dismissals
          </ColumnContent>  
          <Divider style={{gridColumn: '22', height: '100%', background: `${theme.palette.common.grayText}`}} />
        </ComponentSectionRow>
        <RowsContainer style={{height: 'calc(100vh - 20.1rem)'}}>
          {mortgageDataModel.map((category, index) => (
            <div key={category.subCat}>
              <FlexWrapper className="fitContentHeight" style={{padding: '0.4rem 0 0.4rem 2rem', background: `${theme.palette.common.grayBorder}`, borderTop: index !== 0 ? `0.1rem solid ${theme.palette.common.grayText}` : 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
                <Detail className="timelineHeader">{category.subCat}</Detail>
              </FlexWrapper>
              <FlexWrapper className="column" style={{height: 'fit-content'}}>
                {category.fields.map((sweepParameter, index) => (
                  <ComponentSectionRow key={sweepParameter._id} style={{padding: index === 0 ? '0.6rem 0 0.2rem 2rem' : index === (category.fields.length - 1) ? '0.2rem 0 0.6rem 2rem' : '0.2rem 0 0.2rem 2rem', background: index%2 === 1 ? `${theme.palette.common.grayBorder}` : '', display: 'grid', gridTemplateColumns: `25rem 5rem 10rem 8rem 8rem 9rem 6rem 10rem 10rem 9rem 9rem`}}>
                    <CardButton className={(sweepParameter.assignedTier === 'two' ? 'two' : sweepParameter.assignedTier === 'one' ? 'one' : 'three') + ' disabled'} style={{width: 'fit-content', gridColumn: '1', padding: '0.1rem 0.6rem', minHeight: 'fit-content'}} >
                      {sweepParameter.label.includes('Primary') ?
                        <>
                          {sweepParameter.label.replace('Primary', '').toUpperCase()}
                        </>
                      : sweepParameter.label.includes('Secondary') ?
                        <>
                          {sweepParameter.label.replace('Secondary', '').toUpperCase()}
                        </>
                      :
                        sweepParameter.label.toUpperCase()
                      }
                    </CardButton>
                    <Detail className="center" style={{gridColumn: '2'}}>
                      {sweepParameter.active ?
                          <RadioButtonCheckedIcon style={{color: `${theme.palette.primary.main}`}} />
                        :
                          <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorder}`}} />
                      }
                    </Detail>
                    <Detail className="center" style={{gridColumn: '3'}}>{sweepParameter.totalQueries}</Detail>

                    {sweepParameter.populated > 0 ?
                      <Detail className="center" style={{gridColumn: '4', color: `${theme.palette.primary.main}`, fontWeight: '600'}}>
                        {sweepParameter.populated} {( '(' + ((sweepParameter.populated/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                      </Detail>
                    :
                      <Detail className="center" style={{gridColumn: '4', color: '', fontWeight: '400'}}>-</Detail>
                    }
                    {sweepParameter.noBackup > 0 ?
                      <Detail className="center" style={{gridColumn: '5', color: `${theme.palette.common.orange}`, fontWeight: '600'}}>
                        {sweepParameter.noBackup} {( '(' + ((sweepParameter.noBackup/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                      </Detail>
                    :
                      <Detail className="center" style={{gridColumn: '5', color: '', fontWeight: '400'}}>-</Detail>
                    }
                    {sweepParameter.backupOnly > 0 ?
                      <Detail className="center" style={{gridColumn: '6', color: `${theme.palette.common.orange}`, fontWeight: '600'}}>
                        {sweepParameter.backupOnly} {( '(' + ((sweepParameter.backupOnly/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                      </Detail>
                    :
                      <Detail className="center" style={{gridColumn: '6', color: '', fontWeight: '400'}}>-</Detail>
                    }
                    {sweepParameter.empty > 0 ?
                      <Detail className="center" style={{gridColumn: '7', color: `${theme.palette.common.redDark}`, fontWeight: '600'}}>
                        {sweepParameter.empty} {( '(' + ((sweepParameter.empty/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                      </Detail>
                    :
                      <Detail className="center" style={{gridColumn: '7', color: '', fontWeight: '400'}}>-</Detail>
                    }
                    {sweepParameter.discrepancies > 0 ?
                      <Detail className="center" style={{gridColumn: '8', fontWeight: '600'}}>
                        {sweepParameter.discrepancies} {( '(' + ((sweepParameter.discrepancies/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                      </Detail>
                    :
                      <Detail className="center" style={{gridColumn: '8', color: '', fontWeight: '400'}}>-</Detail>
                    }                  
                    <Detail className="center" style={{gridColumn: '9', fontWeight: sweepParameter.renegotiations > 0 ? '600' : '400'}}>{sweepParameter.renegotiations}</Detail>
                    <Detail className="center" style={{gridColumn: '10', fontWeight: sweepParameter.refinances > 0 ? '600' : '400'}}>{sweepParameter.refinances}</Detail>
                    <Detail className="center" style={{gridColumn: '11', fontWeight: sweepParameter.dismissals > 0 ? '600' : '400'}}>{sweepParameter.dismissals}</Detail>
                  </ComponentSectionRow>
                ))}
              </FlexWrapper>
            </div>
          ))}
        </RowsContainer>
      </ScrollWindow>
    </FlexWrapper>
  )
}

export default ParameterQueryAndPerformanceStats;