import React, { forwardRef, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-csv-importer/dist/index.css';
import "../../../../../styles.css";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from "@mui/icons-material/Error";
import DatePicker from 'react-datepicker';
import { useTheme } from "@mui/material";
import {addMonths, subMonths} from 'date-fns';
import { ErrorWrapper, StyledInputElement, FlexWrapper, Typography, Divider, InlineLink } from "../../../../../StyledComponents";
import { useChangeTargetOutcomeMutation } from "../../../../../slices/api/apiSlice";


const FinalizeForm = ({ setStartDate, startDate, statusTerm, mortgageTermValueStr, setStatusTerm, setMortgageTermValueStr, setMortgageTermValue, statusPrincipal, principalValueStr, setStatusPrincipal, setPrincipalValueStr, setPrincipalValue, statusInterest, setStatusInterest, setInterestValueStr, setInterestValue, interestValueStr, termRequired, interestRequired, principalRequired, dateRequired, setTermRequired, setDateRequired, setInterestRequired, setPrincipalRequired, leadData, setIsFetching, userFullName, dashPerspective, userId, termLocked, dateLocked, interestLocked, principalLocked }) => {
  const theme = useTheme()

  const updatedTerm = useRef()
  const updatedInterest = useRef()
  const updatedPrincipal = useRef()

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="styled-calendar-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ))

  const [changeOutcome] = useChangeTargetOutcomeMutation()

  const handleChangeTargetOutcome = () => {
    setIsFetching(true)
    changeOutcome({
      userId: userId,
      dashPerspective: dashPerspective,
      timeline: leadData.timeline,
      userFullName: userFullName,
      newOutcome: 'refinance',
      leadId: leadData._id,
      userNotifications: leadData.userNotifications,
    })
    setIsFetching(false)
  }

  return (
    <FlexWrapper className="column fitContentHeight alignCenter" style={{padding: '2rem 4rem'}}>
      <Typography className="xLarge" style={{marginRight: 'auto'}}>
        Mortgage Details
      </Typography>
      <FlexWrapper className="justifyBetween" style={{width: '70rem', padding: '1rem 4rem 2rem 0rem'}}>
        <FlexWrapper className="column justifyBetween alignEnd" style={{width: '13rem', height: '6rem', marginTop: 'auto'}}>
          <FlexWrapper className='column justifyEnd alignEnd'>
            {dateRequired && (
              <ErrorWrapper className="caution fitContentWidth">
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Required
              </ErrorWrapper>
            )}
            <Typography className="fitContentHeight">
              Mortgage Start Date
            </Typography>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.label.color}`,
                backgroundColor: `${theme.tooltip.label.background}`,
                border: `0.1rem solid ${theme.tooltip.label.border}`,
                borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                opacity: '0',
                borderRadius: '1rem',
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: '1rem 2rem',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 10],
                },
              }]}}
              title={dateLocked ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Switch to a "Refinance" to<br/>
                    make changes to the original terms
                  </Typography>
                </FlexWrapper>
              : null} 
              placement="top"
              arrow
            >
              <FlexWrapper className="column justifyEnd fitContentWidth" style={{height: '2.5rem'}}>
                <FlexWrapper className="fitContentHeight">
                  <DatePicker 
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date)
                      setDateRequired(false)
                    }}
                    disabled={dateLocked}
                    maxDate={addMonths(new Date(), 12)}
                    minDate={subMonths(new Date(), 360)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={10}
                    withPortal
                    portalId="root-portal"
                    customInput={<ExampleCustomInput />}
                    dayClassName={() => "example-datepicker-day-class"}
                    calendarClassName="example-datepicker-class"
                    todayButton="CLICK FOR TODAY'S DATE"
                  />
                </FlexWrapper>
              </FlexWrapper>
            </Tooltip>
          </FlexWrapper>
        </FlexWrapper>

        <FlexWrapper className="column justifyBetween alignEnd" style={{width: '13rem', height: '6rem'}}>
          <FlexWrapper className='column justifyEnd alignEnd'>
            {(termRequired && !statusTerm) && (
              <ErrorWrapper className="caution fitContentWidth">
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Required
              </ErrorWrapper>
            )}
            {statusTerm === "nonNumber" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Must be a number
              </ErrorWrapper>
            )}
            {statusTerm === "negative" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be negative
              </ErrorWrapper>
            )}
            {statusTerm === "zero" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be zero
              </ErrorWrapper>
            )}
            {statusTerm === "large" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                More than 100
              </ErrorWrapper>
            )}
            <Typography className="fitContentHeight">
              Mortgage Term
            </Typography>
          </FlexWrapper>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.tooltip.label.color}`,
              backgroundColor: `${theme.tooltip.label.background}`,
              border: `0.1rem solid ${theme.tooltip.label.border}`,
              borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
              opacity: '0',
              borderRadius: '1rem',
              width: 'fit-content',
              minWidth: 'fit-content',
              padding: '1rem 2rem',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 10],
              },
            }]}}
            title={termLocked ?
              <FlexWrapper className="column">
                <Typography className="bold alignTextCenter">
                  Switch to a "Refinance" to<br/>
                  make changes to the original terms
                </Typography>
              </FlexWrapper>
            : null} 
            placement="top"
            arrow
          >
            <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem'}}>
              <StyledInputElement className="borderBottomOnly" style={{width: '3rem', padding: '0', height: 'fit-content', textAlign: 'end'}}
                value={mortgageTermValueStr}
                type="text"
                name="term"
                id="term"
                disabled={termLocked}
                ref={updatedTerm}
                onChange={() => {
                  if (isNaN(parseFloat(updatedTerm.current.value))) {
                    setStatusTerm("nonNumber")
                  } else if (Math.sign(parseFloat(updatedTerm.current.value)) === -1) {
                    setStatusTerm("negative")
                  } else if (Math.sign(parseFloat(updatedTerm.current.value)) === 0) {
                    setStatusTerm("zero")
                  } else if (parseFloat(updatedTerm.current.value) > 100) {
                    setStatusTerm("large")
                  } else if (statusTerm === "nonNumber" || statusTerm === "negative" || statusTerm === "zero" || statusTerm === "large" || termRequired) {
                    setStatusTerm(null)
                    setTermRequired(false)
                  }
                  setMortgageTermValueStr(updatedTerm.current.value.toString())
                  setMortgageTermValue(updatedTerm.current.value)
                }}
              />
              <span style={{margin: 'auto 0 0 0.5rem'}}>years</span>
            </FlexWrapper>
          </Tooltip>
        </FlexWrapper>

        <FlexWrapper className="column justifyBetween alignEnd" style={{width: '13rem', height: '6rem'}}>
          <FlexWrapper className='column justifyEnd alignEnd'>
            {(principalRequired && !statusPrincipal) && (
              <ErrorWrapper className="caution fitContentWidth">
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Required
              </ErrorWrapper>
            )}
            {statusPrincipal === "nonNumber" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Must be a number
              </ErrorWrapper>
            )}
            {statusPrincipal === "negative" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be negative
              </ErrorWrapper>
            )}
            {statusPrincipal === "zero" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be zero
              </ErrorWrapper>
            )}
            {statusPrincipal === "large" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Larger than 100m
              </ErrorWrapper>
            )}
            <Typography className="fitContentHeight">
              Loan Amount
            </Typography>
          </FlexWrapper>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.tooltip.label.color}`,
              backgroundColor: `${theme.tooltip.label.background}`,
              border: `0.1rem solid ${theme.tooltip.label.border}`,
              borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
              opacity: '0',
              borderRadius: '1rem',
              width: 'fit-content',
              minWidth: 'fit-content',
              padding: '1rem 2rem',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 10],
              },
            }]}}
            title={principalLocked ?
              <FlexWrapper className="column">
                <Typography className="bold alignTextCenter">
                  Switch to a "Refinance" to<br/>
                  make changes to the original terms
                </Typography>
              </FlexWrapper>
            : null} 
            placement="top"
            arrow
          >
            <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem'}}>
              <span style={{color: `${theme.palette.primary.main}`, fontWeight: '600', padding: '0 0 0.1rem 0'}}>$</span>
              <StyledInputElement className="borderBottomOnly" style={{width: '7.5rem', height: 'fit-content', padding: '0 0 0 0.3rem'}}
                value={principalValueStr ? principalValueStr : ""}
                type="text"
                name="principal"
                id="principal"
                ref={updatedPrincipal}
                disabled={principalLocked}
                onChange={() => {
                  if (!/^[0-9,.]*$/.test(updatedPrincipal.current.value)) {
                    setStatusPrincipal("nonNumber")
                  } else if (Math.sign(parseFloat(updatedPrincipal.current.value)) === -1) {
                    setStatusPrincipal("negative")
                  } else if (Math.sign(parseFloat(updatedPrincipal.current.value)) === 0) {
                    setStatusPrincipal("zero")
                  } else if (parseFloat(updatedPrincipal.current.value) > 100000000) {
                    setStatusPrincipal("large")
                  } else if (statusPrincipal === "nonNumber" || statusPrincipal === "negative" || statusPrincipal === "zero" || statusPrincipal === "large" || principalRequired) {
                    setStatusPrincipal(null)
                    setPrincipalRequired(false)
                  }
                  setPrincipalValueStr(updatedPrincipal.current.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
                  setPrincipalValue(parseFloat(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')))
                }}
              />
            </FlexWrapper>
          </Tooltip>
        </FlexWrapper>

        <FlexWrapper className="column justifyBetween alignEnd" style={{width: '13rem', height: '6rem'}}>
          <FlexWrapper className='column justifyEnd alignEnd'>
            {(interestRequired && !statusInterest) && (
              <ErrorWrapper className="caution fitContentWidth">
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Required
              </ErrorWrapper>
            )}
            {statusInterest === "nonNumber" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Must be a number
              </ErrorWrapper>
            )}
            {statusInterest === "negative" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be negative
              </ErrorWrapper>
            )}
            {statusInterest === "zero" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be zero
              </ErrorWrapper>
            )}
            {statusInterest === "large" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                More than 100
              </ErrorWrapper>
            )}
            <Typography className="fitContentHeight">
              Interest Rate
            </Typography>
          </FlexWrapper>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.tooltip.label.color}`,
              backgroundColor: `${theme.tooltip.label.background}`,
              border: `0.1rem solid ${theme.tooltip.label.border}`,
              borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
              opacity: '0',
              borderRadius: '1rem',
              width: 'fit-content',
              minWidth: 'fit-content',
              padding: '1rem 2rem',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 10],
              },
            }]}}
            title={interestLocked ?
              <FlexWrapper className="column">
                <Typography className="bold alignTextCenter">
                  Switch to a "Refinance" to<br/>
                  make changes to the original terms
                </Typography>
              </FlexWrapper>
            : null} 
            placement="top"
            arrow
          >
            <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem'}}>
              <StyledInputElement className="borderBottomOnly" style={{width: '3rem', padding: '0', height: 'fit-content', textAlign: 'end'}}
                value={interestValueStr}
                type="text"
                name="interest"
                id="interest"
                disabled={interestLocked}
                ref={updatedInterest}
                onChange={() => {
                  if (isNaN(parseFloat(updatedInterest.current.value))) {
                    setStatusInterest("nonNumber")
                  } else if (Math.sign(parseFloat(updatedInterest.current.value)) === -1) {
                    setStatusInterest("negative")
                  } else if (Math.sign(parseFloat(updatedInterest.current.value)) === 0) {
                    setStatusInterest("zero")
                  } else if (parseFloat(updatedInterest.current.value) > 100) {
                    setStatusInterest("large")
                  } else if (statusInterest === "nonNumber" || statusInterest === "negative" || statusInterest === "zero" || statusInterest === "large" || interestRequired) {
                    setStatusInterest(null)
                    setInterestRequired(false)
                  }
                  setInterestValueStr(updatedInterest.current.value)
                  setInterestValue(parseFloat(updatedInterest.current.value))
                }}
              />
              <span style={{margin: 'auto 0 0 0.5rem'}}>%</span>
            </FlexWrapper>
          </Tooltip>
        </FlexWrapper>
      </FlexWrapper>
      <Divider className="darkGray" style={{width: '90%'}} />
      {leadData.targetOutcome === 'renegotiation' ?
        <Typography className="alignCenter" style={{marginTop: '0.5rem'}}>
          If you'd like to make changes to the original terms on record,
          <InlineLink onClick={() => handleChangeTargetOutcome()}>click here</InlineLink>
          to change the outcome to a refinance
        </Typography>
      : <></>
      }
    </FlexWrapper>
  )
}

export default FinalizeForm