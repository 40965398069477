import { styled, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import MuiToggleButton from '@mui/material/ToggleButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { DataGrid, gridClasses } from "@mui/x-data-grid";

const teamDropdownStyles = makeStyles((theme) => ({
  formControl: {
    gridColumn: 2,
    "& .MuiInputBase-root": {
      borderRadius: "0",
      color: `${theme.panel.text.primary}`,
      width: "fit-content",
      height: "3rem",
      paddingLeft: "3.2rem"
    },
    "& .Mui-disabled svg": {
      color: `${theme.panel.background.secondary}`,
    },
    "& .MuiSelect-select.MuiSelect-select": {
      minHeight: "3rem",
      display: "flex",
      alignItems: "center",
      height: 'fit-content',
      fontSize: "1.4rem",
      fontWeight: "600",
    },
    "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    },
  },
  select: {
    fontSize: "1.0rem",
    color: `${theme.panel.text.primary}`,
    padding: "0",
    display: "flex",
    justifyContent: "center",
    maxHeight: "2rem"
  },
  selectIcon: {
    color: `${theme.panel.text.primary}`,
  },
  paper: {
    background: `${theme.palette.common.cardBackground}`,
    marginTop: "0.5rem",
  },
  list: {
    maxHeight: "20rem",
    "& li": {
      display: 'flex',
      background: `${theme.palette.common.cardBackground}`,
      color: `${theme.panel.text.primary}`,
    },
    "& li:hover": {
      background: `${theme.palette.common.grayHover}`,
    },
    "& li.Mui-selected": {
      background: `${theme.palette.common.purpleHover}`,
    },
  }
}))
const dropdownStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    gridColumn: 2,
    "& .MuiInputBase-root": {
      borderRadius: "0",
      borderBottom: `0.1rem solid ${theme.dropdown.border.primary}`,
      color: `${theme.dropdown.text.primary}`,
      width: "100%",
      height: "3rem",
      paddingRight: "0rem",
    },
    "& .Mui-disabled svg": {
      color: `${theme.dropdown.icon.primary}`,
    },
    "& .MuiSelect-select.MuiSelect-select": {
      minHeight: "3rem",
      display: "flex",
      alignItems: "center",
      height: 'fit-content',
      fontSize: "1rem",
      fontWeight: "400",
    },
    "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    },
  },
  select: {
    fontSize: "1.0rem",
    color: `${theme.palette.common.black}`,
    padding: "0",
    display: "flex",
    justifyContent: "center",
    maxHeight: "2rem"
  },
  selectIcon: {
    color: `${theme.palette.primary.dark}`,
  },
  paper: {
    background: `${theme.dropdown.paperBackground.primary}`,
    marginTop: "0.5rem",
  },
  list: {
    maxHeight: "20rem",
    "& li": {
      display: 'flex',
      background: `${theme.palette.common.cardBackground}`,
      color: `${theme.palette.common.black}`,
    },
    "& li:hover": {
      background: `${theme.palette.common.grayHover}`,
    },
    "& li.Mui-selected": {
      background: `${theme.palette.common.purpleHover}`,
    },
  }
}))
const dropdownStylesThin = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    gridColumn: 2,
    "& .MuiInputBase-root": {
      borderRadius: "0",
      borderBottom: `0.1rem solid ${theme.dropdown.border.primary}`,
      color: `${theme.dropdown.text.primary}`,
      width: "100%",
      height: "2rem",
      paddingRight: "0rem",
      marginTop: '0.5rem',
    },
    "& .Mui-disabled svg": {
      color: `${theme.dropdown.icon.primary}`,
    },
    "& .MuiSelect-select.MuiSelect-select": {
      minHeight: "2rem",
      display: "flex",
      alignItems: "center",
      height: 'fit-content',
      fontSize: "1rem",
      fontWeight: "400",
    },
    "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    },
  },
  select: {
    fontSize: "1.0rem",
    color: `${theme.palette.common.black}`,
    padding: "0",
    display: "flex",
    justifyContent: "center",
    maxHeight: "2rem"
  },
  selectIcon: {
    color: `${theme.palette.primary.dark}`,
  },
  paper: {
    background: `${theme.dropdown.paperBackground.primary}`,
    marginTop: "0.5rem",
  },
  list: {
    maxHeight: "20rem",
    "& li": {
      display: 'flex',
      background: `${theme.palette.common.cardBackground}`,
      color: `${theme.palette.common.black}`,
    },
    "& li:hover": {
      background: `${theme.palette.common.grayHover}`,
    },
    "& li.Mui-selected": {
      background: `${theme.palette.common.purpleHover}`,
    },
  }
}))

const dropdownMenuProps = {
  classes: {
    list: dropdownStyles.list,
    paper: dropdownStyles.paper
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
};

const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    color: `${theme.palette.common.white}`,
    backgroundColor: `${theme.palette.common.black}`,
    fontSize: "2rem",
    fontWeight: '600',
    margin: '0',
    padding: '0',
  },
}))
const tooltipComponentProps = {
  tooltip: {sx:tooltipStyles.tooltip}
}
const tooltipPopperProps = {
  modifiers: [{
    name: "offset",
    options: {
      offset: [0, -8],
    },
  }]
}

//* Error Tooltip
const errorTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    color: `${theme.tooltip.info.color}`,
    backgroundColor: `${theme.tooltip.info.background}`,
    border: `0.3rem solid ${theme.tooltip.info.border}`,
    borderBottom: `0.2rem solid ${theme.tooltip.info.border}`,
    opacity: '0',
  },
}))
const errorTooltipComponentProps = {
  tooltip: {sx:errorTooltipStyles.tooltip}
}
const errorTooltipPopperProps = {
  modifiers: [{
    name: "offset",
    options: {
      offset: [0, -1],
    },
  }]
}

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  borderRadius: "0.6rem",
  border: `0.1rem solid ${theme.palette.common.black}`,
  color: `${theme.palette.common.black}`,
  fontSize: "1rem",
  fontFamily: "IBM Plex Sans, sans-serif",
  fontWeight: "400",
  lineHeight: "1.5",
  padding: "0.4rem",
  '&:hover': {
    outline: `1px solid ${theme.palette.primary.medium}`
  },
  '&:focus': {
    outline: `1px solid ${theme.palette.primary.main}`
  }
}));  

const StyledToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  width: "100",
  padding: "0.5rem 2rem",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.common.grayTextLight}`,
  color: `${theme.palette.common.grayTextLight}`,
  fontWeight: "600",
  "&.Mui-selected:hover": {
    background: `${theme.palette.primary.transparent}`,
  },
  "&.Mui-selected": {
    color: `${theme.palette.primary.dark}`,
    border: `1px solid ${theme.palette.primary.main}`,
    background: `${theme.palette.primary.transparent}`,
  }
}));  

const primaryRadioColorProps = () => ({
  sx: {
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    color: `#E3E5EB`, //grayBorder
    '&.Mui-checked': {
      color: `#00B812`, //primary.main
    },
  }
});
const grayUnselectedRadioColorProps = () => ({
  sx: {
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    color: `#939599`, //grayText
    '&.Mui-checked': {
      color: `#00B812`, //primary.main
    },
  }
});

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.common.pageBackground,
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.accent.success.primaryFullestTransparent, 0.1),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.accent.success.primaryFullestTransparent,
        0.1 + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.accent.success.primaryFullestTransparent,
          0.1 +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.accent.success.primaryFullestTransparent,
            0.1 + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.accent.success.primaryFullestTransparent, 0.1),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.accent.success.primaryFullestTransparent,
        0.1 + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.accent.success.primaryFullestTransparent,
          0.1 +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.accent.success.primaryFullestTransparent,
            0.1 + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

export { dropdownStyles, dropdownMenuProps, StyledTextArea, teamDropdownStyles, tooltipComponentProps, tooltipPopperProps, StyledToggleButton, primaryRadioColorProps, grayUnselectedRadioColorProps, StripedDataGrid, dropdownStylesThin, errorTooltipComponentProps, errorTooltipPopperProps };