import React from "react";
import SearchResults from "../../../../pages/Dashboard/components/PropertySearch/components/SearchResults";
import { ComponentHeader, ModalContentContainer, Typography } from "../../../../StyledComponents";

const PropertyDetails = ({ handleCloseModal, mortgageData }) => {

  return (
    <>
      <ComponentHeader className="modal">
        <Typography className="xLarge">
          Property Details
        </Typography>
        <Typography className="small" style={{margin: '0.2rem 0 0 0.5rem'}}>
          (as sourced from public records)
        </Typography>
      </ComponentHeader>
      <ModalContentContainer style={{gridRow: '3/6', overflow: 'hidden'}}>
        <SearchResults propertyDetails={mortgageData.recordDetails} mortgageData={mortgageData} origin={'repository'} />
      </ModalContentContainer>
    </>
  )
}

export default PropertyDetails;