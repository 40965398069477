import React from "react"
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from "@mui/styles";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ComponentSectionRow, Detail, Divider, FlexWrapper, ScrollWindow, TierButton, Typography } from "../../StyledComponents";

const ParameterPerformanceStats = ({ mortgageDataModel, handleOpenSweepParameterModal }) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column">
      <Divider className="darkGray" />
      <Typography className="subHeader medium alignTextCenter" style={{margin: '1rem auto'}}>
        "Parameters" are the data points used to discover changes to a mortgages underlying property.<br/>
        When "Active", a discrepancy found in a given data point will trigger an Investigation.<br/>
        The table below shows how each Parameter is performing in monitoring your data.
      </Typography>
      <ComponentSectionRow className="header gray" style={{height: '7.7rem', display: 'grid', gridTemplateColumns: `32rem 10rem 14rem 14rem 14rem 14rem 14rem 1fr 0.5rem`}}>
        <Typography className="header alignCenter justifyCenter" style={{height: '7.4rem', gridColumn: '1'}}>
          label
        </Typography>  
        <Typography className="header alignCenter justifyCenter" style={{height: '7.4rem', gridColumn: '2', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          <Typography className="alignCenter">
            active
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.info.color}`,
                backgroundColor: `${theme.tooltip.info.background}`,
                border: `0.8rem solid ${theme.tooltip.info.border}`,
                borderBottom: `0.3rem solid ${theme.tooltip.info.border}`,
                opacity: '0',
                width: '40rem',
                minWidth: '40rem',
                padding: '3rem 0',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={
                <FlexWrapper className="column">
                  <Typography className="justifyCenter alignCenter">
                    When "Active", discrepancies found in this field will trigger an investigation.
                  </Typography>
                  <Typography className="justifyCenter alignCenter" style={{marginTop: '0.3rem'}}>
                    Statistics are only recorded on active fields.
                  </Typography>
                </FlexWrapper>
              } 
              placement="top"
              arrow
            >
              <InfoIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.5rem'}} />
            </Tooltip>
          </Typography>
        </Typography>  
        <Typography className="header alignCenter" style={{height: '7.4rem', gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, paddingLeft: '0.8rem'}}>
          How often<br/> the field is found<br/> in Public Records
        </Typography>  
        <Typography className="header alignCenter" style={{height: '7.4rem', gridColumn: '4', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, paddingLeft: '0.8rem'}}>
          When Found,<br/>how often<br/>Discrepancies<br/>are present
        </Typography>  
        <Typography className="header alignCenter" style={{height: '7.4rem', gridColumn: '5', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, paddingLeft: '0.8rem'}}>
          When a Discrepancy,<br/>how often a<br/>Renegoitation<br/>results
        </Typography>  
        <Typography className="header alignCenter" style={{height: '7.4rem', gridColumn: '6', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, paddingLeft: '0.8rem'}}>
          When a Discrepancy,<br/>how often a<br/>Refinance<br/>results
        </Typography>  
        <Typography className="header alignCenter" style={{height: '7.4rem', gridColumn: '7', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, borderRight: `0.1rem solid ${theme.palette.common.grayText}`, paddingLeft: '0.8rem'}}>
          When a Discrepancy,<br/>how often a<br/>Dismissal<br/>results
        </Typography>  
      </ComponentSectionRow>
      <ScrollWindow className="verticalOnly config" style={{paddingBottom: '5rem', background: '#fff'}}>
        {mortgageDataModel.map((category, index) => (index !== 0 &&
          <div key={category.subCat}>
            <FlexWrapper className="fitContentHeight" style={{padding: '0.4rem 0 0.4rem 2rem', background: `${theme.palette.common.grayBorder}`, borderTop: index !== 1 ? `0.1rem solid ${theme.palette.common.grayText}` : 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
              <Detail className="timelineHeader">{category.subCat}</Detail>
            </FlexWrapper>
            <FlexWrapper className="column">
              {category.fields.map((sweepParameter, index) => (
                <ComponentSectionRow key={sweepParameter.label} className={index%2 === 1 ? 'list even' : 'list odd'} style={{padding: index === 0 ? '0.6rem 0 0.2rem 0' : index === (category.fields.length - 1) ? '0.2rem 0 0.6rem 0' : '0.2rem 0 0.2rem 0', display: 'grid', gridTemplateColumns: `32rem 10rem 14rem 14rem 14rem 14rem 14rem 1fr 0.5rem`, background: index%2 === 1 ? `${theme.component.row.even}` : `${theme.component.row.odd}`}}>
                  <TierButton 
                    className={sweepParameter.assignedTier === 'two' ? 'two' : sweepParameter.assignedTier === 'one' ? 'one' : 'three'} 
                    style={{width: 'fit-content', gridColumn: '1', margin: '0 0 0 3rem'}} 
                    onClick={() => handleOpenSweepParameterModal(sweepParameter)}
                  >
                    {sweepParameter.label.includes('Primary') ?
                      <>
                        {sweepParameter.label.replace('Primary', '').toUpperCase()}
                      </>
                    : sweepParameter.label.includes('Secondary') ?
                      <>
                        {sweepParameter.label.replace('Secondary', '').toUpperCase()}
                      </>
                    :
                      sweepParameter.label.toUpperCase()
                    }
                  </TierButton>
                  <Detail className="center" style={{gridColumn: '2'}}>
                    {sweepParameter.active ?
                        <RadioButtonCheckedIcon style={{color: `${theme.palette.primary.main}`}} />
                      :
                        <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorderMedium}`}} />
                    }
                  </Detail>
                  <Detail className="center" style={{gridColumn: '3', fontWeight: sweepParameter.populated > 0 ? '600' : '400'}}>
                    {sweepParameter.populated > 0 ?
                      ((((sweepParameter.populated)/sweepParameter.totalQueries) * 100).toFixed(0) + '%')
                    :
                      '-'
                    }
                  </Detail>
                  <Detail className="center" style={{gridColumn: '4', fontWeight: sweepParameter.discrepancies > 0 ? '600' : '400'}}>
                    {sweepParameter.discrepancies > 0 ?
                      (((sweepParameter.discrepancies/(sweepParameter.populated)) * 100).toFixed(0) + '%')
                    :
                      '-'
                    }
                  </Detail>
                  <Detail className="center" style={{gridColumn: '5', fontWeight: sweepParameter.renegoitation > 0 ? '600' : '400'}}>
                    {sweepParameter.renegoitation > 0 ?
                      (((sweepParameter.renegoitation/sweepParameter.discrepancies) * 100).toFixed(0) + '%')
                    :
                      '-'
                    }
                  </Detail>
                  <Detail className="center" style={{gridColumn: '6', fontWeight: sweepParameter.refinances > 0 ? '600' : '400'}}>
                    {sweepParameter.refinances > 0 ?
                      (((sweepParameter.refinances/sweepParameter.discrepancies) * 100).toFixed(0) + '%')
                    :
                      '-'
                    }
                  </Detail>
                  <Detail className="center" style={{gridColumn: '7', fontWeight: sweepParameter.dismissals > 0 ? '600' : '400'}}>
                    {sweepParameter.dismissals > 0 ?
                      (((sweepParameter.dismissals/sweepParameter.discrepancies) * 100).toFixed(0) + '%')
                    :
                      '-'
                    }
                  </Detail>
                </ComponentSectionRow>
              ))}
            </FlexWrapper>
          </div>
        ))}
      </ScrollWindow>
    </FlexWrapper>
  )
}

export default ParameterPerformanceStats;