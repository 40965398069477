import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useTheme } from "@emotion/react";
import MuiAccordion from '@mui/material/Accordion';
import DataBaseModal from "./Modal/DataBaseModal";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch } from "react-redux";
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { setDatabaseActionsNavMenuSelection } from "../../../../../slices/sessionDataSlice";
import { ActionIconWrapper, ColumnContent, ComponentWrapper, HeaderText, HorizontalContainer, VerticalContainer } from "../../../../../StyledComponents";

const Users = ({ userData, userId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [viewingUser, setViewingUser] = useState(null)
  const [openActionsModal, setOpenActionsModal] = useState(false)
  const handleOpenActionsModal = (user) => {
    setViewingUser(user)
    dispatch( setDatabaseActionsNavMenuSelection('view'))
    setOpenActionsModal(true)
  }
  const handleCloseActionsModal = () => {
    setOpenActionsModal(false)
  }

  return (
    <>
      <ComponentWrapper>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{border: 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{background: `${theme.palette.common.grayBorder}`}}>
            <HeaderText className="sub">Users</HeaderText>
          </AccordionSummary>
          <AccordionDetails style={{borderTop: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <HorizontalContainer className='fitContent'>
              <VerticalContainer className="fitContent">
                <ColumnContent className="bottomBorder">
                  actions
                </ColumnContent>
                {userData.map((data) => (
                  <ColumnContent key={data._id}>
                    <ActionIconWrapper className="hover" onClick={() => handleOpenActionsModal(data)}>
                      <OpenInNewIcon />
                    </ActionIconWrapper>
                  </ColumnContent>
                ))}
              </VerticalContainer>
            </HorizontalContainer>
          </AccordionDetails>
        </Accordion>
      </ComponentWrapper>

      <Dialog open={openActionsModal} onClose={handleCloseActionsModal}
        PaperProps={{ sx: {width: "100rem", minWidth: "100rem", maxWidth: "100rem", height: "fit-content", minHeight: "26rem", maxHeight: "95%"}}}
      >
        <DataBaseModal handleCloseActionsModal={handleCloseActionsModal} data={viewingUser} userId={userId} />
      </Dialog>
    </>
  )
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.1rem solid ${theme.palette.common.grayBorder}`,
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'none',
  flexDirection: 'row-reverse',
  minHeight: '3rem',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '1rem',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default Users;