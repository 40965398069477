import React, { useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from "react-redux";
import UserRoleDropdown from "../../../../components/DropDowns/UserRoleDropdown";
import { useSelectNewUserActionsPermissionsSetMutation } from "../../../../slices/api/apiSlice";
import { Button, ComponentBorder, ComponentContainer, Detail, DetailInfoContainer, DetailListContainer, FlexWrapper, GridWrapper, InputWrapper, Label, StatusAndLabelWrapper } from "../../../../StyledComponents";
import { setCurrentUserActionsPermissions } from "../../../../slices/userActionsPermissionsSlice";
import { setCurrentUserActionsRole } from "../../../../slices/sessionDataSlice";

const AppSettings = ({ currentUserActionsPermissions, currentUserActionsRole, userFullName }) => {

  const [selectedRole, setSelectedRole] = useState(currentUserActionsRole)
  const dispatch = useDispatch()

  const [selectNewUserActionsPermissionsSet, {}] = useSelectNewUserActionsPermissionsSetMutation()

  const handleSetSelectedRole = (event) => {
    setSelectedRole(event.target.value);
  }

  const handleSaveCurrentRole = async () => {
    let newPermissions = await selectNewUserActionsPermissionsSet({
      userFullName: userFullName,
      role: selectedRole,
    })
    if (newPermissions.data.message.includes('success')) {
      dispatch( setCurrentUserActionsPermissions(newPermissions.data.data.permissionsObj) )
      dispatch( setCurrentUserActionsRole(newPermissions.data.data.permissionsObj.belongsToRole) )
    }
  }

  return (
    <ComponentContainer style={{height: '100%'}}>
      <FlexWrapper className="column" style={{height: '100%'}}>
        <FlexWrapper className="column fitContentHeight" style={{width: '25rem'}}>
          <InputWrapper style={{margin: '0 auto'}}>
            <StatusAndLabelWrapper>
              <Label>Current User Permissions</Label>
            </StatusAndLabelWrapper>
            <UserRoleDropdown 
              handleChange={handleSetSelectedRole}
              value={selectedRole}
            />
          </InputWrapper>
          <Button onClick={() => handleSaveCurrentRole()} className="primary notes" style={{margin: '1rem 3rem 0 0'}}>
            <SaveIcon />
          </Button>
        </FlexWrapper>
        <FlexWrapper className="column fitContentHeight" style={{padding: '2rem'}}>
          <GridWrapper className="fourColumn">
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">CSV Mortgage Upload</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.csvMortgageUpload ? 'green' : 'red'}>{currentUserActionsPermissions.csvMortgageUpload ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Check Mortgage Duplicates</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.checkMortgageDuplicates ? 'green' : 'red'}>{currentUserActionsPermissions.checkMortgageDuplicates ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Delete Upload Report</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.deleteUploadReport ? 'green' : 'red'}>{currentUserActionsPermissions.deleteUploadReport ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Delete Mortgage</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.deleteMortgage ? 'green' : 'red'}>{currentUserActionsPermissions.deleteMortgage ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </GridWrapper>
          <GridWrapper className="fourColumn">
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item" style={{textWrap: 'nowrap'}}>Clear Mortgage Current Public Record Values</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.clearMortgageCurrentPublicRecordValues ? 'green' : 'red'}>{currentUserActionsPermissions.clearMortgageCurrentPublicRecordValues ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item" style={{textWrap: 'nowrap'}}>Clear Mortgage Current Record Values</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.clearMortgageCurrentRecordValues ? 'green' : 'red'}>{currentUserActionsPermissions.clearMortgageCurrentRecordValues ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Run Single Scan</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.runSingleScan ? 'green' : 'red'}>{currentUserActionsPermissions.runSingleScan ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Run Record Sweep</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.runRecordSweep ? 'green' : 'red'}>{currentUserActionsPermissions.runRecordSweep ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </GridWrapper>
          <GridWrapper className="fourColumn">
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Delete Action Log</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.deleteActionLog ? 'green' : 'red'}>{currentUserActionsPermissions.deleteActionLog ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Add Lead Assignees</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.addLeadAssignees ? 'green' : 'red'}>{currentUserActionsPermissions.addLeadAssignees ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Edit Default Targets</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.editDefaultTargetsPerm ? 'green' : 'red'}>{currentUserActionsPermissions.editDefaultTargetsPerm ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Designate Lead Awaiting Update</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.designateLeadAwaitingUpdate ? 'green' : 'red'}>{currentUserActionsPermissions.designateLeadAwaitingUpdate ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </GridWrapper>
          <GridWrapper className="fourColumn">
          <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Add Mortgage Type</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.addMortgageType ? 'green' : 'red'}>{currentUserActionsPermissions.addMortgageType ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Edit Parameter Status</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.editParameterStatus ? 'green' : 'red'}>{currentUserActionsPermissions.editParameterStatus ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Edit Default Target Type</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.editDefaultTargetType ? 'green' : 'red'}>{currentUserActionsPermissions.editDefaultTargetType ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Create Mortgage Tag</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.createMortgageTag ? 'green' : 'red'}>{currentUserActionsPermissions.createMortgageTag ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </GridWrapper>
          <GridWrapper className="fourColumn">
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Assign Lead Tag</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.assignLeadTag ? 'green' : 'red'}>{currentUserActionsPermissions.assignLeadTag ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Create Lead Tag</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.createLeadTag ? 'green' : 'red'}>{currentUserActionsPermissions.createLeadTag ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Set Investigation Closing</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.setInvestigationClosing ? 'green' : 'red'}>{currentUserActionsPermissions.setInvestigationClosing ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Set Investigation Finalized</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.setInvestigationFinalized ? 'green' : 'red'}>{currentUserActionsPermissions.setInvestigationFinalized ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </GridWrapper>
          <GridWrapper className="fourColumn">
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Dismiss Lead</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.dismissLead ? 'green' : 'red'}>{currentUserActionsPermissions.dismissLead ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Validate Lead Awaiting Update</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.validateLeadAwaitingUpdate ? 'green' : 'red'}>{currentUserActionsPermissions.validateLeadAwaitingUpdate ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Add Mortgage Note</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.addMortgageNote ? 'green' : 'red'}>{currentUserActionsPermissions.addMortgageNote ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item" style={{textWrap: 'nowrap'}}>Resolve All Mortgage Discrepancies</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.resolveAllMortgageDiscrepancies ? 'green' : 'red'}>{currentUserActionsPermissions.resolveAllMortgageDiscrepancies ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </GridWrapper>
          <GridWrapper className="fourColumn">
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Edit Mortgage Record Details</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.editMortgageRecordDetails ? 'green' : 'red'}>{currentUserActionsPermissions.editMortgageRecordDetails ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Open Lead Investigation</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.openLeadInvestigation ? 'green' : 'red'}>{currentUserActionsPermissions.openLeadInvestigation ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Assign Mortgage Tag</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.assignMortgageTag ? 'green' : 'red'}>{currentUserActionsPermissions.assignMortgageTag ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
            <DetailListContainer style={{margin: '0 1.5rem'}}>
              <DetailInfoContainer className="item" style={{gap: '1.4rem'}}>
                <Detail className="item">Edit Lead Target Outcome</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer style={{gap: '1.4rem'}}>
                <Detail className={currentUserActionsPermissions.editLeadTargetOutcome ? 'green' : 'red'}>{currentUserActionsPermissions.editLeadTargetOutcome ? 'True' : 'False'}</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </GridWrapper>
        </FlexWrapper>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default AppSettings