import React from "react"
import IconLogo from '../icon.logo.black.png';
import WhiteLogo from "../icon.logo.white.png";
import { useTheme } from "@mui/styles";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectExcludeMobileVisitor } from "../slices/sessionDataSlice";
import { FlexWrapper, ImageElements, PageContainer, ScreenSaverContainer, Typography } from "../StyledComponents"

const ExcludeMobileVisitor = () => {
  const theme = useTheme()

  const excludeMobileVisitor = useSelector(selectExcludeMobileVisitor)

  return (
    <PageContainer className="screenSaver">
      {excludeMobileVisitor ? <Navigate to="/mobileError" /> : <></>}
      <ScreenSaverContainer className="screenSaver fadeIn">
        <ImageElements src={IconLogo} className="centerLogo opaque" style={{height: '35rem', width: '35rem'}} />
          <FlexWrapper className="column alignCenter fitContentHeight fitContentWidth" style={{height: '45rem', padding: '10rem 0', textAlign: 'center'}}>
            <Typography style={{fontSize: '1.8rem', color: `${theme.palette.common.white}`, zIndex: '101', margin: '0 auto'}}>
              Thank you for visiting
            </Typography>
            <FlexWrapper className="justifyCenter fitContentHeight" style={{margin: '1.5rem 0 0 0', zIndex: '101'}}>
              <ImageElements style={{margin: '0', width: '4.5rem', height: '4.5rem', opacity: '0.7'}} src={WhiteLogo} alt="Lancaster Logo" />
              <span style={{opacity: '0.8', fontSize: '3rem', color: `${theme.palette.common.white}`, fontWeight: '300', fontFamily: 'exo-bold', borderBottom: `0.1rem solid ${theme.accent.success.primary}`, height: '3.7rem', borderRadius: '1rem', marginLeft: '0.5rem'}}>
                Lancaster Sweep
              </span>
            </FlexWrapper>
            <span style={{fontSize: '1.6rem', color: `${theme.palette.common.grayText}`, zIndex: '101', margin: '8rem 0 0 0'}}>
              Unfortunately, we do not offer<br/> a mobile site at this time.
            </span>
            <span style={{fontSize: '1.6rem', color: `${theme.palette.common.grayText}`, zIndex: '101', margin: '1rem 0 0 0'}}>
              Please visit us again<br/> from a desktop or laptop.
            </span>
            <span style={{fontSize: '1.2rem', color: `${theme.palette.common.grayText}`, zIndex: '101', margin: '8rem auto 0 auto'}}>
              contact our team any time at<br/>
              solutions@lancastersweep.com
            </span>
          </FlexWrapper>
      </ScreenSaverContainer>
    </PageContainer>
  )
}

export default ExcludeMobileVisitor