import React from "react";
import ListIcon from '@mui/icons-material/List';
import FullLogo from '../../../../Full.logo.white.png';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useTheme } from "@mui/styles";
import SettingsIcon from '@mui/icons-material/Settings';
import DataObjectIcon from '@mui/icons-material/DataObject';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useNavigate } from "react-router-dom";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useSelector, useDispatch } from "react-redux";
import { selectAdminNavSelection, selectAppLoaded, setAdminNavSelection } from "../../../../slices/sessionDataSlice";
import { SideBarContainer, TitleContainer, NavigationContainer, NavIndex, SideBarNavHeader, Typography, ListItemNav  } from "../../../../StyledComponents";

const SideBar = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const appLoaded = useSelector(selectAppLoaded)
  const adminNavSelection = useSelector(selectAdminNavSelection)

  const changeSelection = (content) => {
    navigate(`/admin/${content}`)
    dispatch( setAdminNavSelection(content) )
  }

  return (
    <SideBarContainer>
      <TitleContainer>
        <img src={FullLogo} style={{width: '90%', height: 'auto', margin: 'auto'}} alt="Lancaster Logo" />
      </TitleContainer>
      <NavigationContainer style={{gridRow: '2/4'}}>
        <SideBarNavHeader>Navigation</SideBarNavHeader>
        <ListItemNav 
          className={adminNavSelection === 'uploads' ? 'mainActive' : 'mainInactive'}
          onClick={(adminNavSelection === 'uploads' || !appLoaded) ? null : () => changeSelection('uploads')}
        >
          <NavIndex>
            <UploadFileIcon fontSize="medium" />
          </NavIndex>
          <Typography className="primary">Uploads</Typography>
        </ListItemNav>
        <ListItemNav 
          className={adminNavSelection === 'repository' ? 'mainActive' : 'mainInactive'}
          onClick={(adminNavSelection === 'repository' || !appLoaded) ? null : () => changeSelection('repository')}
        >
          <NavIndex>
            <ListIcon fontSize="medium" />
          </NavIndex>
          <Typography className="primary">Repository</Typography>
        </ListItemNav>
        <ListItemNav 
          className={adminNavSelection === 'recordSweeps' ? 'mainActive' : 'mainInactive'}
          onClick={(adminNavSelection === 'recordSweeps' || !appLoaded) ? null : () => changeSelection('recordSweeps')}
        >
          <NavIndex>
            <DocumentScannerIcon fontSize="medium" />
          </NavIndex>
          <Typography className="primary">Record Sweeps</Typography>
        </ListItemNav>
        <ListItemNav 
          className={adminNavSelection === 'log' ? 'mainActive' : 'mainInactive'}
          onClick={(adminNavSelection === 'log' || !appLoaded) ? null : () => changeSelection('log')}
        >
          <NavIndex>
            <ListAltIcon fontSize="medium" />
          </NavIndex>
          <Typography className="primary">Actions Log</Typography>
        </ListItemNav>
        <ListItemNav 
          className={adminNavSelection === 'database' ? 'mainActive' : 'mainInactive'}
          onClick={(adminNavSelection === 'database' || !appLoaded) ? null : () => changeSelection('database')}
        >
          <NavIndex>
            <AccountTreeIcon fontSize="medium" />
          </NavIndex>
          <Typography className="primary">Database</Typography>
        </ListItemNav>
        <ListItemNav 
          className={adminNavSelection === 'scheduledTasks' ? 'mainActive' : 'mainInactive'}
          onClick={(adminNavSelection === 'scheduledTasks' || !appLoaded) ? null : () => changeSelection('scheduledTasks')}
        >
          <NavIndex>
            <ScheduleSendIcon fontSize="medium" />
          </NavIndex>
          <Typography className="primary">Scheduled Tasks</Typography>
        </ListItemNav>
        <ListItemNav 
          className={adminNavSelection === 'dataModel' ? 'mainActive' : 'mainInactive'}
          onClick={(adminNavSelection === 'dataModel' || !appLoaded) ? null : () => changeSelection('dataModel')}
        >
          <NavIndex>
            <DataObjectIcon fontSize="medium" />
          </NavIndex>
          <Typography className="primary">Data Model</Typography>
        </ListItemNav>
        <ListItemNav 
          className={adminNavSelection === 'appSettings' ? 'mainActive' : 'mainInactive'}
          onClick={(adminNavSelection === 'appSettings' || !appLoaded) ? null : () => changeSelection('appSettings')}
        >
          <NavIndex>
            <SettingsIcon fontSize="medium" />
          </NavIndex>
          <Typography className="primary">App Settings</Typography>
        </ListItemNav>
      <div style={{gridRow: '3', height: '100%', borderRight: `0.1rem solid ${theme.panel.border.secondary}`}} />
      </NavigationContainer>
    </SideBarContainer>
  )
}

export default SideBar;