import React from "react"
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@mui/styles";
import { CompareLink, FadeWrapper, FlexWrapper, Typography } from "../../../../../../../StyledComponents"

const BooleanUndoDiscrepancy = ({ handleWorkFlow, handleSubmitEdit, record }) => {
  const theme = useTheme()

  return (
      <FadeWrapper>
        <Typography>
          {record.discrepancy === 'rejected' ?
            `resolve the discrepancy?`
          :
            `reactivate the discrepancy?`
          }
        </Typography>
        <FlexWrapper className="fitContentHeight justifyCenter">
          <CompareLink className="small gray" onClick={() => handleSubmitEdit('switch', null, record.currentValue)}>
            <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
          </CompareLink>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')}>
            <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
          </CompareLink>
        </FlexWrapper>
      </FadeWrapper>
  )
}

export default BooleanUndoDiscrepancy