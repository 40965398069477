import React from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import { useTheme } from "@mui/styles";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const PerformanceGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Team" and "Member Performance" Components
      </GuideText>
      <GuideText className="medium">
        provide metrics on the health of your portfolio as well as the outcomes achieved by your team.
      </GuideText>
      
      <Divider className="narrow" />
      
      <FlexWrapper className="fitContentHeight alignCenter">
        <QueryStatsIcon style={{color: `${theme.palette.primary.main}`, height: '1.8rem', width: '1.8rem'}} />
        <GuideText className="medium header" style={{paddingLeft: '0.8rem'}}>
          Team Lead Generation
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph twoIndent">
        Displays monthly statistics to reflect the overall health of your portfolio.<br/>
        It tracks the mortgages that are in good standing, how often new violations are detected, and the outcome of mitigating actions taken by your team.
      </GuideText>
      <FlexWrapper className="fitContentHeight alignCenter" style={{padding: '2rem 0 0 0'}}>
        <QueryStatsIcon style={{color: `${theme.palette.primary.main}`, height: '1.8rem', width: '1.8rem'}} />
        <GuideText className="medium header" style={{paddingLeft: '0.8rem'}}>
          Team Renegotiations and Refinances
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph twoIndent">
        Statistics are kept for each respective closure type, including the number of investigations closed in each month, how often these investigations are being performed, and how the number of closures performed this quarter compares to the last. Refinances will also have stats on the changes in gross revenue that they generate.
      </GuideText>

      <Divider className="narrow" />

      <FlexWrapper className="fitContentHeight alignCenter">
        <GroupsIcon style={{color: `${theme.palette.primary.main}`, height: '1.8rem', width: '1.8rem'}} />
        <GuideText className="medium header" style={{paddingLeft: '0.8rem'}}>
          Member Performance
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph twoIndent bottom">
        Similar statistics on closures, frequencies, and profits are kept for each team member, reflecting their involvement in investigation assignments.
      </GuideText>
    </FlexWrapper>
  )
}

export default PerformanceGuide