import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const TargetsUpdated = ({milestone}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="new" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            updated by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className="column" style={{marginTop: '1rem'}}>
          <Typography className="underline">
            Updated Refinance Targets:
          </Typography>
          {milestone.details.map((detail, index) => (
            <FlexWrapper key={detail.field} className='fitContentHeight'>
              <Typography className="justifyEnd" style={{width: '10rem', marginRight: '0.5rem'}}>
                {detail.field}:
              </Typography>
              <Typography className="fitContentHeight bold">
                {detail.field.includes('Rate') ?
                  detail.old === detail.new ?
                    <Typography className="bold">
                      {detail.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%
                    </Typography>
                  :
                    <Typography className="bold">
                      {detail.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%
                      <Typography className="thin" style={{margin: '0 0.4rem'}}>to</Typography>
                      {detail.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%
                    </Typography>
                : detail.field.includes('Term') ?
                  detail.old === detail.new ?
                    <Typography className="bold">
                      {detail.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} years
                    </Typography>
                  :
                    <Typography className="bold">
                      {detail.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                      <Typography className="thin" style={{margin: '0 0.4rem'}}>to</Typography>
                      {detail.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} years
                    </Typography>
                :
                  detail.old === detail.new ?
                    <Typography className="bold">
                      ${detail.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  :
                    <Typography className="bold">
                      ${detail.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                      <Typography className="thin" style={{margin: '0 0.4rem'}}>to</Typography>
                      ${detail.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                }
              </Typography>
            </FlexWrapper>
          ))}
        </FlexWrapper>
        <FlexWrapper className="column" style={{marginTop: '1rem'}}>
          <Typography className="underline">
            Changes in Potential Outcomes:
          </Typography>
          <FlexWrapper className="alignCenter" style={{height: '2.5rem'}}>
            <Typography className="justifyEnd" style={{width: '10rem', marginRight: '0.5rem'}}>
              Interest Owed:
            </Typography>
            <Typography className="bold">
              ${milestone.newProfits.profitNumber.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
              <Typography className="thin" style={{margin: '0 0.4rem'}}>to</Typography>
              ${milestone.newProfits.profitNumber.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
            </Typography>
            <Detail style={{marginLeft: '0.5rem'}}>
              {milestone.newProfits.profitNumber.difference === 0 ?
                <Detail className="wrappedGray">no change</Detail> 
              : milestone.newProfits.profitNumber.new > milestone.newProfits.profitNumber.old ?
                <Detail className="wrapped">${milestone.newProfits.profitNumber.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} increase</Detail> 
              :
                <Detail className="wrappedRed">${milestone.newProfits.profitNumber.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} decrease</Detail>     
              }
            </Detail>
          </FlexWrapper>
          <FlexWrapper className="alignCenter" style={{height: '2.5rem'}}>
            <Typography className="justifyEnd" style={{width: '10rem', marginRight: '0.5rem'}}>
              Revenue:
            </Typography>
            <Typography className="bold">
              ${milestone.newProfits.profitPercent.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
              <Typography className="thin" style={{margin: '0 0.4rem'}}>to</Typography>
              ${milestone.newProfits.profitPercent.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
            </Typography>
            <Detail style={{marginLeft: '0.5rem'}}>
              {milestone.newProfits.profitPercent.difference === 0 ?
                <Detail className="wrappedGray">no change</Detail> 
              : milestone.newProfits.profitPercent.new > milestone.newProfits.profitPercent.old ?
                <Detail className="wrapped">{milestone.newProfits.profitPercent.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}% increase</Detail> 
              :
                <Detail className="wrappedRed">{milestone.newProfits.profitPercent.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}% decrease</Detail>     
              }
            </Detail>
          </FlexWrapper>
          <FlexWrapper className="alignCenter" style={{height: '2.5rem'}}>
            <Typography className="justifyEnd" style={{width: '10rem', marginRight: '0.5rem'}}>
              Monthly Payments:
            </Typography>
            <Typography className="bold">
              ${milestone.newProfits.monthlyPayments.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
              <Typography className="thin" style={{margin: '0 0.4rem'}}>to</Typography>
              ${milestone.newProfits.monthlyPayments.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
            </Typography>
            <Detail style={{marginLeft: '0.5rem'}}>
              {milestone.newProfits.monthlyPayments.difference === 0 ?
                <Detail className="wrappedGray">no change</Detail> 
              : milestone.newProfits.monthlyPayments.new > milestone.newProfits.monthlyPayments.old ?
                <Detail className="wrapped">${milestone.newProfits.monthlyPayments.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} increase</Detail> 
              :
                <Detail className="wrappedRed">${milestone.newProfits.monthlyPayments.difference.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} decrease</Detail>     
              }
            </Detail>
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default TargetsUpdated