import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Uploads from "./components/Uploads/Uploads";
import SideBar from "./components/SideBar/SideBar";
import IconLogo from '../../icon.logo.black.png';
import DataBase from "./components/DataBase/DataBase";
import DataModel from "./components/DataModel/DataModel";
import AppSettings from "./components/AppSettings/AppSettings";
import LoadingLogo from '../../icon.logo.green.png';
import RecordSweeps from "./components/RecordSweeps/RecordSweeps";
import ScheduledTasks from "./components/ScheduledTasks/ScheduledTasks";
import { useSelector } from "react-redux";
import MortgageRepository from "./components/MortgageRepository/MortgageRepository";
import ActionsAndErrorsLog from "./components/ActionsLog/ActionsAndErrorsLog";
import { selectUserFullName } from "../../slices/userInfoSlice";
import { selectCurrentUserActionsPermissions } from "../../slices/userActionsPermissionsSlice";
import { selectAppLoaded, selectCurrentUserActionsRole } from "../../slices/sessionDataSlice";
import { PageContentContainer, PageContentWrapper, Footer, PageContainer, ScreenSaverContainer, ImageElements } from "../../StyledComponents";

const SuperDash = ({ teamId, userId, subPage }) => {

  const appLoaded = useSelector(selectAppLoaded)
  const userFullName = useSelector(selectUserFullName)
  const currentUserActionsRole = useSelector(selectCurrentUserActionsRole)
  const currentUserActionsPermissions = useSelector(selectCurrentUserActionsPermissions)

  return (
    <PageContainer>

      {!appLoaded &&
        <ScreenSaverContainer className="screenSaver">
          <ImageElements src={IconLogo} className="centerLogo opaque" style={{height: '40rem', width: '40rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo loading one" />
          <ImageElements src={LoadingLogo} className="centerLogo loading two" />
          <ImageElements src={LoadingLogo} className="centerLogo loading three" />
          <ImageElements src={LoadingLogo} className="centerLogo loading four" />
          <ImageElements src={LoadingLogo} className="centerLogo loading five" />
        </ScreenSaverContainer>
      }

      <NavBar />
      <SideBar />

      <PageContentContainer>
        <PageContentWrapper className="noScroll">
          {subPage === 'recordSweeps' ?
            <RecordSweeps teamId={teamId} userId={userId} />
          : subPage === 'repository' ?
            <MortgageRepository userId={userId} teamId={teamId} userFullName={userFullName} />
          : subPage === 'uploads' ?
            <Uploads userId={userId} teamId={teamId} userFullName={userFullName} />
          : subPage === 'log' ?
            <ActionsAndErrorsLog />
          : subPage === 'database' ?
            <DataBase />
          : subPage === 'scheduledTasks' ?
            <ScheduledTasks />
          : subPage === 'dataModel' ?
            <DataModel userFullName={userFullName} />
          : subPage === 'appSettings' ?
            <AppSettings currentUserActionsPermissions={currentUserActionsPermissions} currentUserActionsRole={currentUserActionsRole} userFullName={userFullName} />
          :
            <></>
          }
        </PageContentWrapper>
        <Footer><img src={IconLogo} alt="Lancaster Logo" style={{height: '80%', width: 'auto', marginRight: '0.3rem'}} />Lancaster Sweep<span style={{fontSize: '0.4rem', paddingBottom: '0.5rem'}}>TM</span></Footer>
      </PageContentContainer>
    </PageContainer>
  )
}

export default SuperDash;