import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import GenericDropdown from "../DropDowns/GenericDropdown.js";
import SweepLoadingBar from "./SweepLoadingBar.js";
import { FlexWrapper, SubHeader } from "../../StyledComponents.js";

const BulkScanControls = ({ teamId, userId, mortgageId }) => {
  const theme = useTheme()

  const [sweepTypeSelection, setSweepTypeSelection] = useState('Check All Discrepancies')
  
  const [sweepSizeSelection, setSweepSizeSelection] = useState('All')
  const handleSweepSizeSelectionChange = async (event) => {
    setSweepSizeSelection(event.target.value)
  }
  const [existingScansSelection, setExistingScansSelection] = useState('Any')
  const handleExistingScansSelectionChange = async (event) => {
    setExistingScansSelection(event.target.value)
  }

  const [existingScansDisabled, setExistingScansDisabled] = useState(false)
  const [initialMatchSelection, setInitialMatchSelection] = useState('false')
  const [notifyAssigneesSelection, setNotifyAssigneesSelection] = useState('true')
  const [recordMonthlyStatsSelection, setRecordMonthlyStatsSelection] = useState('true')
  const [recordTeamStatsSelection, setRecordTeamStatsSelection] = useState('true')
  const [updateLastQuerySelection, setUpdateLastQuerySelection] = useState('true')
  const [createLeadSelection, setCreateLeadSelection] = useState('true')
  const [recordQueryPerformanceSelection, setRecordQueryPerformanceSelection] = useState('true')

  const handleSweepTypeChange = async (event) => {
    if (event.target.value === 'Initial Match') {
      setExistingScansSelection('0')
      setExistingScansDisabled(true)
      setInitialMatchSelection('true')
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('false')
      setRecordTeamStatsSelection('false')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('false')
      setRecordQueryPerformanceSelection('true')
    } else if (event.target.value === 'Check All Discrepancies') {
      setExistingScansDisabled(false)
      setInitialMatchSelection('false')
      setNotifyAssigneesSelection('true')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
    } else if (event.target.value === 'Notify Members') {
      setExistingScansDisabled(false)
      setInitialMatchSelection('false')
      setNotifyAssigneesSelection('true')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
    } else if (event.target.value === 'Do Not Record Query Stats') {
      setExistingScansDisabled(false)
      setInitialMatchSelection('false')
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('false')
      setRecordTeamStatsSelection('false')
      setUpdateLastQuerySelection('false')
      setCreateLeadSelection('false')
      setRecordQueryPerformanceSelection('true')
    } else if (event.target.value === 'Check Parameter Discrepancies') {
      setExistingScansDisabled(false)
      setInitialMatchSelection('false')
      setNotifyAssigneesSelection('false')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
    } else if (event.target.value === 'Standard + Retain Price') {
      setExistingScansDisabled(false)
      setInitialMatchSelection('false')
      setNotifyAssigneesSelection('true')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
    } else {
      setExistingScansDisabled(false)
      setInitialMatchSelection('false')
      setNotifyAssigneesSelection('true')
      setRecordMonthlyStatsSelection('true')
      setRecordTeamStatsSelection('true')
      setUpdateLastQuerySelection('true')
      setCreateLeadSelection('true')
      setRecordQueryPerformanceSelection('true')
    }
    setSweepTypeSelection(event.target.value)
  }

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.component.background.primary}`}}>
      <SweepLoadingBar teamId={teamId} userId={userId} mortgageId={mortgageId} sweepTypeSelection={sweepTypeSelection} sweepSizeSelection={sweepSizeSelection} existingScansSelection={existingScansSelection} createLeadSelection={createLeadSelection} recordMonthlyStatsSelection={recordMonthlyStatsSelection} recordQueryPerformanceSelection={recordQueryPerformanceSelection} notifyAssigneesSelection={notifyAssigneesSelection} recordTeamStatsSelection={recordTeamStatsSelection} updateLastQuerySelection={updateLastQuerySelection} initialMatchSelection={initialMatchSelection} />
        <FlexWrapper style={{justifyContent: 'center', height: 'fit-content', minWidth: '80rem'}}>
          <div style={{width: '20rem', padding: '0 1rem 0 0'}}>
            <SubHeader style={{padding: '0.5rem 0 0 0'}}>Sweep Type</SubHeader>
            <GenericDropdown 
              value={sweepTypeSelection}
              handleChange={handleSweepTypeChange}
              disabled={false}
              items={[
                {label: 'Initial Match'},
                {label: 'Do Not Record Query Stats'},
                {label: 'Check All Discrepancies'},
                {label: 'Notify Members'},
              ]} 
            />
          </div>
          <div style={{width: 'fit-content', padding: '0 1rem 0 0'}}>
            <SubHeader style={{padding: '0.5rem 0 0 0'}}>Sweep Size</SubHeader>
              <GenericDropdown 
                value={sweepSizeSelection}
                handleChange={handleSweepSizeSelectionChange}
                disabled={false}
                items={[
                  {label: '1'},
                  {label: '5'},
                  {label: 'All'},
                ]} 
              />
          </div>
          <div style={{width: 'fit-content', padding: '0 2rem 0 0'}}>
            <SubHeader style={{padding: '0.5rem 0 0 0'}}>Existing Scans</SubHeader>
            <GenericDropdown 
                value={existingScansSelection}
                handleChange={handleExistingScansSelectionChange}
                disabled={existingScansDisabled}
                items={[
                  {label: 'Any'},
                  {label: '0'},
                  {label: '1'},
                  {label: '2'},
                  {label: '3'},
                ]} 
              />
          </div>
        </FlexWrapper>
        <FlexWrapper className='justifyBetween' style={{margin: '2rem auto 1rem auto', width: '100rem', minWidth: '80rem', maxWidth: '100%', height: 'fit-content'}}>
          {createLeadSelection === 'true' ?
            <SubHeader className="secondary">Create Leads</SubHeader>
          :
            <SubHeader>Create Leads</SubHeader>
          }
          {recordQueryPerformanceSelection === 'true' ?
            <SubHeader className="secondary">Record Sweep Performance</SubHeader>
          :
            <SubHeader>Record Sweep Performance</SubHeader>
          }
          {notifyAssigneesSelection === 'true' ?
            <SubHeader className="secondary">Notify Assignees</SubHeader>
          :
            <SubHeader>Notify Assignees</SubHeader>
          }
          {recordMonthlyStatsSelection === 'true' ?
            <SubHeader className="secondary">Record Monthly Stats</SubHeader>
          :
            <SubHeader>Record Monthly Stats</SubHeader>
          }
          {recordTeamStatsSelection === 'true' ?
            <SubHeader className="secondary">Record Team Stats</SubHeader>
          :
            <SubHeader>Record Team Stats</SubHeader>
          }
          {updateLastQuerySelection === 'true' ?
            <SubHeader className="secondary">Record Last Query</SubHeader>
          :
            <SubHeader>Record Last Query</SubHeader>
          }
        </FlexWrapper>
    </FlexWrapper>
  )
}

export default BulkScanControls