import React, { useState } from "react";
import Radio from '@mui/material/Radio';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BulkUpload from "./components/BulkUploads/BulkUpload.js";
import CancelIcon from '@mui/icons-material/Cancel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import ReportsWindow from "./components/ReportsWindow.js";
import { useSelector } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import { selectUserRole } from "../../../slices/userInfoSlice.js";
import SingleSearchUpload from "./components/SingleSearchUpload/SingleSearchUpload.js";
import { selectRunningSweep } from "../../../slices/sessionDataSlice.js";
import { selectTeamUploadReports } from "../../../slices/teamAdminDataSlice.js";
import { grayUnselectedRadioColorProps } from "../../../componentThemes.js";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer , ComponentHeader, Typography, FlexWrapper, HeaderText } from "../../../StyledComponents";

const UploadModal = ({ handleCloseModal, userId, userFullName, dashPerspective, teamId }) => {
  const theme = useTheme()

  const userRole = useSelector(selectUserRole)
  const runningSweep = useSelector(selectRunningSweep)
  const teamUploadReports = useSelector(selectTeamUploadReports)

  const [uploadTypeSelection, setUploadTypeSelection] = useState('single property search')

  const handleUploadTypeSelection = (event) => {
    setUploadTypeSelection(event.target.value)
  }

  const uploadTypeSelectionProp = (item) => ({
    checked: uploadTypeSelection === item,
    onChange: handleUploadTypeSelection,
    value: item,
    label: item,
    labelPlacement: "start",
    sx: {
      color: `${theme.palette.common.grayDark}`,
      padding: "0",
    }
  })

  return (
    <ModalContainer className="uploadModal" style={{backgroundColor: `${theme.component.background.primary}`}}>
      <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/5'}}>
        <ComponentHeader className="areaTitle" style={{display: 'grid', gridTemplateColumns: '1fr 57rem'}}>
          <FlexWrapper className="alignCenter">
            <Typography className="xLarge" style={{width: 'fit-content'}}>
              Add new mortgages
            </Typography>
            <FlexWrapper className="fitContentWidth fitContentHeight alignEnd justifyEnd" style={{margin: 'auto auto 0.8rem auto'}}>
              <HeaderText className="center" style={{padding: '0 0.5rem 0 0', fontSize: '1rem', color: `${theme.palette.common.grayDark}`}}>
                method:
              </HeaderText>
              <FormControl>
                <RadioGroup row >
                  <FormControlLabel style={{height: 'fit-content', margin: '0'}}
                    control={<Radio {...grayUnselectedRadioColorProps()} style={{padding: '0 0.4rem'}} />}
                    {...uploadTypeSelectionProp('single property search')} 
                  />
                  <FormControlLabel style={{height: 'fit-content', margin: '0 0 0 0.3rem'}}
                    control={<Radio {...grayUnselectedRadioColorProps()} style={{padding: '0 0.4rem'}} />}
                    {...uploadTypeSelectionProp('bulk upload')} 
                  />
                </RadioGroup>
              </FormControl>
            </FlexWrapper>
          </FlexWrapper>
        </ComponentHeader>
        <ActionIconWrapper className={(runningSweep ? 'disabled ' : '') + "medium grayText circle hoverBorder hover"} style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <FlexWrapper className="column fitContentHeight" style={{margin: '0 0 auto 0'}}>
        <FlexWrapper className="fitContentHeight column alignCenter jusitfyCenter" style={{height: '5rem', padding: '0.6rem 0 0 0', backgroundColor: `${theme.component.background.tertiary}`}}>
          <Typography className="small fitContentWidth alignTextCenter column" style={{margin: '0.5rem 0 1rem 0'}}>
            <Typography className="small secondary" style={{textAlign: 'start'}}>
              Select the fields that you would like to include with your upload.<br/>
              A public records sweep will be performed to populate information in the system, but fields beyond the address<br/>
              will be used to detect any existing discrepancies in the public records.<br/>
            </Typography>
            <FlexWrapper className="column" style={{paddingLeft: '3rem'}}>
              <FlexWrapper className="fitContentHeight fitContentWidth" style={{marginTop: '0.5rem'}}>
                <BlurOnIcon style={{height: '0.9rem', width: '0.9rem', margin: '0.3rem 0.3rem auto 0', color: `${theme.text.secondary.primary}`}} />
                <Typography className="tiny secondary" style={{textAlign: 'start'}}>
                  Keep in mind the potential for the format of owner names to be responsible for discrepancies flagged by the system.<br/>
                  Dismiss these investigations to resolve these discrepancies and match public records.
                </Typography>
              </FlexWrapper>
              <FlexWrapper className="fitContentHeight fitContentWidth" style={{marginTop: '0.5rem'}}>
                <BlurOnIcon style={{height: '0.9rem', width: '0.9rem', margin: '0.3rem 0.3rem auto 0', color: `${theme.text.secondary.primary}`}} />
                <Typography className="tiny secondary" style={{textAlign: 'start'}}>
                  Each attempted property will consume one of your team's monthly queries.<br/>
                  Any duplicates uploaded will not consume queries.
                </Typography>
              </FlexWrapper>
              <FlexWrapper className="fitContentHeight fitContentWidth" style={{marginTop: '0.5rem'}}>
                <BlurOnIcon style={{height: '0.9rem', width: '0.9rem', margin: '0.3rem 0.3rem auto 0', color: `${theme.text.secondary.primary}`}} />
                <Typography className="tiny secondary" style={{textAlign: 'start'}}>
                  Successful uploads will be saved and query results will be reflected in your team's Lead Generation Performance.
                </Typography>
              </FlexWrapper>
            </FlexWrapper>
          </Typography>
          <ComponentBorder className="bottomPanel" style={{height: '1rem', minHeight: '1rem'}} />
        </FlexWrapper>
        {uploadTypeSelection === 'bulk upload' ?
          <BulkUpload teamId={teamId} userFullName={userFullName} userId={userId} userRole={userRole} />
        :
          <SingleSearchUpload teamId={teamId} userFullName={userFullName} userId={userId} userRole={userRole} />
        }
      </FlexWrapper>
      <TimelineContainer className="uploadsModal">
        {teamUploadReports &&
          <ReportsWindow teamUploadReports={teamUploadReports} origin={'modal'} />
        }
      </TimelineContainer>
      <ComponentBorder className="left" style={{gridRow:'2/4', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/4', gridColumn: '4'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/5'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default UploadModal