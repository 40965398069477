import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    //* Notification Objs
    userQueryNotification: null,
    userUploadNotification: null,
    userLeadAssignedNotifications: [],
    userLeadRemovedNotifications: [],
    userLeadNotifications: [],
    userLeadDismissedNotifications: [],
    userLeadFinalizedNotifications: [],
    userMortgageNotifications: [],
    userMortgageAssignedNotifications: [],
    userMortgageRemovedNotifications: [],
    //* Tracking "new" leads per status for control badges
    newAwaitingActionCount: 0,
    newInvestigatingCount: 0,
    newClosingCount: 0,
    newAwaitingUpdatesCount: 0,
    //* Tracking total leads per status for control badges
    awaitingActionCount: 0,
    investigatingCount: 0,
    closingCount: 0,
    awaitingUpdatesCount: 0,
    //* For the SideBar "Mortgage Repository" badge
    mortgageNotifCount: 0,
    //*
    lastUserLogin: '',
    notificationsCount: 0,
    notificationsPresent: false,
    totalLeadNotifCount: 0,
    newAwaitingActionPresent: false,
    newInvestiagingPresent: false,
    newClosingPresent: false,
    newAwaitingUpdatesPresent: false,
    uploadNotificationPresent: false,
    queryNotificationPresent: false,
    newReports: 0,
    newReportRemoved: false,
    notificationsRead: false,
    leadNotificationsRead: false,
  },
  status: 'idle',
  error: null
}

export const userNotificationsSlice = createSlice({
  name: 'userNotifications',
  initialState,
  reducers: {
    setNewReportRemoved(state, action) { 
      state.data.newReportRemoved = action.payload
    },
    removeReportNotifcationCount(state, action) {
      state.data.newReports = 0
    },
    removeMortgageNotifcationCount(state, action) {
      state.data.mortgageNotifCount = 0
    },
    removeNewLeadNotification(state, action) {
      if (action.payload.status === 'awaitingAction') {
        state.data.newAwaitingActionCount = state.data.newAwaitingActionCount - action.payload.count
        state.data.awaitingActionCount = state.data.awaitingActionCount - action.payload.count
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount - action.payload.count
      } else if (action.payload.status === 'investigating') {
        state.data.newInvestigatingCount = state.data.newInvestigatingCount - action.payload.count
        state.data.investigatingCount = state.data.investigatingCount - action.payload.count
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount - action.payload.count
      } else if (action.payload.status === 'closing') {
        state.data.newClosingCount = state.data.newClosingCount - action.payload.count
        state.data.closingCount = state.data.closingCount - action.payload.count
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount - action.payload.count
      } else {
        state.data.newAwaitingUpdatesCount = state.data.newAwaitingUpdatesCount - action.payload.count
        state.data.awaitingUpdatesCount = state.data.awaitingUpdatesCount - action.payload.count
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount - action.payload.count
      }
    },
    removeLeadNotification(state, action) {
      if (action.payload.status === 'awaitingAction') {
        state.data.awaitingActionCount = state.data.awaitingActionCount - action.payload.count
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount - action.payload.count
      } else if (action.payload.status === 'investigating') {
        state.data.investigatingCount = state.data.investigatingCount - action.payload.count
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount - action.payload.count
      } else if (action.payload.status === 'closing') {
        state.data.closingCount = state.data.closingCount - action.payload.count
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount - action.payload.count
      } else {
        state.data.awaitingUpdatesCount = state.data.awaitingUpdatesCount - action.payload.count
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount - action.payload.count
      }
    },
    decrementMortgageNotificationsCount(state, action) {
      state.data.mortgageNotifCount = state.data.mortgageNotifCount - action.payload
    },
    decrementNotificationCount(state, action) {
      state.data.notificationsCount--
    },
    setNotificationsRead(state, action) {
      state.data.notificationsRead = true
    },
    setLeadNotificationsRead(state, action) {
      state.data.leadNotificationsRead = true
    },
    removeQueryNotification(state, action) {
      state.data.queryNotificationPresent = false
    },
    removeUploadNotification(state, action) {
      state.data.uploadNotificationPresent = false
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.lastUserLogin = payload.data.notificationsObj.lastUserLogin
        //* NOTIFICATION OBJECTS
        state.data.userQueryNotification = payload.data.notificationsObj.userQueryNotification
        state.data.userUploadNotification = payload.data.notificationsObj.userUploadNotification
        state.data.userLeadAssignedNotifications = payload.data.notificationsObj.userLeadAssignedNotifications
        state.data.userLeadRemovedNotifications = payload.data.notificationsObj.userLeadRemovedNotifications
        state.data.userLeadNotifications = payload.data.notificationsObj.userLeadNotifications
        state.data.userLeadDismissedNotifications = payload.data.notificationsObj.userLeadDismissedNotifications
        state.data.userLeadFinalizedNotifications = payload.data.notificationsObj.userLeadFinalizedNotifications
        state.data.userMortgageNotifications = payload.data.notificationsObj.userMortgageNotifications
        state.data.userMortgageAssignedNotifications = payload.data.notificationsObj.userMortgageAssignedNotifications
        state.data.userMortgageRemovedNotifications = payload.data.notificationsObj.userMortgageRemovedNotifications
        //* NOTIFICATION COUNTS
        let newNotificationsCount = payload.data.notificationsObj.notificationsCount + payload.data.notificationsObj.queryNotificationsCount + payload.data.notificationsObj.uploadNotificationsCount
        //* Count for NavBar Avatar Badge
        state.data.notificationsCount = newNotificationsCount
        if (newNotificationsCount !== 0) {
          //* Enable NavBar Avatar Menu 
          state.data.leadNotificationsRead = false
          state.data.notificationsPresent = true
          state.data.notificationsRead = false
        } else {
          state.data.notificationsRead = true
        }
        //* Queries
        state.data.queryNotificationPresent = payload.data.notificationsObj.queryNotificationPresent
        //* Uploads
        state.data.uploadNotificationPresent = payload.data.notificationsObj.uploadNotificationPresent
        //* Mortgages
        state.data.mortgageNotifCount = payload.data.notificationsObj.mortgageNotifCount
        //* Leads
        state.data.awaitingActionCount = payload.data.notificationsObj.awaitingActionCount
        state.data.investigatingCount = payload.data.notificationsObj.investigatingCount
        state.data.closingCount = payload.data.notificationsObj.closingCount
        state.data.awaitingUpdatesCount = payload.data.notificationsObj.awaitingUpdatesCount
        //* Reports
        state.data.newReports = payload.data.notificationsObj.userNewReportsCount
        //* Count for SideBar Active Investigations Badge
        state.data.totalLeadNotifCount = payload.data.notificationsObj.awaitingActionCount + payload.data.notificationsObj.investigatingCount + payload.data.notificationsObj.closingCount + payload.data.notificationsObj.awaitingUpdatesCount
        if (payload.data.notificationsObj.newAwaitingActionCount > 0) {
          state.data.newAwaitingActionCount = payload.data.notificationsObj.newAwaitingActionCount
          state.data.newAwaitingActionPresent = true
        }
        if (payload.data.notificationsObj.newInvestigatingCount > 0) {
          state.data.newInvestigatingCount = payload.data.notificationsObj.newInvestigatingCount
          state.data.newInvestiagingPresent = true
        }
        if (payload.data.notificationsObj.newClosingCount > 0) {
          state.data.newClosingCount = payload.data.notificationsObj.newClosingCount
          state.data.newClosingPresent = true
        }
        if (payload.data.notificationsObj.newAwaitingUpdatesCount > 0) {
          state.data.newAwaitingUpdatesCount = payload.data.notificationsObj.newAwaitingUpdatesCount
          state.data.newAwaitingUpdatesPresent = true
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordQueryPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.userNotification) {
        if (state.data.userQueryNotification.date) {
          state.data.userQueryNotification.date[1] = payload.data.userNotification.date[0]
          state.data.userQueryNotification.queryNewLeads = state.data.userQueryNotification.queryNewLeads + payload.data.userNotification.queryNewLeads
          state.data.userQueryNotification.totalQueries = state.data.userQueryNotification.totalQueries + payload.data.userNotification.totalQueries
          state.data.userQueryNotification.notifCount = state.data.userQueryNotification.notifCount + payload.data.userNotification.notifCount
        } else {
          state.data.userQueryNotification = payload.data.userNotification
        }
        if (payload.data.userNotification.newLeadIds.length > 0) {
          state.data.newAwaitingActionPresent = true
          state.data.newAwaitingActionCount = state.data.newAwaitingActionCount + payload.data.userNotification.newLeadIds.length
          state.data.awaitingActionCount = state.data.awaitingActionCount + payload.data.userNotification.newLeadIds.length
          state.data.totalLeadNotifCount = state.data.totalLeadNotifCount + payload.data.userNotification.newLeadIds.length
        }
        state.data.notificationsCount++
        state.data.queryNotificationPresent = true
        state.data.notificationsPresent = true  
      }
    })
    .addMatcher(apiSlice.endpoints.recordUploadPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.userUploadNotification) {
        if (state.data.userUploadNotification.date) {
          state.data.userUploadNotification.date[1] = payload.data.userUploadNotification.date[0]
          state.data.userUploadNotification.queryNewLeads = state.data.userUploadNotification.queryNewLeads + payload.data.userUploadNotification.queryNewLeads
          state.data.userUploadNotification.totalQueries = state.data.userUploadNotification.totalQueries + payload.data.userUploadNotification.totalQueries
          state.data.userUploadNotification.notifCount = state.data.userUploadNotification.notifCount + payload.data.userUploadNotification.notifCount
        } else {
          state.data.userUploadNotification = payload.data.userUploadNotification
        }
        state.data.notificationsCount++
        state.data.uploadNotificationPresent = true
        state.data.notificationsPresent = true  
      }
      if (payload.data.totalNewLeads) {
        state.data.newAwaitingActionPresent = true
        state.data.newAwaitingActionCount = state.data.newAwaitingActionCount + payload.data.totalNewLeads
        state.data.awaitingActionCount = state.data.awaitingActionCount + payload.data.totalNewLeads
        state.data.totalLeadNotifCount = state.data.totalLeadNotifCount + payload.data.totalNewLeads
      }
    })
    .addMatcher(apiSlice.endpoints.removeReportNotifyUser.matchFulfilled, (state, {payload}) => {
      state.data.newReports--
    })
  }
})

export const { removeMortgageNotifcationCount, removeReportNotifcationCount, setNewReportRemoved, removeNewLeadNotification, decrementNotificationCount, removeQueryNotification, setNotificationsRead, setLeadNotificationsRead, removeLeadNotification, decrementMortgageNotificationsCount, removeUploadNotification } = userNotificationsSlice.actions

export default userNotificationsSlice.reducer


//* Tracking "new" leads per status for control badges
export const selectNewAwaitingActionCount = state => state.userNotifications.data.newAwaitingActionCount
export const selectNewInvestigatingCount = state => state.userNotifications.data.newInvestigatingCount
export const selectNewClosingCount = state => state.userNotifications.data.newClosingCount
export const selectNewAwaitingUpdatesCount = state => state.userNotifications.data.newAwaitingUpdatesCount
//* Tracking total leads per status for control badges
export const selectAwaitingActionCount= state => state.userNotifications.data.awaitingActionCount
export const selectInvestigatingCount = state => state.userNotifications.data.investigatingCount
export const selectClosingCount = state => state.userNotifications.data.closingCount
export const selectAwaitingUpdatesCount = state => state.userNotifications.data.awaitingUpdatesCount
//* For the SideBar "Mortgage Repository" badge
export const selectMortgageNotifCount = state => state.userNotifications.data.mortgageNotifCount
//* User Notifications for nav menu
export const selectUserQueryNotification = state => state.userNotifications.data.userQueryNotification
export const selectUserUploadNotification = state => state.userNotifications.data.userUploadNotification
export const selectUserLeadAssignedNotifications = state => state.userNotifications.data.userLeadAssignedNotifications
export const selectUserLeadRemovedNotifications = state => state.userNotifications.data.userLeadRemovedNotifications
export const selectUserLeadNotifications = state => state.userNotifications.data.userLeadNotifications
export const selectUserLeadDismissedNotifications = state => state.userNotifications.data.userLeadDismissedNotifications
export const selectUserLeadFinalizedNotifications = state => state.userNotifications.data.userLeadFinalizedNotifications
export const selectUserMortgageNotifications = state => state.userNotifications.data.userMortgageNotifications
export const selectUserMortgageAssignedNotifications = state => state.userNotifications.data.userMortgageAssignedNotifications
export const selectUserMortgageRemovedNotifications = state => state.userNotifications.data.userMortgageRemovedNotifications
//*
export const selectNewReportRemoved = state => state.userNotifications.data.newReportRemoved
export const selectUserNewReports = state => state.userNotifications.data.newReports
export const selectNotificationsCount = state => state.userNotifications.data.notificationsCount
export const selectQueryNotificationPresent = state => state.userNotifications.data.queryNotificationPresent
export const selectUploadNotificationPresent = state => state.userNotifications.data.uploadNotificationPresent
export const selectLastUserLogin = state => state.userNotifications.data.lastUserLogin
export const selectNotificationsPresent = state => state.userNotifications.data.notificationsPresent
export const selectTotalLeadNotifCount = state => state.userNotifications.data.totalLeadNotifCount
export const selectNewAwaitingActionPresent = state => state.userNotifications.data.newAwaitingActionPresent
export const selectNewInvestigatingPresent = state => state.userNotifications.data.newInvestiagingPresent
export const selectNewClosingPresent = state => state.userNotifications.data.newClosingPresent
export const selectNewAwaitingUpdatesPresent = state => state.userNotifications.data.newAwaitingUpdatesPresent
export const selectNotificationsRead = state => state.userNotifications.data.notificationsRead
export const selectLeadNotificationsRead = state => state.userNotifications.data.leadNotificationsRead