import React, { useState } from "react"
import EditIcon from '@mui/icons-material/Edit'
import UndoIcon from '@mui/icons-material/Undo'
import { useTheme } from "@mui/styles"
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { useDispatch } from "react-redux"
import EditSwitchboard from "./EditSwitchboard/EditSwitchboard"
import CircularProgress from "@mui/material/CircularProgress"
import { setShowLeadActionsModal } from "../../../../../slices/sessionDataSlice"
import { AccentInfo, CompareDetail, CompareLink, ListRow, DetailWrapper } from "../../../../../StyledComponents"
import { useEditOneMortgageDetailMutation, useResolveOneMortgageDetailMutation, useUndoOneMortgageDetailMutation } from "../../../../../slices/api/apiSlice"

const RecordRow = ({ recordFieldRoot, recordField, leadId, userNotifications, dashPerspective, valueType, handleWorkFlow, userId, userFullName, record, mortgageId, setLeadUserNotifications, workflowStage, fieldEditing, activeDiscrepancies, resolvedDiscrepancies, rejectedDiscrepancies, location, editingRecords, dateFormat, teamId, leadTier, leadStatus }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [editOneMortgageDetail] = useEditOneMortgageDetailMutation()
  const [undoOneMortgageDetail] = useUndoOneMortgageDetailMutation()
  const [resolveOneMortgageDetail] = useResolveOneMortgageDetailMutation()

  const [rowStatus, setRowStatus] = useState(null)
  const [editIsFetching, setEditIsFetching] = useState(false)

  const handleSubmitEdit = async (selection, customEditValue, dateFormat) => {
    setEditIsFetching(true)
    let reqObj = {
      userId: userId,
      userFullName: userFullName,
      userNotifications: userNotifications,
      dashPerspective: dashPerspective,
      mortgageId: mortgageId,
      leadId: leadId,
      recordField: recordField,
      customValue: customEditValue,
      activeDiscrepancies: activeDiscrepancies,
      resolvedDiscrepancies: resolvedDiscrepancies,
      rejectedDiscrepancies: rejectedDiscrepancies,
      publicRecordValue: record.publicRecordValue,
      oldDiscrepancy: record.discrepancy,
      recordFieldRoot: recordFieldRoot,
      dateFormat: dateFormat,
      discardLead: false,
      teamId: teamId,
      selection: null,
      financialsUpdated: false,
      leadStatus: leadStatus,
      origin: 'compareRecords',
    }
    if (recordField === 'PrimaryMortgageAmount' || recordField === 'PrimaryMortgageInterestRate' || recordField === 'PrimaryMortgageStartDate' || recordField === 'PrimaryMortgageTerm') {
      reqObj.financialsUpdated = true
    }
    let resp = {}
    if (selection === 'undo') {
      if (rejectedDiscrepancies === 1 && leadTier === 5 && record.discrepancy !== 'provided') {
        reqObj.discardLead = true
      }
      resp = await undoOneMortgageDetail(reqObj)
    } else if (selection === 'edit') {
      resp = await editOneMortgageDetail(reqObj)
    } else if (selection === 'resolve') {
      resp = await resolveOneMortgageDetail(reqObj)
    } else if (selection === 'keep') {
      reqObj.customValue = record.currentValue
      reqObj.selection = 'keep'
      resp = await editOneMortgageDetail(reqObj)
    }
    if (resp.error) {
      setRowStatus('error')
      handleWorkFlow('', '')
    } else {
      if (selection === 'undo') {
        if (rejectedDiscrepancies === 2 && leadTier === 5 && location === 'activeLeads') {
          dispatch( setShowLeadActionsModal(false) )
        }
        setRowStatus(null)
      } else {
        setRowStatus('success')
      }
      handleWorkFlow('', '')
      if (location === 'activeLead') {
        setLeadUserNotifications(0)
      }
    }
    setEditIsFetching(false)
  }

  return (
    <ListRow className={fieldEditing === recordField ? 'compareRecordsOpen' : rowStatus === 'error' ? 'error compareRecordsClosed' : rowStatus === 'success' ? 'success compareRecordsClosed' : 'compareRecordsClosed'}>
      <CompareDetail className='recordRow' style={{gridColumn: '2', justifyContent: 'flex-end'}}>
        <DetailWrapper className={record.discrepancy === 'provided' ? 'provided' : (record.discrepancy === 'active' || record.discrepancy === 'rejected') ? `${record.assignedTier}` : ''}>
          {record.label}
        </DetailWrapper>
      </CompareDetail>
      {record.publicRecordValue ?
        <CompareDetail className="recordRow" style={{gridColumn: '3', justifyContent: 'center'}}>
          {record.label.includes('Interest') ?
            <>
              {parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%
            </>
          : record.label === 'Assessed Value' || record.label === 'Land Value' || record.label === 'Improvements Value' || record.label === 'Close Price' || record.label === 'Last Sale Price' || record.label === 'Assessed Land Value' || record.label === 'Assessed Improvement Value' || record.label === 'Primary Mortgage Amount' ?
            <>
              ${parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
            </>
          : record.label === 'Tax Annual Amount' ?
            <>
              ${parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
            </>
          : record.label === 'Latitude' || record.label === 'Longitude' || record.label === 'Rooms' || record.label === 'Bedrooms' || record.label === 'Bathrooms Full' || record.label === 'Bathrooms Total' ?
            <>
              {parseFloat(record.publicRecordValue)}
            </>
          : record.label === 'Living Area' || record.label === 'Garage Area' || record.label === 'Building Area Total' || record.label === 'Gross Area' || record.label === 'Lot Size Square Feet' ?
            <>
              {parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
            </>
          :
            record.publicRecordValue
          }
        </CompareDetail>
      :
        <CompareDetail className="recordRow" style={{gridColumn: '3', justifyContent: 'center'}}>
          <AccentInfo>-empty-</AccentInfo>
        </CompareDetail>
      }
      <CompareDetail className="recordRow" style={{gridColumn: '4', justifyContent: 'center'}}>
        {record.currentValue ?
          <DetailWrapper className={record.discrepancy === 'active' ? "active" : record.discrepancy === 'rejected' ? "rejected" : record.discrepancy === 'resolved' ? 'resolved' : record.discrepancy === 'inactive' ? 'inactive' : ''}>
            {record.label.includes('Interest') ?
              <>
                {parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%
              </>
            : record.label === 'Assessed Value' || record.label === 'Land Value' || record.label === 'Improvements Value' || record.label === 'Close Price' || record.label === 'Last Sale Price' || record.label === 'Assessed Land Value' || record.label === 'Assessed Improvement Value' || record.label === 'Primary Mortgage Amount' ?
              <>
                ${parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
              </>
            : record.label === 'Tax Annual Amount' ?
              <>
                ${parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
              </>
            : record.label === 'Latitude' || record.label === 'Longitude' || record.label === 'Rooms' || record.label === 'Bedrooms' || record.label === 'Bathrooms Full' || record.label === 'Bathrooms Total' ?
              <>
                {parseFloat(record.currentValue)}
              </>
            : record.label === 'Living Area' || record.label === 'Garage Area' || record.label === 'Building Area Total' || record.label === 'Gross Area' || record.label === 'Lot Size Square Feet' ?
              <>
                {parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
              </>
            :
              record.currentValue
            }
          </DetailWrapper>
        :
          <DetailWrapper className={record.discrepancy === 'active' ? "active fine empty" : record.discrepancy === 'rejected' ? "rejected fine empty" : record.discrepancy === 'resolved' ? 'resolved fine empty' : record.discrepancy === 'inactive' ? 'gray fine empty' : ''} style={{fontWeight: '400'}}>
            -empty-
          </DetailWrapper>
        }
      </CompareDetail>
      {editIsFetching ?
        <CompareDetail style={{gridColumn: '4', gridRow: '2', justifyContent: 'center', height: '10rem'}}>
          <CircularProgress 
            sx={{ color: `${theme.palette.primary.main}`, margin: 'auto' }}
            size={24}
          /> 
        </CompareDetail>
      : fieldEditing === '' && (editingRecords || record.status !== 'inactive') ?
        <CompareDetail 
          className='recordRow'
          style={{gridColumn: '5', justifyContent: 'center', paddingRight: '0.5rem'}}
          onClick={
            record.status === 'discrepancy' ? () => handleWorkFlow('discrepancy', recordField) 
            : record.status === 'edited' || record.status === 'provided' ? () => handleWorkFlow('undo', recordField)
            // : (record.status === 'edited' || record.status === 'inactive' || record.status === 'initial') ? () => handleWorkFlow('undo', recordField)
            : () => handleWorkFlow('custom', recordField)
          }
        >
          <CompareLink 
            className="gray small"
            style={{fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}}
          >
            {record.status === 'discrepancy' ?
              `resolve`
            : record.status === 'edited' || record.status === 'provided' ?
              <UndoIcon style={{height: '1.2rem', width: '1.2rem'}} />
            : valueType === 'boolean' ?
              <SwapHorizIcon style={{height: '1.2rem', width: '1.2rem'}} />
            :
              <EditIcon style={{height: '1.2rem', width: '1.2rem'}} />
            }
          </CompareLink>
        </CompareDetail>
      : (editingRecords || record.status !== 'inactive') && fieldEditing === recordField ?
        <EditSwitchboard recordField={recordField} workflowStage={workflowStage} handleWorkFlow={handleWorkFlow} handleSubmitEdit={handleSubmitEdit} valueType={valueType} record={record} editIsFetching={editIsFetching} dateFormat={dateFormat} rejectedDiscrepancies={rejectedDiscrepancies} leadTier={leadTier} />
      :
        <CompareDetail 
          className='recordRow'
          style={{gridColumn: '5', justifyContent: 'center', paddingRight: '0.5rem'}}
        />
      }
    </ListRow>
  )
}

export default RecordRow