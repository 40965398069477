import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from "@mui/styles";
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';

const PropertySearchGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Property Search" Component
      </GuideText>
      <GuideText className="medium">
        allows any user to retrieve available public information on any property in real time.
      </GuideText>
      <GuideText>
        * There are a limited amount of manual searches your team can perform monthly, which is tracked in the navigation sidebar.
      </GuideText>
      
      <Divider className="narrow" />
      
      <GuideText className="medium">
        Use the search tool to:
      </GuideText>
      <FlexWrapper className="alignCenter" style={{paddingTop: '1rem'}}>
        <InfoIcon style={{height: '2.2rem', width: '2.2rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 2rem'}} />
        <GuideText className="header">
          Retrieve Information
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Perform a search if you want information on a property but don't currently need it saved to your team's mortgage portfolio.
      </GuideText>
      <FlexWrapper className="alignCenter" style={{paddingTop: '1rem'}}>
        <AddHomeWorkIcon style={{height: '2.2rem', width: '2.2rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 2rem'}} />
        <GuideText className="header">
          Save Properties
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Just like saving a property from the Mortgage repository, this tool can be used to save a recently originated mortgage as a new entry in your portfolio.
      </GuideText>

      <Divider className="narrow" />

      <GuideText className="medium newParagraph">
        Use the scan tool to:
      </GuideText>
      <FlexWrapper className="alignCenter" style={{paddingTop: '1rem'}}>
        <SignalWifiStatusbarConnectedNoInternet4Icon style={{height: '2.2rem', width: '2.2rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 2rem'}} />
        <GuideText className="header">
          Check for discrepancies
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Scans the most up to date public records for any property already saved to your team's scrolls.
      </GuideText>
      <GuideText className="twoIndent bottom">
        If discrepancies are discovered, the user will be prompted to either create a lead given the new information, or update the internal records without generating a new lead.
      </GuideText>
    </FlexWrapper>
  )
}

export default PropertySearchGuide