import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: [],
  status: 'idle',
  error: null
}

export const viewingLeadReportslice = createSlice({
  name: 'viewingLeadReports',
  initialState,
  reducers: {
    setViewingLeadReports(state, action) {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      state.data.unshift(payload.data.newReport)
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.newReport._id && payload.data.mission === 'single' || payload.data.mission === 'confirmUpdate') {
        let thisReport = state.data.find(report => report._id.toString() === payload.data.newReport._id.toString())
        if (thisReport) {
          let thisIndex = state.data.indexOf(thisReport)
          state.data[thisIndex] = payload.data.newReport
        }
      }
    })
    .addMatcher(apiSlice.endpoints.continueAwaitingUpdate.matchFulfilled, (state, {payload}) => {
      let thisReport = state.data.find(report => report._id.toString() === payload.data.newReport._id.toString())
      if (thisReport) {
        let thisIndex = state.data.indexOf(thisReport)
        state.data[thisIndex] = payload.data.newReport
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      let thisReport = state.data.find(report => report._id.toString() === payload.data.newReport._id.toString())
      if (thisReport) {
        let thisIndex = state.data.indexOf(thisReport)
        state.data[thisIndex] = payload.data.newReport
      }
    })
  }
})

export const { setViewingLeadReports } = viewingLeadReportslice.actions

export default viewingLeadReportslice.reducer

export const selectViewingLeadReports = state => state.viewingLeadReports.data