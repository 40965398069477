import React from "react";
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const ReportGenerationGuide = () => {

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Closure Reports" component
      </GuideText>
      <GuideText className="medium">
        stores exportable versions of documents that summarize completed investigations.
      </GuideText>

      <Divider className="narrow" />
      
      <GuideText className="halfIndent">
        Reports are meant to be easily digestible historical references to each investigation that your team completes using the system.
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        Whether a renegoitation, refinance, or dismissal, each closure will generate a report that remains attached to the mortgage in question. 
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        The status of the mortgage (principal, interest, and term remaining, etc.) is always included, along with a
      </GuideText>
      <GuideText className="halfIndent">
        full timeline of all the actions taken during the investigation.
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        Additional information is specific to the type of report. The standard layout and information included
      </GuideText>
      <GuideText className="halfIndent">
        in each type, however, can be customized to provide the most relevent reporting to your team.
      </GuideText>
      <GuideText className="halfIndent bottom">
        * Contact your App Admin to discuss any requests.
      </GuideText>
    </FlexWrapper>
  )
}

export default ReportGenerationGuide