import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_URL } from '../../config';

//* Above each cache is the component location where the query or mutation is called from
//* Note that these are in addition to the pre-fetches that are done within the App.js component upon initial load

//TODO: add headers
//TODO: switch a lot of these pessimistic loads to optimistic
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: builder => ({
    launchTeam: builder.mutation({
      query: body => ({
        url: `/launch/executive`,
        method: 'POST',
        body: body,
      })
    }),
    launchSandbox: builder.mutation({
      query: body => ({
        url: `/launch/sandbox`,
        method: 'POST',
        body: body,
      })
    }),
    establishRequestCredentials: builder.mutation({
      query: body => ({
        url: `/auth/establishCredentials`,
        method: 'PATCH',
        body: body,
      })
    }),
    createGuestAccount: builder.mutation({
      query: body => ({
        url: `/auth/create/guest`,
        method: 'POST',
        body: body
      }),
    }),
    loginReturningUser: builder.mutation({
      query: body => ({
        url: `/auth/login/returning/user`,
        method: 'PATCH',
        body: body
      }),
    }),
    selectLoginByEmail: builder.mutation({
      query: body => ({
        url: `/login/select/email`,
        method: 'POST',
        body: body
      }),
    }),
    logReturningVisit: builder.mutation({
      query: body => ({
        url: `/auth/log/returning`,
        method: 'PATCH',
        body: body,
      })
    }),
    initialLoad: builder.mutation({
      query: body => ({
        url: `/load/initial/${body.userId}`,
        method: 'GET',
        headers: { 'authorization': `${body.authToken}` }
      })
    }),
    uploadCSVMortgages: builder.mutation({
      query: body => ({
        url: `/uploads/mortgages/csv`,
        method: 'POST',
        body: body
      }),
    }),
    deleteUploadReport: builder.mutation({
      query: body => ({
        url: `/uploads/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    dismissLead: builder.mutation({
      query: body => ({
        url: `/activeLeads/dismiss`,
        method: 'PATCH',
        body: body
      }),
    }),
    setStatusInvestigating: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/investigating`,
        method: 'PATCH',
        body: body
      }),
    }),
    setStatusClosing: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/closing`,
        method: 'PATCH',
        body: body
      }),
    }),
    setStatusFinalized: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/finalized`,
        method: 'POST',
        body: body
      }),
    }),
    verifyPublicRecords: builder.mutation({
      query: body => ({
        url: `/activeLeads/verifyPublicRecords`,
        method: 'PATCH',
        body: body
      }),
    }),
    saveLeadTargetChanges: builder.mutation({
      query: body => ({
        url: `/activeLeads/targets/save`,
        method: 'PATCH',
        body: body
      }),
    }),
    changeTargetOutcome: builder.mutation({
      query: body => ({
        url: `/activeLeads/changeOutcome`,
        method: 'PATCH',
        body: body
      }),
    }),
    addLeadTag: builder.mutation({
      query: body => ({
        url: `/activeLeads/addLeadTag`,
        method: 'PATCH',
        body: body
      }),
    }),
    updateMortgageAssignees: builder.mutation({
      query: body => ({
        url: `/mortgages/add/assignees`,
        method: 'PATCH',
        body: body
      }),
    }),
    addLeadAssignees: builder.mutation({
      query: body => ({
        url: `/activeLeads/add/assignees`,
        method: 'PATCH',
        body: body
      }),
    }),
    reloadTagViews: builder.mutation({
      query: body => ({
        url: `/tagViews/reload`,
        method: 'POST',
        body: body
      })
    }),
    reloadLeadGenerationQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/teamMonthlyStats/team/quarterBreakdown/leadGeneration/reload`,
        method: 'POST',
        body: body
      })
    }),
    reloadRenegotiationQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/teamMonthlyStats/team/quarterBreakdown/renegotiation/reload`,
        method: 'POST',
        body: body
      })
    }),
    reloadRefinanceQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/teamMonthlyStats/team/quarterBreakdown/refinance/reload`,
        method: 'POST',
        body: body
      })
    }),
    addMortgageNote: builder.mutation({
      query: body => ({
        url: `/mortgages/notes/add`,
        method: 'POST',
        body: body
      }),
    }),
    editOneMortgageDetail: builder.mutation({
      query: body => ({
        url: `/mortgages/update/edit/one`,
        method: 'PATCH',
        body: body
      }),
    }),
    undoOneMortgageDetail: builder.mutation({
      query: body => ({
        url: `/mortgages/update/undo/one`,
        method: 'PATCH',
        body: body
      }),
    }),
    resolveOneMortgageDetail: builder.mutation({
      query: body => ({
        url: `/mortgages/update/resolve/one`,
        method: 'PATCH',
        body: body
      }),
    }),
    resolveAllDiscrepancies: builder.mutation({
      query: body => ({
        url: `/mortgages/update/resolve/all`,
        method: 'PATCH',
        body: body
      }),
    }),
    checkForMortgageDuplicates: builder.mutation({
      query: body => ({
        url: `/mortgages/duplicates`,
        method: 'POST',
        body: body
      }),
    }),
    deleteMortgage: builder.mutation({
      query: body => ({
        url: `/mortgages/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    updateMortgageTags: builder.mutation({
      query: body => ({
        url: `/mortgages/tags/add`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteMortgagePublicRecordAndCurrentDetails: builder.mutation({
      query: body => ({
        url: "mortgages/delete/publicRecordDetails",
        method: 'PATCH',
        body: body,
      })
    }),
    provideFinancialInformation: builder.mutation({
      query: body => ({
        url: "mortgages/provide/financials",
        method: 'PATCH',
        body: body,
      })
    }),
    updateSecuritySetting: builder.mutation({
      query: body => ({
        url: `/teams/update/security`,
        method: 'PATCH',
        body: body
      })
    }),
    selectNewUserActionsPermissionsSet: builder.mutation({
      query: body => ({
        url: `/users/selectRolePermissions`,
        method: 'PATCH',
        body: body
      })
    }),
    updateUserDefaultLocations: builder.mutation({
      query: body => ({
        url: `/users/settings/defaultLocations`,
        method: 'PATCH',
        body: body
      })
    }),
    updateTeamDefaultTargetParameters: builder.mutation({
      query: body => ({
        url: `/teams/settings/defaultTargets`,
        method: 'PATCH',
        body: body
      })
    }),
    updateTeamDiscrepancyParamterSelection: builder.mutation({
      query: body => ({
        url: `/teams/parameter/activeStatus`,
        method: 'PATCH',
        body: body
      })
    }),
    updateSweepParamterSettings: builder.mutation({
      query: body => ({
        url: `/teams/parameter/settings`,
        method: 'PATCH',
        body: body
      })
    }),
    removeReportNotifyUser: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/report`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUserLeadNotification: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/lead`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUserNewAssignmentNotification: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/newAssignment`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUser: builder.mutation({
      query: body => ({
        url: `/users/deleteUser`,
        method: 'DELETE',
        body: body,
      })
    }),
    recordSweepQuery: builder.mutation({
      query: body => ({
        url: `/queries/recordSweep/query`,
        method: 'POST',
        body: body,
      })
    }),
    recordQueryPerformance: builder.mutation({
      query: body => ({
        url: `/queries/recordSweep/record`,
        method: 'POST',
        body: body,
      })
    }),
    recordUploadPerformance: builder.mutation({
      query: body => ({
        url: `/uploads/record`,
        method: 'POST',
        body: body,
      })
    }),
    runPropertySearch: builder.mutation({
      query: body => ({
        url: `/queries/property/search`,
        method: 'POST',
        body: body,
      })
    }),
    runPropertySearchForUpload: builder.mutation({
      query: body => ({
        url: `/queries/property/search/upload`,
        method: 'POST',
        body: body,
      })
    }),
    saveProperty: builder.mutation({
      query: body => ({
        url: `/uploads/mortgages/search`,
        method: 'POST',
        body: body,
      })
    }),
    deleteActionOrErrorLog: builder.mutation({
      query: body => ({
        url: `/teams/actionAndErrorLog/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    createCronTicker: builder.mutation({
      query: body => ({
        url: `/scheduledTasks/cronTicker/create`,
        method: 'POST',
        body: body,
      })
    }),
    editMortgage: builder.mutation({
      query: body => ({
        url: `/mortgages/edit`,
        method: 'PATCH',
        body: body,
      })
    }),
    dropAllFromDatabase: builder.mutation({
      query: body => ({
        url: `/database/drop/all`,
        method: 'DELETE',
        body: body,
      })
    }),
  })
})

export const { usePrefetch, useUploadCSVMortgagesMutation, useSetStatusInvestigatingMutation, useSetStatusClosingMutation, useSetStatusFinalizedMutation, useEditOneMortgageDetailMutation, useAddMortgageNoteMutation, useUpdateTeamDefaultTargetParametersMutation, useUpdateTeamDiscrepancyParamterSelectionMutation, useSaveLeadTargetChangesMutation, useDismissLeadMutation, useAddLeadAssigneesMutation, useUpdateMortgageAssigneesMutation, useChangeTargetOutcomeMutation, useReloadLeadGenerationQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation, useReloadRefinanceQuarterlyBreakdownMutation, useRemoveReportNotifyUserMutation, useAddLeadTagMutation, useDeleteUserLeadNotificationMutation, useDeleteUserNewAssignmentNotificationMutation, useInitialLoadMutation, useDeleteUserMutation, useCheckForMortgageDuplicatesMutation, useDeleteUploadReportMutation, useDeleteMortgageMutation, useResolveAllDiscrepanciesMutation, useDeleteActionOrErrorLogMutation, useCreateCronTickerMutation, useUpdateMortgageTagsMutation, useUpdateSweepParamterSettingsMutation, useCreateGuestAccountMutation, useEstablishRequestCredentialsMutation, useLoginReturningUserMutation, useLaunchSandboxMutation, useLaunchTeamMutation, useLogReturningVisitMutation, useVerifyPublicRecordsMutation, useDeleteMortgagePublicRecordAndCurrentDetailsMutation, useSelectNewUserActionsPermissionsSetMutation, useRunPropertySearchMutation, useSavePropertyMutation, useSelectLoginByEmailMutation, useProvideFinancialInformationMutation, useReloadTagViewsMutation, useUpdateSecuritySettingMutation, useRecordQueryPerformanceMutation, useRecordSweepQueryMutation, useRecordUploadPerformanceMutation, useEditMortgageMutation, useDropAllFromDatabaseMutation, useUndoOneMortgageDetailMutation, useResolveOneMortgageDetailMutation, useRunPropertySearchForUploadMutation, useUpdateUserDefaultLocationsMutation } = apiSlice