import React, { useEffect, useState } from "react"
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import LeadTagDropdown from "../../../../DropDowns/LeadTagDropdown"
import MortgageTagDropdown from "../../../../DropDowns/MortgageTagDropwdown";
import { selectTeamLeadTags, selectTeamMortgageTags } from "../../../../../slices/teamAndUserSettingsSlice";
import { useAddLeadTagMutation, useUpdateMortgageTagsMutation } from "../../../../../slices/api/apiSlice";
import { selectAssignLeadTagPerm, selectAssignMortgageTagPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { ComponentSectionRow, ErrorWrapper, Label, InputWrapper, FlexWrapper, StatusAndLabelWrapper, Button } from "../../../../../StyledComponents";

const AssignOrCreateTag = ({ leadData, userFullName, teamId, dashPerspective, userId, activeLead, mortgageData, setSaveSuccess, setIsError }) => {
  const theme = useTheme()

  const teamLeadTags = useSelector(selectTeamLeadTags)
  const teamMortgageTags = useSelector(selectTeamMortgageTags)
  const assignLeadTagPerm = useSelector(selectAssignLeadTagPerm)
  const assignMortgageTagPerm = useSelector(selectAssignMortgageTagPerm)
  
  const [addLeadTag] = useAddLeadTagMutation()
  const [updateMortgageTags] = useUpdateMortgageTagsMutation()

  const [leadSaveFetching, setLeadSaveFetching] = useState(false)
  const [mortgageSaveFetching, setMortgageSaveFetching] = useState(false)
  const [availableLeadTags, setAvailableLeadTags] = useState(teamLeadTags)
  const [availableMortgageTags, setAvailableMortgageTags] = useState(teamMortgageTags)
  const [newLeadTag, setNewLeadTag] = useState([''])
  const [newMortgageTag, setNewMortgageTag] = useState([''])
  const [showLeadAssignSave, setShowLeadAssignSave] = useState(false)
  const [showMortgageAssignSave, setShowMortgageAssignSave] = useState(false)
  const [statusLeadTagsEmpty, setStatusLeadTagsEmpty] = useState(false)
  const [statusMortgageTagEmpty, setStatusMortgageTagEmpty] = useState(false)
  const [createTag, setCreateTag] = useState(null)

  const handleSetNewLeadTag = (event) => {
    setShowLeadAssignSave(true)
    setNewLeadTag(event.target.value)
    setStatusLeadTagsEmpty(false)
  }
  const handleSetNewMortgageTag = (event) => {
    setShowMortgageAssignSave(true)
    setNewMortgageTag(event.target.value)
    setStatusMortgageTagEmpty(false)
  }

  const handleSaveNewTag = async (action, entity) => {
    setSaveSuccess(false)
    let leadId = null
    if (leadData) {
      leadId = leadData._id
    }
    let noTags = false
    if (entity === 'lead') {
      if (newLeadTag.length === 0) {
        setStatusLeadTagsEmpty(true)
        noTags = true
      }
    } else {
      if (newMortgageTag.length === 0) {
        setStatusLeadTagsEmpty(true)
        noTags = true
      }
    }
    if (!noTags && entity === 'lead') {
      setLeadSaveFetching(true)
      await addLeadTag({
        action: action,
        newTag: newLeadTag,
        userFullName: userFullName,
        leadId: leadId,
        dashPerspective: dashPerspective,
        userId: userId,
        mortgageId: mortgageData._id,
      })
      setNewLeadTag([''])
      setLeadSaveFetching(false)
      setShowLeadAssignSave(false)
    } else if (!noTags && entity === 'mortgage') {
      setMortgageSaveFetching(true)
      await updateMortgageTags({
        reqOrigin: 'lead',
        action: action,
        newTag: newMortgageTag,
        userFullName: userFullName,
        leadId: leadId,
        userId: userId,
        mortgageId: mortgageData._id,
      })
      setNewMortgageTag([''])
      setMortgageSaveFetching(false)
      setShowMortgageAssignSave(false)
    }
    setCreateTag(null)
  }

  useEffect(() => {
    let newAvailableLeadTags = [...teamLeadTags]
    if (leadData && leadData.tags) {
      for (let i = 0; i < leadData.tags.length; i++) {
        let thisTag = newAvailableLeadTags.find(tag => tag._id.toString() === leadData.tags[i].tagId.toString())
        let thisIndex = newAvailableLeadTags.indexOf(thisTag)
        newAvailableLeadTags.splice(thisIndex, 1)
      }
      setAvailableLeadTags(newAvailableLeadTags)
    }
  }, [leadData])
  useEffect(() => {
    let newAvailableMortgageTags = [...teamMortgageTags]
    if (mortgageData.tags) {
      for (let i = 0; i < mortgageData.tags.length; i++) {
        let thisTag = newAvailableMortgageTags.find(tag => tag._id.toString() === mortgageData.tags[i].tagId.toString())
        let thisIndex = newAvailableMortgageTags.indexOf(thisTag)
        newAvailableMortgageTags.splice(thisIndex, 1)
      }
    }
    setAvailableMortgageTags(newAvailableMortgageTags)
  }, [mortgageData])

  return (
    <ComponentSectionRow className={"addTags header gray" + (createTag !== null ? ' closed open' : ' closed')} style={{marginTop: 'auto'}}>
      <FlexWrapper className="justifyCenter" style={{gridRow: '1', gridColumn: '1'}}>
        {activeLead &&
          <FlexWrapper 
            className={((createTag === null) ? 'onDisplay column' : 'hidden column')}
            style={{width: 'fit-content', margin: activeLead ? '0 3rem 0 auto' : '0 auto'}}
          >
            <FlexWrapper>
              <InputWrapper>
                <StatusAndLabelWrapper>
                  <Label>Assign Lead Tag</Label>
                  <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                    {statusLeadTagsEmpty === true && (
                      <ErrorWrapper>
                        <ErrorIcon />
                        Select a tag
                      </ErrorWrapper>
                    )}
                  </FlexWrapper>
                </StatusAndLabelWrapper>
                <LeadTagDropdown
                  style={{width: '100%'}}
                  value={newLeadTag}
                  handleChange={handleSetNewLeadTag}
                  items={availableLeadTags}
                  disabled={!assignLeadTagPerm}
                />
              </InputWrapper>
              {showLeadAssignSave ?
                <Tooltip
                  componentsProps={{tooltip: {sx: {
                    color: `${theme.tooltip.label.color}`,
                    backgroundColor: `${theme.tooltip.label.background}`,
                    border: `0.1rem solid ${theme.tooltip.label.border}`,
                    borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                    opacity: '0',
                    borderRadius: '1rem',
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    padding: '0.5rem 1rem',
                  }}}}
                  PopperProps={{modifiers: [{
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  }]}}
                  title={newLeadTag.length > 0 && assignLeadTagPerm ?
                    <FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Save</span></FlexWrapper>
                  : !assignLeadTagPerm || leadSaveFetching ?
                    null
                  :
                    <FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>No Lead Tags Selected</span></FlexWrapper>
                  } 
                  placement="top"
                  arrow
                >
                  <Button 
                    onClick={(!leadSaveFetching && assignLeadTagPerm) ? () => handleSaveNewTag('add', 'lead') : null}
                    className={!assignLeadTagPerm ? "primaryDisabled notes"  : leadSaveFetching ? "primaryLoading notes" : "primary notes"}
                    style={{marginBottom: '1.5rem'}}
                  >
                    <SaveIcon />
                  </Button>
                </Tooltip>
              :
                <FlexWrapper style={{width: '3rem', minWidth: '3rem', maxWidth: '3rem'}} />
              }
            </FlexWrapper>
          </FlexWrapper>
        }
        <FlexWrapper 
          className={((createTag === null) ? 'onDisplay column' : 'hidden column')}
          style={{width: 'fit-content', margin: activeLead ? '0 auto 0 0' : '0 auto'}}
        >
          <FlexWrapper>
            <InputWrapper style={{marginLeft: '3rem'}}>
              <StatusAndLabelWrapper>
                <Label>
                  Assign Mortgage Tag
                </Label>
                <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                  {statusMortgageTagEmpty === true && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Select a tag
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </StatusAndLabelWrapper>
              <MortgageTagDropdown
                style={{width: '100%'}}
                value={newMortgageTag}
                handleChange={handleSetNewMortgageTag}
                items={availableMortgageTags}
                disabled={!assignMortgageTagPerm}
              />
            </InputWrapper>
            {showMortgageAssignSave ?
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.label.color}`,
                  backgroundColor: `${theme.tooltip.label.background}`,
                  border: `0.1rem solid ${theme.tooltip.label.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                  opacity: '0',
                  borderRadius: '1rem',
                  width: 'fit-content',
                  minWidth: 'fit-content',
                  padding: '0.5rem 1rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                }]}}
                title={newMortgageTag.length > 0 && assignMortgageTagPerm ?
                  <FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Save</span></FlexWrapper>
                : !assignMortgageTagPerm || mortgageSaveFetching ?
                  null
                :
                  <FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>No Mortgage Tags Selected</span></FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <Button 
                  onClick={(!mortgageSaveFetching && assignMortgageTagPerm) ? () => handleSaveNewTag('add', 'mortgage') : null}
                  className={!assignMortgageTagPerm ? "primaryDisabled notes"  : mortgageSaveFetching ? "primaryLoading notes" : "primary notes"}
                  style={{marginBottom: '1.5rem'}}
                >
                  <SaveIcon />
                </Button>
              </Tooltip>
            :
              <FlexWrapper style={{width: '3rem', minWidth: '3rem', maxWidth: '3rem'}} />
            }
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </ComponentSectionRow>
  )
}

export default AssignOrCreateTag