import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'


const initialState = {
  data: {},
  status: 'idle',
  error: null
}

export const mortgageDataModelSlice = createSlice({
  name: 'mortgageDataModel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        if (payload.data.mortgageDataModel) {
          state.data = payload.data.mortgageDataModel
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordQueryPerformance.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.updatedSweepParameters.length; i++) {
        let thisParameter = {}
        let subCat = payload.data.updatedSweepParameters[i].subCategory
        if (subCat === 'identifiers') {
          thisParameter = state.data[0].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[0].fields.indexOf(thisParameter)
          state.data[0].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'lot') {
          thisParameter = state.data[1].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[1].fields.indexOf(thisParameter)
          state.data[1].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'address') {
          thisParameter = state.data[2].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[2].fields.indexOf(thisParameter)
          state.data[2].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'summary') {
          thisParameter = state.data[3].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[3].fields.indexOf(thisParameter)
          state.data[3].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'sale') {
          thisParameter = state.data[4].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[4].fields.indexOf(thisParameter)
          state.data[4].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'living') {
          thisParameter = state.data[5].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[5].fields.indexOf(thisParameter)
          state.data[5].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'building') {
          thisParameter = state.data[6].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[6].fields.indexOf(thisParameter)
          state.data[6].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'assessment') {
          thisParameter = state.data[7].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[7].fields.indexOf(thisParameter)
          state.data[7].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'tax') {
          thisParameter = state.data[8].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[8].fields.indexOf(thisParameter)
          state.data[8].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'owner1') {
          thisParameter = state.data[9].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[9].fields.indexOf(thisParameter)
          state.data[9].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'owner2') {
          thisParameter = state.data[10].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[10].fields.indexOf(thisParameter)
          state.data[10].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'primaryMortgage') {
          thisParameter = state.data[11].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[11].fields.indexOf(thisParameter)
          state.data[11].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        } else if (subCat === 'secondaryMortgage') {
          thisParameter = state.data[12].fields.find(field => field._id === payload.data.updatedSweepParameters[i]._id)
          let thisIndex = state.data[12].fields.indexOf(thisParameter)
          state.data[12].fields[thisIndex] = payload.data.updatedSweepParameters[i]
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateSweepParamterSettings.matchFulfilled, (state, {payload}) => {
      let thisParameter = {}
      let subCat = payload.data.newParameter.subCategory
      if (subCat === 'identifiers') {
        thisParameter = state.data[0].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[0].fields.indexOf(thisParameter)
        state.data[0].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'lot') {
        thisParameter = state.data[1].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[1].fields.indexOf(thisParameter)
        state.data[1].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'address') {
        thisParameter = state.data[2].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[2].fields.indexOf(thisParameter)
        state.data[2].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'summary') {
        thisParameter = state.data[3].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[3].fields.indexOf(thisParameter)
        state.data[3].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'sale') {
        thisParameter = state.data[4].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[4].fields.indexOf(thisParameter)
        state.data[4].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'living') {
        thisParameter = state.data[5].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[5].fields.indexOf(thisParameter)
        state.data[5].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'building') {
        thisParameter = state.data[6].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[6].fields.indexOf(thisParameter)
        state.data[6].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'assessment') {
        thisParameter = state.data[7].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[7].fields.indexOf(thisParameter)
        state.data[7].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'tax') {
        thisParameter = state.data[8].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[8].fields.indexOf(thisParameter)
        state.data[8].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'owner1') {
        thisParameter = state.data[9].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[9].fields.indexOf(thisParameter)
        state.data[9].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'owner2') {
        thisParameter = state.data[10].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[10].fields.indexOf(thisParameter)
        state.data[10].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'primaryMortgage') {
        thisParameter = state.data[11].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[11].fields.indexOf(thisParameter)
        state.data[11].fields[thisIndex] = payload.data.newParameter
      } else if (subCat === 'secondaryMortgage') {
        thisParameter = state.data[12].fields.find(field => field._id === payload.data.parameterId)
        let thisIndex = state.data[12].fields.indexOf(thisParameter)
        state.data[12].fields[thisIndex] = payload.data.newParameter
      }
    })
  }
})

export default mortgageDataModelSlice.reducer

export const selectMortgageDataModel = state => state.mortgageDataModel.data