import React, { useState } from "react";
import 'react-csv-importer/dist/index.css';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { selectDismissLeadPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { Button, ButtonWrapper, ModalContentContainer, ComponentHeader, SubHeader, FlexWrapper, Typography } from "../../../../../StyledComponents";
import { useDismissLeadMutation, useReloadRefinanceQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation, useResolveAllDiscrepanciesMutation } from "../../../../../slices/api/apiSlice";

const DismissLead = ({ handleCloseModal, userFullName, teamId, leadData, userId, dashPerspective }) => {
  const theme = useTheme();

  const dismissLeadPerm = useSelector(selectDismissLeadPerm)

  const [isFetching, setIsFetching] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [dismissLead] = useDismissLeadMutation()
  const [resolveAllDiscrepancies] = useResolveAllDiscrepanciesMutation()
  const [realoadRefinanceQuarterBreakdown] = useReloadRefinanceQuarterlyBreakdownMutation()
  const [reloadRenegotiationQuarterlyBreakdown] = useReloadRenegotiationQuarterlyBreakdownMutation()

  const handleDismissLead = async () => {
    setIsFetching(true)
    // let resolveResp = await resolveAllDiscrepancies({
    //   userId: userId,
    //   userFullName: userFullName,
    //   teamId: teamId,
    //   mortgageId: leadData.belongsToMortgage,
    //   mission: 'dismissInvestigation'
    // })
    // if (resolveResp.error) {
    //   setIsError(true)
    // } else {
      let resp = await dismissLead({
        leadId: leadData._id,
        userId: userId,
        dashPerspective: dashPerspective,
        teamId: teamId,
        mortgageId: leadData.belongsToMortgage,
        userFullName: userFullName,
      })
      if (!resp.error && resp.data.message.includes('successful')) {
        setIsSuccess(true)
        if (leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION') {
          await reloadRenegotiationQuarterlyBreakdown({
            userId: userId,
            teamId: teamId,
            leadTier: leadData.tier,
            actionTaken: 'dismissed',
            closureType: leadData.targetOutcome,
          })
        } else {
          await realoadRefinanceQuarterBreakdown({
            userId: userId,
            teamId: teamId,
            leadTier: leadData.tier,
            actionTaken: 'dismissed',
            closureType: leadData.targetOutcome,
          })
        }
      } else {
        setIsError(true)
      }
    // }
    setIsFetching(false)
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
            <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
            Success
          </span>
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        <ComponentHeader className="modal">Dismiss Lead</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto'}}>
            <Typography className="fitContentHeight justifyEnd small">
              Thie investigation has been dismissed.
            </Typography>
          </SubHeader>
        :
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto', padding: '0 1rem', width: '60rem'}}>
            <Typography className="fitContentHeight justifyEnd small">
              Dismissing the investigation will resolve all discrepancies to match public records.<br/>
              Record any summary notes to serve as a future reference in the Dismissal Report.<br/>
            </Typography>
          </SubHeader>
        }

        {isSuccess ?
          <ButtonWrapper>
            <Button className="neutral" onClick={() => handleCloseModal()}>Close</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            {isFetching ?
              <Button className="dismissLoading">
                <CircularProgress
                  sx={{ color: `${theme.palette.common.white}` }}
                  size={18}
                />
              </Button>
            :
              <Button className={dismissLeadPerm ? "dismiss" : "dismissDisabled"} onClick={dismissLeadPerm ? () => handleDismissLead() : null}>Continue</Button>
            }
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default DismissLead;