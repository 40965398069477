import React, { useState } from "react"
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from "./DeleteModal/DeleteModal";
import DataObjectIcon from '@mui/icons-material/DataObject';
import UploadReportsModal from "./ReportModal/UploadReportsModal";
import { Dialog, useTheme } from "@mui/material";
import { selectTeamUploadReports } from "../../../../../../slices/teamAdminDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { selectDeleteUploadReportPerm } from "../../../../../../slices/userActionsPermissionsSlice";
import { setUploadReportNavMenuSelection } from "../../../../../../slices/sessionDataSlice";
import { ActionIconWrapper, ColumnContent, ComponentBorder, ComponentContainer, ComponentSectionRow, FlexWrapper, HeaderText, RowsContainer } from "../../../../../../StyledComponents";

const Reports = ({ userId, teamId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const teamUploadReports = useSelector(selectTeamUploadReports)
  const deleteUploadReportPerm = useSelector(selectDeleteUploadReportPerm)

  const [viewingReport, setViewingReport] = useState(false)
  const [openUploadReportModal, setOpenUploadReportModal] = useState(false)
  const [openDeleteUploadReportModal, setOpenDeleteUploadReportModal] = useState(false)

  const handleOpenUploadReportModal = (report) => {
    setViewingReport(report)
    dispatch( setUploadReportNavMenuSelection('summary'))
    setOpenUploadReportModal(true)
  }
  const handleCloseUploadReportModal = () => {
    setOpenUploadReportModal(false)
  }

  const handleOpenDeleteUploadReportModal = (report) => {
    setViewingReport(report)
    dispatch( setUploadReportNavMenuSelection('summary'))
    setOpenDeleteUploadReportModal(true)
  }
  const handleCloseDeleteUploadReportModal = () => {
    setOpenDeleteUploadReportModal(false)
  }

  return (
    <>
      <ComponentContainer className="detailsContainer" style={{height: '49%'}}>
        <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
          <ComponentSectionRow className="uploadReports header">
            <ColumnContent className="header" style={{gridColumn: '1'}}>
              Index
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '2'}}>
              Activity
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '3'}}>
              Type
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '4'}}>
              Date
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '5'}}>
              Successful
            </ColumnContent>
            <ColumnContent className="header" style={{gridColumn: '6'}}>
              Errored
            </ColumnContent>
          </ComponentSectionRow>
          {teamUploadReports.length > 0 ?
            <RowsContainer style={{height: '100%'}}>
              {teamUploadReports.toReversed().map((row, index) => (
                <ComponentSectionRow key={row._id}className={(index%2 === 1 ? 'even ' : '') + 'uploadReports'}>
                  <ColumnContent className="detail small" style={{gridColumn: '1'}}>
                    {index + 1}
                  </ColumnContent>
                  <FlexWrapper className="justifyCenter" style={{gridColumn: '2'}}>
                    <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'}>
                      <InfoIcon onClick={() => handleOpenUploadReportModal(row)} style={{height: '2rem', width: '2rem'}} />
                    </ActionIconWrapper>
                    <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + (deleteUploadReportPerm ? '' : 'disabled ') + 'hover'}>
                      <DeleteIcon onClick={deleteUploadReportPerm ? () => handleOpenDeleteUploadReportModal(row) : null} style={{height: '2rem', width: '2rem'}} />
                    </ActionIconWrapper>
                  </FlexWrapper>
                  <ColumnContent className="detail small" style={{gridColumn: '3'}}>
                    {row.uploadType}
                  </ColumnContent>
                  <ColumnContent className="detail small" style={{gridColumn: '4'}}>
                    {row.date}
                  </ColumnContent>
                  <ColumnContent className="detail small" style={{gridColumn: '5'}}>
                    {row.numberSuccessess}
                  </ColumnContent>
                  <ColumnContent className="detail small" style={{gridColumn: '6'}}>
                    {row.numberErrors}
                  </ColumnContent>
                </ComponentSectionRow>
              ))}
            </RowsContainer>
          :
            <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
              <DataObjectIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '7rem', width: '7rem'}} />
              <HeaderText style={{fontSize: '1.4rem', color: `${theme.palette.common.grayTextLight}`}}>no uploads recorded</HeaderText>
            </FlexWrapper>
          } 
        </FlexWrapper>
        <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
        <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
        <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
        <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
      </ComponentContainer>

      <Dialog open={openUploadReportModal} onClose={handleCloseUploadReportModal} 
        PaperProps={{ sx: {width: "110rem", minWidth: "110rem", maxWidth: "110rem", height: "50rem", minHeight: "fit-content", maxHeight: "95%"}}}
      >
        <UploadReportsModal handleCloseUploadReportModal={handleCloseUploadReportModal} viewingReport={viewingReport} />
      </Dialog>
      <Dialog open={openDeleteUploadReportModal} onClose={handleCloseDeleteUploadReportModal} 
        PaperProps={{ sx: {width: "40rem", minWidth: "40rem", maxWidth: "40rem", height: "15rem", minHeight: "15rem", maxHeight: "15rem"}}}
      >
        <DeleteModal closeModal={handleCloseDeleteUploadReportModal} viewingReport={viewingReport} userId={userId} teamId={teamId} />
      </Dialog>
    </>
  )
}

export default Reports;