import React from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import { useTheme } from "@mui/styles";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const PerformanceGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Team Performance" Component
        {/* The "Team" and "Member Performance" Components */}
      </GuideText>
      <GuideText className="medium">
        provides metrics on the health of your portfolio as well as the outcomes achieved by your team.
        {/* provide metrics on the health of your portfolio as well as the outcomes achieved by your team. */}
      </GuideText>
      
      <Divider className="narrow" />
      
      <FlexWrapper className="fitContentHeight alignCenter">
        <QueryStatsIcon style={{color: `${theme.palette.primary.main}`, height: '1.8rem', width: '1.8rem'}} />
        <GuideText className="medium header" style={{paddingLeft: '0.8rem'}}>
          Team Lead Generation
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph twoIndent">
        This component displays statistics that reflect the overall health of your portfolio.<br/>
        It tracks the mortgages that are in good standing, how often new violations are detected, and the outcome of mitigating actions taken by your team, on both monthly and quarterly bases.
      </GuideText>
      <FlexWrapper className="fitContentHeight alignCenter" style={{padding: '2rem 0 0 0'}}>
        <QueryStatsIcon style={{color: `${theme.palette.primary.main}`, height: '1.8rem', width: '1.8rem'}} />
        <GuideText className="medium header" style={{paddingLeft: '0.8rem'}}>
          Team Renegotiations and Refinances
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph twoIndent">
        Statistics are kept for each respective closure type, including the number of investigations closed in each month, how often these investigations are being performed, and how the number of closures performed this month or quarter compares to the last. The gross revenue generated by refinances is also be recorded.
      </GuideText>

      <Divider className="narrow" style={{marginTop: '5rem'}} />

      {/* <FlexWrapper className="fitContentHeight alignCenter">
        <GroupsIcon style={{color: `${theme.palette.primary.main}`, height: '1.8rem', width: '1.8rem'}} />
        <GuideText className="medium header" style={{paddingLeft: '0.8rem'}}>
          Member Performance
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph twoIndent bottom">
        Similar statistics on closures, frequencies, and profits are kept for each team member, reflecting their involvement in investigations that were tracked through the system.
      </GuideText> */}
    </FlexWrapper>
  )
}

export default PerformanceGuide