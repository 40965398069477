import React, { useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@emotion/react";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectTeamDefaultTargets } from "../../../../../slices/teamAndUserSettingsSlice";
import { selectEditDefaultTargetsPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { useUpdateTeamDefaultTargetParametersMutation } from "../../../../../slices/api/apiSlice";
import { selectUpdateDefaultTargetsEnabled, setUpdateDefaultTargetsEnabled } from "../../../../../slices/functionAvailabilitySlice";
import { ErrorWrapper, Label, InputWrapper, StatusAndLabelWrapper, StyledInputElement, Button, FlexWrapper, ComponentSectionRow, ColumnContent, ComponentHeader, Typography } from "../../../../../StyledComponents";

const DefaultTargets = ({ teamId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const teamDefaultTargets = useSelector(selectTeamDefaultTargets)
  const editDefaultTargetsPerm = useSelector(selectEditDefaultTargetsPerm)
  const updateDefaultTargetsEnabled = useSelector(selectUpdateDefaultTargetsEnabled)

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [updateTeamDefaultTargetParameters] = useUpdateTeamDefaultTargetParametersMutation()

  const [defaultTermTarget, setDefaultTermTarget] = useState(parseInt(teamDefaultTargets.defaultTargetTerm))
  const [statusDefaultTerm, setStatusDefaultTerm] = useState(null)
  const defaultTerm = useRef()
  const [defaultInterestTarget, setDefaultInterestTarget] = useState(parseFloat(teamDefaultTargets.defaultTargetInterestRate))
  const [statusDefaultInterest, setStatusDefaultInterest] = useState(null)
  const defaultInterest = useRef()

  const handleTermTargetChange = () => {
    setIsSuccess(false)
    setIsError(false)
    let newValue = parseFloat(defaultTerm.current.value)
    let negativeTerm = Math.sign(newValue)
    if (newValue === teamDefaultTargets.defaultTargetTerm && parseFloat(defaultInterest.current.value) === parseFloat(teamDefaultTargets.defaultTargetInterestRate)) {
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      dispatch( setUpdateDefaultTargetsEnabled(true) )
    }
    if (defaultTerm.current.value.length === 0 || isNaN(newValue) || newValue > 999 || negativeTerm === -1 || !Number.isInteger(newValue)) {
      if (!Number.isInteger(newValue)) {
        setStatusDefaultTerm("float")
      }
      if (isNaN(newValue)) {
        setStatusDefaultTerm("nonNumber")
      }
      if (newValue > 999) {
        setStatusDefaultTerm("tooLarge")
      }
      if (negativeTerm === -1) {
        setStatusDefaultTerm("negative")
      }
      if (defaultTerm.current.value.length === 0) {
        setStatusDefaultTerm("missing")
      }
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      setStatusDefaultTerm(null)
    }
    setDefaultTermTarget(defaultTerm.current.value)
  }
  const handleInterestTargetChange = () => {
    setIsSuccess(false)
    setIsError(false)
    let newValue = parseFloat(defaultInterest.current.value)
    let negativeTerm = Math.sign(newValue)
    if (newValue === parseFloat(teamDefaultTargets.defaultTargetInterestRate) && parseInt(defaultTerm.current.value) === teamDefaultTargets.defaultTargetTerm) {
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      dispatch( setUpdateDefaultTargetsEnabled(true) )
    }
    if (defaultInterest.current.value.length === 0 || isNaN(newValue) || newValue > 100 || negativeTerm === -1 || newValue === 0) {
      if (isNaN(newValue)) {
        setStatusDefaultInterest("nonNumber")
      }
      if (newValue > 100) {
        setStatusDefaultInterest("tooLarge")
      }
      if (negativeTerm === -1) {
        setStatusDefaultInterest("negative")
      }
      if (newValue === 0) {
        setStatusDefaultInterest("zero")
      }
      if (defaultInterest.current.value.length === 0) {
        setStatusDefaultInterest("missing")
      }
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      setStatusDefaultInterest(null)
    }
    setDefaultInterestTarget(defaultInterest.current.value)
  }

  const handleSubmit = async () => {
    setIsFetching(true)
    let resp = await updateTeamDefaultTargetParameters({
      teamId: teamId,
      term: defaultTermTarget,
      interest: defaultInterestTarget,
    })
    if (resp.error) {
      setIsError(true)
    } else if (resp.data.message.includes("success")) {
      setIsSuccess(true)
      dispatch( setUpdateDefaultTargetsEnabled(false) )
    } else {
      setIsError(true)
    }
    setIsFetching(false)
  }

  return (
    <FlexWrapper className="column alignCenter fitContentHeight">
      {isSuccess ?
        <ComponentHeader className="green">
          <CheckIcon style={{height: '1.9rem', width: '1.9rem', margin: '0 0.5rem 0.2rem 0'}} />
          Default Refinance Targets Updated
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="red">
          <ErrorIcon style={{height: '1.9rem', width: '1.9rem', margin: '0 0.5rem 0.2rem 0'}} />
          Default Refinance Targets Update Failed
        </ComponentHeader>
      :
        <ComponentHeader>
          Default Refinance Targets
        </ComponentHeader>
      }
      <ComponentSectionRow style={{padding: '1rem 0 0.5rem 0'}}>
        <ColumnContent className="detail small lowercase thin column">
          <span>Refinance Targets are used to calculate new loan and profit calculations</span>
          <span>when the 'refinance' target outcome is applied to a lead.</span>
          <span>These targets can be adjusted on any individual lead.</span>
        </ColumnContent>
      </ComponentSectionRow>
      <FlexWrapper className="fitContentHeight fitContentWidth" style={{padding: '1rem 0 4rem 0'}}>
        <InputWrapper style={{width: '8rem'}}>
          <StatusAndLabelWrapper className="column">
            {statusDefaultTerm === "missing" && (
              <ErrorWrapper>
                <ErrorIcon />
                Missing
              </ErrorWrapper>
            )}
            {statusDefaultTerm === "nonNumber" && (
              <ErrorWrapper>
                <ErrorIcon />
                Non-number
              </ErrorWrapper>
            )}
            {statusDefaultTerm === "tooLarge" && (
              <ErrorWrapper>
                <ErrorIcon />
                Too Large
              </ErrorWrapper>
            )}
            {statusDefaultTerm === "negative" && (
              <ErrorWrapper>
                <ErrorIcon />
                Negative
              </ErrorWrapper>
            )}
            {statusDefaultTerm === "float" && (
              <ErrorWrapper>
                <ErrorIcon />
                Decimal Value
              </ErrorWrapper>
            )}
            <Label htmlFor="defaultTerm">Term (years)</Label>
          </StatusAndLabelWrapper>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.tooltip.error.color}`,
              backgroundColor: `${theme.tooltip.error.background}`,
              border: `0.3rem solid ${theme.tooltip.error.border}`,
              borderBottom: `0.2rem solid ${theme.tooltip.error.border}`,
              opacity: '0',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -7],
              },
            }]}}
            title={!editDefaultTargetsPerm ?
              <FlexWrapper className="alignCenter" style={{padding: '0.2rem 0.6rem'}}>
                <DoDisturbIcon style={{color: `${theme.tooltip.error.border}`, height: '2rem', width: '2rem'}} />
                <Typography style={{margin: '0 0 0 0.5rem', fontWeight: '600', textAlign: 'center'}}>
                  Elevated authority required
                </Typography>
              </FlexWrapper>
            :
              ""
            }
            placement="top"
            arrow
          >
            <StyledInputElement
              style={{width: '8rem'}}
              value={defaultTermTarget}
              disabled={isFetching || !editDefaultTargetsPerm}
              autoComplete="off"
              type="text"
              name="defaultTerm"
              id="defaultTerm"
              ref={defaultTerm}
              onChange={() => handleTermTargetChange()}
              className={!editDefaultTargetsPerm ? 'disabled' : ''}
            />
          </Tooltip>
        </InputWrapper>
        <InputWrapper style={{width: '8rem'}}>
          <StatusAndLabelWrapper className="column">
            {statusDefaultInterest === "missing" && (
              <ErrorWrapper>
                <ErrorIcon />
                Missing
              </ErrorWrapper>
            )}
            {statusDefaultInterest === "nonNumber" && (
              <ErrorWrapper>
                <ErrorIcon />
                Non-number
              </ErrorWrapper>
            )}
            {statusDefaultInterest === "tooLarge" && (
              <ErrorWrapper>
                <ErrorIcon />
                Too Large
              </ErrorWrapper>
            )}
            {statusDefaultInterest === "negative" && (
              <ErrorWrapper>
                <ErrorIcon />
                Negative
              </ErrorWrapper>
            )}
            {statusDefaultInterest === "zero" && (
              <ErrorWrapper>
                <ErrorIcon />
                Zero
              </ErrorWrapper>
            )}
            <Label htmlFor="defaultInterest">Interest (%)</Label>
          </StatusAndLabelWrapper>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.tooltip.error.color}`,
              backgroundColor: `${theme.tooltip.error.background}`,
              border: `0.3rem solid ${theme.tooltip.error.border}`,
              borderBottom: `0.2rem solid ${theme.tooltip.error.border}`,
              opacity: '0',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -7],
              },
            }]}}
            title={!editDefaultTargetsPerm ?
              <FlexWrapper className="alignCenter" style={{padding: '0.2rem 0.6rem'}}>
                <DoDisturbIcon style={{color: `${theme.tooltip.error.border}`, height: '2rem', width: '2rem'}} />
                <Typography style={{margin: '0 0 0 0.5rem', fontWeight: '600', textAlign: 'center'}}>
                  Elevated authority required
                </Typography>
              </FlexWrapper>
            :
              ""
            }
            placement="top"
            arrow
          >
            <StyledInputElement
              style={{width: '8rem'}}
              value={defaultInterestTarget}
              disabled={isFetching || !editDefaultTargetsPerm}
              autoComplete="off"
              type="text"
              name="defaultInterest"
              id="defaultInterest"
              ref={defaultInterest}
              onChange={() => handleInterestTargetChange()}
              className={!editDefaultTargetsPerm ? 'disabled' : ''}
            />
          </Tooltip>
        </InputWrapper>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.tooltip.error.color}`,
            backgroundColor: `${theme.tooltip.error.background}`,
            border: `0.3rem solid ${theme.tooltip.error.border}`,
            borderBottom: `0.2rem solid ${theme.tooltip.error.border}`,
            opacity: '0',
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -20],
            },
          }]}}
          title={((defaultInterestTarget === teamDefaultTargets.defaultTargetInterestRate && defaultTermTarget === teamDefaultTargets.defaultTargetTerm) || !updateDefaultTargetsEnabled) && !isSuccess ?
            <FlexWrapper className="alignCenter" style={{padding: '0.2rem 0.6rem'}}>
              <DoDisturbIcon style={{color: `${theme.tooltip.error.border}`, height: '2rem', width: '2rem'}} />
              <Typography style={{margin: '0 0 0 0.5rem', fontWeight: '600', textAlign: 'center'}}>
                No changes made
              </Typography>
            </FlexWrapper>
          : !editDefaultTargetsPerm ?
            <FlexWrapper className="alignCenter" style={{padding: '0.2rem 0.6rem'}}>
              <DoDisturbIcon style={{color: `${theme.tooltip.error.border}`, height: '2rem', width: '2rem'}} />
              <Typography style={{margin: '0 0 0 0.5rem', fontWeight: '600', textAlign: 'center'}}>
                Elevated authority required
              </Typography>
            </FlexWrapper>
          :
            ""
          }
          placement="top"
          arrow
        >
          <FlexWrapper className="fitContentWidth">
            <Button 
              className={
                isFetching ? 'primaryLoading' 
                : (!updateDefaultTargetsEnabled || statusDefaultInterest !== null || statusDefaultTerm !== null || isError || (defaultInterestTarget === teamDefaultTargets.defaultTargetInterestRate && defaultTermTarget === teamDefaultTargets.defaultTargetTerm)) ? "primaryDisabled" 
                : "primary"}
              disabled={isFetching || !updateDefaultTargetsEnabled || statusDefaultInterest !== null || statusDefaultTerm !== null || isError || (defaultInterestTarget === teamDefaultTargets.defaultTargetInterestRate && defaultTermTarget === teamDefaultTargets.defaultTargetTerm)}
              onClick={() => handleSubmit()}
              style={{margin: 'auto 3rem 0 3rem'}}
            >
              {isFetching ?
                <CircularProgress
                  sx={{ color: `${theme.button.text.primaryLoading}` }}
                  size={18}
                />
              :
                `save`
              }
            </Button>
          </FlexWrapper>
        </Tooltip>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default DefaultTargets