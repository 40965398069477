import React from "react";
import { useTheme } from "@mui/styles";
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import FastForwardIcon from '@mui/icons-material/FastForward';
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const IntroductionGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <FlexWrapper>
        <GuideText className="header">
          Lancaster Sweep is built with a singular purpose in mind
          <ScatterPlotIcon style={{height: '2.2rem', width: '2.2rem', paddingTop: '0.8rem', color: `${theme.text.secondary.primary}`, margin: '0 0.5rem 0 0.1rem'}} />
        </GuideText>
      </FlexWrapper>
      <GuideText className="medium">
        To make thorough mortgage oversight an easily achievable task.
      </GuideText>
      <GuideText>
        In a time where information moves fast and problems can move even faster, automated systems are the only<br/>
        feasible approach to keeping up with the changes that can't be effectively monitored through manual efforts.<br/>
      </GuideText>
      <GuideText className="newParagraph">
        Lancatser Sweep is tailored to do the heavy lifting of searching for changes that occur without lender consent, while also providing a<br/>
        single source of truth for the mitigating actions your team takes in maintaining the health of your portfolio, and the clients it serves.
      </GuideText>
      <GuideText className="newParagraph">
        While the functionality and insights provided by the application are tailored to serve this exact goal,<br/>
        we believe the benefits from tracking public records, managing investigations, and maintaining records and statistics can be expansive.
      </GuideText>
      <GuideText className="newParagraph">
        We therefore aim to tailor our customer's applications to best serve their particular needs.<br/>
        Please contact our team with any requests or suggestions that will enable us to provide your team with the best possible tool for the job!
      </GuideText>

      <Divider className="narrow" />

      <GuideText className="header newParagraph">
        Getting up to speed and staying ahead
      </GuideText>
      <GuideText>
        The initial upload of your existing porftolio into the application can be accomplished in a few ways:
      </GuideText>
      <GuideText className="newParagraph">
        Public record scans can be accomplished with as little as a list of addresses representing each of your mortgages.
      </GuideText>
      <FlexWrapper style={{marginTop: '0.5rem'}}>
        <FastForwardIcon style={{height: '2.2rem', width: '2.2rem', padding: '0.4rem 0 0.3rem 0', color: `${theme.text.secondary.primary}`, margin: '0 0.5rem 0 1.5rem'}} />
        <GuideText>
          With the current state of publicly sourced information loaded into the application,<br/>
          your team can immediately address any existing issues that can be identified manually.
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph">
        If, however, information like client details or the original financial terms is provided,<br/>
        the initial scan will automatically alert your team to situations that don't match expectations.
      </GuideText>
      <GuideText className="newParagraph medium">
        Either way,
      </GuideText>
      <GuideText>
        your team will gain instant oversight of your entire portfolio, as well as the tools needed to sort, organize, and identify situations<br/>that require action.
        Once the backlog of any newly discovered issues is remedied by your team,
        Lancaster Sweep will continue<br/>running monthly scans, keeping you up to date with alerts triggered whenever new changes to public records are detected.
      </GuideText>
    </FlexWrapper>
  )
}

export default IntroductionGuide