import React, { useState } from "react";
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { StyledToggleButton } from "../../../../componentThemes";
import { useChangeTargetOutcomeMutation } from "../../../../slices/api/apiSlice";
import { selectEditLeadTargetOutcomePerm } from "../../../../slices/userActionsPermissionsSlice";
import { SubHeader, Button, ButtonWrapper, ComponentHeader, ModalContentContainer, Typography, FlexWrapper } from "../../../../StyledComponents";

const ChangeTargetOutcome = ({ userId, userFullName, handleCloseModal, leadData }) => {
  const theme = useTheme();

  const editLeadTargetOutcomePerm = useSelector(selectEditLeadTargetOutcomePerm)

  const [isFetching, setIsFetching] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [targetOutcome, setTargetOutcome] = useState('renegotiation')

  const [changeTargetOutcome] = useChangeTargetOutcomeMutation()

  const handleChangeTargetOutcome = (unassigned) => {
    setIsFetching(true)
    setIsSuccess(false)
    setIsError(false)
    let newOutcome = ''
    if (unassigned) {
      newOutcome = targetOutcome
    } else if (leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION') {
      newOutcome = 'refinance'
    } else {
      newOutcome = 'renegotiation'
    }
    let resp = changeTargetOutcome({
      userId: userId,
      timeline: leadData.timeline,
      userFullName: userFullName,
      newOutcome: newOutcome,
      oldOutcome: leadData.targetOutcome,
      leadId: leadData._id,
    })
    if (resp.error) {
      setIsError(true)
    } else {
      setIsSuccess(true)
    }
    setIsFetching(false)
  }

  const handleTargetOutcome = (event) => {
    setTargetOutcome(event.target.value)
  }

  return (
      <>
        {isSuccess ?
          <ComponentHeader className="modal green">
            <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
              <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
            </FlexWrapper>
            <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
              Success
            </span>
          </ComponentHeader>
        : isError ?
          <ComponentHeader className="modal red">
            <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
              <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
            </FlexWrapper>
            <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
              Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
            </span>
          </ComponentHeader>
        : leadData.targetOutcome === "unassigned" ?
          <ComponentHeader className="modal">
            <Typography className="xLarge">
              Assign Target Outcome
            </Typography>
          </ComponentHeader>
        :
          <ComponentHeader className="modal">
            <Typography className="xLarge">
              Change Target Outcome
            </Typography>
          </ComponentHeader>
        }

        <ModalContentContainer className="centerColumn">
          <SubHeader className="modal" style={{margin: '0 auto 4rem auto'}}>
            <Typography className="small">
              {isSuccess ?
                `The investigation outcome has been set to a ${leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION' ? 'renegotiation' : 'refinance'}.`
              : leadData.targetOutcome === 'unassigned' ?
                `Select the intended outcome of this investigation.`
              :
                `Change the target outcome of this investigation to a ${leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION' ? 'refinance' : 'renegotiation'}?`
              }
            </Typography>
            {leadData.targetOutcome === 'unassigned' &&
              <FlexWrapper style={{height: 'fit-content', margin: '3rem 0'}}>
                <Stack style={{margin: '0 auto'}}>
                  <ToggleButtonGroup
                    value={targetOutcome}
                    exclusive
                    onChange={handleTargetOutcome}
                    style={{height: '3rem', margin: '0 auto 1.5rem auto'}}
                  >
                    <StyledToggleButton value="renegotiation">
                      RENEGOTIATION
                    </StyledToggleButton>
                    <StyledToggleButton value="refinance">
                      REFINANCE
                    </StyledToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </FlexWrapper>
            }
          </SubHeader>
          {(!isSuccess && !isError) &&
            <ButtonWrapper>
              {isFetching ?
                <Button className="primaryLoading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.common.cardBackground}` }}
                    size={13}
                  />
                </Button>
              :
                <Button onClick={editLeadTargetOutcomePerm && leadData.targetOutcome !== 'unassigned' ? () => handleChangeTargetOutcome(false) : editLeadTargetOutcomePerm && leadData.targetOutcome === 'unassigned' ? () => handleChangeTargetOutcome('unassigned') : null} className={editLeadTargetOutcomePerm ? "primary" : "primaryDisabled"}>
                  Confirm
                </Button>
              }         
            </ButtonWrapper>
          }
        </ModalContentContainer>
      </>
  )
}

export default ChangeTargetOutcome;