import React from "react";
import TagsChart from "./Charts/TagsChart";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { ColumnContent, FlexWrapper } from "../../../../../../StyledComponents";

const TagsView = ({ displaySelection, tagsViewMortgages }) => {
  const theme = useTheme()

  return (
    <FlexWrapper style={{height: '54rem', margin: 'auto'}}>
      {tagsViewMortgages === undefined ?
        <ColumnContent className="single">
          <CircularProgress
            sx={{ color: `${theme.palette.primary.main}`}}
            size={48}
          />
        </ColumnContent>
      : displaySelection === 'Chart View' &&
        <TagsChart tagsViewMortgages={tagsViewMortgages} />
      }
    </FlexWrapper>
  )
}

export default TagsView