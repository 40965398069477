import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { HeaderContainer, HeaderText, MainChartWrapper, ColumnContent, ComponentContainer, ComponentBorder, FlexWrapper } from "../../../../../../StyledComponents";
import { useSelector } from "react-redux";
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import MonthlyAllChart from "./components/Charts/MonthlyAllChart";
import MonthlyTwoYearChart from "./components/Charts/MonthlyTwoYearChart";
import MonthlyOneYearChart from "./components/Charts/MonthlyOneYearChart";
import ChartToggle from "./components/ChartToggle";
import ParamtersAllChart from "./components/Charts/ParamtersAllChart";
import { selectDiscrepnacyParameters, selectDiscrepnacyParametersAll, selectDiscrepnacyParametersDismissals, selectDiscrepnacyParametersRefinances, selectDiscrepnacyParametersRenegotiations } from "../../../../../../slices/teamSweepParametersSlice";
import ParamtersRenegotiationsChart from "./components/Charts/ParametersRenegotiationsChart";
import ParametersRefinancesChart from "./components/Charts/ParametersRefinanceChart";
import ParamtersDismissalsChart from "./components/Charts/ParametersDismissalsChart";
import { selectTotalSuccessfulQueries } from "../../../../../../slices/teamStatsSlice";

const MainChart = ({ leadGenerationData }) => {
  const theme = useTheme()

  const teamDiscrepancyParametersAll = useSelector(selectDiscrepnacyParametersAll)
  const teamDiscrepancyParametersRenegotiations = useSelector(selectDiscrepnacyParametersRenegotiations)
  const teamDiscrepancyParametersRefinances = useSelector(selectDiscrepnacyParametersRefinances)
  const teamDiscrepancyParametersDismissals = useSelector(selectDiscrepnacyParametersDismissals)

  const [chartTimeline, setChartTimeline] = useState('All'); 
  const handleTimelineChange = (event) => {
    setChartTimeline(event.target.value)
  }
  const [scatterDisplay, setScatterDisplay] = useState('All Closures'); 
  const handleScatterDisplayChange = (event) => {
    setScatterDisplay(event.target.value)
  }
  const [chartPerspective, setChartPerspective] = useState('Monthly Stats'); 
  const handleChartPerspectiveChange = (event) => {
    setChartPerspective(event.target.value)
    setChartTimeline('All')
  }

  const totalSuccessfulQueries = useSelector(selectTotalSuccessfulQueries)

  return (
    <ComponentContainer className="detailsContainer" style={{gridRow: '3', gridColumn: '3', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer className="chart">
          <HeaderText className="chart" style={{gridColumn: '1', position: 'absolute'}}>
            {chartPerspective === 'Monthly Stats' ?
              `Monthly Stats`
            : chartPerspective === 'Parameter Stats' ?
              `Parameter Stats`
            :
              ''
            }
          </HeaderText>
          <ChartToggle handleTimelineChange={handleTimelineChange} chartTimeline={chartTimeline} handleChartPerspectiveChange={handleChartPerspectiveChange} handleScatterDisplayChange={handleScatterDisplayChange} chartPerspective={chartPerspective} scatterDisplay={scatterDisplay} />
        </HeaderContainer>
        <MainChartWrapper>
          {leadGenerationData !== undefined ?
            <>
              {totalSuccessfulQueries === 0 ?
                <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                  <WifiTetheringOffIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '20rem', width: '20rem'}} />
                  <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no record scans completed</HeaderText>
                </FlexWrapper>
              : chartPerspective === 'Monthly Stats' && chartTimeline === 'All' ?
                <MonthlyAllChart monthlyStatsAll={leadGenerationData.all} />
              : chartPerspective === 'Monthly Stats' && chartTimeline === '2 Year' ?
                <MonthlyTwoYearChart monthlyStatsTwoYear={leadGenerationData.twoYear} />
              : chartPerspective === 'Monthly Stats' && chartTimeline === '1 Year' ?
                <MonthlyOneYearChart monthlyStatsYear={leadGenerationData.oneYear} />
              : chartPerspective === 'Parameter Stats' && scatterDisplay === 'All Closures' ?
                <ParamtersAllChart data={teamDiscrepancyParametersAll} />
              : chartPerspective === 'Parameter Stats' && scatterDisplay === 'Renegotiations' ?
                <ParamtersRenegotiationsChart data={teamDiscrepancyParametersRenegotiations} />
              : chartPerspective === 'Parameter Stats' && scatterDisplay === 'Refinances' ?
                <ParametersRefinancesChart data={teamDiscrepancyParametersRefinances} />
              : chartPerspective === 'Parameter Stats' && scatterDisplay === 'Dismissals' ?
                <ParamtersDismissalsChart data={teamDiscrepancyParametersDismissals} />
              :
                <></>
              }
            </>
          :
            <ColumnContent className="single">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </ColumnContent>
          }
        </MainChartWrapper>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default MainChart;