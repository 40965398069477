import React, { useState } from "react";
import SearchForm from "./components/SearchForm";
import SearchResults from "./components/SearchResults";
import SearchSideBar from "./components/SearchSideBar";
import { useNavigate } from "react-router-dom";
import { PageContent, } from "../../../../StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import { selectSearchForPropertyPerm } from "../../../../slices/userActionsPermissionsSlice";
import { useRecordSweepQueryMutation, useRunPropertySearchMutation, useSavePropertyMutation } from "../../../../slices/api/apiSlice";
import { selectDashPerspective, selectLeadsFilteredBoolean, selectPropertySearchResponse, selectPropertySearchSaveEnabled, setLeadStatusFilter, setMortgageActionNavMenuSelection, setNavSelection, setPropertySearchSaveEnabled, setRunningSweep, setShowMortgageActionsModal } from "../../../../slices/sessionDataSlice";
import { removeNotificationFromLead, selectTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads, setFwdBckFilteredViewingLeads, setFwdBckViewingLeads } from "../../../../slices/teamActiveLeadsSlice";
import { setViewingLead } from "../../../../slices/viewingLeadSlice";
import { setViewingMortgage } from "../../../../slices/viewingMortgageSlice";
import { setViewingReports } from "../../../../slices/teamReportsSlice";
import { removeNotificationFromMortgage, selectMortgagesFilteredBoolean, setFwdBckViewingFilteredMortgages, setFwdBckViewingMortgages } from "../../../../slices/teamMortgagesSlice";
import { removeLeadNotification } from "../../../../slices/userNotificationsSlice";

const PropertySearch = ({ userFullName, teamId, userId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const closingLeads = useSelector(selectTeamClosingLeads)
  const dashPerspective = useSelector(selectDashPerspective)
  const investigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const awaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const saveFunctionEnabled = useSelector(selectPropertySearchSaveEnabled)
  const awaitingUpdateLeads = useSelector(selectTeamAwaitingUpdateLeads)
  const leadsFilteredBoolean = useSelector(selectLeadsFilteredBoolean)
  const searchForPropertyPerm = useSelector(selectSearchForPropertyPerm)
  const propertySearchResponse = useSelector(selectPropertySearchResponse)
  const mortgagesFilteredBoolean = useSelector(selectMortgagesFilteredBoolean)
  
  const [runPropertySearch] = useRunPropertySearchMutation()
  const [recordSweepQuery] = useRecordSweepQueryMutation()
  const [saveProperty] = useSavePropertyMutation()

  const [nullForm, setNullForm] = useState(false)
  const [saveError, setSaveError] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [responseError, setResponseError] = useState(false)
  const [searchFetching, setSearchFetching] = useState(false)
  const [allFieldsPresent, setAllFieldsPresent] = useState(false)
  const [propertyNotFound, setPropertyNotFound] = useState(false)
  const [existingMortgage, setExistingMortgage] = useState(false)
  const [scanResponseError, setScanResponseError] = useState(false)
  
  const handlePropertySearch = async (streetAddress, city, state, postalCode) => {
    setSearchFetching(true)
    setSaveError(false)
    setExistingMortgage(false)
    setSaveSuccess(false)
    if (streetAddress && city && state && postalCode) {
      setAllFieldsPresent(true)
    }
    let resp = await runPropertySearch({
      teamId: teamId,
      userFullName: userFullName,
      streetAddress: streetAddress,
      city: city,
      state: state,
      postalCode: postalCode,
      unitNumber: null,
    })
    if (!resp.error) {
      if (resp.data.message.includes('success')) {
        if (resp.data.data.existingMortgage) {
          setExistingMortgage(true)
          dispatch( setPropertySearchSaveEnabled(false) )
        } else {
          dispatch( setPropertySearchSaveEnabled(true) )
        }
      } else if (resp.data.message.includes('results')) {
        setPropertyNotFound(true)
      } else {
        setResponseError(true)
      }
    } else {
      setResponseError(true)
    }
    setSearchFetching(false)
  }

  const handleMortgageScan = async (mortgageToScan) => {
    setSearchFetching(true)
    setSaveError(false)
    setExistingMortgage(false)
    setSaveSuccess(false)
    dispatch( setRunningSweep(true) )
    let sendQuerySettings = {
      sweepType: 'Check All Discrepancies',
      sweepSize: '1',
      existingScans: 'Any',
      createLead: 'true',
      recordNewMonthlyStats: 'true',
      recordPerformance: 'true',
      notifyAssignees: 'true',
      updateTeamStats: 'true',
      updateLastQuery: 'true',
      initialMatch: 'false',
      skipMissingVendorRejections: false,
      skipNewVendorRejections: false,
    }
    let requestObj = {
      teamId: teamId,
      userId: userId,
      userFullName: userFullName,
      mortgageId: mortgageToScan,
      querySettings: sendQuerySettings,
      newlyGeneratedCoreLogicAccessToken: null,
      isSingleScan: true,
    }
    let resp = await recordSweepQuery(requestObj)
    console.log(resp)
    if (!resp.error) {
      if (resp.data.message.includes('success')) {
        let mortgage = resp.data.data.newMortgage
        dispatch( setViewingReports(mortgage.reports) )
        if (mortgage.activeLead) {
          let thisLead = null
          thisLead = awaitingActionLeads.find(lead => lead._id === mortgage.activeLead.toString())
          if (!thisLead) {
            thisLead = investigatingLeads.find(lead => lead._id === mortgage.activeLead.toString())
            if (!thisLead) {
              thisLead = closingLeads.find(lead => lead._id === mortgage.activeLead.toString())
              if (!thisLead) {
                thisLead = awaitingUpdateLeads.find(lead => lead._id === mortgage.activeLead.toString())
              }
            }
          }
          if (thisLead) {
            if (leadsFilteredBoolean || dashPerspective !== 'team') {
              dispatch( setFwdBckFilteredViewingLeads(thisLead._id) )
            } else {
              dispatch( setFwdBckViewingLeads(thisLead._id) )
            }
            dispatch( setViewingLead(thisLead) )
            if (thisLead.notifCount && thisLead.notifCount > 0) {
              if (thisLead.status === 'awaitingAction') {
                dispatch( removeLeadNotification({status: 'awaitingAction', count: 1}) )
                dispatch( removeNotificationFromLead({status: 'awaitingAction', leadId: thisLead._id}) )
              } else if (thisLead.status === 'investigating') {
                dispatch( removeLeadNotification({status: 'investigating', count: 1}) )
                dispatch( removeNotificationFromLead({status: 'investigating', leadId: thisLead._id}) )
              } else if (thisLead.status === 'closing') {
                dispatch( removeLeadNotification({status: 'closing', count: 1}) )
                dispatch( removeNotificationFromLead({status: 'closing', leadId: thisLead._id}) )
              } else {
                dispatch( removeLeadNotification({status: 'awaitingUpdates', count: 1}) )
                dispatch( removeNotificationFromLead({status: 'awaitingUpdates', leadId: thisLead._id}) )
              }
            }
          }
        } else {
          dispatch( setViewingLead(null) )
          dispatch( setFwdBckViewingLeads(null) )
          dispatch( setFwdBckFilteredViewingLeads(null) )
        }
        if (mortgage.notifCount && mortgage.notifCount > 0) {
          dispatch( removeNotificationFromMortgage({mortgageId: mortgage._id}) )
        }
        if (mortgagesFilteredBoolean) {
          dispatch( setFwdBckViewingFilteredMortgages(mortgage._id) )
        } else {
          dispatch( setFwdBckViewingMortgages(mortgage._id) )
        }
        console.log(mortgage)
        dispatch( setViewingMortgage(mortgage) )
        dispatch( setShowMortgageActionsModal(true) )
        dispatch( setNavSelection('mortgageRepository') )
        dispatch( setMortgageActionNavMenuSelection('compareRecords') )
        navigate(`/dashboard/mortgageRepository/${mortgage._id}`)
      } else {
        setScanResponseError(true)
      }
    } else {
      setScanResponseError(true)
    }
    setSearchFetching(false)
    dispatch( setRunningSweep(false) )
  }

  const handleSaveProperty = async () => {
    setSearchFetching(true)
    dispatch( setPropertySearchSaveEnabled(true) )
    let resp = await saveProperty({
      teamId: teamId,
      userFullName: userFullName,
      recordDetailsObj: propertySearchResponse.recordDetailsObj,
      propMixSuccessDate: propertySearchResponse.propMixSuccessDate,
      attomSuccessDate: propertySearchResponse.attomSuccessDate,
      coreLogicSuccessDate: propertySearchResponse.coreLogicSuccessDate,
      isDuplicate: false,
    })
    if (resp.error) {
      setSaveError(true)
      setExistingMortgage(false)
      setSaveSuccess(false)
    } else if (resp.data.message.includes('existing')) {
      setSaveError(false)
      setExistingMortgage(true)
      setSaveSuccess(false)
      dispatch( setPropertySearchSaveEnabled(false) )
    } else if (resp.data.message.includes('success')) {
      setSaveError(false)
      setExistingMortgage(false)
      setSaveSuccess(true)
      dispatch( setPropertySearchSaveEnabled(false) )
    }
    setSearchFetching(false)
  }

  const [showAll, setShowAll] = useState(false)
  const handleShowAll = (event) => {
    setShowAll(event.target.checked)
  }

  return (
    <PageContent className={propertySearchResponse.dataPresent ? 'grid reports' : 'flex reports'}>
      {!propertySearchResponse.dataPresent ?
        <SearchForm allFieldsPresent={allFieldsPresent} searchFetching={searchFetching} handlePropertySearch={handlePropertySearch} searchForPropertyPerm={searchForPropertyPerm} responseError={responseError} propertyNotFound={propertyNotFound} setNullForm={setNullForm} nullForm={nullForm} setPropertyNotFound={setPropertyNotFound} setResponseError={setResponseError} handleMortgageScan={handleMortgageScan} scanResponseError={scanResponseError} />
      :
        <>
          <SearchSideBar allFieldsPresent={allFieldsPresent} searchFetching={searchFetching} handlePropertySearch={handlePropertySearch} setPropertyNotFound={setPropertyNotFound} setResponseError={setResponseError} propertyNotFound={propertyNotFound} nullForm={nullForm} setNullForm={setNullForm} handleSaveProperty={handleSaveProperty} saveError={saveError} existingMortgage={existingMortgage} saveSuccess={saveSuccess} handleShowAll={handleShowAll} showAll={showAll} saveFunctionEnabled={saveFunctionEnabled} />
          <SearchResults propertyDetails={propertySearchResponse.recordDetailsObj} showAll={showAll} />
        </>
      }      
    </PageContent>
  )
}

export default PropertySearch;