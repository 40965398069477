import React, { useContext, useState, useRef } from "react"
import ErrorIcon from "@mui/icons-material/Error";
import CompanyLogo from "../../../icon.logo.black.png";
import { useTheme } from "@mui/styles";
import { AppState } from "../../../AppState";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { selectUserHistory, setPageSelection } from "../../../slices/sessionDataSlice";
import { selectLoginEnabled } from "../../../slices/functionAvailabilitySlice";
import { selectUserEmail, selectUserRequestCredentials } from "../../../slices/userInfoSlice";
import { useInitialLoadMutation, useLoginReturningUserMutation } from "../../../slices/api/apiSlice";
import { AlertWrapper, Button, FlexWrapper, InlineLink, InputWrapper, Label, StatusAndLabelWrapper, StyledInputElement } from "../../../StyledComponents"

const AccessForm = () => {
  const theme = useTheme()
  const navigate = useNavigate()  
  const dispatch = useDispatch()  
  const { saveUserState } = useContext(AppState)

  const userEmail = useSelector(selectUserEmail)
  const userHistory = useSelector(selectUserHistory)
  const loginEnabled = useSelector(selectLoginEnabled)
  const userRequestCredentials = useSelector(selectUserRequestCredentials)
  const [loginReturningUser] = useLoginReturningUserMutation()
  const [initialLoad] = useInitialLoadMutation()


  const [tokenEmpty, setTokenEmpty] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [viewPassword, setViewPassword] = useState(false)
  const [statusAccessToken, setStatusAccessToken] = useState(null)
  const accessToken = useRef()

  const handleTokenValidation = async () => {
    setIsFetching(true)
    navigate('/login')
    if (accessToken.current.value.includes('<') || accessToken.current.value.includes('>') || accessToken.current.value.length > 13) {
      setStatusAccessToken("invalid")
    } else {
      let login = await loginReturningUser({
        accessToken: accessToken.current.value,
        userEmail: userEmail,
        reqIP: userRequestCredentials.protocol,
        reqLocation: userRequestCredentials.location,
        reqISP: userRequestCredentials.isp,
        reqProxy: userRequestCredentials.proxy,
        reqMobile: userRequestCredentials.mobile,
      })
      if (login.error) {
        setRequestError(true)
      } else {
        if (login.data.message.includes('successful')) {
          let loadAttempt = await initialLoad({userId: login.data.data.userId, authToken: login.data.data.accessToken})
          await saveUserState({userId: login.data.data.userId, accessToken: login.data.data.accessToken})
          if (loadAttempt.data.message.includes("Web Token Expired")) {
            localStorage.clear()
            navigate('/login')
          } else {
            if (login.data.message.includes('Super')) {
              navigate('/admin/uploads')
              dispatch( setPageSelection('uploads') )
            } else {
              navigate('/dashboard/guide')
              dispatch( setPageSelection('dashboard') )
            }
          }
        } else if (login.data.message.includes('No user found')) {
          setStatusAccessToken("incorrect")
        } else if (login.data.message.includes('Invalid')) {
          setStatusAccessToken("invalid")
        } else {
          setRequestError(true)
        }
      }
    }
    setIsFetching(false)
  }

  return (
    <FlexWrapper className="column justifyAround">
      {userHistory === 'existing' ?
        <FlexWrapper className="column" style={{margin: '3rem auto', height: 'fit-content', width: 'fit-content'}}>
          <FlexWrapper style={{margin: '1rem 0 4.5rem 0'}}>
            <img style={{width: '4.5rem', height: '4.5rem', opacity: '0.7'}} src={CompanyLogo} alt="Lancaster Logo" />
            <span style={{opacity: '0.8', fontSize: '3rem', color: `${theme.palette.common.black}`, fontWeight: '300', fontFamily: 'exo-bold', borderBottom: `0.1rem solid ${theme.accent.success.primary}`, height: '3.7rem', borderRadius: '1rem', marginLeft: '0.5rem'}}>
              Lancaster Sweep
            </span>
          </FlexWrapper>
          <span style={{fontSize: '2rem', fontFamily: 'exo-bold', fontWeight: '600', color: `${theme.palette.common.black}`, margin: '0 auto'}}>
            Welcome back
          </span>
          <span style={{fontSize: '1.4rem', fontFamily: 'exo-mediumItalic', fontWeight: '600', color: `${theme.palette.common.grayText}`, margin: '0 auto'}}>
            sweeps are running on schedule...
          </span>
        </FlexWrapper>
      :
        <FlexWrapper className="column" style={{margin: '3rem auto', height: 'fit-content', width: 'fit-content'}}>
          <span style={{fontSize: '2rem', fontFamily: 'exo-bold', fontWeight: '600', color: `${theme.palette.common.grayText}`}}>
            Welcome to
          </span>
          <FlexWrapper style={{margin: '1rem 0 4.5rem 0'}}>
            <img style={{width: '4.5rem', height: '4.5rem', opacity: '0.7'}} src={CompanyLogo} alt="Lancaster Logo" />
            <span style={{opacity: '0.8', fontSize: '3rem', color: `${theme.palette.common.black}`, fontWeight: '300', fontFamily: 'exo-bold', borderBottom: `0.1rem solid ${theme.accent.success.primary}`, height: '3.7rem', borderRadius: '1rem', marginLeft: '0.5rem'}}>
              Lancaster Sweep
            </span>
          </FlexWrapper>
          <span style={{fontSize: '1.4rem', fontFamily: 'exo-mediumItalic', fontWeight: '600', color: `${theme.palette.common.grayText}`, margin: '0 auto'}}>
            provide a token to access the dashboard...
          </span>
        </FlexWrapper>
      }
      <FlexWrapper className="column" style={{height: 'fit-content'}}>
        <InputWrapper style={{margin: '0rem auto 1rem auto', width: 'fit-content'}}>
          <StatusAndLabelWrapper>
            <Label htmlFor="accessToken">Access Token</Label>
            {statusAccessToken === "incorrect" && (
              <AlertWrapper className="error">
                <ErrorIcon />
                Access denied
              </AlertWrapper>
            )}
            {statusAccessToken === "invalid" && (
              <AlertWrapper className="error">
                <ErrorIcon />
                Invalid Token
              </AlertWrapper>
            )}
            {requestError && (
              <AlertWrapper className="error">
                <ErrorIcon />
                Something went wrong
              </AlertWrapper>
            )}
          </StatusAndLabelWrapper>
          <FlexWrapper>
            <StyledInputElement
              autoFocus
              autoComplete="off"
              type={viewPassword ? 'text' : 'password'}
              name="accessToken"
              id="accessToken"
              className="auth"
              ref={accessToken}
              style={{background: tokenEmpty ? `${theme.input.field.unpopulated}` : `${theme.input.field.populated}`}}
              onChange={() => {
                if (statusAccessToken) {
                  setStatusAccessToken(null)
                }
                if (requestError) {
                  setRequestError(null)
                }
                if (accessToken.current.value.length === 0) {
                  setTokenEmpty(true)
                } else {
                  setTokenEmpty(false)
                }
              }}
            />
            <InlineLink tabIndex={-1}>
              <VisibilityIcon onClick={() => setViewPassword(!viewPassword)} style={{color: viewPassword ? `${theme.palette.primary.medium}` : `${theme.palette.common.blackLight}`, width: '1.5rem', height: '1.5rem'}} />
            </InlineLink>
          </FlexWrapper>
        </InputWrapper>
        <Button 
          className={isFetching ? 'primaryLoading' : ((!loginEnabled) || tokenEmpty || statusAccessToken || requestError) ? "primaryDisabled" : "primary"}
          disabled={isFetching || !loginEnabled || tokenEmpty || statusAccessToken || requestError}
          onClick={() => handleTokenValidation()}
          style={{marginBottom: '2.5rem'}}
        >
          {isFetching ? (
            <CircularProgress
              sx={{color: `${theme.button.text.primary}`}}
              size={12}
            />
          ) : (
            "Login"
          )}
        </Button>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default AccessForm