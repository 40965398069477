import React, { useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@emotion/react";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDefaultLocations } from "../../../../../slices/teamAndUserSettingsSlice";
import { selectUpdateDefaulLocationsEnabled, setUpdateDefaultLocationsEnabled } from "../../../../../slices/functionAvailabilitySlice";
import { useUpdateUserDefaultLocationsMutation } from "../../../../../slices/api/apiSlice";
import { ErrorWrapper, Label, InputWrapper, StatusAndLabelWrapper, StyledInputElement, Button, FlexWrapper, ComponentSectionRow, ColumnContent, ComponentHeader, Typography, AccentInfo } from "../../../../../StyledComponents";

const DefaultLocations = ({ userId, userFullName }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const userDefaultLocations = useSelector(selectUserDefaultLocations)
  const updateDefaultLocationsEnabled = useSelector(selectUpdateDefaulLocationsEnabled)

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [updateUserDefaultLocations] = useUpdateUserDefaultLocationsMutation()

  const [newDefaultState, setNewDefaultState] = useState(userDefaultLocations.state)
  const [statusDefaultState, setStatusDefaultState] = useState(null)
  const defaultState = useRef()
  const [newDefaultCity, setNewDefaultCity] = useState(userDefaultLocations.city)
  const [statusDefaultCity, setStatusDefaultCity] = useState(null)
  const defaultCity = useRef()

  const handleStateChange = () => {
    setIsSuccess(false)
    setIsError(false)
    if (defaultCity.current.value.toUpperCase() === userDefaultLocations.city.toUpperCase() && defaultState.current.value.toUpperCase() === userDefaultLocations.state.toUpperCase()) {
      dispatch( setUpdateDefaultLocationsEnabled(false) )
    } else {
      dispatch( setUpdateDefaultLocationsEnabled(true) )
    }
    if (defaultState.current.value.length !== 2 || (!isNaN(defaultState.current.value) && defaultState.current.value.length > 0) || defaultState.current.value.includes('<') || defaultState.current.value.includes('>')) {
      setStatusDefaultState("invalid")
      dispatch( setUpdateDefaultLocationsEnabled(false) )
    } else {
      setStatusDefaultState(null)
    }
    setNewDefaultState(defaultState.current.value)
  }
  const handleCityChange = () => {
    setIsSuccess(false)
    setIsError(false)
    if (defaultCity.current.value.toUpperCase() === userDefaultLocations.city.toUpperCase() && defaultState.current.value.toUpperCase() === userDefaultLocations.state.toUpperCase()) {
      dispatch( setUpdateDefaultLocationsEnabled(false) )
    } else {
      dispatch( setUpdateDefaultLocationsEnabled(true) )
    }
    if (defaultCity.current.value.length > 50 || (!isNaN(defaultCity.current.value) && defaultCity.current.value.length > 0) || defaultCity.current.value.includes('<') || defaultCity.current.value.includes('>')) {
      if (defaultCity.current.value.length > 50) {
        setStatusDefaultCity("tooLong")
      }
      if (defaultCity.current.value.includes('<') || defaultCity.current.value.includes('>') || !isNaN(defaultCity.current.value)) {
        setStatusDefaultCity("invalid")
      }
      dispatch( setUpdateDefaultLocationsEnabled(false) )
    } else {
      setStatusDefaultCity(null)
    }
    setNewDefaultCity(defaultCity.current.value)
  }

  const handleSubmit = async () => {
    setIsFetching(true)
    let resp = await updateUserDefaultLocations({
      userId: userId,
      userFullName: userFullName,
      newDefaultCity: newDefaultCity,
      newDefaultState: newDefaultState,
    })
    if (resp.error) {
      setIsError(true)
    } else if (resp.data.message.includes("success")) {
      setIsSuccess(true)
      dispatch( setUpdateDefaultLocationsEnabled(false) )
    } else {
      setIsError(true)
    }
    setIsFetching(false)
  }

  // useEffect(() => {
  //   if (userDefaultLocations && userDefaultLocations.state) {
  //     setNewDefaultState(userDefaultLocations.state)
  //   }
  //   if (userDefaultLocations && userDefaultLocations.city) {
  //     setNewDefaultCity(userDefaultLocations.city)
  //   }
  // }, [userDefaultLocations])

  return (
    <FlexWrapper className="column alignCenter fitContentHeight">
      {isSuccess ?
        <ComponentHeader className="green subHeader" style={{width: '60%', margin: '2rem 0 0 0'}}>
          <CheckIcon style={{height: '1.9rem', width: '1.9rem', margin: '0 0.5rem 0.2rem 0'}} />
          Default Locations Updated
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="red subHeader" style={{width: '60%', margin: '2rem 0 0 0'}}>
          <ErrorIcon style={{height: '1.9rem', width: '1.9rem', margin: '0 0.5rem 0.2rem 0'}} />
          Default Locations Update Failed
        </ComponentHeader>
      :
        <ComponentHeader className="subHeader" style={{width: '60%', margin: '2rem 0 0 0'}}>
          Default Locations
        </ComponentHeader>
      }
      <ComponentSectionRow style={{padding: '1rem 0 0.5rem 0'}}>
        <ColumnContent className="detail small lowercase thin column">
          <span>Default locations are used to pre-populate search forms</span>
        </ColumnContent>
      </ComponentSectionRow>
      <FlexWrapper className="fitContentHeight fitContentWidth" style={{padding: '1rem 0 4rem 0'}}>
        <InputWrapper style={{width: '14rem', height: '8rem', justifyContent: 'flex-start'}}>
          <StatusAndLabelWrapper className="column">
            {statusDefaultCity === "invalid" && (
              <ErrorWrapper>
                <ErrorIcon />
                invalid
              </ErrorWrapper>
            )}
            {statusDefaultCity === "tooLong" && (
              <ErrorWrapper>
                <ErrorIcon />
                Too Long
              </ErrorWrapper>
            )}
            <Label htmlFor="defaultTerm">City</Label>
          </StatusAndLabelWrapper>
          <StyledInputElement
            style={{width: '14rem'}}
            value={newDefaultCity}
            disabled={isFetching}
            autoComplete="off"
            type="text"
            name="defaultCity"
            id="defaultCity"
            ref={defaultCity}
            onChange={() => handleCityChange()}
          />
        </InputWrapper>
        <InputWrapper style={{width: '8rem', height: '8rem', justifyContent: 'flex-start'}}>
          <StatusAndLabelWrapper className="column">
            <Label htmlFor="defaultState">State (XX)</Label>
          </StatusAndLabelWrapper>
          <StyledInputElement
            style={{width: '8rem'}}
            value={newDefaultState}
            disabled={isFetching}
            autoComplete="off"
            type="text"
            name="defaultState"
            id="defaultState"
            ref={defaultState}
            onChange={() => handleStateChange()}
            />
            {statusDefaultState === "invalid" && (
              <ErrorWrapper>
                <ErrorIcon />
                invalid
              </ErrorWrapper>
            )}
        </InputWrapper>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.tooltip.error.color}`,
            backgroundColor: `${theme.tooltip.error.background}`,
            border: `0.3rem solid ${theme.tooltip.error.border}`,
            borderBottom: `0.2rem solid ${theme.tooltip.error.border}`,
            opacity: '0',
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -20],
            },
          }]}}
          title={((newDefaultState.toUpperCase() === userDefaultLocations.state.toUpperCase() && newDefaultCity.toUpperCase() === userDefaultLocations.city.toUpperCase()) && !isSuccess) ?
            <FlexWrapper className="alignCenter" style={{padding: '0.2rem 0.6rem'}}>
              <DoDisturbIcon style={{color: `${theme.tooltip.error.border}`, height: '2rem', width: '2rem'}} />
              <Typography style={{margin: '0 0 0 0.5rem', fontWeight: '600', textAlign: 'center'}}>
                No changes made
              </Typography>
            </FlexWrapper>
          :
            null
          }
          placement="top"
          arrow
        >
          <FlexWrapper className="fitContentWidth">
            <Button 
              className={
                isFetching ? 'primaryLoading' 
                : ((!updateDefaultLocationsEnabled || statusDefaultCity !== null || statusDefaultState !== null || isError || (newDefaultState.toUpperCase() === userDefaultLocations.state.toUpperCase() && newDefaultCity.toUpperCase() === userDefaultLocations.city.toUpperCase()))) ? "primaryDisabled" 
                : "primary"}
              disabled={isFetching || !updateDefaultLocationsEnabled || statusDefaultCity !== null || statusDefaultState !== null || isError || (newDefaultState.toUpperCase() === userDefaultLocations.state.toUpperCase() && newDefaultCity.toUpperCase() === userDefaultLocations.city.toUpperCase())}
              onClick={() => handleSubmit()}
              style={{margin: '0.6rem 3rem 0 3rem'}}
            >
              {isFetching ?
                <CircularProgress
                  sx={{ color: `${theme.button.text.primaryLoading}` }}
                  size={18}
                />
              :
                `save`
              }
            </Button>
          </FlexWrapper>
        </Tooltip>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default DefaultLocations