import React from "react"
import ClearIcon from '@mui/icons-material/Clear'
import { AccentInfo, Button, CompareDetail, CompareLink, FadeWrapper, FlexWrapper } from "../../../../../../../StyledComponents"

const DiscrepancySelector = ({ handleWorkFlow, handleSubmitEdit, valueType, recordField }) => {

  return (
    <FadeWrapper style={{display: 'flex'}}>
      <CompareDetail className="resolve" style={{height: '100%', justifyContent: 'center'}}>
        <FlexWrapper className="alignCenter column">
          <CompareLink className='gray' style={{fontSize: '1rem', margin: '0.2rem 0 0 0', width: '100%'}} onClick={() => handleSubmitEdit('keep')}>
            keep current value
          </CompareLink>
          <AccentInfo style={{margin: '0', width: '100%'}}>- or -</AccentInfo>
          <CompareLink className='gray' style={{fontSize: '1rem', margin: '0 0 0 0', width: '100%'}} onClick={() => handleSubmitEdit('resolve')}>
            match public record
          </CompareLink>
          {valueType !== 'boolean' &&
            <>
              <AccentInfo style={{margin: '0', width: '100%'}}>- or -</AccentInfo>
              <CompareLink className='gray' style={{fontSize: '1rem', margin: '0 0 0 0', width: '100%'}} onClick={() => handleWorkFlow('custom', recordField)}>
                enter other value
              </CompareLink>
            </>
          }
        </FlexWrapper>
        <Button className="neutral input" onClick={() => handleWorkFlow('', '')} style={{margin: '0 2rem'}}>
          <ClearIcon />
        </Button>
      </CompareDetail>
    </FadeWrapper>
  )
}

export default DiscrepancySelector