import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    teamMembersInfo: [{
      memberId: '',
      dateCreated: '',
      role: '',
      email: '',
      fullName: '',
      //* Member Performance Sidebar
      awaitingActionLeadsNum: 0,
      investigatingLeadsNum: 0,
      closingLeadsNum: 0,
      awaitingUpdateLeadsNum: 0,
      awaitingVerificationLeadsNum: 0,
      lastLoginDate: 0,
      //* Member Performance Stats Window
      closedRenegotiations: 0,
      renegotiationFrequency: 0,
      closedRefinances: 0,
      refinanceFrequency: 0,
      grossProfitNumber: 0,
      grossProfitPercent: 0,
    }],
    teamMembersMonthlyStats: [{
      memberId: '',
      memberMonthlyStats: [],
    }],
    teamMembersByName: [{
      memberId: '',
      role: '',
      firstName: '',
      fullName: '',
    }],
  },
  status: 'idle',
  error: null
}

export const teamMembersSlice = createSlice({
  name: 'teamMembers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.teamMembersData
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.removedAssigneeIds || payload.data.newAssigneeIds) {
        let investigationStatus = payload.data.leadStatus
        let statField = ''
        if (investigationStatus === 'awaitingAction') {
          statField = 'awaitingActionLeadsNum'
        } else if (investigationStatus === 'investigating') {
          statField = 'investigatingLeadsNum'
        } else if (investigationStatus === 'closing') {
          statField = 'closingLeadsNum'
        } else if (investigationStatus === 'awaitingUpdate' && payload.data.activeDiscrepancies > 0) {
          statField = 'awaitingUpdateLeadsNum'
        } else if (investigationStatus === 'awaitingUpdate') {
          statField = 'awaitingVerificationLeadsNum'
        }
        if (statField.length > 0) {
          for (let i = 0; i < payload.data.removedAssigneeIds.length; i++) {
            let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.removedAssigneeIds[i].toString())
            let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
            state.data.teamMembersInfo[thisMemberIndex][statField] = state.data.teamMembersInfo[thisMemberIndex][statField] - 1
          }
          for (let i = 0; i < payload.data.newAssigneeIds.length; i++) {
            let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.newAssigneeIds[i].toString())
            let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
            state.data.teamMembersInfo[thisMemberIndex][statField] = state.data.teamMembersInfo[thisMemberIndex][statField] + 1
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusInvestigating.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.assigneeIds.length; i++) {
        let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.assigneeIds[i].toString())
        let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
        state.data.teamMembersInfo[thisMemberIndex].investigatingLeadsNum = state.data.teamMembersInfo[thisMemberIndex].investigatingLeadsNum + 1
      }
      if (payload.data.requestOrigin !== 'repository') {
        for (let i = 0; i < payload.data.previousleadAssignees.length; i++) {
          let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.previousleadAssignees.toString())
          let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
          state.data.teamMembersInfo[thisMemberIndex].awaitingActionLeadsNum = state.data.teamMembersInfo[thisMemberIndex].awaitingActionLeadsNum - 1
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusClosing.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.assigneeIds.length; i++) {
        let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.assigneeIds[i].toString())
        let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
        state.data.teamMembersInfo[thisMemberIndex].closingLeadsNum = state.data.teamMembersInfo[thisMemberIndex].closingLeadsNum + 1
        state.data.teamMembersInfo[thisMemberIndex].investigatingLeadsNum = state.data.teamMembersInfo[thisMemberIndex].investigatingLeadsNum - 1
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.assigneeUpdateObjs.length; i++) {
        let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.assigneeUpdateObjs[i].belongsToUser)
        let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
        state.data.teamMembersInfo[thisMemberIndex].awaitingVerificationLeadsNum = payload.data.assigneeUpdateObjs[i].leadsAwaitingVerification
        state.data.teamMembersInfo[thisMemberIndex].awaitingUpdateLeadsNum = payload.data.assigneeUpdateObjs[i].leadsAwaitingUpdate
        if (payload.data.leadStatus === 'awaitingAction') {
          state.data.teamMembersInfo[thisMemberIndex].awaitingActionLeadsNum = state.data.teamMembersInfo[thisMemberIndex].awaitingActionLeadsNum - 1
        } else if (payload.data.leadStatus === 'investigating') {
          state.data.teamMembersInfo[thisMemberIndex].investigatingLeadsNum = state.data.teamMembersInfo[thisMemberIndex].investigatingLeadsNum - 1
        } else if (payload.data.leadStatus === 'closing') {
          state.data.teamMembersInfo[thisMemberIndex].closingLeadsNum = state.data.teamMembersInfo[thisMemberIndex].closingLeadsNum - 1
        }
      }
      for (let i = 0; i < payload.data.returnMemberMonthlyStats.length; i++) {
        let thisMember = state.data.teamMembersMonthlyStats.find(member => member.memberId === payload.data.returnMemberMonthlyStats[i].belongsToUser.toString())
        let thisMemberIndex = state.data.teamMembersMonthlyStats.indexOf(thisMember)
        let thisMonthlyStat = thisMember.memberMonthlyStats.find(monthlyStat => monthlyStat.sessionParsed === payload.data.returnMemberMonthlyStats[i].sessionParsed)
        if (thisMonthlyStat) {
          let thisMonthlyStatIndex = thisMember.memberMonthlyStats.indexOf(thisMonthlyStat)
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats[thisMonthlyStatIndex] = payload.data.returnMemberMonthlyStats[i]
        } else {
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats.splice(23, 1, (payload.data.returnMemberMonthlyStats[i]))
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordSweepQuery.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.updatedLead) {
        if (payload.data.updatedLead.status === 'awaitingAction') {
          for (let i = 0; i < payload.data.updatedLead.assigneeIds.length; i++) {
            let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.assigneeIds[i].toString())
            let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
            state.data.teamMembersInfo[thisMemberIndex].awaitingUpdateLeadsNum = state.data.teamMembersInfo[thisMemberIndex].awaitingUpdateLeadsNum - 1
            state.data.teamMembersInfo[thisMemberIndex].awaitingVerificationLeadsNum = state.data.teamMembersInfo[thisMemberIndex].awaitingVerificationLeadsNum + 1
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.assigneeIds.length; i++) {
        let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.assigneeIds[i].toString())
        let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
        state.data.teamMembersInfo[thisMemberIndex].closingLeadsNum = state.data.teamMembersInfo[thisMemberIndex].closingLeadsNum - 1
        if (payload.data.activeDiscrepancies > 0) {
          state.data.teamMembersInfo[thisMemberIndex].awaitingUpdateLeadsNum = state.data.teamMembersInfo[thisMemberIndex].awaitingUpdateLeadsNum + 1
        }
      }
      for (let i = 0; i < payload.data.assigneeUpdateObjs.length; i++) {
        let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.assigneeUpdateObjs[i].belongsToUser)
        let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
        state.data.teamMembersInfo[thisMemberIndex].closedRenegotiations = payload.data.assigneeUpdateObjs[i].closedRenegotiations
        state.data.teamMembersInfo[thisMemberIndex].renegotiationFrequency = payload.data.assigneeUpdateObjs[i].renegotiationFrequency
        state.data.teamMembersInfo[thisMemberIndex].closedRefinances = payload.data.assigneeUpdateObjs[i].closedRefinances
        state.data.teamMembersInfo[thisMemberIndex].refinanceFrequency = payload.data.assigneeUpdateObjs[i].refinanceFrequency
        state.data.teamMembersInfo[thisMemberIndex].grossProfitNumber = payload.data.assigneeUpdateObjs[i].grossProfitNumber
        state.data.teamMembersInfo[thisMemberIndex].grossProfitPercent = payload.data.assigneeUpdateObjs[i].grossProfitPercent
        state.data.teamMembersInfo[thisMemberIndex].awaitingVerificationLeadsNum = payload.data.assigneeUpdateObjs[i].leadsAwaitingVerification
        state.data.teamMembersInfo[thisMemberIndex].awaitingUpdateLeadsNum = payload.data.assigneeUpdateObjs[i].leadsAwaitingUpdate
      }
      for (let i = 0; i < payload.data.returnMemberMonthlyStats.length; i++) {
        let thisMember = state.data.teamMembersMonthlyStats.find(member => member.memberId === payload.data.returnMemberMonthlyStats[i].belongsToUser.toString())
        let thisMemberIndex = state.data.teamMembersMonthlyStats.indexOf(thisMember)
        let thisMonthlyStat = thisMember.memberMonthlyStats.find(monthlyStat => monthlyStat.sessionParsed === payload.data.returnMemberMonthlyStats[i].sessionParsed)
        if (thisMonthlyStat) {
          let thisMonthlyStatIndex = thisMember.memberMonthlyStats.indexOf(thisMonthlyStat)
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats[thisMonthlyStatIndex] = payload.data.returnMemberMonthlyStats[i]
        } else {
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats.splice(23, 1, (payload.data.returnMemberMonthlyStats[i]))
        }
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.assigneeUpdateObjs.length; i++) {
        let thisMember = state.data.teamMembersInfo.find(member => member.memberId === payload.data.assigneeUpdateObjs[i].belongsToUser)
        let thisMemberIndex = state.data.teamMembersInfo.indexOf(thisMember)
        if (payload.data.assigneeUpdateObjs[i].verifyingAwaitingUpdte === true) {
        } else if (payload.data.assigneeUpdateObjs[i].verifyingAwaitingVerification === true) {

        } else {
          state.data.teamMembersInfo[thisMemberIndex].closedRenegotiations = payload.data.assigneeUpdateObjs[i].closedRenegotiations
          state.data.teamMembersInfo[thisMemberIndex].renegotiationFrequency = payload.data.assigneeUpdateObjs[i].renegotiationFrequency
          state.data.teamMembersInfo[thisMemberIndex].closedRefinances = payload.data.assigneeUpdateObjs[i].closedRefinances
          state.data.teamMembersInfo[thisMemberIndex].refinanceFrequency = payload.data.assigneeUpdateObjs[i].refinanceFrequency
          state.data.teamMembersInfo[thisMemberIndex].grossProfitNumber = payload.data.assigneeUpdateObjs[i].grossProfitNumber
          state.data.teamMembersInfo[thisMemberIndex].grossProfitPercent = payload.data.assigneeUpdateObjs[i].grossProfitPercent
          state.data.teamMembersInfo[thisMemberIndex].awaitingVerificationLeadsNum = payload.data.assigneeUpdateObjs[i].leadsAwaitingVerification
          state.data.teamMembersInfo[thisMemberIndex].awaitingUpdateLeadsNum = payload.data.assigneeUpdateObjs[i].leadsAwaitingUpdate
        }
      }
      for (let i = 0; i < payload.data.returnMemberMonthlyStats.length; i++) {
        let thisMember = state.data.teamMembersMonthlyStats.find(member => member.memberId === payload.data.returnMemberMonthlyStats[i].belongsToUser.toString())
        let thisMemberIndex = state.data.teamMembersMonthlyStats.indexOf(thisMember)
        let thisMonthlyStat = thisMember.memberMonthlyStats.find(monthlyStat => monthlyStat.sessionParsed === payload.data.returnMemberMonthlyStats[i].sessionParsed)
        if (thisMonthlyStat) {
          let thisMonthlyStatIndex = thisMember.memberMonthlyStats.indexOf(thisMonthlyStat)
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats[thisMonthlyStatIndex] = payload.data.returnMemberMonthlyStats[i]
        } else {
          state.data.teamMembersMonthlyStats[thisMemberIndex].memberMonthlyStats.splice(23, 1, (payload.data.returnMemberMonthlyStats[i]))
        }
      }
    })
  }
})

export default teamMembersSlice.reducer

export const selectTeamMembersInfo = state => state.teamMembers.data.teamMembersInfo
export const selectTeamMembersByName = state => state.teamMembers.data.teamMembersByName
export const selectTeamMembersMonthlyStats = state => state.teamMembers.data.teamMembersMonthlyStats