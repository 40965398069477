import React from "react";
import SellIcon from '@mui/icons-material/Sell';
import AdjustIcon from '@mui/icons-material/Adjust';
import SearchIcon from '@mui/icons-material/Search';
import CircleIcon from '@mui/icons-material/Circle';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useTheme } from "@mui/styles";
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { useDispatch } from "react-redux";
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { setGuideNavSelection } from "../../../../../slices/sessionDataSlice";
import { Divider, FlexWrapper, GuideText, InlineLink, MilestoneConnector, MilestonePathContainer, Typography } from "../../../../../StyledComponents";

const LeadInvestigationGuide = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Active Investigations" Component
      </GuideText>
      <GuideText className="medium">
        is where your team will manage the workflow of investigating violations discovered by the system.
      </GuideText>
      
      <Divider className="narrow" />
      
      <GuideText className="header">
        Standard Investigation Workflow
      </GuideText>
      <FlexWrapper className="fitContentHeight" style={{padding: '1rem 0 0 0.3rem'}}>
        <MilestonePathContainer>
          <FlagCircleIcon style={{color: `${theme.icon.primary.primary}`, height: '1.9rem', width: '1.9rem'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
        <GuideText style={{paddingLeft: '1.3rem'}}>
          Potential violations are given an "awaiting action" status when first discovered by the system.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight" style={{paddingLeft: '0.5rem'}}>
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '3.6rem'}} />
          <FiberManualRecordIcon style={{height: '0.5rem'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
        <GuideText className="newParagraph" style={{paddingLeft: '1.3rem'}}>
          Once examined, a user can determine if a renegotiation or refinance should be investigated.<br/>
          If mitigating action is not required, the situation can be dismissed.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight" style={{paddingLeft: '0.3rem'}}>
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '1.1rem'}} />
          <SearchIcon style={{color: `${theme.icon.primary.primary}`, height: '1.9rem', width: '1.9rem'}} />
          <MilestoneConnector style={{height: '2.2rem'}} />
        </MilestonePathContainer>
        <FlexWrapper className="column">
          <GuideText className="newParagraph" style={{paddingLeft: '1.3rem'}}>
            An investigation can optionally be assigned to one or many users if they wanted to<br/>
            receive relevent notifications as the investigation progresses.
          </GuideText>
          <GuideText style={{paddingLeft: '1.3rem'}}>
            
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight" style={{paddingLeft: '0.5rem'}}>
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '3.6rem'}} />
          <FiberManualRecordIcon style={{height: '0.5rem'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
        <GuideText className="newParagraph" style={{paddingLeft: '1.3rem'}}>
          Once a resolution is established, the investigation waits for the public records to reflect the finalized details.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight" style={{paddingLeft: '0rem'}}>
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '1.1rem'}} />
          <SportsScoreIcon style={{height: '2.5rem', width: '2.5rem'}} />
        </MilestonePathContainer>
        <GuideText className="newParagraph" style={{paddingLeft: '0.8rem'}}>
          Downloadable reports are generated to record the details of the investigation and the new arrangements.
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph" style={{paddingLeft: '10rem'}}>
        * Metrics are kept on the outcome of every lead in order to assess performance over time.
      </GuideText>
      <GuideText style={{paddingLeft: '11rem'}}>
        Snapshots of these statistics can be seen in the "Team Performance" components.
      </GuideText>

      <Divider className="narrow" />

      <GuideText className="header">
        Investigation Actions
      </GuideText>
      <GuideText className="medium newParagraph" style={{paddingLeft: '2rem'}}>
        Investigations are managed through the app by performing the following actions:
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <SummarizeIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Viewing Reports
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        View any report previously generated for this mortgage.
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <EditNoteIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Adding Notes
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Streamline communication by saving notes to an investigation.
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <FileCopyIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Comparing Records
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Compare the mortgage's previously recorded details with the current public records to get a detailed perspective of the situation. 
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <HomeWorkIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Viewing Property Details
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Detailed property information that is updated automatically to reflect public records.
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <AdjustIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Changing Refinance Targets
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        If a Refinance is the intended outcome, the target term, principal, interest rate, and amortization schedule<br/>
        can be defined here. The system will provide forecasted profit numbers based on these inputs. 
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <SellIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Assigning Tags
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Tags are used to group, sort, and easily identify the scenario behind each investigation.
      </GuideText>
      <GuideText className="twoIndent">
        Tags are assigned by the system based on the unique details behind each mortgage and violation.
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <PersonAddIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Managing Assignees
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        When a user is assigned to an investigation, they are able to receive in-app and/or email notifications when<br/>
        there are important developments or actions taken.
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <CircleIcon style={{color: `${theme.palette.primary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Advancing Investigations
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Investigations are moved from "awaiting action" to "investigating" to "closing" for easier tracking in the system. 
      </GuideText>
      <FlexWrapper className="alignCenter" style={{padding: '2rem 0 0 4rem'}}>
        <CircleIcon style={{color: `${theme.palette.secondary.main}`, width: '2rem', height: '2rem', margin: '0 0.7rem'}} />
        <GuideText className="medium">
          Dismissing Investigations
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        If the current scenario is determined to not require mitigating action, the lead can be dismissed.
      </GuideText>
      <GuideText className="twoIndent bottom">
        All updates found in the public records or from actions taken are saved alongside the mortgage.
      </GuideText>
    </FlexWrapper>
  )
}

export default LeadInvestigationGuide