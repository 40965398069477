import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";
import { dropdownStyles, dropdownMenuProps } from "../../componentThemes";

const Tier1ParametersDropdown = ({ value, handleChange, items }) => {
  const theme = useTheme()
  const dropdown = dropdownStyles(theme)

  return (
    <FormControl className={dropdown.formControl}>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        MenuProps={dropdownMenuProps}
        classes={{
          select: dropdown.select,
          icon: dropdown.selectIcon
        }}
      >
        {items.map((parameter) => (
          <MenuItem
            muiskiplisthighlight='true'
            key={parameter.apiMapping}
            disabled={value === parameter.apiMapping}
            value={parameter.apiMapping}
          >
            {parameter.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Tier1ParametersDropdown;
