import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    //* Admin - Upload CSV
    checkMortgageDuplicates: false,
    deleteUploadReport: false,
    //* Admin - Repository
    deleteMortgage: false,
    clearMortgageCurrentPublicRecordValues: false,
    clearMortgageCurrentRecordValues: false,
    //* Admin - Repository && LeadAwaitingUpdateCard
    runSingleScan: false,
    //* Admin - Record Sweeps
    runRecordSweep: false,
    //* Admin - Actions Log
    deleteActionLog: false,
    //* Admin - Database
    dropAllFromDatabase: false,
    //* Admin - Team Details
    addMortgageType: false,
    //* Admin - Data Model / Configuration
    editParameterStatus: false,
    //* Settings
    editDefaultTargets: false,
    editSecuritySettings: false,
    editDefaultPaymentSchedule: false,
    //* Repository
    assignMortgageTag: false,
    createMortgageTag: false,
    csvMortgageUpload: false,
    searchMortgageUpload: false,
    //* PropertySearch
    runMortgageScan: false,
    searchForProperty: false,
    saveFromSearch: false,
    //* Active Leads
    assignLeadTag: false,
    createLeadTag: false,
    setInvestigationClosing: false,
    setInvestigationFinalized: false,
    dismissLead: false,
    validateLeadAwaitingUpdate: false,
    saveTargetRefinanceChanges: false,
    //* Repository && Active Leads
    addMortgageNote: false,
    resolveAllMortgageDiscrepancies: false,
    editMortgageRecordDetails: false,
    editLeadTargetOutcome: false,
    addAssignees: false,
    openLeadInvestigation: false,
  },
  status: 'idle',
  error: null
}

export const userActionsPermissionsSlice = createSlice({
  name: 'userActionsPermissions',
  initialState,
  reducers: {
    setDuplicatesCheckEnabled(state, action) { state.data.checkMortgageDuplicates = action.payload },
    setCurrentUserActionsPermissions(state, action) { state.data = action.payload },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.userActionsPermissions
      }
    })
    .addMatcher(apiSlice.endpoints.selectNewUserActionsPermissionsSet.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.permissionsObj
    })
  }
})

export const { setDuplicatesCheckEnabled, setCurrentUserActionsPermissions } = userActionsPermissionsSlice.actions


export default userActionsPermissionsSlice.reducer

export const selectCurrentUserActionsPermissions = state => state.userActionsPermissions.data
export const selectDismissLeadPerm = state => state.userActionsPermissions.data.dismissLead
export const selectAddAssigneesPerm = state => state.userActionsPermissions.data.addAssignees
export const selectRunSingleScanPerm = state => state.userActionsPermissions.data.runSingleScan
export const selectAssignLeadTagPerm = state => state.userActionsPermissions.data.assignLeadTag
export const selectCreateLeadTagPerm = state => state.userActionsPermissions.data.createLeadTag
export const selectDeleteMortgagePerm = state => state.userActionsPermissions.data.deleteMortgage
export const selectRunRecordSweepPerm = state => state.userActionsPermissions.data.runRecordSweep
export const selectSaveFromSearchPerm = state => state.userActionsPermissions.data.saveFromSearch
export const selectRunMortgageScanPerm = state => state.userActionsPermissions.data.runMortgageScan
export const selectDeleteActionLogPerm = state => state.userActionsPermissions.data.deleteActionLog
export const selectAddMortgageNotePerm = state => state.userActionsPermissions.data.addMortgageNote
export const selectAddMortgageTypePerm = state => state.userActionsPermissions.data.addMortgageType
export const selectSearchForPropertyPerm = state => state.userActionsPermissions.data.searchForProperty
export const selectAssignMortgageTagPerm = state => state.userActionsPermissions.data.assignMortgageTag
export const selectCSVMortgageUploadPerm = state => state.userActionsPermissions.data.csvMortgageUpload
export const selectCreateMortgageTagPerm = state => state.userActionsPermissions.data.createMortgageTag
export const selectDeleteUploadReportPerm = state => state.userActionsPermissions.data.deleteUploadReport
export const selectEditDefaultTargetsPerm = state => state.userActionsPermissions.data.editDefaultTargets
export const selectDropAllFromDatabasePerm = state => state.userActionsPermissions.data.dropAllFromDatabase
export const selectEditParameterStatusPerm = state => state.userActionsPermissions.data.editParameterStatus
export const selectSearchMortgageUploadPerm = state => state.userActionsPermissions.data.searchMortgageUpload
export const selectEditSecuritySettingsPerm = state => state.userActionsPermissions.data.editSecuritySettings
export const selectEditLeadTargetOutcomePerm = state => state.userActionsPermissions.data.editLeadTargetOutcome
export const selectOpenLeadInvestigationPerm = state => state.userActionsPermissions.data.openLeadInvestigation
export const selectCheckMortgageDuplicatesPerm = state => state.userActionsPermissions.data.checkMortgageDuplicates
export const selectSetInvestigationClosingPerm = state => state.userActionsPermissions.data.setInvestigationClosing
export const selectEditMortgageRecordDetailsPerm = state => state.userActionsPermissions.data.editMortgageRecordDetails
export const selectSetInvestigationFinalizedPerm = state => state.userActionsPermissions.data.setInvestigationFinalized
export const selectEditDefaultPaymentSchedulePerm = state => state.userActionsPermissions.data.editDefaultPaymentSchedule
export const selectSaveTargetRefinanceChangesPerm = state => state.userActionsPermissions.data.saveTargetRefinanceChanges
export const selectSetValidateLeadAwaitingUpdatePerm = state => state.userActionsPermissions.data.validateLeadAwaitingUpdate
export const selectResolveAllMortgageDiscrepanciesPerm = state => state.userActionsPermissions.data.resolveAllMortgageDiscrepancies
export const selectClearMortgageCurrentRecordValuesPerm = state => state.userActionsPermissions.data.clearMortgageCurrentRecordValues
export const selectClearMortgageCurrentPublicRecordValuesPerm = state => state.userActionsPermissions.data.clearMortgageCurrentPublicRecordValues