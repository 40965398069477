import React, { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/styles";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Button, CardButton, ComponentSectionRow, FlexWrapper, Typography } from "../../../../../StyledComponents";

const TagTypeDisplay = ({ tagData, assignmentType, tagType }) => {
  const theme = useTheme()

  const [tagShown, setTagShown] = useState(null)
  const [fieldsShown, setFieldsShown] = useState([])
  const [showingFields, setShowingFields] = useState(false)

  const showFields = (fields, tag) => {
    setTagShown(tag)
    setFieldsShown(fields)
    setShowingFields(true)
  }
  const hideFields = () => {
    setTagShown(null)
    setFieldsShown([])
    setShowingFields(false)
  }

  return (
    <>
        <ComponentSectionRow style={{width: '90%', margin: '1rem auto 1rem 2rem'}}>
          <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
            <FlexWrapper className="justifyCenter flexWrap fitContentHeight">
              {tagData && tagData.tags.map((tag, index) => (((tag.apiMapping === 'propertyType' || tag.apiMapping === 'loanType' || tag.apiMapping === 'taxInitialDelinquencyYear')) &&
                <FlexWrapper className="fitContentWidth fitContentHeight" style={{margin: index !== 0 ? "0 0 1rem 5.5rem" : "0 0 1rem 0"}}>
                  <Tooltip
                    key={tag.label}
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.tooltip.label.color}`,
                      backgroundColor: `${theme.tooltip.label.background}`,
                      border: `0.1rem solid ${theme.tooltip.label.border}`,
                      borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                      opacity: '0',
                      borderRadius: '1rem',
                      width: 'fit-content',
                      minWidth: 'fit-content',
                      padding: '0.5rem 1rem',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, 0],
                      },
                    }]}}
                    title={!showingFields && tagType !== 'mortgage' ?
                      <FlexWrapper className="column">
                        <Typography className="bold alignTextCenter">
                          View violated fields
                        </Typography>
                      </FlexWrapper>
                    : null} 
                    placement="top"
                    arrow
                  >
                    <CardButton 
                      className={tagType === 'mortgage' ?
                        "horizontal primaryDark onDisplay noHover"
                      : (tagShown === `${tag.label}` || tagShown === null) ? 
                        "horizontal primaryDark onDisplay" 
                      : 
                        "horizontal primaryDark onDisplay hidden"
                      }
                      onClick={tagType === 'mortgage' ? null : () => showFields(tag.discrepancyFields, tag.label)}
                    >
                      <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem'}} />
                      <Typography 

                      >
                        {tag.label}
                      </Typography>
                    </CardButton>
                  </Tooltip>
                  {(tagType === 'mortgage' && (tag.label === 'Loan Type' || tag.label === 'Property Type' || tag.apiMapping === 'taxInitialDelinquencyYear')) &&
                    <Typography className={(tagShown === `${tag.label}` || tagShown === null) ? "large bold accent onDisplay" : "hidden"}>
                      {tag.discrepancyFields.map((field, index) => (
                        index === (tag.discrepancyFields.length - 1) ?
                          <span key={field}>
                            {field}
                          </span>
                        :
                          <span key={field}>
                            {field},
                          </span>
                      ))}
                    </Typography>
                  }
                </FlexWrapper>
              ))}
            </FlexWrapper>
            <FlexWrapper className="justifyCenter flexWrap fitContentHeight">
              {tagData && tagData.tags.map((tag) => (((tag.apiMapping !== 'propertyType' && tag.apiMapping !== 'loanType' && tag.apiMapping !== 'taxInitialDelinquencyYear')) &&
                <FlexWrapper className="fitContentWidth fitContentHeight" style={{margin: '1rem'}}>
                  <Tooltip
                    key={tag.label}
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.tooltip.label.color}`,
                      backgroundColor: `${theme.tooltip.label.background}`,
                      border: `0.1rem solid ${theme.tooltip.label.border}`,
                      borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                      opacity: '0',
                      borderRadius: '1rem',
                      width: 'fit-content',
                      minWidth: 'fit-content',
                      padding: '0.5rem 1rem',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, 0],
                      },
                    }]}}
                    title={!showingFields && tagType !== 'mortgage' ?
                      <FlexWrapper className="column">
                        <Typography className="bold alignTextCenter">
                          View violated fields
                        </Typography>
                      </FlexWrapper>
                    : null} 
                    placement="top"
                    arrow
                  >
                    <CardButton 
                      className={tagType === 'mortgage' ?
                        "horizontal primaryDark onDisplay noHover"
                      : (tagShown === `${tag.label}` || tagShown === null) ? 
                        "horizontal primaryDark onDisplay" 
                      : 
                        "horizontal primaryDark onDisplay hidden"
                      }
                      onClick={tagType === 'mortgage' ? null : () => showFields(tag.discrepancyFields, tag.label)}
                    >
                      <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem'}} />
                      <Typography 

                      >
                        {tag.label}
                      </Typography>
                    </CardButton>
                  </Tooltip>
                  {(tagType === 'mortgage' && (tag.label === 'Loan Type' || tag.label === 'Property Type')) &&
                    <Typography className={(tagShown === `${tag.label}` || tagShown === null) ? "large bold accent onDisplay" : "hidden"}>
                      {tag.discrepancyFields.map((field, index) => (
                        index === (tag.discrepancyFields.length - 1) ?
                          `${field}`
                        :
                          `${field},`
                      ))}
                    </Typography>
                  }
                </FlexWrapper>
              ))}
            </FlexWrapper>
            <ComponentSectionRow className={(showingFields? 'heightOnDisplay' : 'heightHidden')}>
              <FlexWrapper className={tagShown ? "alignCenter onDisplay" : "hidden"} style={{padding: '0 6rem 0 0.5rem'}}>
                <Tooltip
                  componentsProps={{tooltip: {sx: {
                    color: `${theme.tooltip.label.color}`,
                    backgroundColor: `${theme.tooltip.label.background}`,
                    border: `0.1rem solid ${theme.tooltip.label.border}`,
                    borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                    opacity: '0',
                    borderRadius: '1rem',
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    padding: '0.5rem 1rem',
                  }}}}
                  PopperProps={{modifiers: [{
                    name: "offset",
                    options: {
                      offset: [0, -6],
                    },
                  }]}}
                  title={tagShown ?
                    <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Close
                      </Typography>
                    </FlexWrapper>
                  :null} 
                  placement="top"
                  arrow
                >
                  <Button onClick={() => hideFields()} className="round secondaryLight">
                    <CancelIcon />
                  </Button>
                </Tooltip>
                <FlexWrapper className={tagShown ? "fitContentHeight flexWrap onDisplay" : "hidden"} style={{padding: '0 6rem 0 0.5rem'}}>
                  <Typography className="bold">
                    {assignmentType === 'query' ?
                      `Fields found in violation that resulted in this tag being applied:`
                    :
                      `Fields that apply this tag when a violation is found:`
                    }
                  </Typography>
                  {fieldsShown.map((field, index) => (
                    index === (fieldsShown.length - 1) ?
                      <Typography key={field} className="thin" style={{margin: '0 0 0 0.6rem'}}>
                        {field}
                      </Typography>
                    :
                      <Typography key={field} className="thin" style={{margin: '0 0 0 0.6rem'}}>
                        {field},
                      </Typography>
                  ))}
                </FlexWrapper>
              </FlexWrapper>
            </ComponentSectionRow>
          </FlexWrapper>
        </ComponentSectionRow>
    </>
  )
}

export default TagTypeDisplay