import React, { useState, useRef } from "react";
import 'react-csv-importer/dist/index.css';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledTextArea } from "../../../../componentThemes.js";
import { selectUserFullName } from "../../../../slices/userInfoSlice.js";
import { selectViewingMortgage } from "../../../../slices/viewingMortgageSlice.js";
import { selectAddMortgageNotePerm } from "../../../../slices/userActionsPermissionsSlice.js";
import { useAddMortgageNoteMutation } from "../../../../slices/api/apiSlice.js";
import { Button, ButtonWrapper, FlexWrapper, InputWrapper, AccentInfo, NoteContainer, NoteContentContainer, Label, ModalContentContainer, ComponentHeader, ErrorWrapper, StatusAndLabelWrapper } from "../../../../StyledComponents.js";


const Notes = ({ handleCloseModal, userId, leadData, dashPerspective, requestOrigin, activeLead }) => {
	const theme = useTheme();

  const addMortgageNotePerm = useSelector(selectAddMortgageNotePerm)
  const userFullName = useSelector(selectUserFullName)
  const mortgageData = useSelector(selectViewingMortgage)

  const [addMortgageNotes, { isFetching, isSuccess, isError }] = useAddMortgageNoteMutation()

  const handleClearNotes = () => {
    setNotesCount(0)
    setStatusNotesLength(null)
    setStatusNotesCharacter(null)
    mortgageNotes.current.value = ''
  }

  const handleSaveNote = async () => {
    let currentNote = mortgageNotes.current.value 
    let timeline = []
    if (requestOrigin === 'repository') {
      timeline = mortgageData.timeline
    } else {
      timeline = leadData.timeline
    }
    addMortgageNotes({
      leadId: leadData._id,
      timeline: timeline,
      dashPerspective: dashPerspective,
      note: currentNote,
      author: userFullName,
      mortgageId: mortgageData._id,
      userId: userId,
      requestOrigin: requestOrigin,
      userNotifications: leadData.userNotifications,
    });
    mortgageNotes.current.value = "";
    setNotesCount(0)
  };

  const [statusNotesCharacter, setStatusNotesCharacter] = useState(null);
  const [statusNotesLength, setStatusNotesLength] = useState(null);
  const mortgageNotes = useRef();
  const [notesCount, setNotesCount] = useState(0);

  const handleNotesCount = () => {
    setNotesCount(mortgageNotes.current.value.length);
		if (mortgageNotes.current.value.includes('<') || mortgageNotes.current.value.includes('>')) {
			setStatusNotesCharacter(true)
		} else {
			setStatusNotesCharacter(false)
		}
		if (mortgageNotes.current.value.length > 255) {
			setStatusNotesLength("tooLong")
		} else {
			setStatusNotesLength(null)
    }
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.</ComponentHeader>
      :
        <ComponentHeader className="modal">Notes</ComponentHeader>
      }
      <ModalContentContainer className="noScroll column leadActions darkBackground">
        {mortgageData !== undefined ?
          <>
            <NoteContainer className="actionModal">
              <NoteContentContainer className="list">
                {mortgageData.mortgageNotes.length > 0 ?
                  <ul style={{maxWidth:  '100%', overflowWrap: 'break-word', padding: '0 0 0 1rem'}}>
                    {mortgageData.mortgageNotes.map((note, index) => (
                      <div key={note._id} style={{marginTop: index === 0 ? '0' : '1rem'}}>
                        <li>{note.content}</li>
                        <span>- {note.author}, {note.date}</span>
                      </div>
                    ))}
                  </ul>
                :
                  <AccentInfo>- no notes recorded -</AccentInfo>
                }
              </NoteContentContainer>
              <NoteContentContainer className="input">
                <InputWrapper style={{width: '100%', margin: '0 0.5rem'}}>
                  <StatusAndLabelWrapper className="notes">
                    <Label>Add  Note</Label>
                    <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                      {statusNotesCharacter === true && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Cannot include the characters " < " or " > "`}
                        </ErrorWrapper>
                      )}
                      {statusNotesLength === "tooLong" && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Note cannot be longet than 255 characters`}
                        </ErrorWrapper>
                      )}
                      {statusNotesLength === "tooShort" && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Cannot submit a blank note`}
                        </ErrorWrapper>
                      )}
                      <Label className="count">{notesCount}/255</Label>
                    </FlexWrapper>
                  </StatusAndLabelWrapper>
                  <StyledTextArea
                    autoFocus
                    type="text"
                    name="term"
                    id="term"
                    ref={mortgageNotes}
                    onChange={() => {
                      if (statusNotesLength === "tooShort") {
                        setStatusNotesLength(null)
                      }
                      handleNotesCount();
                    }}
                  />
                </InputWrapper>
                {notesCount === 0 ?
                  <Button onClick={() => handleClearNotes()}  style={{marginRight: '0.5rem'}} className="secondaryDisabled notes"><BackspaceIcon /></Button>
                :
                  <Button onClick={() => handleClearNotes()}  style={{marginRight: '0.5rem'}} className="secondary notes"><BackspaceIcon /></Button>
                }
                {isFetching ?
                  <Button className="primaryLoading notes"><SaveIcon /></Button>
                : notesCount === 0 || statusNotesLength === 'tooLong' || statusNotesCharacter ?
                  <Button className="primaryDisabled notes"><SaveIcon /></Button>
                :
                  <Button onClick={() => handleSaveNote("initial")} className="primary notes"><SaveIcon /></Button>
                }
              </NoteContentContainer>
            </NoteContainer>
          </>
        :
          <NoteContainer className="center">
            <CircularProgress
              sx={{ color: `${theme.palette.primary.main}` }}
              size={36}
            />
          </NoteContainer>
        }
        <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
          <Button onClick={addMortgageNotePerm ? () => handleCloseModal() : null} className={addMortgageNotePerm ? "secondary" : "secondaryDisabled"} style={{margin: '1rem auto'}}>Close</Button>
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default Notes;