import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useTheme } from "@mui/styles";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { ColumnContent, ComponentWrapper, HeaderText, HorizontalContainer, MainContent, VerticalContainer } from "../../../../../StyledComponents";

const Team = ({ teamData }) => {
  const theme = useTheme()

  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{border: 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{background: `${theme.palette.common.grayBorder}`}}>
          <HeaderText className="sub">Team</HeaderText>
        </AccordionSummary>
        <AccordionDetails style={{borderTop: `0.1rem solid ${theme.palette.common.grayText}`}}>
          <HorizontalContainer className='fitContent'>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Name
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.teamName}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Date Created
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.dateCreated}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                ID
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData._id}</MainContent>
              </ColumnContent>
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className='fitContent'>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Renegotiations
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.closedRenegotiations}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Closures
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.renegotiationClosures.length}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Last
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.lastRenegotiation}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Frequency
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.renegotiationFrequency}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Refinances
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.closedRefinances}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Closures
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.refinanceClosures.length}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Last
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.lastRefinance}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Frequency
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.refinanceFrequency}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Dismissed
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.dismissedLeads}</MainContent>
              </ColumnContent>
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className='fitContent'>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Total Mortgages
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.mortgages.length}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Total Reports
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.reports.length}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Profit Number
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.grossProfitNumber}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Profit Percent
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.grossProfitPercent}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Average Profit Number
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.averageProfitNumber}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Average Profit Percent
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.averageProfitPercent}</MainContent>
              </ColumnContent>
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className='fitContent'>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Awaiting Action
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.leadsAwaitingAction.length}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Investigating
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.investigatingLeads.length}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Closing
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.closingLeads.length}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Total Tier 1
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.totalTier1Leads}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Total Tier 2
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.totalTier2Leads}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Total Hits Avg %
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.totalHitsAvgPercent}</MainContent>
              </ColumnContent>
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className='fitContent'>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Query Frequency
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.queryFrequency}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Last Query
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.lastQuery}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Default Term
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.defaultTargetTerm}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Default Rate
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.defaultTargetInterestRate}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Auto Interest
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Lead Tags
              </ColumnContent>
              <ColumnContent>
                <MainContent>{teamData.leadTags.length}</MainContent>
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Tier 1 Parameters
              </ColumnContent>
              <ColumnContent>
                {/* <MainContent>{teamData.tier1Parameters.length}</MainContent> */}
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Tier 2 Parameters
              </ColumnContent>
              <ColumnContent>
                {/* <MainContent>{teamData.tier2Parameters.length}</MainContent> */}
              </ColumnContent>
            </VerticalContainer>
            <VerticalContainer className="fitContent">
              <ColumnContent className="bottomBorder">
                Tier 3 Parameters
              </ColumnContent>
              <ColumnContent>
                {/* <MainContent>{teamData.tier3Parameters.length}</MainContent> */}
              </ColumnContent>
            </VerticalContainer>
          </HorizontalContainer>
        </AccordionDetails>
      </Accordion>
  )
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.1rem solid ${theme.palette.common.grayBorder}`,
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'none',
  flexDirection: 'row-reverse',
  minHeight: '3rem',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '1rem',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default Team;