import React, { useState, useRef } from "react";
import 'react-csv-importer/dist/index.css';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledTextArea } from "../../../../componentThemes.js";
import { selectUserFullName } from "../../../../slices/userInfoSlice.js";
import { selectViewingMortgage } from "../../../../slices/viewingMortgageSlice.js";
import { selectAddMortgageNotePerm } from "../../../../slices/userActionsPermissionsSlice.js";
import { useAddMortgageNoteMutation } from "../../../../slices/api/apiSlice.js";
import { Button, FlexWrapper, InputWrapper, AccentInfo, NoteContainer, NoteContentContainer, Label, ModalContentContainer, ComponentHeader, ErrorWrapper, StatusAndLabelWrapper } from "../../../../StyledComponents.js";


const Notes = ({ userId, leadData, requestOrigin, mortgagestatus }) => {
	const theme = useTheme()

  const addMortgageNotePerm = useSelector(selectAddMortgageNotePerm)
  const userFullName = useSelector(selectUserFullName)
  const mortgageData = useSelector(selectViewingMortgage)

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const [addMortgageNote] = useAddMortgageNoteMutation()

  const handleClearNotes = () => {
    setNotesCount(0)
    setStatusNotesLength(null)
    setStatusNotesCharacter(null)
    mortgageNotes.current.value = ''
  }

  const handleSaveNote = async () => {
    setIsFetching(true)
    setIsError(false)
    setIsSuccess(false)
    let currentNote = mortgageNotes.current.value 
    let resp = await addMortgageNote({
      leadId: leadData._id,
      note: currentNote,
      author: userFullName,
      mortgageId: mortgageData._id,
      userId: userId,
      requestOrigin: requestOrigin,
    })
    if (resp.error) {
      setIsError(true)
    } else {
      setIsSuccess(true)
      mortgageNotes.current.value = "";
    }
    setNotesCount(0)
    setIsFetching(false)
  }

  const [statusNotesCharacter, setStatusNotesCharacter] = useState(null);
  const [statusNotesLength, setStatusNotesLength] = useState(null);
  const mortgageNotes = useRef();
  const [notesCount, setNotesCount] = useState(0);

  const handleNotesCount = () => {
    setNotesCount(mortgageNotes.current.value.length);
		if (mortgageNotes.current.value.includes('<') || mortgageNotes.current.value.includes('>')) {
			setStatusNotesCharacter(true)
		} else {
			setStatusNotesCharacter(false)
		}
		if (mortgageNotes.current.value.length > 255) {
			setStatusNotesLength("tooLong")
		} else {
			setStatusNotesLength(null)
    }
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
            <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
            Success
          </span>
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        <ComponentHeader className="modal">Notes</ComponentHeader>
      }
      <ModalContentContainer className="noScroll column leadActions darkBackground">
        {mortgageData !== undefined ?
          <>
            <NoteContainer className="actionModal">
              <NoteContentContainer className="list">
                {mortgageData.mortgageNotes.length > 0 ?
                  <ul style={{maxWidth:  '100%', overflowWrap: 'break-word', padding: '0 0 0 1rem'}}>
                    {mortgageData.mortgageNotes.map((note, index) => (
                      <div key={note._id} style={{marginTop: index === 0 ? '0' : '1rem'}}>
                        <li>{note.content}</li>
                        <span>- {note.author}, {note.date}</span>
                      </div>
                    ))}
                  </ul>
                :
                  <AccentInfo>- no notes recorded -</AccentInfo>
                }
              </NoteContentContainer>
              <NoteContentContainer className="input">
                <InputWrapper style={{width: '100%', margin: '0 0.5rem'}}>
                  <StatusAndLabelWrapper className="notes">
                    <Label>Add  Note</Label>
                    <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                      {statusNotesCharacter === true && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Cannot include the characters " < " or " > "`}
                        </ErrorWrapper>
                      )}
                      {statusNotesLength === "tooLong" && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Note cannot be longet than 255 characters`}
                        </ErrorWrapper>
                      )}
                      {statusNotesLength === "tooShort" && (
                        <ErrorWrapper>
                          <ErrorIcon />
                          {`Cannot submit a blank note`}
                        </ErrorWrapper>
                      )}
                      <Label className="count">{notesCount}/255</Label>
                    </FlexWrapper>
                  </StatusAndLabelWrapper>
                  <StyledTextArea
                    tabIndex="1"
                    autoFocus
                    type="text"
                    name="term"
                    id="term"
                    ref={mortgageNotes}
                    disabled={mortgagestatus === 'inactive'}
                    onChange={() => {
                      if (statusNotesLength === "tooShort") {
                        setStatusNotesLength(null)
                      }
                      handleNotesCount()
                    }}
                  />
                </InputWrapper>
                <Button
                  tabIndex="2"
                  style={{marginRight: '0.5rem'}}
                  onClick={isFetching || ((notesCount === 0 || statusNotesLength === 'tooLong' || statusNotesCharacter || !addMortgageNotePerm)) ? null : () => handleSaveNote()}
                  className={isFetching ? "primaryLoading notes" : (notesCount === 0 || statusNotesLength === 'tooLong' || statusNotesCharacter || !addMortgageNotePerm) ? "primaryDisabled notes" : "primary notes"}
                >
                  <SaveIcon />
                </Button>
                <Button
                  tabIndex="3"
                  onClick={() => handleClearNotes()}
                  className={notesCount === 0 ? "neutral notes" : "neutral notes"}
                >
                  <BackspaceIcon />
                </Button>
              </NoteContentContainer>
            </NoteContainer>
          </>
        :
          <NoteContainer className="center">
            <CircularProgress
              sx={{ color: `${theme.palette.primary.main}` }}
              size={36}
            />
          </NoteContainer>
        }
      </ModalContentContainer>
    </>
  )
}

export default Notes;