import React, { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import PendingIcon from '@mui/icons-material/Pending';
import CircularProgress from "@mui/material/CircularProgress";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useDispatch, useSelector } from "react-redux";
import { selectDuplicateCheckResponse } from "../../../../../slices/sessionDataSlice";
import { useCheckForMortgageDuplicatesMutation } from "../../../../../slices/api/apiSlice";
import { tooltipComponentProps, tooltipPopperProps } from "../../../../../componentThemes";
import { selectCheckMortgageDuplicatesPerm, setDuplicatesCheckEnabled } from "../../../../../slices/userActionsPermissionsSlice";
import { FlexWrapper, ComponentContainer, ColumnContent, Button, ComponentSectionRow, RowsContainer, ComponentBorder, HeaderText, HeaderContainer } from "../../../../../StyledComponents";

const Duplicates = ({ teamId, userFullName }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const duplicatesReport = useSelector(selectDuplicateCheckResponse)
  const checkMortgageDuplicatesPerm = useSelector(selectCheckMortgageDuplicatesPerm)
  const [mortgagesChecked, setMortgagesChecked] = useState(false)

  const [checkForMortgageDuplicates, { isLoading }] = useCheckForMortgageDuplicatesMutation()
  const handleClick = async () => {
    setMortgagesChecked(true)
    await checkForMortgageDuplicates({
      teamId: teamId,
      userFullName: userFullName,
    })
    dispatch( setDuplicatesCheckEnabled(false) )
  }

  return (
    <ComponentContainer className="justifyStart detailsContainer" style={{gridRow: '2', gridColumn: '2', height: '49%'}}>
      <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
        <HeaderContainer style={{display: 'flex', justifyContent: 'center', margin: 'auto 0', height: '4rem'}}>
          {isLoading ?
            <Button className="primaryLoading" style={{width: '22rem'}}>
              <CircularProgress
                sx={{color: `${theme.palette.common.white}`}}
                size={12}
              />
            </Button>
          : !checkMortgageDuplicatesPerm ?
            <Tooltip
              componentsProps={tooltipComponentProps}
              PopperProps={tooltipPopperProps}
              title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>All mortgages have been checked</span></FlexWrapper>} 
              placement="top"
              arrow
            >
              <Button className="primaryDisabled" style={{width: '22rem'}}>Check For Mortgage Duplicates</Button>
            </Tooltip>
          :
            <Button className="primary" onClick={() => handleClick()} style={{width: '22rem'}}>Check For Mortgage Duplicates</Button>
          }
        </HeaderContainer>
        <ComponentSectionRow className="duplicateCheck header" style={{padding: '0 0.5rem'}}>
          <ColumnContent className="header" style={{gridColumn: '1'}}>
            Index
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '2'}}>
            Message
          </ColumnContent>
          <ColumnContent className="header" style={{gridColumn: '3'}}>
            Match
          </ColumnContent>
        </ComponentSectionRow>
        {duplicatesReport[1].length > 0 ?
          duplicatesReport[0].discovered ?
            <RowsContainer style={{height: '100%', padding: '0 0.5rem'}}>
              {duplicatesReport[1].map((row, index) => (
                <ComponentSectionRow key={row._id}className={(index%2 === 1 ? 'even ' : '') + 'duplicateCheck'}>
                  <ColumnContent className="detail small" style={{gridColumn: '1'}}>
                    {index + 1}
                  </ColumnContent>
                  <ColumnContent className="detail small" style={{gridColumn: '2'}}>
                    {row.message}
                  </ColumnContent>
                  <ColumnContent className="detail small" style={{gridColumn: '3'}}>
                    {row.match}
                  </ColumnContent>
                </ComponentSectionRow>
              ))}
            </RowsContainer>
          :
            <RowsContainer style={{height: '100%'}}>
              <FlexWrapper className="column justifyCenter alignCenter" style={{margin: '1rem 0'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '7rem', width: '7rem'}} />
                <HeaderText style={{fontSize: '1.4rem', color: `${theme.palette.common.grayTextLight}`}}>no duplicates detected</HeaderText>
                <span>Fields Checked: {duplicatesReport[1].map((row, index) => (<span key={row._id}>{row.checks}</span>))}</span>
              </FlexWrapper>
            </RowsContainer>
        : mortgagesChecked && checkMortgageDuplicatesPerm ?
          <RowsContainer style={{height: '100%'}}>
            <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
              <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '7rem', width: '7rem'}} />
              <HeaderText style={{fontSize: '1.4rem', color: `${theme.palette.common.grayTextLight}`}}>no duplicates detected</HeaderText>
            </FlexWrapper>
          </RowsContainer>
        :
          <RowsContainer style={{height: '100%'}}>
            <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
              <PendingIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '7rem', width: '7rem'}} />
              <HeaderText style={{fontSize: '1.4rem', color: `${theme.palette.common.grayTextLight}`}}>no checks performed</HeaderText>
            </FlexWrapper>
          </RowsContainer>
        }
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default Duplicates;