import React, { useRef, useState } from "react"
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useEditMortgageMutation } from "../../slices/api/apiSlice";
import { ActionIconWrapper, AlertWrapper, Button, ComponentBorder, ComponentHeader, FlexWrapper, InputWrapper, Label, ModalContainer, StatusAndLabelWrapper, StyledInputElement } from "../../StyledComponents"

const EditDatabaseModal = ({ dataToEdit, userFullName, closeModal }) => {
  const theme = useTheme()

  const [isError, setIsError] = useState(false)
  const [noChanges, setNoChanges] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [statusNewValue, setStatusNewValue] = useState(null)
  const [statusAccessToken, setStatusAccessToken] = useState(null)
  const newValue = useRef()

  const [editMortgage] = useEditMortgageMutation()

  const handleSaveEdit = async () => {
    setIsFetching(true)
    let invalidValue = false
    if (dataToEdit.fieldToUpdate === 'owner1' || dataToEdit.fieldToUpdate === 'owner2' || dataToEdit.fieldToUpdate === 'NumberOfUnitsTotal') {
      if (newValue.current.value.includes('<') && newValue.current.value.includes('>') && !newValue.current.value.length < 100) {
        invalidValue = true
      }
    }
    if (invalidValue) {
      setStatusNewValue('invalid')
    } else if (dataToEdit.oldValue !== newValue.current.value) {
      let resp = await editMortgage({
        newValue: newValue.current.value,
        mortgageId: dataToEdit.id,
        userFullName: userFullName,
        fieldToUpdate: dataToEdit.fieldToUpdate,
      })
      if (resp.error) {
        setIsError(true)
      } else if (resp.data.message.includes('successful')) {
        setIsSuccess(true)
      } else {
        setIsError(true)
      }
    } else {
      //TODO: bring in "noChanges"
    }
    setIsFetching(false)
  }

  return (
    <ModalContainer className="onePanel">
      <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/4'}}>
        <ComponentHeader className="areaTitle">
          Edit Database
        </ComponentHeader>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => closeModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <FlexWrapper className="column justifyCenter">  
        <FlexWrapper className="fitContentHeight">
          {isSuccess ?
            <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
          : isError ?
            <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Something went wrong.</ComponentHeader>
          :
            <ComponentHeader className="modal caution" style={{fontSize: '1rem'}}><ErrorIcon style={{height: '1.5rem', width: '1.5rem', margin: '0 0.5rem 0.2rem 0'}} />
              Public Record will also be overwritten.
            </ComponentHeader>
          }
        </FlexWrapper>
        <FlexWrapper className="alignCenter">
          <InputWrapper style={{margin: '0rem auto 1rem auto', width: 'fit-content'}}>
            <StatusAndLabelWrapper>
              <Label htmlFor="accessToken">New Value</Label>
              {statusNewValue === "invalid" && (
                <AlertWrapper className="error">
                  <ErrorIcon />
                  Invalid
                </AlertWrapper>
              )}
            </StatusAndLabelWrapper>
            <FlexWrapper>
              <StyledInputElement
                autoFocus
                autoComplete="off"
                type='text'
                name="newValue"
                id="newValue"
                className="auth"
                ref={newValue}
                disabled={isSuccess}
                onChange={() => {
                  if (statusAccessToken) {
                    setStatusAccessToken(null)
                  }
                  if (isError) {
                    setIsError(null)
                  }
                }}
              />
            </FlexWrapper>
          </InputWrapper> 
        </FlexWrapper>
        <Button 
          className={isFetching ? 'primaryLoading' : (statusAccessToken || statusNewValue || isSuccess) ? "primaryDisabled" : "primary"}
          disabled={isFetching || statusAccessToken || statusNewValue || isSuccess}
          onClick={() => handleSaveEdit()}
          style={{marginBottom: '2.5rem'}}
        >
          {isFetching ? (
            <CircularProgress
              sx={{color: `${theme.button.text.primary}`}}
              size={12}
            />
          ) : (
            "Save"
          )}
        </Button>  
      </FlexWrapper>
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default EditDatabaseModal