import React, { useEffect, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import TagsView from "./components/TagsView/TagsView";
import CircleIcon from '@mui/icons-material/Circle';
import { Dialog } from "@mui/material";
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UploadModal from "../../../../components/Modals/UploadModal/UploadModal";
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";
import { GridToolbar } from '@mui/x-data-grid';
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { setViewingLead } from "../../../../slices/viewingLeadSlice";
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import RepositoryControls from "./components/RepositoryControls";
import { StripedDataGrid } from "../../../../componentThemes";
import MortgageActionsModal from "../../../../components/Modals/MortgageActions/MortgageActionsModal";
import { setViewingReports } from "../../../../slices/teamReportsSlice";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import handleFilterMortgages from "./utils/handleFilterMortgages";
import { selectUserFullName } from "../../../../slices/userInfoSlice";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { setViewingMortgage } from "../../../../slices/viewingMortgageSlice";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { removeLeadNotification } from "../../../../slices/userNotificationsSlice";
import { useSelector, useDispatch } from "react-redux";
import { ActionIconWrapper, Badge, ComponentBorder, ComponentContainer, FlexWrapper, HeaderText, NewLabel } from "../../../../StyledComponents";
import { addCustomTagView, editCustomTagView, removeCustomTagView, selectCustomTagViewAdded, selectTagsViewMortgages } from "../../../../slices/teamStatsSlice";
import { removeNotificationFromMortgage, selectMortgagesFilteredBoolean, selectTeamMortgages, setFilteredMortgages, setFwdBckViewingFilteredMortgages, setFwdBckViewingMortgages, setMortgagesFilteredBoolean } from "../../../../slices/teamMortgagesSlice";
import { removeNotificationFromLead, selectTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads, setFwdBckFilteredViewingLeads, setFwdBckViewingLeads } from "../../../../slices/teamActiveLeadsSlice";
import { selectDashPerspective, selectLeadsFilteredBoolean, selectRepositoryPage, selectRepositoryPageSize, selectRunningSweep, selectShowMortgagectionsModal, selectViewingMortgageActions, selectViewingUploadWindow, setMortgageActionNavMenuSelection, setRepositoryPage, setRepositoryPageSize, setShowMortgageActionsModal, setViewingMortgageActions, setViewingUploadWindow } from "../../../../slices/sessionDataSlice";

const MortgageRepository = ({ userId, teamId }) => {
  const theme = useTheme()
  const navigate = useNavigate()  
  const dispatch = useDispatch()

  const pageSize = useSelector(selectRepositoryPageSize)
  const userFullName = useSelector(selectUserFullName)
  const closingLeads = useSelector(selectTeamClosingLeads)
  const runningSweep = useSelector(selectRunningSweep)
  const teamMortgages = useSelector(selectTeamMortgages)
  const currentPageSize = useSelector(selectRepositoryPage)
  const dashPerspective = useSelector(selectDashPerspective)
  const showUploadModal = useSelector(selectViewingUploadWindow)
  const tagsViewMortgages = useSelector(selectTagsViewMortgages)
  const investigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const customTagViewAdded = useSelector(selectCustomTagViewAdded)
  const awaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const awaitingUpdateLeads = useSelector(selectTeamAwaitingUpdateLeads)
  const leadsFilteredBoolean = useSelector(selectLeadsFilteredBoolean)
  const viewingMortgageActions = useSelector(selectViewingMortgageActions)
  const showMortgageActionsModal = useSelector(selectShowMortgagectionsModal)
  const mortgagesFilteredBoolean = useSelector(selectMortgagesFilteredBoolean)

  const [userFilter, setUserFilter] = useState('team')
  const [mortgageRows, setMortgageRows] = useState(teamMortgages ? teamMortgages : [])

  const handleOpenActionModal = (mortgage, destination) => {
    dispatch( setViewingReports(mortgage.reports) )
    if (mortgage.activeLead) {
      let thisLead = {}
      thisLead = awaitingActionLeads.find(lead => lead._id === mortgage.activeLead.toString())
      if (!thisLead) {
        thisLead = investigatingLeads.find(lead => lead._id === mortgage.activeLead.toString())
        if (!thisLead) {
          thisLead = closingLeads.find(lead => lead._id === mortgage.activeLead.toString())
          if (!thisLead) {
            thisLead = awaitingUpdateLeads.find(lead => lead._id === mortgage.activeLead.toString())
          }
        }
      }
      if (leadsFilteredBoolean || dashPerspective !== 'team') {
        dispatch( setFwdBckFilteredViewingLeads(thisLead._id) )
      } else {
        dispatch( setFwdBckViewingLeads(thisLead._id) )
      }
      dispatch( setViewingLead(thisLead) )
      if (thisLead.notifCount && thisLead.notifCount > 0) {
        if (thisLead.status === 'awaitingAction') {
          dispatch( removeLeadNotification({status: 'awaitingAction', count: 1}) )
          dispatch( removeNotificationFromLead({status: 'awaitingAction', leadId: thisLead._id}) )
        } else if (thisLead.status === 'investigating') {
          dispatch( removeLeadNotification({status: 'investigating', count: 1}) )
          dispatch( removeNotificationFromLead({status: 'investigating', leadId: thisLead._id}) )
        } else if (thisLead.status === 'closing') {
          dispatch( removeLeadNotification({status: 'closing', count: 1}) )
          dispatch( removeNotificationFromLead({status: 'closing', leadId: thisLead._id}) )
        } else {
          dispatch( removeLeadNotification({status: 'awaitingUpdates', count: 1}) )
          dispatch( removeNotificationFromLead({status: 'awaitingUpdates', leadId: thisLead._id}) )
        }
      }
    } else {
      dispatch( setViewingLead(null) )
      dispatch( setFwdBckViewingLeads(null) )
      dispatch( setFwdBckFilteredViewingLeads(null) )
    }
    if (mortgage.notifCount && mortgage.notifCount > 0) {
      dispatch( removeNotificationFromMortgage({mortgageId: mortgage._id}) )
    }
    if (destination === 'investigateLead') {
      dispatch( setMortgageActionNavMenuSelection('investigateLead') )
    } else {
      dispatch( setMortgageActionNavMenuSelection('compareRecords') )
    }
    if (mortgagesFilteredBoolean) {
      dispatch( setFwdBckViewingFilteredMortgages(mortgage._id) )
    } else {
      dispatch( setFwdBckViewingMortgages(mortgage._id) )
    }
    dispatch( setViewingMortgage(mortgage) )
    dispatch( setShowMortgageActionsModal(true) )
    navigate(`/dashboard/mortgageRepository/${mortgage._id}`)
  }
  const handleCloseModal = () => {
    navigate(`/dashboard/mortgageRepository/`)
    dispatch( setViewingMortgageActions(false) )
    dispatch( setShowMortgageActionsModal(false) )
  }
  const handleOpenUploadModal = () => {
    dispatch( setViewingUploadWindow(true) )
  }
  const handleCloseUploadModal = () => {
    if (!runningSweep) {
      dispatch( setViewingUploadWindow(false) )
    }
  }
 
  const handleUserChange = async (event) => {
    if (event === 'team') {
      setUserFilter('team')
      setMortgageRows(teamMortgages)
      dispatch( setMortgagesFilteredBoolean(false) )
      dispatch( setFilteredMortgages(null) )
    } else {
      setUserFilter(event)
      let filteredMortgages = await handleFilterMortgages('user', teamMortgages, event, null)
      dispatch( setMortgagesFilteredBoolean(true) )
      setMortgageRows(filteredMortgages)
      dispatch( setFilteredMortgages(filteredMortgages) )
    }
  }

  const [matchTypeSelection, setMatchTypeSelection] = useState('all')
  const handleMatchTypeSelection = (event) => {
    let newSelection = 'all'
    if (event.target.value === 'any') {
      newSelection = 'any'
    }
    setMatchTypeSelection(event.target.value)
    if (displaySelection === 'Table View') {
      handleSelectedTableDetails({target: {value: selectedTableDetail, selection: newSelection}})
    } else {
      handleSelectedTableDetails({target: {value: customTagCombination, selection: newSelection}})
    }
  }

  const [tagTypesForDropdown, setTagTypesForDropdown] = useState(tagsViewMortgages ? tagsViewMortgages.map(view => {
    return {
      tagType: view.tagType,
      tagTypeAbbr: view.tagTypeAbbr,
      customTagView: view.customTagView
    }
  }) : [])
  const [selectedTableDetail, setSelectedTableDetail] = useState(['all'])
  const [customTagCombination, setCustomTagCombination] = useState([])
  const handleSelectedTableDetails = async (event) => {
    let newSelectedDetails = event.target.value
    let tableSelectionReset = false
    let thisMatchTypeSelection = matchTypeSelection
    if (event.target.selection) {
      thisMatchTypeSelection = event.target.selection
    }
    if (displaySelection === 'Table View') {
      if ((newSelectedDetails[0] === 'all' && newSelectedDetails.length === 1) || (newSelectedDetails[newSelectedDetails.length-1] === 'all' && newSelectedDetails[0] !== 'all') || newSelectedDetails.length === 0) {
        setMortgageRows(teamMortgages)
        dispatch( setMortgagesFilteredBoolean(false) )
        dispatch( setFilteredMortgages(null) )
        setSelectedTableDetail(['all'])
        setCustomTagCombination([])
        tableSelectionReset = true
        newSelectedDetails = ['all']
      } else {
        let allIndex = newSelectedDetails.indexOf('all')
        if (allIndex >= 0) {
          newSelectedDetails.splice(allIndex, 1)
        }
        let customIndex = newSelectedDetails.indexOf('Custom')
        if (customIndex >= 0) {
          newSelectedDetails.splice(customIndex, 1)
        }
        setSelectedTableDetail(newSelectedDetails)
        setCustomTagCombination(newSelectedDetails)
      }
    } else if (newSelectedDetails.length === 0) {
      setMortgageRows(teamMortgages)
      dispatch( setMortgagesFilteredBoolean(false) )
      dispatch( setFilteredMortgages(null) )
      setSelectedTableDetail(['all'])
      setCustomTagCombination([])
      tableSelectionReset = true
      newSelectedDetails = []
    }
    let newCustomTagCombinationMortgageIds = []
    if (thisMatchTypeSelection === 'any') {
      for (let i = 0; i < newSelectedDetails.length; i++) {
        let matchingTagView = tagsViewMortgages.find(view => view.tagType === newSelectedDetails[i])
        if (matchingTagView) {
          for (let j = 0; j < matchingTagView.mortgages.length; j++) {
            let matchingMortgage = newCustomTagCombinationMortgageIds.find(mortgageId => mortgageId === matchingTagView.mortgages[j])
            if (!matchingMortgage) {
              newCustomTagCombinationMortgageIds.push(matchingTagView.mortgages[j])
            }
          }
        }
      }
    } else {
      let matchingViews = []
      for (let i = 0; i < newSelectedDetails.length; i++) {
        let matchingTagView = tagsViewMortgages.find(view => view.tagType === newSelectedDetails[i])
        if (matchingTagView) {
          matchingViews.push(matchingTagView)
        }
      }
      if (matchingViews.length === 1) {
        newCustomTagCombinationMortgageIds = matchingViews[0].mortgages
      } else if (matchingViews.length > 0) {
        for (let j = 0; j < matchingViews[0].mortgages.length; j++) {
          let existsInAll = true
          for (let k = 1; k < matchingViews.length; k++) {
            if (!matchingViews[k].mortgages.includes(matchingViews[0].mortgages[j])) {
              existsInAll = false
              break
            }
          }
          if (existsInAll) {
            newCustomTagCombinationMortgageIds.push(matchingViews[0].mortgages[j])
          }
        }
      }
    }
    let filteredMortgages = []
    if (displaySelection === 'Table View') {
      if (!tableSelectionReset) {
        filteredMortgages = await handleFilterMortgages('customTagViewCombination', teamMortgages, null, newCustomTagCombinationMortgageIds)
        dispatch( setMortgagesFilteredBoolean(true) )
        setMortgageRows(filteredMortgages)
        dispatch( setFilteredMortgages(filteredMortgages) )
      }
    } else {
      if (!tableSelectionReset) {
        setSelectedTableDetail(newSelectedDetails)
        setCustomTagCombination(newSelectedDetails)
        filteredMortgages = await handleFilterMortgages('customTagViewCombination', teamMortgages, null, newCustomTagCombinationMortgageIds)
        dispatch( setMortgagesFilteredBoolean(true) )
        setMortgageRows(filteredMortgages)
        dispatch( setFilteredMortgages(filteredMortgages) )
      }
    }
    if (filteredMortgages.length > 0) {
      if (!customTagViewAdded) {
        dispatch( addCustomTagView({
          tagType: 'Custom',
          tagTypeAbbr: 'Custom',
          customTagView: true,
          totalMortgages: filteredMortgages.length,
          mortgages: null,
        }) )
      } else {
        dispatch( editCustomTagView({
          newTotal: filteredMortgages.length,
        }))
      }
    } else if (customTagViewAdded && newSelectedDetails.length === 0 && displaySelection === 'Chart View') {
      setMortgageRows(teamMortgages)
      dispatch( setMortgagesFilteredBoolean(false) )
      dispatch( setFilteredMortgages(null) )
      dispatch( removeCustomTagView())
    } else if (customTagViewAdded) {
      dispatch( editCustomTagView({
        newTotal: 0,
      }))
    }
  }

  const [displaySelection, setDisplaySelection] = useState('Table View')
  const handleDisplayChange = (event) => {
    setDisplaySelection(event.target.value)
  }

  useEffect(() => {
    setMortgageRows(teamMortgages)
    if (viewingMortgageActions) {
      dispatch( setShowMortgageActionsModal(true) )
    }
  }, [teamMortgages, viewingMortgageActions, dispatch])

  const renderAssignmentLabel = (cellValues) => {
    return (
      <Tooltip
        componentsProps={{tooltip: {sx: {
          color: `${theme.palette.common.white}`,
          backgroundColor: `${theme.palette.common.black}`,
          fontSize: "2rem",
          margin: '3rem 0 0 0'
        }}}}
        PopperProps={{modifiers: [{
          name: "offset",
          options: {
            offset: [0, 0],
          },
        }]}}
        title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Open Actions Window</p></div>} 
        placement="top"
        arrow
      >
        {cellValues.row.newAssignmentNotification ?
          <NewLabel className="new hover" onClick={() => handleOpenActionModal(cellValues.row)} style={{padding: "0.2rem 0.5rem 0.2rem 0.5rem"}}>
            New Assignment
          </NewLabel>
        : cellValues.row.userAssignment ?
          <NewLabel className="new hover" onClick={() => handleOpenActionModal(cellValues.row)} style={{padding: "0.2rem 0.5rem 0.2rem 0.5rem"}}>
            Assigned
          </NewLabel>
        : cellValues.row.notifCount && cellValues.row.notifCount !== 0 ?
          <NewLabel className="notification hover" onClick={() => handleOpenActionModal(cellValues.row)} style={{padding: "0.2rem 0.5rem 0.2rem 0.5rem"}}>
            Updates
          </NewLabel>
        :
          <></>
        }
      </Tooltip>
    )
  }
  const renderActionButtons = (cellValues) => {
    return (
      <FlexWrapper className="alignCenter justifyCenter" style={{marginLeft: '0.6rem'}}>
        <ActionIconWrapper onClick={() => handleOpenActionModal(cellValues.row)} className='hover' >
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Open Actions Window</p></div>} 
            placement="top"
            arrow
          >
            <FlexWrapper>
              {(cellValues.row.notifCount && cellValues.row.notifCount !== 0) ?
                <>
                  <NotificationsIcon style={{color: `${theme.notification.color.active}`, position: 'relative', right: '-0.8rem'}} />
                  <Badge className="repository">
                    {cellValues.row.notifCount}
                  </Badge>
                </>
              : cellValues.row.newAssignmentNotification ?
                <NotificationsIcon style={{color: `${theme.notification.color.inactive}`}} />
              :
                <NotificationsNoneIcon style={{color: `${theme.notification.color.outlined}`}} />
              }
              <OpenInNewIcon style={{color: `${theme.palette.primary.dark}`, height: '1.6rem', width: '1.6rem', marginLeft: '0.6rem', position: 'relative', left: (cellValues.row.notifCount && cellValues.row.notifCount !== 0) ? '-0.8rem' : ''}} />
            </FlexWrapper>
          </Tooltip>
        </ActionIconWrapper>
      </FlexWrapper>
    )
  }
  const renderStatusButton = (cellValues) => {
    return (
      <FlexWrapper className="alignCenter justifyCenter" style={{marginLeft: '1rem'}}>
        {cellValues.row.status === 'inactive' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={
              <div style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>
                  <span style={{fontWeight: '900', fontSize: '1.2rem'}}>
                    Inactive
                  </span>
                </p>
              </div>
            } 
            placement="top"
            arrow
          >
            <ActionIconWrapper onClick={() => handleOpenActionModal(cellValues.row, 'investigateLead')} className="hover">
              <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorderDark}`}} />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingAction' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={
              <div style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>
                  currently 
                  <span style={{fontWeight: '900', fontSize: '1.2rem', marginLeft: '0.3rem'}}>
                    Awaiting Action
                  </span>
                </p>
              </div>
            } 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover successDark">
              <RadioButtonUncheckedIcon style={{color: 'inherit'}} />
              <ArrowForwardIcon style={{color: `${theme.palette.secondary.main}`, height: '1.2rem', width: '1.2rem'}} />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'investigating' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={
              <div style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>
                  currently 
                  <span style={{fontWeight: '900', fontSize: '1.2rem', marginLeft: '0.3rem'}}>
                    Under Investigation
                  </span>
                </p>
              </div>
            } 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover successDark">
              <RadioButtonCheckedIcon style={{color: 'inherit'}} />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingUpdate' && cellValues.row.publicRecordsUpdated ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={
              <div style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>
                  Public Records were updated.<br/>
                  Please review new arrangements.
                </p>
              </div>
            } 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover successDark">
              <CheckCircleOutlineIcon style={{color: 'inherit'}} />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingUpdate' && cellValues.row.awaitingUpdates ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={
              <div style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>
                  Awaiting Public Records to reflect<br/>
                  expected arrangements.
                </p>
              </div>
            } 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover successDark">
              <PendingIcon style={{color: 'inherit'}} />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingUpdate' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={
              <div style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>
                  Awaiting confirmation tnat Public Records reflect<br/>
                  the expected arrangements.
                </p>
              </div>
            } 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover successDark">
              <TaskAltIcon style={{color: 'inherit'}} />
            </ActionIconWrapper>
          </Tooltip>
        :
           <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={
              <div style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>
                  currently 
                  <span style={{fontWeight: '900', fontSize: '1.2rem', marginLeft: '0.3rem'}}>
                    Closing the Investigation
                  </span>
                </p>
              </div>
            } 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover successDark">
              <CircleIcon style={{color: 'inherit'}} />
            </ActionIconWrapper>
          </Tooltip>
        }
      </FlexWrapper>
    )
  }

  const columns = [
    {
      field: 'id' , 
      headerName: '', 
      renderCell: renderAssignmentLabel,
      disableClickEventBubbling: true,
      width: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 50,
      renderCell: renderActionButtons,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 50,
      renderCell: renderStatusButton,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
    },
    {field: "recordSweeps", headerName: "Scans", align: 'center', width: 50, headerAlign: 'center'},
    {field: "propertyType", headerName: "Property Type", width: 90},
    {field: "streetAddress", headerName: "Street Address", width: 175},
    {field: "city", headerName: "City", width: 110},
    {field: "originationDateLabel", headerName: "Origination Date", width: 90},
    {field: "endDateLabel", headerName: "End Date", width: 90},
    {field: "monthsRemaining", headerName: "Months Remaining", align: 'center', width: 100},
    {field: "owner1", headerName: "Primary Owner", width: 140},
    {field: "owner2", headerName: "Co-Owner", width: 140},
  ]

  return (
    <>
      <FlexWrapper style={{padding: '1rem'}}>
        <ComponentContainer className="fitWindow">
          <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
            <RepositoryControls matchTypeSelection={matchTypeSelection} tagTypesForDropdown={tagTypesForDropdown} handleSelectedTableDetails={handleSelectedTableDetails} customTagCombination={customTagCombination} tagsViewMortgages={tagsViewMortgages} handleUserChange={handleUserChange} selectedTableDetail={selectedTableDetail} userFilter={userFilter} displaySelection={displaySelection} handleDisplayChange={handleDisplayChange} handleMatchTypeSelection={handleMatchTypeSelection} handleOpenUploadModal={handleOpenUploadModal} />
            {mortgageRows.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter fitContentHeight" style={{gridColumn: '1/3', gridRow: '1/3', margin: 'auto 0'}}>
                <DomainDisabledIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>
                  {teamMortgages.length === 0 ? `no mortgages have been uploaded` : `no mortgages match the selected criteria`}
                </HeaderText>
              </FlexWrapper>
            : mortgageRows !== undefined && displaySelection === 'Table View' ?
              <StripedDataGrid
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'primary.main',
                  },
                  '& .MuiTablePagination-select': {
                    color: 'primary.main',
                  },
                  '& .MuiDataGrid-virtualScrollerContent': {
                    overflow: 'scroll'
                  },
                  '& .MuiDataGrid-row': {
                    background: `#fff`
                  },
                  '&.MuiDataGrid-root': {
                    background: `#fff`
                  },
                  '&.MuiDataGrid-columnHeaderRow': {
                    background: `#fff`
                  },
                }}
                style={{width: '100%', height: '100%', minHeight: '20rem', borderRadius: '0', background: `${theme.component.background.secondary}`}}
                rows={mortgageRows}
                rowHeight={40}
                columns={columns}
                getRowId={(row) =>  row._id}
                components={{
                  Toolbar: GridToolbar,
                }}
                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => dispatch( setRepositoryPageSize(newPageSize))}
                rowsPerPageOptions={[20, 40, 80]}
                page={currentPageSize}
                onPageChange={(newPage) => dispatch( setRepositoryPage(newPage) )}
                pagination
              />
            : displaySelection === 'Chart View' ?
                <TagsView displaySelection={displaySelection} tagsViewMortgages={tagsViewMortgages} />
            :
              <FlexWrapper className="justifyCenter alignCenter" style={{height: '50%', margin: 'auto'}}>
                <CircularProgress
                  sx={{ color: `${theme.palette.primary.main}`}}
                  size={48}
                />
              </FlexWrapper>
            }
            </FlexWrapper>
            <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
            <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
            <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
            <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
          </ComponentContainer>
        </FlexWrapper>

        <Dialog open={showMortgageActionsModal} onClose={handleCloseModal} 
          PaperProps={{ sx: {width: "95%", minWidth: "100rem", maxWidth: "150rem", height: "fit-content", minHeight: "66rem", maxHeight: "95%"}}}
          tabIndex={-1}
        >
          <MortgageActionsModal handleCloseModal={handleCloseModal} userId={userId} teamId={teamId} userFullName={userFullName} dashPerspective={dashPerspective} />
        </Dialog>
        <Dialog open={showUploadModal} onClose={handleCloseUploadModal}
          PaperProps={{ sx: {width: "130rem", minWidth: "130rem", maxWidth: "130rem", height: "63rem", minHeight: "63rem", maxHeight: "63rem"}}}
          tabIndex={-1}
        >
        <UploadModal handleCloseModal={handleCloseUploadModal} userFullName={userFullName} teamId={teamId} />
      </Dialog>
    </>
  )
}

export default MortgageRepository;