import React, { useEffect, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { selectUserFullName } from "../../slices/userInfoSlice";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { setMortgageFromSweep } from "../../slices/viewingMortgageSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectRunRecordSweepPerm } from "../../slices/userActionsPermissionsSlice";
import { addMemberUpdatedLeadsFromSweep } from "../../slices/memberAssignmentsSlice";
import { addMortgagesFromSweep, selectTeamMortgages } from "../../slices/teamMortgagesSlice";
import { addNewLeadsFromSweep, addUpdatedLeadsFromSweep } from "../../slices/teamActiveLeadsSlice";
import { useRecordQueryPerformanceMutation, useRecordSweepQueryMutation } from "../../slices/api/apiSlice";
import { AlertWrapper, Button, ButtonWrapper, ErrorWrapper, FlexWrapper, GridWrapper, LoadingBar, LoadingBarWrapper, Typography } from "../../StyledComponents";
import { selectExistingInitialSweeps, selectRejectionsAttomAndCoreLogicDataExpected, selectRejectionsAttomAndCoreLogicDataNew, selectRejectionsAttomDataExpected, selectRejectionsAttomDataNew, selectRejectionsCoreLogicDataExpected, selectRejectionsCoreLogicDataNew, selectRunningSweep, setExistingInitialSweeps, setRejectionsAttomAndCoreLogicDataExpected, setRejectionsAttomAndCoreLogicDataNew, setRejectionsAttomDataExpected, setRejectionsAttomDataNew, setRejectionsCoreLogicDataExpected, setRejectionsCoreLogicDataNew, setRunningSweep } from "../../slices/sessionDataSlice";
import { selectTeamRemainingMonthlyQueries } from "../../slices/teamStatsSlice";

const SweepLoadingBar = ({ teamId, userId, mortgageId, sweepTypeSelection, sweepSizeSelection, existingScansSelection, createLeadSelection, recordMonthlyStatsSelection, recordQueryPerformanceSelection, notifyAssigneesSelection, recordTeamStatsSelection, updateLastQuerySelection, initialMatchSelection }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [isError, setIsError] = useState(false)
  const [workflowStage, setWorkflowStage] = useState('ready')
  const [sweepComplete, setSweepComplete] = useState(false)
  const [awaitingRecord, setAwaitingRecord] = useState(false)
  const [noUnMatchedMortgages, setNoUnMatchedMortgages] = useState(false)
  const [numMortgagesToSweep, setNumMortgagesToSweep] = useState(null)
  const [dontShowRejectedNew, setDontShowRejectedNew] = useState(false)
  const [noMatchingExistingScans, setNoMatchingExistingScans] = useState(false)
  const [mortgagesStagedForSweep, setMortgagesStagedForSweep] = useState(0)
  const [monthlyQueriesExhausted, setMonthlyQueriesExhausted] = useState(false)
  const [dontShowRejectedExpected, setDontShowRejectedExpected] = useState(false)

  const userFullName = useSelector(selectUserFullName)
  const runningSweep = useSelector(selectRunningSweep)
  const teamMortgages = useSelector(selectTeamMortgages)
  const runRecordSweepPerm = useSelector(selectRunRecordSweepPerm)
  const existingInitialSweeps = useSelector(selectExistingInitialSweeps)
  const rejectionsAttomDataNew = useSelector(selectRejectionsAttomDataNew)
  const remainingMonthlyQueries = useSelector(selectTeamRemainingMonthlyQueries)
  const rejectionsCoreLogicDataNew = useSelector(selectRejectionsCoreLogicDataNew)
  const rejectionsAttomDataExpected = useSelector(selectRejectionsAttomDataExpected)
  const rejectionsCoreLogicDataExpected = useSelector(selectRejectionsCoreLogicDataExpected)
  const rejectionsAttomAndCoreLogicDataNew = useSelector(selectRejectionsAttomAndCoreLogicDataNew)
  const rejectionsAttomAndCoreLogicDataExpected = useSelector(selectRejectionsAttomAndCoreLogicDataExpected)
  
  const [recordSweepQuery] = useRecordSweepQueryMutation()
  const [recordQueryPerformance] = useRecordQueryPerformanceMutation()

  const handleClick = async (stage) => {
    setIsError(null)
    setDontShowRejectedNew(false)
    setDontShowRejectedExpected(false)
    if (stage === 'initiate') {
      setWorkflowStage('initiate')
    } else if (stage === 'submit' || stage === 'submitRejectionsExpected' || stage === 'submitRejectionsNew') {
      setNumMortgagesToSweep(0)
      setMortgagesStagedForSweep(null)
      let mortgageArrayToSweep = []
      let skipMissingVendorRejections = false
      let skipNewVendorRejections = false
      if (stage === 'submitRejectionsExpected') {
        skipMissingVendorRejections = true
        mortgageArrayToSweep.push(...rejectionsAttomDataExpected)
        mortgageArrayToSweep.push(...rejectionsCoreLogicDataExpected)
        mortgageArrayToSweep.push(...rejectionsAttomAndCoreLogicDataExpected)
      } else if (stage === 'submitRejectionsNew') {
        skipNewVendorRejections = true
        mortgageArrayToSweep.push(...rejectionsAttomDataNew)
        mortgageArrayToSweep.push(...rejectionsCoreLogicDataNew)
        mortgageArrayToSweep.push(...rejectionsAttomAndCoreLogicDataNew)
      } else {
        mortgageArrayToSweep = teamMortgages
      }
      let newLeads = []
      let newLeadIds = []
      let newLeadObjs = []
      let updatedLeads = []
      let newMortgages = []
      let totalQueried = 0
      let totalUpdated = 0
      let totalNewLeads = 0
      let newTier1Count = 0
      let newTier2Count = 0
      let attomNotFound = []
      let improperQueries = []
      let propMixNotFound = []
      let formattingErrors = []
      let completeNotFound = []
      let brokenConnections = []
      let updatedTier2Count = 0
      let updatedTier1Count = 0
      let upgradedTier2Count = 0
      let totalInactiveLeads = 0
      let totalDiscrepancies = 0
      let totalNewTier1Leads = 0
      let totalNewTier2Leads = 0
      let totalFailedQueries = 0
      let attomMissingRecords = []
      let propMixMissingRecords = []
      let completeMissingRecords = []
      let newLeadsAwaitingUpdate = 0
      let totalSuccessfulQueries = 0
      let attomSuccessfulQueries = 0
      let leadsWithUpgradedTiers = 0
      let totalTier1Discrepancies = 0
      let totalTier2Discrepancies = 0
      let totalTier3Discrepancies = 0
      let monthlyQueriesExhausted = false
      let newExistingInitialSweeps = 0
      let propMixSuccessfulQueries = 0
      let gatherRejectionsAttomNew = []
      let clCurrentMortgageNotFound = []
      let newTeamLeadsAwaitingAction = []
      let newLeadsAwaitingVerification = 0
      let gatherRejectionsCoreLogicNew = []
      let gatherRejectionsAttomExpected = []
      let clCurrentMortgageMissingRecords = []
      let gatherRejectionsCoreLogicExpected = []
      let clCurrentMortgageSuccessfulQueries = 0
      let newlyGeneratedCoreLogicAccessToken = ''
      let gatherRejectionsAttomAndCoreLogicNew = []
      let gatherRejectionsAttomAndCoreLogicData = []
      let newRemainingMonthlyQueries = remainingMonthlyQueries
      dispatch( setRunningSweep(true) )
      setWorkflowStage(null)
      let sendQuerySettings = {
        sweepType: sweepTypeSelection,
        sweepSize: sweepSizeSelection,
        existingScans: existingScansSelection,
        createLead: createLeadSelection,
        recordNewMonthlyStats: recordMonthlyStatsSelection,
        recordPerformance: recordQueryPerformanceSelection,
        notifyAssignees: notifyAssigneesSelection,
        updateTeamStats: recordTeamStatsSelection,
        updateLastQuery: updateLastQuerySelection,
        initialMatch: initialMatchSelection,
        skipMissingVendorRejections: skipMissingVendorRejections,
        skipNewVendorRejections: skipNewVendorRejections,
      }
      if (mortgageId) {
        let requestObj = {
          teamId: teamId,
          userId: userId,
          userFullName: userFullName,
          mortgageId: mortgageId,
          querySettings: sendQuerySettings,
        }
        await recordSweepQuery(requestObj)
      } else {
        let mortgagesToSweep = 0
        if (sweepSizeSelection === 'All') {
          mortgagesToSweep = mortgageArrayToSweep.length
          setNumMortgagesToSweep(mortgageArrayToSweep.length)
        } else {
          mortgagesToSweep = parseInt(sweepSizeSelection)
          setNumMortgagesToSweep(parseInt(sweepSizeSelection))
        }
        let mortgagesSwept = 0
        for (let i = 0; i < mortgageArrayToSweep.length; i++) {
          newRemainingMonthlyQueries--
          if (newRemainingMonthlyQueries >= 0) {
            let previousScanSkip = false
            if (existingScansSelection === '0') {
              if (initialMatchSelection === 'true') {
                if (mortgageArrayToSweep[i].recordSweeps > 0) {
                  previousScanSkip = true
                  newExistingInitialSweeps++
                }
                if ((i === (mortgageArrayToSweep.length - 1)) && (mortgagesSwept === 0) && (i > 1)) {
                  setNoUnMatchedMortgages(true)
                }
              } else {
                if (mortgageArrayToSweep[i].recordSweeps > 0) {
                  previousScanSkip = true
                  if ((i === (mortgageArrayToSweep.length - 1)) && (mortgagesSwept === 0)) {
                    setNoMatchingExistingScans(true)
                  }
                }
              }
            } else if (existingScansSelection === '1') {
              if (mortgageArrayToSweep[i].recordSweeps !== 1) {
                previousScanSkip = true
                if ((i === (mortgageArrayToSweep.length - 1)) && (mortgagesSwept === 0)) {
                  setNoMatchingExistingScans(true)
                }
              }
            } else if (existingScansSelection === '2') {
              if (mortgageArrayToSweep[i].recordSweeps !== 2) {
                previousScanSkip = true
                if ((i === (mortgageArrayToSweep.length - 1)) && (mortgagesSwept === 0)) {
                  setNoMatchingExistingScans(true)
                }
              }
            } else if (existingScansSelection === '3') {
              if (mortgageArrayToSweep[i].recordSweeps !== 3) {
                previousScanSkip = true
                if ((i === (mortgageArrayToSweep.length - 1)) && (mortgagesSwept === 0)) {
                  setNoMatchingExistingScans(true)
                }
              }
            }
            if (!previousScanSkip && (mortgagesSwept < mortgagesToSweep)) {
              let mortgagetoSweepId = ''
              if (stage === 'submitRejectionsExpected' || stage === 'submitRejectionsNew') {
                mortgagetoSweepId = mortgageArrayToSweep[i]
              } else {
                mortgagetoSweepId = mortgageArrayToSweep[i]._id
              }
              let requestObj = {
                teamId: teamId,
                userId: userId,
                userFullName: userFullName,
                mortgageId: mortgagetoSweepId,
                querySettings: sendQuerySettings,
                newlyGeneratedCoreLogicAccessToken: newlyGeneratedCoreLogicAccessToken,
                isSingleScan: false,
              }
              let sweep = await recordSweepQuery(requestObj)
              mortgagesSwept = mortgagesSwept + 1
              setMortgagesStagedForSweep(mortgagesSwept)
              if (sweep.error) {
                setIsError('sweep')
              } else {
                if (sweep.data.data.newlyGeneratedCoreLogicAccessToken) {
                  newlyGeneratedCoreLogicAccessToken = sweep.data.data.newlyGeneratedCoreLogicAccessToken
                }
                if (sweep.data.data.newLead._id) {
                  newLeads.push(sweep.data.data.newLead)
                  newLeadIds.push(sweep.data.data.newLead._id)
                }
                if (sweep.data.data.updatedLead._id) {
                  updatedLeads.push(sweep.data.data.updatedLead)
                }
                if (sweep.data.data.newLeadObjforNotification) {
                  newLeadObjs.push(sweep.data.data.newLeadObjforNotification)
                }
                if (sweep.data.data.newMortgage._id) {
                  newMortgages.push(sweep.data.data.newMortgage)
                }
                if (sweep.data.data.attomNotFound.Vendor) {
                  attomNotFound.push(sweep.data.data.attomNotFound)
                }
                if (sweep.data.data.propMixNotFound.Vendor) {
                  propMixNotFound.push(sweep.data.data.propMixNotFound)
                }
                if (sweep.data.data.attomMissingRecords.providers) {
                  attomMissingRecords.push(sweep.data.data.attomMissingRecords)
                }
                if (sweep.data.data.propMixMissingRecords.providers) {
                  propMixMissingRecords.push(sweep.data.data.propMixMissingRecords)
                }
                if (sweep.data.data.completeMissingRecords.providers) {
                  completeMissingRecords.push(sweep.data.data.completeMissingRecords)
                }
                if (sweep.data.data.clCurrentMortgageNotFound.Vendor) {
                  clCurrentMortgageNotFound.push(sweep.data.data.clCurrentMortgageNotFound)
                }
                if (sweep.data.data.clCurrentMortgageMissingRecords.providers) {
                  clCurrentMortgageMissingRecords.push(sweep.data.data.clCurrentMortgageMissingRecords)
                }
                if (sweep.data.data.newTeamLeadsAwaitingAction.length > 0) {
                  newTeamLeadsAwaitingAction.push(sweep.data.data.newTeamLeadsAwaitingAction)
                }
                if (sweep.data.data.improperQueries.length > 0) {
                  improperQueries.push(sweep.data.data.improperQueries)
                }
                if (sweep.data.data.formattingErrors.length > 0) {
                  formattingErrors.push(sweep.data.data.formattingError)
                }
                if (sweep.data.data.completeNotFound.length > 0) {
                  completeNotFound.push(sweep.data.data.completeNotFound)
                }
                if (sweep.data.data.brokenConnections.length > 0) {
                  brokenConnections.push(sweep.data.data.brokenConnections)
                }
                if (sweep.data.data.totalUpdated) {
                  totalUpdated = totalUpdated + 1
                }
                if (sweep.data.data.totalNewLeads) {
                  totalNewLeads = totalNewLeads + 1
                }
                if (sweep.data.data.newTier1Count) {
                  newTier1Count = newTier1Count + 1
                }
                if (sweep.data.data.newTier2Count) {
                  newTier2Count = newTier2Count + 1
                }
                if (sweep.data.data.updatedTier2Count) {
                  updatedTier2Count = updatedTier2Count + 1
                }
                if (sweep.data.data.updatedTier1Count) {
                  updatedTier1Count = updatedTier1Count + 1
                }
                if (sweep.data.data.upgradedTier2Count) {
                  upgradedTier2Count = upgradedTier2Count + 1
                }
                if (sweep.data.data.totalInactiveLeads) {
                  totalInactiveLeads = totalInactiveLeads + 1
                }
                if (sweep.data.data.totalNewTier1Leads) {
                  totalNewTier1Leads = totalNewTier1Leads + 1
                }
                if (sweep.data.data.totalNewTier2Leads) {
                  totalNewTier2Leads = totalNewTier2Leads + 1
                }
                if (sweep.data.data.totalFailedQueries) {
                  totalFailedQueries = totalFailedQueries + 1
                }
                if (sweep.data.data.removeLeadAwaitingUpdate) {
                  newLeadsAwaitingUpdate = newLeadsAwaitingUpdate - 1
                }
                if (sweep.data.data.totalSuccessfulQueries) {
                  totalSuccessfulQueries = totalSuccessfulQueries + 1
                }
                if (sweep.data.data.attomSuccessfulQueries) {
                  attomSuccessfulQueries = attomSuccessfulQueries + 1
                }
                if (sweep.data.data.leadsWithUpgradedTiers) {
                  leadsWithUpgradedTiers = leadsWithUpgradedTiers + 1
                }
                if (sweep.data.data.propMixSuccessfulQueries) {
                  propMixSuccessfulQueries = propMixSuccessfulQueries + 1
                }
                if (sweep.data.data.newLeadsAwaitingVerification) {
                  newLeadsAwaitingVerification = newLeadsAwaitingVerification + 1
                }
                if (sweep.data.data.clCurrentMortgageSuccessfulQueries) {
                  clCurrentMortgageSuccessfulQueries = clCurrentMortgageSuccessfulQueries + 1
                }
                if (sweep.data.data.rejectionAttomDataExpected) {
                  gatherRejectionsAttomExpected.push(sweep.data.data.mortgageId)
                  setDontShowRejectedExpected(false)
                }
                if (sweep.data.data.rejectionCoreLogicDataExpected) {
                  gatherRejectionsCoreLogicExpected.push(sweep.data.data.mortgageId)
                  setDontShowRejectedExpected(false)
                }
                if (sweep.data.data.rejectionAttomAndCoreLogicExpected) {
                  gatherRejectionsAttomAndCoreLogicData.push(sweep.data.data.mortgageId)
                  setDontShowRejectedExpected(false)
                }
                if (sweep.data.data.rejectionAttomNew) {
                  gatherRejectionsAttomNew.push(sweep.data.data.mortgageId)
                  setDontShowRejectedNew(false)
                }
                if (sweep.data.data.rejectionCoreLogicNew) {
                  gatherRejectionsCoreLogicNew.push(sweep.data.data.mortgageId)
                  setDontShowRejectedNew(false)
                }
                if (sweep.data.data.rejectionAttomAndCoreLogicNew) {
                  gatherRejectionsAttomAndCoreLogicNew.push(sweep.data.data.mortgageId)
                  setDontShowRejectedNew(false)
                }
                if (sweep.data.data.totalQueried) {
                  totalQueried = totalQueried + 1
                }
                totalDiscrepancies = totalDiscrepancies + sweep.data.data.totalDiscrepancies
                totalTier1Discrepancies = totalTier1Discrepancies + sweep.data.data.totalTier1Discrepancies
                totalTier2Discrepancies = totalTier2Discrepancies + sweep.data.data.totalTier2Discrepancies
                totalTier3Discrepancies = totalTier3Discrepancies + sweep.data.data.totalTier3Discrepancies
              }
              if (mortgagesSwept === mortgagesToSweep) {
                break
              }
            }
          } else {
            setNumMortgagesToSweep(mortgagesSwept)
            setMortgagesStagedForSweep(mortgagesSwept)
            monthlyQueriesExhausted = true
            break
          }
        } //* end loop
        if (newRemainingMonthlyQueries <= 0) {
          monthlyQueriesExhausted = true
        }
        setMonthlyQueriesExhausted(monthlyQueriesExhausted)
        setSweepComplete(true)
        if (mortgagesSwept > 0) {
          if (initialMatchSelection === 'true') {
            newExistingInitialSweeps = newExistingInitialSweeps + mortgagesSwept
          }
          dispatch( addNewLeadsFromSweep(newLeads) )
          dispatch( addMortgagesFromSweep(newMortgages) )
          dispatch( setExistingInitialSweeps(newExistingInitialSweeps) )
          dispatch( addUpdatedLeadsFromSweep(updatedLeads) )
          dispatch( addMemberUpdatedLeadsFromSweep(updatedLeads) )
          dispatch( setRejectionsAttomDataExpected(gatherRejectionsAttomExpected) )
          dispatch( setRejectionsCoreLogicDataExpected(gatherRejectionsCoreLogicExpected) )
          dispatch( setRejectionsAttomAndCoreLogicDataExpected(gatherRejectionsAttomAndCoreLogicData) )
          dispatch( setRejectionsAttomDataNew(gatherRejectionsAttomNew) )
          dispatch( setRejectionsCoreLogicDataNew(gatherRejectionsCoreLogicNew) )
          dispatch( setRejectionsAttomAndCoreLogicDataNew(gatherRejectionsAttomAndCoreLogicNew) )
          if (mortgageId) {
            dispatch( setMortgageFromSweep(newMortgages[0]) )
          }
          setAwaitingRecord(true)
          let recordSweepResults = await recordQueryPerformance({
            sweepResults: {
              userId: userId,
              newLeadIds: newLeadIds,
              newLeadObjs: newLeadObjs,
              updatedLeads: updatedLeads,
              newMortgages: newMortgages,
              totalQueried: totalQueried,
              totalUpdated: totalUpdated,
              totalNewLeads: totalNewLeads,
              newTier1Count: newTier1Count,
              newTier2Count: newTier2Count,
              attomNotFound: attomNotFound,
              improperQueries: improperQueries,
              propMixNotFound: propMixNotFound,
              formattingErrors: formattingErrors,
              completeNotFound: completeNotFound,
              brokenConnections: brokenConnections,
              updatedTier2Count: updatedTier2Count,
              updatedTier1Count: updatedTier1Count,
              upgradedTier2Count: upgradedTier2Count,
              totalInactiveLeads: totalInactiveLeads,
              totalDiscrepancies: totalDiscrepancies,
              totalNewTier1Leads: totalNewTier1Leads,
              totalNewTier2Leads: totalNewTier2Leads,
              totalFailedQueries: totalFailedQueries,
              attomMissingRecords: attomMissingRecords,
              propMixMissingRecords: propMixMissingRecords,
              completeMissingRecords: completeMissingRecords,
              newLeadsAwaitingUpdate: newLeadsAwaitingUpdate,
              totalSuccessfulQueries: totalSuccessfulQueries,
              attomSuccessfulQueries: attomSuccessfulQueries,
              leadsWithUpgradedTiers: leadsWithUpgradedTiers,
              monthlyQueriesExhausted: monthlyQueriesExhausted,
              totalTier1Discrepancies: totalTier1Discrepancies,
              totalTier2Discrepancies: totalTier2Discrepancies,
              totalTier3Discrepancies: totalTier3Discrepancies,
              propMixSuccessfulQueries: propMixSuccessfulQueries,
              clCurrentMortgageNotFound: clCurrentMortgageNotFound,
              newTeamLeadsAwaitingAction: newTeamLeadsAwaitingAction,
              newLeadsAwaitingVerification: newLeadsAwaitingVerification,
              clCurrentMortgageMissingRecords: clCurrentMortgageMissingRecords,
              clCurrentMortgageSuccessfulQueries: clCurrentMortgageSuccessfulQueries,
            },
            sweepSizeSelection: sweepSizeSelection,
            querySettings: sendQuerySettings,
            teamId: teamId,
            userId: userId,
            userFullName: userFullName,
          })
          if (!recordSweepResults.error) {
            dispatch( addMemberUpdatedLeadsFromSweep({leads: updatedLeads, identifiedUpdatesByMember: recordSweepResults.data.data.identifiedUpdatesByMember}) )
          } else {
            setIsError('recording')
          }
          setAwaitingRecord(false)
        }
      }
      // setNumMortgagesToSweep(0)
      // setMortgagesStagedForSweep(null)
      dispatch( setRunningSweep(false) )
    } else if (stage === 'cancel') {
      setWorkflowStage('ready')
      setNoUnMatchedMortgages(false)
      setNoMatchingExistingScans(false)
    } else if (stage === 'noRejectionsExpected') {
      setDontShowRejectedExpected(true)
      dispatch( setRejectionsAttomDataExpected([]) )
      dispatch( setRejectionsCoreLogicDataExpected([]) )
      dispatch( setRejectionsAttomAndCoreLogicDataExpected([]) )
    } else if (stage === 'noRejectionsNew') {
      setDontShowRejectedNew(true)
      dispatch( setRejectionsAttomDataNew([]) )
      dispatch( setRejectionsCoreLogicDataNew([]) )
      dispatch( setRejectionsAttomAndCoreLogicDataNew([]) )
    }
  }

  return (
    <GridWrapper style={{gridTemplateColumns: '30rem 1fr 30rem'}}>
      {monthlyQueriesExhausted &&
        <FlexWrapper className="justifyCenter alignCenter" style={{margin: '0 auto'}}>
          <WarningAmberIcon style={{width: '1.8rem', height: '1.8rem', color: `${theme.palette.common.orangeDark}`}} />
          <Typography className="fitContentHeight alignCenter medium" style={{margin: '0 0 0 0.3rem', width: '24rem', color: `${theme.palette.common.orangeDark}`}}>
            Monthly queries were exhausted...
          </Typography>
        </FlexWrapper>
      }
      {(runningSweep || sweepComplete) &&
        <GridWrapper style={{gridTemplateColumns: '10rem 1fr 15rem', gridColumn: '2', height: '7rem'}}>
          <FlexWrapper className="justifyEnd alignCenter" style={{paddingRight: '1rem'}}>
            <Typography className="xxxLarge">{numMortgagesToSweep && mortgagesStagedForSweep}</Typography>
          </FlexWrapper>
          <FlexWrapper className="alignCenter">
            <LoadingBarWrapper>
              <LoadingBar style={{width: numMortgagesToSweep && mortgagesStagedForSweep !== 0 ? `${Math.round(((mortgagesStagedForSweep)/numMortgagesToSweep)*100)}%` : numMortgagesToSweep ? '0%' : '100%'}} />
            </LoadingBarWrapper>
          </FlexWrapper>
          <FlexWrapper className="justifyStart alignCenter" style={{paddingLeft: '1rem'}}>
              <Typography className="xxxLarge">{numMortgagesToSweep}</Typography>
              <Typography className="xLarge">
                {numMortgagesToSweep && mortgagesStagedForSweep !== 0 ?
                  '(' + Math.round(((mortgagesStagedForSweep)/numMortgagesToSweep)*100) + '%)'
                : numMortgagesToSweep ?
                  '(0%)'
                :
                  '100%'
                }
              </Typography>
          </FlexWrapper>
        </GridWrapper>
      }
      {noMatchingExistingScans || noUnMatchedMortgages ?
        <FlexWrapper className="justifyCenter alignCenter" style={{height: '7rem', gridColumn: '2'}}>
          <ButtonWrapper className="justifyCenter" style={{height:'4rem', margin: '0', gridColumn: '2'}}>
            <Button className="secondary prompt" onClick={() => handleClick('cancel')} style={{margin: 'auto 0.5rem'}}>
              <ClearIcon />
            </Button>
          </ButtonWrapper>
          <ErrorWrapper className="caution" style={{marginLeft: '1.5rem'}}>
            <WarningAmberIcon style={{width: '1.2rem', height: '1.2rem'}} />
            {noMatchingExistingScans ?
              `No mortgages with ${existingScansSelection} existing scans`
            :
              `No unmatched mortgages`
            }
          </ErrorWrapper>
        </FlexWrapper>
      : (rejectionsAttomDataExpected.length > 0 || rejectionsCoreLogicDataExpected.length > 0 || rejectionsAttomAndCoreLogicDataExpected.length > 0) && !runningSweep && !dontShowRejectedExpected ?
        <FlexWrapper className="column alignCenter justifyCenter" style={{height: '7rem', gridColumn: '2'}}>
          <FlexWrapper className="justifyCenter alignCenter">
            <ButtonWrapper className="justifyCenter" style={{height:'4rem', margin: '0', gridColumn: '2'}}>
              <Button className={runRecordSweepPerm ? "caution" : "cautionDisabled"}  onClick={() => handleClick('submitRejectionsExpected')} style={{margin: 'auto 0.5rem', width: '18rem'}}>
                Ignore Rejections
              </Button>
              <Button className="secondary prompt" onClick={() => handleClick('noRejectionsExpected')} style={{margin: 'auto 0.5rem'}}>
                <ClearIcon />
              </Button>
            </ButtonWrapper>
            {rejectionsAttomDataExpected.length > 0 ?
              <ErrorWrapper className="caution" style={{marginLeft: '1.5rem'}}>
                <WarningAmberIcon style={{width: '1.2rem', height: '1.2rem'}} />
                {rejectionsAttomDataExpected.length} Attom rejection(s)
              </ErrorWrapper>
            :
              <></>
            }
            {rejectionsCoreLogicDataExpected.length > 0 ?
              <ErrorWrapper className="caution" style={{marginLeft: '1.5rem'}}>
                <WarningAmberIcon style={{width: '1.2rem', height: '1.2rem'}} />
                {rejectionsCoreLogicDataExpected.length} CoreLogic rejection(s)
              </ErrorWrapper>
            :
              <></>
            }
            {rejectionsAttomAndCoreLogicDataExpected.length > 0 ?
              <ErrorWrapper className="caution" style={{marginLeft: '1.5rem'}}>
                <WarningAmberIcon style={{width: '1.2rem', height: '1.2rem'}} />
                {rejectionsAttomAndCoreLogicDataExpected.length} Attom and CoreLogic rejection(s)
              </ErrorWrapper>
            :
              <></>
            }
          </FlexWrapper>
          <FlexWrapper className="justifyCenter alignCenter">
            <WarningAmberIcon style={{width: '1.8rem', height: '1.8rem', color: `${theme.icon.primary.primary}`}} />
            <Typography className="fitContentHeight alignCenter" style={{margin: '0.2rem 0 0 0.3rem'}}>
              Ignoring will retain the populated version of the Public Records.
            </Typography>
          </FlexWrapper>
        </FlexWrapper>
      : (rejectionsAttomDataNew.length > 0 || rejectionsCoreLogicDataNew.length > 0 || rejectionsAttomAndCoreLogicDataNew.length > 0) && !runningSweep && !dontShowRejectedNew ?
        <FlexWrapper className="column alignCenter justifyCenter" style={{height: '7rem', gridColumn: '2'}}>
          <FlexWrapper className="justifyCenter alignCenter">
            <ButtonWrapper className="justifyCenter" style={{height:'4rem', margin: '0', gridColumn: '2'}}>
              <Button className={runRecordSweepPerm ? "primary" : "primaryDisabled"}  onClick={() => handleClick('submitRejectionsNew')} style={{margin: 'auto 0.5rem', width: '18rem'}}>
                Ignore Rejections
              </Button>
              <Button className="secondary prompt" onClick={() => handleClick('noRejectionsNew')} style={{margin: 'auto 0.5rem'}}>
                <ClearIcon />
              </Button>
            </ButtonWrapper>
            {rejectionsAttomDataNew.length > 0 ?
              <AlertWrapper className="success" style={{marginLeft: '1.5rem'}}>
                <WarningAmberIcon style={{width: '1.2rem', height: '1.2rem'}} />
                {rejectionsAttomDataNew.length} Attom rejection(s)
              </AlertWrapper>
            :
              <></>
            }
            {rejectionsCoreLogicDataNew.length > 0 ?
              <AlertWrapper className="success" style={{marginLeft: '1.5rem'}}>
                <WarningAmberIcon style={{width: '1.2rem', height: '1.2rem'}} />
                {rejectionsCoreLogicDataNew.length} CoreLogic rejection(s)
              </AlertWrapper>
            :
              <></>
            }
            {rejectionsAttomAndCoreLogicDataNew.length > 0 ?
              <AlertWrapper className="success" style={{marginLeft: '1.5rem'}}>
                <WarningAmberIcon style={{width: '1.2rem', height: '1.2rem'}} />
                {rejectionsAttomAndCoreLogicDataNew.length} Attom and CoreLogic rejection(s)
              </AlertWrapper>
            :
              <></>
            }
          </FlexWrapper>
          <FlexWrapper className="justifyCenter alignCenter">
            <WarningAmberIcon style={{width: '1.8rem', height: '1.8rem', color: `${theme.icon.primary.primary}`}} />
            <Typography className="fitContentHeigh alignCenter" style={{margin: '0.2rem 0 0 0.3rem'}}>
              Ignoring will update the records match any null entries with Public Records without recording a discrepancy
            </Typography>
          </FlexWrapper>
        </FlexWrapper>
      : (isError || existingInitialSweeps > 0) && !runningSweep && !workflowStage ?
        <FlexWrapper className="justifyCenter alignCenter" style={{height: '7rem', gridColumn: '2'}}>
          <ButtonWrapper className="justifyCenter" style={{height:'4rem', margin: '0', gridColumn: '2'}}>
            <Button className={runRecordSweepPerm ? "neutral prompt" : "neautralDisabled"}  onClick={() => handleClick('cancel')} style={{margin: 'auto 0.5rem'}}>
              <CheckIcon />
            </Button>
          </ButtonWrapper>
          {isError ?
            <ErrorWrapper style={{marginLeft: '1.5rem'}}>
              <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
              {isError === 'recording' ?
                `Error when attempting to record sweep results`
              :
                `Error while performing a recording sweep`
              }
            </ErrorWrapper>
          :
              <></>
          }
          {existingInitialSweeps > 0 ?
            <AlertWrapper className="success">
              <RadioButtonCheckedIcon style={{width: '1.2rem', height: '1.2rem'}} />
              An initial sweep has been performed on {existingInitialSweeps} mortgages
            </AlertWrapper>
          :
            <></>
          }
        </FlexWrapper>
      : workflowStage === 'initiate' ?
        <ButtonWrapper className="justifyCenter" style={{width: '11rem', height:'7rem', margin: '0 auto', gridColumn: '2'}}>
          <Button className={runRecordSweepPerm ? "primary prompt" : "primaryDisabled"} onClick={runRecordSweepPerm ? () => handleClick('submit') : null} style={{margin: 'auto 0.5rem'}}>
            {runningSweep ?
              <CircularProgress
                sx={{color: `${theme.palette.common.white}`}}
                size={12}
              />
            :
              <CheckIcon />
            }
          </Button>
          <Button className="secondary prompt" onClick={() => handleClick('cancel')} style={{margin: 'auto 0.5rem'}}>
            <ClearIcon />
          </Button>
        </ButtonWrapper>
      :
        <ButtonWrapper style={{width: '11rem', height:'7rem', margin: '0 auto', gridColumn: '2'}}>
          <Button className="primary" onClick={() => handleClick('initiate')} style={{width: '10rem', margin: '1rem'}}>Run Sweep</Button>
        </ButtonWrapper>
      }
    </GridWrapper>
  )
}

export default SweepLoadingBar