import React from "react";
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CardIndex, CompareLink, Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../../../../StyledComponents";

const AssignmentRemoved = ({ notification, handleStatusChange, handleOpenDeleteModal, handleOpenNotesModal, cardIndex }) => {
  const theme = useTheme();

  return (
    <>
      <div style={{display: 'flex'}}>
        <CardIndex style={{paddingRight: '1rem'}}>
          {cardIndex+1}
        </CardIndex>
        <DetailContainer className="notificationTitle">
          <div style={{width: '6rem', margin: '0 0 0 0.5rem', display: 'flex', justifyContent: 'space-between'}}>
            {cardIndex === 0 ?
              <CompareLink autoFocus onClick={() => handleOpenDeleteModal()} style={{margin: '0'}} className="notification">
                <DeleteIcon style={{height: '1rem'}} />
              </CompareLink>
            :
              <CompareLink onClick={() => handleOpenDeleteModal()} style={{margin: '0'}} className="notification">
                <DeleteIcon style={{height: '1rem'}} />
              </CompareLink>
            }
            {notification.status !== "new" ?
              <Tooltip
                componentsProps={{tooltip: {sx: {color: `${theme.palette.common.white}`, backgroundColor: `${theme.palette.common.black}`}}}}
                title={<div><p style={{margin: "0.2rem"}}>Mark as "New"</p></div>} 
                placement="top"
                arrow
              >
                <CompareLink onClick={() => handleStatusChange(notification.status, true)} style={{margin: '0'}} className="notification">
                  <EmailIcon style={{height: '1rem'}} />
                </CompareLink>
              </Tooltip>
            :
              <></>
            }
          </div>
          <div style={{display: 'flex', margin: '0.5rem 0 0 0'}}>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="subject">subject:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="notificationTitle">Removed from Assignment</Detail>
            </DetailInfoContainer>
          </div>
          <div style={{display: 'flex'}}>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">initiated by:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">{notification.updatedBy}</Detail>
            </DetailInfoContainer>
          </div>
          <div style={{display: 'flex'}}>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">date:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer className="notificationTitle">
              <Detail className="date">{notification.date}</Detail>
            </DetailInfoContainer>
          </div>
          <CompareLink onClick={() => handleOpenNotesModal(notification.belongsToMortgage)} style={{margin: '0'}} >
            View Notes <OpenInNewIcon style={{height: '1rem'}} />
          </CompareLink>
        </DetailContainer>
      </div>
      <DetailContainer style={{minWidth: '20rem'}}>
        <div style={{height: 'fit-content'}}>
          <Detail className="header">Mortgage</Detail>
        </div>
        <DetailListContainer>
          <DetailInfoContainer className="item">
            {notification.Owner2FullName ?
              <>
                <Detail className="item">Borrowers:</Detail>
                <Detail></Detail>
              </>
            :
              <Detail className="item">Borrower:</Detail>
            }
            <Detail className="item">Street Address:</Detail>
          </DetailInfoContainer>
          <DetailInfoContainer>
            <Detail>{notification.Owner1FullName}</Detail>
            {notification.Owner2FullName ?
              <Detail>{notification.Owner2FullName}</Detail>
              :
              <></>
            }
            <Detail>{notification.streetAddress}</Detail>
          </DetailInfoContainer>
        </DetailListContainer>
      </DetailContainer>
      {notification.targetOutcome === 'Refinance' || notification.targetOutcome === 'Unassigned' ?
        <>
          <DetailContainer style={{minWidth: '20rem'}}>
            <div style={{height: 'fit-content'}}>
              <Detail className="header">Details</Detail>
            </div>
            <DetailListContainer>
              <DetailInfoContainer className="item">
                {notification.previousAssignees.length > 1 ?
                  <Detail className="item" style={{width: '10rem'}}>Previous Assignees:</Detail>
                :
                  <Detail className="item" style={{width: '10rem'}}>Previous Assignee:</Detail>
                }
                {notification.newAssignees ?
                  notification.newAssignees.length > 1 ?
                    <Detail className="item" style={{width: '10rem'}}>New Assignees:</Detail>
                  :
                    <Detail className="item" style={{width: '10rem'}}>New Assignee:</Detail>
                :
                  notification.removedAssignees.length > 1 ?
                    <Detail className="item" style={{width: '10rem'}}>Removed Assignees:</Detail>
                  :
                    <Detail className="item" style={{width: '10rem'}}>Removed Assignee:</Detail>
                }
              </DetailInfoContainer>
              <DetailInfoContainer>
                {notification.previousAssignees.map((fullName, index) => (
                  <Detail key={fullName}>{fullName}</Detail>
                ))}
                {notification.newAssignees ?
                  notification.newAssignees.map((fullName, index) => (
                    <Detail key={fullName}>{fullName}</Detail>
                  ))
                :
                  notification.removedAssignees.map((fullName, index) => (
                    <Detail key={fullName}>{fullName}</Detail>
                  ))
                }
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>
          <DetailContainer className="target" style={{minWidth: '20rem'}}>
            <DetailListContainer>
              <DetailInfoContainer className="potential item">
                <Detail className="potential">Target interest due:</Detail>
                <Detail className="potential">Percent increase:</Detail>
              </DetailInfoContainer>
              <DetailInfoContainer>
                <Detail className="target">${notification.targetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Detail>
                <Detail className="target">{notification.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Detail>
              </DetailInfoContainer>
            </DetailListContainer>
          </DetailContainer>
        </>
      :
        <DetailContainer style={{minWidth: '20rem', width: '45%', justifyContent: 'flex-start'}}>
          <div style={{height: 'fit-content'}}>
            <Detail className="header">Details</Detail>
          </div>
          <DetailListContainer>
            <DetailInfoContainer className="item">
              <Detail className="item">Target Outcome:</Detail>
              {notification.previousAssignees.length > 1 ?
                <Detail className="item">Previous Assignees:</Detail>
              :
                <Detail className="item">Previous Assignee:</Detail>
              }
              {notification.newAssignees ?
                notification.newAssignees.length > 1 ?
                  <Detail className="item">New Assignees:</Detail>
                :
                  <Detail className="item">New Assignee:</Detail>
              :
                notification.removedAssignees.length > 1 ?
                  <Detail className="item">Removed Assignees:</Detail>
                :
                  <Detail className="item">Removed Assignee:</Detail>
              }
            </DetailInfoContainer>
            <DetailInfoContainer>
              <Detail>{notification.targetOutcome}</Detail>
              {notification.previousAssignees.map((fullName, index) => (
                <Detail key={fullName}>{fullName}</Detail>
              ))}
              {notification.newAssignees ?
                notification.newAssignees.map((fullName, index) => (
                  <Detail key={fullName}>{fullName}</Detail>
                ))
              :
                notification.removedAssignees.map((fullName, index) => (
                  <Detail key={fullName}>{fullName}</Detail>
                ))
              }
            </DetailInfoContainer>
          </DetailListContainer>
        </DetailContainer>
      }
    </>
  )
}

export default AssignmentRemoved;