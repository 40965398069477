// import {
//     Document,
//     Page,
//     StyleSheet,
//     Text,
//     View,
//     Image,
//   } from "@react-pdf/renderer";
  // import FlagCircleIcon from "@mui/icons-material/FlagCircle";
  // import SportsScoreIcon from "@mui/icons-material/SportsScore";
  import React from "react";
  import CompanyLogo from "../../../../../icon.logo.black.png";
  import { s as mainStyle } from "./ReportView";
  
  // const s = StyleSheet.create(mainStyle);
  
  function getDateFromISOString(isoString) {
    const date = new Date(isoString);
    let day = date.getDay();
    const dayLastDigit = Number(day.toString().at(-1));
    if (dayLastDigit === 1) {
      day = day + "st";
    } else if (dayLastDigit === 2) {
      day = day + "nd";
    } else if (dayLastDigit === 3) {
      day = day + "rd";
    } else {
      day = day + "th";
    }
  
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  
    const month = months[date.getMonth()];
  
    return `${day} ${month}, ${date.getFullYear()}`;
  }
  
  export default function ReportPDF({ report }) {
    return (
      // <Document>
      //   <Page size={"A4"} style={s.main}>
      //     <View style={s.reportHead}>
      //       <View style={s.headerContainer}>
      //         <Image
      //           source={CompanyLogo}
      //           src={CompanyLogo}
      //           style={s.companyLogo}
      //         />
  
      //         <View>
      //           <Text style={s.reportId}>Report #{report.parcelNumber}</Text>
      //           <Text style={s.closeDate}>
      //             Closed: {getDateFromISOString(report.endDate)}
      //           </Text>
      //         </View>
      //       </View>
  
      //       <View style={s.reportTypeContainer}>
      //         <Text style={s.reportType}>{report.outcome}</Text>
      //       </View>
  
      //       <View style={s.addressContainer}>
      //         <Text style={s.addressContent}>APN: 0000-000-001</Text>
      //         <Text style={s.addressContent}>
      //           Address: 111 Beach Dr San Diego, CA 99999.
      //         </Text>
      //       </View>
  
      //       <View style={s.borrowerContainer}>
      //         <View>
      //           <Text style={s.borrowerCardCaption}>Borrower One</Text>
      //           <Text style={s.borrowerName}>Julius Baldwin</Text>
      //           <Text style={s.addressContent}>Credit Score: 700</Text>
      //         </View>
      //         <View>
      //           <Text style={s.borrowerCardCaption}>Borrower Two</Text>
      //           <Text style={s.borrowerName}>Isidor Chandler</Text>
      //           <Text style={s.addressContent}>Credit Score: 700</Text>
      //         </View>
      //       </View>
      //     </View>
  
      //     <View style={s.reportBody}>
      //       <View style={s.reportSection}>
      //         <Text style={s.reportSectionTitle}>ORIGINAL MORTGAGE</Text>
      //         <View style={s.reportSectionContentContainer}>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Start Date</Text>
      //               <Text style={s.reportRowValue}>01 JAN 1995</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Loan Amount</Text>
      //               <Text style={s.reportRowValue}>$200,000</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Interest Rate</Text>
      //               <Text style={s.reportRowValue}>7.0%</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Monthly Payments</Text>
      //               <Text style={s.reportRowValue}>$2,048.23</Text>
      //             </View>
      //           </View>
  
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>End Date</Text>
      //               <Text style={s.reportRowValue}>31 DEC 2025</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Down Payment</Text>
      //               <Text style={s.reportRowValue}>20%</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Interest Owed</Text>
      //               <Text style={s.reportRowValue}>$200,000</Text>
      //             </View>
      //           </View>
      //         </View>
      //       </View>
  
      //       <View style={s.reportSection}>
      //         <Text style={s.reportSectionTitle}>AT CLOSING</Text>
      //         <View style={s.reportSectionContentContainer}>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Time Remaining</Text>
      //               <Text style={s.reportRowValue}>1 year 1 Month</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Principal Paid</Text>
      //               <Text style={s.reportRowValue}>$190,000</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Principal Remaining</Text>
      //               <Text style={s.reportRowValue}>$10,000</Text>
      //             </View>
      //           </View>
  
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}> </Text>
      //               <Text style={s.reportRowValue}> </Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Interest Paid</Text>
      //               <Text style={s.reportRowValue}>$90,000</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Interest Remaining</Text>
      //               <Text style={s.reportRowValue}>$10,000</Text>
      //             </View>
      //           </View>
      //         </View>
      //       </View>
  
      //       <View style={s.reportSection}>
      //         <Text style={s.reportSectionTitle}>NEW MORTGAGE</Text>
      //         <View style={s.reportSectionContentContainer}>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Start Date</Text>
      //               <Text style={s.reportRowValue}>15 NOV 2023</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Loan Amount</Text>
      //               <Text style={s.reportRowValue}>$10,000</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Interest Rate</Text>
      //               <Text style={s.reportRowValue}>7.0%</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Monthly Payments</Text>
      //               <Text style={s.reportRowValue}>$1,000</Text>
      //             </View>
      //           </View>
  
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>End Date</Text>
      //               <Text style={s.reportRowValue}>14 NOV 2024</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Down Payment</Text>
      //               <Text style={s.reportRowValue}>20%</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Interest Owed</Text>
      //               <Text style={s.reportRowValue}>$15,000</Text>
      //             </View>
      //           </View>
      //         </View>
      //       </View>
  
      //       <View
      //         style={[
      //           s.reportSection,
      //           { borderBottom: "none", paddingBottom: 0 },
      //         ]}
      //       >
      //         <Text style={s.reportSectionTitle}>FINANCIALS</Text>
      //         <View style={s.reportSectionContentContainer}>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Profit:</Text>
      //               <Text style={s.reportRowValue}>$5,000</Text>
      //             </View>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Team Total Profit:</Text>
      //               <Text style={s.reportRowValue}>$20,000</Text>
      //             </View>
      //           </View>
  
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Difference</Text>
      //               <Text style={s.reportRowValue}>100%</Text>
      //             </View>
      //           </View>
      //         </View>
      //       </View>
      //     </View>
      //   </Page>
  
      //   <Page size={"A4"} style={{ ...s.main, padding: 35 }}>
      //     {/* DISCREPANCIES START */}
      //     <View style={{ marginBottom: 10 }}>
      //       <View style={s.reportSection}>
      //         <Text style={s.reportSectionTitle}>
      //           DISCREPANCIES PRESENT AT CLOSING
      //         </Text>
  
      //         <View style={{ marginTop: 5 }}>
      //           <Text
      //             style={{
      //               ...s.reportSectionTitle,
      //               fontSize: 12,
      //               marginBottom: 5,
      //               textDecoration: "underline",
      //             }}
      //           >
      //             Last appraisal date
      //           </Text>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Original</Text>
      //               <Text style={s.reportRowValue}>Jan 1st 1995</Text>
      //             </View>
      //           </View>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>New Record</Text>
      //               <Text style={s.reportRowValue}>Jun 31st 2020</Text>
      //             </View>
      //           </View>
      //         </View>
  
      //         <View style={{ marginTop: 15 }}>
      //           <Text
      //             style={{
      //               ...s.reportSectionTitle,
      //               fontSize: 12,
      //               marginBottom: 5,
      //               textDecoration: "underline",
      //             }}
      //           >
      //             Last appraisal value
      //           </Text>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Original</Text>
      //               <Text style={s.reportRowValue}>$200,000</Text>
      //             </View>
      //           </View>
  
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>New Record</Text>
      //               <Text style={s.reportRowValue}>$220,000</Text>
      //             </View>
      //           </View>
      //         </View>
  
      //         <View style={{ marginTop: 15 }}>
      //           <Text
      //             style={{
      //               ...s.reportSectionTitle,
      //               fontSize: 12,
      //               marginBottom: 5,
      //               textDecoration: "underline",
      //             }}
      //           >
      //             Year Built
      //           </Text>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Original</Text>
      //               <Text style={s.reportRowValue}>Jan 1st 2020</Text>
      //             </View>
      //           </View>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>New Record</Text>
      //               <Text style={s.reportRowValue}>Mar 1st 2020</Text>
      //             </View>
      //           </View>
      //           =
      //         </View>
      //       </View>
      //     </View>
      //     {/* DISCREPANCIES END */}
  
      //     {/* RESOLVED DISCREPANCIES START */}
      //     <View style={{ marginBottom: 30 }}>
      //       <View style={s.reportSection}>
      //         <Text style={s.reportSectionTitle}>RESOLVED DISCREPANCIES </Text>
  
      //         <View style={{ marginTop: 5 }}>
      //           <Text
      //             style={{
      //               ...s.reportSectionTitle,
      //               fontSize: 12,
      //               marginBottom: 5,
      //               textDecoration: "underline",
      //             }}
      //           >
      //             Number of buildings
      //           </Text>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Original</Text>
      //               <Text style={s.reportRowValue}>1</Text>
      //             </View>
      //           </View>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>New Record</Text>
      //               <Text style={s.reportRowValue}>2</Text>
      //             </View>
      //           </View>
      //           <View style={s.reportSectionColumn}>
      //             <View style={s.reportSectionContentContainer}>
      //               <Text style={s.reportRowTitle}>Resolved To</Text>
      //               <Text style={s.reportRowValue}>2</Text>
      //             </View>
      //           </View>
      //         </View>
      //       </View>
      //     </View>
      //     {/* DISCREPANCIES END */}
  
      //     {/* TIMELINE START */}
      //     <View>
      //       <Text style={{ ...s.reportSectionTitle, marginBottom: 20 }}>
      //         TIMELINE
      //       </Text>
      //       {report.timeline.map((timeline, i) => (
      //         <View
      //           key={i}
      //           style={{
      //             ...s.timeLineContainer,
      //             ...(i === report.timeline.length - 1
      //               ? { paddingBottom: 0, borderLeft: "0" }
      //               : {}),
      //           }}
      //         >
      //           <View
      //             style={{
      //               width: 13,
      //               height: 13,
      //               backgroundColor: "#DC8E43",
      //               alignItems: "center",
      //               justifyContent: "center",
      //               borderRadius: 100,
      //               position: "absolute",
      //               left: -6.5,
      //               top: 2,
      //             }}
      //           >
      //             {i === 0 ? (
      //               <Image
      //                 src="https://img.icons8.com/FFFFFF/material-rounded/24/flag.png"
      //                 alt=""
      //                 style={{ width: 8, height: 8 }}
      //               />
      //             ) : (
      //               <View
      //                 style={{
      //                   width: 7,
      //                   height: 7,
      //                   backgroundColor: "#FFFFFF",
      //                   borderRadius: 100,
      //                 }}
      //               />
      //             )}
      //           </View>
      //           <Text style={s.timeLineTitle}>{timeline.category}</Text>
  
      //           {timeline.details.map((detail, i) => (
      //             <View key={i} style={s.timeLineDetail}>
      //               <Text> {detail.title} </Text>
      //               <Text style={s.timeLineDetailValue}> {detail.value} </Text>
      //             </View>
      //           ))}
      //         </View>
      //       ))}
      //     </View>
      //     {/* TIMELINE END */}
      //   </Page>
      // </Document>
      <></>
    );
  }
  