import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    userId: '',
    email: '',
    role: '',
    firstName: '',
    fullName: '',
    initials: '',
    teamId: '',
    reqCredentials: {
      protocol: '',
      location: '',
      isp: '',
      proxy: false,
      mobile: false,
    },
  },
  status: 'idle',
  error: null
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.data.role = action.payload.role
      state.data.userId = action.payload.userId
      state.data.teamId = action.payload.teamId
      state.data.fullName = action.payload.fullName
      state.data.initials = action.payload.initials
      state.data.firstName = action.payload.firstName
    },
    setTeamId (state, action) { state.data.teamId = action.payload },
    setUserId (state, action) { state.data.userId = action.payload },
    setUserEmail(state, action) { state.data.email = action.payload },
    setUserFirstName(state, action) { state.data.firstName = action.payload },
    setUserReqCredentials(state, action) { 
      state.data.reqCredentials.isp = action.payload.isp
      state.data.reqCredentials.proxy = action.payload.proxy
      state.data.reqCredentials.mobile = action.payload.mobile
      state.data.reqCredentials.protocol = action.payload.protocol
      state.data.reqCredentials.location = action.payload.location
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.loginReturningUser.matchFulfilled, (state, {payload}) => {
      if (payload.message.includes('successful')) {
        state.data.role = payload.data.userInfo.role
        state.data.email = payload.data.userInfo.email
        state.data.userId = payload.data.userInfo.userId
        state.data.teamId = payload.data.userInfo.teamId
        state.data.fullName = payload.data.userInfo.fullName
        state.data.initials = payload.data.userInfo.initials
        state.data.firstName = payload.data.userInfo.firstName
        state.data.reqCredentials.isp = payload.data.userInfo.isp
        state.data.reqCredentials.proxy = payload.data.userInfo.proxy
        state.data.reqCredentials.mobile = payload.data.userInfo.mobile
        state.data.reqCredentials.protocol = payload.data.userInfo.protocol
        state.data.reqCredentials.location = payload.data.userInfo.location
      }
    })
  }
})

export const { setUserInfo, setUserFirstName, setUserReqCredentials, setUserEmail, setUserId, setTeamId } = userInfoSlice.actions

export default userInfoSlice.reducer

export const selectUserId = state => state.userInfo.data.userId
export const selectTeamId = state => state.userInfo.data.teamId
export const selectUserRole = state => state.userInfo.data.role
export const selectUserEmail = state => state.userInfo.data.email
export const selectUserFullName = state => state.userInfo.data.fullName
export const selectUserInitials = state => state.userInfo.data.initials
export const selectUserFirstName = state => state.userInfo.data.firstName
export const selectUserRequestCredentials = state => state.userInfo.data.reqCredentials