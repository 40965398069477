import React from "react";
import styled from "@emotion/styled";
import SellIcon from '@mui/icons-material/Sell';
import ErrorIcon from '@mui/icons-material/Error';
import { useTheme } from "@mui/styles";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useDispatch, useSelector } from "react-redux";
import { selectMortgageActionsNavSelection, setMortgageActionNavMenuSelection } from "../../../slices/sessionDataSlice";
import { Button, FlexWrapper, NavIndex, NavText, NavigationContainer, SideBarNavHeader, Typography } from "../../../StyledComponents";

const MortgageActionsNav = ({ mortgageData, reportsNumber, handleCloseModal, leadData, activeLead }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const navSelection = useSelector(selectMortgageActionsNavSelection)
  
  const changeSelection = (content) => {
    dispatch( setMortgageActionNavMenuSelection(content))
  }

  return (
    <NavigationContainer className="modal" style={{gridRow:'2/6'}}>
      <SideBarNavHeader className="modal">Actions</SideBarNavHeader>
      {navSelection ?
        <>
          {navSelection === 'reports' ?
            <ListItemNav selected top>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          : reportsNumber > 0 ?
            <ListItemNav onClick={() => changeSelection('reports')} top>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          :
            <ListItemNav top disabled>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          }
          {navSelection === 'notes' ?
            <ListItemNav selected>
              <NavIndex>
                <EditNoteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Notes ({mortgageData.mortgageNotes.length})</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('notes')}>
              <NavIndex>
                <EditNoteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Notes ({mortgageData.mortgageNotes.length})</NavText>
            </ListItemNav>
          }
          {navSelection === 'compareRecords' ?
            <ListItemNav selected>
              <NavIndex>
                <FileCopyIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Compare Records</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('compareRecords')}>
              <NavIndex>
                <FileCopyIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Compare Records</NavText>
            </ListItemNav>
          }
          {navSelection === 'propertyDetails' ?
            <ListItemNav selected>
              <NavIndex>
                <HomeWorkIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Property Details</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('propertyDetails')}>
              <NavIndex>
                <HomeWorkIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Property Details</NavText>
            </ListItemNav>
          }
          {!mortgageData.financialsPresent ?
            navSelection === 'provideTerms' || navSelection === 'provideFinancialsModal' ?
              <ListItemNav attention selected style={{padding: '0.1rem 0 0 0.3rem'}}>
                <NavIndex>
                  <AttachMoneyIcon fontSize="large" style={{color: `${theme.accent.caution.primary}`}} />
                </NavIndex>
                <NavText className="main">Provide Original Terms</NavText>
                <ErrorIcon style={{margin: '0 0 2.4rem 0.8rem', width: '1.2rem', color: `${theme.accent.caution.primary}`}} />
                <NavText className="label" style={{margin: '0 0 2.4rem 0', color: `${theme.accent.caution.primary}`}}>ToDo</NavText>
              </ListItemNav>
            :
              <ListItemNav attention onClick={() => changeSelection('provideTerms')} style={{padding: '0.1rem 0 0 0.3rem'}}>
                <NavIndex>
                  <AttachMoneyIcon fontSize="large" style={{color: `${theme.accent.caution.primary}`}} />
                </NavIndex>
                <NavText className="main">Provide Original Terms</NavText>
                <ErrorIcon style={{margin: '0 0 2.4rem 0.8rem', width: '1.2rem', color: `${theme.accent.caution.primary}`}} />
                <NavText className="label" style={{margin: '0 0 2.4rem 0', color: `${theme.accent.caution.primary}`}}>ToDo</NavText>
              </ListItemNav>
          :
            <></>
          }
          {navSelection === 'editTags' ?
            <ListItemNav selected>
              <NavIndex>
                <SellIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Tags</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('editTags')}>
              <NavIndex>
                <SellIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Tags</NavText>
            </ListItemNav>
          }
          {activeLead && navSelection === 'addAssignees' ?
            <ListItemNav selected>
              <NavIndex>
                <PersonAddIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Manage Assignees {leadData && leadData.assigneeIds && leadData.assigneeIds.length > 0 && mortgageData.assigneeIds.length > 0 ? `(${leadData.assigneeIds.length + mortgageData.assigneeIds.length})` : mortgageData.assigneeIds.length > 0 ? `(${mortgageData.assigneeIds.length})` : leadData.assigneeIds && leadData.assigneeIds.length > 0 ? `(${leadData.assigneeIds.length})` : ''}</NavText>
            </ListItemNav>
          : activeLead ?
            <ListItemNav onClick={() => changeSelection('addAssignees')}>
              <NavIndex>
                <PersonAddIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Manage Assignees {leadData && leadData.assigneeIds && leadData.assigneeIds.length > 0 && mortgageData.assigneeIds.length > 0 ? `(${leadData.assigneeIds.length + mortgageData.assigneeIds.length})` : mortgageData.assigneeIds.length > 0 ? `(${mortgageData.assigneeIds.length})` : leadData.assigneeIds && leadData.assigneeIds.length > 0 ? `(${leadData.assigneeIds.length})` : ''}</NavText>
            </ListItemNav>
          :
            <></>
          }
          <FlexWrapper className="column justifyCenter alignCenter fitContentHeight" style={{marginTop: '8rem'}}>
            {mortgageData.status === 'inactive' ?
              <Button 
                style={{margin: '0 auto', width: '16rem'}} 
                className={navSelection === 'investigateLead' ? "primaryActive disabled" : "primary" }
                disabled={navSelection === 'investigateLead'}
                onClick={navSelection === 'investigateLead' ? null : () => changeSelection('investigateLead')}
              >
                <Typography className="small">Open Investigation</Typography>
              </Button>
            : mortgageData.status === 'awaitingUpdate' ?
              <Button 
                style={{margin: '0 auto', width: '16rem'}} 
                className={navSelection === 'confirmUpdate' ? "primaryActive disabled" : "primary" }
                disabled={navSelection === 'confirmUpdate'}
                onClick={navSelection === 'confirmUpdate' ? null : () => changeSelection('confirmUpdate')}
              >
                <Typography className="small">Confirm Updates</Typography>
              </Button>
            : <></>}
            <Button className="neutral" style={{margin: '2rem auto 0 auto', width: '16rem'}} onClick={() => handleCloseModal()}>
              <Typography className="small">Close Window</Typography>
            </Button>
          </FlexWrapper>
        </>
      :
        <>
          <ListItemNav disabled>
            <NavIndex>
              <SummarizeIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View Reports</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <EditNoteIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View/Add Notes</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <FileCopyIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Compare Records</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <HomeWorkIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View Property Details</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <SellIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Mortgage Tags</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <PersonAddIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Manage Assignees</NavText>
          </ListItemNav>
        </>
      }
    </NavigationContainer>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 0.3rem;
  margin: 0;
  background-color: ${(props) => props.attention && props.selected ? '#FFD08F' : props.attention ? `#FFECD1` : props.selected && !props.disabled ? "#4B14752B" : props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor ? "default" : props.disabled ? 'not-allowed' : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  border-top: ${(props) => props.top ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  border-bottom: ${(props) => props.bottom ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  &:hover {
    background-color: ${(props) => props.attention && props.selected ? '#FCC477' : props.attention ? '#FFF4E6' : props.selected && !props.disabled ? "#4B147533" : props.disabled ? "#E0E0E0" : "#F2F2F2"};
  }
`

export default MortgageActionsNav;