import React, { PureComponent } from 'react';
import { useTheme } from "@mui/styles";
import { AccentInfo } from '../../../../../../../../StyledComponents';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Label} from 'recharts';

const CustomTooltip = ({ active, payload, perspective }) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <div style={{backgroundColor: `${theme.palette.common.cardBackground}`, padding: "10px", width: "200px", borderRadius: ".5rem", border: `0.1rem solid ${theme.palette.common.black}`}}>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          {`Month: `}
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.sessionLabelFull}`}
          </span>
        </div>

        <hr />

        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Closed Renegotiations: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {payload[0].payload.closedRenegotiations ?
              `${payload[0].payload.closedRenegotiations}`
            :
              <AccentInfo>none</AccentInfo>
            }
          </span>
        </div>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600", marginTop: '0.5rem'}}>
          <span>{`Tier 1 Closures: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {payload[0].payload.tier1Renegotiations ?
              `${payload[0].payload.tier1Renegotiations}`
            :
              <AccentInfo>none</AccentInfo>
            }
          </span>
        </div>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Tier 2 Closures: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {payload[0].payload.tier2Renegotiations ?
              `${payload[0].payload.tier2Renegotiations}`
            :
              <AccentInfo>none</AccentInfo>
            }
          </span>
        </div>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Manual Closures: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {payload[0].payload.manualRenegotiations ?
              `${payload[0].payload.manualRenegotiations}`
            :
              <AccentInfo>none</AccentInfo>
            }
          </span>
        </div>
      </div>
    );
  }

  return null;
};


export default class MonthlyTwoYearChart extends PureComponent {

  render() 
  {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={this.props.monthlyStatsTwoYear}
          layout="horizontal"
          margin={{
            top: 0,
            right: 5,
            left: 15,
            bottom: 0,
          }}
        >
          <XAxis dataKey="sessionLabel">

          </XAxis>
          <YAxis dataKey="closedRenegotiations" type="number" domain={[0, dataMax => Math.round((dataMax * 1.2))]} yAxisId="right" orientation="right" stroke="#5a5c68">
            <Label angle={90} position='right' style={{ textAnchor: 'middle' }} offset={-20}>
              closed renegotiations
            </Label>
          </YAxis>
          <Tooltip content={<CustomTooltip/>} cursor={{fill: `#93959921`}} />
          <Legend />
          <Bar yAxisId="right" dataKey="closedRenegotiations" fill="#6e88cc" stackId="a" name="Closed Renegotiations" />
          <Bar yAxisId="right" dataKey="tier1Renegotiations" fill="#E38A86" stackId="b" name="Tier 1 Closures" />
          <Bar yAxisId="right" dataKey="tier2Renegotiations" fill="#CAABD1" stackId="b" name="Tier 2 Closures" />
          <Bar yAxisId="right" dataKey="manualRenegotiations" fill="#00B81284" stackId="b" name="Manual Closures" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}