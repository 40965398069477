import React from "react";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectMembersDataArray } from "../../../../../slices/membersDataArraySlice";
import { FlexWrapper, ComponentSectionRow, ColumnContent, ComponentHeader, Typography } from "../../../../../StyledComponents";

const TeamList = ({ userId }) => {
  const theme = useTheme()

  const memberData = useSelector(selectMembersDataArray)

  return (
      <FlexWrapper className="column fitContentHeight" style={{paddingBottom: '4rem'}}>
        <ComponentHeader>
          My Team
        </ComponentHeader>
        <ComponentSectionRow className="listMembers header subHeader">
          <ColumnContent className="header" style={{gridColumn: '2'}}>
            <Typography style={{margin: '0 auto'}}>
              Name
            </Typography>
          </ColumnContent>  
          <ColumnContent className="header" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <Typography style={{margin: '0 auto'}}>
              Role
            </Typography>
          </ColumnContent>  
          <ColumnContent className="header" style={{gridColumn: '4', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <Typography style={{margin: '0 auto'}}>
              eMail
            </Typography>
          </ColumnContent>  
        </ComponentSectionRow>
        {memberData.map((member, index) => (
          member.memberId !== userId &&
            <ComponentSectionRow key={member.memberId} className='listMembers' style={{padding: '0.5rem 0'}}>
              <ColumnContent className="detail small" style={{gridColumn: '2'}}>
                {member.fullName}
              </ColumnContent>
              <ColumnContent className="detail small" style={{gridColumn: '3'}}>
                {member.role === 'super' ?
                  'App Admin'
                : member.role === 'admin' ?
                  'Manager'
                :
                  'User'
                }
              </ColumnContent>
              <ColumnContent className="detail small lowercase" style={{gridColumn: '4'}}>
                {member.email}
              </ColumnContent>
            </ComponentSectionRow>
        ))}
      </FlexWrapper>
  )
}

export default TeamList