import React from "react"
import { useTheme } from "@mui/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AccentInfo, Detail, DetailListContainer, FlexWrapper, Typography } from "../../../../StyledComponents"

const DiscrepanciesDetected = ({ type, tier1, tier2, tier3  }) => {
  const theme = useTheme()

  return (
    <>
      <Typography 
        className="justifyEnd"
        style={{width: '15rem', margin: '1rem 0 0.5rem 0'}}
      >
        {type === 'discrepancy' ?
          'New Discrepancies Identified:'
        : type === 'update' ?
          <>
            Public Record updates on<br/>
            Existing Discrepancies:
          </>
        :
          <>
            Public Record updates that<br/>
            Match Existing Records:
          </>
        }
      </Typography>
      <DetailListContainer className="timeline column">
        {tier1 && tier1.map((discrepancy) => (
          discrepancy.publicRecordValue !== null ?
            <FlexWrapper key={discrepancy.label} className="alignCenter">
              <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                <Detail className={type === 'resolved' ? "timelineDetail tier success" : "timelineDetail tier one"} style={{padding: type === 'resolved' ? '0 1rem 0 0.1rem' : ''}}>
                  {type === 'resolved' && <CheckCircleIcon style={{color: `${theme.palette.primary.dark}`, marginRight: '0.1rem'}} />}
                  {discrepancy.label}
                </Detail>
              </div>
              <Detail style={{padding: '0 0 0 0.3rem'}}>
                {!discrepancy.originalValue || discrepancy.originalValue === 0 || discrepancy.originalValue.length === 0 ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{discrepancy.publicRecordValue}</span>
                  </FlexWrapper>
                : !discrepancy.publicRecordValue || discrepancy.publicRecordValue === 0 || discrepancy.publicRecordValue.length === 0 ?
                  discrepancy.label === 'Primary Mortgage Interest Rate' ?
                    <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                      <Detail><AccentInfo style={{fontWeight: '600'}}>-empty-</AccentInfo></Detail>
                      <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 1})}%</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                    </FlexWrapper>
                  :
                    <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                      <Detail><AccentInfo style={{fontWeight: '600'}}>-empty-</AccentInfo></Detail>
                      <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{discrepancy.originalValue}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                    </FlexWrapper>
                : discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' || discrepancy.label === 'Primary Mortgage Amount' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>${parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Tax Annual Amount' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>${parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{parseFloat(discrepancy.publicRecordValue)}</span>
                    (from <span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{parseFloat(discrepancy.originalValue)}</span>)
                  </FlexWrapper>
                : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Primary Mortgage Interest Rate' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 1})}%</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 1})}%</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                :
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{discrepancy.publicRecordValue}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{discrepancy.originalValue}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                }
              </Detail>
            </FlexWrapper>
          :
            <FlexWrapper key={discrepancy.label}>
              <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
              <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                <Detail><AccentInfo style={{fontWeight: '600'}}>-empty-</AccentInfo></Detail>
                <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{discrepancy.originalValue}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
        ))}
        {tier2 && tier2.map((discrepancy) => (
          discrepancy.publicRecordValue !== null ?
            <FlexWrapper key={discrepancy.label} className="alignCenter">
              <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                <Detail className={type === 'resolved' ? "timelineDetail tier success" : "timelineDetail tier two"}>
                  {type === 'resolved' && <CheckCircleIcon style={{color: `${theme.palette.primary.dark}`, marginRight: '0.1rem'}} />}
                  {discrepancy.label}
                </Detail>
              </div>
              <Detail style={{padding: '0 0 0 0.3rem'}}>
                {discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>${parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Tax Annual Amount' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>${parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{parseFloat(discrepancy.publicRecordValue)}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{parseFloat(discrepancy.originalValue)}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                :
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{discrepancy.publicRecordValue}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{discrepancy.originalValue}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                }
              </Detail>
            </FlexWrapper>
          :
            <FlexWrapper key={discrepancy.label}>
              <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
              <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                <Detail><AccentInfo style={{fontWeight: '600'}}>-empty-</AccentInfo></Detail>
                <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{discrepancy.originalValue}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
        ))}
        {tier3 && tier3.map((discrepancy) => (
          discrepancy.publicRecordValue !== null ?
            <FlexWrapper key={discrepancy.label} className="alignCenter">
              <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                <Detail className={type === 'resolved' ? "timelineDetail tier success" : "timelineDetail tier three"}>
                  {type === 'resolved' && <CheckCircleIcon style={{color: `${theme.palette.primary.dark}`, marginRight: '0.1rem'}} />}
                  {discrepancy.label}
                </Detail>
              </div>
              <Detail style={{padding: '0 0 0 0.3rem'}}>
                {discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>${parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Tax Annual Amount' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>${parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{parseFloat(discrepancy.publicRecordValue)}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{parseFloat(discrepancy.originalValue)}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{parseFloat(discrepancy.originalValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                :
                  <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                    <span style={{fontWeight: '600', marginRight: '0.4rem'}}>{discrepancy.publicRecordValue}</span>
                    <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{discrepancy.originalValue}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
                  </FlexWrapper>
                }
              </Detail>
            </FlexWrapper>
          :
            <FlexWrapper key={discrepancy.label}>
              <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
              <FlexWrapper className="column" style={{margin: '0.2rem 0', fontWeight: '400'}}>
                <Detail><AccentInfo style={{fontWeight: '600'}}>-empty-</AccentInfo></Detail>
                <FlexWrapper><span style={{fontWeight: '300'}}>(from</span><span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{discrepancy.originalValue}</span><span style={{fontWeight: '300'}}>)</span></FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
        ))}
      </DetailListContainer>
    </>
  )
}

export default DiscrepanciesDetected