import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    leadGenerations: {
      totalSuccessfulQueries: 0,
      totalLeadsGenerated: 0,
      closureRatio: 0,
      totalHits: 0,
      totalHitsAvgPercent: 0,
      totalTier1Leads: 0,
      totalTier2Leads: 0,
    },
    dismissedLeads: 0,
    renegotiations: {
      closedRenegotiations: 0,
      tier1Renegotiations: 0,
      tier2Renegotiations: 0,
      manualRenegotiations: 0,
      lastRenegotiation: '',
      renegotiationFrequency: 0,
    },
    refinances: {
      closedRefinances: 0,
      tier1Refinances: 0,
      tier2Refinances: 0,
      manualRefinances: 0,
      grossProfitNumber: 0,
      grossProfitPercent: 0,
      averageProfitNumber: 0,
      averageProfitPercent: 0,
      lastRefinance: '',
      refinanceFrequency: 0,
    },
    pieCharts: {
      closureType: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      actionType: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      tier1: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      tier2: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      manual: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      renegotiations: [
        {name: '', size: 0},
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      refinances: [
        {name: '', size: 0},
        {name: '', size: 0},
        {name: '', size: 0},
      ],
    },
    query: {
      lastQuery: '',
      nextQuery: '',
      remainingMonthlyQueries: 0,
      subscriptionMonthlyQueries: 0,
    },
    tagsViewMortgages: [
      {}, //ownerIsCorporationMortgages
      {}, //ownerIsLLCMortgages
      {}, //ownerIsLLPMortgages
      {}, //ownerIsFLLPMortgages
      {}, //ownerIsTrustMortgages
      {}, //ownerIsRevocableTrustMortgages
      {}, //ownerIsLivingTrustMortgages
      {}, //ownerIsAssociationMortgages
      {}, //commercialPropertyMortgages
      {}, //residentialPropertyMortgages
      {}, //conventionalLoanMortgages
      {}, //fixedRateAmortizationMortgages
      {}, //secondMortgageMortgages
      {}, //taxInitialDelinquencyYearMortgages
      {}, //reoFlagMortgages
      {}, //distressMortgages
      {}, //quitClaimMortgages
      {}, //ownerOccupiedMortgages
      {}, //taxExemptionStatusMortgages
    ],
    customTagViewAdded: false,
    superTagsViewMortgages: [
      {}, //miscEntityOwnerTypeMortgages
      {}, //miscPropertyTypeMortgages
      {}, //miscLoanTypeMortgages
    ],
  },
  status: 'idle',
  error: null
}

export const teamStatsSlice = createSlice({
  name: 'teamStats',
  initialState,
  reducers: {
    addCustomTagView(state, action) { 
      state.data.tagsViewMortgages.unshift(action.payload)
      state.data.customTagViewAdded = true
    },
    editCustomTagView(state, action) { 
      state.data.tagsViewMortgages[0].totalMortgages = action.payload.newTotal
    },
    decrementTeamRemainingMonthlyQueries(state, action) { 
      state.data.query.remainingMonthlyQueries = state.data.query.remainingMonthlyQueries - action.payload
    },
    removeCustomTagView(state, action) { 
      state.data.tagsViewMortgages.splice(0, 1)
      state.data.customTagViewAdded = false
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.teamStats
        if (payload.data.teamStats.superTagsViewMortgages) {
          state.data.tagsViewMortgages = [...payload.data.teamStats.tagsViewMortgages, ...payload.data.teamStats.superTagsViewMortgages]
        }
      }
    })
    .addMatcher(apiSlice.endpoints.reloadTagViews.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.superTagsViewMortgages) {
        state.data.tagsViewMortgages = [...payload.data.tagsViewMortgages, ...payload.data.superTagsViewMortgages]
        state.data.superTagsViewMortgages = payload.data.superTagsViewMortgages
      }
    })
    .addMatcher(apiSlice.endpoints.recordQueryPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.query.remainingMonthlyQueries = payload.data.remainingMonthlyQueries
        if (payload.data.querySettings.updateTeamStats === 'true') {
          state.data.leadGenerations = payload.data.newTeamStats
          if (payload.data.querySettings.updateLastQuery === 'true') {
            state.data.query.lastQuery = payload.data.lastQueryLabel
            state.data.query.nextQuery = payload.data.nextQueryLabel
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordUploadPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.newTotalSuccessfulQueries) {
        state.data.leadGenerations.totalSuccessfulQueries = payload.data.newTotalSuccessfulQueries
      }
      if (payload.data.newTotalLeadsGenerated) {
        state.data.leadGenerations.totalLeadsGenerated = payload.data.newTotalLeadsGenerated
        state.data.leadGenerations.totalTier1Leads = state.data.query.totalTier1Leads + payload.data.newTotalLeadsGenerated
      }
      if (payload.data.newTotalHits) {
        state.data.leadGenerations.totalHits = payload.data.newTotalHits
      }
      if (payload.data.newTotalHitsAvgPercent) {
        state.data.leadGenerations.totalHitsAvgPercent = payload.data.newTotalHitsAvgPercent
      }
      if (payload.data.remainingMonthlyQueries) {
        state.data.query.remainingMonthlyQueries = payload.data.remainingMonthlyQueries
      }
    })
    .addMatcher(apiSlice.endpoints.recordSweepQuery.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data && payload.data.isSingleScan) {
        state.data.query.remainingMonthlyQueries = state.data.query.remainingMonthlyQueries - 1
      }
    })
    .addMatcher(apiSlice.endpoints.runPropertySearch.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.query.remainingMonthlyQueries = state.data.query.remainingMonthlyQueries - 1
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      state.data.dismissedLeads = payload.data.totalTeamDismissedLeads
      state.data.pieCharts.actionType[1] = {name: `${payload.data.teamStats.totalDismissed}`, size: parseInt(`${payload.data.teamStats.totalDismissed}`)}
      state.data.pieCharts.tier1[1] = {name: `${payload.data.teamStats.tier1Dismissed}`, size: parseInt(`${payload.data.teamStats.tier1Dismissed}`)}
      state.data.pieCharts.tier2[1] = {name: `${payload.data.teamStats.tier2Dismissed}`, size: parseInt(`${payload.data.teamStats.tier2Dismissed}`)}
      state.data.pieCharts.manual[1] = {name: `${payload.data.teamStats.manualDismissed}`, size: parseInt(`${payload.data.teamStats.manualDismissed}`)}
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      if (payload.data.reportCreated) {
        if (payload.data.targetOutcome.toUpperCase() === 'RENEGOTIATION') {
          state.data.renegotiations.closedRenegotiations = payload.data.teamStats.closedRenegotiations;
          state.data.renegotiations.manualRenegotiations = payload.data.teamStats.manualRenegotiations;
          state.data.renegotiations.lastRenegotiation = payload.data.teamStats.lastRenegotiation;
          state.data.renegotiations.renegotiationFrequency = payload.data.teamStats.renegotiationFrequency;
          state.data.pieCharts.closureType[0] = {name: `Renegotiations: ${payload.data.teamStats.closedRenegotiations}`, size: parseInt(`${payload.data.teamStats.closedRenegotiations}`)}
          if (payload.data.leadTier === 1) {
            state.data.pieCharts.renegotiations[0] = {name: `${payload.data.teamStats.tier1Renegotiations}`, size: parseInt(`${payload.data.teamStats.tier1Renegotiations}`)}
          } else if (payload.data.leadTier === 2) {
            state.data.pieCharts.renegotiations[1] = {name: `${payload.data.teamStats.tier2Renegotiations}`, size: parseInt(`${payload.data.teamStats.tier2Renegotiations}`)}
          } else {
            state.data.pieCharts.renegotiations[2] = {name: `${payload.data.teamStats.manualRenegotiations}`, size: parseInt(`${payload.data.teamStats.manualRenegotiations}`)}
          }
        } else {
          state.data.refinances.closedRefinances = payload.data.teamStats.closedRefinances;
          state.data.refinances.manualRefinances = payload.data.teamStats.manualRefinances;
          state.data.refinances.lastRefinance = payload.data.teamStats.lastRefinance;
          state.data.refinances.refinanceFrequency = payload.data.teamStats.refinanceFrequency;
          state.data.refinances.grossProfitNumber = payload.data.teamStats.grossProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0});
          state.data.refinances.grossProfitPercent = payload.data.teamStats.grossProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0});
          state.data.refinances.averageProfitNumber = payload.data.teamStats.averageProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0});
          state.data.refinances.averageProfitPercent = payload.data.teamStats.averageProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0});
          state.data.pieCharts.closureType[1] = {name: `Refinances: ${payload.data.teamStats.closedRefinances}`, size: parseInt(`${payload.data.teamStats.closedRefinances}`)}
          if (payload.data.leadTier === 1) {
            state.data.pieCharts.refinances[0] = {name: `${payload.data.teamStats.tier1Refinances}`, size: parseInt(`${payload.data.teamStats.tier1Refinances}`)}
          } else if (payload.data.leadTier === 2) {
            state.data.pieCharts.refinances[1] = {name: `${payload.data.teamStats.tier2Refinances}`, size: parseInt(`${payload.data.teamStats.tier2Refinances}`)}
          } else {
            state.data.pieCharts.refinances[2] = {name: `${payload.data.teamStats.manualRefinances}`, size: parseInt(`${payload.data.teamStats.manualRefinances}`)}
          }
        }
        state.data.pieCharts.actionType[0] = {name: `${payload.data.teamStats.totalClosures}`, size: parseInt(`${payload.data.teamStats.totalClosures}`)}
        state.data.pieCharts.tier1[0] = {name: `${payload.data.teamStats.tier1Closures}`, size: parseInt(`${payload.data.teamStats.tier1Closures}`)}
        state.data.pieCharts.tier2[0] = {name: `${payload.data.teamStats.tier2Closures}`, size: parseInt(`${payload.data.teamStats.tier2Closures}`)}
        state.data.pieCharts.manual[0] = {name: `${payload.data.teamStats.manualClosures}`, size: parseInt(`${payload.data.teamStats.manualClosures}`)}
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      if (payload.data.teamStats.outcome === 'renegotiation') {
        state.data.renegotiations.closedRenegotiations = payload.data.teamStats.closedRenegotiations;
        state.data.renegotiations.tier1Renegotiations = payload.data.teamStats.tier1Renegotiations;
        state.data.renegotiations.tier2Renegotiations = payload.data.teamStats.tier2Renegotiations;
        state.data.renegotiations.manualRenegotiations = payload.data.teamStats.manualRenegotiations;
        state.data.renegotiations.lastRenegotiation = payload.data.teamStats.lastRenegotiation;
        state.data.renegotiations.renegotiationFrequency = payload.data.teamStats.renegotiationFrequency;
        state.data.pieCharts.closureType[0] = {name: `Renegotiations: ${payload.data.teamStats.closedRenegotiations}`, size: parseInt(`${payload.data.teamStats.closedRenegotiations}`)}
        state.data.pieCharts.renegotiations[0] = {name: `${payload.data.teamStats.tier1Renegotiations}`, size: parseInt(`${payload.data.teamStats.tier1Renegotiations}`)}
        state.data.pieCharts.renegotiations[1] = {name: `${payload.data.teamStats.tier2Renegotiations}`, size: parseInt(`${payload.data.teamStats.tier2Renegotiations}`)}
        state.data.pieCharts.renegotiations[2] = {name: `${payload.data.teamStats.manualRenegotiations}`, size: parseInt(`${payload.data.teamStats.manualRenegotiations}`)}
      } else {
        state.data.refinances.closedRefinances = payload.data.teamStats.closedRefinances;
        state.data.refinances.tier1Refinances = payload.data.teamStats.tier1Refinances;
        state.data.refinances.tier2Refinances = payload.data.teamStats.tier2Refinances;
        state.data.refinances.manualRefinances = payload.data.teamStats.manualRefinances;
        state.data.refinances.lastRefinance = payload.data.teamStats.lastRefinance;
        state.data.refinances.refinanceFrequency = payload.data.teamStats.refinanceFrequency;
        state.data.refinances.grossProfitNumber = payload.data.teamStats.grossProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0});
        state.data.refinances.grossProfitPercent = payload.data.teamStats.grossProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0});
        state.data.refinances.averageProfitNumber = payload.data.teamStats.averageProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0});
        state.data.refinances.averageProfitPercent = payload.data.teamStats.averageProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0});
        state.data.pieCharts.closureType[1] = {name: `Refinances: ${payload.data.teamStats.closedRefinances}`, size: parseInt(`${payload.data.teamStats.closedRefinances}`)}
        state.data.pieCharts.refinances[0] = {name: `${payload.data.teamStats.tier1Refinances}`, size: parseInt(`${payload.data.teamStats.tier1Refinances}`)}
        state.data.pieCharts.refinances[1] = {name: `${payload.data.teamStats.tier2Refinances}`, size: parseInt(`${payload.data.teamStats.tier2Refinances}`)}
        state.data.pieCharts.refinances[2] = {name: `${payload.data.teamStats.manualRefinances}`, size: parseInt(`${payload.data.teamStats.manualRefinances}`)}
      }
      state.data.pieCharts.actionType[0] = {name: `${payload.data.teamStats.totalClosures}`, size: parseInt(`${payload.data.teamStats.totalClosures}`)}
      state.data.pieCharts.tier1[0] = {name: `${payload.data.teamStats.tier1Closures}`, size: parseInt(`${payload.data.teamStats.tier1Closures}`)}
      state.data.pieCharts.tier2[0] = {name: `${payload.data.teamStats.tier2Closures}`, size: parseInt(`${payload.data.teamStats.tier2Closures}`)}
      state.data.pieCharts.manual[0] = {name: `${payload.data.teamStats.manualClosures}`, size: parseInt(`${payload.data.teamStats.manualClosures}`)}
    })
    .addMatcher(apiSlice.endpoints.reloadRenegotiationQuarterlyBreakdown.matchFulfilled, (state, {payload}) => {
      if (payload.data.actionTaken === 'closed') {
        state.data.pieCharts.actionType[0] = payload.data.newPieChartTwo
        state.data.pieCharts.closureType[0] = payload.data.newPieChartOne
        if (payload.data.leadTier === 1) {
          state.data.pieCharts.tier1[0] = payload.data.newPieChartThree
        } else if (payload.data.leadTier === 2) {
          state.data.pieCharts.tier2[0] = payload.data.newPieChartThree
        } else {
          state.data.pieCharts.manual[0] = payload.data.newPieChartThree
        }
      } else if (payload.data.actionTaken === 'dismissed') {
        state.data.pieCharts.actionType[1] = payload.data.newPieChartTwo
        if (payload.data.leadTier === 1) {
          state.data.pieCharts.tier1[1] = payload.data.newPieChartThree
        } else if (payload.data.leadTier === 2) {
          state.data.pieCharts.tier2[1] = payload.data.newPieChartThree
        } else {
          state.data.pieCharts.manual[1] = payload.data.newPieChartThree
        }
      }
    })
    .addMatcher(apiSlice.endpoints.reloadRefinanceQuarterlyBreakdown.matchFulfilled, (state, {payload}) => {
      if (payload.data.actionTaken === 'closed') {
        state.data.pieCharts.actionType[0] = payload.data.newPieChartTwo
        state.data.pieCharts.closureType[1] = payload.data.newPieChartOne
        if (payload.data.leadTier === 1) {
          state.data.pieCharts.tier1[0] = payload.data.newPieChartThree
        } else if (payload.data.leadTier === 2) {
          state.data.pieCharts.tier2[0] = payload.data.newPieChartThree
        } else {
          state.data.pieCharts.manual[0] = payload.data.newPieChartThree
        }
      } else if (payload.data.actionTaken === 'dismissed') {
        state.data.pieCharts.actionType[1] = payload.data.newPieChartTwo
        if (payload.data.leadTier === 1) {
          state.data.pieCharts.tier1[1] = payload.data.newPieChartThree
        } else if (payload.data.leadTier === 2) {
          state.data.pieCharts.tier2[1] = payload.data.newPieChartThree
        } else {
          state.data.pieCharts.manual[1] = payload.data.newPieChartThree
        }
      }
    })
  }
})

export const { addCustomTagView, editCustomTagView, removeCustomTagView, decrementTeamRemainingMonthlyQueries } = teamStatsSlice.actions

export default teamStatsSlice.reducer

export const selectTeamQueryStats = state => state.teamStats.data.query
export const selectTeamRefinances = state => state.teamStats.data.refinances
export const selectTier2PieChartData = state => state.teamStats.data.pieCharts.tier2
export const selectTier1PieChartData = state => state.teamStats.data.pieCharts.tier1
export const selectTagsViewMortgages = state => state.teamStats.data.tagsViewMortgages
export const selectTeamRenegotiations = state => state.teamStats.data.renegotiations
export const selectManualPieChartData = state => state.teamStats.data.pieCharts.manual
export const selectCustomTagViewAdded = state => state.teamStats.data.customTagViewAdded
export const selectNextRecordSweepDate = state => state.teamStats.data.query.nextQuery
export const selectTeamClosedRefinances = state => state.teamStats.data.refinances.closedRefinances
export const selectRefinancesPieChartData = state => state.teamStats.data.pieCharts.refinances
export const selectTotalSuccessfulQueries = state => state.teamStats.data.leadGenerations.totalSuccessfulQueries
export const selectActionTypePieChartData = state => state.teamStats.data.pieCharts.actionType
export const selectTeamLeadGenerationStats = state => state.teamStats.data.leadGenerations
export const selectClosureTypePieChartData = state => state.teamStats.data.pieCharts.closureType
export const selectTeamClosedRenegotiations = state => state.teamStats.data.renegotiations.closedRenegotiations
export const selectRenegotiationsPieChartData = state => state.teamStats.data.pieCharts.renegotiations
export const selectTeamRemainingMonthlyQueries = state => state.teamStats.data.query.remainingMonthlyQueries