import React from "react";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { AccentInfo, FlexWrapper } from "../../../../../StyledComponents";
import { selectTeamQueryStats } from "../../../../../slices/teamStatsSlice";

const QuickStats = () => {
  const theme = useTheme();
  const teamQueries = useSelector(selectTeamQueryStats)

  return (
    <div style={{width: '100%', height: '100%', gridRow: '3', gridColumn: '1', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderRight: `0.1rem solid #c8cbcf`}}>
      <div style={{width: '100%', height: '100%', padding: '1rem 2rem 0 2rem'}}>
        <FlexWrapper className="alignCenter justifyBetween fitContentHeight" style={{padding: '2.5rem 0 0 0'}}>
          <div style={{color: `${theme.palette.common.grayText}`}}>
            Last Record Sweep:
          </div>
          <div style={{color: `${theme.palette.common.black}`, justifyContent: 'flex-end'}}>
            {teamQueries.lastQuery}
          </div>
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyBetween fitContentHeight" style={{padding: '2.5rem 0 0 0'}}>
          <div style={{color: `${theme.palette.common.grayText}`}}>
            Manual Sweeps Remaining:
          </div>
          <div style={{color: `${theme.palette.common.black}`, justifyContent: 'flex-end'}}>
            <AccentInfo>Unlimited</AccentInfo>
          </div>
        </FlexWrapper>
      </div>
    </div>
  )
}

export default QuickStats;