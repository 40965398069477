import React, { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useSelector } from "react-redux";
import AddAssigneesInput from "./AddAssigneesInput";
import { selectAddAssigneesPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { Button, ButtonWrapper, FlexWrapper, ModalContentContainer, ComponentHeader, Typography } from "../../../../../StyledComponents";

const AddAssignees = ({ teamMembersByName, handleCloseModal, activeLead, leadData, mortgageData, userId, userFullName, leadUserNotifications }) => {

  const addAssigneesPerm = useSelector(selectAddAssigneesPerm)

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Something went wrong. Please try again or contact support@lancastersweep.com</ComponentHeader>
      :
        <ComponentHeader className="modal">Manage Assignees</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions" style={{padding: '0'}}>
        {activeLead ?
          <FlexWrapper className="column jusitfyCenter alignCenter fitContentHeight" style={{marginTop: '4rem'}}>
            <Typography className="small fitContentWidth alignTextCenter">
              Select one or more team members to assign.<br/>
              Team members assigned to the lead will receive all updates regarding the investigation.<br/>
              Those only assigned to the mortgage will be notfied when the investigation<br/>
              is closed or when the mortgage's internal records are updated.
            </Typography>
          </FlexWrapper>
        :
          <FlexWrapper className="column jusitfyCenter alignCenter fitContentHeight" style={{marginTop: '4rem'}}>
            <Typography className="small fitContentWidth alignTextCenter">
              Select one or more team members to assign to the mortgage.<br/>
              Assignees are notified when changes to the public records are detected, or<br/>
              when the mortgage's internal records are updated.
            </Typography>
          </FlexWrapper>
        }
        {activeLead ?
          <FlexWrapper className="fitContentHeight column" style={{marginTop: '8rem'}}>
            <AddAssigneesInput entity={'lead'} currentAssigneeIds={leadData.assigneeIds} leadId={leadData._id} userFullName={userFullName} mortgageId={mortgageData._id} leadUserNotifications={leadUserNotifications} activeLead={activeLead} addAssigneesPerm={addAssigneesPerm} teamMembersByName={teamMembersByName} setIsError={setIsError} setIsSuccess={setIsSuccess} isSuccess={isSuccess} userId={userId} />
            <AddAssigneesInput entity={'mortgage'} currentAssigneeIds={mortgageData.assigneeIds} leadId={leadData._id} mortgageId={mortgageData._id} userFullName={userFullName} leadUserNotifications={leadUserNotifications} activeLead={activeLead} addAssigneesPerm={addAssigneesPerm} teamMembersByName={teamMembersByName} setIsError={setIsError} setIsSuccess={setIsSuccess} isSuccess={isSuccess} userId={userId} />
          </FlexWrapper>
        :
          <FlexWrapper className="fitContentHeight column" style={{marginTop: '8rem'}}>
            <AddAssigneesInput entity={'mortgage'} currentAssigneeIds={mortgageData.assigneeIds} leadId={leadData._id} mortgageId={mortgageData._id} userFullName={userFullName} leadUserNotifications={leadUserNotifications} activeLead={activeLead} addAssigneesPerm={addAssigneesPerm} teamMembersByName={teamMembersByName} setIsError={setIsError} setIsSuccess={setIsSuccess} isSuccess={isSuccess} userId={userId} />
          </FlexWrapper>
        }
        <ButtonWrapper className="justifyCenter" style={{marginTop: '4rem'}}>
          <Button 
            className='neutral'
            onClick={() => handleCloseModal()}
          >
            Close
          </Button>
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default AddAssignees;