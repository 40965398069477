import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    auth: {
      launchTeam: false,
      login: false,
    },
    previewTargetChanges: false,
    saveFinancialInformation: false,
    updateDefaultTargets: false,
  },
  status: 'idle',
  error: null
}

export const functionAvailabilitySlice = createSlice({
  name: 'functionAvailability',
  initialState,
  reducers: {
    setUpdateDefaultTargetsEnabled(state, action) { state.data.updateDefaultTargets = action.payload },
    setLaunchTeamEnabled(state, action) { state.data.auth.launchTeam = action.payload },
    setLoginEnabled(state, action) { state.data.auth.login = action.payload },
    setPreviewTargetChangesEnabled(state, action) { state.data.previewTargetChanges = action.payload },
    setSaveFinancialInformationEnabled(state, action) { state.data.saveFinancialInformation = action.payload },
  },
  extraReducers: (builder) => {builder
    .addMatcher(apiSlice.endpoints.loginReturningUser.matchFulfilled, (state, {payload}) => {
      if (payload.message.includes('successful')) {
        state.data.auth.login = false
      }
    })
    .addMatcher(apiSlice.endpoints.createGuestAccount.matchFulfilled, (state, {payload}) => {
      state.data.updateDefaultTargets = false
    })
    .addMatcher(apiSlice.endpoints.establishRequestCredentials.matchFulfilled, (state, {payload}) => {
      if (payload.data.existingTeam) {
        state.data.auth.launchTeam = false
      }
    })
  }
})

export const { setPreviewTargetChangesEnabled, setSaveFinancialInformationEnabled, setUpdateDefaultTargetsEnabled, setLaunchTeamEnabled, setLoginEnabled } = functionAvailabilitySlice.actions

export default functionAvailabilitySlice.reducer

export const selectLoginEnabled = state => state.functionAvailability.data.auth.login
export const selectLaunchTeamEnabled = state => state.functionAvailability.data.auth.launchTeam
export const selectUpdateDefaultTargetsEnabled = state => state.functionAvailability.data.updateDefaultTargets
export const selectPreviewTargetChangesEnabled = state => state.functionAvailability.data.previewTargetChanges
export const selectSaveFinancialInformationEnabled = state => state.functionAvailability.data.saveFinancialInformation