import React from "react"
import IconLogo from '../icon.logo.black.png';
import LoadingLogo from '../icon.logo.green.png';
import { useTheme } from "@mui/styles";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { useSelector } from "react-redux";
import { selectIPError } from "../slices/sessionDataSlice";
import { FlexWrapper, ImageElements, PageContainer, ScreenSaverContainer } from "../StyledComponents"
import { Navigate } from "react-router-dom";

const ScreenSaver = () => {
  const theme = useTheme()

  const ipError = useSelector(selectIPError)

  return (
    <PageContainer className="screenSaver">
      <Navigate to="/loading" />
      <ScreenSaverContainer className="screenSaver fadeIn">
        <ImageElements src={IconLogo} className="centerLogo opaque" style={{height: '40rem', width: '40rem'}} />
        {ipError ?
          <FlexWrapper className="column alignCenter fitContentHeight fitContentWidth">
            <FlexWrapper className="fitContentHeight fitContentWidth">
              <GppMaybeIcon style={{color: `${theme.palette.common.cautionDarkest}`, height: '8rem', width: '8rem'}} />
              <span style={{fontSize: '5rem', color: `${theme.palette.common.cautionDarkest}`, zIndex: '101', margin: 'auto 0'}}>Your IP Address Could Not be Read</span>
            </FlexWrapper>
            <span style={{fontSize: '3rem', color: `${theme.palette.common.grayText}`, zIndex: '101', margin: 'auto 0'}}>try refreshing your browser</span>
          </FlexWrapper>
        :
          <>
            <ImageElements src={LoadingLogo} className="centerLogo loading" style={{right: '10rem', animationDelay: '0.5s'}} />
            <ImageElements src={LoadingLogo} className="centerLogo loading" style={{right: '5rem', animationDelay: '0.7s'}} />
            <ImageElements src={LoadingLogo} className="centerLogo loading" style={{animationDelay: '0.9s'}} />
            <ImageElements src={LoadingLogo} className="centerLogo loading" style={{left: '5rem', animationDelay: '1.1s'}} />
            <ImageElements src={LoadingLogo} className="centerLogo loading" style={{left: '10rem', animationDelay: '1.3s'}} />
          </>
        }
      </ScreenSaverContainer>
    </PageContainer>
  )
}

export default ScreenSaver