import React, { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import AddAssigneesInput from "./AddAssigneesInput";
import { selectAddAssigneesPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { Button, ButtonWrapper, FlexWrapper, ModalContentContainer, ComponentHeader, Typography } from "../../../../../StyledComponents";

const AddAssignees = ({ teamMembersByName, handleCloseModal, activeLead, leadData, mortgageData, userId, userFullName }) => {
  const theme = useTheme()

  const addAssigneesPerm = useSelector(selectAddAssigneesPerm)

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
            <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
            Success
          </span>
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        <ComponentHeader className="modal">Manage Assignees</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions" style={{padding: '0'}}>
        <FlexWrapper className="column jusitfyCenter alignCenter fitContentHeight" style={{marginTop: '4rem'}}>
          <Typography className="small fitContentWidth alignTextCenter">
            Select one or more team members to assign.<br/>
            Team members assigned to the lead will receive all updates regarding the investigation.<br/>
          </Typography>
        </FlexWrapper>
        <FlexWrapper className="fitContentHeight column" style={{marginTop: '8rem'}}>
          <AddAssigneesInput entity={'lead'} currentAssigneeIds={leadData.assigneeIds} leadData={leadData} mortgageId={mortgageData._id} userFullName={userFullName} activeLead={activeLead} addAssigneesPerm={addAssigneesPerm} teamMembersByName={teamMembersByName} setIsError={setIsError} setIsSuccess={setIsSuccess} isSuccess={isSuccess} userId={userId} />
        </FlexWrapper>
        <ButtonWrapper className="justifyCenter" style={{marginTop: '4rem'}}>
          <Button 
            className='neutral'
            onClick={() => handleCloseModal()}
          >
            Close
          </Button>
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default AddAssignees;