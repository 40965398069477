import React , {useState} from "react";
import { useTheme } from "@mui/styles";
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MuiBadge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import SellIcon from '@mui/icons-material/Sell';
import AdjustIcon from '@mui/icons-material/Adjust';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditNoteIcon from '@mui/icons-material/EditNote';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChangeTargetOutcome from "../../../../../../components/Modals/LeadActions/Components/ChangeTargetOutcome.js";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CardButton, ActionMenuItem, ActionMenuTitle, Badge, NewLabel, CardIndex, AddButton, FlexWrapper, Typography, ActionIconWrapper, GridWrapper, NavText, Divider } from "../../../../../../StyledComponents";

const InvestigatingCard = ({ lead, userId, userFullName, dashPerspective, handleOpenLeadActionsModal, cardIndex }) => {
  const theme = useTheme();

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.common.error}`,
      fontWeight: '800',
      fontSize: "1rem",
      height: "1.4rem",
      padding: "0 0.5rem",
      borderRadius: "1rem",
      minWidth: "1.2rem",
    }
  }

  const [changeOutcomeDetails, setChangeOutcomeDetails] = useState({})

  const [menuActive, setMenuActive] = useState(false)
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const openActionsMenu = Boolean(actionMenuAnchorEl);
  const [outcomeMenuAnchorEl, setOutcomeMenuAnchorEl] = useState(null);
  const openOutcomeMenu = Boolean(outcomeMenuAnchorEl);

  const handleOpenActions = (event) => {
    setActionMenuAnchorEl(event.currentTarget);
    setMenuActive(true);
  };
  const handleCloseActions = () => {
    setActionMenuAnchorEl(null)
    setMenuActive(false)
    setOutcomeMenuAnchorEl(null)
  };
  
  const handleOpenOutcomeMenu = (event, leadId, timeline, oldOutcome) => {
    let newOutcome = ''
    if (oldOutcome === 'renegotiation') {
      newOutcome = 'refinance'
    } else {
      newOutcome = 'renegotiation'
    }
    setChangeOutcomeDetails({
      leadId: leadId, 
      timeline: timeline,
      newOutcome: newOutcome,
    })
    setOutcomeMenuAnchorEl(event.currentTarget);
  }
  const handleCloseOutcomeMenu = () => {
    setOutcomeMenuAnchorEl(null)
    setActionMenuAnchorEl(null)
    setMenuActive(false)
  };

  return (
    <>
      <CardIndex>
        {cardIndex+1}
      </CardIndex>
      <div style={{display: 'flex', justifyContent: 'space-between', width: '6rem', margin: '0 0 0 0.5rem'}}>
        <CardButton
          className={lead.tier === 0 ? 'tier manualInvestigation' : lead.tier === 1 ? 'tier one' : lead.tier === 2 ? 'tier two' : 'tier three'}
          variant="contained"
          disableElevation
          onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.belongsToMortgage)}
        >
          {lead.tier === 0 || lead.tier === 5 ?
            <span style={{transform: "rotate(-90deg)", display: "flex", textWrap: 'nowrap', fontSize: '1.2rem', alignItems: 'center', justifyContent: 'center'}}>
              Manual Investigation
              <OpenInNewIcon style={{padding: "0.2rem 0 0.1rem 0"}} />
            </span>
          :
            <span style={{transform: "rotate(-90deg)", display: "flex", alignItems: 'center', justifyContent: 'center'}}>
              {lead.belongsToMortgage.activeDiscrepancies > 0 ?
                <Badge className="one">{lead.belongsToMortgage.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              {lead.tier === 1 ?
                <span style={{padding: "0 0 0 0.2rem"}}>1</span>
              : lead.tier === 2 ?
                <span style={{padding: "0 0 0 0.2rem"}}>2</span>
              :
                <span style={{padding: "0 0 0 0.2rem"}}>3</span>
              }
              <OpenInNewIcon style={{padding: "0.1rem 0 0.1rem 0"}} />
            </span>
          }
        </CardButton>
        <CardButton
          variant="contained"
          disableElevation
          onClick={handleOpenActions}
          className={`${menuActive ? "active" : "actions"}`}
          style={{marginLeft: '0.3rem'}}
        >
          <span style={{transform: "rotate(-90deg)", display: "flex", alignItems: 'center'}}>
            ACTIONS
            {lead.userNotifications > 0 ?
              <MuiBadge 
              badgeContent={lead.userNotifications} 
              color="error" 
              sx={badgeStyle}   
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              >
                <KeyboardArrowDownIcon style={{padding: "0"}} />
              </MuiBadge>
            :
              <KeyboardArrowDownIcon style={{padding: "0"}} />
            }
          </span>
        </CardButton>
        <StyledMenu
          anchorEl={actionMenuAnchorEl}
          open={openActionsMenu}
          onClose={handleCloseActions}
        >
          {lead.belongsToMortgage.reports.length === 0 ?
            <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius" style={{background: `${theme.palette.common.disabledGray}`}}>
              <ActionMenuItem className="noHover topRadius">
                <SummarizeIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Reports ({lead.belongsToMortgage.reports.length})</ActionMenuTitle>
              </ActionMenuItem>
            </MenuItem>
          :
            <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius">
              <ActionMenuItem onClick={() => handleOpenLeadActionsModal('reports', lead, lead.belongsToMortgage)}>
                <SummarizeIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Reports ({lead.belongsToMortgage.reports.length})</ActionMenuTitle>
              </ActionMenuItem>
            </MenuItem>
          }
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('notes', lead, lead.belongsToMortgage)}>
              <EditNoteIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Notes ({lead.belongsToMortgage.mortgageNotes.length})</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.belongsToMortgage)}>
              <FileCopyIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Compare Records</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('propertyDetails', lead, lead.belongsToMortgage)}>
              <HomeWorkIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Property Details</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          {!lead.belongsToMortgage.financialsPresent &&
            <MenuItem onClick={handleCloseActions} disableRipple className="border" style={{background: '#FFF4E6'}}>
              <ActionMenuItem onClick={() => handleOpenLeadActionsModal('provideFinancialsCard', lead, lead.belongsToMortgage)}>
                <AttachMoneyIcon style={{color: `${theme.accent.caution.primary}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Provide Financials</ActionMenuTitle>
              </ActionMenuItem>
              <NavText className="label" style={{margin: '0 0 1.1rem 0.4rem', color: `${theme.accent.caution.primary}`}}>ToDo</NavText>
            </MenuItem>
          }
          <MenuItem onClick={(e) => handleOpenOutcomeMenu(e, lead._id, lead.timeline, lead.targetOutcome)} disableRipple className="border">
            <ActionMenuItem>
              <FlagCircleIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              {lead.targetOutcome === 'renegotiation' ?
                  <ActionMenuTitle>Switch to Refinance</ActionMenuTitle>
                :
                  <ActionMenuTitle>Switch to Renegotiation</ActionMenuTitle>
              }
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('editTargets', lead, lead.belongsToMortgage)}>
              <AdjustIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Change Targets</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.belongsToMortgage)}>
              <SellIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Manage Tags</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('addAssignees', lead, lead.belongsToMortgage)}>
              <PersonAddIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Manage Assignees</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions}  className="button buttonTop" disableRipple style={{cursor: "default", justifyContent: 'center'}}>
            <ActionMenuItem 
              className="advance" 
              style={{width: '14rem', margin: '0'}} 
              onClick={() => handleOpenLeadActionsModal('beginClosingLead', lead, lead.belongsToMortgage)}
            >
              <ActionMenuTitle className="white">Begin Closing</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} className="button bottomRadius" disableRipple style={{cursor: "default", justifyContent: 'center'}}>
            <ActionMenuItem 
              className="dismiss" 
              style={{width: '14rem', margin: '0'}} 
              onClick={() => handleOpenLeadActionsModal('dismissLead', lead, lead.belongsToMortgage)}
            >              
              <ActionMenuTitle className="white">Dismiss</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
        </StyledMenu>
        <StyledMenu
          anchorEl={outcomeMenuAnchorEl}
          open={openOutcomeMenu}
          onClose={handleCloseOutcomeMenu}
        >
          <ChangeTargetOutcome userId={userId} userFullName={userFullName} changeOutcomeDetails={changeOutcomeDetails} dashPerspective={dashPerspective} />
        </StyledMenu>
      </div>

      <FlexWrapper className="column">
        <GridWrapper style={{gridTemplateColumns: '22rem 1fr 24rem'}}>
          <FlexWrapper className="column"  style={{gridColumn: '1', width: '22rem', minWidth: '22rem', paddingLeft: '1rem'}}>
            <FlexWrapper className="fitContentHeight">
              {lead.newAssignmentNotification || (lead.notifCount && lead.notifCount !== 0) ?
                <NewLabel className={((lead.notifCount && lead.notifCount !== 0) ? "" : "inactive") + " notification hover"} onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.belongsToMortgage)} style={{padding: lead.notifCount && lead.notifCount !== 0 ? " 0.1rem 0.1rem 0.1rem 0.3rem" : " 0.1rem 0.5rem 0.1rem 0.3rem"}}>
                  <NotificationsIcon style={{color: (lead.notifCount && lead.notifCount !== 0) ? `${theme.notification.color.active}` : `${theme.notification.color.inactive}`}} />
                  {(lead.notifCount && lead.notifCount !== 0) &&
                    <Badge className="cardNotif" style={{height: '1.5rem', width: '1.5rem'}}>
                      {lead.notifCount}
                    </Badge>
                  }
                  <span style={{position: 'relative', right: lead.notifCount && lead.notifCount !== 0 ? '0.5rem' : '0'}}>
                    {lead.newAssignmentNotification ? 
                      `Assigned`
                    :
                      `Updates`
                    }
                  </span>
                </NewLabel>
              : lead.newLeadLabel ?
                <NewLabel className="new hover" onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.belongsToMortgage)}>
                  <FlagCircleIcon style={{color: `${theme.palette.primary.dark}`}} />
                  New
                </NewLabel>
              :
                <></>
              }
              {lead.targetOutcome === "renegotiation" ?
                <Typography className="outcome renegotiation autoMarginHorizontal" style={{margin: lead.notifCount && lead.notifCount !== 0 ? '0 0.5rem' : '0 auto'}}>
                  Renegotiation
                </Typography>
              :
                <Typography className="outcome refinance autoMarginHorizontal" style={{margin: lead.notifCount && lead.notifCount !== 0 ? '0 0.5rem' : '0 auto'}}>
                  Refinance
                </Typography>
              }
            </FlexWrapper>
            <FlexWrapper className="alignCenter fitContentHeight" style={{marginTop: '0.5rem'}}>
              <FlexWrapper className="column alignEnd justifyStart" style={{height: '100%'}}>
                {lead.tier !== 0 &&
                  <Typography className="tinyHeight tiny">Date Discovered:</Typography>
                }
                <Typography className="tinyHeight tiny">Began Investigation:</Typography>
                <Typography className="tinyHeight tiny">Active Discrepancies:</Typography>
                <Typography className="tinyHeight tiny">Inactive Discrepancies:</Typography>
                {lead.assigneeNames.length === 1 ?
                  <Typography className="tinyHeight tiny">Assignee:</Typography>
                :
                  <Typography className="tinyHeight tiny">Assignees:</Typography>
                }        
              </FlexWrapper>
              <FlexWrapper className="column" style={{paddingLeft: '0.5rem'}}>
                {lead.tier !== 0 &&
                  <Typography className="bold tiny">
                    {lead.dateDiscoveredLabel}
                  </Typography>
                }
                <Typography className="bold tiny">
                  {lead.dateInvestigating}
                </Typography>
                {lead.belongsToMortgage.activeDiscrepancies > 0 ?
                  <Typography className="bold tiny">
                    {lead.belongsToMortgage.activeDiscrepancies}
                  </Typography>
                :
                  <Typography className="tiny accent">-none-</Typography>
                }
                {lead.belongsToMortgage.rejectedDiscrepancies === 0 && lead.belongsToMortgage.resolvedDiscrepancies === 0 ?
                  <Typography className="tiny accent">-none-</Typography>
                : lead.belongsToMortgage.rejectedDiscrepancies === 0 && lead.belongsToMortgage.resolvedDiscrepancies > 0 ?
                  <Typography className="bold tiny">
                    {lead.belongsToMortgage.resolvedDiscrepancies} resolved
                  </Typography>
                : lead.belongsToMortgage.rejectedDiscrepancies > 0 && lead.belongsToMortgage.resolvedDiscrepancies === 0 ?
                  <Typography className="bold tiny">
                    {lead.belongsToMortgage.rejectedDiscrepancies} rejected
                  </Typography>
                :
                  <Typography className="bold tiny">
                    {lead.belongsToMortgage.resolvedDiscrepancies} resolved, {lead.belongsToMortgage.rejectedDiscrepancies} rejected
                  </Typography>
                }
                {lead.assigneeNames.length === 0 ?
                  <Typography className="tiny accent">-none-</Typography>
                : lead.assigneeNames.length === 1 ?
                  <Typography className="bold tiny">
                    {lead.assigneeNames[0]}
                  </Typography>
                :
                  lead.assigneeNames.map((assignee, index) => (
                    <Typography className="bold tiny" key={assignee}>{assignee}</Typography>
                  ))
                }
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>

          <FlexWrapper className="justifyAround" style={{gridColumn: '2'}}>
            <FlexWrapper className="column fitContentWidth">
              <Typography className="subHeader dark autoMarginHorizontal">Mortgage Info</Typography>
              <FlexWrapper className="alignCenter fitContentHeight" style={{marginTop: '0.2rem'}}>
                <FlexWrapper className="column alignEnd justifyBetween">
                  <Typography className="tinyHeight tiny">Address:</Typography>
                  <Typography className="tinyHeight"></Typography>
                  {lead.belongsToMortgage.owner1 && lead.belongsToMortgage.owner2 ?
                    <>
                      <Typography className="tinyHeight tiny">Borrowers:</Typography>
                      <Typography className="tinyHeight"></Typography>
                    </>
                  : lead.belongsToMortgage.owner1 || lead.belongsToMortgage.owner2 ?
                    <Typography className="tinyHeight tiny">Borrower:</Typography>
                  :
                    <Typography className="tinyHeight tiny">Borrowers:</Typography>
                  }
                  {lead.belongsToMortgage.originationDateLabel.length > 0 && lead.belongsToMortgage.endDateLabel.length === 0 ?
                    <Typography className="tinyHeight tiny">Originated:</Typography>
                  : lead.belongsToMortgage.endDateLabel.length > 0 && !lead.belongsToMortgage.originationDateLabel.length > 0 ?
                    <Typography className="tinyHeight tiny">Terminates:</Typography>
                  : 
                    <Typography className="tinyHeight tiny">Term:</Typography>
                  }
                  <Typography className="tinyHeight tiny">Remaining:</Typography>           
                </FlexWrapper>
                <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem'}}>
                  {lead.belongsToMortgage.streetAddress.length > 0 ?
                    <>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.streetAddress}
                      </Typography>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.recordDetails.address.City.currentValue}, {lead.belongsToMortgage.recordDetails.address.StateOrProvince.currentValue} {lead.belongsToMortgage.recordDetails.address.PostalCode.currentValue}
                      </Typography>
                    </>
                  :
                    <>
                      <Typography className="tiny accent">-N/A-</Typography>
                      <Typography className="tinyHeight"></Typography>
                    </>
                  }
                  {lead.belongsToMortgage.owner1.length > 0 && lead.belongsToMortgage.owner2.length > 0 ?
                    <>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.owner1}
                      </Typography>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.owner2}
                      </Typography>
                    </>
                  : lead.belongsToMortgage.owner1.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.owner1}
                    </Typography>
                  : lead.belongsToMortgage.owner2.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.owner2}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                  {lead.belongsToMortgage.originationDateLabel.length > 0 && lead.belongsToMortgage.endDateLabel.length > 0 ?
                    <FlexWrapper>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.originationDateLabel} 
                      </Typography>
                      <Typography className="bold tiny">
                        <Typography className="thin tiny" style={{margin: '0 0.3rem'}}>to</Typography>
                        {lead.belongsToMortgage.endDateLabel}
                      </Typography>
                    </FlexWrapper>
                  : lead.belongsToMortgage.originationDateLabel.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.originationDateLabel}
                    </Typography>
                  : lead.belongsToMortgage.endDateLabel.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.endDateLabel}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                  {lead.belongsToMortgage.remainingTerm.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.remainingTerm}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
            
            <FlexWrapper className="column alignCenter fitContentWidth">
              <Typography className="subHeader dark autoMarginHorizontal">Financials</Typography>
              <FlexWrapper className="alignCenter fitContentHeight" style={{marginTop: '0.2rem'}}>
                <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                  {lead.belongsToMortgage.originalLoanAmount > 0 &&
                    <Typography className="tinyHeight tiny">Loan Amount:</Typography>
                  }
                  {lead.belongsToMortgage.principalRemaining > 0 &&
                    <Typography className="tinyHeight tiny">Principal Remaining:</Typography>
                  }
                  {lead.belongsToMortgage.originalInterestRate > 0 &&
                    <Typography className="tinyHeight tiny">Original Interest Rate:</Typography>
                  }
                  {lead.belongsToMortgage.originalInterestDue > 0 &&
                    <Typography className="tinyHeight tiny">Original Interest Due:</Typography>
                  }
                  {lead.belongsToMortgage.interestRemaining > 0 &&
                    <Typography className="tinyHeight tiny">Current Interest Due:</Typography>
                  }                
                </FlexWrapper>
                <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem'}}>
                  {lead.belongsToMortgage.originalLoanAmount > 0 &&
                    <Typography className="bold tiny">
                      ${(lead.belongsToMortgage.originalLoanAmount).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  }
                  {lead.belongsToMortgage.principalRemaining > 0 &&
                    <Typography className="bold tiny">
                      ${(lead.belongsToMortgage.principalRemaining).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  }
                  {lead.belongsToMortgage.originalInterestRate > 0 &&
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.originalInterestRate}%
                    </Typography>
                  }
                  {lead.belongsToMortgage.originalInterestDue > 0 &&
                    <Typography className="bold tiny">
                      ${(lead.belongsToMortgage.originalInterestDue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  }
                  {lead.belongsToMortgage.interestRemaining > 0 &&
                    <Typography className="bold tiny">
                      ${(lead.belongsToMortgage.interestRemaining).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>

          <FlexWrapper className="column alignCenter fitContentHeight" style={{gridColumn: '3', width: 'fit-content', minWidth: 'fit-content', paddingRight: '0.5rem'}}>
            <Typography className="subHeader dark autoMarginHorizontal">Refinance Potential</Typography>
            {lead.belongsToMortgage.financialsPresent ?
              <FlexWrapper className="alignCenter fitContentHeight" style={{marginTop: '0.2rem'}}>
                <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                  <Typography className="tinyHeight tiny">Target Interest Rate:</Typography>
                    <>
                      <Typography className="tinyHeight tiny">Target Interest Due:</Typography>
                      <Typography className="largeHeight tiny">Change in Interest Owed:</Typography>
                      <Typography className="largeHeight tiny">Change in Revenue:</Typography>              
                    </>
                </FlexWrapper>
                <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem'}}>
                  <Typography className="tinyHeight tiny bold">{lead.targetInterestRate}%</Typography>              
                  <Typography className="tinyHeight tiny bold">${lead.targetInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                  {parseInt(lead.targetProfitNumber) > 0 ?
                    <Typography className="largeHeight large bold success">
                      ${lead.targetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography> 
                  : parseInt(lead.targetProfitNumber) < 0 ?
                    <Typography className="largeHeight large bold error">
                      -${(lead.targetProfitNumber*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography> 
                  :
                    <Typography className="largeHeight tiny">$0</Typography>
                  }
                  {parseInt(lead.targetProfitPercent) >= 0 ?
                    <Typography className="largeHeight large bold success">
                      {lead.targetProfitPercent}%
                    </Typography> 
                  :
                    <Typography className="largeHeight large bold error">
                      {lead.targetProfitPercent}%
                    </Typography> 
                  }
                </FlexWrapper>
              </FlexWrapper>
            :
              <ActionIconWrapper style={{margin: '1rem auto 0 auto'}} className="wrapped caution hover" onClick={() => handleOpenLeadActionsModal('provideFinancialsCard', lead, lead.belongsToMortgage)}>
                <AddCircleOutlineIcon style={{marginRight: '0.5rem'}} />
                <Typography className="caution">Provide Original Terms</Typography>
              </ActionIconWrapper>
            }
          </FlexWrapper>
        </GridWrapper>

        <FlexWrapper className="column">
          <Divider style={{width: '97%', margin: '0.4rem 0 0.3rem 0'}} />
          <FlexWrapper style={{paddingLeft: '1rem'}}>
            {lead.belongsToMortgage.tags.length === 0 ?
              <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="accent">no mortgage tags</Typography>
                <AddButton onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.belongsToMortgage)}>
                  <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
                </AddButton>
              </FlexWrapper>
            :
              <FlexWrapper className="alignCenter" style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="medium" style={{margin: 'auto 0.5rem auto 0'}}>mortgage tags:</Typography>
                {lead.belongsToMortgage.tags.map((tag) => (
                  (tag.apiMapping === 'loanType' || tag.apiMapping === 'propertyType') &&
                    <CardButton key={tag.tagId} className="horizontal mortgageTag" onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.belongsToMortgage)}>
                      <span style={{marginRight: '0.3rem', color: `${theme.palette.common.grayBorderDarkest}`, fontWeight: '700', fontSize: '0.8rem'}}>{tag.label}</span> - {tag.discrepancyFields[0].toUpperCase()}
                    </CardButton>
                ))}
                {lead.belongsToMortgage.tags.map((tag) => (
                  (tag.apiMapping !== 'loanType' && tag.apiMapping !== 'propertyType') &&
                    <CardButton key={tag.tagId} className="horizontal mortgageTag" onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.belongsToMortgage)}>
                      {tag.label}
                    </CardButton>
                ))}
              </FlexWrapper>
            }
          </FlexWrapper>
          <FlexWrapper style={{paddingLeft: '1rem'}}>
            {lead.tags.length === 0 ?
              <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="accent" style={{marginBottom: '0.2rem'}}>no lead tags</Typography>
                <AddButton onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.belongsToMortgage)}>
                  <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
                </AddButton>
              </FlexWrapper>
            :
              <FlexWrapper className="alignCenter" style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="medium" style={{margin: 'auto 0.5rem auto 0'}}>investigation tags:</Typography>
                {lead.tags.map((tag) => (
                  <CardButton key={tag.tagId} className="horizontal" onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.belongsToMortgage)}>
                    {tag.label}
                  </CardButton>
                ))}
              </FlexWrapper>
            }
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </>
  )
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '1rem',
    minWidth: 180,
    background: `${theme.palette.common.grayBorder}`,
    borderRight: `1rem solid ${theme.palette.common.grayBorder}`,
    borderLeft: `1rem solid ${theme.palette.common.grayBorder}`,
    borderTop: `0.4rem solid ${theme.palette.common.grayBorder}`,
    borderBottom: `0.4rem solid ${theme.palette.common.grayBorder}`,
    '& .MuiMenu-list:hover': {
    },
    '& .MuiMenuItem-root': {
      background: theme.palette.common.pageBackground,
      '&.buttonTop': {
        padding: '1rem 2rem 0.5rem 2rem',
      },
      '&.border': {
        borderBottom: `0.1rem solid ${theme.palette.common.grayBorder}`
      },
      '&.topRadius': {
        borderRadius: '0.6rem 0.6rem 0 0',
      },
      '&.bottomRadius': {
        borderRadius: '0 0 0.6rem 0.6rem',
        padding: '0.5rem 2rem 1rem 2rem',
      }
    },
    '& .MuiMenuItem-root:hover': {
      background: theme.palette.common.grayHover,
      '&.button': {
        background: theme.palette.common.pageBackground,
      },
    },
  },
}));

export default InvestigatingCard;