import React from "react";
import MemberMonthlyChart from "./Charts/MemberMonthlyChart";
import { HeaderContainer, MainChartWrapper, ComponentContainer, FlexWrapper, ComponentBorder, ScrollWindow, Typography } from "../../../../../StyledComponents";

const SelectedMemberChart = ({selectedMemberMonthlyStats, selectedMemberFirstName}) => {

  return (
    <ComponentContainer className="detailsContainer" style={{gridRow: '4/6', gridColumn: '3', height: '100%'}}>
      <ScrollWindow className="justifyStart">
        <FlexWrapper className="column">
          <HeaderContainer className="chart">
            <Typography className="componentHeader" style={{minWidth: '22rem'}}>{selectedMemberFirstName}'s Monthly Stats</Typography>
          </HeaderContainer>
          <MainChartWrapper style={{minWidth: '85rem'}}>
            <MemberMonthlyChart selectedMemberMonthlyStats={selectedMemberMonthlyStats} />
          </MainChartWrapper>
        </FlexWrapper>
      </ScrollWindow>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default SelectedMemberChart