import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-csv-importer/dist/index.css';
import "../../../../../styles.css";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@mui/styles";
import FinalizeForm from "./FinalizeForm";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { selectSetInvestigationFinalizedPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { ComponentHeader, Button, ButtonWrapper, FlexWrapper, Typography } from "../../../../../StyledComponents";
import { useSetLeadStatusFinalizedMutation, useReloadRefinanceQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation, useEditOneMortgageDetailMutation } from "../../../../../slices/api/apiSlice";

const FinalizeLead = ({ handleCloseModal, leadData, teamId, userId, userFullName, dashPerspective, leadUserNotifications, mortgageData }) => {
  const theme = useTheme()

  const setInvestigationFinalizedPerm = useSelector(selectSetInvestigationFinalizedPerm)
  
  const [editOneMortgageDetail] = useEditOneMortgageDetailMutation()

  const [setLeadFinalized] = useSetLeadStatusFinalizedMutation()
  const [realoadRenegotiationQuarterBreakdown] = useReloadRenegotiationQuarterlyBreakdownMutation()
  const [realoadRefinanceQuarterBreakdown] = useReloadRefinanceQuarterlyBreakdownMutation()

  const [startDate, setStartDate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [mortgageTermValue, setMortgageTermValue] = useState(null)
  const [mortgageTermValueStr, setMortgageTermValueStr] = useState('')
  const [statusTerm, setStatusTerm] = useState(null)
  const [principalValue, setPrincipalValue] = useState(null)
  const [principalValueStr, setPrincipalValueStr] = useState('')
  const [statusPrincipal, setStatusPrincipal] = useState(null)
  const [interestValue, setInterestValue] = useState(null)
  const [interestValueStr, setInterestValueStr] = useState('')
  const [statusInterest, setStatusInterest] = useState(null)

  const handleSubmit = async () => {
    setIsError(false)
    setIsFetching(true)
    let recordUpdateError = false
    let updatedFields = []
    if (moment(startDate).format('MMM Do, YYYY') !== moment(mortgageData.originationDate).format('MMM Do, YYYY')) {
      updatedFields.push({
        fieldRoot: 'primaryMortgage',
        field: 'PrimaryMortgageStartDate',
        label: 'Primary Mortgage Start Date',
        originalValue: "",
        old: "",
        new: moment(startDate).format('MMM Do, YYYY'),
        selection: "custom",
        publicRecordValue: "",
        updateParameter: false,
      })
      let resp = await editOneMortgageDetail({
        userId: userId,
        userFullName: userFullName,
        userNotifications: leadData.userNotifications,
        dashPerspective: dashPerspective,
        mortgageId: mortgageData._id,
        leadId: leadData._id,
        recordField: 'PrimaryMortgageStartDate',
        customValue: startDate,
        activeDiscrepancies: mortgageData.activeDiscrepancies,
        resolvedDiscrepancies: mortgageData.resolvedDiscrepancies,
        rejectedDiscrepancies: mortgageData.rejectedDiscrepancies,
        publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageStartDate.publicRecordValue,
        oldDiscrepancy: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageStartDate.discrepancy,
        recordFieldRoot: 'primaryMortgage',
        dateFormat: 'MMM Do, yyyy',
        discardLead: false,
        teamId: teamId,
        selection: null,
        financialsUpdated: true,
        origin: 'provideFinancials',
        leadStatus: leadData.status,
      })
      if (resp.error) {
        recordUpdateError = true
      }
    }
    if (mortgageTermValue !== mortgageData.mortgageTerm) {
      updatedFields.push({
        fieldRoot: 'primaryMortgage',
        field: 'PrimaryMortgageTerm',
        label: 'Primary Mortgage Term',
        originalValue: "",
        old: "",
        new: parseFloat(mortgageTermValue),
        selection: "custom",
        publicRecordValue: "",
        updateParameter: false,
      })
      let resp = await editOneMortgageDetail({
        userId: userId,
        userFullName: userFullName,
        userNotifications: leadData.userNotifications,
        dashPerspective: dashPerspective,
        mortgageId: mortgageData._id,
        leadId: leadData._id,
        recordField: 'PrimaryMortgageTerm',
        customValue: mortgageTermValue.toString(),
        activeDiscrepancies: mortgageData.activeDiscrepancies,
        resolvedDiscrepancies: mortgageData.resolvedDiscrepancies,
        rejectedDiscrepancies: mortgageData.rejectedDiscrepancies,
        publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageTerm.publicRecordValue,
        oldDiscrepancy: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageTerm.discrepancy,
        recordFieldRoot: 'primaryMortgage',
        dateFormat: null,
        discardLead: false,
        teamId: teamId,
        selection: null,
        financialsUpdated: true,
        origin: 'provideFinancials',
        leadStatus: leadData.status,
      })
      if (resp.error) {
        recordUpdateError = true
      }
    }
    if (principalValue !== mortgageData.originalLoanAmount) {
      updatedFields.push({
        fieldRoot: 'primaryMortgage',
        field: 'PrimaryMortgageAmount',
        label: 'Primary Mortgage Amount',
        originalValue: "",
        old: "",
        new: parseFloat(principalValue),
        selection: "custom",
        publicRecordValue: "",
        updateParameter: false,
      })
      let resp = await editOneMortgageDetail({
        userId: userId,
        userFullName: userFullName,
        userNotifications: leadData.userNotifications,
        dashPerspective: dashPerspective,
        mortgageId: mortgageData._id,
        leadId: leadData._id,
        recordField: 'PrimaryMortgageAmount',
        customValue: principalValue.toString(),
        activeDiscrepancies: mortgageData.activeDiscrepancies,
        resolvedDiscrepancies: mortgageData.resolvedDiscrepancies,
        rejectedDiscrepancies: mortgageData.rejectedDiscrepancies,
        publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageAmount.publicRecordValue,
        oldDiscrepancy: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageAmount.discrepancy,
        recordFieldRoot: 'primaryMortgage',
        dateFormat: null,
        discardLead: false,
        teamId: teamId,
        selection: null,
        financialsUpdated: true,
        origin: 'provideFinancials',
        leadStatus: leadData.status,
      })
      if (resp.error) {
        recordUpdateError = true
      }
    }
    if (interestValue !== mortgageData.originalInterestRate) {
      updatedFields.push({
        fieldRoot: 'primaryMortgage',
        field: 'PrimaryMortgageInterestRate',
        label: 'Primary Mortgage Interest Rate',
        originalValue: "",
        old: "",
        new: parseFloat(interestValue),
        selection: "custom",
        publicRecordValue: "",
        updateParameter: false,
      })
      let resp = await editOneMortgageDetail({
        userId: userId,
        userFullName: userFullName,
        userNotifications: leadData.userNotifications,
        dashPerspective: dashPerspective,
        mortgageId: mortgageData._id,
        leadId: leadData._id,
        recordField: 'PrimaryMortgageInterestRate',
        customValue: interestValue.toString(),
        activeDiscrepancies: mortgageData.activeDiscrepancies,
        resolvedDiscrepancies: mortgageData.resolvedDiscrepancies,
        rejectedDiscrepancies: mortgageData.rejectedDiscrepancies,
        publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageInterestRate.publicRecordValue,
        oldDiscrepancy: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageInterestRate.discrepancy,
        recordFieldRoot: 'primaryMortgage',
        dateFormat: null,
        discardLead: false,
        teamId: teamId,
        selection: null,
        financialsUpdated: true,
        origin: 'provideFinancials',
        leadStatus: leadData.status,
      })
      if (resp.error) {
        recordUpdateError = true
      }
    }

    if (!recordUpdateError) {
      let updatedStartDate = moment(startDate).format('MMM Do, YYYY')
      if (leadData.targetOutcome === 'renegotiation') {
        updatedStartDate = mortgageData.originationDate
      }
      let initialResp = await setLeadFinalized({
        userId: userId,
        leadId: leadData._id,
        dashPerspective: dashPerspective,
        teamId: teamId,
        userNotifications: leadUserNotifications,
        updatedTerm: mortgageTermValue,
        updatedPrincipal: principalValue,
        updatedInterestRate: interestValue,
        updatedStartDate: updatedStartDate,
        updatedFields: updatedFields,
        userFullName: userFullName,
      })
      if (initialResp.error) {
        setIsError(true)
      } else {
        let reloadResp = {}
        if (leadData.targetOutcome === 'renegotiation') {
          reloadResp = await realoadRenegotiationQuarterBreakdown({
            userId: userId,
            teamId: teamId,
            leadTier: leadData.tier,
            actionTaken: 'closed',
            closureType: leadData.targetOutcome,
          })
        } else {
          reloadResp = await realoadRefinanceQuarterBreakdown({
            userId: userId,
            teamId: teamId,
            leadTier: leadData.tier,
            actionTaken: 'closed',
            closureType: leadData.targetOutcome,
          })
        }
        if (reloadResp.error) {
          setIsError(true)
        } else if (reloadResp.data.message.includes('success')) {
          setIsSuccess(true)
        } else {
          setIsError(true)
        }
      }
    }
    if (recordUpdateError) {
      setIsError(true)
    }
    setIsFetching(false)
  }

  const [termRequired, setTermRequired] = useState(null)
  const [termLocked, setTermLocked] = useState(false)
  const [dateRequired, setDateRequired] = useState(null)
  const [dateLocked, setDateLocked] = useState(false)
  const [interestRequired, setInterestRequired] = useState(null)
  const [interestLocked, setInterestLocked] = useState(false)
  const [principalRequired, setPrincipalRequired] = useState(null)
  const [principalLocked, setPrincipalLocked] = useState(false)

  useEffect(() => {
    setTermLocked(false)
    setDateLocked(false)
    setInterestLocked(false)
    setPrincipalLocked(false)
    if (mortgageData.status === 'closing' && leadData.status === 'closing' && leadData.targetOutcome !== 'Refinance') {
      if (mortgageData.originationDate.length > 0) {
        const today = new Date(mortgageData.originationDate.slice(0,4)+'-'+mortgageData.originationDate.slice(5,10))
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        setStartDate(tomorrow)
        if (leadData.targetOutcome === 'renegotiation') {
          setDateLocked(true)
        }
      } else {
        if (!startDate) {
          setDateRequired(true)
        }
        setStartDate(new Date())
      }
      if (isNaN((mortgageData.originalLoanAmount))) {
        setStatusPrincipal("nonNumber")
      } else if (Math.sign(parseFloat(mortgageData.originalLoanAmount)) === -1) {
        setStatusPrincipal("negative")
      } else if (Math.sign(parseFloat(mortgageData.originalLoanAmount)) === 0) {
        setStatusPrincipal("zero")
      } else if (parseFloat(mortgageData.originalLoanAmount) > 100000000) {
        setStatusPrincipal("large")
      }
      if (!mortgageData.originalLoanAmount || mortgageData.originalLoanAmount === 0) {
        if (principalRequired === null) {
          setPrincipalRequired(true)
        }
      } else {
        if (leadData.targetOutcome === 'renegotiation') {
          setPrincipalLocked(true)
        }
      }
      if (isNaN((mortgageData.mortgageTerm))) {
        setStatusTerm("nonNumber")
      } else if (Math.sign(parseFloat(mortgageData.mortgageTerm)) === -1) {
        setStatusTerm("negative")
      } else if (Math.sign(parseFloat(mortgageData.mortgageTerm)) === 0) {
        setStatusTerm("zero")
      } else if (parseFloat(mortgageData.mortgageTerm) > 100000000) {
        setStatusTerm("large")
      }
      if (!mortgageData.mortgageTerm || mortgageData.mortgageTerm === 0) {
        if (termRequired === null) {
          setTermRequired(true)
        }
      } else {
        if (leadData.targetOutcome === 'renegotiation') {
          setTermLocked(true)
        }
      }
      if (isNaN((mortgageData.originalInterestRate))) {
        setStatusInterest("nonNumber")
      } else if (Math.sign(parseFloat(mortgageData.originalInterestRate)) === -1) {
        setStatusInterest("negative")
      } else if (Math.sign(parseFloat(mortgageData.originalInterestRate)) === 0) {
        setStatusInterest("zero")
      } else if (parseFloat(mortgageData.originalInterestRate) > 100000000) {
        setStatusInterest("large")
      }
      if (!mortgageData.originalInterestRate || mortgageData.originalInterestRate === 0) {
        if (interestRequired === null) {
          setInterestRequired(true)
        }
      } else {
        if (leadData.targetOutcome === 'renegotiation') {
          setInterestLocked(true)
        }
      }
      if (mortgageData.originalLoanAmount && mortgageData.originalLoanAmount > 0) {
        setPrincipalValue(parseFloat(mortgageData.originalLoanAmount))
        setPrincipalValueStr(mortgageData.originalLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
      }
      if (mortgageData.originalInterestRate && mortgageData.originalInterestRate > 0) {
        setInterestValue(mortgageData.originalInterestRate)
        setInterestValueStr(mortgageData.originalInterestRate)
      }
      if (mortgageData.mortgageTerm && mortgageData.mortgageTerm > 0) {
        setMortgageTermValue(mortgageData.mortgageTerm)
        setMortgageTermValueStr(mortgageData.mortgageTerm)
      }
    } else if (leadData.targetOutcome === 'Refinance') {
      setDateRequired(true)
      setPrincipalRequired(true)
      setTermRequired(true)
      setInterestRequired(true)
    }
  }, [mortgageData, leadData])

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <ErrorIcon style={{height: '2rem', width: '2rem', color: `${theme.palette.common.redDark}`}} />
          <span style={{padding: '0.1rem 0.5rem 0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        leadData.targetOutcome === 'renegotiation' ?
          <ComponentHeader className="modal">Finalize Renegotiation</ComponentHeader>
        :
          <ComponentHeader className="modal">Finalize Refinance</ComponentHeader>
      }

      <FlexWrapper className="column" style={{gridRow: '3/6', gridColumn: '3'}}>
        {!isSuccess &&
          <>
            {mortgageData.activeDiscrepancies &&
              <FlexWrapper className="alignCenter fitContentHeight fitContentWidth" style={{margin: '2rem 3rem'}}>
                <FlexWrapper 
                  style={{
                    border: `0.2rem solid ${theme.palette.common.orangeDark}`, 
                    padding: '0.5rem', width: '2.7rem',
                    borderRadius: '1rem 0 0 1rem',
                    background: `${theme.palette.common.orange}`}}
                  >
                  <ReportProblemIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                </FlexWrapper>
                <div style={{
                  border: `0.1rem solid ${theme.palette.common.orangeDark}`,
                  padding: '0.3rem 5rem 0.3rem 1rem',
                  borderRadius: '0 1rem 1rem 0',
                  background: `${theme.palette.common.orangeTransparent}`,
                  borderLeft: 'none', color: `${theme.palette.common.orangeDark}`}}
                >
                  The lead currently has active discrepencies.<br/>
                  <FlexWrapper style={{margin: '0.6rem 0'}}>
                    <div>•</div>
                    <div style={{marginLeft: '1rem'}}>
                      If the current public records information is excepted, resolve all discrepancies before closing so the mortgage can be placed in the "inactive" pool upon closing.
                    </div>
                  </FlexWrapper>
                  <FlexWrapper style={{margin: '0.6rem 0'}}>
                    <div>•</div>
                    <div style={{marginLeft: '1rem'}}>
                      Otherwise, close the lead as-is to place the mortgage in the "awaiting public record updates" pool where your team will be notified once the intended changes are reflected in county records.
                    </div>
                  </FlexWrapper>
                </div>
              </FlexWrapper>
            }
            <FinalizeForm setStartDate={setStartDate} startDate={startDate} statusTerm={statusTerm} mortgageTermValueStr={mortgageTermValueStr} setStatusTerm={setStatusTerm} setMortgageTermValueStr={setMortgageTermValueStr} setMortgageTermValue={setMortgageTermValue} statusPrincipal={statusPrincipal} principalValueStr={principalValueStr} setStatusPrincipal={setStatusPrincipal} setPrincipalValueStr={setPrincipalValueStr} setPrincipalValue={setPrincipalValue} statusInterest={statusInterest} setStatusInterest={setStatusInterest} setInterestValueStr={setInterestValueStr} setInterestValue={setInterestValue} interestValueStr={interestValueStr} termRequired={termRequired} interestRequired={interestRequired} principalRequired={principalRequired} dateRequired={dateRequired} setTermRequired={setTermRequired} setDateRequired={setDateRequired} setInterestRequired={setInterestRequired} setPrincipalRequired={setPrincipalRequired} leadData={leadData} setIsFetching={setIsFetching} userFullName={userFullName} dashPerspective={dashPerspective} userId={userId} termLocked={termLocked} dateLocked={dateLocked} interestLocked={interestLocked} principalLocked={principalLocked} />
          </>
        }
        {isSuccess ?
          <FlexWrapper className="column fitContentHeight" style={{margin: 'auto 0 0 0'}}>
            <Typography className="justifyCenter small">
              The {leadData.targetOutcome === 'renegotiation' ? 'renegotiated' : 'refinanced'} mortgage has been saved.<br/>
            </Typography>
            {mortgageData.activeDiscrepancies > 0 &&
              <Typography className="alignTextCenter small" style={{margin: '2rem auto 0 auto'}}>
                The investigation will retain an "Awaiting Public Record Update" status until the
                <br/>
                expected arrangements are reflected in the public records.`
              </Typography>
            }
          </FlexWrapper>
        :
          <Typography className="alignTextCenter small" style={{height: '6rem', margin: '1rem auto 5rem auto'}}>
            Finalizing the lead will generate a report detailing the investigation and the new agreement.<br/>
            Make any necessary changes before continuing.
          </Typography>
        }

        {isSuccess ?
          <ButtonWrapper style={{margin: '15rem auto 10rem auto'}}>
            <Button onClick={() => handleCloseModal()} className="neutral" style={{width: '14rem'}}>Close Window</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            <Button onClick={() => handleCloseModal()} className="neutral" style={{width: '14rem'}}>Close Window</Button>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.error.color}`,
                backgroundColor: `${theme.tooltip.error.background}`,
                border: `0.1rem solid ${theme.tooltip.error.border}`,
                borderBottom: `0.1rem solid ${theme.tooltip.error.border}`,
                opacity: '0',
                borderRadius: '1rem',
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: '0.5rem 1rem',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={!setInvestigationFinalizedPerm ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Elevated permissions required
                  </Typography>
                </FlexWrapper>
              : (statusPrincipal || statusInterest || statusTerm) ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Form inputs required
                  </Typography>
                </FlexWrapper>
              : (dateRequired || principalRequired || interestRequired || termRequired) ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Form inputs required
                  </Typography>
                </FlexWrapper>
              : null} 
              placement="top"
              arrow
            >
              <Button 
                className={
                  (!setInvestigationFinalizedPerm || statusPrincipal || statusInterest || statusTerm || dateRequired || principalRequired || interestRequired || termRequired) ? "primaryDisabled" 
                  : isFetching ? "primaryLoading" : "primary"
                }
                onClick={(!setInvestigationFinalizedPerm || isFetching || dateRequired || principalRequired || interestRequired || termRequired || statusPrincipal || statusInterest || statusTerm) ? null : () => handleSubmit()}
                style={{width: '24rem'}}
              >
                {isFetching ?
                  <CircularProgress sx={{ color: `${theme.palette.common.cardBackground}` }} size={13} />
                : (mortgageData.activeDiscrepancies + mortgageData.rejectedDiscrepancies) > 0 ?
                  `Await Public Record Updates`
                :
                  `Confirm`
                }
              </Button>
            </Tooltip>
          </ButtonWrapper>
        }
      </FlexWrapper>
    </>
  )
}

export default FinalizeLead