import React, { useEffect, useState } from "react";
import TreeItem from "./components/TreeItem";
import ReportPDF from "./components/ReportPDF";
import BlackLogo from '../../../../icon.logo.black.png';
import ReportView from "./components/ReportView";
import { useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress"
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { selectAllTeamReports } from "../../../../slices/teamReportsSlice";
import { useRemoveReportNotifyUserMutation } from "../../../../slices/api/apiSlice";
import { CardListContainer, Divider, FlexWrapper, HeaderContainer, HeaderText, ImageElements, ReportTreeListContainer, ScrollWindow, TreeContainer, Typography } from "../../../../StyledComponents";
import { selectDashPerspective, selectLeadsFilteredBoolean, setMortgageActionNavMenuSelection, setNavSelection, setViewingMortgageActions } from "../../../../slices/sessionDataSlice";
import { setViewingLead } from "../../../../slices/viewingLeadSlice";
import { setViewingMortgage } from "../../../../slices/viewingMortgageSlice";
import { decrementMortgageNotificationsCount, removeLeadNotification } from "../../../../slices/userNotificationsSlice";
import { removeNotificationFromMortgage, selectMortgagesFilteredBoolean, selectTeamMortgages, setFwdBckViewingFilteredMortgages, setFwdBckViewingMortgages } from "../../../../slices/teamMortgagesSlice";
import { removeNotificationFromLead, selectTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads, selectTeamLeadsAwaitingVerification, setFwdBckFilteredViewingLeads, setFwdBckViewingLeads } from "../../../../slices/teamActiveLeadsSlice";
import { useNavigate } from "react-router-dom";

const Reports = ({ userId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const [removeUserNotification] = useRemoveReportNotifyUserMutation() 
  
  const teamReports = useSelector(selectAllTeamReports)
  const teamMortgages = useSelector(selectTeamMortgages)
  const dashPerspective = useSelector(selectDashPerspective)
  const teamLeadsClosing = useSelector(selectTeamClosingLeads)
  const leadsFilteredBoolean = useSelector(selectLeadsFilteredBoolean)
  const teamLeadsInvestigating = useSelector(selectTeamInvestigatingLeads)
  const teamLeadsAwaitingUpdate = useSelector(selectTeamAwaitingUpdateLeads)
  const teamLeadsAwaitingAction = useSelector(selectTeamAwaitingActionLeads)
  const mortgagesFilteredBoolean = useSelector(selectMortgagesFilteredBoolean)
  const teamLeadsAwaitingVerification = useSelector(selectTeamLeadsAwaitingVerification)
  
  const [initialIsNew, setInitialIsNew] = useState(false)
  const [selectedReport, setSelectedReport] = useState(teamReports[0])
  const [selectedReportId, setSelectedReportId] = useState(null)

  const selectReport = (reportId) => {
    if (initialIsNew) {
      removeUserNotification({
        userId: userId,
        reportId: selectedReportId
      })
      setInitialIsNew(false)
    }
    let thisReport = teamReports.find(report => report._id === reportId)
    setSelectedReport(thisReport)
    setSelectedReportId(thisReport._id)
    if (thisReport.notifyUser) {
      removeUserNotification({
        userId: userId,
        reportId: thisReport._id
      })
    }
  }

  const handleGoToMortgage = (mortgageId, PreviousOwner1FullName) => {
    let mortgageMatch = teamMortgages.find(mortgage => mortgage._id.toString() === mortgageId.toString())
    if (mortgageMatch) {
      dispatch( setViewingMortgage(mortgageMatch) )
      if (mortgagesFilteredBoolean) {
        dispatch( setFwdBckViewingFilteredMortgages(mortgageMatch._id) )
      } else {
        dispatch( setFwdBckViewingMortgages(mortgageMatch._id) )
      }
      if (mortgageMatch.notifCount && mortgageMatch.notifCount !== 0) {
        dispatch( decrementMortgageNotificationsCount(1) )
        dispatch( removeNotificationFromMortgage({mortgageId: mortgageId}) )
      }
      if (mortgageMatch.activeLead) {
        let leadStatus = ''
        let leadMatch = teamLeadsAwaitingAction.find(lead => lead._id.toString() === mortgageMatch.activeLead.toString())
        if (!leadMatch) {
          leadMatch = teamLeadsInvestigating.find(lead => lead._id.toString() === mortgageMatch.activeLead.toString())
          if (!leadMatch) {
            leadMatch = teamLeadsClosing.find(lead => lead._id.toString() === mortgageMatch.activeLead.toString())
            if (!leadMatch) {
              leadMatch = teamLeadsAwaitingUpdate.find(lead => lead._id.toString() === mortgageMatch.activeLead.toString())
              if (!leadMatch) {
                leadMatch = teamLeadsAwaitingVerification.find(lead => lead._id.toString() === mortgageMatch.activeLead.toString())
              }
            }
          }
        }
        if (leadMatch) {
          dispatch( setViewingLead(leadMatch) )
          if (leadsFilteredBoolean || dashPerspective !== 'team') {
            dispatch( setFwdBckFilteredViewingLeads(leadMatch._id) )
          } else {
            dispatch( setFwdBckViewingLeads(leadMatch._id) )
          }
          if (leadMatch.notifCount && leadMatch.notifCount !== 0) {
            dispatch( removeLeadNotification({status: leadStatus, count: 1}) )
            dispatch( removeNotificationFromLead({status: leadStatus, leadId: leadMatch._id}) )
          }
        }
      }
      dispatch( setNavSelection('repository') )
      dispatch( setMortgageActionNavMenuSelection('compareRecords') )
      navigate(`/dashboard/mortgageRepository/${mortgageId}`)
      dispatch( setViewingMortgageActions(true) )
    }
  }

  useEffect(() => {
    if (teamReports.length > 0) {
      setSelectedReportId(teamReports[0]._id)
      if (teamReports[0].notifyUser) {
        setInitialIsNew(true)
      }
    }
  }, [teamReports])

  return (
    <FlexWrapper>
      {teamReports.length > 0 ?
        <FlexWrapper>
          <TreeContainer style={{margin: '5rem 2rem auto 2rem', width: 'fit-content', height: 'fit-content', maxHeight: 'calc(80vh)'}}>
            <FlexWrapper className="column">
              <HeaderContainer className="reports">
                <ImageElements src={BlackLogo} style={{width: '2.5rem', height: '2.5rem', opacity: '0.5', margin: '0 0.5rem 0 0'}} />
                <HeaderText className="reports">Lancaster Reports</HeaderText>
              </HeaderContainer>
              <FlexWrapper className="column fitContentWidth" style={{padding: '1rem 1.5rem'}}>
                <Typography>Total: {teamReports.length}</Typography>
                <Divider className="full darkGray" />
                <ScrollWindow className="fitContentHeight fitContentWidth verticalOnly" style={{minHeight: '3.5rem', padding: '0 0.5rem', maxHeight: 'calc(70vh)'}}>
                  {teamReports !== undefined ?
                    <ReportTreeListContainer>
                      {teamReports.map((report, index) => (
                        <TreeItem key={report._id} itemIndex={index} borrower={report.Owner1FullName} selectReport={selectReport} reportId={report._id} selectedReportId={selectedReportId} date={report.dateGeneratedFileLabel} isNew={report.notifyUser} address={report.streetAddress} />
                      ))}
                    </ReportTreeListContainer>
                  :
                    <CardListContainer className="reportsLoading">
                      <CircularProgress
                        sx={{ color: `${theme.palette.primary.main}`}}
                        size={48}
                      />
                    </CardListContainer>
                  }
                </ScrollWindow>
              </FlexWrapper>
            </FlexWrapper>
          </TreeContainer>
          <ReportView report={selectedReport} handleGoToMortgage={handleGoToMortgage} />
          {/* <PDFDownloadLink
            document={<ReportPDF report={selectedReport} />}
            fileName={`${selectedReport.outcome}-report-${selectedReport.dateGeneratedFileLabel}.pdf`}
            style={{
              textDecoration: "none",
              color: "white",
              backgroundColor: "purple",
              borderRadius: 10,
              display: "flex",
              height: 50,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {({ loading }) => {
              return loading ? "Generating report..." : "Download your Report";
            }}
          </PDFDownloadLink> */}
        </FlexWrapper>
      :
        <FlexWrapper className="column justifyCenter alignCenter" style={{gridRow: '1/4', gridColumn: '1/5'}}>
          <ContentPasteIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
          <HeaderText style={{fontSize: '2.2rem', color: `${theme.palette.common.grayTextLight}`}}>no reports</HeaderText>
        </FlexWrapper>
      }
    </FlexWrapper>
  )
}

export default Reports