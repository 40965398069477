import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { useTheme } from "@mui/styles";
import BulkScanControls from "../../../../components/SweepControls/BulkScanControls";
import SweepPerformances from "../../../../components/Windows/SweepPerformances";
import SweepFailuresModal from "./components/modal/SweepFailuresModal";
import { ComponentBorder, ComponentContainer, FlexWrapper } from "../../../../StyledComponents";


const RecordSweeps = ({ teamId, userId }) => {
  const theme = useTheme()

  const [sweepFailures, setSweepFailures] = useState([])
  const [failureType, setFailureType] = useState([])
  const [openSweepFailuresModal, setOpenSweepFailuresModal] = useState(false)
  const handleOpenViewSweepFailuresModal = (failures, type) => {
    setSweepFailures(failures)
    setOpenSweepFailuresModal(true)
    setFailureType(type)
  }
  const handleCloseSweepFailuresModal = () => {
    setOpenSweepFailuresModal(false)
  }

  return (
    <FlexWrapper className="column">
      <ComponentContainer className="fitWindow" style={{}}>
        <FlexWrapper className="column" style={{backgroundColor: `${theme.palette.common.grayBorder}`, background: `${theme.component.background.secondary}`}}>
          <BulkScanControls teamId={teamId} userId={userId} mortgageId={null} />
          <SweepPerformances handleOpenViewSweepFailuresModal={handleOpenViewSweepFailuresModal} />
        </FlexWrapper>

        <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
        <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
        <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
        <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
      </ComponentContainer>

      <Dialog open={openSweepFailuresModal} onClose={handleCloseSweepFailuresModal} 
      PaperProps={{ sx: {width: "90rem", minWidth: "90rem", maxWidth: "90rem", height: "50rem", minHeight: "50rem", maxHeight: "50rem"}}}
      >
        <SweepFailuresModal sweepFailures={sweepFailures} failureType={failureType} closeModal={handleCloseSweepFailuresModal} />
      </Dialog>
    </FlexWrapper>
  )
}

export default RecordSweeps;