import React, { useState } from "react";
import 'react-csv-importer/dist/index.css';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { selectDismissLeadPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { Button, ButtonWrapper, ModalContentContainer, ComponentHeader, SubHeader } from "../../../../../StyledComponents";
import { useDismissLeadMutation, useReloadRefinanceQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation } from "../../../../../slices/api/apiSlice";

const DismissLead = ({ handleCloseModal, userFullName, leadStatusFilter, teamId, leadData, userId, dashPerspective, leadUserNotifications }) => {
  const theme = useTheme();

  const dismissLeadPerm = useSelector(selectDismissLeadPerm)

  const [isFetching, setIsFetching] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [dismissLead] = useDismissLeadMutation()
  const [realoadRefinanceQuarterBreakdown] = useReloadRefinanceQuarterlyBreakdownMutation()
  const [realoadRenegotiationQuarterBreakdown] = useReloadRenegotiationQuarterlyBreakdownMutation()

  const handleDismissLead = async () => {
    setIsFetching(true)
    let resp = await dismissLead({
      leadId: leadData._id,
      userId: userId,
      dashPerspective: dashPerspective,
      leadFilter: leadStatusFilter,
      teamId: teamId,
      mortgageId: leadData.belongsToMortgage,
      userFullName: userFullName,
      userNotifications: leadUserNotifications,
    })
    if (!resp.error && resp.data.message.includes('successful')) {
      setIsSuccess(true)
      if (leadData.targetOutcome === 'renegotiation') {
        await realoadRenegotiationQuarterBreakdown({
          userId: userId,
          teamId: teamId,
          leadTier: leadData.tier,
          actionTaken: 'dismissed',
          closureType: leadData.targetOutcome,
        })
      } else {
        await realoadRefinanceQuarterBreakdown({
          userId: userId,
          teamId: teamId,
          leadTier: leadData.tier,
          actionTaken: 'dismissed',
          closureType: leadData.targetOutcome,
        })
      }
    } else {
      setIsError(true)
    }
    setIsFetching(false)
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.</ComponentHeader>
      :
        <ComponentHeader className="modal">Dismiss Lead</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto'}}>
            <span>This mortgage has been removed as an active lead.</span>
          </SubHeader>
        :
          <SubHeader className="modal" style={{margin: '0 auto 3rem auto', padding: '0 1rem', width: '35rem'}}>
            <span>Removing the "Active Lead" status from this mortgage will place it back in the pool to be queried against the public records.</span>
          </SubHeader>
        }

        {isSuccess ?
          <ButtonWrapper>
            <Button className="neutral" onClick={() => handleCloseModal()}>Continue</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            {isFetching ?
              <Button className="dismissLoading">
                <CircularProgress
                  sx={{ color: `${theme.palette.common.white}` }}
                  size={18}
                />
              </Button>
            :
              <Button className={dismissLeadPerm ? "dismiss" : "dismissDisabled"} onClick={dismissLeadPerm ? () => handleDismissLead() : null}>Continue</Button>
            }
            <Button className="neutral" onClick={() => handleCloseModal()}>Cancel</Button>
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default DismissLead;