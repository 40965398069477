import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    queryPerformances: [],
    uploadReports: [],
    actionsAndErrorsLog: [],
  },
  status: 'idle',
  error: null
}

export const teamAdminDataSlice = createSlice({
  name: 'teamAdminData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.teamAdminData
        if (payload.data.newLog) {
          state.data.actionsAndErrorsLog.push(payload.data.newLog)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.saveProperty.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.recordUploadPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
      if (payload.data.newUploadReport) {
        let existingUploadReport = state.data.uploadReports.find(report => report._id.toString() === payload.data.newUploadReport._id.toString())
        if (existingUploadReport) {
          let thisIndex = state.data.uploadReports.indexOf(existingUploadReport)
          state.data.uploadReports[thisIndex] = payload.data.newUploadReport
        } else {
          state.data.uploadReports.unshift(payload.data.newUploadReport)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.uploadCSVMortgages.matchFulfilled, (state, {payload}) => {
      if (payload.data.newUploadReport) {
        state.data.uploadReports.push(payload.data.newUploadReport)
      }
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.deleteUploadReport.matchFulfilled, (state, {payload}) => {
      if (payload.data.reportId) {
        let thisReport = state.data.uploadReports.find(report => report._id === payload.data.reportId);
        let thisIndex = state.data.uploadReports.indexOf(thisReport)
        state.data.uploadReports.splice(thisIndex, 1)
      }
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    //* Actions And Errors Logs
    //TODO: only return logs from api if user is super
    .addMatcher(apiSlice.endpoints.deleteActionOrErrorLog.matchFulfilled, (state, {payload}) => {
      if (payload.data.logId) {
        let thisLog = state.data.actionsAndErrorsLog.find(log => log._id === payload.data.logId)
        let thisIndex = state.data.actionsAndErrorsLog.indexOf(thisLog)
        state.data.actionsAndErrorsLog.splice(thisIndex, 1)
      }
    })
    .addMatcher(apiSlice.endpoints.editMortgage.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageTags.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.selectNewUserActionsPermissionsSet.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.checkForMortgageDuplicates.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.removeReportNotifyUser.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateTeamDefaultTargetParameters.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateTeamDiscrepancyParamterSelection.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.recordQueryPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
      if (payload.data.newQueryPerformance) {
        state.data.queryPerformances.push(payload.data.newQueryPerformance)
      }
    })
    .addMatcher(apiSlice.endpoints.runPropertySearch.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.provideFinancialInformation.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.logReturningVisit.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        if (payload.data.newLog) {
          state.data.actionsAndErrorsLog.push(payload.data.newLog)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      state.data.actionsAndErrorsLog.push(payload.data.newLog)
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(...payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusInvestigating.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusClosing.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateUserDefaultLocations.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateSecuritySetting.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetChanges.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateSweepParamterSettings.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.updateTeamDiscrepancyParamterSelection.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLog) {
        state.data.actionsAndErrorsLog.push(payload.data.newLog)
      }
    })
    .addMatcher(apiSlice.endpoints.deleteUser.matchFulfilled, (state, {payload}) => {
      state.data.actionsAndErrorsLog.push(payload.data.newLog)
    })
  }
})

export default teamAdminDataSlice.reducer

export const selectTeamQueryPerformances = state => state.teamAdminData.data.queryPerformances
export const selectTeamUploadReports = state => state.teamAdminData.data.uploadReports
export const selectActionsAndErrorsLog = state => state.teamAdminData.data.actionsAndErrorsLog
