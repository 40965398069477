import React from "react";
import { useTheme } from "@mui/styles";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useSelector } from "react-redux";
import { selectUserRole } from "../../../slices/userInfoSlice";
import DiscrepanciesDetected from "./components/DiscrepanciesDetected";
import { AccentInfo, CardButton, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const ScanPerformed = ({milestone}) => {
  const theme = useTheme()

  const userRole = useSelector(selectUserRole)

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="new" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      {(milestone.newResolved && milestone.newResolved > 0) ?
        <Typography>
          <span style={{fontWeight: '700', marginRight: '0.3rem'}}>
            {milestone.newResolved}
          </span>
          {milestone.newResolved === 1 ? `Discrepancy resolved` : `Discrepancies resolved`}
        </Typography>
      : <></>
      }
      {milestone.milestone === 'Public Records Updated' && milestone.activeDiscrepancies === 0 ?
        <Typography style={{fontWeight: '700'}}>
          {milestone.milestone === 'Record Scanned Successfuly' ?
            <AccentInfo>
              No discrepancies detected
            </AccentInfo>
          :
            <>
              No Discrepancies remaining
              <br/>
            </>
          }
          {milestone.leadAwaitingUpdates &&
            'Ready to verify'
          }
        </Typography>
      :
        <Typography>
          <span style={{fontWeight: '700', marginRight: '0.3rem'}}>
            {milestone.activeDiscrepancies}
          </span>
          {milestone.activeDiscrepancies === 1 ? `Discrepancy ` : `Discrepancies `}
          {milestone.milestone === 'Lead Discovered' ? `detected` : `remaining`}
        </Typography>
      }
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        {userRole === 'super' &&
          <FlexWrapper className='fitContentHeight'>
            <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
              Queries:
            </Typography>
            <Typography className="fitContentHeight bold">
              {milestone.details}
            </Typography>
          </FlexWrapper>
        }
        {(milestone.tier1Discrepancies || milestone.tier2Discrepancies || milestone.tier3Discrepancies) &&
          <DiscrepanciesDetected type={'discrepancy'} tier1={milestone.tier1Discrepancies} tier2={milestone.tier2Discrepancies} tier3={milestone.tier3Discrepancies} />
        }
        {(milestone.tier1Updates || milestone.tier2Updates || milestone.tier3Updates) &&
          <DiscrepanciesDetected type={'update'} tier1={milestone.tier1Updates} tier2={milestone.tier2Updates} tier3={milestone.tier3Updates} />
        }
        {(milestone.tier1Resolved || milestone.tier2Resolved || milestone.tier3Resolved) &&
          <DiscrepanciesDetected type={''} tier1={milestone.tier1Resolved} tier2={milestone.tier2Resolved} tier3={milestone.tier3Resolved} />
        }
        {milestone.newLeadTags && milestone.newLeadTags.length > 0 &&
          <FlexWrapper className="column fitContentHeight">
            <Typography className="fitContentHeight justifyEnd" style={{width: '14rem', margin: '1rem 0 0.5rem 0'}}>
              {milestone.newLeadTags.length > 1 ?
                `New Lead Tags:`
              :
                `New Lead Tag:`
              }
            </Typography>
            <FlexWrapper 
              className="flexWrap justifyCenter"
              style={{maxWidth: '35rem', marginBottom: '1rem'}}
            >
              {milestone.newLeadTags.map((mortgageTag) => (
                <CardButton key={mortgageTag.label} className="thin horizontal noHover primaryDark fitContentWidth">
                  <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem'}} />
                  <Typography className="fitContentHeight">
                    {mortgageTag.label}
                  </Typography>
                </CardButton>
              ))}
            </FlexWrapper>
          </FlexWrapper>
        }
        {milestone.newMortgageTags && milestone.newMortgageTags.length > 0 &&
          <FlexWrapper className="column fitContentHeight">
            <Typography className="fitContentHeight justifyEnd" style={{width: '14rem', margin: '1rem 0 0.5rem 0'}}>
              {milestone.newMortgageTags.length > 1 ?
                `New Mortgage Tags:`
              :
                `New Mortgage Tag:`
              }
            </Typography>
            <FlexWrapper 
              className="flexWrap justifyCenter"
              style={{maxWidth: '35rem', marginBottom: '1rem'}}
            >
              {milestone.newMortgageTags.map((mortgageTag) => (
                <CardButton key={mortgageTag.label} className="thin horizontal noHover primaryDark fitContentWidth">
                  <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem'}} />
                  <Typography className="fitContentHeight">
                    {mortgageTag.label}
                  </Typography>
                </CardButton>
              ))}
            </FlexWrapper>
          </FlexWrapper>
        }
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default ScanPerformed