import React, { useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import BlackLogo from '../../../../../icon.logo.black.png';
import Switch from '@mui/material/Switch';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import { selectSaveFromSearchPerm, selectSearchForPropertyPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { AccentInfo, AlertWrapper, Button, ButtonWrapper, Divider, ErrorWrapper, FlexWrapper, HeaderText, ImageElements, InfoBubble, InputWrapper, Label, StatusAndLabelWrapper, StyledInputElement, TreeContainer, Typography } from "../../../../../StyledComponents";
import { selectUserRole } from "../../../../../slices/userInfoSlice";

const SearchSideBar = ({ allFieldsPresent, searchFetching, handlePropertySearch, responseError, setPropertyNotFound, setResponseError, propertyNotFound, nullForm, setNullForm, handleSaveProperty, saveError, existingMortgage, saveSuccess, handleShowAll, showAll, saveFunctionEnabled }) => {
  const theme = useTheme()
  
  const userRole = useSelector(selectUserRole)
  const saveFromSearchPerm = useSelector(selectSaveFromSearchPerm)
  const searchForPropertyPerm = useSelector(selectSearchForPropertyPerm)
  
  const [statusStreetAddress, setStatusStreetAddress] = useState(null)
  const streetAddress = useRef()
  const [statusCity, setStatusCity] = useState(null)
  const city = useRef()
  const [statusState, setStatusState] = useState(null)
  const state = useRef()
  const [statusPostalCode, ssetStatusPostalCode] = useState(null)
  const postalCode = useRef()

  const validateSearch = () => {
    setNullForm(false)
    setResponseError(false)
    setPropertyNotFound(false)
    let newPostalCode = parseFloat(postalCode.current.value)
    let negativePostalCode = Math.sign(newPostalCode)
    if (streetAddress.current.value.includes('<') || streetAddress.current.value.includes('>') || city.current.value.includes('<') || city.current.value.includes('>') || state.current.value.includes('<') || state.current.value.includes('>') || newPostalCode > 99999 || postalCode.current.value.includes('>') || postalCode.current.value.includes('<') || negativePostalCode === -1 || (isNaN(postalCode.current.value) && postalCode.current.value.length !== 0) || (streetAddress.current.value.length === 0 && city.current.value.length === 0 && state.current.value.length === 0 && postalCode.current.value.length === 0)) {
      if (streetAddress.current.value.includes('<') || streetAddress.current.value.includes('>')) {
        setStatusStreetAddress('invalid')
      }
      if (city.current.value.includes('<') || city.current.value.includes('>')) {
        setStatusCity('invalid')
      }
      if (state.current.value.includes('<') || state.current.value.includes('>')) {
        setStatusState('invalid')
      }
      if (newPostalCode > 99999 || postalCode.current.value.includes('>') || postalCode.current.value.includes('<') || negativePostalCode === -1 || isNaN(postalCode.current.value)) {
        ssetStatusPostalCode('invalid')
      }
      if ((streetAddress.current.value.length === 0 && city.current.value.length === 0 && state.current.value.length === 0 && postalCode.current.value.length === 0)) {
        setNullForm(true)
      }
    } else {
      handlePropertySearch(streetAddress.current.value, city.current.value, state.current.value, postalCode.current.value)
    }
  }

  return (
    <>
      <FlexWrapper style={{gridColumn: '1',gridRow: '1', paddingLeft: '2rem'}}>
        <ImageElements src={BlackLogo} style={{width: '2.5rem', height: '2.5rem', opacity: '0.5', margin: 'auto 0.5rem auto 0'}} />
        <Typography className="xxLarge secondary alignCenter" style={{height: '100%', paddingBottom: '0.1rem'}}>Lancaster Search</Typography>
      </FlexWrapper>
      <FlexWrapper className="column justifyBetween" style={{gridColumn: '1', gridRow: '2', height: '100%'}}>
        <Divider style={{height: '0.3rem'}} />
        <InfoBubble className="primary" style={{width: '25rem', margin: 'auto', width: '26rem'}}>
          <InfoIcon style={{color: `${theme.text.secondary.primary}`, margin: 'auto 1rem auto 0'}} />
          <Typography className="normal secondary">
            This information was sourced from public records.
            Records may be incomplete or innacurate.
          </Typography>
        </InfoBubble>
      </FlexWrapper>
      <TreeContainer className="fitContentHeight" style={{gridColumn: '1', gridRow: '3', width: '29rem', margin: '1rem auto 0 auto'}}>
        {saveError ?
          <ErrorWrapper className="fitContentHeight" style={{marginTop: '1rem'}}>
            <ErrorIcon />
            <FlexWrapper className="column">
              <Typography>Save failed</Typography>
              <Typography>Try again or contact your</Typography>
              <Typography>App Administrator</Typography>
            </FlexWrapper>
          </ErrorWrapper>
        : existingMortgage ?
          <AlertWrapper className="caution" style={{marginTop: '1rem'}}>
            <ErrorIcon style={{color: `${theme.accent.caution.primary}`}} />
            Property already saved to repository
          </AlertWrapper>
        : saveSuccess ?
          <AlertWrapper className="success" style={{marginTop: '1rem'}}>
            <CheckCircleIcon style={{color: `${theme.accent.success.secondary}`}} />
            Property saved.
          </AlertWrapper>
        :
          <FlexWrapper style={{height: '3rem'}}></FlexWrapper>
        }

        <ButtonWrapper className="selfAlignEnd marginAuto" style={{margin: '0 auto 0.6rem auto'}}>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: !saveFunctionEnabled ? `${theme.tooltip.info.color}` : `${theme.tooltip.error.background}`,
              backgroundColor: !saveFunctionEnabled ? `${theme.tooltip.info.background}` : `${theme.tooltip.error.background}`,
              border: !saveFunctionEnabled ? `0.3rem solid ${theme.tooltip.info.border}`: `${theme.tooltip.error.background}`,
              borderBottom: !saveFunctionEnabled ? `0.2rem solid ${theme.tooltip.info.border}` : `${theme.tooltip.error.background}`,
              opacity: '0',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -7],
              },
            }]}}
            title={!saveFunctionEnabled ?
              <FlexWrapper className="alignCenter" style={{padding: '0 1rem'}}>
                <WarningAmberIcon style={{marginBottom: '0.3rem'}} />
                <span style={{padding: '1rem 1rem 1rem 0.5rem', margin: '0', fontWeight: '600', textAlign: 'center'}}>
                  Property is saved to your repository
                </span>
              </FlexWrapper>
            : !saveFromSearchPerm ?
            <FlexWrapper>
              <span style={{padding: '1rem', margin: '0', fontWeight: '600', textAlign: 'center'}}>
                Elevated permissions required
              </span>
            </FlexWrapper>
          :
              ""
            }
            placement="top"
            arrow
          >
            <Button className={searchFetching ? "primaryLoading" : (saveFunctionEnabled && saveFromSearchPerm) ? "primary" : "primaryDisabled"} onClick={(saveFunctionEnabled && saveFromSearchPerm) ? () => handleSaveProperty() : null} style={{width: '23rem'}}>
              {searchFetching ?
                <CircularProgress
                  sx={{ color: `${theme.palette.common.white}` }}
                  size={18}
                />
              :
                <>
                  <SaveIcon style={{marginRight: '0.5rem'}} />
                  Save Property
                </>
              }
            </Button>
          </Tooltip>
        </ButtonWrapper>
              
        <FlexWrapper style={{justifyContent: 'center'}}>
          <Divider style={{width: '10rem'}} />
          <AccentInfo style={{width: '4.5rem', margin: '0'}}>-or-</AccentInfo>
          <Divider style={{width: '10rem'}} />
        </FlexWrapper>

        <FlexWrapper className="column" style={{width: '100%', height: 'fit-content', padding: '0 1rem'}}>
          {responseError || propertyNotFound || nullForm ?
            <StatusAndLabelWrapper style={{width: 'fit-content', margin: '0 auto'}}>
              <ErrorWrapper>
                {responseError ?
                  <>
                    <ErrorIcon />
                    Your search encountered an error. Please try again.
                  </>
                : nullForm ?
                  <>
                    <ErrorIcon />
                    Empty form.
                  </>
                : allFieldsPresent ?
                  <>
                    <SignalWifiBadIcon />
                    No results found. 
                  </>
                :
                  <>
                    <SignalWifiBadIcon />
                    Search failed. Please try again.
                  </>
                }
              </ErrorWrapper>
            </StatusAndLabelWrapper>
          :
            <div style={{height: '3rem'}}></div>
          }
          <HeaderText style={{margin: '0.5rem auto 0 auto'}}>
            Perform another search
          </HeaderText>
          <InputWrapper style={{width: '23rem', margin: '0 auto'}}>
            <StatusAndLabelWrapper className="justifyStart">
              <Label htmlFor="streetAddress">Street Address</Label>
              {(statusStreetAddress === "invalid" || statusCity === "invalid" || statusState === "invalid" || statusPostalCode === "invalid") && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Invalid Entries
                </ErrorWrapper>
              )}
            </StatusAndLabelWrapper>
            <StyledInputElement
              style={{width: '23rem'}}
              disabled={searchFetching || !searchForPropertyPerm}
              autoComplete="off"
              type="text"
              name="streetAddress"
              id="streetAddress"
              ref={streetAddress}
              className={statusStreetAddress === "invalid" ? 'error' : searchForPropertyPerm ? '' : 'disabled'}
              onChange={() => {
                if (statusStreetAddress === "invalid") {
                  setStatusStreetAddress(null)
                }
                if (nullForm) {
                    setNullForm(false)
                  }
              }}
            />
          </InputWrapper>
          <FlexWrapper style={{width: 'fit-content', margin: '0 auto'}}>
            <InputWrapper style={{width: '18rem'}}>
              <StatusAndLabelWrapper className="column">
                <Label htmlFor="city">City</Label>
              </StatusAndLabelWrapper>
              <StyledInputElement
                style={{width: '18rem'}}
                disabled={searchFetching || !searchForPropertyPerm}
                autoComplete="off"
                type="text"
                name="city"
                id="city"
                ref={city}
                className={statusCity === "invalid" ? 'error' : searchForPropertyPerm ? '' : 'disabled'}
                onChange={() => {
                  if (statusCity === "invalid") {
                    setStatusCity(null)
                  }
                  if (nullForm) {
                    setNullForm(false)
                  }
                }}
              />
            </InputWrapper>
          </FlexWrapper>
          <FlexWrapper style={{width: 'fit-content', margin: '0 auto'}}>
            <InputWrapper style={{width: '6rem', marginRight: '0.5rem'}}>
              <StatusAndLabelWrapper className="column">
                <Label htmlFor="state">State</Label>
              </StatusAndLabelWrapper>
              <StyledInputElement
                style={{width: '6rem'}}
                disabled={searchFetching || !searchForPropertyPerm}
                autoComplete="off"
                type="text"
                name="state"
                id="state"
                ref={state}
                className={statusState === "invalid" ? 'error' : searchForPropertyPerm ? '' : 'disabled'}
                onChange={() => {
                  if (statusState === "invalid") {
                    setStatusState(null)
                  }
                  if (nullForm) {
                    setNullForm(false)
                  }
                }}
              />
            </InputWrapper>
            <InputWrapper style={{width: '8rem', marginLeft: '0.5rem'}}>
              <StatusAndLabelWrapper className="column">
                <Label htmlFor="postalCode">Zip Code</Label>
              </StatusAndLabelWrapper>
              <StyledInputElement
                style={{width: '8rem'}}
                disabled={searchFetching || !searchForPropertyPerm}
                autoComplete="off"
                type="text"
                name="postalCode"
                id="postalCode"
                ref={postalCode}
                className={statusPostalCode === "invalid" ? 'error' : searchForPropertyPerm ? '' : 'disabled'}
                onChange={() => {
                  if (statusPostalCode === "invalid") {
                    ssetStatusPostalCode(null)
                  }
                  if (nullForm) {
                    setNullForm(false)
                  }
                }}
              />
            </InputWrapper>
          </FlexWrapper>

          <ButtonWrapper className="selfAlignEnd marginAuto" style={{margin: '1.5rem auto 2.5rem auto'}}>
            <Button 
              className={searchFetching ? 'neutralLoading' : (!searchForPropertyPerm || nullForm || responseError) ? "neutralDisabled" : "neutral"}
              disabled={searchFetching || !searchForPropertyPerm || nullForm || responseError}
              onClick={() => validateSearch()}
            >
              {searchFetching ?
                <CircularProgress
                  sx={{ color: `${theme.button.text.neutralLoading}` }}
                  size={18}
                />
              :
                <>
                  <SearchIcon style={{marginRight: '0.2rem'}} />
                  Search
                </>
              }
            </Button>
          </ButtonWrapper>
        </FlexWrapper>
        {userRole === 'super' ?
          <FlexWrapper className="alignCenter justifyCenter">
            <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
            <Typography>View all fields:</Typography>
            <Switch checked={showAll} onChange={(e) => handleShowAll(e)} />
          </FlexWrapper>
        :
          <></>
        }
      </TreeContainer>
    </>
  )
}

export default SearchSideBar;