import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";
import { setMemberAssignments } from "../../slices/memberAssignmentsSlice";
import { selectMembersDataArray } from "../../slices/membersDataArraySlice";
import { useDispatch, useSelector } from "react-redux";
import { teamDropdownStyles, dropdownMenuProps } from "../../componentThemes";
import { setDashPerspective, setDashUserFullName } from "../../slices/sessionDataSlice";

const TeamDropdown = ({ dashPerspective}) => {
  const theme = useTheme();
  const dropdown = teamDropdownStyles(theme);
  const dispatch = useDispatch()

  const memberData = useSelector(selectMembersDataArray)

  const handleChange = (event) => {
    dispatch( setDashPerspective(event.target.value) )
    let newMember = memberData.find(member => member.memberId === event.target.value)
    if (newMember) {
      dispatch( setMemberAssignments(newMember))
      dispatch( setDashUserFullName(newMember.fullName) )
    }
  }

  return (
    <FormControl className={dropdown.formControl}>
      <Select
        value={dashPerspective}
        onChange={handleChange}
        MenuProps={dropdownMenuProps}
        classes={{
          select: dropdown.select,
          icon: dropdown.selectIcon
        }}
        style={{justifyContent: 'center'}}
      >
      <MenuItem
        muiskiplisthighlight='true'
        disabled={dashPerspective === "team"}
        value="team"
        style={{justifyContent: 'center'}}
      >
        My Team
      </MenuItem>
        {memberData.length > 0 && memberData !== undefined ?
         memberData.map((teamMember, index) => (
          <MenuItem
            muiskiplisthighlight='true'
            key={teamMember.memberId}
            disabled={dashPerspective === teamMember.memberId}
            value={teamMember.memberId}
            style={{justifyContent: 'center'}}
          >
            {teamMember.fullName}
          </MenuItem>
        ))
        : <></>
      }
      </Select>
    </FormControl>
  );
};

export default TeamDropdown;
