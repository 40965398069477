import React, { useEffect, useState } from "react";
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from '@mui/material/FormControlLabel';
import { primaryRadioColorProps } from "../../../componentThemes";
import { selectViewingSweepParameter } from "../../../slices/viewingSweepParameterSlice";
import { selectEditParameterStatusPerm } from "../../../slices/userActionsPermissionsSlice";
import { useUpdateSweepParamterSettingsMutation } from "../../../slices/api/apiSlice";
import { ActionIconWrapper, ModalContainer, ComponentBorder, FlexWrapper, HeaderText, ButtonWrapper, Button, CardButton, Typography, TierButton } from "../../../StyledComponents";

const SweepParameterModal = ({ closeModal, userFullName }) => {
  const theme = useTheme()

  const sweepParameter = useSelector(selectViewingSweepParameter)
  const editParameterStatusPerm = useSelector(selectEditParameterStatusPerm)
  
  const [editParameterSettings] = useUpdateSweepParamterSettingsMutation()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [parameterTier, setParameterTier] = useState(sweepParameter.assignedTier)
  const [workflowSubmit, setWorkflowSubmit] = useState(false)
  const [parameterLabel, setParameterLabel] = useState(sweepParameter.label)
  const [parameterStatus, setParameterStatus] = useState(null)


  const parameterStatusControlProps = (item) => ({
    checked: parameterStatus === item,
    onChange: handleParameterStatusChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const handleParameterStatusChange = (event) => {
    setParameterStatus(event.target.value)
  }

  const parameterTierControlProps = (item) => ({
    checked: parameterTier === item,
    onChange: handleParameterTierChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const handleParameterTierChange = (event) => {
    setParameterTier(event.target.value)
  }

  const handleSubmit = async () => {
    setIsSuccess(false)
    setIsFetching(true)
    let newStatus = false
    if (parameterStatus === 'active') {
      newStatus = true
    }
    let resp = await editParameterSettings({
      parameterId: sweepParameter._id,
      userFullName: userFullName,
      status: newStatus,
      newTier: parameterTier,
      originalTier: sweepParameter.assignedTier,
      subCat: sweepParameter.subCategory,
    })
    if (resp.error) {
      setIsSuccess(true)
    }
    setWorkflowSubmit(false)
    setIsFetching(false)
    closeModal()
  }

  useEffect(() => {
    if (sweepParameter) {
      setParameterLabel(sweepParameter.label)
      setParameterTier(sweepParameter.assignedTier)
      if (sweepParameter.active) {
        setParameterStatus('active')
      } else {
        setParameterStatus('inactive')
      }
    }
  }, [sweepParameter])

  return (
    <ModalContainer className="onePanel small">
      <ComponentBorder className="top" style={{gridColumn: '1/4'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => closeModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>

      <FlexWrapper className="column justifyBetween alignCenter" style={{height: '100%', width: 'fit-content', margin: '0 auto'}}>
        <FlexWrapper className="justifyCenter" style={{height: '2rem', margin: '1rem 0 0 0'}}>
          <TierButton 
            className={(parameterTier === 'two' ? 'two' : parameterTier === 'one' ? 'one' : 'three') + ' noHover'} 
            style={{width: 'fit-content', padding: '0.3rem 1rem', margin: '0'}}
          >
            {parameterLabel}
          </TierButton>
          {parameterStatus === 'active' ?
            <span style={{width: '5rem', margin: 'auto 0 auto 2rem', color: `${theme.palette.primary.main}`}}>ACTIVE</span>
          :
            <span style={{width: '5rem', margin: 'auto 0 auto 2rem', color: `${theme.palette.common.grayBorder}`}}>INACTIVE</span>
          }
        </FlexWrapper>
        <FlexWrapper className="column" style={{height: 'fit-content', margin: 'auto 0'}}>
          <FlexWrapper className="column" style={{height: '3rem'}}>
            <FlexWrapper>
              <HeaderText className="center" style={{padding: '0 0.5rem 0 0'}}>
                Status
              </HeaderText>
              <FormControl style={{margin: '0 0 0 3rem'}}>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterStatusControlProps('active')} 
                  />
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterStatusControlProps('inactive')} 
                  />
                </RadioGroup>
              </FormControl>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper className="column" style={{height: '3rem'}}>
            <FlexWrapper>
              <HeaderText className="center" style={{padding: '0 0.5rem 0 0'}}>
                Tier
              </HeaderText>
              <FormControl style={{margin: '0 0 0 3rem'}}>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterTierControlProps('one')} 
                  />
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterTierControlProps('two')} 
                  />
                  <FormControlLabel
                    control={<Radio {...primaryRadioColorProps()} />}
                    {...parameterTierControlProps('three')} 
                  />
                </RadioGroup>
              </FormControl>
            </FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>
        {workflowSubmit ?
          <ButtonWrapper style={{margin: '0 auto 1rem auto'}}>
            <Button 
              className={isFetching ? 'primaryLoading' : isSuccess ? "primaryDisabled" : "primary"}
              disabled={isFetching || isSuccess}
              style={{margin: '0 0.25rem 0 0', height: '3rem', width: '3rem'}}
            >
              {isFetching ? (
                <CircularProgress
                  sx={{color: `${theme.button.text.primary}`}}
                  size={12}
                />
              ) : (
                <CheckIcon style={{height: '2rem', width: '2rem'}} />
              )}
            </Button>
            <Button 
              className="secondary confirm" 
              onClick={() => setWorkflowSubmit(false)} 
              style={{margin: '0 0 0 0.25rem', height: '3rem', width: '3rem'}}
            >
              <ClearIcon style={{height: '2rem', width: '2rem'}} />
            </Button>
          </ButtonWrapper>
        :
          <ButtonWrapper style={{margin: '0 auto 1rem auto'}}>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.permission.color}`,
                backgroundColor: `${theme.tooltip.permission.background}`,
                border: `0.5rem solid ${theme.tooltip.permission.border}`,
                borderBottom: `0.2rem solid ${theme.tooltip.permission.border}`,
                opacity: '0',
                width: '20rem',
                minWidth: '20rem',
                padding: '2rem 0',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={!editParameterStatusPerm ?
                <FlexWrapper className="column">
                  <Typography className="justifyCenter alignCenter">
                    Elevated authority required.
                  </Typography>
                </FlexWrapper>
              :
                ''
              } 
              placement="top"
              arrow
            >
              <Button 
                className={!editParameterStatusPerm ? "primaryDisabled" : "primary"}
                disabled={!editParameterStatusPerm}
                onClick={editParameterStatusPerm ? () => setWorkflowSubmit(true) : null}
                style={{width: '8rem', margin: '0'}}
              >
                Submit
              </Button>
            </Tooltip>
          </ButtonWrapper>
        }
      </FlexWrapper>

      <ComponentBorder className="left" style={{gridRow:'2/4'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/4', gridColumn: '3'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'3'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default SweepParameterModal;