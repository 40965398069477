import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";
import { AccentInfo } from "../../StyledComponents";
import { dropdownStyles, dropdownMenuProps } from "../../componentThemes";

const RepositoryTagsDropdown = ({ value, handleChange, items, disabled, origin }) => {
  const theme = useTheme()
  const dropdown = dropdownStyles(theme)

  return (
    <FormControl className={dropdown.formControl} style={{maxWidth: origin === 'table' ? '30rem' : '61rem', minWidth: '15rem'}}>
      <Select
        disabled={disabled}
        multiple
        value={value}
        onChange={handleChange}
        MenuProps={dropdownMenuProps}
        classes={{
          select: dropdown.select,
          icon: dropdown.selectIcon
        }}
      >
        {origin === 'table' &&
          <MenuItem
            key={'all'}
            disabled={value === 'all'}
            value={'all'}
          >
            All
          </MenuItem>
        }
        {items && items.length > 0 ?
          items.map((tag) => (
            !tag.customTagView &&
              <MenuItem
                key={tag.tagType}
                disabled={value === tag.tagType}
                value={tag.tagType}
              >
                {tag.tagType}
              </MenuItem>
            ))
        :
          <MenuItem disabled>
            <AccentInfo>
              error - no tags to assign
            </AccentInfo>
          </MenuItem>
        }
      </Select>
    </FormControl>
  )
}

export default RepositoryTagsDropdown
