import React, { forwardRef, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-csv-importer/dist/index.css';
import "../../../../../styles.css";
import moment from "moment";
import UndoIcon from '@mui/icons-material/Undo';
import ErrorIcon from "@mui/icons-material/Error";
import DatePicker from 'react-datepicker';
import { useTheme } from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {addMonths, subMonths} from 'date-fns';
import { ErrorWrapper, StyledInputElement, FlexWrapper, Typography, Divider, ActionIconWrapper, AccentInfo } from "../../../../../StyledComponents";

const FinalizeForm = ({ setStartDate, startDate, statusTerm, mortgageTermValueStr, setStatusTerm, setMortgageTermValueStr, setMortgageTermValue, statusPrincipal, principalValueStr, setStatusPrincipal, setPrincipalValueStr, setPrincipalValue, statusInterest, setStatusInterest, setInterestValueStr, setInterestValue, interestValueStr, termRequired, interestRequired, principalRequired, dateRequired, setTermRequired, setDateRequired, setInterestRequired, setPrincipalRequired, termPresent, datePresent, interestPresent, principalPresent, mortgageData, setStatusDate, statusDate, leadOutcome, handleNoChanges, dateUpdated, termUpdated, amountUpdated, interestUpdated, mortgageTermValue, principalValue, interestValue, leadData }) => {
  const theme = useTheme()

  const updatedTerm = useRef()
  const updatedInterest = useRef()
  const updatedPrincipal = useRef()

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="styled-calendar-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ))

  const handleResetDate = () => {
    const today = new Date(mortgageData.originationDate.slice(0,4)+'-'+mortgageData.originationDate.slice(5,10))
    const tomorrow = new Date(today)
    // tomorrow.setDate(tomorrow.getDate() + 1)
    setStartDate(tomorrow)
    setStatusDate(null)
    if (!dateUpdated) {
      handleNoChanges(tomorrow, mortgageTermValue, principalValue, interestValue)
    }
  }
  const handleResetTerm = () => {
    if (leadOutcome === 'refinance' && leadData.targetLoanTerm) {
      setMortgageTermValueStr(mortgageData.targetLoanTerm.toString())
      setMortgageTermValue(mortgageData.targetLoanTerm)
      setStatusTerm(null)
      if (!amountUpdated) {
        handleNoChanges(startDate, mortgageData.targetLoanTerm, principalValue, interestValue)
      }
    } else {
      setMortgageTermValueStr(mortgageData.mortgageTerm.toString())
      setMortgageTermValue(mortgageData.mortgageTerm)
      setStatusTerm(null)
      if (!amountUpdated) {
        handleNoChanges(startDate, mortgageData.mortgageTerm, principalValue, interestValue)
      }
    }
  }
  const handleResetPrincipal = () => {
    if (leadOutcome === 'refinance' && mortgageData.principalRemaining) {
      setPrincipalValueStr(parseInt(mortgageData.principalRemaining).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0}))
      setPrincipalValue(parseFloat(mortgageData.principalRemaining))
      setStatusPrincipal(null)
      if (!amountUpdated) {
        handleNoChanges(startDate, mortgageTermValue, parseFloat(mortgageData.principalRemaining), interestValue)
      }
    } else {
      setPrincipalValueStr(parseInt(mortgageData.originalLoanAmount).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0}))
      setPrincipalValue(parseFloat(mortgageData.originalLoanAmount))
      setStatusPrincipal(null)
      if (!amountUpdated) {
        handleNoChanges(startDate, mortgageTermValue, parseFloat(mortgageData.originalLoanAmount), interestValue)
      }
    }
  }
  const handleResetInterest = () => {
    if (leadOutcome === 'refinance' && leadData.targetInterestRate) {
      setInterestValueStr(mortgageData.targetInterestRate)
      setInterestValue(parseFloat(mortgageData.targetInterestRate))
      setStatusInterest(null)
      if (!interestUpdated) {
        handleNoChanges(startDate, mortgageTermValue, principalValue, parseFloat(mortgageData.targetInterestRate))
      }
    } else {
      setInterestValueStr(mortgageData.originalInterestRate)
      setInterestValue(parseFloat(mortgageData.originalInterestRate))
      setStatusInterest(null)
      if (!interestUpdated) {
        handleNoChanges(startDate, mortgageTermValue, principalValue, parseFloat(mortgageData.originalInterestRate))
      }
    }
  }

  return (
    <FlexWrapper className="column fitContentHeight alignCenter" style={{padding: '1rem 0 1rem 1rem', margin: 'auto'}}>
      <Typography className="xLarge" style={{marginRight: 'auto'}}>
        Financial Details
      </Typography>
      <FlexWrapper className="justifyBetween" style={{width: '70rem', padding: '1rem 4rem 2rem 0rem'}}>
        <FlexWrapper className="column justifyBetween alignEnd" style={{width: '13rem', height: '6rem', marginTop: 'auto'}}>
          <FlexWrapper className='column justifyEnd alignEnd'>
            {dateRequired && (
              <ErrorWrapper className="fitContentWidth">
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Required
              </ErrorWrapper>
            )}
            {statusDate === "discrepancy" && (
              <ErrorWrapper className="caution" style={{maxWidth: '10rem', textWrap: 'wrap', height: '3.5rem', alignItems: 'flex-start', padding: '0.1rem 0.4rem'}}>
                <ReportProblemIcon style={{width: '1.2rem', height: '1.2rem', marginTop: '0.2rem'}} />
                Creates Discrepancy
              </ErrorWrapper>
            )}
            <Typography className="fitContentHeight">
              Mortgage Start Date
            </Typography>
            <FlexWrapper className="column justifyEnd fitContentWidth" style={{height: '2.5rem'}}>
              <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem'}}>
                {/* {statusDate === "discrepancy" && (
                  <ActionIconWrapper className="neutral" style={{margin: '0 0.5rem 0.3rem 0'}}>
                    <UndoIcon style={{height: '1.9rem', width: '1.9rem'}} onClick={() => handleResetDate()} />
                  </ActionIconWrapper>
                )} */}
                <DatePicker 
                  selected={startDate}
                  onChange={(date) => {
                    if (datePresent && (moment(date).format('MMM Do, YYYY') !== moment(mortgageData.originationDate).format('MMM Do, YYYY'))) {
                      setStatusDate("discrepancy")
                    } else if (datePresent && (moment(date).format('MMM Do, YYYY') === moment(mortgageData.originationDate).format('MMM Do, YYYY'))) {
                      setStatusDate(null)
                    }                  
                    if (!dateUpdated) {
                      handleNoChanges(date, mortgageTermValue, principalValue, interestValue)
                    }  
                    setStartDate(date)
                    setDateRequired(false)
                  }}
                  maxDate={addMonths(new Date(), 12)}
                  minDate={subMonths(new Date(), 360)}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={10}
                  withPortal
                  portalId="root-portal"
                  customInput={<ExampleCustomInput />}
                  dayClassName={() => "example-datepicker-day-class"}
                  calendarClassName="example-datepicker-class"
                  todayButton="CLICK FOR TODAY'S DATE"
                  disabled={leadOutcome === 'renegotiation' && datePresent}
                  style={{cursor: leadOutcome === 'renegotiation' && datePresent ? 'not-allowed' : ''}}
                />
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>

        <FlexWrapper className="column justifyBetween alignEnd" style={{width: '13rem', height: '6rem'}}>
          <FlexWrapper className='column justifyEnd alignEnd'>
            {(termRequired && !statusTerm) && (
              <ErrorWrapper className="fitContentWidth">
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Required
              </ErrorWrapper>
            )}
            {statusTerm === "nonNumber" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Must be a number
              </ErrorWrapper>
            )}
            {statusTerm === "negative" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be negative
              </ErrorWrapper>
            )}
            {statusTerm === "zero" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be zero
              </ErrorWrapper>
            )}
            {statusTerm === "large" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                More than 100
              </ErrorWrapper>
            )}
            {statusTerm === "discrepancy" && (
              <ErrorWrapper className="caution" style={{maxWidth: '10rem', textWrap: 'wrap', height: '3.5rem', alignItems: 'flex-start', padding: '0.1rem 0.4rem'}}>
                <ReportProblemIcon style={{width: '1.2rem', height: '1.2rem', marginTop: '0.2rem'}} />
                Creates Discrepancy
              </ErrorWrapper>
            )}
            <Typography className="fitContentHeight">
              Mortgage Term
            </Typography>
          </FlexWrapper>
          <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem'}}>
            {/* {statusTerm === "discrepancy" && (
              <ActionIconWrapper className="neutral" style={{margin: '0 0.5rem 0.3rem 0'}}>
                <UndoIcon style={{height: '1.9rem', width: '1.9rem'}} onClick={() => handleResetTerm()} />
              </ActionIconWrapper>
            )} */}
            <StyledInputElement className="borderBottomOnly" 
              style={{width: '3rem', padding: '0 0.7rem 0 0', height: 'fit-content', textAlign: 'end', 
                cursor: leadOutcome === 'renegotiation' && termPresent ? 'not-allowed' : ''
              }}
              value={mortgageTermValueStr}
              disabled={leadOutcome === 'renegotiation' && termPresent}
              type="text"
              name="term"
              id="term"
              tabIndex="1"
              ref={updatedTerm}
              onChange={() => {
                if (isNaN(parseInt(updatedTerm.current.value))) {
                  setStatusTerm("nonNumber")
                } else if (Math.sign(parseInt(updatedTerm.current.value)) === -1) {
                  setStatusTerm("negative")
                } else if (Math.sign(parseInt(updatedTerm.current.value)) === 0) {
                  setStatusTerm("zero")
                } else if (parseInt(updatedTerm.current.value) > 100) {
                  setStatusTerm("large")
                } else if (statusTerm === "nonNumber" || statusTerm === "negative" || statusTerm === "zero" || statusTerm === "large" || termRequired) {
                  setStatusTerm(null)
                  setTermRequired(false)
                } else if (termPresent && (parseInt(updatedTerm.current.value) !== parseInt(mortgageData.mortgageTerm))) {
                  setStatusTerm("discrepancy")
                } else if (termPresent && (parseInt(updatedTerm.current.value) === parseInt(mortgageData.mortgageTerm))) {
                  setStatusTerm(null)
                }
                if (!termUpdated) {
                  handleNoChanges(startDate, updatedTerm.current.value, principalValue, interestValue)
                }
                setMortgageTermValueStr(updatedTerm.current.value.toString())
                setMortgageTermValue(updatedTerm.current.value)
              }}
            />
            <span style={{margin: 'auto 0 0 0.5rem'}}>years</span>
          </FlexWrapper>
        </FlexWrapper>

        <FlexWrapper className="column justifyBetween alignEnd" style={{width: '15rem', height: '6rem'}}>
          <FlexWrapper className='column justifyEnd alignEnd'>
            {(principalRequired && !statusPrincipal) && (
              <ErrorWrapper className="fitContentWidth">
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Required
              </ErrorWrapper>
            )}
            {statusPrincipal === "nonNumber" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Must be a number
              </ErrorWrapper>
            )}
            {statusPrincipal === "negative" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be negative
              </ErrorWrapper>
            )}
            {statusPrincipal === "zero" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be zero
              </ErrorWrapper>
            )}
            {statusPrincipal === "large" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Larger than 100m
              </ErrorWrapper>
            )}
            {statusPrincipal === "discrepancy" && (
              <ErrorWrapper className="caution" style={{maxWidth: '10rem', textWrap: 'wrap', height: '3.5rem', alignItems: 'flex-start', padding: '0.1rem 0.4rem'}}>
                <ReportProblemIcon style={{width: '1.2rem', height: '1.2rem', marginTop: '0.2rem'}} />
                Creates Discrepancy
              </ErrorWrapper>
            )}
            <Typography className="fitContentHeight" style={{paddingRight: '1rem'}}>
              Loan Amount
            </Typography>
          </FlexWrapper>
          <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem'}}>
            {/* {statusPrincipal === "discrepancy" && (
              <ActionIconWrapper className="neutral" style={{margin: '0 0.5rem 0.3rem 0'}}>
                <UndoIcon style={{height: '1.9rem', width: '1.9rem'}} onClick={() => handleResetPrincipal()} />
              </ActionIconWrapper>
            )} */}
            <span style={{color: `${theme.palette.primary.main}`, fontWeight: '600', padding: '0 0 0.1rem 0'}}>$</span>
            <StyledInputElement className="borderBottomOnly" 
              style={{width: '7.5rem', height: 'fit-content', padding: '0 0 0 0.3rem', 
                cursor: leadOutcome === 'renegotiation' && principalPresent ? 'not-allowed' : ''
              }}
              value={principalValueStr ? principalValueStr : ""}
              disabled={leadOutcome === 'renegotiation' && principalPresent}
              type="text"
              name="principal"
              id="principal"
              tabIndex="2"
              ref={updatedPrincipal}
              onChange={() => {
                if (!/^[0-9,.]*$/.test(updatedPrincipal.current.value)) {
                  setStatusPrincipal("nonNumber")
                } else if (Math.sign(parseInt(updatedPrincipal.current.value)) === -1) {
                  setStatusPrincipal("negative")
                } else if (Math.sign(parseInt(updatedPrincipal.current.value)) === 0) {
                  setStatusPrincipal("zero")
                } else if (parseInt(updatedPrincipal.current.value.replace(/,/g, "")) > 100000000) {
                  setStatusPrincipal("large")
                } else if (statusPrincipal === "nonNumber" || statusPrincipal === "negative" || statusPrincipal === "zero" || statusPrincipal === "large" || principalRequired) {
                  setStatusPrincipal(null)
                  setPrincipalRequired(false)
                } else if (principalPresent && (parseInt(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')) !== parseInt(mortgageData.originalLoanAmount))) {
                  setStatusPrincipal("discrepancy")
                } else if (principalPresent && (parseInt(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')) === parseInt(mortgageData.originalLoanAmount))) {
                  setStatusPrincipal(null)
                }
                if ((parseInt(updatedPrincipal.current.value.replace(/,/g, "")) < 100000000 || (isNaN(parseInt(updatedPrincipal.current.value.replace(/,/g, ""))))) && updatedPrincipal.current.value.length < 12) {
                  if (!amountUpdated) {
                    handleNoChanges(startDate, mortgageTermValue, parseInt(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')), interestValue)
                  }
                  if (!isNaN(parseInt(updatedPrincipal.current.value))) {
                    setPrincipalValueStr(parseInt(updatedPrincipal.current.value.replace(/,/g, "")).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0}))
                  } else {
                    setPrincipalValueStr(updatedPrincipal.current.value)
                  }
                  setPrincipalValue(parseInt(updatedPrincipal.current.value.replace(/[^0-9-.]/g, '')))
                }
              }}
            />
          </FlexWrapper>
        </FlexWrapper>

        <FlexWrapper className="column justifyBetween alignEnd" style={{width: '13rem', height: '6rem'}}>
          <FlexWrapper className='column justifyEnd alignEnd'>
            {(interestRequired && !statusInterest) && (
              <ErrorWrapper className="fitContentWidth">
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Required
              </ErrorWrapper>
            )}
            {statusInterest === "nonNumber" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Must be a number
              </ErrorWrapper>
            )}
            {statusInterest === "negative" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be negative
              </ErrorWrapper>
            )}
            {statusInterest === "zero" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                Can't be zero
              </ErrorWrapper>
            )}
            {statusInterest === "large" && (
              <ErrorWrapper>
                <ErrorIcon style={{width: '1.2rem', height: '1.2rem'}} />
                More than 100
              </ErrorWrapper>
            )}
            {statusInterest === "discrepancy" && (
              <ErrorWrapper className="caution" style={{maxWidth: '10rem', textWrap: 'wrap', height: '3.5rem', alignItems: 'flex-start', padding: '0.1rem 0.4rem'}}>
                <ReportProblemIcon style={{width: '1.2rem', height: '1.2rem', marginTop: '0.2rem'}} />
                Creates Discrepancy
              </ErrorWrapper>
            )}
            <Typography className="fitContentHeight">
              Interest Rate
            </Typography>
          </FlexWrapper>
          <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem'}}>
            {/* {statusInterest === "discrepancy" && (
              <ActionIconWrapper className="neutral" style={{margin: '0 0.5rem 0.3rem 0'}}>
                <UndoIcon style={{height: '1.9rem', width: '1.9rem'}} onClick={() => handleResetInterest()} />
              </ActionIconWrapper>
            )} */}
            <StyledInputElement className="borderBottomOnly" 
              style={{width: '3rem', padding: '0 0.7rem 0 0', height: 'fit-content', textAlign: 'end', 
                cursor: leadOutcome === 'renegotiation' && interestPresent ? 'not-allowed' : ''
              }}
              value={interestValueStr}
              disabled={leadOutcome === 'renegotiation' && interestPresent}
              type="text"
              name="interest"
              id="interest"
              tabIndex="3"
              ref={updatedInterest}
              onChange={() => {
                if (isNaN(parseFloat(updatedInterest.current.value))) {
                  setStatusInterest("nonNumber")
                } else if (Math.sign(parseFloat(updatedInterest.current.value)) === -1) {
                  setStatusInterest("negative")
                } else if (Math.sign(parseFloat(updatedInterest.current.value)) === 0) {
                  setStatusInterest("zero")
                } else if (parseFloat(updatedInterest.current.value) > 100) {
                  setStatusInterest("large")
                } else if (statusInterest === "nonNumber" || statusInterest === "negative" || statusInterest === "zero" || statusInterest === "large" || interestRequired) {
                  setStatusInterest(null)
                  setInterestRequired(false)
                } else if (interestPresent && (parseFloat(updatedInterest.current.value) !== parseFloat(mortgageData.originalInterestRate))) {
                  setStatusInterest("discrepancy")
                } else if (interestPresent && (parseFloat(updatedInterest.current.value) === parseFloat(mortgageData.originalInterestRate))) {
                  setStatusInterest(null)
                }
                if (!interestUpdated) {
                  handleNoChanges(startDate, mortgageTermValue, principalValue, parseFloat(updatedInterest.current.value))
                }
                setInterestValueStr(updatedInterest.current.value)
                setInterestValue(parseFloat(updatedInterest.current.value))
              }}
            />
            <span style={{margin: 'auto 0 0 0.5rem'}}>%</span>
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
      <Divider className="darkGray" style={{width: '90%', margin: '0 0 1rem 0'}} />
      {leadOutcome === 'renegotiation' &&
        <AccentInfo>
          Switch to a Refinance if changes were made to the original mortgage terms.
        </AccentInfo>
      }
    </FlexWrapper>
  )
}

export default FinalizeForm