import React, { useState } from "react"
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/styles";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from "react-redux";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NewInvestigationRow from "./components/NewInvestigationRow";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { selectDashPerspective, selectLeadsFilteredBoolean } from "../../../../slices/sessionDataSlice";
import { selectTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads } from "../../../../slices/teamActiveLeadsSlice";
import { FlexWrapper, ColumnContent, ComponentBorder, ComponentContainer, ComponentSectionRow, HeaderText, RowsContainer, ComponentHeader, Typography, ActionIconWrapper, ScrollWindow } from "../../../../StyledComponents";

const ReportsWindow = ({ teamUploadReports, origin }) => {
  const theme = useTheme()

  const [viewingDetails, setViewingDetails] = useState(false)
  const [viewingDetailsReport, setViewingDetailsReport] = useState(null)

  const dashPerspective = useSelector(selectDashPerspective)
  const teamClosingLeads = useSelector(selectTeamClosingLeads)
  const leadsFilteredBoolean = useSelector(selectLeadsFilteredBoolean)
  const teamInvestigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const teamAwaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const teamAwaitingUpdateLeads = useSelector(selectTeamAwaitingUpdateLeads)

  const handleShowDetails = (report) => {
    setViewingDetailsReport(report)
    setViewingDetails(true)
  }
  const handleCloseDetails = () => {
    setViewingDetailsReport(null)
    setViewingDetails(false)
  }

  return (
    <ComponentContainer 
      className="detailsContainer"
      style={{height: '100%', borderRadius: '0', gridTemplateRows: '3rem 1fr 0.5rem', gridTemplateColumns: origin !== 'adminPanel' ? '0.5rem 1fr 0.5rem' : '0 1fr 0'}}
    >
      {!viewingDetails ?
        <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
          <ComponentHeader className="subHeader" style={{width: '100%', margin: '0', padding: '0.4rem 0 0.2rem 5rem', fontSize: '1rem', fontWeight: '400', textTransform: 'none', justifyContent: 'flex-start'}}>
            *Click to expand uploads with investigations, errors, or duplicates
          </ComponentHeader>
          <ComponentSectionRow className="header" style={{display: 'grid', gridTemplateColumns: '1fr 11.2rem 6rem 3rem 3rem 3rem 3rem', padding: '0 0.5rem'}}>
            <ColumnContent className="header center" style={{gridColumn: '1'}}>
              Contributor
            </ColumnContent>
            <ColumnContent className="header center" style={{gridColumn: '2'}}>
              Type
            </ColumnContent>
            <ColumnContent className="header center" style={{gridColumn: '3'}}>
              Date
            </ColumnContent>
            <ColumnContent className="header center" style={{gridColumn: '4'}}>
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.3rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.2rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="bold alignTextCenter">
                      Successful uplaods
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <CheckCircleOutlineIcon />
              </Tooltip>
            </ColumnContent>
            <ColumnContent className="header center" style={{gridColumn: '5'}}>
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.3rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.2rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="bold alignTextCenter">
                      Investigations opened
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <FlagCircleIcon />
              </Tooltip>
            </ColumnContent>
            <ColumnContent className="header center" style={{gridColumn: '6'}}>
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.3rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.2rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="bold alignTextCenter">
                      Upload errors
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <ErrorOutlineIcon />
              </Tooltip>
            </ColumnContent>
            <ColumnContent className="header center" style={{gridColumn: '7'}}>
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.3rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.2rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="bold alignTextCenter">
                      Previously existing or duplicates
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <ContentCopyIcon />
              </Tooltip>
            </ColumnContent>
          </ComponentSectionRow>
          {teamUploadReports.length > 0 ?
            <RowsContainer style={{height: 'fit-content'}}>
              {teamUploadReports.map((row, index) => (
                <Tooltip
                  componentsProps={{tooltip: {sx: {
                    color: `${theme.tooltip.caution.color}`,
                    backgroundColor: `${theme.tooltip.caution.background}`,
                    border: `0.1rem solid ${theme.tooltip.caution.border}`,
                    borderBottom: `0.1rem solid ${theme.tooltip.caution.border}`,
                    opacity: '0',
                    borderRadius: '1rem',
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    padding: '0.5rem 1rem',
                  }}}}
                  PopperProps={{modifiers: [{
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  }]}}
                  title={row.monthlyQueriesExhausted ?
                    <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        This upload exhausted your team's<br/>monthly queries.
                      </Typography>
                    </FlexWrapper>
                  : null} 
                  placement="top"
                  arrow
                  key={row._id} 
                >
                  <ComponentSectionRow                     
                    className={(index%2 === 1 ? 
                        'evenDark ' : '')
                      + (row.monthlyQueriesExhausted ? 
                        'caution ' : '')
                      + ((row.newLeadObjs.length > 0 || row.noResultsObjs.length > 0 || row.duplicateObjs.length > 0 || row.errorObjs.length > 0) ? 
                        'hover ' 
                      : 'noPointHover ')
                      + 'uploadReports'} 
                    style={{display: 'grid', gridTemplateColumns: '1fr 11.2rem 6rem 3rem 3rem 3rem 3rem', paddingLeft: '0.5rem'}}
                    onClick={(row.newLeadObjs.length > 0 || row.noResultsObjs.length > 0 || row.duplicateObjs.length > 0 || row.errorObjs.length > 0) ? () => handleShowDetails(row) : null}
                  >
                    <ColumnContent className="detail small" style={{gridColumn: '1'}}>
                      {row.contributor}
                    </ColumnContent>
                    <ColumnContent className="detail small" style={{gridColumn: '2', textTransform: 'none'}}>
                      {row.uploadType}
                    </ColumnContent>
                    <ColumnContent className="detail small" style={{gridColumn: '3'}}>
                      {row.date}
                    </ColumnContent>
                    <ColumnContent className="detail small" style={{gridColumn: '4'}}>
                      {row.numberSuccessess}
                    </ColumnContent>
                    <ColumnContent className="detail small" style={{gridColumn: '5'}}>
                      {row.numberNewLeads}
                    </ColumnContent>
                    <ColumnContent className="detail small" style={{gridColumn: '6'}}>
                      {row.numberErrors}
                    </ColumnContent>
                    <ColumnContent className="detail small" style={{gridColumn: '7'}}>
                      {row.numberDuplicates}
                    </ColumnContent>
                  </ComponentSectionRow>
                </Tooltip>
              ))}
            </RowsContainer>
          :
            <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', height: 'fit-content', margin: 'auto'}}>
              <DataObjectIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '7rem', width: '7rem'}} />
              <HeaderText style={{fontSize: '1.4rem', color: `${theme.palette.common.grayTextLight}`}}>no uploads recorded</HeaderText>
            </FlexWrapper>
          } 
        </FlexWrapper>
      :
        <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
          <ComponentSectionRow className="header" style={{height: '3rem'}}>
            <Typography className="subHeader" style={{width: '17rem', fontSize: '1.1rem', marginLeft: '1rem'}}>
              viewing upload from {viewingDetailsReport.date}
            </Typography>
            {viewingDetailsReport.monthlyQueriesExhausted &&
              <>
                <WarningAmberIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.orangeDark}`}} />
                <span style={{marginLeft: '0.2rem', color: `${theme.palette.common.grayText}`, fontSize: '0.9rem'}}>
                  Upload exhausted your team's monthly queries.
                </span>
              </>
            }
            <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 0.5rem auto auto', width: '2rem', height: '2rem'}}>
              <CancelIcon onClick={() => handleCloseDetails()} style={{height: '100%', width: '100%'}} />  
            </ActionIconWrapper>
          </ComponentSectionRow>
          {(viewingDetailsReport.newLeadObjs && viewingDetailsReport.newLeadObjs.length > 0) &&
            <>
              <ComponentHeader className="subHeader" style={{width: '100%', margin: '0', padding: '0.4rem 0 0.2rem 0', fontSize: '1.2rem'}}>
                Investigations created for
              </ComponentHeader>
              <ScrollWindow className="verticalOnly">
                {viewingDetailsReport.newLeadObjs.map((row, index) => (
                  <NewInvestigationRow row={row} dashPerspective={dashPerspective} teamClosingLeads={teamClosingLeads} teamInvestigatingLeads={teamInvestigatingLeads} teamAwaitingActionLeads={teamAwaitingActionLeads} teamAwaitingUpdateLeads={teamAwaitingUpdateLeads} leadsFilteredBoolean={leadsFilteredBoolean} />
                ))}
              </ScrollWindow>
            </>
          }
          {(viewingDetailsReport.noResultsObjs && viewingDetailsReport.noResultsObjs.length > 0) &&
            <>
              <ComponentHeader className="subHeader" style={{width: '100%', margin: '0', padding: '0.4rem 0 0.2rem 0', fontSize: '1.2rem'}}>
                No results returned for
              </ComponentHeader>
              <ScrollWindow className="verticalOnly">
                {viewingDetailsReport.noResultsObjs.map((row, index) => (
                  <ComponentSectionRow key={row.streetAddress} style={{justifyContent: 'center'}}>
                    <Typography style={{width: 'fit-content'}}>{row.streetAddress},</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.city},</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.state}</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.postalCode}</Typography>
                  </ComponentSectionRow>
                ))}
              </ScrollWindow>
            </>
          }
          {(viewingDetailsReport.duplicateObjs && viewingDetailsReport.duplicateObjs.length > 0 )&&
            <>
              <ComponentHeader className="subHeader" style={{width: '100%', margin: '0', padding: '0.4rem 0 0.2rem 0', fontSize: '1.2rem'}}>
                Existing properties or duplicates
              </ComponentHeader>
              <ScrollWindow className="verticalOnly">
                {viewingDetailsReport.duplicateObjs.map((row, index) => (
                  <ComponentSectionRow key={row.streetAddress} style={{justifyContent: 'center'}}>
                    <Typography style={{width: 'fit-content'}}>{row.streetAddress},</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.city},</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.state}</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.postalCode}</Typography>
                  </ComponentSectionRow>
                ))}
              </ScrollWindow>
            </>
          }
          {(viewingDetailsReport.errorObjs && viewingDetailsReport.errorObjs.length > 0) &&
            <>
              <ComponentHeader className="subHeader" style={{width: '100%', margin: '0', padding: '0.4rem 0 0.2rem 0', fontSize: '1.2rem'}}>
                Upload errors encountered with
              </ComponentHeader>
              <ScrollWindow className="verticalOnly">
                {viewingDetailsReport.errorObjs.map((row, index) => (
                  <ComponentSectionRow key={row.streetAddress} style={{justifyContent: 'center'}}>
                    <Typography style={{width: 'fit-content'}}>{row.streetAddress},</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.city},</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.state}</Typography>
                    <Typography style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.postalCode}</Typography>
                  </ComponentSectionRow>
                ))}
              </ScrollWindow>
            </>
          }
        </FlexWrapper>
      }
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}}>
        <ComponentHeader className="modal" style={{fontSize: '1.4rem'}}>Previous Uploads</ComponentHeader>
      </ComponentBorder>
      {origin !== 'adminPanel' &&
        <>
          <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
          <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
          <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
        </>
      }
    </ComponentContainer>
  )
}

export default ReportsWindow