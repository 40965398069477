import React from "react";

import { Divider, FlexWrapper, Typography } from "../../../../../StyledComponents";

const SelectedMemberInfo = ({selectedMemberInfoObj}) => {

  return (
    <FlexWrapper className="column justifyBetween" style={{maxHeight: '33rem', height: '25rem', padding: '3rem 0 2rem 0', maxWidth: '18rem'}}>
      <FlexWrapper className="column fitContentHeight">
        <Typography className="large" style={{paddingBottom: '0.5rem'}}>{selectedMemberInfoObj.fullName}</Typography>
        <FlexWrapper className="justifyBetween">
          <Typography>Date joined:</Typography>
          <Typography className="bold">{selectedMemberInfoObj.dateCreated}</Typography>
        </FlexWrapper>
        <FlexWrapper className="justifyBetween">
          <Typography>Last login:</Typography>
          <Typography className="bold">{selectedMemberInfoObj.lastLoginDate}</Typography>
        </FlexWrapper>
        <FlexWrapper className="justifyBetween">
          <Typography>Role:</Typography>
          <Typography className="bold">
            {selectedMemberInfoObj.role === 'admin' ?
              'Manager'
            : selectedMemberInfoObj.role === 'user' ?
              'User'
            :
              'Guest'
            }
          </Typography>
        </FlexWrapper>
        <FlexWrapper className="justifyBetween" style={{flexWrap: 'wrap'}}>
          <Typography>Email:</Typography>
          <Typography className="bold" style={{overflowWrap: 'anywhere'}}>{selectedMemberInfoObj.email}</Typography>
        </FlexWrapper>
      </FlexWrapper>

      <Divider style={{margin: '0.3rem 0'}} />

      <FlexWrapper className="column fitContentHeight">
        <Typography className="bold" style={{padding: '0 0.5rem 0.3rem 0'}}>Active Investigations</Typography>
        <FlexWrapper className="justifyBetween">
          <Typography style={{paddingLeft: '0.5rem'}}>Awaiting Action:</Typography>
          <Typography className="bold">{selectedMemberInfoObj.awaitingActionLeadsNum}</Typography>
        </FlexWrapper>
        <FlexWrapper className="justifyBetween">
          <Typography style={{paddingLeft: '0.5rem'}}>Under Investigation:</Typography>
          <Typography className="bold">{selectedMemberInfoObj.investigatingLeadsNum}</Typography>
        </FlexWrapper>
        <FlexWrapper className="justifyBetween">
          <Typography style={{paddingLeft: '0.5rem'}}>Closing:</Typography>
          <Typography className="bold">{selectedMemberInfoObj.closingLeadsNum}</Typography>
        </FlexWrapper>
        <FlexWrapper className="justifyBetween">
          <Typography style={{paddingLeft: '0.5rem'}}>Awaiting Update:</Typography>
          <Typography className="bold">{selectedMemberInfoObj.awaitingUpdateLeadsNum}</Typography>
        </FlexWrapper>
        <FlexWrapper className="justifyBetween">
          <Typography style={{paddingLeft: '0.5rem'}}>Awaiting Verification:</Typography>
          <Typography className="bold">{selectedMemberInfoObj.awaitingVerificationLeadsNum}</Typography>
        </FlexWrapper>
      </FlexWrapper>
      
    </FlexWrapper>
  )
}

export default SelectedMemberInfo