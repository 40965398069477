import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    mortgages: [],
    filteredMortgages: [],
    fwdViewingMortgage: null,
    bckViewingMortgage: null,
    mortgagesFilteredBoolean: false,
  },
  status: 'idle',
  error: null
}

export const teamMortgagesSlice = createSlice({
  name: 'teamMortgages',
  initialState,
  reducers: {
    setFilteredMortgages(state, action) {
      state.data.filteredMortgages = action.payload
    },
    setMortgagesFilteredBoolean(state, action) {
      state.data.mortgagesFilteredBoolean = action.payload
    },
    setFwdBckViewingMortgages(state, action) {
      if (!action.payload) {
        state.data.fwdViewingMortgage = null
        state.data.bckViewingMortgage = null
      } else {
        let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id.toString() === action.payload.toString())
        if (thisMortgage) {
          let thisIndex = state.data.mortgages.indexOf(thisMortgage)
          if (thisIndex || thisIndex === 0) {
            if (state.data.mortgages[thisIndex+1]) {
              state.data.fwdViewingMortgage = state.data.mortgages[thisIndex+1]
            } else if (thisIndex === 0) {
              state.data.fwdViewingMortgage = null
            } else {
              state.data.fwdViewingMortgage = state.data.mortgages[0]
            }
            if (state.data.mortgages[thisIndex-1]) {
              state.data.bckViewingMortgage = state.data.mortgages[thisIndex-1]
            } else if (thisIndex === state.data.mortgages.length-1) {
              state.data.bckViewingMortgage = null
            } else {
              state.data.bckViewingMortgage = state.data.mortgages[state.data.mortgages.length-1]
            }
          } else {
            state.data.fwdViewingMortgage = null
            state.data.bckViewingMortgage = null
          }
        }
      }
    },
    setFwdBckViewingFilteredMortgages(state, action) {
      if (!action.payload) {
        state.data.fwdViewingMortgage = null
        state.data.bckViewingMortgage = null
      } else {
        let thisMortgage = state.data.filteredMortgages.find(mortgage => mortgage._id.toString() === action.payload.toString())
        if (thisMortgage) {
          let thisIndex = state.data.filteredMortgages.indexOf(thisMortgage)
          if (thisIndex || thisIndex === 0) {
            if (state.data.filteredMortgages[thisIndex+1]) {
              state.data.fwdViewingMortgage = state.data.filteredMortgages[thisIndex+1]
            } else if (thisIndex === 0) {
              state.data.fwdViewingMortgage = null
            } else {
              state.data.fwdViewingMortgage = state.data.filteredMortgages[0]
            }
            if (state.data.filteredMortgages[thisIndex-1]) {
              state.data.bckViewingMortgage = state.data.filteredMortgages[thisIndex-1]
            } else if (thisIndex === state.data.filteredMortgages.length-1) {
              state.data.bckViewingMortgage = null
            } else {
              state.data.bckViewingMortgage = state.data.filteredMortgages[state.data.filteredMortgages.length-1]
            }
          } else {
            state.data.fwdViewingMortgage = null
            state.data.bckViewingMortgage = null
          }
        } else {
          state.data.fwdViewingMortgage = null
          state.data.bckViewingMortgage = null
        }
      }
    },
    addMortgagesFromSweep(state, action) { 
      for (let i = 0; i < action.payload.length; i++) {
        let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === action.payload[i]._id)
        let thisIndex = state.data.mortgages.indexOf(thisMortgage)
        state.data.mortgages[thisIndex] = action.payload[i]
      }
    },
    removeNotificationFromMortgage(state, action) { 
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id.toString() === action.payload.mortgageId.toString())
      if (thisMortgage) {
        let thisIndex = state.data.mortgages.indexOf(thisMortgage)
        state.data.mortgages[thisIndex].notifCount = null
      }
    }
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.mortgages = payload.data.mortgages
      }
    })
    .addMatcher(apiSlice.endpoints.editMortgage.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.saveProperty.matchFulfilled, (state, {payload}) => {
      if (payload.data.newMortgage) {
        state.data.mortgages.push(payload.data.newMortgage)
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.setStatusInvestigating.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.setStatusClosing.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.mortgageId)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages.splice(thisIndex, 1)
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.updateMortgageTags.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.updateMortgageAssignees.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetChanges.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.provideFinancialInformation.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      let thisIndex = state.data.mortgages.indexOf(thisMortgage)
      state.data.mortgages[thisIndex] = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.recordSweepQuery.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.newMortgage) {
        let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
        let thisIndex = state.data.mortgages.indexOf(thisMortgage)
        state.data.mortgages[thisIndex] = payload.data.newMortgage
      }
    })
    .addMatcher(apiSlice.endpoints.uploadCSVMortgages.matchFulfilled, (state, {payload}) => {
      state.data.mortgages.unshift(...payload.data.newMortgages)
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.newMortgages.length; i++) {
        let thisMortgage = state.data.mortgages.find(mortgage => mortgage._id === payload.data.newMortgages[i]._id)
        let thisIndex = state.data.mortgages.indexOf(thisMortgage)
        state.data.mortgages[thisIndex] = payload.data.newMortgages[i]
      }
    })
  }
})

export const { addMortgagesFromSweep, removeNotificationFromMortgage, setFwdBckViewingMortgages, setFilteredMortgages, setFwdBckViewingFilteredMortgages, setMortgagesFilteredBoolean } = teamMortgagesSlice.actions

export default teamMortgagesSlice.reducer

export const selectTeamMortgages = state => state.teamMortgages.data.mortgages
export const selectTotalTeamMortgages = state => state.teamMortgages.data.mortgages.length
export const selectTeamFilteredMortgages = state => state.teamMortgages.data.filteredMortgages
export const selectForwardViewingMortgage = state => state.teamMortgages.data.fwdViewingMortgage
export const selectBackwardViewingMortgage = state => state.teamMortgages.data.bckViewingMortgage
export const selectMortgagesFilteredBoolean = state => state.teamMortgages.data.mortgagesFilteredBoolean