import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {},
  status: 'idle',
  error: null
}

export const viewingLeadSlice = createSlice({
  name: 'viewingLead',
  initialState,
  reducers: {
    setViewingLead(state, action) {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.mission === 'single' || payload.data.mission === 'confirmUpdate') {
        if (payload.data.newLeads.length > 0) {
          console.log(payload.data.newLeads[0])
          state.data = payload.data.newLeads[0]
        }
      }
    })
    .addMatcher(apiSlice.endpoints.continueAwaitingUpdate.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.activeDiscrepancies > 0) {
        state.data = payload.data.newLead
      } else {
        state.data.timeline.push(payload.data.timelineAddition)
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.addMortgageTag.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.addMortgageAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus.length > 0) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadCreated || payload.data.leadExisting) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.newLead && payload.data.newLead.status) {
        if (payload.data.discardLead) {
          state.data = {}
        } else {
          state.data = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLead) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetUpdates.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusInvestigating.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusClosing.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.provideMortgageFinancialInformation.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadUpdated) {
        state.data = payload.data.newLead
      }
    })
  }
})

export const { setViewingLead } = viewingLeadSlice.actions

export default viewingLeadSlice.reducer

export const selectViewingLead = state => state.viewingLead.data
export const selectViewingLeadTimeline = state => state.viewingLead.data.timeline
