import React, { useCallback, useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import CompanyLogo from "../../../../Full.logo.navy.png";
import WelcomeModal from "../WelcomeModal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from "react-redux";
import RepositoryGuide from "./components/RepositoryGuide";
import { useSelector } from "react-redux";
import GuideNavigation from "./components/GuideNavigation";
import LeadClosingGuide from "./components/LeadClosingGuide";
import PerformanceGuide from "./components/PerformanceGuide";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TeamSettingsGuide from "./components/TeamSettingsGuide";
import LeadOverviewGuide from "./components/LeadOverviewGuide";
import ConfigurationGuide from "./components/ConfigurationGuide";
import NotificationsGuide from "./components/NotificationsGuide";
import PropertySearchGuide from "./components/PropertySearchGuide";
import ReportGenerationGuide from "./components/ReportGenerationGuide";
import LeadInvestigationGuide from "./components/LeadInvestigationGuide";
import { selectGuideNavSelection, selectShowWelcomeModal, setGuideNavSelection, setShowWelcomeModal } from "../../../../slices/sessionDataSlice";
import { FlexWrapper, PageContent, TreeContainer, Typography, ScrollWindow, HeaderContainer, InlineLink, Shadow, PageComponent } from "../../../../StyledComponents";

const Guide = () => {
  const dispatch = useDispatch()
  
  const navSelection = useSelector(selectGuideNavSelection)

  const [forwardArticle, setForwardArticle] = useState(null)
  const [backwardArticle, setBackwardArticle] = useState(null)
  const showWelcomeModal = useSelector(selectShowWelcomeModal)

  const handleGuideNavSelection = useCallback((dir) => {
    if (navSelection === 'leadOverview') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('investigatingLeads'))
      }
      setForwardArticle("Investigations")
      setBackwardArticle(null)
    } else if (navSelection === 'investigatingLeads') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('closingLeads'))
      } else if (dir === 'bck') {
        dispatch( setGuideNavSelection('leadOverview'))
      }
      setForwardArticle("Closing Investigations")
      setBackwardArticle("Overview")
    } else if (navSelection === 'closingLeads') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('performance'))
      } else if (dir === 'bck') {
        dispatch( setGuideNavSelection('investigatingLeads'))
      }
      setForwardArticle("Performance Metrics")
      setBackwardArticle("Investigations")
    } else if (navSelection === 'performance') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('repository'))
      } else if (dir === 'bck') {
        dispatch( setGuideNavSelection('closingLeads'))
      }
      setForwardArticle("Mortgage Repository")
      setBackwardArticle("Closing Leads")
    } else if (navSelection === 'repository') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('configuration'))
      } else if (dir === 'bck') {
        dispatch( setGuideNavSelection('performance'))
      }
      setForwardArticle("System Configuration")
      setBackwardArticle("Performance Metrics")
    } else if (navSelection === 'configuration') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('propertySearch'))
      } else if (dir === 'bck') {
        dispatch( setGuideNavSelection('repository'))
      }
      setForwardArticle("Property Search")
      setBackwardArticle("Mortgage Repository")
    } else if (navSelection === 'propertySearch') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('reports'))
      } else if (dir === 'bck') {
        dispatch( setGuideNavSelection('configuration'))
      }
      setForwardArticle("Report Generation")
      setBackwardArticle("System Configuration")
    } else if (navSelection === 'reports') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('notifications'))
      } else if (dir === 'bck') {
        dispatch( setGuideNavSelection('propertySearch'))
      }
      setForwardArticle("Notification System")
      setBackwardArticle("Property Search")
    } else if (navSelection === 'notifications') {
      if (dir === 'fwd') {
        dispatch( setGuideNavSelection('settings'))
      } else if (dir === 'bck') {
        dispatch( setGuideNavSelection('reports'))
      }
      setForwardArticle("Team Settings")
      setBackwardArticle("Report Generation")
    } else if (navSelection === 'settings') {
      if (dir === 'bck') {
        dispatch( setGuideNavSelection('notifications'))
      }
      setForwardArticle(null)
      setBackwardArticle("Notification System")
    }
  }, [navSelection, dispatch])

  useEffect(() => {
    handleGuideNavSelection(null)
  }, [handleGuideNavSelection, navSelection])

  return (
    <>
      <PageContent className="grid guide">
        <FlexWrapper style={{gridRow: '2'}}>
          <TreeContainer className="fitContentHeight">
            <FlexWrapper className="column">
              <HeaderContainer className="guide">
                <Typography className="guideHeader">Subjects</Typography>
              </HeaderContainer>
              <GuideNavigation navSelection={navSelection} />
            </FlexWrapper>
          </TreeContainer>
        </FlexWrapper>
        <PageComponent style={{gridColumn: '3', height: 'fit-content'}}>
          <FlexWrapper className="column fitContentHeight shadow">
            <HeaderContainer className="page alignEnd" style={{padding: '0.5rem 0'}}>
              <img alt="lancaster sweep guide" src={CompanyLogo} style={{width: '30rem', opacity: '0.6'}} />
              <Typography className="secondary xxLarge" style={{margin: '0 0 0.4rem 0.8rem'}}>usage guide</Typography>
            </HeaderContainer>
            <HeaderContainer className="page">
              <FlexWrapper className="justifyCenter">
                <InlineLink className={backwardArticle ? 'pageNav left' : 'pageNav left disabled'} onClick={() => handleGuideNavSelection('bck')}>
                  <ArrowBackIcon style={{marginRight: '0.5rem', height: '2rem', width: '2rem'}} />
                  <Typography className="large">{backwardArticle}</Typography>
                </InlineLink>
              </FlexWrapper>
              <FlexWrapper className="justifyCenter">
                <InlineLink className={forwardArticle ? 'pageNav right' : 'pageNav right disabled'} onClick={() => handleGuideNavSelection('fwd')}>
                  <Typography className="large">{forwardArticle}</Typography>
                  <ArrowForwardIcon style={{marginLeft: '0.5rem', height: '2rem', width: '2rem'}} />
                </InlineLink>
              </FlexWrapper>
            </HeaderContainer>
          </FlexWrapper>
          <ScrollWindow className="guide shadow">
            {navSelection === 'leadOverview' ?
              <LeadOverviewGuide />
            : navSelection === 'investigatingLeads' ?
              <LeadInvestigationGuide />
            : navSelection === 'closingLeads' ?
              <LeadClosingGuide />
            : navSelection === 'performance' ?
              <PerformanceGuide />
            : navSelection === 'repository' ?
              <RepositoryGuide />
            : navSelection === 'configuration' ?
              <ConfigurationGuide />
            : navSelection === 'propertySearch' ?
              <PropertySearchGuide />
            : navSelection === 'reports' ?
              <ReportGenerationGuide />
            : navSelection === 'notifications' ?
              <NotificationsGuide />
            :
              <TeamSettingsGuide />
            }
          </ScrollWindow>
          <Shadow />
        </PageComponent>
      </PageContent>

      <Dialog 
        open={showWelcomeModal}
        onClose={() => dispatch( setShowWelcomeModal(false) )}
        PaperProps={{ sx: {width: "70rem", minWidth: "70rem", maxWidth: "70rem", height: "30rem", minHeight: "30rem", maxHeight: "30rem"}}}
      >
        <WelcomeModal />
      </Dialog>
    </>
  )
}

export default Guide