import React, { useState } from "react";
import SearchForm from "./components/SearchForm";
import SearchResults from "./components/SearchResults";
import SearchSideBar from "./components/SearchSideBar";
import { useDispatch, useSelector } from "react-redux";
import { PageContent, } from "../../../../StyledComponents";
import { selectSearchForPropertyPerm } from "../../../../slices/userActionsPermissionsSlice";
import { selectPropertySearchResponse, selectPropertySearchSaveEnabled, setPropertySearchSaveEnabled } from "../../../../slices/sessionDataSlice";
import { useRunPropertySearchMutation, useSavePropertyMutation } from "../../../../slices/api/apiSlice";

const PropertySearch = ({ userFullName, teamId }) => {
  const dispatch = useDispatch()
  
  const saveFunctionEnabled = useSelector(selectPropertySearchSaveEnabled)
  const searchForPropertyPerm = useSelector(selectSearchForPropertyPerm)
  const propertySearchResponse = useSelector(selectPropertySearchResponse)

  const [runPropertySearch, {}] = useRunPropertySearchMutation()
  const [saveProperty, {isFetching, isSuccess, isError}] = useSavePropertyMutation()

  const [nullForm, setNullForm] = useState(false)
  const [saveError, setSaveError] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [responseError, setResponseError] = useState(false)
  const [searchFetching, setSearchFetching] = useState(false)
  const [allFieldsPresent, setAllFieldsPresent] = useState(false)
  const [propertyNotFound, setPropertyNotFound] = useState(false)
  const [existingMortgage, setExistingMortgage] = useState(false)
  
  const handlePropertySearch = async (streetAddress, city, state, postalCode) => {
    setSearchFetching(true)
    setSaveError(false)
    setExistingMortgage(false)
    setSaveSuccess(false)
    if (streetAddress && city && state && postalCode) {
      setAllFieldsPresent(true)
    }
    let resp = await runPropertySearch({
      userFullName: userFullName,
      streetAddress: streetAddress,
      city: city,
      state: state,
      postalCode: postalCode,
    })
    if (!resp.error) {
      if (resp.data.message.includes('success')) {
        if (resp.data.data.existingMortgage) {
          setExistingMortgage(true)
          dispatch( setPropertySearchSaveEnabled(false) )
        } else {
          dispatch( setPropertySearchSaveEnabled(true) )
        }
      } else if (resp.data.message.includes('results')) {
        setPropertyNotFound(true)
      } else {
        setResponseError(true)
      }
    } else {
      setResponseError(true)
    }
    setSearchFetching(false)
  }

  const handleSaveProperty = async () => {
    setSearchFetching(true)
    dispatch( setPropertySearchSaveEnabled(true) )
    let resp = await saveProperty({
      teamId: teamId,
      userFullName: userFullName,
      recordDetailsObj: propertySearchResponse.recordDetailsObj,
      AttomQueryAddress: propertySearchResponse.AttomQueryAddress,
      CoreLogicQueryAddress: propertySearchResponse.CoreLogicQueryAddress,
      PropMixQueryAddress: propertySearchResponse.PropMixQueryAddress,
      propMixSuccessDate: propertySearchResponse.propMixSuccessDate,
      attomSuccessDate: propertySearchResponse.attomSuccessDate,
      coreLogicSuccessDate: propertySearchResponse.coreLogicSuccessDate,
    })
    if (resp.error) {
      setSaveError(true)
      setExistingMortgage(false)
      setSaveSuccess(false)
    } else if (resp.data.message.includes('existing')) {
      setSaveError(false)
      setExistingMortgage(true)
      setSaveSuccess(false)
      dispatch( setPropertySearchSaveEnabled(false) )
    } else if (resp.data.message.includes('success')) {
      setSaveError(false)
      setExistingMortgage(false)
      setSaveSuccess(true)
      dispatch( setPropertySearchSaveEnabled(false) )
    }
    setSearchFetching(false)
  }

  const [showAll, setShowAll] = useState(false)
  const handleShowAll = (event) => {
    setShowAll(event.target.checked);
  };

  return (
    <PageContent className={propertySearchResponse.dataPresent ? 'grid reports' : 'flex reports'}>
      {!propertySearchResponse.dataPresent ?
        <SearchForm allFieldsPresent={allFieldsPresent} searchFetching={searchFetching} handlePropertySearch={handlePropertySearch} searchForPropertyPerm={searchForPropertyPerm} responseError={responseError} propertyNotFound={propertyNotFound} setNullForm={setNullForm} nullForm={nullForm} setPropertyNotFound={setPropertyNotFound} setResponseError={setResponseError} />
      :
        <>
          <SearchSideBar allFieldsPresent={allFieldsPresent} searchFetching={searchFetching} handlePropertySearch={handlePropertySearch} setPropertyNotFound={setPropertyNotFound} setResponseError={setResponseError} propertyNotFound={propertyNotFound} nullForm={nullForm} setNullForm={setNullForm} handleSaveProperty={handleSaveProperty} saveError={saveError} existingMortgage={existingMortgage} saveSuccess={saveSuccess} handleShowAll={handleShowAll} showAll={showAll} saveFunctionEnabled={saveFunctionEnabled} />
          <SearchResults propertyDetails={propertySearchResponse.recordDetailsObj} showAll={showAll} />
        </>
      }
    </PageContent>
  )
}

export default PropertySearch;