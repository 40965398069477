import React from "react";
import { HeaderContainer, ComponentContainer, ComponentBorder, Typography, GridWrapper, StatsRow, ScrollWindow } from "../../../../../StyledComponents";

const SelectedMemberStats = ({selectedMemberInfoObj, selectedMemberFirstName}) => {

  return (
    <ComponentContainer className="justifyStart detailsContainer thinBorders" style={{gridRow: '1/3', gridColumn: '3', height: '100%'}}>
      <ScrollWindow className="horizontalOnly">
        <GridWrapper style={{gridTemplateRows: '2.5rem 2.5rem 2.5rem', gridTemplateColumns: '1fr', minWidth: '91.2rem'}}>
          <HeaderContainer style={{gridRow: '1', gridColumn: '1/8'}}>
            <Typography className="componentHeader">{selectedMemberFirstName}'s Closure Stats</Typography>
          </HeaderContainer>
          <StatsRow className="member" style={{gridRow: '2'}}>
            <Typography className="justifyCenter" style={{gridColumn: '1'}}>Renegotiations</Typography>
            <Typography className="justifyCenter" style={{gridColumn: '2'}}>Renegotiation Frequency</Typography>
            <Typography className="justifyCenter" style={{gridColumn: '3'}}>Refinances</Typography>
            <Typography className="justifyCenter" style={{gridColumn: '4'}}>Refinance Frequency</Typography>
            <Typography className="justifyCenter" style={{gridColumn: '5'}}>Gross Profit</Typography>
            <Typography className="justifyCenter" style={{gridColumn: '6'}}>Gross Revenue Increase</Typography>
          </StatsRow>
          <GridWrapper className="sixColumn" style={{gridRow: '3'}}>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '1'}}>{selectedMemberInfoObj.assignedMortgagesNum}</Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '2'}}>{selectedMemberInfoObj.awaitingActionLeadsNum}</Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '3'}}>{selectedMemberInfoObj.investigatingLeadsNum}</Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '4'}}>{selectedMemberInfoObj.closingLeadsNum}</Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5'}}>{selectedMemberInfoObj.awaitingUpdateLeadsNum}</Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '6'}}>{selectedMemberInfoObj.awaitingVerificationLeadsNum}</Typography>
          </GridWrapper>
        </GridWrapper>
      </ScrollWindow>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default SelectedMemberStats