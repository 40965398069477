import React from "react";
import { useTheme } from "@mui/styles";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { CardButton, Detail, DetailListContainer, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const UploadSuccessful = ({milestone}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="milestone" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      <FlexWrapper className='column fitContentHeight' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            upload by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        {milestone.milestone !== 'File Upload' &&
          <FlexWrapper className='fitContentHeight'>
            <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
              details:
            </Typography>
            <Typography className="fitContentHeight bold">
              {milestone.details}
            </Typography>
          </FlexWrapper>
        }
      </FlexWrapper>
      {milestone.newLeadTags && milestone.newLeadTags.length > 0 &&
        <FlexWrapper className="column fitContentHeight">
          <Typography className="fitContentHeight justifyEnd" style={{width: '14rem', margin: '1rem 0 0.5rem 0'}}>
            {milestone.newLeadTags.length > 1 ?
              `New Investigation Tags:`
            :
              `New Investigation Tag:`
            }
          </Typography>
          <FlexWrapper 
            className="flexWrap justifyCenter"
            style={{maxWidth: '35rem', marginBottom: '1rem'}}
          >
            {milestone.newLeadTags.map((mortgageTag) => (
              <CardButton key={mortgageTag.label} className="thin horizontal noHover primaryDark fitContentWidth" tabIndex="-1">
                <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem'}} />
                <Typography className="fitContentHeight">
                  {mortgageTag.label}
                </Typography>
              </CardButton>
            ))}
          </FlexWrapper>
        </FlexWrapper>
      }
      {milestone.newMortgageTags && milestone.newMortgageTags.length > 0 &&
        <FlexWrapper className="column fitContentHeight">
          <Typography className="fitContentHeight justifyEnd" style={{width: '14rem', margin: '1rem 0 0.5rem 0'}}>
            {milestone.newMortgageTags.length > 1 ?
              `New Mortgage Tags:`
            :
              `New Mortgage Tag:`
            }
          </Typography>
          <FlexWrapper 
            className="flexWrap justifyCenter"
            style={{maxWidth: '35rem', marginBottom: '1rem'}}
          >
            {milestone.newMortgageTags.map((mortgageTag) => (
              <CardButton key={mortgageTag.label} className="thin horizontal noHover primaryDark fitContentWidth" tabIndex="-1">
                <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem'}} />
                <Typography className="fitContentHeight">
                  {mortgageTag.label}
                </Typography>
              </CardButton>
            ))}
          </FlexWrapper>
        </FlexWrapper>
      }
    </FlexWrapper>
    )
}

export default UploadSuccessful