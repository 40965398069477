import React, { useEffect } from "react";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSelector } from "react-redux";
import FormatClearIcon from '@mui/icons-material/FormatClear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { selectTeamQueryPerformances } from "../../slices/teamAdminDataSlice";
import { ActionIconWrapper, ColumnContent, ComponentBorder, ComponentSectionRow, FlexWrapper, RowsContainer, ScrollWindow, Typography } from "../../StyledComponents";

const SweepPerformances = ({ handleOpenViewSweepFailuresModal, origin }) => {
  const theme = useTheme()

  const rows = useSelector(selectTeamQueryPerformances)

  useEffect(() => {
    console.log(rows)
  })

  return (
    <FlexWrapper className="column fitContentHeight" style={{marginBottom: 'auto'}}>
      {origin === 'quickStats' &&
        <FlexWrapper className="fitContentHeight" style={{height: '5rem', padding: '0.6rem 0', backgroundColor: `${theme.component.background.tertiary}`}}>
          <Typography className="small fitContentWidth alignTextCenter column" style={{margin: '0.5rem auto 0.5rem 3rem'}}>
            <Typography className="small secondary" style={{textAlign: 'start'}}>
              Public record sweeps are ran automatically for every saved property on a monthly basis.<br/>
              In addition to tracking discrepancy detection performance over time, the summaries below should also be used to ensure each of your mortgages is remaining linked to its public record sources.<br/>
              If you notice reported issues in recent sweeps, contact your app administrator so they can diagnose the situation.<br/>
            </Typography>
          </Typography>
        </FlexWrapper>
      }
      <ComponentBorder className="bottomPanel" style={{height: '1rem', minHeight: '1rem'}} />
      <ScrollWindow className="horizontalOnly" style={{gridColumn: origin === 'quickStats' ? '2' : '', gridRow: origin === 'quickStats' ? '2' : ''}}>
        <ComponentSectionRow className={(origin === 'quickStats' ? 'clientView ' : '') + "header recordSweeps"} style={{borderBottom: 'none'}}>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: ' 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -8],
              },
            }]}}
            title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: 'fit-content'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Not Found</p>
              </div>
            </div>} 
            placement="top"
            arrow
          >
            <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '14/18' : '11', height: '100%', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative'}}>
              <SignalWifiBadIcon style={{color: `${theme.palette.common.orangeDark}`}} />
            </ColumnContent>
          </Tooltip>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: ' 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -8],
              },
            }]}}
            title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: 'fit-content'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Missing Records</p>
              </div>
            </div>} 
            placement="top"
            arrow
          >
            <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '18/22' : '12', height: '100%', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative'}}>
              <WrongLocationIcon style={{color: `${theme.palette.common.orangeDark}`}} />
            </ColumnContent>
          </Tooltip>
          <ColumnContent style={{height: '100%', gridColumn: origin !== 'quickStats' ? '22/26' : '13/17', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, fontWeight: '400', position: 'relative'}}>
            DISCREPANCIES
          </ColumnContent>  
          <ColumnContent style={{height: '100%', gridColumn: origin !== 'quickStats' ? '26/29' : '17/20', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, fontWeight: '400', position: 'relative'}}>
            NEW LEADS
          </ColumnContent>  
          <ColumnContent style={{height: '100%', gridColumn: origin !== 'quickStats' ? '29/32' : '20/23', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, fontWeight: '400', position: 'relative'}}>
            UPDATED LEADS
          </ColumnContent>  
          <ColumnContent style={{height: '100%', gridColumn: origin !== 'quickStats' ? '32' : '23', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, fontWeight: '400', position: 'relative'}}>
            UPGRADED
          </ColumnContent>  
        </ComponentSectionRow>
        <ComponentSectionRow className={(origin === 'quickStats' ? 'clientView ' : '') + "header recordSweeps"}>
          <ColumnContent style={{gridColumn: '1', height: '100%', fontWeight: '400'}}>
            INDEX
          </ColumnContent>
          <ColumnContent style={{gridColumn: '2', height: '100%', fontWeight: '400'}}>
            DATE
          </ColumnContent>
          <ColumnContent style={{gridColumn: '3', height: '100%', fontWeight: '400'}}>
            SELECTION
          </ColumnContent>
          <ColumnContent style={{gridColumn: '4', height: '100%', fontWeight: '400'}}>
            QUERIED
          </ColumnContent>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: ' 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -8],
              },
            }]}}
            title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: 'fit-content'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Successful Queries</p>
              </div>
            </div>} 
            placement="top"
            arrow
          >
            <ColumnContent style={{gridColumn: '5', height: '100%'}}>
              <CheckCircleIcon style={{color: `${theme.palette.primary.main}`}} />
            </ColumnContent>
          </Tooltip>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: ' 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -8],
              },
            }]}}
            title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: 'fit-content'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Failed Queries</p>
              </div>
            </div>} 
            placement="top"
            arrow
          >
            <ColumnContent style={{gridColumn: '6', height: '100%'}}>
              <CancelIcon style={{color: `${theme.palette.common.error}`}} />
            </ColumnContent>
          </Tooltip>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: ' 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -8],
              },
            }]}}
            title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: '5rem'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Inactive</p>
              </div>
            </div>} 
            placement="top"
            arrow
          >
            <ColumnContent style={{gridColumn: '7', height: '100%'}}>
              <ErrorIcon style={{color: `${theme.palette.common.white}`}} />
            </ColumnContent>
          </Tooltip>
          {origin !== 'quickStats' &&
            <>
              <ColumnContent style={{gridColumn: '8', height: '100%', fontWeight: '400'}}>
                PM
              </ColumnContent>
              <ColumnContent style={{gridColumn: '9', height: '100%', fontWeight: '400'}}>
                AT
              </ColumnContent>
              <ColumnContent style={{gridColumn: '10', height: '100%', fontWeight: '400'}}>
                CL
              </ColumnContent>
            </>
          }
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: ' 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -8],
              },
            }]}}
            title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: '10rem'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Formatting Errors</p>
              </div>
            </div>} 
            placement="top"
            arrow
          >
            <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '11' : '8', height: '100%', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
              <FormatClearIcon style={{color: `${theme.palette.common.orangeDark}`}} />
            </ColumnContent>
          </Tooltip>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: ' 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -8],
              },
            }]}}
            title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: '9rem'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Improper Query</p>
              </div>
            </div>} 
            placement="top"
            arrow
          >
            <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '12' : '9', height: '100%'}}>
              <SpeakerNotesOffIcon style={{color: `${theme.palette.common.orangeDark}`}} />
            </ColumnContent>
          </Tooltip>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: ' 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -8],
              },
            }]}}
            title={<div style={{display: 'flex', justifyContent: "center", width: '12rem'}}>
              <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>Broken Connection</p>
            </div>} 
            placement="top"
            arrow
          >
            <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '13' : '10', height: '100%'}}>
              <WifiOffIcon style={{color: `${theme.palette.common.orangeDark}`}} />
            </ColumnContent>
          </Tooltip>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '14' : '11', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative'}}>
            <span style={{color: `${theme.palette.common.error}`}}>{origin === 'quickStats' ? 'TOTAL' : 'TOT'}</span>
          </ColumnContent>
          {origin !== 'quickStats' &&
            <>
              <ColumnContent style={{gridColumn: '15', height: '100%', fontWeight: '400'}}>
                <span style={{color: `${theme.palette.common.orangeDark}`}}>PM</span>
              </ColumnContent>
              <ColumnContent style={{gridColumn: '16', height: '100%', fontWeight: '400'}}>
                <span style={{color: `${theme.palette.common.orangeDark}`}}>AT</span>
              </ColumnContent>
              <ColumnContent style={{gridColumn: '17', height: '100%', fontWeight: '400'}}>
                <span style={{color: `${theme.palette.common.orangeDark}`}}>CL</span>
              </ColumnContent>
            </>
          }
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '18' : '12', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative'}}>
            <span style={{color: `${theme.palette.common.error}`}}>{origin === 'quickStats' ? 'TOTAL' : 'TOT'}</span>
          </ColumnContent>
          {origin !== 'quickStats' &&
            <>
              <ColumnContent style={{gridColumn: '19', height: '100%', fontWeight: '400'}}>
                <span style={{color: `${theme.palette.common.orangeDark}`}}>PM</span>
              </ColumnContent>
              <ColumnContent style={{gridColumn: '20', height: '100%', fontWeight: '400'}}>
                <span style={{color: `${theme.palette.common.orangeDark}`}}>AT</span>
              </ColumnContent>
              <ColumnContent style={{gridColumn: '21', height: '100%', fontWeight: '400'}}>
                <span style={{color: `${theme.palette.common.orangeDark}`}}>CL</span>
              </ColumnContent>
            </>
          }
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '22' : '13', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative'}}>
            TOTAL
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '23' : '14', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
            TIER 1
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '24' : '15', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
            TIER 2
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '25' : '16', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
            TIER 3
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '26' : '17', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative'}}>
            TOTAL
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '27' : '18', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
            TIER 1
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '28' : '19', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
            TIER 2
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '29' : '20', height: '100%', fontWeight: '400', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative'}}>
            TOTAL
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '30' : '21', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
            TIER 1
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '31' : '22', height: '100%', fontWeight: '400', fontSize: '0.8rem'}}>
            TIER 2
          </ColumnContent>
          <ColumnContent style={{gridColumn: origin !== 'quickStats' ? '32' : '23', height: '100%', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, position: 'relative'}}>
            <KeyboardDoubleArrowUpIcon style={{color: `${theme.palette.grayText}`}} />
            TIER 2 to 1
          </ColumnContent>
        </ComponentSectionRow>
        <RowsContainer style={{height: '100%'}}>
          {rows.toReversed().map((row, index) => (
            row.errored ?
              <ComponentSectionRow key={row._id} className={(origin === 'quickStats' ? 'clientView ' : '') + (index%2 === 1 ? 'even ' : '') + 'recordSweeps'}>
                <ColumnContent className="detail" style={{gridColumn: '1', fontSize: '1rem'}}>
                  {index + 1}
                </ColumnContent>
                <ColumnContent className="detail" style={{gridColumn: '2', fontSize: '1rem'}}>
                  {row.date}
                </ColumnContent>
                <ColumnContent className="detail" style={{gridColumn: '3/25'}}>
                  -connection failed-
                </ColumnContent>
              </ComponentSectionRow>
            :
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.caution.color}`,
                  backgroundColor: `${theme.tooltip.caution.background}`,
                  border: `0.1rem solid ${theme.tooltip.caution.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.caution.border}`,
                  opacity: '0',
                  borderRadius: '1rem',
                  width: 'fit-content',
                  minWidth: 'fit-content',
                  padding: '1.5rem 2rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                }]}}
                title={row.monthlyQueriesExhausted ?
                  <FlexWrapper className="column">
                    <Typography className="bold alignTextCenter justifyCenter" style={{width: '33rem'}}>
                      This record sweep exhausted your team's monthly query limit.<br/>
                      Some properties went unverified.<br/>
                      Contact your app admin to request a higher limit.
                    </Typography>
                  </FlexWrapper>
                : null} 
                placement="top"
                arrow
                key={row._id}
              >
                <ComponentSectionRow
                  className={
                    (origin === 'quickStats' ? 
                      'clientView ' : '') 
                    + (index%2 === 1 ? 
                      'evenDark ' : '') 
                    + (row.monthlyQueriesExhausted ? 
                      'caution ' : '') 
                    + 'recordSweeps'
                  }
                >
                  <ColumnContent className="detail" style={{gridColumn: '1', fontSize: '1rem'}}>
                    {index + 1}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: '2', fontSize: '1rem'}}>
                    {row.date}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: '3', fontSize: '1rem'}}>
                    {row.selection}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: '4', fontSize: '1rem'}}>
                    {row.totalQueried}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: '5', fontSize: '1rem'}}>
                    {row.totalSuccessfulQueries}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: '6', fontSize: '1rem'}}>
                    {row.totalFailedQueries}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: '7', fontSize: '1rem'}}>
                    {row.totalInactive}
                  </ColumnContent>
                  {origin !== 'quickStats' &&
                    <>
                      <ColumnContent 
                        className="detail"
                        style={{
                          gridColumn: '8',
                          fontSize: '1rem',
                          color: (row.propMixLessThanLast || row.propMixGreaterThanLast) ? `${theme.palette.common.white}` : `${theme.text.primary.primary}`,
                          background: row.propMixLessThanLast ? `${theme.accent.error.primary}` : row.propMixGreaterThanLast ? `${theme.accent.success.primary}` : 'none',
                        }}
                      >
                        {row.propMixSuccessfulQueries}
                      </ColumnContent>
                      <ColumnContent className="detail"
                        style={{
                          gridColumn: '9',
                          fontSize: '1rem',
                          color: (row.attomLessThanLast || row.attomGreaterThanLast) ? `${theme.palette.common.white}` : `${theme.text.primary.primary}`,
                          background: row.attomLessThanLast ? `${theme.accent.error.primary}` : row.attomGreaterThanLast ? `${theme.accent.success.primary}` : 'none',
                        }}
                      >
                        {row.attomSuccessfulQueries}
                      </ColumnContent>
                      <ColumnContent className="detail"
                        style={{
                          gridColumn: '10',
                          fontSize: '1rem',
                          color: (row.clCurrentMortgageLessThanLast || row.clCurrentMortgageGreaterThanLast) ? `${theme.palette.common.white}` : `${theme.text.primary.primary}`,
                          background: row.clCurrentMortgageLessThanLast ? `${theme.accent.error.primary}` : row.clCurrentMortgageGreaterThanLast ? `${theme.accent.success.primary}` : 'none'
                        }}
                      >
                        {row.clCurrentMortgageSuccessfulQueries}
                      </ColumnContent>
                    </>
                  }
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '11' : '8', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                    {row.formattingErrors.length}
                    {row.formattingErrors.length > 0 ?
                      <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.formattingErrors, 'formattingErrors')}>
                        <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                      </ActionIconWrapper>
                    :
                      <></>
                    }
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '12' : '9', fontSize: '1rem'}}>
                    {row.improperQueries.length}
                    {row.improperQueries.length > 0 ?
                      <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.improperQueries, 'improperQueries')}>
                        <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                      </ActionIconWrapper>
                    :
                      <></>
                    }
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '13' : '10', fontSize: '1rem'}}>
                    {row.brokenConnections.length}
                    {row.brokenConnections.length > 0 ?
                      <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.brokenConnections, 'brokenConnections')}>
                        <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                      </ActionIconWrapper>
                    :
                      <></>
                    }
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '14' : '11', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                    {row.completeNotFound.length}
                    {row.completeNotFound.length > 0 ?
                      <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.completeNotFound, 'failedQueries')}>
                        <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                      </ActionIconWrapper>
                    :
                      <></>
                    }
                  </ColumnContent>
                  {origin !== 'quickStats' &&
                    <>
                      <ColumnContent className="detail" style={{gridColumn: '15', fontSize: '1rem'}}>
                        {row.propMixNotFound.length}
                        {row.propMixNotFound.length > 0 ?
                          <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.propMixNotFound, 'failedQueries')}>
                            <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                          </ActionIconWrapper>
                        :
                          <></>
                        }
                      </ColumnContent>
                      <ColumnContent className="detail" style={{gridColumn: '16', fontSize: '1rem'}}>
                        {row.attomNotFound.length}
                        {row.attomNotFound.length > 0 ?
                          <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.attomNotFound, 'failedQueries')}>
                            <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                          </ActionIconWrapper>
                        :
                          <></>
                        }
                      </ColumnContent>
                      <ColumnContent className="detail" style={{gridColumn: '17', fontSize: '1rem'}}>
                        {row.clCurrentMortgageNotFound.length}
                        {row.clCurrentMortgageNotFound.length > 0 ?
                          <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.clCurrentMortgageNotFound, 'failedQueries')}>
                            <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                          </ActionIconWrapper>
                        :
                          <></>
                        }
                      </ColumnContent>
                    </>
                  }
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '18' : '12', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                    {row.completeMissingRecords.length}
                    {row.completeMissingRecords.length > 0 ?
                      <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.completeMissingRecords, 'failedQueries')}>
                        <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                      </ActionIconWrapper>
                    :
                      <></>
                    }
                  </ColumnContent>
                  {origin !== 'quickStats' &&
                    <>
                      <ColumnContent className="detail" style={{gridColumn: '19', fontSize: '1rem'}}>
                        {row.propMixMissingRecords.length}
                        {row.propMixMissingRecords.length > 0 ?
                          <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.propMixMissingRecords, 'failedQueries')}>
                            <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                          </ActionIconWrapper>
                        :
                          <></>
                        }
                      </ColumnContent>
                      <ColumnContent className="detail" style={{gridColumn: '20', fontSize: '1rem'}}>
                        {row.attomMissingRecords.length}
                        {row.attomMissingRecords.length > 0 ?
                          <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.attomMissingRecords, 'failedQueries')}>
                            <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                          </ActionIconWrapper>
                        :
                          <></>
                        }
                      </ColumnContent>
                      <ColumnContent className="detail" style={{gridColumn: '21', fontSize: '1rem'}}>
                        {row.clCurrentMortgageMissingRecords.length}
                        {row.clCurrentMortgageMissingRecords.length > 0 ?
                          <ActionIconWrapper className={(index%2 === 1 ? 'even ' : '') + 'hover'} onClick={() => handleOpenViewSweepFailuresModal(row.clCurrentMortgageMissingRecords, 'failedQueries')}>
                            <OpenInNewIcon style={{height: '1.0rem', width: '1.0rem', color: `${theme.palette.primary.dark}`}} />
                          </ActionIconWrapper>
                        :
                          <></>
                        }
                      </ColumnContent>
                    </>
                  }
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '22' : '13', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                    {row.totalDiscrepancies}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '23' : '14', fontSize: '1rem'}}>
                    {row.totalTier1Discrepancies}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '24' : '15', fontSize: '1rem'}}>
                    {row.totalTier2Discrepancies}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '25' : '16', fontSize: '1rem'}}>
                    {row.totalTier3Discrepancies}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '26' : '17', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                    {row.totalNewLeads}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '27' : '18', fontSize: '1rem'}}>
                    {row.tier1New}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '28' : '19', fontSize: '1rem'}}>
                    {row.tier2New}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '29' : '20', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                    {row.totalUpdatedLeads}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '30' : '21', fontSize: '1rem'}}>
                    {row.tier1Updated}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '31' : '22', fontSize: '1rem'}}>
                    {row.tier2Updated}
                  </ColumnContent>
                  <ColumnContent className="detail" style={{gridColumn: origin !== 'quickStats' ? '32' : '23', fontSize: '1rem', borderLeft: `0.1rem solid ${theme.palette.common.black}`}}>
                    {row.tier2Upgraded}
                  </ColumnContent>
                </ComponentSectionRow>
              </Tooltip>
          ))}
        </RowsContainer>
      </ScrollWindow>
    </FlexWrapper>
  )
}

export default SweepPerformances