import React from "react";
import Badge from '@mui/material/Badge';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from "@mui/styles";
import LeadTagDropdown from "../../../../../components/DropDowns/LeadTagDropdown";
import { useSelector } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import FilterTierDropdown from "../../../../../components/DropDowns/Filters/FilterTierDropdown";
import SortCategoryDropdown from "../../../../../components/DropDowns/Sorts/SortCategoryDropdown";
import FilterLeadTypeDropdown from "../../../../../components/DropDowns/Filters/FilterLeadTypeDropdown";
import { ControlContainer, ControlHeader, SubHeader, NewLabel, FlexWrapper } from "../../../../../StyledComponents";
import { selectTeamLeadsAwaitingUpdate, selectTeamLeadsAwaitingVerification } from "../../../../../slices/teamActiveLeadsSlice";
import { selectAwaitingUpdatesCount, selectClosingCount, selectInvestigatingCount, selectNewAwaitingActionCount, selectNewAwaitingActionPresent, selectNewAwaitingUpdatesPresent, selectNewClosingPresent, selectNewInvestigatingPresent } from "../../../../../slices/userNotificationsSlice";

const Controls = ({ leadStatusFilter, leadTypeFilter, leadTierFilter, handleLeadStatusFilterChange, leadCategorySort, handleLeadSortCategoryChange, leadSortMethod, handleLeadSortMethodChange, handleLeadTierFilterChange, handleLeadTypeFilterChange, leadNewFilter, handleLeadNewFilterChange, leadTagFilter, handleLeadTagFilterChange, teamLeadTags, memberAwaitingActionAmount, memberInvestigatingAmount, memberClosingAmount, memberAwaitingUpdateAmount, teamAwaitingActionAmount, teamInvestigatingAmount, teamClosingAmount, teamAwaitingUpdateAmount, dashPerspective }) => {
  const theme = useTheme()

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.common.error}`,
      fontWeight: '800',
      fontSize: "1rem",
      height: "1.4rem",
      padding: "0 0.5rem",
      borderRadius: "1rem",
      minWidth: "1.2rem",
      margin: '0.2rem 0 0 0.5em'
    }
  }

  const newAwaitingActionCount = useSelector(selectNewAwaitingActionCount)
  const awaitingUpdatesCount = useSelector(selectAwaitingUpdatesCount)
  const investigatingCount = useSelector(selectInvestigatingCount)
  const closingCount = useSelector(selectClosingCount)

  const teamLeadsAwaitingUpdate = useSelector(selectTeamLeadsAwaitingUpdate)
  const teamLeadsAwaitingVerification = useSelector(selectTeamLeadsAwaitingVerification)
  const newAwaitingActionPresent = useSelector(selectNewAwaitingActionPresent)
  const newInvestigatingPresent = useSelector(selectNewInvestigatingPresent)
  const newClosingPresent = useSelector(selectNewClosingPresent)
  const newAwaitingUpdatesPresent = useSelector(selectNewAwaitingUpdatesPresent)

  const filterStatusControlProps = (item, memberAmount, teamAmount) => ({
    checked: leadStatusFilter === item,
    onChange: handleLeadStatusFilterChange,
    value: item,
    label: dashPerspective === 'team' ? `(${teamAmount}) ${item}` : `(${memberAmount}) ${item}`,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const filterNewControlProps = (item) => ({
    checked: leadNewFilter === item,
    onChange: handleLeadNewFilterChange,
    value: item,
    label: item,
    labelPlacement: "start",
    sx: {
      color: `${theme.palette.common.black}`,
      margin: '0 1rem 0 0'
    }
  });
  const sortMethodControlProps = (item) => ({
    checked: leadSortMethod === item,
    onChange: handleLeadSortMethodChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const colorProps = () => ({
    sx: {
      padding: "0.5rem 0.5rem 0.5rem 0",
      color: `${theme.palette.common.grayBorder}`,
      '&.Mui-checked': {
        color: `${theme.palette.primary.main}`,
      },
    }
  });
  const colorPropsRow = () => ({
    sx: {
      padding: "0.5rem 0 0.5rem 0.5rem",
      color: `${theme.palette.common.grayBorder}`,
      '&.Mui-checked': {
        color: `${theme.palette.primary.main}`,
      },
    }
  });

  return (
    <>
      <ControlContainer style={{paddingLeft: '0.5rem'}}>
        <ControlHeader>Filter</ControlHeader>
        <FormControl>
  
          <SubHeader style={{margin: '0'}}>By Status</SubHeader>
          <RadioGroup>
            {newAwaitingActionCount !== undefined ?
              newAwaitingActionCount > 0 ?
                <Badge 
                  badgeContent={newAwaitingActionCount} 
                  color="error" 
                  sx={badgeStyle}   
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <FormControlLabel
                    control={<Radio {...colorProps()} />}
                    {...filterStatusControlProps('Awaiting Action', memberAwaitingActionAmount, teamAwaitingActionAmount)} 
                  />
                </Badge>
              :
                <FormControlLabel
                  control={<Radio {...colorProps()} />}
                  {...filterStatusControlProps('Awaiting Action', memberAwaitingActionAmount, teamAwaitingActionAmount)} 
                />
            :
              <FormControlLabel
                control={<Radio {...colorProps()} />}
                {...filterStatusControlProps('Awaiting Action', memberAwaitingActionAmount, teamAwaitingActionAmount)} 
              />
            }
            {investigatingCount !== undefined ?
              investigatingCount > 0 ?
                <Badge 
                  badgeContent={investigatingCount} 
                  color="error" 
                  sx={badgeStyle}   
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <FormControlLabel
                    control={<Radio {...colorProps()} />}
                    {...filterStatusControlProps('Investigating', memberInvestigatingAmount, teamInvestigatingAmount)} 
                  />
                </Badge>
              :
                <FormControlLabel
                  control={<Radio {...colorProps()} />}
                  {...filterStatusControlProps('Investigating', memberInvestigatingAmount, teamInvestigatingAmount)} 
                />
            :
              <FormControlLabel
                control={<Radio {...colorProps()} />}
                {...filterStatusControlProps('Investigating', memberInvestigatingAmount, teamInvestigatingAmount)} 
              />
            }
            {closingCount !== undefined ?
              closingCount > 0 ?
                <Badge 
                  badgeContent={closingCount} 
                  color="error" 
                  sx={badgeStyle}   
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <FormControlLabel
                    control={<Radio {...colorProps()} />}
                    {...filterStatusControlProps('Closing', memberClosingAmount, teamClosingAmount)} 
                  />
                </Badge>
              :
                <FormControlLabel
                  control={<Radio {...colorProps()} />}
                  {...filterStatusControlProps('Closing', memberClosingAmount, teamClosingAmount)} 
                />
            :
              <FormControlLabel
                control={<Radio {...colorProps()} />}
                {...filterStatusControlProps('Closing', memberClosingAmount, teamClosingAmount)} 
              />
            }
            <div style={{height: '0.1rem', width: '100%', background: `${theme.palette.common.grayBorder}`}} />
            {awaitingUpdatesCount !== undefined ?
              awaitingUpdatesCount > 0 ?
                <Badge 
                  badgeContent={awaitingUpdatesCount} 
                  color="error" 
                  sx={badgeStyle}   
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <FormControlLabel
                    control={<Radio {...colorProps()} />}
                    {...filterStatusControlProps('Awaiting PR Updates', memberAwaitingUpdateAmount, teamAwaitingUpdateAmount)} 
                  />
                </Badge>
              :
                <FormControlLabel
                  control={<Radio {...colorProps()} />}
                  {...filterStatusControlProps('Awaiting PR Updates', memberAwaitingUpdateAmount, teamAwaitingUpdateAmount)} 
                />
            :
              <FormControlLabel
                control={<Radio {...colorProps()} />}
                {...filterStatusControlProps('Awaiting PR Updates', memberAwaitingUpdateAmount, teamAwaitingUpdateAmount)} 
              />
            }
          </RadioGroup>
        </FormControl>
      </ControlContainer>
      <FlexWrapper style={{height: 'fit-content', margin: '0 0 2rem 0'}}>
        <FlexWrapper className="column justifyBetween" style={{ width: '3rem', height: '2.4rem', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          <div style={{width: '100%', height: '0.1rem', background: `${theme.palette.common.grayBorder}`, marginTop: '0.75rem'}}></div>
          <div style={{width: '100%', height: '0.1rem', background: `${theme.palette.common.grayBorder}`}}></div>
        </FlexWrapper>
        <FlexWrapper className="column">
          <FlexWrapper>
            <span style={{fontWeight: '400'}}>
              ({`${teamLeadsAwaitingUpdate}`})
            </span>
            <PendingIcon style={{color: `${theme.palette.common.grayText}`, margin: '0 0.3rem'}} />
            pending
          </FlexWrapper>
          <FlexWrapper>
            ({`${teamLeadsAwaitingVerification}`})
            <TaskAltIcon style={{color: `${theme.palette.common.grayText}`, margin: '0 0.3rem'}} />
            ready to verify
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>

      <SubHeader style={{padding: '1.5rem 0 0 2rem'}}>By Tier</SubHeader>
      <FilterTierDropdown value={leadTierFilter} handleChange={handleLeadTierFilterChange} />

      {/* <SubHeader style={{padding: '1.5rem 0 0 2rem'}}>By Tag</SubHeader>
      <LeadTagDropdown value={leadTagFilter} handleChange={handleLeadTagFilterChange} items={teamLeadTags} /> */}

      {leadStatusFilter === 'Awaiting Action' ?
          <></>
        :
          <>
            <SubHeader style={{padding: '1.5rem 0 0 2rem'}}>By Type</SubHeader>
            <FilterLeadTypeDropdown value={leadTypeFilter} handleChange={handleLeadTypeFilterChange} />
          </>
      }
      {(leadStatusFilter === 'Awaiting Action' && newAwaitingActionPresent) || (leadStatusFilter === 'Investigating' && newInvestigatingPresent) || (leadStatusFilter === 'Closing' && newClosingPresent) || (leadStatusFilter === 'Awaiting PR Updates' && newAwaitingUpdatesPresent) ?
        <>
          <SubHeader className="new" style={{margin: '2rem 0 0 1.5rem'}}>
            <NewLabel style={{width: '8.5rem'}}>New Only</NewLabel>
          </SubHeader>
          <div style={{padding: '0 0 0 2rem'}}>
            <RadioGroup row>
              <FormControlLabel
                control={<Radio {...colorPropsRow()} />}
                {...filterNewControlProps('true')} 
                />
              <FormControlLabel
                control={<Radio {...colorPropsRow()} />}
                {...filterNewControlProps('false')} 
                />
            </RadioGroup>
          </div>
        </>
      :
        <></>
      }

      <ControlHeader style={{padding: '2.5rem 0 0.5rem 1rem'}}>Sort</ControlHeader>

      <SubHeader style={{padding: '0 0 0 2rem'}}>Category</SubHeader>
      <SortCategoryDropdown value={leadCategorySort} handleChange={handleLeadSortCategoryChange} />
        
      <ControlContainer>
        <SubHeader style={{padding: '1.5rem 0 0 0'}}>Method</SubHeader>
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              control={<Radio {...colorProps()} />}
              {...sortMethodControlProps('High-Low')} 
            />
            <FormControlLabel
              control={<Radio {...colorProps()} />}
              {...sortMethodControlProps('Low-High')} 
            />
          </RadioGroup>
        </FormControl>
      </ControlContainer>
    </>
  )
}

export default Controls;