import React, { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@mui/styles";
import TagTypeDisplay from "./TagTypeDisplay";
import AssignOrCreateTag from "./AssignOrCreateTag";
import { AccentInfo, ComponentSectionRow, ModalContentContainer, ListRow, CompareDetail, Typography, ComponentHeader, FlexWrapper } from "../../../../../StyledComponents";

const EditTags = ({ leadData, userFullName, teamId, dashPerspective, userId, activeLead, mortgageData }) => {
  const theme = useTheme()

  const [isError, setIsError] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)

  return (
    <>
      {saveSuccess ?
        <ComponentHeader className="modal green">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
            <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
            Success
          </span>
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        <ComponentHeader className="modal">
          <Typography className="xLarge">
            {leadData && leadData.tags && leadData.tags.length > 0 && mortgageData.tags.length > 0 ?
              `Mortgage and Lead tags`
            : leadData && leadData.tags && leadData.tags.length > 0 ?
              `Lead tags`
            : mortgageData.tags.length > 0 ?
              `Mortgage tags`
            :
              `Tags`
            }
          </Typography>
        </ComponentHeader>
      }
      <ModalContentContainer className="topColumn" style={{gridRow: '3/6', overflow: 'hidden'}}>
        {leadData && leadData.tags && leadData.tags.length > 0 ?
          <>
            <ListRow style={{margin: '0 0 2rem 0'}}>
              <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                Lead Tags
              </CompareDetail>
            </ListRow>
            <TagTypeDisplay tagData={leadData} assignmentType={'query'} />
          </>
        : leadData && leadData.tags && leadData.tags.length === 0 ?
          <>
            <ListRow style={{margin: '0 0 2rem 0'}}>
              <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                Lead Tags
              </CompareDetail>
            </ListRow>
            <ComponentSectionRow>
              <AccentInfo style={{margin: '0 auto 2rem auto'}}>-no lead tags-</AccentInfo>
            </ComponentSectionRow>
          </>
        :
          <div style={{height: '3rem'}} />
        }
        {mortgageData.tags.length > 0 ?
          <>
            <ListRow style={{margin: '0.3rem 0 2rem 0'}}>
              <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                Mortgage Tags 
              </CompareDetail>
            </ListRow>
            <TagTypeDisplay tagData={mortgageData} assignmentType={'query'} tagType={'mortgage'} />
          </>
        : mortgageData.tags.length === 0 ?
          <>
            <ListRow style={{margin: '0.3rem 0 2rem 0'}}>
              <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                Mortgage Tags 
              </CompareDetail>
            </ListRow>
            <ComponentSectionRow>
              <AccentInfo style={{margin: '5rem auto auto auto'}}>- no mortgage tags -</AccentInfo>
            </ComponentSectionRow>
          </>
        :
          <div style={{height: '3rem'}} />
        }
        <AssignOrCreateTag setIsError={setIsError} setSaveSuccess={setSaveSuccess} leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={activeLead} mortgageData={mortgageData} />
      </ModalContentContainer>
    </>
  )
}

export default EditTags;