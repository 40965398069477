import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";
import { dropdownStyles, dropdownMenuProps } from "../../componentThemes";

const GenericDropdown = ({value, handleChange, items, disabled}) => {
  const theme = useTheme()
  const dropdown = dropdownStyles(theme)

  return (
    <FormControl className={dropdown.formControl}>
      <Select
        disabled={disabled}
        value={value}
        onChange={handleChange}
        MenuProps={dropdownMenuProps}
        classes={{
          select: dropdown.select,
          icon: dropdown.selectIcon
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            muiskiplisthighlight='true'
            key={item.label}
            disabled={value === item.label}
            value={item.label}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GenericDropdown;
