import React, { useContext, useState } from "react";
import Team from "./components/Team";
import Users from "./components/Users";
import Reports from "./components/Reports";
import Mortgages from "./components/Mortgages";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppState } from "../../../../AppState";
import MonthlyStats from "./components/MonthlyStats";
import PublicRecords from "./components/PublicRecords";
import SweepParameters from "./components/SweepParameters";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import EditDatabaseModal from "../../../../components/Modals/EditDatabaseModal";
import { selectDataBase } from "../../../../slices/teamAdminDataSlice";
import { APP_ENVIRONMENT } from "../../../../config";
import { Dialog, useTheme } from "@mui/material";
import { selectTeamMortgages } from "../../../../slices/teamMortgagesSlice";
import { selectDropAllFromDatabasePerm } from "../../../../slices/userActionsPermissionsSlice";
import { useDropAllFromDatabaseMutation } from "../../../../slices/api/apiSlice";
import { selectUserFullName, selectUserRequestCredentials } from "../../../../slices/userInfoSlice";
import { Button, ButtonWrapper, ComponentBorder, ComponentContainer, FlexWrapper } from "../../../../StyledComponents";

const DataBase = ({ userId }) => {
  const theme = useTheme()
  const { signOut } = useContext(AppState)

  const [dataToEdit, setDataToEdit] = useState({})
  const [dropIsError, setDropIsError] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [workflowStage, setWorkflowStage] = useState(null)
  const [dropIsFetching, setDropIsFetching] = useState(false)

  const data = useSelector(selectDataBase)
  const mortgageData = useSelector(selectTeamMortgages)
  const userFullName = useSelector(selectUserFullName)
  const reqCredentials = useSelector(selectUserRequestCredentials)
  const dropAllFromDatabasePerm = useSelector(selectDropAllFromDatabasePerm)

  const [dropAllFromDatabase] = useDropAllFromDatabaseMutation()

  const handleOpenEditModal = (id, fieldToUpdate, oldValue) => {
    setDataToEdit({
      id: id,
      fieldToUpdate: fieldToUpdate,
      oldValue: oldValue,
    })
    setOpenEditModal(true)
  }

  const handleClick = async (stage) => {
    if (stage === 'initiate') {
      setWorkflowStage('initiate')
    } else if (stage === 'cancel') {
      setWorkflowStage(null)
    } else if (stage === 'submit') {
      setWorkflowStage(null)
      setDropIsFetching(true)
      let resp = await dropAllFromDatabase({
        userFullName: userFullName,
      })
      if (resp.error) {
        setDropIsError(true)
      } else if (resp.data.message.includes('success')) {
        signOut(reqCredentials)
      }
      setDropIsFetching(false)
    }
  }

  return (
    <>
      <FlexWrapper className="column" style={{padding: '1rem'}}>
        <ComponentContainer className="fitWindow" style={{maxHeight: '90%'}}>
          <FlexWrapper className="column">
            <Team teamData={data.team} />
            <Users userData={data.users} userId={userId} />
            <Reports reportData={data.reportIds} />
            <PublicRecords publicRecordData={data.publicRecords} />
            <SweepParameters />
            <MonthlyStats monthlyStatsData={data.monthlyStats} />
            <Mortgages mortgageData={mortgageData} handleOpenEditModal={handleOpenEditModal} />
          </FlexWrapper>
    
          <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
          <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
          <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
          <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
        </ComponentContainer>

        {APP_ENVIRONMENT === 'staging' &&
          <FlexWrapper className="fitContentHeight" style={{paddingTop: '1rem'}}>
            {workflowStage === 'initiate' ?
              <ButtonWrapper className="justifyBetween" style={{width: '7rem', height:'3rem', margin: '0 auto'}}>
                <Button 
                  className={dropIsFetching ? 'deleteLoading' : (!dropAllFromDatabasePerm) ? "deleteDisabled" : "delete"}
                  disabled={dropIsFetching || !dropAllFromDatabasePerm || dropIsError}
                  onClick={() => handleClick('submit')}
                  style={{height: '3rem', width: '3rem', margin: '0'}}
                >
                  {dropIsFetching ?
                    <CircularProgress
                      sx={{ color: `${theme.button.text.deleteLoading}` }}
                      size={12}
                    />
                  :
                    <CheckIcon />
                  }
                </Button>
                <Button 
                  className="secondary prompt" 
                  onClick={() => handleClick('cancel')} 
                  style={{height: '3rem', width: '3rem'}}
                >
                  <ClearIcon />
                </Button>
              </ButtonWrapper>
            :
              <ButtonWrapper style={{height:'3rem', margin: '0 auto'}}>
                <Button 
                  className={!dropAllFromDatabasePerm ? "deleteDisabled" : "delete"}
                  disabled={!dropAllFromDatabasePerm || dropIsError}
                  onClick={() => handleClick('initiate')}
                  style={{width: '16rem'}}
                >
                  <DeleteIcon style={{marginRight: '0.5rem'}} />
                  Drop Database
                </Button>
              </ButtonWrapper>
            }
          </FlexWrapper>
        }
      </FlexWrapper>

      <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)} 
        PaperProps={{ sx: {width: "40rem", minWidth: "40rem", maxWidth: "40rem", height: "35rem", minHeight: "35rem", maxHeight: "35rem"}}}
      >
        <EditDatabaseModal closeModal={() => setOpenEditModal(false)} dataToEdit={dataToEdit} userFullName={userFullName} />
      </Dialog>
    </>
  )
}

export default DataBase;