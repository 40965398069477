import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectMembersDataArray } from "../../slices/membersDataArraySlice";

const useStyles = makeStyles((theme) => ({
  formControl: {
    gridColumn: 2,
    padding: '0 0 0 1rem',
    "& .MuiInputBase-root": {
        borderRadius: "1rem",
        border: `0.1rem solid ${theme.dropdown.border.dark}`,
        color: `${theme.palette.common.black}`,
        width: "18rem",
        height: "2.4rem",
      },
      "& .Mui-disabled svg": {
        color: `${theme.palette.common.grayBorder}`,
      },
      "& .MuiSelect-select.MuiSelect-select": {
        minHeight: "2.4rem",
        display: "flex",
        alignItems: "center",
        padding: '0.1rem 2rem 0 0'
    },
    "& .Mui-focused": {
    },
    "& .MuiInputBase-root:hover": {
      border: `0.1rem solid ${theme.palette.primary.main}`,
    },
    "& .MuiInputBase-root:hover svg": {
    },
    "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    }
  },
  select: {
    fontSize: "1.0rem",
    color: `${theme.palette.common.black}`,
    padding: "0",
    display: "flex",
    justifyContent: "center"
  },
  selectIcon: {
    color: `${theme.palette.primary.main}`,
  },
  paper: {
    background: `${theme.palette.common.white}`,
    marginTop: "0.5rem",
  },
  list: {
    maxHeight: "20rem",
    "& li": {
    background: `${theme.palette.common.white}`,
    borderBottom: `0.2rem solid ${theme.palette.common.grayBorder}`,
    borderTop: `0.2rem solid ${theme.palette.common.grayBorder}`,
    color: `${theme.palette.common.black}`,
    justifyContent: "center",
    overflow: "scroll"
    },
    "& li:hover": {
      background: `${theme.palette.common.grayHover}`,
    },
    "& li.Mui-selected:hover": {
      background: `${theme.palette.secondary.light}`,
    },
    "& li.Mui-selected": {
      background: `${theme.palette.secondary.lightest}`,
    },
  }
}));

const TeamRepositoryDropdown = ({ userFilter, handleUserChange }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
  };

  const memberData = useSelector(selectMembersDataArray)

  const handleSelectUser = (event) => {
    if (event.target.value === 'team') {
      handleUserChange('team')
    } else {
      let newMember = memberData.find(member => member.memberId === event.target.value)
      if (newMember) {
        handleUserChange(newMember.memberId)
      }
    }
  }

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={userFilter}
        onChange={handleSelectUser}
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon
        }}
        style={{justifyContent: 'center'}}
      >
        <MenuItem
          muiskiplisthighlight='true'
          disabled={userFilter === "team"}
          value={"team"}
          style={{justifyContent: 'center'}}
        >
          Entire Team
        </MenuItem>
        {memberData.length > 0 && memberData !== undefined ?
         memberData.map((teamMember, index) => (
          <MenuItem
            muiskiplisthighlight='true'
            key={teamMember.memberId}
            disabled={userFilter === teamMember.memberId}
            value={teamMember.memberId}
            style={{justifyContent: 'center'}}
          >
            {teamMember.fullName}
          </MenuItem>
        ))
        : <></>
      }
      </Select>
    </FormControl>
  );
};

export default TeamRepositoryDropdown
