import React, { useEffect, useRef, useState } from "react"
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/styles";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useSelector } from "react-redux";
import LeadTagDropdown from "../../../../DropDowns/LeadTagDropdown"
import { StyledTextArea } from "../../../../../componentThemes";
import MortgageTagDropdown from "../../../../DropDowns/MortgageTagDropwdown";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tier1ParametersDropdown from "../../../../DropDowns/Tier1ParametersDropdown";
import Tier2ParametersDropdown from "../../../../DropDowns/Tier2ParametersDropdown";
import Tier3ParametersDropdown from "../../../../DropDowns/Tier3ParametersDropdown";
import { selectTeamLeadTags, selectTeamMortgageTags } from "../../../../../slices/teamAndUserSettingsSlice";
import { useAddLeadTagMutation, useAddMortgageTagMutation } from "../../../../../slices/api/apiSlice";
import { selectTier1Parameters, selectTier2Parameters, selectTier3Parameters } from "../../../../../slices/teamSweepParametersSlice";
import { selectAssignLeadTagPerm, selectAssignMortgageTagPerm, selectCreateLeadTagPerm, selectCreateMortgageTagPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { ComponentSectionRow, DetailWrapper, ErrorWrapper, Label, InputWrapper, FlexWrapper, StatusAndLabelWrapper, StyledInputElement, Button, Typography } from "../../../../../StyledComponents";

const AssignOrCreateTag = ({ leadData, userFullName, teamId, dashPerspective, userId, activeLead, mortgageData, setSaveSuccess, setIsError }) => {
  const theme = useTheme()

  const teamLeadTags = useSelector(selectTeamLeadTags)
  const tier1Parameters = useSelector(selectTier1Parameters)
  const tier2Parameters = useSelector(selectTier2Parameters)
  const tier3Parameters = useSelector(selectTier3Parameters)
  const teamMortgageTags = useSelector(selectTeamMortgageTags)
  const assignLeadTagPerm = useSelector(selectAssignLeadTagPerm)
  const createLeadTagPerm = useSelector(selectCreateLeadTagPerm)
  const assignMortgageTagPerm = useSelector(selectAssignMortgageTagPerm)
  const createMortgageTagPerm = useSelector(selectCreateMortgageTagPerm)
  
  const [addLeadTag, {isError: leadTagError}] = useAddLeadTagMutation()
  const [addMortgageTag, {isSuccess: mortgageTagSuccess, isError: mortgageTagError}] = useAddMortgageTagMutation()

  const [leadSaveFetching, setLeadSaveFetching] = useState(false)
  const [mortgageSaveFetching, setMortgageSaveFetching] = useState(false)
  const [availableLeadTags, setAvailableLeadTags] = useState(teamLeadTags)
  const [availableMortgageTags, setAvailableMortgageTags] = useState(teamMortgageTags)
  const [newLeadTag, setNewLeadTag] = useState([''])
  const [newMortgageTag, setNewMortgageTag] = useState([''])
  const [showLeadAssignSave, setShowLeadAssignSave] = useState(false)
  const [showMortgageAssignSave, setShowMortgageAssignSave] = useState(false)
  const [newTier1, setNewTier1] = useState([''])
  const [newTier2, setNewTier2] = useState([''])
  const [newTier3, setNewTier3] = useState([''])
  const [statusLeadTagsEmpty, setStatusLeadTagsEmpty] = useState(false)
  const [statusMortgageTagEmpty, setStatusMortgageTagEmpty] = useState(false)
  const [descriptionCount, setDescriptionCount] = useState(0)
  const [titleCount, setTitleCount] = useState(0)
  const [tagTitleValue, setTagTitleValue] = useState('')
  const [tagDescriptionValue, setTagDescriptionValue] = useState('')
  const [createTag, setCreateTag] = useState(null)
  const [statusTitleLength, setStatusTitleLength] = useState(null)
  const [statusTitleCharacter, setStatusTitleCharacter] = useState(false)
  const [statusTitleExisting, setStatusTitleExisting] = useState(false)
  const tagTitle = useRef()
  const [statusDescriptionLength, setStatusDescriptionLength] = useState(null)
  const [statusDescriptionCharacter, setStatusDescriptionCharacter] = useState(false)
  const tagDescription = useRef()


  const handleSetNewLeadTag = (event) => {
    setShowLeadAssignSave(true)
    setNewLeadTag(event.target.value)
    setStatusLeadTagsEmpty(false)
  }
  const handleSetNewMortgageTag = (event) => {
    setShowMortgageAssignSave(true)
    setNewMortgageTag(event.target.value)
    setStatusMortgageTagEmpty(false)
  }

  const handleCreateTag = (entity) => {
    setCreateTag(entity)
  }

  const handleClearFields = () => {
    setTagTitleValue('')
    setTagDescriptionValue('')
    setTitleCount(0)
    setDescriptionCount(0)
    setNewTier1([])
    setNewTier2([])
    setNewTier3([])
    setStatusLeadTagsEmpty(false)
    setStatusTitleLength(null)
    setStatusTitleCharacter(false)
    setStatusTitleExisting(false)
    setStatusDescriptionLength(null)
    setStatusDescriptionCharacter(false)
  }
  const handleTitleCount = () => {
    setStatusTitleExisting(false)
    setTitleCount(tagTitle.current.value.length)
		if (tagTitle.current.value.includes('<') || tagTitle.current.value.includes('>')) {
			setStatusTitleCharacter(true)
		} else {
			setStatusTitleCharacter(false)
		}
		if (tagTitle.current.value.length > 55) {
			setStatusTitleLength("tooLong")
		} else {
			setStatusTitleLength(null)
    }
  }
  const handleDescriptionCount = () => {
    setDescriptionCount(tagDescription.current.value.length)
		if (tagDescription.current.value.includes('<') || tagDescription.current.value.includes('>')) {
			setStatusDescriptionCharacter(true)
		} else {
			setStatusDescriptionCharacter(false)
		}
		if (tagDescription.current.value.length > 255) {
			setStatusDescriptionLength("tooLong")
		} else {
			setStatusDescriptionLength(null)
    }
  }

  const handleSaveNewTag = async (action, entity) => {
    setSaveSuccess(false)
    if (action === 'create') {
      if (tagDescription.current.value.includes('<') || tagDescription.current.value.includes('>') || tagDescription.current.value.length > 255 || tagDescription.current.value.length === 0 || tagTitle.current.value.includes('<') || tagTitle.current.value.includes('>') || tagTitle.current.value.length > 255 || tagTitle.current.value.length === 0) {
        if (tagDescription.current.value.includes('<') || tagDescription.current.value.includes('>')) {
          setStatusDescriptionCharacter(true)
        }
        if (tagDescription.current.value.length > 255) {
          setStatusDescriptionLength("tooLong")
        }
        if (tagDescription.current.value.length === 0) {
          setStatusDescriptionLength("tooShort")
        }
        if (tagTitle.current.value.includes('<') || tagTitle.current.value.includes('>')) {
          setStatusTitleCharacter(true)
        }
        if (tagTitle.current.value.length > 255) {
          setStatusTitleLength("tooLong")
        }
        if (tagTitle.current.value.length === 0) {
          setStatusTitleLength("tooShort")
        }
      } else {
        let matchedTag = null
        if (entity === 'lead') {
          matchedTag = teamLeadTags.find(tag => tag.label.toUpperCase() === tagTitle.current.value.toUpperCase())
        } else {
          matchedTag = teamMortgageTags.find(tag => tag.label.toUpperCase() === tagTitle.current.value.toUpperCase())
        }
        if (matchedTag) {
          setStatusTitleExisting(true)
        } else {
          if (entity === 'lead') {
            setLeadSaveFetching(true)
            let resp = await addLeadTag({
              action: action,
              newTier1: newTier1,
              newTier2: newTier2,
              newTier3: newTier3,
              tagTitle: tagTitle.current.value,
              tagDescription: tagDescription.current.value,
              teamId: teamId,
              userFullName: userFullName,
              leadId: leadData._id,
              dashPerspective: dashPerspective,
              userId: userId,
              mortgageId: mortgageData._id,
            })
            if (resp.error) {
              setIsError(true)
            } else {
              setSaveSuccess(true)
            }
            setNewLeadTag([''])
            setLeadSaveFetching(false)
            setShowLeadAssignSave(false)
          } else {
            setMortgageSaveFetching(true)
            let resp = await addMortgageTag({
              reqOrigin: 'lead',
              action: action,
              tagTitle: tagTitle.current.value,
              tagDescription: tagDescription.current.value,
              teamId: teamId,
              userFullName: userFullName,
              leadId: leadData._id,
              dashPerspective: dashPerspective,
              userId: userId,
              mortgageId: mortgageData._id,
            })
            if (resp.error) {
              setIsError(true)
            } else {
              setSaveSuccess(true)
            }
            setNewMortgageTag([''])
            setMortgageSaveFetching(false)
            setShowMortgageAssignSave(false)
          }
          handleClearFields()
          setCreateTag(null)
        }
      } 
    } else {
      let noTags = false
      if (entity === 'lead') {
        if (newLeadTag.length === 0) {
          setStatusLeadTagsEmpty(true)
          noTags = true
        }
      } else {
        if (newMortgageTag.length === 0) {
          setStatusLeadTagsEmpty(true)
          noTags = true
        }
      }
      if (!noTags && entity === 'lead') {
        setLeadSaveFetching(true)
        await addLeadTag({
          action: action,
          newTag: newLeadTag,
          userFullName: userFullName,
          leadId: leadData._id,
          dashPerspective: dashPerspective,
          userId: userId,
          mortgageId: mortgageData._id,
        })
        setNewLeadTag([''])
        setLeadSaveFetching(false)
        setShowLeadAssignSave(false)
      } else if (!noTags && entity === 'mortgage') {
        setMortgageSaveFetching(true)
        await addMortgageTag({
          reqOrigin: 'lead',
          action: action,
          newTag: newMortgageTag,
          userFullName: userFullName,
          leadId: leadData._id,
          dashPerspective: dashPerspective,
          userId: userId,
          mortgageId: mortgageData._id,
        })
        setNewMortgageTag([''])
        setMortgageSaveFetching(false)
        setShowMortgageAssignSave(false)
      }
      handleClearFields()
      setCreateTag(null)
    }
  }

  useEffect(() => {
    let newAvailableLeadTags = [...teamLeadTags]
    if (leadData.tags) {
      for (let i = 0; i < leadData.tags.length; i++) {
        let thisTag = newAvailableLeadTags.find(tag => tag._id.toString() === leadData.tags[i].tagId.toString())
        let thisIndex = newAvailableLeadTags.indexOf(thisTag)
        newAvailableLeadTags.splice(thisIndex, 1)
      }
      setAvailableLeadTags(newAvailableLeadTags)
    }
  }, [leadData])
  useEffect(() => {
    let newAvailableMortgageTags = [...teamMortgageTags]
    if (mortgageData.tags) {
      for (let i = 0; i < mortgageData.tags.length; i++) {
        let thisTag = newAvailableMortgageTags.find(tag => tag._id.toString() === mortgageData.tags[i].tagId.toString())
        let thisIndex = newAvailableMortgageTags.indexOf(thisTag)
        newAvailableMortgageTags.splice(thisIndex, 1)
      }
    }
    setAvailableMortgageTags(newAvailableMortgageTags)
  }, [mortgageData])

  return (
    <ComponentSectionRow className={"addTags header gray" + (createTag !== null ? ' closed open' : ' closed')} style={{marginTop: 'auto'}}>
      <FlexWrapper className="justifyCenter" style={{gridRow: '1', gridColumn: '1'}}>
        {activeLead &&
          <FlexWrapper 
            className={((createTag === null) ? 'onDisplay column' : 'hidden column')}
            style={{width: 'fit-content', margin: activeLead ? '0 auto 0 3rem' : '0 auto'}}
          >
            <FlexWrapper>
              <InputWrapper>
                <StatusAndLabelWrapper>
                  <Label>Assign Lead Tag</Label>
                  <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                    {statusLeadTagsEmpty === true && (
                      <ErrorWrapper>
                        <ErrorIcon />
                        Select a tag
                      </ErrorWrapper>
                    )}
                  </FlexWrapper>
                </StatusAndLabelWrapper>
                <LeadTagDropdown
                  style={{width: '100%'}}
                  value={newLeadTag}
                  handleChange={handleSetNewLeadTag}
                  items={availableLeadTags}
                  disabled={!assignLeadTagPerm}
                />
              </InputWrapper>
              {showLeadAssignSave ?
                <Tooltip
                  componentsProps={{tooltip: {sx: {
                    color: `${theme.tooltip.label.color}`,
                    backgroundColor: `${theme.tooltip.label.background}`,
                    border: `0.1rem solid ${theme.tooltip.label.border}`,
                    borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                    opacity: '0',
                    borderRadius: '1rem',
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    padding: '0.5rem 1rem',
                  }}}}
                  PopperProps={{modifiers: [{
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  }]}}
                  title={newLeadTag.length > 0 && assignLeadTagPerm ?
                    <FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Save</span></FlexWrapper>
                  : !assignLeadTagPerm || leadSaveFetching ?
                    null
                  :
                    <FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>No Lead Tags Selected</span></FlexWrapper>
                  } 
                  placement="top"
                  arrow
                >
                  <Button 
                    onClick={(!leadSaveFetching && assignLeadTagPerm) ? () => handleSaveNewTag('add', 'lead') : null}
                    className={!assignLeadTagPerm ? "primaryDisabled notes"  : leadSaveFetching ? "primaryLoading notes" : "primary notes"}
                    style={{marginBottom: '1.5rem'}}
                  >
                    <SaveIcon />
                  </Button>
                </Tooltip>
              :
                <FlexWrapper style={{width: '3rem', minWidth: '3rem', maxWidth: '3rem'}} />
              }
            </FlexWrapper>
          </FlexWrapper>
        }
        <FlexWrapper 
          className={((createTag === null) ? 'onDisplay column' : 'hidden column')}
          style={{width: 'fit-content', margin: activeLead ? '0 auto 0 3rem' : '0 auto'}}
        >
          <FlexWrapper>
            <InputWrapper>
              <StatusAndLabelWrapper>
                <Label>
                  {activeLead ? 'Assign Mortgage Tag' : 'Assign Tag'}
                </Label>
                <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                  {statusMortgageTagEmpty === true && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Select a tag
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </StatusAndLabelWrapper>
              <MortgageTagDropdown
                style={{width: '100%'}}
                value={newMortgageTag}
                handleChange={handleSetNewMortgageTag}
                items={availableMortgageTags}
                disabled={!assignMortgageTagPerm}
              />
            </InputWrapper>
            {showMortgageAssignSave ?
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.label.color}`,
                  backgroundColor: `${theme.tooltip.label.background}`,
                  border: `0.1rem solid ${theme.tooltip.label.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                  opacity: '0',
                  borderRadius: '1rem',
                  width: 'fit-content',
                  minWidth: 'fit-content',
                  padding: '0.5rem 1rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                }]}}
                title={newMortgageTag.length > 0 && assignMortgageTagPerm ?
                  <FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Save</span></FlexWrapper>
                : !assignMortgageTagPerm || mortgageSaveFetching ?
                  null
                :
                  <FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>No Mortgage Tags Selected</span></FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <Button 
                  onClick={(!mortgageSaveFetching && assignMortgageTagPerm) ? () => handleSaveNewTag('add', 'mortgage') : null}
                  className={!assignMortgageTagPerm ? "primaryDisabled notes"  : mortgageSaveFetching ? "primaryLoading notes" : "primary notes"}
                  style={{marginBottom: '1.5rem'}}
                >
                  <SaveIcon />
                </Button>
              </Tooltip>
            :
              <FlexWrapper style={{width: '3rem', minWidth: '3rem', maxWidth: '3rem'}} />
            }
          </FlexWrapper>
        </FlexWrapper>  
        <FlexWrapper className="column justifyCenter fitContentWidth" style={{margin: '0 1rem'}}>
          {activeLead &&
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.label.color}`,
                backgroundColor: `${theme.tooltip.label.background}`,
                border: `0.1rem solid ${theme.tooltip.label.border}`,
                borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                opacity: '0',
                borderRadius: '1rem',
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: '0.5rem 1rem',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={createTag === 'lead' ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Close
                  </Typography>
                </FlexWrapper>
              : null} 
              placement="top"
              arrow
            >
              <DetailWrapper 
                style={{marginBottom: '0.5rem', padding: '0.2rem 1rem', height: 'fit-content'}} 
                onClick={!createLeadTagPerm ? null : createTag === 'lead' ? () => setCreateTag(null) : () => handleCreateTag('lead')}
                className={(!createLeadTagPerm ? 'addButtonDisabled' : createTag === 'lead' ? 'addButton active' : 'addButton')} 
              >
                <AddCircleOutlineIcon style={{height: '2rem', width: '2rem', color: `${theme.palette.primary.main}`, margin: '0 0.3rem 0 0'}} /> 
                Create New Lead Tag
              </DetailWrapper>
            </Tooltip>
          }
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.tooltip.label.color}`,
              backgroundColor: `${theme.tooltip.label.background}`,
              border: `0.1rem solid ${theme.tooltip.label.border}`,
              borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
              opacity: '0',
              borderRadius: '1rem',
              width: 'fit-content',
              minWidth: 'fit-content',
              padding: '0.5rem 1rem',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={createTag === 'mortgage' ?
              <FlexWrapper className="column">
                <Typography className="bold alignTextCenter">
                  Close
                </Typography>
              </FlexWrapper>
            : null} 
            placement="top"
            arrow
          >
            <DetailWrapper 
              style={{marginTop: '0.5rem', padding: '0.2rem 1rem', height: 'fit-content'}}
              onClick={!createMortgageTagPerm ? null : createTag === 'mortgage' ? () => setCreateTag(null) : () => handleCreateTag('mortgage')}
              className={(!createMortgageTagPerm ? 'addButtonDisabled' : createTag === 'mortgage' ? 'addButton active' : 'addButton')} 
            >
              <AddCircleOutlineIcon style={{height: '2rem', width: '2rem', color: `${theme.palette.primary.main}`, margin: '0 0.3rem 0 0'}} /> 
              Create New Mortgage Tag
            </DetailWrapper>
          </Tooltip>
        </FlexWrapper>
      </FlexWrapper>
      <ComponentSectionRow className={((createTag !== null) ? 'onDisplay' : 'hidden')} style={{gridRow: '', gridColumn: '1'}}>
        <FlexWrapper className="column" style={{width: '60rem', margin: '0 auto'}}>
          <InputWrapper className="notes" style={{margin: '0 1rem 0.5rem 1rem', width: '28rem'}}>
            <StatusAndLabelWrapper className="notes">
              <Label>
                {createTag === 'lead' ? 'Lead' : 'Mortgage'} Tag Title
              </Label>
              <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                {statusTitleCharacter && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Cannot include the characters " < " or " > "`}
                  </ErrorWrapper>
                )}
                {statusTitleLength === "tooLong" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Too long`}
                  </ErrorWrapper>
                )}
                {statusTitleLength === "tooShort" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Cannot be blank`}
                  </ErrorWrapper>
                )}
                {statusTitleExisting && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Tag already exists`}
                  </ErrorWrapper>
                )}
                <Label className="count">{titleCount}/55</Label>
              </FlexWrapper>
            </StatusAndLabelWrapper>
            <StyledInputElement style={{width: '100%'}}
              autoFocus
              value={tagTitleValue}
              type="text"
              name="TagTitle"
              id="TagTitle"
              ref={tagTitle}
              onChange={() => {
                if (statusTitleLength === "tooShort" || statusTitleLength === "tooLong") {
                  setStatusTitleLength(null)
                }
                if (statusTitleCharacter) {
                  setStatusTitleCharacter(null)
                }
                setTagTitleValue(tagTitle.current.value)
                handleTitleCount()
              }}
            />
          </InputWrapper>
          <InputWrapper className="notes" style={{width: '100%'}}>
            <StatusAndLabelWrapper className="notes">
              <Label>
                {createTag === 'lead' ? 'Lead' : 'Mortgage'} Tag Description
              </Label>
              <FlexWrapper className="fitContentWidth alignCenter" style={{height: '2rem'}}>
                {statusDescriptionCharacter === true && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Cannot include the characters " < " or " > "`}
                  </ErrorWrapper>
                )}
                {statusDescriptionLength === "tooLong" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Too long`}
                  </ErrorWrapper>
                )}
                {statusDescriptionLength === "tooShort" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Cannot be blank`}
                  </ErrorWrapper>
                )}
                <Label className="count">{descriptionCount}/255</Label>
              </FlexWrapper>
            </StatusAndLabelWrapper>
            <StyledTextArea
              value={tagDescriptionValue}
              type="text"
              name="tagDescription"
              id="tagDescription"
              ref={tagDescription}
              onChange={() => {
                if (statusDescriptionLength === "tooShort" || statusDescriptionLength === "tooLong") {
                  setStatusDescriptionLength(null)
                }
                if (statusDescriptionCharacter) {
                  setStatusDescriptionCharacter(false)
                }
                setTagDescriptionValue(tagDescription.current.value)
                handleDescriptionCount()
              }}
            />
          </InputWrapper>
          {createTag === 'lead' &&
            <FlexWrapper className="column" style={{margin: '2rem 0 0 0', height: 'fit-content', width: '100%'}}>
              <span>(<span style={{fontWeight: '600'}}>OPTIONAL</span>) Add fields to automatically assign this tag when a discrepancy is found</span>
              <FlexWrapper className="justifyBetween">
                <InputWrapper style={{margin: '0 auto', width: '18rem', maxWidth: '18rem'}}>
                  <StatusAndLabelWrapper>
                    <Label>Tier 1</Label>
                  </StatusAndLabelWrapper>
                  <Tier1ParametersDropdown
                    style={{width: '100%'}}
                    value={newTier1}
                    handleChange={(event) => setNewTier1(event.target.value)}
                    items={tier1Parameters}
                  />
                </InputWrapper>
                <InputWrapper style={{margin: '0 auto', width: '18rem', maxWidth: '18rem'}}>
                  <StatusAndLabelWrapper>
                    <Label>Tier 2</Label>
                  </StatusAndLabelWrapper>
                  <Tier2ParametersDropdown
                    style={{width: '100%'}}
                    value={newTier2}
                    handleChange={(event) => setNewTier2(event.target.value)}
                    items={tier2Parameters}
                  />
                </InputWrapper>
                <InputWrapper style={{margin: '0 auto', width: '18rem', maxWidth: '18rem'}}>
                  <StatusAndLabelWrapper>
                    <Label>Tier 3</Label>
                  </StatusAndLabelWrapper>
                  <Tier3ParametersDropdown
                    style={{width: '100%'}}
                    value={newTier3}
                    handleChange={(event) => setNewTier3(event.target.value)}
                    items={tier3Parameters}
                  />
                </InputWrapper>
              </FlexWrapper>
            </FlexWrapper>
          }
          <FlexWrapper className="justifyCenter" style={{padding: '1rem 0'}}>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.label.color}`,
                backgroundColor: `${theme.tooltip.label.background}`,
                border: `0.1rem solid ${theme.tooltip.label.border}`,
                borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                opacity: '0',
                borderRadius: '1rem',
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: '0.5rem 1rem',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Clear Inputs</span></FlexWrapper>} 
              placement="top"
              arrow
            >
              <Button onClick={() => handleClearFields()} className="grayDisabled notes" style={{margin: '0 0.5rem 0 0'}}><BackspaceIcon /></Button>
            </Tooltip>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.label.color}`,
                backgroundColor: `${theme.tooltip.label.background}`,
                border: `0.1rem solid ${theme.tooltip.label.border}`,
                borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                opacity: '0',
                borderRadius: '1rem',
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: '0.5rem 1rem',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Cancel</span></FlexWrapper>} 
              placement="top"
              arrow
            >
              <Button onClick={() => setCreateTag(null)} className="grayDisabled notes"  style={{marginRight: '0.5rem'}}><CancelIcon /></Button>
            </Tooltip>
            {leadSaveFetching ?
              <Button className="primaryLoading notes"><SaveIcon /></Button>
            :
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.label.color}`,
                  backgroundColor: `${theme.tooltip.label.background}`,
                  border: `0.1rem solid ${theme.tooltip.label.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.label.border}`,
                  opacity: '0',
                  borderRadius: '1rem',
                  width: 'fit-content',
                  minWidth: 'fit-content',
                  padding: '0.5rem 1rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                }]}}
                title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>Save</span></FlexWrapper>} 
                placement="top"
                arrow
              >
                <Button onClick={() => handleSaveNewTag('create', createTag)} className="primary notes"><SaveIcon /></Button>
              </Tooltip>
            }
          </FlexWrapper>
        </FlexWrapper>
      </ComponentSectionRow>
    </ComponentSectionRow>
  )
}

export default AssignOrCreateTag