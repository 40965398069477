import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSelector } from "react-redux";
import SweepPerformanceModal from "../../../../../components/Modals/SweepPerformanceModal/SweepPerformanceModal";
import { selectTeamQueryStats } from "../../../../../slices/teamStatsSlice";
import { ActionIconWrapper, Divider, FlexWrapper } from "../../../../../StyledComponents";

const QuickStats = () => {
  const theme = useTheme()
  const teamQueries = useSelector(selectTeamQueryStats)

  const [openSweepReportsModal, setOpenSweepReportsModal] = useState(false)
  const handleOpenSweepReportsModal = () => {
    setOpenSweepReportsModal(true)
  }
  const handleCloseSweepReportsModal = () => {
    setOpenSweepReportsModal(false)
  }

  return (
    <>
      <div style={{width: '100%', height: '100%', gridRow: '3', gridColumn: '1', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderRight: `0.1rem solid #c8cbcf`}}>
        <div style={{width: '100%', height: '100%', padding: '2rem 2rem 0 2rem'}}>
          <Divider className="darkGray" style={{margin: 'auto'}} />
          <FlexWrapper className="alignCenter justifyBetween fitContentHeight" style={{padding: '2.5rem 0 0 0'}}>
            <div style={{color: `${theme.palette.common.grayText}`}}>
              Last Record Sweep:
            </div>
            <div style={{color: `${theme.palette.common.black}`, justifyContent: 'flex-end'}}>
              {teamQueries.lastQuery}
            </div>
          </FlexWrapper>
          <FlexWrapper className="alignCenter justifyBetween fitContentHeight" style={{padding: '0.5rem 0 0 0'}}>
            <div style={{color: `${theme.palette.common.grayText}`}}>
              Next Record Sweep:
            </div>
            <div style={{color: `${theme.palette.common.black}`, justifyContent: 'flex-end'}}>
              {teamQueries.nextQuery}
            </div>
          </FlexWrapper>
          <ActionIconWrapper className="neutral" style={{padding: '0.5rem 0 0 0', margin: '0.3rem auto 0 auto'}} onClick={() => handleOpenSweepReportsModal()}>
            view sweep reports
            <OpenInNewIcon style={{height: '1.4rem', width: '1.4rem', marginLeft: '0.2rem'}} /> 
          </ActionIconWrapper>
          <FlexWrapper className="alignCenter justifyBetween fitContentHeight" style={{padding: '2.5rem 0 0 0'}}>
            <div style={{color: `${theme.palette.common.grayText}`}}>
              Monthly queries
            </div>
          </FlexWrapper>
          <FlexWrapper className="alignCenter justifyBetween fitContentHeight" style={{paddingTop: '0.5rem'}}>
            <div style={{color: `${theme.palette.common.grayText}`}}>
              used:
            </div>
            <div style={{color: `${theme.palette.common.black}`, justifyContent: 'flex-end'}}>
              {teamQueries.subscriptionMonthlyQueries-teamQueries.remainingMonthlyQueries}
            </div>
          </FlexWrapper>
          <FlexWrapper className="alignCenter justifyBetween fitContentHeight" style={{}}>
            <div style={{color: teamQueries.remainingMonthlyQueries === 0 ? `${theme.palette.common.orangeDark}` : `${theme.palette.common.grayText}`}}>
            remaining:
            </div>
            <div style={{color: teamQueries.remainingMonthlyQueries === 0 ? `${theme.palette.common.orangeDark}` : `${theme.palette.common.black}`, justifyContent: 'flex-end', marginTop: 'auto'}}>
              {teamQueries.remainingMonthlyQueries}
            </div>
          </FlexWrapper>
        </div>
      </div>

      <Dialog open={openSweepReportsModal} onClose={handleCloseSweepReportsModal}
        PaperProps={{ sx: {width: "121rem", minWidth: "121rem", maxWidth: "121rem", height: "55rem", minHeight: "55rem", maxHeight: "55rem"}}}
        tabIndex={-1}
      >
        <SweepPerformanceModal handleCloseModal={handleCloseSweepReportsModal} origin={'quickStats'} />
      </Dialog>
    </>
  )
}

export default QuickStats;