import React from "react"
import Tooltip from '@mui/material/Tooltip';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectDesignateLeadAwaitingUpdatePerm } from "../../../../../../../slices/userActionsPermissionsSlice";
import { Badge, CardButton, FlexWrapper, NewLabel, Typography } from "../../../../../../../StyledComponents"

const Verify = ({ lead, handleContinueAwaitingUpdate, handleConfirmRecords }) => {
  const theme = useTheme()

  const designateLeadAwaitingUpdatePerm = useSelector(selectDesignateLeadAwaitingUpdatePerm)

  return (
    <FlexWrapper className="justifyCenter alignCenter" style={{maxWidth: '31rem', minWidth: '31rem', height: '100%'}}>
      <FlexWrapper className="column alignCenter" style={{paddingTop: '0.5rem'}}>
        {lead.publicRecordsUpdated &&
          <NewLabel className="leadCard" style={{width: 'fit-content', padding: '0.2rem 1rem'}}>Public Records updated!</NewLabel>
        }
        {lead.newAssignmentNotification &&
          <NewLabel className="leadCard" style={{width: '10rem'}}>New Assignment</NewLabel>
        }
        <FlexWrapper className="justifyCenter" style={{margin: '0.5rem 0 0.25rem 0'}}>
          <Typography className="tinyHeight tiny" style={{marginRight: '0.5rem'}}>
            Last Scan:
          </Typography>
          <Typography className="bold tiny">
            {lead.belongsToMortgage.lastUpdateDate}
          </Typography>
        </FlexWrapper> 
        {(lead.belongsToMortgage.rejectedDiscrepancies > 0 || lead.belongsToMortgage.activeDiscrepancies > 0) ?
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="alignCenter justifyEnd bold" style={{height: '2rem'}}>
              <Badge 
                className="three awaiting"
                style={{marginRight: '0.3rem', height: '1.8rem', width: '1.8rem', top: '0', right: '0', left: '0'}}
              >
                {lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies}
              </Badge>
              {(lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies) === 1 ?
                `Field`
              :
                `Fields`
              }
              <span style={{marginLeft: '0.3rem'}}>
                Awaiting Public Records to match
              </span>
            </Typography>
          </FlexWrapper>
        :
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="fitContentHeight justifyEnd bold" style={{marginRight: '0.5rem'}}>
              No active discrepancies!
            </Typography>
          </FlexWrapper>
        }
        {lead.belongsToMortgage.resolvedDiscrepancies > 0 && (lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies !== 0) &&
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="fitContentHeight justifyEnd" style={{marginRight: '0.5rem'}}>
              Resolved Discrepancies:
            </Typography>
            <Typography className="fitContentHeight bold">
              {lead.belongsToMortgage.resolvedDiscrepancies}
            </Typography>
          </FlexWrapper>
        }
        <FlexWrapper className="column alignCenter" style={{margin: '1rem auto auto auto', height: 'fit-content', paddingRight: '2rem'}}>
          <FlexWrapper className="alignCenter" style={{margin: '0 auto', width: 'fit-content'}}>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.palette.primary.pastelDark}`,
                backgroundColor: `${theme.palette.primary.pastelLight}`,
                border: `0.3rem solid ${theme.palette.common.grayTextLight}`,
                borderRight: `0.2rem solid ${theme.palette.common.grayTextLight}`,
                opacity: '0',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, -7],
                },
              }]}}
              title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>verify records</span></div>} 
              placement="left"
              arrow
            >
              <CardButton style={{minHeight: 'fit-content'}} onClick={() => handleConfirmRecords()} className="iconButton primary">
                <TaskAltIcon style={{height: '3rem', width: '3rem'}} />
              </CardButton>
            </Tooltip>
            <Typography className="bold tiny alignTextCenter" style={{marginLeft: '1rem'}}>
              Verify the Public Records reflect<br/> the expected arrangements
            </Typography>
          </FlexWrapper>
          <Typography className="accent" style={{padding: '0.4rem 0 0 1rem'}}>
            -or-
          </Typography>
          <Typography className="accent justifyCenter alignTextCenter" style={{padding: '0.4rem 0 0 2rem'}}>
            make any expected changes in the "compare Records" tab to continue waiting for Pubic Records to match
          </Typography>
          {/* <Typography className="accent" style={{padding: '0.4rem 0 0 1rem'}}>
            -or-
          </Typography>
          <FlexWrapper className="alignCenter" style={{margin: '0 auto', width: 'fit-content'}}>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.palette.common.grayText}`,
                backgroundColor: `${theme.palette.common.grayHover}`,
                border: `0.3rem solid ${theme.palette.common.grayText}`,
                borderRight: `0.2rem solid ${theme.palette.common.grayText}`,
                opacity: '0',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, -7],
                },
              }]}}
              title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>await update</span></div>} 
              placement="left"
              arrow
            >
              <CardButton style={{minHeight: 'fit-content'}} onClick={designateLeadAwaitingUpdatePerm ? () => handleContinueAwaitingUpdate() : null} className={designateLeadAwaitingUpdatePerm ? "iconButton cancel" : "iconButton primaryDisabled cancel"}>
                <PendingIcon style={{height: '3rem', width: '3rem'}} />
              </CardButton>
            </Tooltip>
            <Typography className="bold tiny" style={{marginLeft: '1rem'}}>
              Confirm further updates are required
            </Typography>
          </FlexWrapper> */}
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default Verify