import React from "react";
import FullLogo from '../../../../Full.logo.white.png';
import Navigation from "./components/Navigation";
import QuickStats from "./components/QuickStats";
import { SideBarContainer, TitleContainer } from "../../../../StyledComponents";

const SideBar = ({ userId }) => {

  return (
    <SideBarContainer>
      <TitleContainer>
        <img src={FullLogo} style={{width: '90%', height: 'auto', margin: 'auto'}} alt="Lancaster Logo" />
      </TitleContainer>
      <Navigation userId={userId} />
      <QuickStats />
    </SideBarContainer>
  )
}



export default SideBar;