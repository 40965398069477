import React from "react";
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const TeamSettingsGuide = () => {

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Settings" Component
      </GuideText>
      <GuideText className="medium">
        contains a handful of settings that apply to your team and your account.
      </GuideText>
      <GuideText>
        Administrators can change team settings, while any user can edit their own profile.
      </GuideText>

      <Divider className="narrow" style={{margin: '3rem 0 2rem 0'}} />

      <GuideText className="header">
        Security
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        Passwords are the first line of defense for authenticating that the person using an account is who they say they are.
      </GuideText>
      <GuideText className="halfIndent">
        If a password is compromised, unwanted users will have unfettered access to your application.
      </GuideText>
      <GuideText className="halfIndent">
        Unless further authentication is required.
      </GuideText>
      <GuideText className="halfIndent">
        Your team can enforce the following security mandates in an effort to protect access to the site:
      </GuideText>

      <GuideText className="newParagraph header medium halfIndent">
        * Two-Factor Authentication:
      </GuideText>
      <GuideText className="oneIndent">
        Requiring 2FA means that a user will need an Authenticar App on their mobile device that they can link to Lancaster Sweep.
      </GuideText>
      <GuideText className="oneIndent">
        They will use a token generated by this app each time they attempt to login to the web site.
      </GuideText>

      <GuideText className="newParagraph header medium halfIndent">
        * IP Whitelist:
      </GuideText>
      <GuideText className="oneIndent">
        Enforcing an IP Whitelist means that only access requests coming from verified IP addressess will be allowed in.
      </GuideText>
      <GuideText className="oneIndent">
        To get on the Whitelist, a user that attempts to log in from a new device will have an email sent to their address on file.
      </GuideText>
      <GuideText className="oneIndent">
        They will click a link to confirm they are trying to access the site and access will be granted.
      </GuideText>
      <GuideText className="oneIndent">
        Moving forward, they will only need to provide their login credentials since the device's IP address will now be recognized.
      </GuideText>
      <GuideText style={{padding: '3rem 6rem 0 6rem'}}>
        RECOMMENDATION - Lancaster Sweep employs multiple security layers to protect your dedicated system of servers and databases from outside internet traffic, but we also provide various methods of enforcing legitmate access to these resources. Utilizing each option will craete the most secure ecosystem, but we provide your team with the ability to choose how many of these locks you want to put in place.
      </GuideText>
      
      <Divider className="narrow" style={{margin: '3rem 0 2rem 0'}} />
      
      <GuideText className="header">
        Default Refinance Targets
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        As your team investigates contractural renegotiations,
      </GuideText>
      <GuideText className="halfIndent">
        Lancaster Sweep provides you with insights on the potential outcome of a refinance.
      </GuideText>
      <GuideText className="halfIndent">
        The system will apply a default loan term and interest rate to what is currently remaining on the mortgage,
      </GuideText>
      <GuideText className="halfIndent">
        thereby coming up with calculations like the new interest to be owed and the corresponding increase in net profit.
      </GuideText>
      <GuideText className="halfIndent">
        The terms can be updated on any specific investigation, but the default term and
      </GuideText>
      <GuideText className="halfIndent bottom">
        interest rate will be applied when a lead is first discovered.
      </GuideText>
    </FlexWrapper>
  )
}

export default TeamSettingsGuide