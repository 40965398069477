import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from "@mui/styles";
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';

const PropertySearchGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Property Search" Component
      </GuideText>
      <GuideText className="medium">
        allows any user to retrieve available public information on any property in real time.
      </GuideText>
      <GuideText>
        * There are a limited amount of manual searches your team can perform monthly, which is tracked in the navigation sidebar.
      </GuideText>
      
      <Divider className="narrow" />
      
      <GuideText className="medium">
        Use the search tool to:
      </GuideText>
      <FlexWrapper className="alignCenter" style={{paddingTop: '1rem'}}>
        <InfoIcon style={{height: '2.2rem', width: '2.2rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 2rem'}} />
        <GuideText className="header">
          Retrieve Information
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Perform a search of Publically available sources on any property in your area.
      </GuideText>
      <GuideText className="twoIndent">
        Information available includes geographic classifications, community designations, property details, and
      </GuideText>
      <GuideText className="twoIndent">
        declarations made in deeds, mortgages, liens, and court decrees.
      </GuideText>
      <FlexWrapper className="alignCenter" style={{paddingTop: '1rem'}}>
        <AddHomeWorkIcon style={{height: '2.2rem', width: '2.2rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 2rem'}} />
        <GuideText className="header">
          Save Properties
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        Save a search to add a new mortgage to your portfolio.
      </GuideText>

      <Divider className="narrow" />

      <GuideText className="medium newParagraph">
        Use the scan tool to:
      </GuideText>
      <FlexWrapper className="alignCenter" style={{paddingTop: '1rem'}}>
        <SignalWifiStatusbarConnectedNoInternet4Icon style={{height: '2.2rem', width: '2.2rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 2rem'}} />
        <GuideText className="header">
          Check for discrepancies
        </GuideText>
      </FlexWrapper>
      <GuideText className="twoIndent">
        While every property saved to your portfolio is scanned monthly automatically, 
      </GuideText>
      <GuideText className="twoIndent">
        you can perform a manual search on any property, at any time. 
      </GuideText>
    </FlexWrapper>
  )
}

export default PropertySearchGuide