import React from "react"
import WhiteLogo from "../../Full.logo.white.png"
import AccessForm from "./components/AccessForm"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectIPAcquired } from "../../slices/sessionDataSlice"
import { AuthContainer, PageContainer, ImageElements, ComponentBorder } from "../../StyledComponents"

const Login = () => {

  const ipAcquired = useSelector(selectIPAcquired)
  
  return (
    <PageContainer className="login">
      {ipAcquired ? <Navigate to="/login" /> : <></>}
      <AuthContainer>
        <AccessForm />
      </AuthContainer>

      <ComponentBorder className="loginTop">
        <ImageElements src={WhiteLogo} className="authNav" />
      </ComponentBorder>
    </PageContainer>
  )
}

export default Login
