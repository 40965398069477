import React from "react";
import Menu from "./components/Menu";
import TeamDropdown from "../../components/DropDowns/TeamDropdown";
import { useSelector } from "react-redux";
import { FlexWrapper, NavBarContainer } from "../../StyledComponents";
import { selectDashPerspective, selectNavSelection, selectPageSelection, selectRunningSweep } from "../../slices/sessionDataSlice";
 
const NavBar = () => {

  const navSelection = useSelector(selectNavSelection)
  const runningSweep = useSelector(selectRunningSweep)
  const pageSelection = useSelector(selectPageSelection)
  const dashPerspective = useSelector(selectDashPerspective)
  
  return (
    <NavBarContainer>
      {dashPerspective && navSelection === 'activeLeads' && pageSelection === 'dashboard' && !runningSweep ?
        <FlexWrapper className="alignCenter justifyCenter" style={{gridColumn: '1'}}>
          <TeamDropdown dashPerspective={dashPerspective} />
        </FlexWrapper>
      :
        <></>
      }
      <FlexWrapper className="justifyCenter" style={{gridColumn: '2'}}>
        <Menu runningSweep={runningSweep} />
      </FlexWrapper>
    </NavBarContainer>
  )
}

export default NavBar;