import React from "react";
import EmailIcon from '@mui/icons-material/Email';
import { useTheme } from "@mui/styles";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const NotificationsGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        Notification System
      </GuideText>
      <FlexWrapper>
        <GuideText className="medium">Lancaster Sweep ensures your team is up to date by providing</GuideText>
        <GuideText className="medium" style={{padding: '0 0.5rem'}}>In-App</GuideText>
        <GuideText className="medium">and</GuideText>
        <GuideText className="medium" style={{padding: '0 0.5rem'}}>Email</GuideText>
        <GuideText className="medium">notifications.</GuideText>
      </FlexWrapper>
      
      <Divider className="narrow" />

      <FlexWrapper className="alignCenter">
        <EmailIcon style={{height: '1.8rem', width: '1.8rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 0'}} />
        <GuideText className="header">
          Emails
        </GuideText>
      </FlexWrapper>
      <GuideText>
        Emails are reserved for timely and important information and fall under the following categories:
      </GuideText>
      <GuideText className="newParagraph halfIndent header medium">
        * Leads Discovered
      </GuideText>
      <GuideText className="twoIndent">
        The system will run periodic sweeps to compare your internal records with what is being tracked publically.
      </GuideText>
      <GuideText className="twoIndent">
        Reports will be emailed to your team to notify everyone of any situations that warrant investigation.
      </GuideText>
      <GuideText className="twoIndent">
        If no issues are discovered, you will know your portfolio is in optimum health.
      </GuideText>
      <GuideText className="newParagraph halfIndent header medium">
        * Assignments
      </GuideText>
      <GuideText className="twoIndent">
        Team members that are assigned to an investigation or mortgage will receive an email so they know to pick up the case.
      </GuideText>
      <GuideText className="newParagraph halfIndent header medium">
        * Performance
      </GuideText>
      <GuideText className="twoIndent">
        Monthly reports will be emailed to give your team a high-level view on the current health of your mortgage portfolio.
      </GuideText>
      <GuideText className="twoIndent">
        Information will include how many investigations were discovered, progressed, and closed over the last month.
      </GuideText>
      <GuideText className="twoIndent">
        Each individual will also be able to see an overview of their current log of assignments.
      </GuideText>

      <Divider className="narrow" />

      <FlexWrapper className="alignCenter">
        <NotificationsIcon style={{height: '1.8rem', width: '1.8rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 0'}} />
        <GuideText className="header">
          In-App
        </GuideText>
      </FlexWrapper>
      <GuideText>
        In-App notifications take a few forms, but all serve to direct a user's attention to whats changed or progressed since their last visit.
      </GuideText>
      <GuideText className="newParagraph halfIndent header medium">
        * Notifications
      </GuideText>
      <GuideText className="twoIndent">
        This component allows a user to keep track of how their team is utilizing the application.
      </GuideText>
      <GuideText className="twoIndent">
        Actions performed by teammates, or by the system itself, will add an entry to this log,
      </GuideText>
      <GuideText className="twoIndent">
        allowing these notifications to serve as a timeline of important actions, including new leads that were generated,
      </GuideText>
      <GuideText className="twoIndent">
        investigations that were launched, mortgages that were uploaded, and much more.
      </GuideText>
      <GuideText className="newParagraph halfIndent header medium">
        * Active Leads
      </GuideText>
      <GuideText className="twoIndent">
        Notifications are generated When a teammate performs actions on a lead you are assigned to.
      </GuideText>
      <GuideText className="twoIndent bottom">
        You can open up the Actions Window for the lead and see the recent changes recorded in the lead's Timeline..
      </GuideText>
    </FlexWrapper>
  )
}

export default NotificationsGuide