import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer, Typography } from "../../../StyledComponents";

const PropertySaved = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">updated by:</Detail>
          <Detail className="timelineDetail">date:</Detail>
          <Detail>Mortgage info included:</Detail>
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem">{milestone.date}</Detail>
          <Detail className="timelineItem">{milestone.details}</Detail>
        </DetailInfoContainer>
      </DetailListContainer>
        {milestone.newMortgageTags.length > 0 ?
          milestone.newMortgageTags.length > 1 ?
            <>
              <Detail>Tags Assigned:</Detail>
              <DetailListContainer style={{flexWrap: 'wrap', maxWidth: '35rem', padding: '1rem 0'}}>
                {milestone.newMortgageTags.map((mortgageTag, index) => (
                  mortgageTag.label === 'Loan Type' || mortgageTag.label === 'Property Type' ?
                    <Detail key={mortgageTag.label} className="timelineDetail tier three" style={{margin: '0.2rem auto 0.1rem 2rem'}}>
                      <Typography className="bold">{mortgageTag.label}</Typography>
                      <Typography className="thin" style={{margin: '0 0.5rem'}}>-</Typography>
                      <Typography className="thin">{mortgageTag.discrepancyFields}</Typography>
                    </Detail>
                  : 
                    <Detail key={mortgageTag.label} className="timelineDetail tier three" style={{margin: '0.2rem auto 0.1rem 2rem'}}>
                      <Typography className="bold">{mortgageTag.label}</Typography>
                    </Detail>
                ))}
              </DetailListContainer>
            </>
          :
            <>
              <Detail>New Mortgage Tag</Detail>
              <DetailListContainer style={{padding: '1rem 0'}}>
                <Detail className="timelineDetail tier three" style={{margin: '0.2rem auto 0.1rem 2rem'}}>{milestone.newMortgageTags[0].label}</Detail>
              </DetailListContainer>
            </>
            
        :
          <></>
        }
    </DetailContainer>
  )
}

export default PropertySaved