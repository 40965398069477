import React from "react";
import Guide from "./components/Guide/Guide";
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "./components/SideBar/SideBar";
import Reports from "./components/Reports/Reports";
import IconLogo from '../../icon.logo.black.png';
import Settings from "./components/Settings/Settings";
import LoadingLogo from '../../icon.logo.green.png';
import ActiveLeads from "./components/ActiveLeads/ActiveLeads";
import Configuration from "./components/Configuration/Configuration";
import PropertySearch from "./components/PropertySearch/PropertySearch";
import { useSelector } from "react-redux";
import GreenIconLogo from '../../icon.logo.green.png';
import MemberPerformance from "./components/MemberPerformance/MemberPerformance";
import MortgageRepository from "./components/MortgageRepository/MortgageRepository";
import RefinancesOverview from "./components/RefinancesOverview/RefinancesOverview";
import { selectAppLoaded, selectLoggedOut } from "../../slices/sessionDataSlice";
import RenegotiationsOverview from "./components/RenegotiationsOverview/RenegotiationsOverview";
import LeadGenerationOverview from "./components/LeadGenerationOverview/LeadGenerationOverview";
import { selectUserFullName } from "../../slices/userInfoSlice";
import { PageContainer, PageContentContainer, Footer, PageContentWrapper, ScreenSaverContainer, ImageElements, FlexWrapper } from "../../StyledComponents";

const Dashboard = ({ teamId, userId, subPage }) => {

  const appLoaded = useSelector(selectAppLoaded)
  const userFullName = useSelector(selectUserFullName)
  const loggedOut = useSelector(selectLoggedOut)

  return (
    <PageContainer className="dashboard">
      {!appLoaded ?
        <ScreenSaverContainer className="screenSaver">
          <ImageElements src={IconLogo} className="centerLogo opaque" style={{height: '40rem', width: '40rem'}}  />
          <ImageElements src={LoadingLogo} className="centerLogo loading one" />
          <ImageElements src={LoadingLogo} className="centerLogo loading two" />
          <ImageElements src={LoadingLogo} className="centerLogo loading three" />
          <ImageElements src={LoadingLogo} className="centerLogo loading four" />
          <ImageElements src={LoadingLogo} className="centerLogo loading five" />
        </ScreenSaverContainer>
      : loggedOut ?
        <ScreenSaverContainer className="screenSaver fadeIn">
          <FlexWrapper className="column" style={{margin: 'auto', padding: '0 0 10rem 0', height: 'fit-content', width: 'fit-content', zIndex: '100000'}}>
            <FlexWrapper style={{margin: '1rem 0 4.5rem 0'}}>
              <span style={{opacity: '0.8', fontSize: '3rem', color: `#DFDFEB`, fontWeight: '300', fontFamily: 'exo-bold', margin: '13rem 0 0 60.5rem'}}>
                Thank you for watching
              </span>
            </FlexWrapper>
          </FlexWrapper>
          <ImageElements src={GreenIconLogo} className="centerLogo" style={{height: '40rem', width: '40rem', opacity: '0.5'}} />
        </ScreenSaverContainer>
      :
        <ScreenSaverContainer className="fadeOut">
          <ImageElements src={IconLogo} className="centerLogo opaque" style={{height: '40rem', width: '40rem'}}  />
          <ImageElements src={LoadingLogo} className="centerLogo loaded one" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded two" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded three" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded four" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded five" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded six" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded seven" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded eight" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded nine" />
          <ImageElements src={LoadingLogo} className="centerLogo loaded ten" />
        </ScreenSaverContainer>
      }
      <NavBar />
      <SideBar userId={userId} />
      <PageContentContainer>
        <PageContentWrapper className="noScroll">
          {subPage === 'refinancesOverview' ?
            <RefinancesOverview teamId={teamId} />
          : subPage === 'renegotiationsOverview' ?
            <RenegotiationsOverview />
          : subPage === 'leadGeneration' ?
            <LeadGenerationOverview />
          : subPage === 'activeLeads' ?
            <ActiveLeads teamId={teamId} userId={userId} />
          : subPage === 'mortgageRepository' ?
            <MortgageRepository userId={userId} teamId={teamId} />
          : subPage === 'memberPerformance' ?
            <MemberPerformance />
          : subPage === 'propertySearch' ?
            <PropertySearch userFullName={userFullName} teamId={teamId} userId={userId} />
          : subPage === 'reports' ?
            <Reports userId={userId} />
          : subPage === 'configuration' ?
            <Configuration />
          : subPage === 'settings' ?
            <Settings teamId={teamId} userFullName={userFullName} userId={userId} />
          : subPage === 'guide' ?
            <Guide />
          :
            <></>
          }
        </PageContentWrapper>
        <Footer>
          <img src={IconLogo} alt="Lancaster Logo" style={{height: '80%', width: 'auto', marginRight: '0.3rem'}} />
          Lancaster Sweep
          {/* <span style={{fontSize: '0.4rem', paddingBottom: '0.5rem'}}>
            TM
          </span> */}
        </Footer>
      </PageContentContainer>
    </PageContainer>
  )
}

export default Dashboard;