import React, { useEffect, useState } from "react"
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@mui/styles";
import { CompareLink, FadeWrapper, FlexWrapper, Typography } from "../../../../../../../StyledComponents"

const BooleanSwitch = ({ handleWorkFlow, handleSubmitEdit, record }) => {
  const theme = useTheme()

  const [newValue, setNewValue] = useState('')

  useEffect(() => {
    if (record.currentValue === 'TRUE') {
      setNewValue('FALSE')
    } else if (record.currentValue === 'FALSE') {
      setNewValue('TRUE')
    } else if (record.currentValue === 'Y') {
      setNewValue('N')
    } else if (record.currentValue === 'N') {
      setNewValue('Y')
    } else if (record.currentValue === 'LEASEHOLD') {
      setNewValue('FEESIMPLE')
    } else if (record.currentValue === 'FEESIMPLE') {
      setNewValue('LEASEHOLD')
    }
  }, [record])

  return (
      <FadeWrapper>
        <Typography>
          switch to {newValue}?
        </Typography>
        <FlexWrapper className="fitContentHeight justifyCenter" style={{marginTop: '1rem'}}>
          <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{fontSize: '1rem', marginRight: '0.3rem'}}>
            <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
          </CompareLink>
          <CompareLink className="small green" onClick={() => handleSubmitEdit('edit', newValue)} style={{fontSize: '1rem', margin: '0'}}>
            <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
          </CompareLink>
        </FlexWrapper>
      </FadeWrapper>
  )
}

export default BooleanSwitch