import React from "react";
import EditTags from "../LeadActions/Components/EditTags/EditTags.js";
import Timeline from "../../Timeline/Timeline";
import CancelIcon from '@mui/icons-material/Cancel';
import NotesModal from "../LeadActions/Components/Notes.js";
import { useTheme } from "@mui/styles";
import ReportsModal from "../LeadActions/Components/ReportsModal.js";
import AddAssignees from "../LeadActions/Components/AddAssignees/AddAssignees.js";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ConfirmUpdate from "../LeadActions/Components/LeadStatus/ConfirmUpdate.js";
import CompareRecords from "../LeadActions/Components/CompareRecords.js";
import { useNavigate } from "react-router-dom";
import InvestigateLead from "../LeadActions/Components/LeadStatus/InvestigateLead.js";
import PropertyDetails from "../LeadActions/Components/PropertyDetails.js";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ProvideFinancials from "../LeadActions/Components/ProvideFinancials.js";
import MortgageActionsNav from "./MortgageActionsNav";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { selectViewingReports, setViewingReports } from "../../../slices/teamReportsSlice.js";
import { selectTeamMembersByName } from "../../../slices/teamMembersSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { selectViewingLead, setViewingLead } from "../../../slices/viewingLeadSlice.js";
import { selectViewingMortgage, setViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer, ComponentHeader, Typography, DetailWrapper, FlexWrapper } from "../../../StyledComponents";
import { removeNotificationFromMortgage, selectBackwardViewingMortgage, selectForwardViewingMortgage, selectMortgagesFilteredBoolean, setFwdBckViewingFilteredMortgages, setFwdBckViewingMortgages } from "../../../slices/teamMortgagesSlice.js";
import { selectTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads, setFwdBckFilteredViewingLeads, setFwdBckViewingLeads } from "../../../slices/teamActiveLeadsSlice.js";
import { selectLeadsFilteredBoolean, selectMortgageActionsNavSelection, setLeadActionNavMenuSelection, setLeadStatusFilter, setNavSelection, setShowMortgageActionsModal, setViewingLeadActions, setViewingMortgageActions } from "../../../slices/sessionDataSlice.js";

const MortgageActionsModal = ({ handleCloseModal, userId, teamId, userFullName, dashPerspective }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const leadData = useSelector(selectViewingLead)
  const mortgageData = useSelector(selectViewingMortgage)
  const navSelection = useSelector(selectMortgageActionsNavSelection)
  const viewingReports = useSelector(selectViewingReports)
  const teamMembersByName = useSelector(selectTeamMembersByName)
  const leadsFilteredBoolean = useSelector(selectLeadsFilteredBoolean)
  const forwardViewingMortage = useSelector(selectForwardViewingMortgage)
  const backwardViewingMortgage = useSelector(selectBackwardViewingMortgage)
  const mortgagesFilteredBoolean = useSelector(selectMortgagesFilteredBoolean)

  const closingLeads = useSelector(selectTeamClosingLeads)
  const investigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const awaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const awaitingUpdateLeads = useSelector(selectTeamAwaitingUpdateLeads)

  const handleGoToLeadActions = (mortgageStatus) => {
    dispatch( setViewingLead(leadData) )
    if (leadsFilteredBoolean || dashPerspective !== 'team') {
      dispatch( setFwdBckFilteredViewingLeads(leadData._id) )
    } else {
      dispatch( setFwdBckViewingLeads(leadData._id) )
    }
    dispatch( setNavSelection('investigations') )
    if (mortgageStatus === 'awaitingAction') {
      dispatch( setLeadStatusFilter('Awaiting Action') )
    } else if (mortgageStatus === 'investigating') {
      dispatch( setLeadStatusFilter('Investigating') )
    } else if (mortgageStatus === 'closing') {
      dispatch( setLeadStatusFilter('Closing') )
    } else {
      dispatch( setLeadStatusFilter('Awaiting PR Updates') )
    }
    dispatch( setViewingLeadActions(true) )
    dispatch( setViewingMortgageActions(false) )
    dispatch( setShowMortgageActionsModal(false) )
    navigate(`/dashboard/investigations/${mortgageData._id}`)
    dispatch( setLeadActionNavMenuSelection('compareRecords') )
  }

  const handleFwdBckViewingMortgage = (direction) => {
    if (direction === 'fwd') {
      dispatch( setViewingReports(forwardViewingMortage.reports) )
      if (forwardViewingMortage.activeLead) {
        let thisLead = {}
        thisLead = awaitingActionLeads.find(lead => lead._id === forwardViewingMortage.activeLead.toString())
        if (!thisLead) {
          thisLead = investigatingLeads.find(lead => lead._id === forwardViewingMortage.activeLead.toString())
          if (!thisLead) {
            thisLead = closingLeads.find(lead => lead._id === forwardViewingMortage.activeLead.toString())
            if (!thisLead) {
              thisLead = awaitingUpdateLeads.find(lead => lead._id === forwardViewingMortage.activeLead.toString())
            }
          }
        }
        dispatch( setViewingLead(thisLead) )
      } else {
        dispatch( setViewingLead(null) )
        dispatch( setFwdBckViewingLeads(null) )
        dispatch( setFwdBckFilteredViewingLeads(null) )
      }
      if (forwardViewingMortage.notifCount && forwardViewingMortage.notifCount > 0) {
        dispatch( removeNotificationFromMortgage({mortgageId: forwardViewingMortage._id}) )
      }
      if (mortgagesFilteredBoolean) {
        dispatch( setFwdBckViewingFilteredMortgages(forwardViewingMortage._id) )
      } else {
        dispatch( setFwdBckViewingMortgages(forwardViewingMortage._id) )
      }
      dispatch( setViewingMortgage(forwardViewingMortage) )
      navigate(`/dashboard/mortgageRepository/${forwardViewingMortage._id}`)
    } else {
      dispatch( setViewingReports(backwardViewingMortgage.reports) )
      if (backwardViewingMortgage.activeLead) {
        let thisLead = {}
        thisLead = awaitingActionLeads.find(lead => lead._id === backwardViewingMortgage.activeLead.toString())
        if (!thisLead) {
          thisLead = investigatingLeads.find(lead => lead._id === backwardViewingMortgage.activeLead.toString())
          if (!thisLead) {
            thisLead = closingLeads.find(lead => lead._id === backwardViewingMortgage.activeLead.toString())
            if (!thisLead) {
              thisLead = awaitingUpdateLeads.find(lead => lead._id === backwardViewingMortgage.activeLead.toString())
            }
          }
        }
        dispatch( setViewingLead(thisLead) )
      } else {
        dispatch( setViewingLead(null) )
        dispatch( setFwdBckViewingLeads(null) )
        dispatch( setFwdBckFilteredViewingLeads(null) )
      }
      if (backwardViewingMortgage.notifCount && backwardViewingMortgage.notifCount > 0) {
        dispatch( removeNotificationFromMortgage({mortgageId: backwardViewingMortgage._id}) )
      }
      if (mortgagesFilteredBoolean) {
        dispatch( setFwdBckViewingFilteredMortgages(backwardViewingMortgage._id) )
      } else {
        dispatch( setFwdBckViewingMortgages(backwardViewingMortgage._id) )
      }
      dispatch( setViewingMortgage(backwardViewingMortgage) )
      navigate(`/dashboard/mortgageRepository/${backwardViewingMortgage._id}`)
    }
  }

  return (
    <ModalContainer className="threePanel">
      <ComponentBorder className="top" style={{gridColumn: '1/6'}}>
        <ComponentHeader className="areaTitle" style={{paddingLeft: '1.5rem'}}>
          <FlexWrapper className="fitContentWidth alignCenter">
            <DetailWrapper 
              className={backwardViewingMortgage ? 'addButton' : 'addButtonDisabled'}
              onClick={backwardViewingMortgage ? () => handleFwdBckViewingMortgage('bck') : null} 
              style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', padding: '0.1rem 0.5rem 0.1rem 0.5rem'}}
            >
              <ArrowBackIosIcon style={{height: '1rem', width: '1rem', color: `${theme.palette.primary.main}`}} /> 
              prev
            </DetailWrapper>
            <DetailWrapper 
              className={forwardViewingMortage ? 'addButton' : 'addButtonDisabled'}
              onClick={forwardViewingMortage ? () => handleFwdBckViewingMortgage('fwd') : null} 
              style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', padding: '0.1rem 0.3rem 0.1rem 0.5rem', marginLeft: '0.5rem'}}
            >
              next
              <ArrowForwardIosIcon style={{height: '1rem', width: '1rem', color: `${theme.palette.primary.main}`}} /> 
            </DetailWrapper>
          </FlexWrapper>
          <Typography className="medium" style={{marginRight: '0.5rem', marginLeft: '1rem'}}>mortgage for:</Typography>
          <Typography className="xLarge">
            {mortgageData.recordDetails.address.StreetAddressOneLine.currentValue ?
              `${mortgageData.recordDetails.address.StreetAddressOneLine.currentValue}`
            :
              `${mortgageData.streetAddress}`
            }
          </Typography>
          {mortgageData.status !== 'inactive' &&
            <Typography className="xLarge" style={{margin: '0 0.6rem'}}>-</Typography>
          }
          {mortgageData.status === 'awaitingAction' ?
            <Typography className="large" style={{color: `${theme.accent.caution.primary}`, marginRight: '0.6rem'}}>
              Current Violation Awaiting Action
            </Typography>
          : mortgageData.status === 'investigating' ?
            <Typography className="large" style={{color: `${theme.accent.caution.primary}`, marginRight: '0.6rem'}}>
              Currently Under Investigation
            </Typography>
          : mortgageData.status === 'closing' ?
            <Typography className="large" style={{color: `${theme.accent.success.primary}`, marginRight: '0.6rem'}}>
              Currently Closing an Investigation
            </Typography>
          : mortgageData.status === 'awaitingUpdate' ?
            <Typography className="large" style={{color: `${theme.accent.success.primary}`, marginRight: '0.6rem'}}>
              Currently Awaiting Public Record Updates
            </Typography>
          :
            <></>
          }
          {(mortgageData.status === 'awaitingAction' || mortgageData.status === 'investigating' || mortgageData.status === 'closing' || mortgageData.status === 'awaitingUpdate') &&
            <DetailWrapper 
              className="addButton"
              onClick={() => handleGoToLeadActions(mortgageData.status)} 
              style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', marginLeft: '0.5rem'}}
            >
              {mortgageData.status === 'awaitingAction' ?
                'View Potential Violation'
              : mortgageData.status === 'awaitingUpdate' ?
                'View in Awaiting Update pool'
              :
                'View Investigation'
              }
              <OpenInNewIcon style={{height: '1.4rem', width: '1.4rem', color: `${theme.palette.primary.main}`, margin: '0 0 0 0.3rem'}} /> 
            </DetailWrapper>
          }
        </ComponentHeader>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <MortgageActionsNav mortgageData={mortgageData} reportsNumber={mortgageData.reports.length} handleCloseModal={handleCloseModal} leadData={leadData} activeLead={mortgageData.activeLead} />
      {navSelection === 'reports' ?
        <ReportsModal reports={viewingReports} />
      : navSelection === 'notes' ?
        <NotesModal userId={userId} userFullName={userFullName} leadData={leadData} mortgageData={mortgageData} />
      : navSelection === 'compareRecords' ?
        <CompareRecords mortgageData={mortgageData} userFullName={userFullName} leadData={leadData} teamId={teamId} location={'repository'} />
      : navSelection === 'propertyDetails' ?
        <PropertyDetails handleCloseModal={handleCloseModal} mortgageData={mortgageData} />
      : navSelection === 'investigateLead' ?
        <InvestigateLead handleCloseModal={handleCloseModal} userId={userId} teamId={teamId} userFullName={userFullName} teamMembersByName={teamMembersByName} mortgageId={mortgageData._id} leadData={{assigneeIds: []}} dashPerspective={dashPerspective} requestOrigin={'repository'} />
      : navSelection === 'editTags' ?
        <EditTags leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={mortgageData.activeLead} mortgageData={mortgageData} />
      : navSelection === 'addAssignees' ?
        <AddAssignees teamMembersByName={teamMembersByName} handleCloseModal={handleCloseModal} activeLead={mortgageData.activeLead} leadData={leadData} mortgageData={mortgageData} userFullName={userFullName} userId={userId} />
      : navSelection === 'provideFinancialsModal' || navSelection === 'provideFinancialsCard' || navSelection === 'provideTerms' ?
        <ProvideFinancials mortgageData={mortgageData} userFullName={userFullName} leadData={leadData} userId={userId} navSelection={navSelection} handleCloseModal={handleCloseModal} teamId={teamId} />
      : navSelection === 'confirmUpdate' ?
        <ConfirmUpdate handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} teamId={teamId} mortgageData={mortgageData} />
      :
        <></>
      }
      <ComponentHeader className="modal timeline">Timeline</ComponentHeader>
      <TimelineContainer className="actionsModal">
        <Timeline timeline={mortgageData.timeline} origin={'modal'} />
      </TimelineContainer>
      <ComponentBorder className="left" style={{gridRow: '2/6', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow: '2/6', gridColumn: '5'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow: '6', gridColumn: '1/6'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default MortgageActionsModal;