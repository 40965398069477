import React from "react"
import Portal from "./components/Portal"
import ErrorIcon from "@mui/icons-material/Error"
import WhiteLogo from "../../Full.logo.white.png"
import AccessForm from "./components/AccessForm"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { APP_ENVIRONMENT } from "./../../config";
import IconLogo from '../../icon.logo.black.png';
import LoadingLogo from '../../icon.logo.green.png';
import { selectIPAcquired, selectLoginFailed } from "../../slices/sessionDataSlice"
import { AuthContainer, PageContainer, ImageElements, ComponentBorder, ActionIconWrapper, ScreenSaverContainer } from "../../StyledComponents"

const Login = () => {

  const ipAcquired = useSelector(selectIPAcquired)
  const loginFailed = useSelector(selectLoginFailed)
  
  return (
    <PageContainer className="login">
      {/* {ipAcquired || loginFailed ? <Navigate to="/login" /> : <></>} */}
      {/* <Navigate to="/screensaver" /> */}

      <ScreenSaverContainer className="screenSaver fadeOut quick">
        {/* <ImageElements src={IconLogo} className="centerLogo opaque" style={{height: '40rem', width: '40rem'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loading" style={{right: '10rem', animationDelay: '0.5s'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loading" style={{right: '5rem', animationDelay: '0.7s'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loading" style={{animationDelay: '0.9s'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loading" style={{left: '5rem', animationDelay: '1.1s'}} />
        <ImageElements src={LoadingLogo} className="centerLogo loading" style={{left: '10rem', animationDelay: '1.3s'}} /> */}
      </ScreenSaverContainer>

      <AuthContainer>
        {loginFailed &&
          <ActionIconWrapper className="wrapped error noHover filled" style={{marginTop: '1rem', width: '90%', alignItems: 'center'}}>
            <ErrorIcon style={{height: '2rem', width: '2rem', margin: '0 0.5rem 0.2rem 0'}} />
            Something went wrong.<br/>Please try again or contact solutions@lancastersweep.com<br/>for immediate assistance.
          </ActionIconWrapper>
        }
        {APP_ENVIRONMENT === 'SANDBOX' ?
          <Portal />
        :
          <AccessForm />
        }
      </AuthContainer>

      <ComponentBorder className="loginTop">
        <ImageElements src={WhiteLogo} className="authNav" />
      </ComponentBorder>
    </PageContainer>
  )
}

export default Login
