import React from "react"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RepositoryTagsDropdown from "../../../../../components/DropDowns/RepositoryTagsDropdown";
import TeamRepositoryDropdown from "../../../../../components/DropDowns/TeamRepositoryDropdown";
import { grayUnselectedRadioColorProps } from "../../../../../componentThemes";
import { ComponentBorder, DetailWrapper, Divider, FlexWrapper, HeaderText, SubHeader } from "../../../../../StyledComponents"

const RepositoryControls = ({ matchTypeSelection, handleMatchTypeSelection, tagTypesForDropdown, customTagCombination, selectedTableDetail, handleSelectedTableDetails, userFilter, handleUserChange, displaySelection, handleDisplayChange, handleOpenUploadModal }) => {
  const theme = useTheme()

  const displayControlProps = (item) => ({
    checked: displaySelection === item,
    onChange: handleDisplayChange,
    value: item,
    label: item,
    labelPlacement: "start",
    sx: {
      color: `${theme.palette.common.grayDark}`,
      padding: "0",
    }
  })
  const matchTypeSelectionProp = (item) => ({
    checked: matchTypeSelection === item,
    onChange: handleMatchTypeSelection,
    value: item,
    label: item,
    labelPlacement: "start",
    sx: {
      color: `${theme.palette.common.grayDark}`,
      padding: "0",
    }
  })

  return (
    <>
      <FlexWrapper className="fitContentHeight" style={{height: '5rem', padding: '0.6rem 0', backgroundColor: `${theme.component.background.tertiary}`}}>
        <FlexWrapper className="alignCenter justifyCenter fitContentWidth" style={{margin: '0 3rem 0 2rem'}}>
          <HeaderText className="center" style={{padding: '0 1rem 0 0'}}>
            Display:
          </HeaderText>
          <FormControl style={{paddingTop: '0.4rem'}}>
            <RadioGroup row >
              <FormControlLabel
                style={{marginLeft: '0'}}
                control={<Radio {...grayUnselectedRadioColorProps()} />}
                {...displayControlProps('Table View')} 
              />
              <FormControlLabel
                control={<Radio {...grayUnselectedRadioColorProps()} />}
                {...displayControlProps('Chart View')} 
              />
            </RadioGroup>
          </FormControl>
        </FlexWrapper>
        <Divider style={{width: '0.1rem', height: '100%', background: `${theme.palette.common.grayText}`}} />
        {displaySelection === 'Table View' ?
          <FlexWrapper className="alignCenter justifyCenter fitContentWidth" style={{margin: '0 0 0 1rem'}}>
            <HeaderText className="center" style={{padding: '0 1.5rem 0 0'}}>
              Filter by:
            </HeaderText>
            <FlexWrapper className="fitContentWidth fitContentHeight column alignEnd" style={{paddingTop: '0.5rem'}}>
              <SubHeader className="dark" style={{margin: '0'}}>
                Mortgage Detail:
              </SubHeader>
              <FlexWrapper className="fitContentWidth fitContentHeight" style={{margin: 'auto'}}>
                <HeaderText className="center" style={{padding: '0 0.5rem 0 0', fontSize: '1rem', color: customTagCombination.length === 0 ? `${theme.palette.common.grayText}` : `${theme.palette.common.grayDark}`}}>
                  match:
                </HeaderText>
                <FormControl>
                  <RadioGroup row >
                    <FormControlLabel style={{height: 'fit-content', margin: '0'}}
                      control={<Radio {...grayUnselectedRadioColorProps()} style={{padding: '0 0.4rem'}} disabled={customTagCombination.length === 0} />}
                      {...matchTypeSelectionProp('all')} 
                    />
                    <FormControlLabel style={{height: 'fit-content', margin: '0 0 0 0.3rem'}}
                      control={<Radio {...grayUnselectedRadioColorProps()} style={{padding: '0 0.4rem'}} disabled={customTagCombination.length === 0} />}
                      {...matchTypeSelectionProp('any')} 
                    />
                  </RadioGroup>
                </FormControl>
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper className="fitContentWidth alignCenter" style={{margin: '0 0 0 1rem'}}>
             <RepositoryTagsDropdown
                value={selectedTableDetail}
                handleChange={handleSelectedTableDetails}
                items={tagTypesForDropdown}
                origin={'table'}
              />
            </FlexWrapper>
            <FlexWrapper className="fitContentWidth alignCenter" style={{margin: '0 0 0 3rem'}}>
              <SubHeader className="dark">
                Investigation Assignee:
              </SubHeader>
              <TeamRepositoryDropdown userFilter={userFilter} handleUserChange={handleUserChange} />
            </FlexWrapper>
          </FlexWrapper>
        : displaySelection === 'Chart View' &&
          <FlexWrapper className="alignCenter justifyCenter fitContentWidth" style={{margin: '0 0 0 1rem'}}>
            <FlexWrapper className="column justifyEnd">
              <HeaderText className="center" style={{padding: '0 0.5rem 0 0'}}>
                Custom Detail Combination:
              </HeaderText>
              <FlexWrapper className="fitContentWidth fitContentHeight" style={{margin: 'auto'}}>
                <HeaderText className="center" style={{padding: '0 0.5rem 0 0', fontSize: '1rem', color: customTagCombination.length === 0 ? `${theme.palette.common.grayText}` : `${theme.palette.common.grayDark}`}}>
                  match:
                </HeaderText>
                <FormControl>
                  <RadioGroup row >
                    <FormControlLabel style={{height: 'fit-content', margin: '0'}}
                      control={<Radio {...grayUnselectedRadioColorProps()} style={{padding: '0 0.4rem'}} disabled={customTagCombination.length === 0} />}
                      {...matchTypeSelectionProp('all')} 
                    />
                    <FormControlLabel style={{height: 'fit-content', margin: '0 0 0 0.3rem'}}
                      control={<Radio {...grayUnselectedRadioColorProps()} style={{padding: '0 0.4rem'}} disabled={customTagCombination.length === 0} />}
                      {...matchTypeSelectionProp('any')} 
                    />
                  </RadioGroup>
                </FormControl>
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper className="fitContentWidth alignCenter" style={{margin: '0 0 0 1rem'}}>
              <RepositoryTagsDropdown
                value={customTagCombination}
                handleChange={handleSelectedTableDetails}
                items={tagTypesForDropdown}
                origin={'chart'}
              />
            </FlexWrapper>
          </FlexWrapper>
        }
        <FlexWrapper className="fitContentWidth alignCenter" style={{margin: '0 2rem 0 auto'}}>
          <DetailWrapper className="addButton" onClick={() => handleOpenUploadModal()} style={{width: 'fit-content', fontSize: '1rem', textWrap: 'nowrap', margin: '0', zIndex: '100'}}>
            <AddCircleOutlineIcon style={{marginRight: '0.5rem', color: `${theme.palette.primary.dark}`}} />
            Add new mortgages
          </DetailWrapper>
        </FlexWrapper>
      </FlexWrapper>
      <ComponentBorder className="bottomPanel" style={{height: '1rem', minHeight: '1rem'}} />
    </>
  )
}

export default RepositoryControls