import React from "react"
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { useSelector } from "react-redux";
import NotificationsIcon from '@mui/icons-material/Notifications';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { selectRunSingleScanPerm } from "../../../../../../../slices/userActionsPermissionsSlice";
import { selectTeamRemainingMonthlyQueries } from "../../../../../../../slices/teamStatsSlice";
import { ActionIconWrapper, Badge, FlexWrapper, NewLabel, Typography } from "../../../../../../../StyledComponents"

const Waiting = ({ lead, handleRunScan, handleOpenLeadActionsModal }) => {
  const theme = useTheme()

  const runSingleScanPerm = useSelector(selectRunSingleScanPerm)
  const remainingMonthlyQueries = useSelector(selectTeamRemainingMonthlyQueries)

  return (
    <FlexWrapper className="justifyCenter" style={{maxWidth: '31rem', minWidth: '31rem', height: '100%', padding: '0.5rem 1rem 0 1rem'}}>
      <FlexWrapper className='column justifyStart alignCenter'> 
        <FlexWrapper style={{margin: '0 auto 0.2rem 0'}}>
          {lead.newAssignmentNotification &&
            <NewLabel className="leadCard" style={{width: 'fit-content', margin: '0 0.5rem 0 0', padding: '0.1rem 0.5rem'}}>New Assignment</NewLabel>
          }
          {lead.newAssignmentNotification || (lead.notifCount && lead.notifCount !== 0) ?
            <NewLabel className={((lead.notifCount && lead.notifCount !== 0) ? "" : "inactive") + " notification hover"} onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.belongsToMortgage)} style={{padding: lead.notifCount && lead.notifCount !== 0 ? " 0.1rem 0.1rem 0.1rem 0.3rem" : " 0.1rem 0.5rem 0.1rem 0.3rem"}}>
              <NotificationsIcon style={{color: (lead.notifCount && lead.notifCount !== 0) ? `${theme.notification.color.active}` : `${theme.notification.color.inactive}`}} />
              {(lead.notifCount && lead.notifCount !== 0) &&
                <Badge className="cardNotif" style={{height: '1.5rem', width: '1.5rem'}}>
                  {lead.notifCount}
                </Badge>
              }
              <span style={{position: 'relative', right: lead.notifCount && lead.notifCount !== 0 ? '0.5rem' : '0'}}>
                {lead.newAssignmentNotification ? 
                  `Assigned`
                :
                  `Updates`
                }
              </span>
            </NewLabel>
          : lead.newLeadLabel ?
            <NewLabel className="new hover" onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.belongsToMortgage)}>
              <FlagCircleIcon style={{color: `${theme.palette.primary.dark}`}} />
              New
            </NewLabel>
          :
            <></>
          }
        </FlexWrapper>
        {(lead.belongsToMortgage.rejectedDiscrepancies > 0 || lead.belongsToMortgage.activeDiscrepancies > 0) ?
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="alignCenter justifyEnd bold" style={{height: '2rem'}}>
              <Badge 
                className="three awaiting"
                style={{marginRight: '0.3rem', height: '1.8rem', width: '1.8rem', top: '0', right: '0', left: '0'}}
              >
                {lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies}
              </Badge>
              {(lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies) === 1 ?
                `Field`
              :
                `Fields`
              }
              <span style={{marginLeft: '0.3rem'}}>
                Awaiting Public Records to match
              </span>
            </Typography>
          </FlexWrapper>
        :
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="fitContentHeight justifyEnd bold primary" style={{marginRight: '0.5rem'}}>
              No active discrepancies!
            </Typography>
          </FlexWrapper>
        }
        {lead.belongsToMortgage.resolvedDiscrepancies > 0 && (lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies !== 0) &&
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="fitContentHeight justifyEnd" style={{marginRight: '0.5rem'}}>
              Resolved Discrepancies:
            </Typography>
            <Typography className="fitContentHeight bold">
              {lead.belongsToMortgage.resolvedDiscrepancies}
            </Typography>
          </FlexWrapper>
        }
        <FlexWrapper className='fitContentHeight justifyCenter'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '12rem', marginRight: '0.5rem'}}>
            Last Scan:
          </Typography>
          <Typography className="fitContentHeight bold" style={{width: '8rem'}}>
            {lead.belongsToMortgage.lastUpdateDate}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className="alignCenter column" style={{width: 'fit-content'}}>
          <Typography className="tiny accent" style={{margin: '1rem 0 auto 0.3rem'}}>awaiting updates...</Typography>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.black}`,
              backgroundColor: `${theme.palette.common.offWhite}`,
              border: `0.3rem solid ${theme.palette.common.grayText}`,
              borderRight: `0.2rem solid ${theme.palette.common.grayText}`,
              opacity: '0',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -7],
              },
            }]}}
            title={runSingleScanPerm && !lead.disableScan && remainingMonthlyQueries > 0 ? null :
              <div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}>
                <span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>
                  {lead.disableScan ? `scan performed` : remainingMonthlyQueries === 0 ? 'No monthly queries remaining.' : 'Elevated authority required'}
                </span>
              </div>
            } 
            placement="left"
            arrow
          >
            <span>
              <ActionIconWrapper
                style={{width: '10rem', marginTop: '0.5rem'}}
                onClick={!runSingleScanPerm || lead.disableScan || remainingMonthlyQueries === 0 ? null : () => handleRunScan()} 
                className={!runSingleScanPerm || lead.disableScan || remainingMonthlyQueries === 0 ? "primaryDisabled wrapped disabled full" : "primary wrapped success full"}
              >
                <DocumentScannerIcon style={{height: '1.7rem', width: '1.7rem', marginRight: '0.5rem'}} />
                Run Scan
              </ActionIconWrapper>
            </span>
          </Tooltip>
        </FlexWrapper>               
      </FlexWrapper>
    </FlexWrapper>  
  )
}

export default Waiting