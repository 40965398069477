import React from "react"
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch } from "react-redux";
import { setShowWelcomeModal } from "../../../slices/sessionDataSlice";
import { ActionIconWrapper, ComponentBorder, ComponentHeader, FlexWrapper, ModalContainer, Typography } from "../../../StyledComponents"

const WelcomeModal = () => {
  const dispatch = useDispatch()

  return (
    <ModalContainer className="onePanel">
      <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/4'}}>
        <ComponentHeader className="areaTitle">
          Welcome!
        </ComponentHeader>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => dispatch( setShowWelcomeModal(false) )} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <FlexWrapper className="column alignCenter">
        <Typography className="sans xLarge" style={{padding: '2rem 0'}}>This demo showcases the capabilities of the Lancaster Sweep software.</Typography>        
        <Typography className="sans xLarge" style={{padding: '0'}}>It is designed to be a "sandbox" environment where the original data</Typography>        
        <Typography className="sans xLarge" style={{padding: '0'}}>is always restored when you leave the page,</Typography>        
        <Typography className="sans xLarge" style={{paddingBottom: '2rem'}}>so feel free to test everything out and make whatever edits you'd like!</Typography>        
        <Typography className="sans xLarge">Read through the "Quick Guide" for a breakdown of how the system is used.</Typography>        
        <Typography className="sans xLarge">Thank you for your interest.</Typography>        
      </FlexWrapper>
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default WelcomeModal