import React, { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from '@mui/icons-material/Check';
import TagTypeDisplay from "./TagTypeDisplay";
import AssignOrCreateTag from "./AssignOrCreateTag";
import { AccentInfo, ComponentSectionRow, ModalContentContainer, ListRow, CompareDetail, ScrollWindow, Typography, ComponentHeader } from "../../../../../StyledComponents";

const EditTags = ({ leadData, userFullName, teamId, dashPerspective, userId, activeLead, mortgageData }) => {

  const [isError, setIsError] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)

  return (
    <>
      {saveSuccess ?
        <ComponentHeader className="modal green">
          <CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />
          Success
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />
          Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
        </ComponentHeader>
      :
        <ComponentHeader className="modal">
          <Typography className="xLarge">
            {leadData.tags && leadData.tags.length > 0 && mortgageData.tags.length > 0 ?
              `Mortgage and Lead tags`
            : leadData.tags && leadData.tags.length > 0 ?
              `Lead tags`
            : mortgageData.tags.length > 0 ?
              `Mortgage tags`
            :
              `Tags`
            }
          </Typography>
        </ComponentHeader>
      }
      <ModalContentContainer className="topColumn" style={{gridRow: '3/6', overflow: 'hidden'}}>
        <ScrollWindow className="verticalOnly" style={{width: "100%"}}>
          {leadData.tags && leadData.tags.length > 0 &&
            <>
              <ListRow style={{marginBottom: '1rem'}}>
                <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                  Scan Assigned Lead Tags
                </CompareDetail>
              </ListRow>
              <TagTypeDisplay tagData={leadData} assignmentType={'query'} />
              {leadData && leadData.tagAdded &&
                <>
                  <ListRow style={{margin: '0.3rem 0 2rem 0'}}>
                    <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                      Manually Assigned Lead Tags
                    </CompareDetail>
                  </ListRow>
                  <TagTypeDisplay tagData={leadData} assignmentType={'manual'} />
                </>
              }
            </>
          }
          {leadData.tags && leadData.tags.length === 0 ?
            <>
              <ListRow style={{marginBottom: '1rem'}}>
                <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                  Scan Assigned Lead Tags
                </CompareDetail>
              </ListRow>
              <ComponentSectionRow>
                <AccentInfo style={{margin: '1rem 0 0 3rem'}}>-no lead tags-</AccentInfo>
              </ComponentSectionRow>
            </>
          :
            <></>
          }
          {mortgageData.tags.length > 0 &&
            <>
              <ListRow style={{margin: '0.3rem 0 2rem 0'}}>
                <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                  Automatically Assigned Mortgage Tags 
                </CompareDetail>
              </ListRow>
              <TagTypeDisplay tagData={mortgageData} assignmentType={'query'} tagType={'mortgage'} />
              {mortgageData.tagAdded &&
                <>
                  <ListRow style={{margin: '0.3rem 0 2rem 0'}}>
                    <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                      Manually Assigned Mortgage Tags
                    </CompareDetail>
                  </ListRow>
                  <TagTypeDisplay tagData={mortgageData} assignmentType={'manual'} tagType={'mortgage'} />
                </>
              }
            </>
          }
          {mortgageData.tags.length === 0 ?
            <ComponentSectionRow>
              <AccentInfo style={{margin: '1rem 0 0 3rem'}}>-no mortgage tags-</AccentInfo>
            </ComponentSectionRow>
          :
            <div style={{height: '3rem'}} />
          }
        </ScrollWindow>
        <AssignOrCreateTag setIsError={setIsError} setSaveSuccess={setSaveSuccess} leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={activeLead} mortgageData={mortgageData} />
      </ModalContentContainer>
    </>
  )
}

export default EditTags;