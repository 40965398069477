import React from "react";
import SaveIcon from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/Upload';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from "@mui/styles";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { MilestoneConnector, MilestonePathContainer } from "../../../../StyledComponents";

const PathConnectors = ({milestone, mapIndex, timeline}) => {
  const theme = useTheme()

  return (
    <>
      {(milestone.milestone === 'Public Records Updated' && milestone.activeDiscrepancies === 0) || milestone.milestone === 'All Discrepancies Resolved' ?
        <MilestonePathContainer>
          <MilestoneConnector className='small' />
          <TaskAltIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : '', margin: mapIndex !== 0 ? '0' : '0.5rem 0 0 0'}} />
          {(mapIndex + 1) !== timeline.length ?
              <MilestoneConnector />
            :
              <></>
          }
        </MilestonePathContainer>
      : milestone.milestone === 'Property Uploaded Successfully' ?
        <MilestonePathContainer>
          <UploadIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : '', margin: mapIndex !== 0 ? '0' : '0.5rem 0 0 0'}} />
          {(mapIndex + 1) !== timeline.length ?
              <MilestoneConnector />
            :
              <></>
          }
        </MilestonePathContainer>
      : milestone.milestone === 'Property Saved from Search' ?
        <MilestonePathContainer>
          <div style={{margin: '0.5rem 0 0 0'}}>
            <SaveIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
          </div>
          {(mapIndex + 1) !== timeline.length ?
              <MilestoneConnector />
            :
              <></>
          }
        </MilestonePathContainer>
      : milestone.milestone === 'Lead Discovered' || milestone.milestone === 'Manual Investigation' || milestone.milestone === 'Internal Records Updated' ?
        <MilestonePathContainer>
          {mapIndex === 0 ?
            <div style={{margin: '0.8rem 0 0 0'}}>
              {milestone.milestone === 'Internal Records Updated' ?
                <PendingIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
              :
                <FlagCircleIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
              }
            </div>
          :
            <>
              <MilestoneConnector className='small' />
              <div style={{margin: '0'}}>
                {milestone.milestone === 'Internal Records Updated' ?
                  <PendingIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
                :
                  <FlagCircleIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
                }
              </div>
            </>
          }
          {(mapIndex + 1) !== timeline.length ?
              <MilestoneConnector />
            :
              <></>
          }
        </MilestonePathContainer>
      : milestone.milestone === 'Finalized' || milestone.milestone === 'Lead Dismissed' ?
        <MilestonePathContainer>
          <MilestoneConnector className='small' />
          {milestone.awaitingUpdate || (mapIndex + 1) !== timeline.length ?
            <RadioButtonCheckedIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
          :
            <SportsScoreIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
          }
          {(mapIndex + 1) !== timeline.length ?
            <MilestoneConnector />
          :
            <></>
          }
        </MilestonePathContainer>
      : milestone.milestone === 'Public Records Verified' ?
        <MilestonePathContainer>
          <MilestoneConnector className='small' />
          <SportsScoreIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
        </MilestonePathContainer>
      :
        <MilestonePathContainer>
          <MilestoneConnector className='small' />
          <RadioButtonCheckedIcon style={{color: milestone.notify ? `${theme.palette.primary.main}` : ''}} />
          {(mapIndex + 1) !== timeline.length ?
              <MilestoneConnector />
            :
              <></>
          }
        </MilestonePathContainer>
      }
    </>
  )
}

export default PathConnectors