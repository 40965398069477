import React from "react";
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectEditDefaultPaymentSchedulePerm } from "../../../../../slices/userActionsPermissionsSlice";
import { selectTeamDefaultPaymentSchedule, selectTeamPaymentSchedules } from "../../../../../slices/teamAndUserSettingsSlice";
import { FlexWrapper, ComponentSectionRow, ColumnContent, ComponentHeader, Typography, DetailWrapper } from "../../../../../StyledComponents";

const MortgageSchedules = () => {
  const theme = useTheme()

  const paymentSchedules = useSelector(selectTeamPaymentSchedules)
  const defaultPaymentSchedule = useSelector(selectTeamDefaultPaymentSchedule)
  const editDefaultPaymentSchedulePerm = useSelector(selectEditDefaultPaymentSchedulePerm)

  return (
    <FlexWrapper className="column fitContentHeight" style={{paddingBottom: '4rem'}}>
      <ComponentHeader>
        Mortgage Schedules
      </ComponentHeader>
      <ComponentSectionRow style={{padding: '1rem 0 0.5rem 0'}}>
        <ColumnContent className="detail small lowercase thin column">
          <span>Lancaster Sweep uses schedules to provide up-to-date breakdowns of the</span>
          <span>interest and principal that has been paid on any mortgage, as well as how much of each remains due.</span>
          <span>Schedules can be assigned to each mortgage individually.</span>
          <span>Contact your App Administrator to request additional schedules.</span>
        </ColumnContent>
      </ComponentSectionRow>
      <ComponentSectionRow className="mortgageSchedules header subHeader" style={{border: `0.1rem solid ${theme.text.header.component}`, margin: '2rem 0 0 0'}}>
        <ColumnContent className="header" style={{gridColumn: '1'}}>
          <Typography style={{margin: '0 auto'}}>
            Name
          </Typography>
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '2', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          <Typography style={{margin: '0 auto'}}>
            Role
          </Typography>
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          <Typography style={{margin: '0 auto'}}>
            eMail
          </Typography>
        </ColumnContent>  
      </ComponentSectionRow>
      {paymentSchedules.map((schedule, index) => (
        <ComponentSectionRow key={schedule._id.toString()} className='mortgageSchedules' style={{padding: '0', border: `0.1rem solid ${theme.text.header.component}`}}>
          <ColumnContent className="detail small" style={{gridColumn: '1'}}>
            {schedule.label}
          </ColumnContent>
          <ColumnContent className="detail small" style={{gridColumn: '2', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
            {schedule.calculation}
          </ColumnContent>
          <ColumnContent className="detail small lowercase" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <FlexWrapper className="column" style={{padding: '0.4rem'}}>
              {schedule.legend.map((legend) => (
                <span key={legend.substring(0, 5)}>{legend}</span>
              ))}
            </FlexWrapper>
          </ColumnContent>
        </ComponentSectionRow>
      ))}
      <ComponentSectionRow style={{padding: '1rem 0 0.5rem 0'}}>
        <ColumnContent className="detail small lowercase thin">
          <span>Default Schedule:</span>
          <span style={{fontWeight: '500', marginLeft: '0.5rem'}}>{defaultPaymentSchedule && defaultPaymentSchedule.label}</span>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.tooltip.error.color}`,
              backgroundColor: `${theme.tooltip.error.background}`,
              border: `0.3rem solid ${theme.tooltip.error.border}`,
              borderBottom: `0.2rem solid ${theme.tooltip.error.border}`,
              opacity: '0',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -7],
              },
            }]}}
            title={''}
            placement="top"
            arrow
          >
            <DetailWrapper 
              className={!editDefaultPaymentSchedulePerm ? 'addButtonDisabled dark' : 'addButton'}
              style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', margin: '0 0 0 0.5rem', zIndex: '100', padding: '0 0.5rem 0 0', height: '1.5rem'}}
            >
              <EditIcon style={{height: '1rem', width: '1rem', color: `${theme.palette.primary.main}`, margin: '0 0.3rem'}} /> 
              Edit
            </DetailWrapper>
          </Tooltip>
        </ColumnContent>
      </ComponentSectionRow>
    </FlexWrapper>
  )
}

export default MortgageSchedules