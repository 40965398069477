import React from "react"
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { selectEditParameterStatusPerm } from "../../slices/userActionsPermissionsSlice";
import { CardButton, ColumnContent, ComponentSectionRow, Detail, FlexWrapper, RowsContainer, ScrollWindow } from "../../StyledComponents";

const ParameterQueryAndPerformanceStats = ({ mortgageDataModel, handleOpenSweepParameterModal }) => {
  const theme = useTheme()

  const editParameterStatus = useSelector(selectEditParameterStatusPerm)

  return (
    <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)'}}>
      <ComponentSectionRow className="header gray" style={{display: 'grid', gridTemplateColumns: `25rem 5rem 10rem 8rem 8rem 9rem 6rem 10rem 10rem 9rem 9rem 0.5rem`, padding: '0 0 0 2rem', borderTop: `0.1rem solid ${theme.palette.common.grayText}`}}>
        <ColumnContent className="header" style={{gridColumn: '1'}}>
          label
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '2', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          active
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          total queries
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '4', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          populated
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '5', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          no backup
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '6', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          backup only
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '7', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          empty
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '8', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          discrepancies
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '9', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          renegotiations
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '10', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          refinances
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '11', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          dismissals
        </ColumnContent>  
      </ComponentSectionRow>
      <RowsContainer style={{height: 'calc(100vh - 20.1rem)'}}>
        {mortgageDataModel.map((category, index) => (
          <div key={category.subCat}>
            <FlexWrapper className="fitContentHeight" style={{padding: '0.4rem 0 0.4rem 2rem', background: `${theme.palette.common.grayBorder}`, borderTop: index !== 0 ? `0.1rem solid ${theme.palette.common.grayText}` : 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
              <Detail className="timelineHeader">{category.subCat}</Detail>
            </FlexWrapper>
            <FlexWrapper className="column" style={{height: 'fit-content'}}>
              {category.fields.map((sweepParameter, index) => (
                <ComponentSectionRow key={sweepParameter._id} style={{padding: index === 0 ? '0.6rem 0 0.2rem 2rem' : index === (category.fields.length - 1) ? '0.2rem 0 0.6rem 2rem' : '0.2rem 0 0.2rem 2rem', background: index%2 === 1 ? `${theme.palette.common.grayBorder}` : '', display: 'grid', gridTemplateColumns: `25rem 5rem 10rem 8rem 8rem 9rem 6rem 10rem 10rem 9rem 9rem`}}>
                  <CardButton className={(sweepParameter.assignedTier === 'two' ? 'two' : sweepParameter.assignedTier === 'one' ? 'one' : 'three') + (editParameterStatus ? '' : ' disabled')} style={{width: 'fit-content', gridColumn: '1', padding: '0.1rem 0.6rem'}} onClick={editParameterStatus ? () => handleOpenSweepParameterModal(sweepParameter) : null}>
                    {sweepParameter.label.includes('Primary') ?
                      <>
                        {sweepParameter.label.replace('Primary', '').toUpperCase()}
                      </>
                    : sweepParameter.label.includes('Secondary') ?
                      <>
                        {sweepParameter.label.replace('Secondary', '').toUpperCase()}
                      </>
                    :
                      sweepParameter.label.toUpperCase()
                    }
                  </CardButton>
                  <Detail className="center" style={{gridColumn: '2'}}>
                    {sweepParameter.active ?
                        <RadioButtonCheckedIcon style={{color: `${theme.palette.primary.main}`}} />
                      :
                        <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorder}`}} />
                    }
                  </Detail>
                  <Detail className="center" style={{gridColumn: '3'}}>{sweepParameter.totalQueries}</Detail>

                  {sweepParameter.populated > 0 ?
                    <Detail className="center" style={{gridColumn: '4', color: `${theme.palette.primary.main}`, fontWeight: '600'}}>
                      {sweepParameter.populated} {( '(' + ((sweepParameter.populated/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                    </Detail>
                  :
                    <Detail className="center" style={{gridColumn: '4', color: '', fontWeight: '400'}}>-</Detail>
                  }
                  {sweepParameter.noBackup > 0 ?
                    <Detail className="center" style={{gridColumn: '5', color: `${theme.palette.common.orange}`, fontWeight: '600'}}>
                      {sweepParameter.noBackup} {( '(' + ((sweepParameter.noBackup/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                    </Detail>
                  :
                    <Detail className="center" style={{gridColumn: '5', color: '', fontWeight: '400'}}>-</Detail>
                  }
                  {sweepParameter.backupOnly > 0 ?
                    <Detail className="center" style={{gridColumn: '6', color: `${theme.palette.common.orange}`, fontWeight: '600'}}>
                      {sweepParameter.backupOnly} {( '(' + ((sweepParameter.backupOnly/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                    </Detail>
                  :
                    <Detail className="center" style={{gridColumn: '6', color: '', fontWeight: '400'}}>-</Detail>
                  }
                  {sweepParameter.empty > 0 ?
                    <Detail className="center" style={{gridColumn: '7', color: `${theme.palette.common.redDark}`, fontWeight: '600'}}>
                      {sweepParameter.empty} {( '(' + ((sweepParameter.empty/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                    </Detail>
                  :
                    <Detail className="center" style={{gridColumn: '7', color: '', fontWeight: '400'}}>-</Detail>
                  }
                  {sweepParameter.discrepancies > 0 ?
                    <Detail className="center" style={{gridColumn: '8', fontWeight: '600'}}>
                      {sweepParameter.discrepancies} {( '(' + ((sweepParameter.discrepancies/sweepParameter.totalQueries) * 100).toFixed(0) + '%)')}
                    </Detail>
                  :
                    <Detail className="center" style={{gridColumn: '8', color: '', fontWeight: '400'}}>-</Detail>
                  }                  
                  <Detail className="center" style={{gridColumn: '9', fontWeight: sweepParameter.renegotiations > 0 ? '600' : '400'}}>{sweepParameter.renegotiations}</Detail>
                  <Detail className="center" style={{gridColumn: '10', fontWeight: sweepParameter.refinances > 0 ? '600' : '400'}}>{sweepParameter.refinances}</Detail>
                  <Detail className="center" style={{gridColumn: '11', fontWeight: sweepParameter.dismissals > 0 ? '600' : '400'}}>{sweepParameter.dismissals}</Detail>
                </ComponentSectionRow>
              ))}
            </FlexWrapper>
          </div>
        ))}
      </RowsContainer>
    </ScrollWindow>
  )
}

export default ParameterQueryAndPerformanceStats;