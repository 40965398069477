import React, { useState } from "react";
import 'react-csv-importer/dist/index.css';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/styles";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Importer, ImporterField } from 'react-csv-importer';
import { useDispatch, useSelector } from "react-redux";
import { decrementTeamRemainingMonthlyQueries, selectTeamRemainingMonthlyQueries } from "../../../../../../slices/teamStatsSlice";
import { useRecordUploadPerformanceMutation, useReloadTagViewsMutation, useRunPropertySearchForUploadMutation, useSavePropertyMutation } from "../../../../../../slices/api/apiSlice";
import { ModalContainer, ComponentHeader, SubHeader, ComponentBorder, ActionIconWrapper, FlexWrapper, GridWrapper, Typography, LoadingBar, LoadingBarWrapper } from "../../../../../../StyledComponents";
import { incFileUploadMortgagesAdded, incFileUplodDuplicateMortgages, incFileUplodErrors, selectFileUploadDuplicateMortgages, selectFileUploadErrors, selectFileUploadMortgagesAdded, selectSessionDateParsed, setRunningSweep } from "../../../../../../slices/sessionDataSlice";

const CSVUploadModal = ({ handleCloseUploadModal, ownersChecked, financialsChecked, teamId, userFullName, userId, userRole }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isUploadError, setUploadIsError] = useState(false)
  const [isReloadError, setReloadIsError] = useState(false)
  const [monthlyQueriesExhausted, setMonthlyQueriesExhausted] = useState(false)

  const [mortgagesStagedForSweep, setMortgagesStagedForSweep] = useState(0)
  const [numMortgagesToSweep, setNumMortgagesToSweep] = useState(null)

  const remainingMonthlyQueries = useSelector(selectTeamRemainingMonthlyQueries)
  const duplicateMortgages = useSelector(selectFileUploadDuplicateMortgages)
  const sessionDateParsed = useSelector(selectSessionDateParsed)
  const fileUploadErrors = useSelector(selectFileUploadErrors)
  const mortgagesAdded = useSelector(selectFileUploadMortgagesAdded)

  const [runPropertySearchForUpload] = useRunPropertySearchForUploadMutation()
  const [recordUploadPerformance] = useRecordUploadPerformanceMutation()
  const [reloadTagViews] = useReloadTagViewsMutation()
  const [saveProperty] = useSavePropertyMutation()

  const handlePropertySearch = async (properties) => {
    dispatch( setRunningSweep(true) )
    setIsFetching(true)
    let errorObjs = []
    let errorCount = 0
    let newLeadIds = []
    let newLeadObjs = []
    let lastIsError = false
    let isDuplicate = false
    let totalNewLeads = 0
    let duplicateObjs = []
    let noResultsObjs = []
    let lastIsNewLead = false
    let mortgagesSwept = 0
    let noResultsCount = 0
    let duplicatesCount = 0
    let successfulCount = 0
    let lastIsDuplicate = false
    let lastIsNoResults = false
    let mortgagesToSweep = 0
    let totalDiscrepanciesCount = 0
    let monthlyQueriesExhausted = null
    let newRemainingMonthlyQueries = remainingMonthlyQueries
    for (let i = 0; i < properties.length; i++) {
      newRemainingMonthlyQueries--
      if (newRemainingMonthlyQueries >= 1 || (newRemainingMonthlyQueries >= 0 && properties.length === 1)) {
        dispatch( decrementTeamRemainingMonthlyQueries(1) )
        if (i === 0 && properties.length > 1) {
          if (properties.length > 2) {
            mortgagesToSweep = properties.length+1
            setNumMortgagesToSweep(properties.length+1)
          } else {
            mortgagesToSweep = properties.length
            setNumMortgagesToSweep(properties.length)
          }
          setMortgagesStagedForSweep(0)
        }
        let searchResp = await runPropertySearchForUpload({
          userFullName: userFullName,
          streetAddress: properties[i].StreetAddress,
          city: properties[i].City,
          state: properties[i].State,
          postalCode: properties[i].PostalCode,
          unitNumber: null,
        })
        if (!searchResp.error) {
          isDuplicate = false
          let LoanAmount = null
          let InterestRate = null
          let MortgageTerm = null
          let Owner2FullName = null
          let Owner1FullName = null
          let OriginationDate = null
          let recordDetailsObj = null
          let attomSuccessDate = null
          let propMixSuccessDate = null
          let coreLogicSuccessDate = null
          if (searchResp.data && searchResp.data.message === 'existing mortgage') {
            dispatch( incFileUplodDuplicateMortgages(1) )
            isDuplicate = true
            lastIsDuplicate = true
            duplicatesCount = duplicatesCount + 1
            duplicateObjs.push(searchResp.data.data.failureObj)
          } else if (searchResp.data && searchResp.data.message === 'no results') {
            lastIsNoResults = true
            noResultsCount = noResultsCount + 1
            noResultsObjs.push(searchResp.data.data.failureObj)
          } else if (searchResp.data && searchResp.data.message === 'error') {
            lastIsError = true
            dispatch( incFileUplodErrors(1) )  
            errorCount = errorCount + 1     
            setUploadIsError(true)  
            errorObjs.push(searchResp.data.data.failureObj)
          } else if (searchResp.data.data) {
            recordDetailsObj = searchResp.data.data.recordDetailsObj
            propMixSuccessDate = searchResp.data.data.propMixSuccessDate
            attomSuccessDate = searchResp.data.data.attomSuccessDate
            coreLogicSuccessDate = searchResp.data.data.coreLogicSuccessDate
          }
          if (ownersChecked) {
            Owner1FullName = properties[i].Owner1FullName
            Owner2FullName = properties[i].Owner2FullName
          }
          if (financialsChecked) {
            InterestRate = properties[i].InterestRate
            OriginationDate = properties[i].OriginationDate
            LoanAmount = properties[i].LoanAmount
            MortgageTerm = properties[i].MortgageTerm
          }
          if (!isDuplicate) {
            let saveResp = await saveProperty({
              teamId: teamId,
              userFullName: userFullName,
              fileUpload: true,
              recordDetailsObj: recordDetailsObj,
              attomSuccessDate: attomSuccessDate,
              propMixSuccessDate: propMixSuccessDate,
              coreLogicSuccessDate: coreLogicSuccessDate,
              streetAddress: properties[i].StreetAddress,
              ownersChecked: ownersChecked,
              Owner1FullName: Owner1FullName,
              Owner2FullName: Owner2FullName,
              financialsChecked: financialsChecked,
              OriginationDate: OriginationDate,
              LoanAmount: LoanAmount,
              MortgageTerm: MortgageTerm,
              InterestRate: InterestRate,
            })
            if (saveResp.data && !saveResp.data.message.includes('duplicate')) {
              dispatch( incFileUploadMortgagesAdded(1) )
              successfulCount = successfulCount + 1
              if (saveResp.data.data && saveResp.data.data.newLead) {
                totalNewLeads = totalNewLeads + 1
                totalDiscrepanciesCount = totalDiscrepanciesCount + saveResp.data.data.totalDiscrepanciesCount
                newLeadIds.push(saveResp.data.data.newLead._id)
                lastIsNewLead = true
                newLeadObjs.push({
                  leadId: saveResp.data.data.newLead._id,
                  streetAddress: properties[i].StreetAddress.toUpperCase(),
                  city: properties[i].City.toUpperCase(),
                  state: properties[i].State.trim().toUpperCase(),
                  postalCode: properties[i].PostalCode.toUpperCase(),
                })
              }
            } else if (!searchResp.data || (searchResp.data && searchResp.data.message !== 'error')) {     
              dispatch( incFileUplodErrors(1) )  
              errorCount = errorCount + 1     
              setUploadIsError(true)
              errorObjs.push({
                streetAddress: properties[i].StreetAddress.toUpperCase(),
                city: properties[i].City.toUpperCase(),
                state: properties[i].State.trim().toUpperCase(),
                postalCode: properties[i].PostalCode.toUpperCase(),
              })
            }
          }
        } else {
          dispatch( incFileUplodErrors(1) )
          errorCount = errorCount + 1
          setUploadIsError(true)
        }
        mortgagesSwept = mortgagesSwept + 1
        setMortgagesStagedForSweep(mortgagesSwept)
      } else {
        setNumMortgagesToSweep(mortgagesSwept)
        setMortgagesStagedForSweep(mortgagesSwept)
        setMonthlyQueriesExhausted(true)
        monthlyQueriesExhausted = true
        break
      }
    }
    if ((properties.length > 1 && newRemainingMonthlyQueries <= 1) || (properties.length === 1 && newRemainingMonthlyQueries === 0)) {
      monthlyQueriesExhausted = true
    } else if ((properties.length > 1 && newRemainingMonthlyQueries > 1) || (properties.length === 1 && newRemainingMonthlyQueries > 0)) {
      monthlyQueriesExhausted = false
    }
    let uploadType = 'Address only'
    if (ownersChecked && financialsChecked) {
      uploadType = 'Owners and Financials'
    } else if (ownersChecked) {
      uploadType = 'Owners included'
    } else if (financialsChecked) {
      uploadType = 'Financials included'
    }
    let recordUploadPerformanceResp = await recordUploadPerformance({
      userId: userId,
      teamId: teamId,
      userFullName: userFullName,
      uploadType: uploadType,
      lastIsDuplicate: lastIsDuplicate,
      lastIsError: lastIsError,
      lastIsNoResults: lastIsNoResults,
      lastIsNewLead: lastIsNewLead,
      notifyAssignees: true,
      totalNewLeads: totalNewLeads,
      recordNewMonthlyStats: true,
      uploadTimeParsed: sessionDateParsed,
      numberSuccessess: successfulCount,
      numberDuplicates: duplicatesCount,
      numberErrors: errorCount,
      numberNoResults: noResultsCount,
      errorObjs: errorObjs,
      noResultsObjs: noResultsObjs,
      duplicateObjs: duplicateObjs,
      newLeadIds: newLeadIds,
      leadObjs: newLeadObjs,
      totalDiscrepanciesCount: totalDiscrepanciesCount,
      monthlyQueriesExhausted: monthlyQueriesExhausted,
    })
    let reloadTagViewsResp = await reloadTagViews({
      teamId: teamId,
      userRole: userRole,
    })
    if (reloadTagViewsResp.error || recordUploadPerformanceResp.error) {
      setReloadIsError(true)   
    } else {
      setIsSuccess(true)
    }
    if (properties.length === 1) {
      setNumMortgagesToSweep(null)
      setMortgagesStagedForSweep(0)
    }
    setIsComplete(true)
    setIsFetching(false)
    dispatch( setRunningSweep(false) )
  }
  
  return (
    <ModalContainer className="onePanel" style={{height: 'fit-content'}}>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseUploadModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2', overflowY: 'scroll'}}>
        {isUploadError ?
          <ComponentHeader className="modal red" style={{height: '4rem'}}>
            <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
              <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
            </FlexWrapper>
            <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
              Upload Errors were encountered
            </span>
          </ComponentHeader>
        : monthlyQueriesExhausted ?
          <ComponentHeader className="modal caution" style={{height: '4rem'}}>
            <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.orangeDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.orange}`}}>
              <WarningAmberIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
            </FlexWrapper>
            <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
              Monthly queries were exhausted...
            </span>
          </ComponentHeader>
        : isReloadError ?
          <ComponentHeader className="modal red" style={{height: '4rem'}}>
            <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
              <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
            </FlexWrapper>
            <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
              Errors were encountered upon saving
            </span>
          </ComponentHeader>
        : isSuccess ?
          <ComponentHeader className="modal green" style={{height: '4rem'}}>
            <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
              <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
            </FlexWrapper>
            <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
              Success
            </span>
          </ComponentHeader>
        :
          <ComponentHeader className="modal" style={{height: '4rem'}}>Upload CSV File</ComponentHeader>
        }
        {isFetching || isComplete ?
          <>
            <SubHeader className="modal">
              <span style={{fontSize: '1.4rem', fontFamily: 'exo-mediumItalic', fontWeight: '600', color: `${theme.palette.common.grayText}`, margin: '0 auto'}}>
                Sweeping public records for each mortgage...
              </span>
            </SubHeader>
            <GridWrapper style={{gridTemplateColumns: '20rem 1fr 20rem', gridColumn: '2', height: '7rem'}}>
              <FlexWrapper className="justifyEnd alignCenter" style={{paddingRight: '1rem'}}>
                <Typography className="xxxLarge">{numMortgagesToSweep && mortgagesStagedForSweep}</Typography>
              </FlexWrapper>
              <FlexWrapper className="alignCenter">
                <LoadingBarWrapper>
                  <LoadingBar style={{width: numMortgagesToSweep && mortgagesStagedForSweep !== 0 ? `${Math.round(((mortgagesStagedForSweep)/numMortgagesToSweep)*100)}%` : numMortgagesToSweep ? '0%' : '100%'}} />
                </LoadingBarWrapper>
              </FlexWrapper>
              <FlexWrapper className="justifyStart alignCenter" style={{paddingLeft: '1rem'}}>
                  <Typography className="xxxLarge">{numMortgagesToSweep}</Typography>
                  <Typography className="xLarge">
                    {numMortgagesToSweep && mortgagesStagedForSweep !== 0 ?
                      '(' + Math.round(((mortgagesStagedForSweep)/numMortgagesToSweep)*100) + '%)'
                    : numMortgagesToSweep ?
                      '(0%)'
                    :
                      '100%'
                    }
                  </Typography>
              </FlexWrapper>
            </GridWrapper>
          </>
        :
          <SubHeader className="modal">
            <span>Follow the instructions below.</span>
          </SubHeader>
        }
        {isSuccess &&
          <SubHeader className="modal" style={{padding: '0'}}>
            <span style={{color: `${theme.palette.primary.main}`, fontSize: "1.6rem"}}>Success!</span>
            <span style={{fontSize: '1.2rem'}}>Properties Added: {mortgagesAdded}</span>
            <span style={{fontSize: '1.2rem'}}>Duplicates: {duplicateMortgages}</span>
            <span style={{fontSize: '1.2rem'}}>Errors: {fileUploadErrors}</span>
          </SubHeader>
        }

        <div style={{width: '100%', padding: '1.5rem'}}>
          <Importer
            dataHandler={async (rows) => {
              await handlePropertySearch(rows)
            }}
            defaultNoHeader={false}
            restartable={false}
            onClose={() => {
              handleCloseUploadModal()
            }}
          >
            <ImporterField name="StreetAddress" label="Street Address" />
            <ImporterField name="City" label="City" />
            <ImporterField name="State" label="State" />
            <ImporterField name="PostalCode" label="Postal Code" />
            {ownersChecked &&
              <>
                <ImporterField name="Owner1FullName" label="Owner One Full Name" />
                <ImporterField name="Owner2FullName" label="Owner Two Full Name" />
              </>
            }
            {financialsChecked &&
              <>
                <ImporterField name="OriginationDate" label="Start Date" />
                <ImporterField name="LoanAmount" label="Loan Amount" />
                <ImporterField name="MortgageTerm" label="Mortgage Term" />
                <ImporterField name="InterestRate" label="Interest Rate" />
              </>
            }
          </Importer>
        </div>
      </FlexWrapper>

      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ModalContainer>
  )
}

export default CSVUploadModal