import React, { useEffect, useState } from "react";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { setViewingLead } from "../../../../slices/viewingLeadSlice";
import { setViewingMortgage } from "../../../../slices/viewingMortgageSlice";
import { useDispatch, useSelector } from "react-redux";
import { ActionIconWrapper, Badge, ComponentSectionRow, DetailWrapper, Divider, FlexWrapper, ScrollWindow, Typography } from "../../../../StyledComponents";
import { selectTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads, setFwdBckFilteredViewingLeads, setFwdBckViewingLeads } from "../../../../slices/teamActiveLeadsSlice";
import { selectDashPerspective, selectLeadsFilteredBoolean, setLeadActionNavMenuSelection, setLeadStatusFilter, setNavSelection, setViewingLeadActions, setViewingMortgageActions, setViewingUploadWindow } from "../../../../slices/sessionDataSlice";

const UploadNotification = ({ notification, uploadNotificationPresent }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const [leadObjs, setLeadObjs] = useState([])

  const dashPerspective = useSelector(selectDashPerspective)
  const teamClosingLeads = useSelector(selectTeamClosingLeads)
  const leadsFilteredBoolean = useSelector(selectLeadsFilteredBoolean)
  const teamInvestigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const teamAwaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const teamAwaitingUpdateLeads = useSelector(selectTeamAwaitingUpdateLeads)

  const handleGoToUploadWindow = () => {
    dispatch( setViewingUploadWindow(true) )
    dispatch( setNavSelection('repository') )
    navigate(`/dashboard/mortgageRepository/`)
  }

  const handleGoToInvestigationWidnow = async (leadData) => {
    if (leadsFilteredBoolean || dashPerspective !== 'team') {
      dispatch( setFwdBckFilteredViewingLeads(leadData._id) )
    } else {
      dispatch( setFwdBckViewingLeads(leadData._id) )
    }
    dispatch( setViewingLead(leadData) )
    dispatch( setViewingMortgage(leadData.belongsToMortgage) )
    dispatch( setNavSelection('investigations') )
    if (leadData.status === 'awaitingAction') {
      dispatch( setLeadStatusFilter('Awaiting Action') )
    } else if (leadData.status === 'investigating') {
      dispatch( setLeadStatusFilter('Investigating') )
    } else if (leadData.status === 'closing') {
      dispatch( setLeadStatusFilter('Closing') )
    } else {
      dispatch( setLeadStatusFilter('Awaiting PR Updates') )
    }
    dispatch( setViewingLeadActions(true) )
    dispatch( setViewingMortgageActions(true) )
    navigate(`/dashboard/investigations/${leadData.belongsToMortgage._id}`)
    dispatch( setLeadActionNavMenuSelection('compareRecords') )
  }

  useEffect(() => {
    if (notification) {
      let newLeadObjs = []
      for (let i = 0; i < notification.leadObjs.length; i++) {
        let match = teamAwaitingActionLeads.find(lead => lead._id.toString() === notification.leadObjs[i].leadId.toString())
        if (match) {
          newLeadObjs.push({
            streetAddress: notification.leadObjs[i].streetAddress,
            city: notification.leadObjs[i].city,
            state: notification.leadObjs[i].state,
            postalCode: notification.leadObjs[i].postalCode,
            leadObj: match,
            leadClosed: false,
          })
        } else {
          let match = teamInvestigatingLeads.find(lead => lead._id.toString() === notification.leadObjs[i].leadId.toString())
          if (match) {
            newLeadObjs.push({
              streetAddress: notification.leadObjs[i].streetAddress,
              city: notification.leadObjs[i].city,
              state: notification.leadObjs[i].state,
              postalCode: notification.leadObjs[i].postalCode,
              leadObj: match,
              leadClosed: false,
            })
          } else {
            let match = teamClosingLeads.find(lead => lead._id.toString() === notification.leadObjs[i].leadId.toString())
            if (match) {
              newLeadObjs.push({
                streetAddress: notification.leadObjs[i].streetAddress,
                city: notification.leadObjs[i].city,
                state: notification.leadObjs[i].state,
                postalCode: notification.leadObjs[i].postalCode,
                leadObj: match,
                leadClosed: false,
              })
            } else {
              let match = teamAwaitingUpdateLeads.find(lead => lead._id.toString() === notification.leadObjs[i].leadId.toString())
              if (match) {
                newLeadObjs.push({
                  streetAddress: notification.leadObjs[i].streetAddress,
                  city: notification.leadObjs[i].city,
                  state: notification.leadObjs[i].state,
                  postalCode: notification.leadObjs[i].postalCode,
                  leadObj: match,
                  leadClosed: false,
                })
              }
            }
          }
        }
        if (!match) {
          newLeadObjs.push({
            streetAddress: notification.leadObjs[i].streetAddress,
            city: notification.leadObjs[i].city,
            state: notification.leadObjs[i].state,
            postalCode: notification.leadObjs[i].postalCode,
            leadClosed: true,
          })
        }
      }
      setLeadObjs(newLeadObjs)
    }
  }, [notification])

  return (
    <FlexWrapper className="fitContentHeight column">
      <FlexWrapper>
        <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
          Mortgages Uploaded
        </Typography>
        <Badge className={uploadNotificationPresent ? "flex one" : "flex readTransparent"} style={{height: notification.notifCount < 100 ? '1.8rem' : '2rem', width: notification.notifCount < 100 ? '1.8rem' : '2.8rem', margin: '0.5rem 0 0 0.6rem', padding: '0.1rem 0.4rem 0.1rem 0.3rem'}}>
          {notification.notifCount}
        </Badge>
        <DetailWrapper 
          className="addButton"
          onClick={() => handleGoToUploadWindow()} 
          style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', margin: '0.8rem 1rem 0 auto'}}
        >
          <OpenInNewIcon style={{height: '1.4rem', width: '1.4rem', color: `${theme.palette.primary.main}`, margin: '0 0.3rem 0 0', transform: 'rotate(180deg)'}} /> 
          View Uploads Window
        </DetailWrapper>
      </FlexWrapper>
      <Divider style={{margin: ' 0.5rem 0 0.2rem 0'}} />
      <FlexWrapper className='fitContentHeight' style={{width: '30rem', padding: '0.5rem 0 1rem 0'}}>
        <FlexWrapper className="fitContentHieght fitContentWidth column">
          <Typography className="fitContentHeight" style={{marginLeft: '1rem', width: '15rem'}}>
            {notification.date.length === 1 || (notification.date[0] === notification.date[1]) ?
              `date: ${notification.date[0]}`
            :
              `from ${notification.date[0]} to ${notification.date[notification.date.length-1]}`
            }
          </Typography>
          <Typography className="fitContentHeight" style={{marginLeft: '1rem', width: '15rem'}}>
            {notification.notifCount > 1 ?
              `last contributor: ${notification.contributor}`
            :
              `contributor: ${notification.contributor}`
            }
          </Typography>
        </FlexWrapper>
        <FlexWrapper className="column fitContentWidth fitContentHeight">
          <FlexWrapper className="fitContentWidth fitContentHeight" style={{marginLeft: notification.queryNewLeads > 0 ? '1.5rem' : '0.5rem'}}>
            <Typography className="fitContentHeight fitContentWidth bold">
              {notification.totalQueries}
            </Typography>
            <Typography className="fitContentHeight thin" style={{marginLeft: '0.3rem'}}>
              {notification.totalQueries === 1 ?
                'Upload'
              :
                'Uploads'
              }
            </Typography>
          </FlexWrapper>
          <FlexWrapper className="fitContentWidth fitContentHeight">
            {notification.queryNewLeads > 0 ?
              <FlexWrapper className="fitContentWidth fitContentHeight alignCenter">
                <ErrorOutlineIcon style={{width: '1.2rem', height: '1.2rem', color: `${theme.accent.error.primary}`, marginRight: '0.3rem'}} />
                <Typography className="fitContentHeight justifyCenter bold" style={{width: 'fit-content', marginRight: '0.3rem'}}>
                  {notification.queryNewLeads}
                </Typography>
              </FlexWrapper>
            :
              <Typography className="fitContentWidth fitContentHeight thin">
                <TaskAltIcon style={{color: `${theme.palette.primary.main}`, marginRight: '0.2rem'}} />
              </Typography>
            }
              <Typography className="fitContentHeight thin">
                {notification.queryNewLeads === 0 ?
                  'No discrepancies detected'
                : notification.queryNewLeads === 1 ?
                  'Mortgage requires attention'
                :
                  'Mortgages require attention'
                }
              </Typography>
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
      {leadObjs.length > 0 &&
        <FlexWrapper className="fitContentHeight column" style={{marginBottom: '0.5rem'}}>
          <Typography className="fitContentHeight" style={{margin: '0 0 0 2rem'}}>
            New investigations:
          </Typography>
          <ScrollWindow className="verticalOnly" style={{maxHeight: '15rem'}}>
            {leadObjs.map((row, index) => (
              <ComponentSectionRow 
                key={row.streetAddress} 
                className={row.leadClosed ? 'noPointHover' : "hover hoverActivate"} style={{justifyContent: 'center'}} 
                onClick={row.leadClosed ? null : () => handleGoToInvestigationWidnow(row.leadObj)}
              >
                {!row.leadClosed &&
                  <ActionIconWrapper style={{color: 'inherit'}}>
                    <VisibilityIcon style={{height: '1.4rem', width: '1.4rem', margin: '0 0.3rem 0 0', transform: 'rotate(180deg)'}} /> 
                  </ActionIconWrapper>
                }
                <Typography className="primary" style={{width: 'fit-content'}}>{row.streetAddress},</Typography>
                <Typography className="primary" style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.city},</Typography>
                <Typography className="primary" style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.state}</Typography>
                <Typography className="primary" style={{width: 'fit-content', marginLeft: '0.3rem'}}>{row.postalCode}</Typography>
              </ComponentSectionRow>
            ))}
          </ScrollWindow>
        </FlexWrapper>
      }
    </FlexWrapper>
  )
}

export default UploadNotification