import React, { useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import ParameterTags from "../../../../components/Configurations/ParameterTags";
import { useSelector } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import LeadTagPerformance from "../../../../components/Configurations/LeadTagPerformance";
import ParameterPerformanceStats from "../../../../components/Configurations/ParameterPerformanceStats";
import { selectMortgageDataModel } from "../../../../slices/mortgageDataModelSlice";
import { grayUnselectedRadioColorProps } from "../../../../componentThemes";
import { ComponentContainer, ComponentSectionRow, FlexWrapper, HeaderText, ComponentBorder } from "../../../../StyledComponents";

const Configuration = () => {
  const theme = useTheme()

  const mortgageDataModel = useSelector(selectMortgageDataModel)

  const [configurationView, seetConfigurationView] = useState('Field Performance')

  const configurationViewToggleControlProps = (item) => ({
    checked: configurationView === item,
    onChange: handleConfigurationViewChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  })
  
  const handleConfigurationViewChange = (event) => {
    seetConfigurationView(event.target.value)
  }

  return (
    <>
      <FlexWrapper style={{padding: '1rem'}}>
        <ComponentContainer className="fitWindow onePanel" style={{background: `${theme.palette.common.grayBorderLight}`}}>
          <FlexWrapper className="column fitContentHeight" style={{gridRow: '2', gridColumn: '2'}}>
            <ComponentSectionRow style={{justifyContent: 'center', padding: '1rem 0', background: `${theme.palette.common.grayBorderLight}`}}>
              <FlexWrapper className="fitContentWidth alignCenter">
                <HeaderText className="center" style={{margin: '0.2rem 0 0 0'}}>
                  Tables:
                </HeaderText>
                <FormControl style={{margin: '0.1rem 0 0 2.5rem'}}>
                  <RadioGroup row>
                    <FormControlLabel
                      control={<Radio {...grayUnselectedRadioColorProps()} />}
                      {...configurationViewToggleControlProps('Field Performance')} 
                    />
                    <FormControlLabel
                      control={<Radio {...grayUnselectedRadioColorProps()} />}
                      {...configurationViewToggleControlProps('Investigation Tags')} 
                    />
                    <FormControlLabel
                      control={<Radio {...grayUnselectedRadioColorProps()} />}
                      {...configurationViewToggleControlProps('Tag Performance')} 
                    />
                  </RadioGroup>
                </FormControl>
              </FlexWrapper>
            </ComponentSectionRow>
            {configurationView === 'Field Performance' ?
              <ParameterPerformanceStats mortgageDataModel={mortgageDataModel} />
            : configurationView === 'Investigation Tags' ?
              <ParameterTags mortgageDataModel={mortgageDataModel} />
            :
              <LeadTagPerformance mortgageDataModel={mortgageDataModel} />
            }
          </FlexWrapper>
          <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
          <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
          <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
          <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
        </ComponentContainer>
      </FlexWrapper>
    </>
  )
}

export default Configuration