import React, { useState, useContext } from "react";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import MuiMenu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/styles";
import { AppState } from "../../../AppState";
import ListItemIcon from "@mui/material/ListItemIcon";
import GridViewIcon from '@mui/icons-material/GridView';
import { useNavigate } from "react-router-dom";
import { FlexWrapper } from "../../../StyledComponents";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useSelector, useDispatch } from "react-redux";
import { selectUserInitials, selectUserRequestCredentials, selectUserRole } from "../../../slices/userInfoSlice";
import { selectPageSelection, setAdminNavSelection, setNavSelection, setPageSelection } from "../../../slices/sessionDataSlice";

const Menu = ({ runningSweep }) => {
  const theme = useTheme()
  const navigate = useNavigate()  
  const dispatch = useDispatch()
  const { signOut } = useContext(AppState)

  const reqCredentials = useSelector(selectUserRequestCredentials)
  const pageSelection = useSelector(selectPageSelection)
  const userInitials = useSelector(selectUserInitials)
  const userRole = useSelector(selectUserRole)
  
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePageSelection = (page) => {
    dispatch( setNavSelection('guide') )
    dispatch( setAdminNavSelection('uploads') )
    dispatch( setPageSelection(page) )
    navigate(`/${page}`)
  }

  return (
    <FlexWrapper className="justifyCenter">
      {!runningSweep ?
        <Tooltip title={<p style={{fontSize: "1.2rem", margin: "0px"}}>Menu</p>}>
          <IconButton
            onClick={handleClick}
            size="small"
          >
          <Avatar sx={{ 
            width: 30, 
            height: 30, 
            fontSize: "1.6rem", 
            backgroundColor: ``,
            fontWeight: "600", 
            background: anchorEl ? `radial-gradient(${theme.menuIcon.background.light}, ${theme.menuIcon.background.light}, ${theme.menuIcon.background.active})` : `radial-gradient(${theme.menuIcon.background.light}, ${theme.menuIcon.background.mid}, ${theme.menuIcon.background.dark})`,
            color: `${theme.menuIcon.color.active}`,
            border: anchorEl ? `0.2px solid ${theme.menuIcon.border.active}` : `0.2px solid ${theme.menuIcon.border.inactive}`,
            '&:hover': {
              background: `radial-gradient(${theme.menuIcon.background.light}, ${theme.menuIcon.background.light}, ${theme.menuIcon.background.active})`,
            }
          }}>
            {userInitials}
          </Avatar>
          </IconButton>
        </Tooltip>
      :
        <Tooltip title={<p style={{fontSize: "1.2rem", margin: "0px"}}>Page is loading...</p>}>
          <IconButton
            size="small"
          >
          <Avatar sx={{ width: 30, height: 30, fontSize: "1.6rem", backgroundColor: `${theme.panel.border.secondary}`, fontWeight: "600", color: `${theme.text.light.primary}` }}>
            {userInitials}
          </Avatar>
          </IconButton>
        </Tooltip>
      }
      {!runningSweep ?
        <MuiMenu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 0,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem 
            onClick={pageSelection === 'dashboard' ? null : () => handlePageSelection('dashboard')}
            style={{fontSize: "1.4rem", background: `${theme.palette.common.cardBackground}`}}
            disabled={pageSelection === 'dashboard'}
          >
            <ListItemIcon>
              <GridViewIcon fontSize="large" />
            </ListItemIcon>
            Dashboard
          </MenuItem>
          {userRole === 'super' &&
            <MenuItem 
              onClick={pageSelection === 'admin' ? null : () => handlePageSelection('admin')}
              style={{fontSize: "1.4rem", background: `${theme.palette.common.cardBackground}`}}
              disabled={pageSelection === 'admin'}
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="large" />
              </ListItemIcon>
              Admin
            </MenuItem>
          }
          <MenuItem 
            onClick={() => signOut(reqCredentials)}
            style={{fontSize: "1.4rem"}}
          >
            <ListItemIcon>
              <Logout fontSize="large" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </MuiMenu>
      :
        <></>
      }
    </FlexWrapper>
  )
}

export default Menu;