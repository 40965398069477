import React, { useState, useRef, useEffect } from "react";
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { calculateTargetChanges } from "../../../../utils/calculateTargetsPreview";
import { useDispatch, useSelector } from "react-redux";
import { setLeadActionNavMenuSelection } from "../../../../slices/sessionDataSlice";
import { useSaveLeadTargetChangesMutation } from "../../../../slices/api/apiSlice";
import { selectSaveTargetRefinanceChangesPerm } from "../../../../slices/userActionsPermissionsSlice";
import { selectMortgageTargets, setMortgageTargets } from "../../../../slices/mortgageTargetsSlice";
import { selectPreviewTargetChangesEnabled, setPreviewTargetChangesEnabled } from "../../../../slices/functionAvailabilitySlice";
import { ErrorWrapper, InputWrapper, StatusAndLabelWrapper, StyledInputElement, Button, ButtonWrapper, ModalContentContainer, ComponentHeader, FlexWrapper, Typography, ActionIconWrapper, Divider } from "../../../../StyledComponents";

const EditTargets = ({ leadData, handleCloseModal, userId, userFullName, mortgageData }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [previewChanges, setPreviewChanges] = useState(false)
  const [previewLoading, setPreviewLoading] = useState(false)

  const saveTargetRefinanceChangesPerm = useSelector(selectSaveTargetRefinanceChangesPerm)
  const previewTargetChangesEnabled = useSelector(selectPreviewTargetChangesEnabled)
  const editedTargetsPreview = useSelector(selectMortgageTargets)
  const [saveLeadTargetChanges] = useSaveLeadTargetChangesMutation()

  const [loanAmountPopulated, setLoanAmountPopulated] = useState(false)
  const [loanAmountRequired, setLoanAmountRequired] = useState(false)
  const [invalidInputs, setInvalidInputs] = useState(false)
  const [saveIsFetching, setSaveIsFetching] = useState(false)
  const [saveIsSuccess, setSaveIsSuccess] = useState(false)
  const [saveIsError, setSaveIsError] = useState(false)
  const [oldTargets, setOldTargets] = useState({})

  const [loanAmount, setLoanAmount] = useState(leadData.targetLoanAmount)
  const [loanAmountStr, setLoanAmountStr] = useState('')
  const [statusLoanAmount, setStatusLoanAmount] = useState(null)
  const targetLoanAmount = useRef()
  const [loanTerm, setLoanTerm] = useState(leadData.targetLoanTerm)
  const [statusLoanTerm, setStatusLoanTerm] = useState(null)
  const targetLoanTerm = useRef()
  const [interestRate, setInterestRate] = useState(leadData.targetInterestRate)
  const [statusInterestRate, setStatusInterestRate] = useState(null)
  const targetInterestRate = useRef()

  const validateLoanAmount = async () => {
    let validated = false
    if ((parseInt(targetLoanAmount.current.value.replace(/,/g, "")) < 100000000 || (isNaN(parseInt(targetLoanAmount.current.value.replace(/,/g, ""))))) && targetLoanAmount.current.value.length < 12) {
      if (!isNaN(parseInt(targetLoanAmount.current.value))) {
        setLoanAmountStr(parseInt(targetLoanAmount.current.value.replace(/,/g, "")).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0}))
      } else {
        setLoanAmountStr(targetLoanAmount.current.value)
      }
      setLoanAmount(parseInt(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')))
    }
    let negativeAmount = Math.sign(parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')))
    let zeroAmount = Math.sign(parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')))
    if (negativeAmount === -1 || zeroAmount === 0 || isNaN(parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, ''))) || parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) > 100000000 || parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) % 1 != 0 || !parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) > 0) {
      if (parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) % 1 != 0) {
        setStatusLoanAmount('decimal')
      }
      if (negativeAmount === -1) {
        setStatusLoanAmount('negative')
      }
      if (zeroAmount === 0) {
        setStatusLoanAmount('zero')
      }
      if (isNaN(parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')))) {
        setStatusLoanAmount('nonNumber')
      }
      if (parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) > 100000000) {
        setStatusLoanAmount('large')
      }
      dispatch( setPreviewTargetChangesEnabled(false) )
    } else {
      validated = true
      setLoanAmountRequired(false)
      setStatusLoanAmount(null)
      if (saveIsError) {
        setSaveIsError(false)
      }
      if (targetLoanAmount.current && parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) > 0) {
        setLoanAmountPopulated(true)
      } else {
        setLoanAmountPopulated(false)
      }
      if ((parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) === leadData.targetLoanAmount)
        && (parseInt(targetLoanTerm.current.value) === leadData.targetLoanTerm)
        && (parseFloat(targetInterestRate.current.value) === leadData.targetInterestRate)
      ) {
        dispatch( setPreviewTargetChangesEnabled(false) )
      } else {
        dispatch( setPreviewTargetChangesEnabled(true) )
      }
    }
    return validated
  }
  const validateLoanTerm = async () => {
    let validated = false
    setLoanTerm(targetLoanTerm.current.value)
    let negativeTerm = Math.sign(parseFloat(targetLoanTerm.current.value))
    let zeroTerm = Math.sign(parseFloat(targetLoanTerm.current.value))
    if (negativeTerm === -1 || zeroTerm === 0 || isNaN(parseFloat(targetLoanTerm.current.value)) || parseFloat(targetLoanTerm.current.value) > 99 || parseFloat(targetLoanTerm.current.value) % 1 !== 0 || !parseInt(targetLoanTerm.current.value) > 0) {
      if (parseFloat(targetLoanTerm.current.value) % 1 !== 0) {
        setStatusLoanTerm('decimal')
      }
      if (negativeTerm === -1) {
        setStatusLoanTerm('negative')
      }
      if (zeroTerm === 0) {
        setStatusLoanTerm('zero')
      }
      if (isNaN(parseFloat(targetLoanTerm.current.value))) {
        setStatusLoanTerm('nonNumber')
      }
      if (parseFloat(targetLoanTerm.current.value) > 99) {
        setStatusLoanTerm('large')
      }
      dispatch( setPreviewTargetChangesEnabled(false) )
    } else {
      validated = true
      setStatusLoanTerm(null)
      if (saveIsError) {
        setSaveIsError(false)
      }
      if ((parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) === leadData.targetLoanAmount)
        && (parseInt(targetLoanTerm.current.value) === leadData.targetLoanTerm)
        && (parseFloat(targetInterestRate.current.value) === leadData.targetInterestRate)
      ) {
        dispatch( setPreviewTargetChangesEnabled(false) )
      } else {
        dispatch( setPreviewTargetChangesEnabled(true) )
      }
    }
    return validated
  }
  const validateInterestRate = async () => {
    let validated = false
    setInterestRate(targetInterestRate.current.value)
    let negativeInterest = Math.sign(parseFloat(targetInterestRate.current.value))
    let zeroInterest = Math.sign(parseFloat(targetInterestRate.current.value))
    if (negativeInterest === -1 || zeroInterest === 0 || isNaN(parseFloat(targetInterestRate.current.value)) || parseFloat(targetInterestRate.current.value) > 99 || !parseFloat(targetInterestRate.current.value) > 0) {
      if (negativeInterest === -1) {
        setStatusInterestRate('negative')
      }
      if (zeroInterest === 0) {
        setStatusInterestRate('zero')
      }
      if (isNaN(parseFloat(targetInterestRate.current.value))) {
        setStatusInterestRate('nonNumber')
      }
      if (parseFloat(targetInterestRate.current.value) > 99) {
        setStatusInterestRate('large')
      }
      dispatch( setPreviewTargetChangesEnabled(false) )
    } else {
      validated = true
      setStatusInterestRate(null)
      if (saveIsError) {
        setSaveIsError(false)
      }
      if ((parseFloat(targetLoanAmount.current.value.replace(/[^0-9-.]/g, '')) === leadData.targetLoanAmount)
        && (parseInt(targetLoanTerm.current.value) === leadData.targetLoanTerm)
        && (parseFloat(targetInterestRate.current.value) === leadData.targetInterestRate)
      ) {
        dispatch( setPreviewTargetChangesEnabled(false) )
      } else {
        dispatch( setPreviewTargetChangesEnabled(true) )
      }
    }
    return validated
  }

  const handlePreviewChanges = async () => {
    setPreviewLoading(true)
    let validateTerm = await validateLoanTerm()
    let validateAmount = await validateLoanAmount()
    let validateInterest = await validateInterestRate()
    if (validateTerm && validateAmount && validateInterest) {
      let previewValues = await calculateTargetChanges({
        userId: userId,
        interestRate: interestRate,
        loanAmount: loanAmount,
        loanTerm: loanTerm,
        targetInterestDue: leadData.targetInterestDue,
        targetMonthlyPayments: leadData.targetMonthlyPayments,
        interestRemaining: mortgageData.interestRemaining,
        targetProfitNumber: leadData.targetProfitNumber,
        targetProfitPercent: leadData.targetProfitPercent,
      })
      dispatch( setMortgageTargets(previewValues) )
      setPreviewChanges(true)
    } else {
      setInvalidInputs(true)
      dispatch( setPreviewTargetChangesEnabled(false) )
    }
    setPreviewLoading(false)
  }

  const handleSaveChanges = async () => {
    setSaveIsFetching(true)
    let updatedFields = []
    if (loanAmount !== leadData.targetLoanAmount) {
      updatedFields.push({field: 'Loan Amount', old: leadData.targetLoanAmount, new: parseFloat(loanAmount)})
    }
    if (loanTerm !== leadData.targetLoanTerm) {
      updatedFields.push({field: 'Loan Term', old: leadData.targetLoanTerm, new: parseFloat(loanTerm)})
    }
    if (interestRate !== leadData.targetInterestRate) {
      updatedFields.push({field: 'Interest Rate', old: leadData.targetInterestRate, new: parseFloat(interestRate)})
    }
    let resp = await saveLeadTargetChanges({
      userId: userId,
      oldTargetProfitNumber: leadData.targetProfitNumber,
      targetProfitNumber: editedTargetsPreview.newProfitNumber,
      newProfitNumberDifference: editedTargetsPreview.newProfitNumberDifference,
      oldTargetProfitPercent: leadData.targetProfitPercent,
      targetProfitPercent: editedTargetsPreview.newProfitPercent,
      newProfitPercentDifference: editedTargetsPreview.newProfitPercentDifference,
      userFullName: userFullName,
      updatedFields: updatedFields,
      leadId: leadData._id,
      targetLoanAmount: loanAmount,
      targetLoanTerm: loanTerm,
      targetInterestRate: interestRate,
      targetInterestDue: editedTargetsPreview.newInterestDue,
      targetMonthlyPayments: editedTargetsPreview.newMonthlyPayments,
      newMonthlyPaymentsDifference: editedTargetsPreview.newMonthlyPaymentsDifference,
      oldMonthlyPayments: leadData.targetMonthlyPayments,
    });
    if (resp.error) {
      setSaveIsError(true)
    } else {
      setSaveIsSuccess(true)
    }
    setSaveIsFetching(false)
  }

  const handleReset = () => {
    setSaveIsSuccess(false)
    setPreviewChanges(false)
  }

  useEffect(() => {
    if (leadData.targetLoanAmount && leadData.targetLoanAmount > 0) {
      setLoanAmountStr(leadData.targetLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0}))
      setLoanAmountPopulated(true)
    } else {
      setLoanAmountRequired(true)
    }
    setOldTargets({
      oldTargetProfitPercent: leadData.targetProfitPercent,
      oldTargetProfitNumber: leadData.targetProfitNumber,
      oldTargetMonthlyPayments: leadData.targetMonthlyPayments,
      oldTargetInterestDue: leadData.targetInterestDue,
    })
    dispatch( setPreviewTargetChangesEnabled(false) )
  }, [leadData])

   return (
      <>
        {saveIsSuccess ?
          <ComponentHeader className="modal green">
            <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
              <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
            </FlexWrapper>
            <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
              Success
            </span>
          </ComponentHeader>
        : saveIsError ?
          <ComponentHeader className="modal red">
            <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
              <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
            </FlexWrapper>
            <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
              Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
            </span>
          </ComponentHeader>
        :
          <ComponentHeader className="modal">Refinance Targets</ComponentHeader>
        }

        <ModalContentContainer className="topColumn leadActions" style={{padding: '0 0 1rem 0', height: 'none',}}>
          <FlexWrapper className="justifyBetween autoMarginHorizontal fitContentHeight">
            <FlexWrapper className="column alignCenter fitContentHeight" style={{paddingLeft: '6rem'}}>
              <div style={{minHeight: '1.0rem'}} />
              <Typography className="subHeader autoMarginHorizontal">Finances</Typography>
              <FlexWrapper className="column alignCenter">
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Original Principal:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.originalLoanAmount > 0 ?
                      <Typography className="bold medium">${(mortgageData.originalLoanAmount).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Term:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.mortgageTerm > 0 ?
                      <Typography className="bold medium">{mortgageData.mortgageTerm} years</Typography>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Interest Rate:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.originalInterestRate > 0 ?
                      <Typography className="bold medium">{mortgageData.originalInterestRate}%</Typography>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <Divider style={{width: '80%', margin: '0.3rem 0'}} />
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Original Interest:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.originalTotalDue > 0 ?
                      <Typography className="bold medium">${(mortgageData.originalTotalDue-mortgageData.originalLoanAmount).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Total Owed at Origination:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.originalTotalDue > 0 ?
                      <Typography className="bold medium">${(mortgageData.originalTotalDue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Months Remaining:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.monthsRemaining > 0 ?
                      <>
                        <Typography className="medium bold">
                          {mortgageData.monthsRemaining}
                        </Typography>
                        <FlexWrapper className="alignCenter">
                          {mortgageData.mortgageTerm > 0 &&
                            <Typography className="small" style={{marginLeft: '0.5rem'}}>
                              ({((mortgageData.monthsRemaining/(mortgageData.mortgageTerm*12))*100).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% of total)
                            </Typography>
                          }
                        </FlexWrapper>
                      </>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Monthly Payments:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.monthlyPayments > 0 ?
                      <Typography className="bold medium">${mortgageData.monthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Typography>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Principal Paid:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                  {mortgageData.principalPaid > 0 ?
                    <>
                      <Typography className="medium bold">
                        ${mortgageData.principalPaid.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                      </Typography>
                      {mortgageData.originalLoanAmount > 0 &&
                        <Typography className="small" style={{marginLeft: '0.5rem'}}>
                          ({((mortgageData.principalPaid/mortgageData.originalLoanAmount)*100).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% of total)
                        </Typography>
                      }
                    </>
                  :
                    <Typography className="small accent thin">-N/A-</Typography>
                  }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Principal Remaining:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.principalRemaining > 0 ?
                      <>
                      <Typography className="medium bold">
                        ${mortgageData.principalRemaining.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                      </Typography>
                      {mortgageData.originalLoanAmount > 0 &&
                        <Typography className="small" style={{marginLeft: '0.5rem'}}>
                          ({((mortgageData.principalRemaining/mortgageData.originalLoanAmount)*100).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% of total)
                        </Typography>
                      }
                    </>
                  :
                    <Typography className="small accent thin">-N/A-</Typography>
                  }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Interest Paid:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.interestPaid > 0 ?
                      <>
                        <Typography className="medium bold">
                          ${mortgageData.interestPaid.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        </Typography>
                        {mortgageData.originalInterestDue > 0 &&
                          <Typography className="small" style={{marginLeft: '0.5rem'}}>
                            ({((mortgageData.interestPaid/mortgageData.originalInterestDue)*100).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% of total)
                          </Typography>
                        }
                      </>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper className='fitContentHeight justifyCenter'>
                  <Typography className="fitContentHeight justifyEnd medium" style={{width: '18rem', marginRight: '0.5rem'}}>
                    Interest Remaining:
                  </Typography>
                  <FlexWrapper className="alignCenter" style={{width: '18rem'}}>
                    {mortgageData.interestRemaining > 0 ?
                      <>
                        <Typography className="bold medium">
                          ${mortgageData.interestRemaining.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        </Typography>
                        {mortgageData.originalInterestDue > 0 &&
                          <Typography className="small" style={{marginLeft: '0.5rem'}}>
                            ({((mortgageData.interestRemaining/mortgageData.originalInterestDue)*100).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% of total)
                          </Typography>
                        }
                      </>
                    :
                      <Typography className="small accent thin">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
              {!mortgageData.financialsPresent &&
                <ActionIconWrapper style={{marginTop: '1rem'}} className="primary wrapped success hover" onClick={() => dispatch( setLeadActionNavMenuSelection('provideFinancialsModal'))}>
                  <AddCircleOutlineIcon style={{marginRight: '0.5rem'}} />
                  <Typography className="success">Provide Original Terms</Typography>
                </ActionIconWrapper>
              }
            </FlexWrapper>

            <FlexWrapper className="column alignStart" style={{width: '30rem', minWidth: '30rem'}}>
              <FlexWrapper className="column alignStart fitContentHeight">
                {invalidInputs ?
                  <ErrorWrapper className="caution">
                    <WarningAmberIcon />
                    Invalid. Try again
                  </ErrorWrapper>
                :
                  <div style={{minHeight: '2.0rem'}} />
                }
                <Typography className="subHeader" style={{paddingLeft: '2rem'}}>Targets</Typography>
              </FlexWrapper>
              <InputWrapper style={{width: '100%', margin: '0', paddingLeft: '2rem'}}>
                <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                  <FlexWrapper className="fitContentWidth">
                    <Typography htmlFor="loanAmount">Loan Amount ($)</Typography>
                  </FlexWrapper>
                </StatusAndLabelWrapper>
                <FlexWrapper className="alignCenter fitContentHeight">
                  {loanAmountRequired ?
                    <Tooltip
                      componentsProps={{tooltip: {sx: {
                        color: `${theme.tooltip.warning.color}`,
                        backgroundColor: `${theme.tooltip.warning.background}`,
                        border: `0.3rem solid ${theme.tooltip.warning.border}`,
                        borderBottom: `0.2rem solid ${theme.tooltip.warning.border}`,
                        opacity: '0',
                        width: '35rem',
                        minWidth: '35rem',
                        padding: '3rem 5rem',
                      }}}}
                      PopperProps={{modifiers: [{
                        name: "offset",
                        options: {
                          offset: [0, 0],
                        },
                      }]}}
                      title={
                        <FlexWrapper className="column">
                          <Typography className="bold alignTextCenter">
                            The "Target Loan Amount" would be the principal currently remaining on the mortgage.
                          </Typography>
                          <Typography className="bold alignTextCenter" style={{marginTop: '1rem'}}>
                            Provide this mortgage's original terms so that the current financial standing can be determined.
                          </Typography>
                        </FlexWrapper>} 
                      placement="top"
                      arrow
                    >
                      <StyledInputElement style={{width: '10rem', marginRight: '0.5rem'}}
                        value={loanAmountStr}
                        disabled={previewChanges || !mortgageData.financialsPresent}
                        type="text"
                        name="loanAmount"
                        id="loanAmount"
                        ref={targetLoanAmount}
                        className={loanAmountRequired ? 'required disabled error' : (previewChanges || !mortgageData.financialsPresent) ? 'disabled' : statusLoanAmount ? 'error' : loanAmountPopulated ? 'populated' : ''}
                        onChange={() => validateLoanAmount()}
                      />
                      <ErrorWrapper style={{width: 'fit-content', marginTop: '0.5rem', padding: '0 0.8rem'}}>
                        Required - Please provide original terms
                      </ErrorWrapper>
                    </Tooltip>
                  :
                    <StyledInputElement style={{width: '10rem', marginRight: '0.5rem'}}
                      value={loanAmountStr}
                      disabled={previewChanges || !mortgageData.financialsPresent}
                      type="text"
                      name="loanAmount"
                      id="loanAmount"
                      ref={targetLoanAmount}
                      className={loanAmountRequired ? 'required disabled error' : (previewChanges || !mortgageData.financialsPresent) ? 'disabled' : statusLoanAmount ? 'error' : loanAmountPopulated ? 'populated' : ''}
                      onChange={() => validateLoanAmount()}
                    />
                  }
                  {statusLoanAmount === "nonNumber" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Must be a number
                    </ErrorWrapper>
                  )}
                  {statusLoanAmount === "negative" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be negative
                    </ErrorWrapper>
                  )}
                  {statusLoanAmount === "zero" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be zero
                    </ErrorWrapper>
                  )}
                  {statusLoanAmount === "large" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      {`Must be < 100m`}
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>
              <InputWrapper style={{width: '100%', margin: '0', paddingLeft: '2rem'}}>
                <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                  <FlexWrapper className="fitContentWidth">
                    <Typography htmlFor="loanTerm">Term (years)</Typography>
                  </FlexWrapper>
                </StatusAndLabelWrapper>
                <FlexWrapper className="alignCenter fitContentHeight">
                  <StyledInputElement
                    style={{width: '10rem'}}
                    value={loanTerm}
                    disabled={previewChanges || !mortgageData.financialsPresent}
                    type="text"
                    name="loanTerm"
                    id="loanTerm"
                    ref={targetLoanTerm}
                    className={(previewChanges || !mortgageData.financialsPresent) ? 'disabled' : statusLoanTerm ? 'error' : (targetLoanTerm.current && parseInt(targetLoanTerm.current.value) > 0) ? 'populated' : ''}
                    onChange={() => validateLoanTerm()}
                  />
                  {statusLoanTerm === "nonNumber" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Must be a number
                    </ErrorWrapper>
                  )}
                  {statusLoanTerm === "decimal" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Must be a whole number
                    </ErrorWrapper>
                  )}
                  {statusLoanTerm === "negative" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be negative
                    </ErrorWrapper>
                  )}
                  {statusLoanTerm === "zero" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be zero
                    </ErrorWrapper>
                  )}
                  {statusLoanTerm === "large" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      {`Must be < 99`}
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>
              <InputWrapper style={{width: '100%', margin: '0', paddingLeft: '2rem'}}>
                <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                  <FlexWrapper className="fitContentWidth">
                    <Typography htmlFor="interestRate">Interest Rate (%)</Typography>
                  </FlexWrapper>
                </StatusAndLabelWrapper>
                <FlexWrapper className="alignCenter fitContentHeight">
                  <StyledInputElement style={{width: '10rem'}}
                    value={interestRate}
                    disabled={previewChanges || !mortgageData.financialsPresent}
                    type="text"
                    name="interestRate"
                    id="interestRate"
                    ref={targetInterestRate}
                    className={(previewChanges || !mortgageData.financialsPresent) ? 'disabled' : statusInterestRate ? 'error' : (targetInterestRate.current && parseFloat(targetInterestRate.current.value) > 0) ? 'populated' : ''}
                    onChange={() => validateInterestRate()}
                  />
                  {statusInterestRate === "nonNumber" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Must be a number
                    </ErrorWrapper>
                  )}
                  {statusInterestRate === "negative" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be negative
                    </ErrorWrapper>
                  )}
                  {statusInterestRate === "zero" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      Can't be zero
                    </ErrorWrapper>
                  )}
                  {statusInterestRate === "large" && (
                    <ErrorWrapper>
                      <ErrorIcon />
                      {`Must be < 99`}
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>
            </FlexWrapper>
          </FlexWrapper>

          <Divider style={{margin: '1rem 0', width: '90%', height: '0.4rem'}} />

          <FlexWrapper className="justifyCenter autoMarginVertical fitContentHeight">
            {previewChanges ?
              <FlexWrapper className="justifyCenter" style={{marginLeft: '12rem'}}>
                <FlexWrapper
                  className="column justifyBetween alignEnd fitContentHeight"
                  style={{width: saveIsSuccess ? "fit-content" : "20rem", minWidth: saveIsSuccess ? 'fit-content' : "20rem"}}
                >
                  <Typography className="alignCenter bold" style={{height: '5rem', minHeight: '5rem'}}>
                    {saveIsSuccess ?
                      `New Target Interest Due:`
                    :
                      `Proposed Target Interest Due:`
                    }
                  </Typography>
                  <Typography className="alignCenter bold" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, height: '5rem', minHeight: '5rem'}}>
                    {saveIsSuccess ?
                      `New Target Monthly Payments:`
                    :
                      `Proposed Target Monthly Payments:`
                    }
                  </Typography>
                  <Typography className="alignCenter bold" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, height: '5rem', minHeight: '5rem'}}>
                    {saveIsSuccess ?
                      `New Change in Interest Owed:`
                    :
                      `Resulting Change in Interest Owed:`
                    }
                  </Typography>
                  <Typography className="alignCenter bold" style={{borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`, height: '5rem', minHeight: '5rem'}}>
                    {saveIsSuccess ?
                      `New Change in Revenue:`
                    :
                      `Resulting Change in Revenue:`
                    }
                  </Typography>
                </FlexWrapper>
                <FlexWrapper 
                  className="column justifyBetween fitContentHeight"
                  style={{width: saveIsSuccess ? "fit-content" : "30rem"}}
                >
                  <FlexWrapper className="alignCenter fitContentHeight">
                    <Typography className="xLarge bold alignCenter" style={{height: '5rem', padding: '0 1.5rem 0 1rem'}}>
                      ${editedTargetsPreview.newInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                    {!saveIsSuccess &&
                      <>
                        <FlexWrapper className="column fitContentWidth" style={{height: '5rem'}}>
                          <FlexWrapper>
                            <FlexWrapper className="column fitContentWidth alignEnd">
                              <Typography className="largeHeight">before: </Typography>
                              <Typography className="largeHeight">difference: </Typography>
                            </FlexWrapper>
                            <FlexWrapper className="column" style={{paddingLeft: '0.5rem', width: '8rem'}}>
                              <Typography className="largeHeight bold">
                                ${oldTargets.oldTargetInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                              </Typography>
                              {!editedTargetsPreview.newInterestDueDifference.negative && editedTargetsPreview.newInterestDueDifference.value !== undefined ?
                                <Typography className="largeHeight bold success">
                                  ${editedTargetsPreview.newInterestDueDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                </Typography>
                              : editedTargetsPreview.newInterestDueDifference.value !== undefined ?
                                <Typography className="largeHeight bold error">
                                  -${editedTargetsPreview.newInterestDueDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                </Typography>
                              :
                                <Typography className="largeHeight accent thin">-N/A-</Typography>
                              }
                            </FlexWrapper>
                          </FlexWrapper>
                        </FlexWrapper>
                        {!editedTargetsPreview.newInterestDueDifference.negative ?
                          <Typography className="wrapped">
                            {editedTargetsPreview.newInterestDuePercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% increase
                          </Typography> 
                        :
                          <Typography className="wrappedRed">
                            {editedTargetsPreview.newInterestDuePercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% decrease
                          </Typography>     
                        }
                      </>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="alignCenter fitContentHeight">
                    <Typography className="xLarge bold alignCenter" style={{height: '5rem', padding: '0 1.5rem 0 1rem', borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`}}>
                      ${editedTargetsPreview.newMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                    </Typography>
                    {!saveIsSuccess &&
                      <>
                        <FlexWrapper className="column fitContentWidth fitContentHeight" style={{height: '5rem', borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`}}>
                          <FlexWrapper>
                            <FlexWrapper className="column fitContentWidth alignEnd">
                              <Typography className="largeHeight">before: </Typography>
                              <Typography className="largeHeight">difference: </Typography>
                            </FlexWrapper>
                            <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem', width: '8rem'}}>
                              <Typography className="largeHeight bold">
                                ${oldTargets.oldTargetMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                              </Typography>
                              {!editedTargetsPreview.newMonthlyPaymentsDifference.negative && editedTargetsPreview.newMonthlyPaymentsDifference.value !== undefined ?
                                <Typography className="largeHeight bold success">
                                  ${editedTargetsPreview.newMonthlyPaymentsDifference.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                                </Typography>
                              : editedTargetsPreview.newMonthlyPaymentsDifference.value !== undefined  ?
                                <Typography className="largeHeight bold error">
                                  -${editedTargetsPreview.newMonthlyPaymentsDifference.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                                </Typography>
                              :
                                <Typography className="largeHeight accent thin">-N/A-</Typography>
                              }
                            </FlexWrapper>
                          </FlexWrapper>
                        </FlexWrapper>
                        {!editedTargetsPreview.newMonthlyPaymentsDifference.negative ?
                          <Typography className="wrapped">
                            {editedTargetsPreview.newMonthlyPaymentsPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% increase
                          </Typography> 
                        :
                          <Typography className="wrappedRed">
                            {editedTargetsPreview.newMonthlyPaymentsPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% decrease
                          </Typography>     
                        }
                      </>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="alignCenter fitContentHeight">
                    {parseInt(editedTargetsPreview.newProfitNumber) >= 0 ?
                      <Typography className="xLarge bold alignCenter success" style={{height: '5rem', padding: '0 1.5rem 0 1rem', borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`}}>
                        ${editedTargetsPreview.newProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                      </Typography>
                    :
                      <Typography className="xLarge bold alignCenter error" style={{height: '5rem', padding: '0 1.5rem 0 1rem', borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`}}>
                        -${(editedTargetsPreview.newProfitNumber*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                      </Typography>
                    }
                    {!saveIsSuccess &&
                      <>
                        <FlexWrapper className="column fitContentWidth fitContentHeight" style={{height: '5rem', borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`}}>
                          <FlexWrapper>
                            <FlexWrapper className="column fitContentWidth alignEnd">
                              <Typography className="largeHeight">before: </Typography>
                              <Typography className="largeHeight">difference: </Typography>
                            </FlexWrapper>
                            <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem', width: '8rem'}}>
                              <Typography className="largeHeight bold">
                                ${oldTargets.oldTargetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                              </Typography>
                              {!editedTargetsPreview.newProfitNumberDifference.negative && editedTargetsPreview.newProfitNumberDifference.value !== undefined ?
                                <Typography className="largeHeight bold success">
                                  ${editedTargetsPreview.newProfitNumberDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                </Typography>
                              : editedTargetsPreview.newProfitNumberDifference.value !== undefined ?
                                <Typography className="largeHeight bold error">
                                  -${editedTargetsPreview.newProfitNumberDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                                </Typography>
                              :
                                <Typography className="largeHeight accent thin">-N/A-</Typography>
                              }
                            </FlexWrapper>
                          </FlexWrapper>
                        </FlexWrapper>
                        {!editedTargetsPreview.newProfitNumberDifference.negative ?
                          <Typography className="wrapped">
                            {editedTargetsPreview.newProfitNumberPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% increase
                          </Typography> 
                        :
                          <Typography className="wrappedRed">
                            {editedTargetsPreview.newProfitNumberPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% decrease
                          </Typography>     
                        }
                      </>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="alignCenter fitContentHeight">
                    {parseInt(editedTargetsPreview.newProfitPercent) >= 0 ?
                      <Typography className="xLarge bold alignCenter success" style={{height: '5rem', padding: '0 1.5rem 0 1rem', borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`}}>
                        {editedTargetsPreview.newProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%
                      </Typography>
                    :
                      <Typography className="xLarge bold error" style={{height: '5rem', padding: '0 1.5rem 0 1rem', borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`}}>
                        -{(editedTargetsPreview.newProfitPercent*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%
                      </Typography>
                    }
                    {!saveIsSuccess &&
                      <>
                        <FlexWrapper className="column fitContentWidth fitContentHeight">
                          <FlexWrapper style={{height: '5rem', borderTop: `solid 0.1rem ${theme.palette.common.grayBorder}`}}>
                            <FlexWrapper className="column fitContentWidth alignEnd">
                              <Typography className="largeHeight">before: </Typography>
                              <Typography className="largeHeight">difference: </Typography>
                            </FlexWrapper>
                            <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem', width: '8rem'}}>
                              <Typography className="largeHeight bold">
                                {oldTargets.oldTargetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%
                              </Typography>
                              {!editedTargetsPreview.newProfitPercentDifference.negative && editedTargetsPreview.newProfitPercentDifference.value !== undefined ?
                                <Typography className="largeHeight bold success">
                                  {editedTargetsPreview.newProfitPercentDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%
                                </Typography>
                              : editedTargetsPreview.newProfitPercentDifference.value !== undefined ?
                                <Typography className="largeHeight bold error">
                                  -{editedTargetsPreview.newProfitPercentDifference.value.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%
                                </Typography>
                              :
                                <Typography className="largeHeight accent thin">-N/A-</Typography>
                              }
                            </FlexWrapper>
                          </FlexWrapper>
                        </FlexWrapper>
                        {!editedTargetsPreview.newProfitPercentDifference.negative && editedTargetsPreview !== null ?
                          <Typography className="wrapped">
                            {editedTargetsPreview.newProfitPercentPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% increase
                          </Typography> 
                        : editedTargetsPreview !== null ?
                          <Typography className="wrappedRed">
                            {editedTargetsPreview.newProfitPercentPercent.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}% decrease
                          </Typography>  
                        :
                          <Typography className="xLarge accent thin">-N/A-</Typography>
                        }
                      </>
                    }
                  </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper style={{borderRight: '0.1rem solid black', height: '15.6rem', margin: 'auto 0', width: '0.5rem'}}>
                  <FlexWrapper className="column justifyBetween">
                    <Divider style={{width: '100%', background: 'black'}} />
                    <Divider style={{width: '100%', background: 'black'}} />
                    <Divider style={{width: '100%', background: 'black'}} />
                    <Divider style={{width: '100%', background: 'black'}} />
                  </FlexWrapper>
                </FlexWrapper>
                <Typography className="tiny fitContentHeight autoMarginVertical" style={{marginLeft: '0.2rem'}}>
                  Changes from<br/>previous targets
                </Typography>
              </FlexWrapper>
            :
              <>
                <FlexWrapper className="column justifyBetween alignEnd fitContentHeight">
                  <Typography className="xLargeHeight">Target Interest Due:</Typography>
                  <Typography className="xLargeHeight">Target Monthly Payments:</Typography>
                  <Typography className="xLargeHeight">Change in Interest Owed:</Typography>
                  <Typography className="xLargeHeight">Change in Revenue:</Typography>
                </FlexWrapper>
                <FlexWrapper className="column justifyBetween fitContentHeight" style={{width: '37rem', minWidth: '37rem', paddingLeft: '0.5rem'}}>
                  {leadData.targetInterestDue > 0 ?
                    <Typography className="bold xLarge">${leadData.targetInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                  :
                    <Typography className="xLarge accent thin">-N/A-</Typography>
                  }
                  {leadData.targetMonthlyPayments > 0 ?
                    <Typography className="bold xLarge">${leadData.targetMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                  :
                    <Typography className="xLarge accent thin">-N/A-</Typography>
                  }
                  {leadData.targetProfitNumber < 0 ?
                    <Typography className="bold xLarge error">
                      -${(leadData.targetProfitNumber * -1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  : leadData.targetProfitNumber >= 0 ?
                    <Typography className="bold xLarge success">${leadData.targetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                  :
                    <Typography className="xLarge accent thin">-N/A-</Typography>
                  }
                  {leadData.targetProfitPercent < 0 ?
                    <Typography className="bold xLarge error">{leadData.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Typography>
                  : leadData.targetProfitPercent >= 0 ?
                    <Typography className="bold xLarge success">{leadData.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Typography>
                  :
                    <Typography className="xLarge accent thin">-N/A-</Typography>
                  }
                </FlexWrapper>
              </>
            }
          </FlexWrapper>

          <ButtonWrapper className="marginAuto">
            {saveIsSuccess ?
              <>
                <Button className="neutral" onClick={() => handleCloseModal()}>Close</Button>
                <Button className="primary" onClick={() => handleReset()} style={{width: '14rem'}}>Calculate Again</Button>
              </>
            : previewChanges || saveIsError ?
              <>
                <Button 
                  className={saveIsFetching ? 'neutralLoading' : 'neutral'}
                  disabled={saveIsFetching}
                  onClick={saveIsFetching ? null : () => setPreviewChanges(false)}
                >
                  Go Back
                </Button>
                <Button 
                  className={saveIsFetching ? 'primaryLoading' : (saveTargetRefinanceChangesPerm && !saveIsError) ? 'primary' : 'primaryDisabled'}
                  disabled={!saveTargetRefinanceChangesPerm || saveIsError || saveIsFetching}
                  onClick={(saveTargetRefinanceChangesPerm && !saveIsError && !saveIsFetching) ? () => handleSaveChanges() : null}
                >
                  {saveIsFetching ?
                    <CircularProgress
                      sx={{ color: `${theme.palette.common.white}` }}
                      size={18}
                    />
                  :
                    `Save`
                  }
                </Button>
              </>
            :
              <>
                <Button 
                  className={previewLoading ? 'neutralLoading' : 'neutral'}
                  disabled={previewLoading}
                  onClick={previewLoading ? null : () => handleCloseModal()}
                >
                  Close
                </Button>
                <Tooltip
                  componentsProps={{tooltip: {sx: {
                    color: `${theme.tooltip.caution.color}`,
                    backgroundColor: `${theme.tooltip.caution.background}`,
                    border: `0.2rem solid ${theme.tooltip.caution.border}`,
                    borderBottom: `0.2rem solid ${theme.tooltip.caution.border}`,
                    opacity: '0',
                    width: '15rem',
                    minWidth: '15rem',
                    padding: '1rem 1rem',
                  }}}}
                  PopperProps={{modifiers: [{
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  }]}}
                  title={!previewTargetChangesEnabled ?
                    <FlexWrapper className="alignCenter justifyCenter">
                      <WarningAmberIcon />
                      <Typography className="bold alignTextCenter justifyCenter" style={{margin: '0.1rem 0 0 0.3rem'}}>
                        No changes made
                      </Typography>
                    </FlexWrapper>
                  :
                    null
                  } 
                  placement="top"
                  arrow
                >
                  <Button 
                    style={{width: '16rem'}} 
                    className={previewLoading ? 'primaryLoading' : (previewTargetChangesEnabled && mortgageData.financialsPresent) ? 'primary' : 'primaryDisabled'}
                    disabled={!previewTargetChangesEnabled || previewLoading || !mortgageData.financialsPresent}
                    onClick={(previewTargetChangesEnabled && !previewLoading && mortgageData.financialsPresent) ? () => handlePreviewChanges() : null}
                  >
                    {previewLoading ?
                      <CircularProgress
                        sx={{ color: `${theme.palette.common.white}` }}
                        size={18}
                      />
                    :
                      `Preview Changes`
                    }
                  </Button>
                </Tooltip>
              </>
            }
          </ButtonWrapper>
        </ModalContentContainer>
      </>
   )
}

export default EditTargets;