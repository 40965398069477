import React from "react";
import Timeline from "../../../../../components/Timeline/Timeline";
import CompanyLogo from "../../../../../Full.logo.white.png";
import { AccentInfo, CardContainer, ScrollWindow } from "../../../../../StyledComponents";

export const s = {
  main: {
    padding: '0.5rem',
  },
  reportHead: {
    backgroundColor: "#F4F4F4",
    padding: '5px 15px',
  },

  headerContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    position: 'relative',
  },

  companyLogo: {
    height: 100,
    top: 20,
    position: 'absolute',
    zIndex: 1,
    // left: '-15rem',
    opacity: '0.6',
  },

  reportId: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Courier",
    marginLeft: "auto",
  },

  closeDate: {
    textAlign: "right",
    fontSize: 12,
    fontFamily: "Courier",
    marginTop: 2,
  },

  reportTypeContainer: {
    margin: 0,
    zIndex: 10,
  },

  reportType: {
    fontFamily: "Courier",
    fontWeight: 700,
    fontSize: 18,
    color: "#8eaeb3",
    textTransform: "uppercase",
    margin: 0,
  },

  addressContainer: {
    marginTop: 20,
    maxWidth: 200,
    zIndex: 10,
  },

  addressContent: {
    fontSize: 12,
    fontFamily: "Courier",
    margin: 0,
    position: 'relative',
    zIndex: 10,
  },

  borrowerContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    fontFamily: "Courier",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  borrowerCardCaption: {
    fontSize: 14,
    fontFamily: "Courier",
    marginBottom: 5,
    zIndex: 10,
    position: 'relative',
  },

  borrowerName: {
    fontWeight: 700,
    fontFamily: "Courier",
    fontSize: 20,
    color: "#637984",
    margin: 0,
    zIndex: 10,
    position: 'relative',
  },

  reportBody: {
    backgroundColor: "#FFFFFF",
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    fontFamily: "Courier",
  },

  reportSection: {
    borderBottom: "1px solid #F4F4F4",
    paddingTop: 20,
    paddingBottom: 20,
  },

  reportSectionUpdates: {
    borderBottom: "1px solid #F4F4F4",
    paddingTop: 0,
    paddingBottom: 20,
  },

  reportSectionTitle: {
    fontSize: 16,
    fontFamily: "Courier",
    fontWeight: 700,
    color: "#14424C",
    marginBottom: 10,
  },

  reportSectionContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 5,
    gap: 25,
    width: 'fit-content',
  },
  reportSectionContentContainerClosing: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 5,
    gap: 25,
  },

  reportSectionColumn: {
    flexGrow: 1,
  },

  reportRowTitle: {
    fontSize: 12,
    fontFamily: "Courier",
    fontStyle: "oblique",
    margin: '0',
    width: '8rem',
  },
  reportRowTitleLarge: {
    fontSize: 12,
    fontFamily: "Courier",
    fontStyle: "oblique",
    margin: '0',
    width: '14rem',
  },

  reportRowValue: {
    fontSize: 12,
    fontFamily: "Courier",
    fontWeight: 700,
    margin: '0',
  },

  timeLineContainer: {
    borderLeft: "1px solid #e4e4e4",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 20,
    paddingBottom: 25,
    fontFamily: "Courier",
    position: "relative",
  },

  timeLineTitle: {
    fontSize: 14,
    color: "#3A724F",
    fontFamily: "Courier",
    fontWeight: 700,
    marginBottom: 5,
  },

  timeLineDetail: {
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },

  timeLineDetailValue: {
    fontFamily: "Courier",
    fontWeight: 700,
  },
}

export default function ReportView({ report, origin }) {
  return (
    <CardContainer className={origin === 'modal' ? "report modal" : "report"}>
      <ScrollWindow className="verticalOnly">
      <div style={s.main}>
        <div style={s.reportHead}>
          <div style={s.headerContainer}>
            <div style={s.reportTypeContainer}>
              <p style={s.reportType}>{report.outcome}</p>
            </div>
            
            <img
              alt="lancaster report"
              src={CompanyLogo}
              style={s.companyLogo}
            />

            <div>
              <p style={s.closeDate}>
                Closed: {report.dateGenerated}
              </p>
            </div>
          </div>


          <div style={s.addressContainer}>
            <p style={s.addressContent}>
              Address: {report.streetAddress}
            </p>
            <p style={s.addressContent}>{report.City}, {report.StateOrProvince} {report.PostalCode}</p>
          </div>

          <div style={s.borrowerContainer}>
            <div>
              <p style={s.borrowerCardCaption}>Primary Owner</p>
              <p style={s.borrowerName}>{report.Owner1FullName}</p>
            </div>
            {report.Owner2FullName ?
              <div>
                <p style={s.borrowerCardCaption}>Co-Owner</p>
                <p style={s.borrowerName}>{report.Owner2FullName}</p>
              </div>
            :
              <></>
            }
          </div>
        </div>

        <div style={s.reportBody}>
          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>ORIGINAL MORTGAGE</p>
            <div style={s.reportSectionContentContainer}>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Start Date</p>
                  <p style={s.reportRowValue}>{report.originalOriginationDate}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>End Date</p>
                  <p style={s.reportRowValue}>{report.originalEndDate}</p>
                </div>
              </div>

              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Loan Amount</p>
                  <p style={s.reportRowValue}>${report.originalLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Interest Rate</p>
                  <p style={s.reportRowValue}>{report.originalInterestRate}%</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Monthly Payments</p>
                  <p style={s.reportRowValue}>${report.originalMonthlyPayments.toFixed(2)}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Interest Owed</p>
                  <p style={s.reportRowValue}>${report.originalInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                </div>
              </div>
            </div>
          </div>

          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>DUE AT CLOSING</p>
            <div style={s.reportSectionContentContainerClosing}>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Term Remaining</p>
                  <p style={s.reportRowValue}>{report.remainingTermAtClosing}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Principal Paid</p>
                  <p style={s.reportRowValue}>${report.principalPaidAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Principal Remaining</p>
                  <p style={s.reportRowValue}>${report.principalRemainingAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                </div>
              </div>

              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Interest Paid</p>
                  <p style={s.reportRowValue}>${report.interestPaidAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Interest Remaining</p>
                  <p style={s.reportRowValue}>${report.interestRemainingAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                </div>
              </div>
            </div>
          </div>

          {report.outcome === 'refinance' &&
            <>
              <div style={s.reportSection}>
                <p style={s.reportSectionTitle}>NEW MORTGAGE</p>
                <div style={s.reportSectionContentContainer}>
                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Start Date</p>
                      <p style={s.reportRowValue}>{report.newStartDate}</p>
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Loan Amount</p>
                      <p style={s.reportRowValue}>${report.newLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Interest Rate</p>
                      <p style={s.reportRowValue}>${report.newInterestRate}</p>
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Monthly Payments</p>
                      <p style={s.reportRowValue}>${report.newMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                    </div>
                  </div>

                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>End Date</p>
                      <p style={s.reportRowValue}>{report.newEndDate}</p>
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Interest Due</p>
                      <p style={s.reportRowValue}>${report.newInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...s.reportSection,
                }}
              >
                <p style={s.reportSectionTitle}>FINANCIALS</p>
                <div style={s.reportSectionContentContainer}>
                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Profit:</p>
                      <p style={s.reportRowValue}>${report.profitAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Team Total Profit:</p>
                      <p style={s.reportRowValue}>
                        {report.teamTotalProfit ? `$${report.teamTotalProfit.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}` : <></>}
                      </p>
                    </div>
                  </div>

                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Difference</p>
                      <p style={s.reportRowValue}>
                        {report.profitPercent ? `${report.profitPercent.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%` : <></>}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>

      <div size={"A4"} style={{ ...s.main, padding: '0 3.5rem 3.5rem 3.5rem', paddingTop: 0 }}>
        {/* DISCREPANCIES START */}
          <div style={{ marginBottom: 10 }}>
            <div style={s.reportSectionUpdates}>
              <p style={s.reportSectionTitle}>RECORDS UPDATED</p>
              {report.updates.map((update) => (
                <div style={{ marginTop: 5 }}>
                  <p
                    style={{
                      ...s.reportSectionTitle,
                      fontSize: 12,
                      marginBottom: 5,
                      textDecoration: "underline",
                    }}
                  >
                    {update.label}
                  </p>
                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitle}>Original</p>
                      <p style={s.reportRowValue}>
                        {!update.old ?
                          <AccentInfo>-empty-</AccentInfo>
                        : update.old === 'Y' ?
                          'YES'
                        : update.old === 'N' ?
                          'NO'
                        : update.field.includes('Rate') ?
                          `${update.old}%`
                        : update.field.includes('Price')
                        || update.field.includes('Amount')
                        || update.field.includes('Value') ?
                          `$${update.old}`
                        :
                          update.old
                        }
                      </p>
                    </div>
                  </div>
                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitle}>New Record</p>
                      <p style={s.reportRowValue}>
                        {!update.new ?
                          <AccentInfo>-empty-</AccentInfo>
                        : update.new === 'Y' ?
                          'YES'
                        : update.new === 'N' ?
                          'NO'
                        : update.field.includes('Rate') ?
                          `${update.new}%`
                        : update.field.includes('Price')
                        || update.field.includes('Amount')
                        || update.field.includes('Value') ?
                          `$${update.new}`
                        :
                          update.new
                        }
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        {/* DISCREPANCIES END */}

        {/* TIMELINE START */}
        <div>
          <p style={{ ...s.reportSectionTitle, marginBottom: 20 }}>TIMELINE</p>
          <Timeline timeline={report.timeline} origin={'report'} />
        </div>
        {/* TIMELINE END */}
      </div>
      </ScrollWindow>
    </CardContainer>
  )
}
