import React, { PureComponent } from 'react';
import { useTheme } from "@mui/styles";
import { Bar, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart} from 'recharts';
import { FlexWrapper } from '../../../../../../../StyledComponents';

const CustomTooltip = ({ active, payload }) => {
  const theme = useTheme()

  if (active && payload && payload.length) {
    return (
      <div style={{backgroundColor: `${theme.palette.common.cardBackground}`, padding: "10px", width: "200px", borderRadius: ".5rem", border: `0.1rem solid ${theme.palette.common.black}`}}>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600", fontSize: '1.2rem'}}>
          {`${payload[0].payload.tagType}`}
        </div>

        <hr />

        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600", fontSize: '1rem'}}>
          {`Number of Mortgages: `}
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.totalMortgages}`}
          </span>
        </div>

        <hr />
        
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600", fontSize: '1rem', flexDirection: 'column'}}>
          <FlexWrapper>
            Initial delinquency year:
          </FlexWrapper>
          <FlexWrapper className='column alignEnd'>
            {payload[0].payload.tagDetails &&
              payload[0].payload.tagDetails.map((tagDetail) => (
                <FlexWrapper className='justifyEnd' key={tagDetail.year}>
                  <FlexWrapper className='justifyEnd' style={{width: '5rem', fontWeight: '400'}}>
                    {tagDetail.year}:
                  </FlexWrapper>
                  <FlexWrapper className='justifyEnd' style={{width: '5rem'}}>
                    {tagDetail.count}
                  </FlexWrapper>
                </FlexWrapper>
              ))
            }
          </FlexWrapper>
        </div>        
      </div>
    );
  }

  return null
}

export default class TagsChart extends PureComponent {
  
  render() 
  {
    return (
      <ResponsiveContainer width="99%" height="99%">
        <ComposedChart
          data={this.props.tagsViewMortgages}
          layout="horizontal"
          margin={{
            top: 0,
            right: 5,
            left: 15,
            bottom: 0,
          }}
        >
          <XAxis dataKey="tagTypeAbbr" style={{fontSize: '1rem'}} />
          <YAxis label={{ value: 'Total Mortgages', angle: -90, position: '' }} dataKey="totalMortgages" type="number" domain={[0, dataMax => (dataMax * 2)]} yAxisId="left" orientation="left" stroke="#5a5c68" />
          <Tooltip content={<CustomTooltip/>} cursor={{fill: `#93959921`}} />
          <Legend />
          <Bar stackId="a" dataKey="totalMortgages" fill='#4CA855' yAxisId="left" name="Mortgages">
            {this.props.tagsViewMortgages.map((entry, index) => (
                <Cell fill={entry.customTagView ? '#4CA855' : '#80C486' }/>
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    )
  }
}