import React, { useState } from "react";
import ChartToggle from "./components/ChartToggle";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import MonthlyAllChart from "./components/Charts/Monthly/MonthlyAllChart";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import CircularProgress from "@mui/material/CircularProgress";
import ProfitTrackerChart from "./components/Charts/ProfitTracker/ProfitTrackerChart";
import MonthlyOneYearChart from "./components/Charts/Monthly/MonthlyOneYearChart";
import MonthlyTwoYearChart from "./components/Charts/Monthly/MonthlyTwoYearChart";
import { selectTeamClosedRefinances } from "../../../../../../slices/teamStatsSlice";
import { selectTeamRefinanceClosures } from "../../../../../../slices/teamClosuresSlice";
import { HeaderContainer, HeaderText, MainChartWrapper, ColumnContent, ComponentContainer, FlexWrapper, ComponentBorder } from "../../../../../../StyledComponents";
import { selectTeamRefinanceMonthlyStatsAll, selectTeamRefinanceMonthlyStatsOneYear, selectTeamRefinanceMonthlyStatsTwoYear } from "../../../../../../slices/teamMonthlyStatsSlice";

const MainChart = () => {
  const theme = useTheme()
  const [chartTimeline, setChartTimeline] = useState('All'); 
  const handleTimelineChange = (event) => {
    setChartTimeline(event.target.value);
  };
  const [chartPerspective, setChartPerspective] = useState('Monthly Stats'); 
  const handleChartPerspectiveChange = (event) => {
    setChartPerspective(event.target.value);
    setChartTimeline('All');
  };

  const teamClosedRefinances = useSelector(selectTeamClosedRefinances)
  const teamRefinanceClosures = useSelector(selectTeamRefinanceClosures)

  const teamMonthlyStatsAll = useSelector(selectTeamRefinanceMonthlyStatsAll)
  const teamMonthlyStatsTwoYear = useSelector(selectTeamRefinanceMonthlyStatsTwoYear)
  const teamMonthlyStatsOneYear = useSelector(selectTeamRefinanceMonthlyStatsOneYear)

  return (
    <ComponentContainer className="detailsContainer" style={{gridRow: '3/9', gridColumn: '3', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer className="chart">
          <HeaderText className="chart" style={{minWidth: '22rem'}}>
            {chartPerspective === 'Monthly Stats' ?
              `Monthly Stats`
            : chartPerspective === 'Historical Profit' ?
              'Refinance Profits'
            :
              ''
            }
          </HeaderText>
          <ChartToggle handleTimelineChange={handleTimelineChange} chartTimeline={chartTimeline} handleChartPerspectiveChange={handleChartPerspectiveChange} chartPerspective={chartPerspective} />
        </HeaderContainer>
        <MainChartWrapper>
          {teamRefinanceClosures !== undefined && teamClosedRefinances !== null && teamMonthlyStatsAll !== undefined && teamMonthlyStatsTwoYear !== undefined && teamMonthlyStatsOneYear !== undefined ?
            <>
              {teamClosedRefinances === 0 ?
                <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                  <TroubleshootIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '20rem', width: '20rem'}} />
                  <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no refinances completed</HeaderText>
                </FlexWrapper>
              : chartPerspective === 'Monthly Stats' && chartTimeline === 'All' ?
                <MonthlyAllChart monthlyStatsAll={teamMonthlyStatsAll} />
              : chartPerspective === 'Monthly Stats' && chartTimeline === '2 Year' ?
                <MonthlyTwoYearChart monthlyStatsTwoYear={teamMonthlyStatsTwoYear} />
              : chartPerspective === 'Monthly Stats' && chartTimeline === '1 Year' ?
                <MonthlyOneYearChart monthlyStatsYear={teamMonthlyStatsOneYear} />
              : chartPerspective === "Historical Profit" ?
                <ProfitTrackerChart closures={teamRefinanceClosures} />
              :
                <></>
              }
            </>
          :
            <ColumnContent className="single">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </ColumnContent>
          }
        </MainChartWrapper>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default MainChart;