import React from "react"
import Tooltip from '@mui/material/Tooltip';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from "@mui/styles";
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, CardButton, FlexWrapper, NewLabel, Typography } from "../../../../../../../StyledComponents"

const Verify = ({ lead, handleOpenLeadActionsModal }) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="justifyCenter alignCenter" style={{maxWidth: '31rem', minWidth: '31rem', height: '100%'}}>
      <FlexWrapper className="column alignCenter" style={{paddingTop: '0.5rem'}}>
        <FlexWrapper style={{margin: '0 auto 0.2rem 0'}}>
          {lead.publicRecordsUpdated &&
            <NewLabel className="leadCard" style={{width: 'fit-content', padding: '0.2rem 1rem', margin: '0 0.5rem 0 0'}}>Public Records updated!</NewLabel>
          }
          {lead.newAssignmentNotification &&
            <NewLabel className="leadCard" style={{width: 'fit-content', margin: '0 0.5rem 0 0', padding: '0.1rem 0.5rem'}}>New Assignment</NewLabel>
          }
          {lead.newAssignmentNotification || (lead.notifCount && lead.notifCount !== 0) ?
            <NewLabel className={((lead.notifCount && lead.notifCount !== 0) ? "" : "inactive") + " notification hover"} onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.belongsToMortgage)} style={{padding: lead.notifCount && lead.notifCount !== 0 ? " 0.1rem 0.1rem 0.1rem 0.3rem" : " 0.1rem 0.5rem 0.1rem 0.3rem"}}>
              <NotificationsIcon style={{color: (lead.notifCount && lead.notifCount !== 0) ? `${theme.notification.color.active}` : `${theme.notification.color.inactive}`}} />
              {(lead.notifCount && lead.notifCount !== 0) &&
                <Badge className="cardNotif" style={{height: '1.5rem', width: '1.5rem'}}>
                  {lead.notifCount}
                </Badge>
              }
              <span style={{position: 'relative', right: lead.notifCount && lead.notifCount !== 0 ? '0.5rem' : '0'}}>
                {lead.newAssignmentNotification ? 
                  `Assigned`
                :
                  `Updates`
                }
              </span>
            </NewLabel>
          : lead.newLeadLabel ?
            <NewLabel className="new hover" onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.belongsToMortgage)}>
              <FlagCircleIcon style={{color: `${theme.palette.primary.dark}`}} />
              New
            </NewLabel>
          :
            <></>
          }
        </FlexWrapper>
        <FlexWrapper className="justifyCenter" style={{margin: '0.5rem 0 0.25rem 0'}}>
          <Typography className="tinyHeight tiny" style={{marginRight: '0.5rem'}}>
            Last Scan:
          </Typography>
          <Typography className="bold tiny">
            {lead.belongsToMortgage.lastUpdateDate}
          </Typography>
        </FlexWrapper> 
        {(lead.belongsToMortgage.rejectedDiscrepancies > 0 || lead.belongsToMortgage.activeDiscrepancies > 0) ?
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="alignCenter justifyEnd bold" style={{height: '2rem'}}>
              <Badge 
                className="three awaiting"
                style={{marginRight: '0.3rem', height: '1.8rem', width: '1.8rem', top: '0', right: '0', left: '0'}}
              >
                {lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies}
              </Badge>
              {(lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies) === 1 ?
                `Field`
              :
                `Fields`
              }
              <span style={{marginLeft: '0.3rem'}}>
                Awaiting Public Records to match
              </span>
            </Typography>
          </FlexWrapper>
        :
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="fitContentHeight justifyEnd bold primary" style={{marginRight: '0.5rem'}}>
              No active discrepancies!
            </Typography>
          </FlexWrapper>
        }
        {lead.belongsToMortgage.resolvedDiscrepancies > 0 && (lead.belongsToMortgage.rejectedDiscrepancies + lead.belongsToMortgage.activeDiscrepancies !== 0) &&
          <FlexWrapper className='fitContentHeight justifyCenter'>
            <Typography className="fitContentHeight justifyEnd" style={{marginRight: '0.5rem'}}>
              Resolved Discrepancies:
            </Typography>
            <Typography className="fitContentHeight bold">
              {lead.belongsToMortgage.resolvedDiscrepancies}
            </Typography>
          </FlexWrapper>
        }
        <FlexWrapper className="column alignCenter" style={{margin: '1rem auto auto auto', height: 'fit-content', paddingRight: '2rem'}}>
          <FlexWrapper className="alignCenter" style={{margin: '0 auto', width: 'fit-content'}}>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.palette.primary.pastelDark}`,
                backgroundColor: `${theme.palette.primary.pastelLight}`,
                border: `0.3rem solid ${theme.palette.common.grayTextLight}`,
                borderRight: `0.2rem solid ${theme.palette.common.grayTextLight}`,
                opacity: '0',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, -7],
                },
              }]}}
              title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>verify records</span></div>} 
              placement="left"
              arrow
            >
              <span>
                <CardButton style={{minHeight: 'fit-content'}} onClick={() => handleOpenLeadActionsModal('confirmUpdate', lead, lead.belongsToMortgage)} className="iconButton primary">
                  <TaskAltIcon style={{height: '3rem', width: '3rem'}} />
                </CardButton>
              </span>
            </Tooltip>
            <Typography className="bold tiny alignTextCenter" style={{marginLeft: '1rem'}}>
              Verify the Public Records reflect<br/> the expected arrangements
            </Typography>
          </FlexWrapper>
          <Typography className="accent" style={{padding: '0.4rem 0 0 1rem'}}>
            -or-
          </Typography>
          <Typography className="accent justifyCenter alignTextCenter" style={{padding: '0.4rem 0 0 2rem'}}>
            make any expected changes in the "compare Records" tab and continue waiting for Pubic Records to match
          </Typography>
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default Verify