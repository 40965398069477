import React from "react"
import { AccentInfo, Detail, DetailListContainer, FlexWrapper, Typography } from "../../../../StyledComponents"

const DiscrepanciesDetected = ({ type, tier1, tier2, tier3  }) => {

  return (
    <>
      <Typography 
        className="justifyEnd"
        style={{width: '15rem', margin: '1rem 0 0.5rem 0'}}
      >
        {type === 'discrepancy' ?
          'New Discrepancies Identified:'
        : type === 'update' ?
          <>
            Public Record updates on<br/>
            Existing Discrepancies:
          </>
        :
          <>
            Public Record updates that<br/>
            Match Existing Records:
          </>
        }
      </Typography>
      <DetailListContainer className="timeline column">
        {tier1 && tier1.map((discrepancy) => (
          discrepancy.publicRecordValue !== null ?
            <FlexWrapper key={discrepancy.label} className="alignCenter">
              <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
              </div>
              <Detail style={{padding: '0 0 0 0.3rem'}}>
                {!discrepancy.publicRecordValue || discrepancy.publicRecordValue === 0 || discrepancy.publicRecordValue.length === 0 ?
                  <AccentInfo>
                    -empty-
                  </AccentInfo>
                : discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' ?
                  <>
                    ${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </>
                : discrepancy.label === 'Tax Annual Amount' ?
                  <>
                    ${parseFloat(discrepancy.publicRecordValue.substring(1)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                  </>
                : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                  <>
                    {parseFloat(discrepancy.publicRecordValue)}
                  </>
                : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                  <>
                    {parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
                  </>
                :
                  discrepancy.publicRecordValue
                }
              </Detail>
            </FlexWrapper>
          :
            <FlexWrapper key={discrepancy.label}>
              <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
              <Detail><AccentInfo>-empty-</AccentInfo></Detail>
            </FlexWrapper>
        ))}
        {tier2 && tier2.map((discrepancy) => (
          discrepancy.publicRecordValue !== null ?
            <FlexWrapper key={discrepancy.label} className="alignCenter">
              <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                <Detail className="timelineDetail tier two">{discrepancy.label}</Detail>
              </div>
              <Detail style={{padding: '0 0 0 0.3rem'}}>
                {discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' ?
                  <>
                    ${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </>
                : discrepancy.label === 'Tax Annual Amount' ?
                  <>
                    ${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                  </>
                : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                  <>
                    {parseFloat(discrepancy.publicRecordValue)}
                  </>
                : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                  <>
                    {parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
                  </>
                :
                  discrepancy.publicRecordValue
                }
              </Detail>
            </FlexWrapper>
          :
            <FlexWrapper key={discrepancy.label}>
              <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
              <Detail><AccentInfo>-empty-</AccentInfo></Detail>
            </FlexWrapper>
        ))}
        {tier3 && tier3.map((discrepancy) => (
          discrepancy.publicRecordValue !== null ?
            <FlexWrapper key={discrepancy.label} className="alignCenter">
              <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                <Detail className="timelineDetail tier three">{discrepancy.label}</Detail>
              </div>
              <Detail style={{padding: '0 0 0 0.3rem'}}>
                {discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' ?
                  <>
                    ${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </>
                : discrepancy.label === 'Tax Annual Amount' ?
                  <>
                    ${parseFloat(discrepancy.publicRecordValue.substring(1)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                  </>
                : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                  <>
                    {parseFloat(discrepancy.publicRecordValue)}
                  </>
                : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                  <>
                    {parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
                  </>
                :
                  discrepancy.publicRecordValue
                }
              </Detail>
            </FlexWrapper>
          :
            <FlexWrapper key={discrepancy.label}>
              <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
              <Detail><AccentInfo>-empty-</AccentInfo></Detail>
            </FlexWrapper>
        ))}
      </DetailListContainer>
    </>
  )
}

export default DiscrepanciesDetected