import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";
import { dropdownStyles, dropdownMenuProps } from "../../componentThemes";

const AssigneeDropdown = ({value, handleChange, items, disabled}) => {
  const theme = useTheme()
  const dropdown = dropdownStyles(theme)

  return (
    <FormControl className={dropdown.formControl}>
      <Select
        disabled={disabled}
        multiple
        value={value}
        onChange={handleChange}
        MenuProps={dropdownMenuProps}
        classes={{
          select: dropdown.select,
          icon: dropdown.selectIcon
        }}
      >
        {/* <MenuItem
          muiskiplisthighlight='true'
          disabled={value === items}
          value={items}
        >
          Assign All
        </MenuItem> */}
        {items.map((teamMember, index) => (
          <MenuItem
            muiskiplisthighlight='true'
            key={teamMember.memberId}
            disabled={value === teamMember.memberId}
            value={teamMember.memberId}
          >
            {teamMember.fullName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AssigneeDropdown;
