import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-csv-importer/dist/index.css';
import "../../../../../styles.css";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@mui/styles";
import FinalizeForm from "./FinalizeForm";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { selectSetInvestigationFinalizedPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { ComponentHeader, Button, ButtonWrapper, FlexWrapper, Typography, ModalContentContainer } from "../../../../../StyledComponents";
import { useReloadRefinanceQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation, useEditOneMortgageDetailMutation, useSetStatusFinalizedMutation, useResolveAllDiscrepanciesMutation } from "../../../../../slices/api/apiSlice";

const FinalizeLead = ({ handleCloseModal, leadData, teamId, userId, userFullName, mortgageData }) => {
  const theme = useTheme()

  const setInvestigationFinalizedPerm = useSelector(selectSetInvestigationFinalizedPerm)
  
  const [editOneMortgageDetail] = useEditOneMortgageDetailMutation()

  const [setStatusFinalized] = useSetStatusFinalizedMutation()
  const [resolveAllDiscrepancies] = useResolveAllDiscrepanciesMutation()
  const [realoadRefinanceQuarterBreakdown] = useReloadRefinanceQuarterlyBreakdownMutation()
  const [reloadRenegotiationQuarterlyBreakdown] = useReloadRenegotiationQuarterlyBreakdownMutation()

  const [startDate, setStartDate] = useState(null)
  const [statusDate, setStatusDate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [mortgageTermValue, setMortgageTermValue] = useState(null)
  const [mortgageTermValueStr, setMortgageTermValueStr] = useState('')
  const [statusTerm, setStatusTerm] = useState(null)
  const [principalValue, setPrincipalValue] = useState(null)
  const [principalValueStr, setPrincipalValueStr] = useState('')
  const [statusPrincipal, setStatusPrincipal] = useState(null)
  const [interestValue, setInterestValue] = useState(null)
  const [interestValueStr, setInterestValueStr] = useState('')
  const [statusInterest, setStatusInterest] = useState(null)
  const [actionTaken, setActionTaken] = useState(false)
  const [noChanges, setNoChanges] = useState(false)
  const [dateUpdated, setDateUpdated] = useState(false)
  const [termUpdated, setTermUpdated] = useState(false)
  const [amountUpdated, setAmountUpdated] = useState(false)
  const [interestUpdated, setInterestUpdated] = useState(false)

  const handleNoChanges = (newDateValue, newTermValue, newPrincipalValue, newInterestValue) => {
    let dateMatch = false
    let termMatch = false
    let principalMatch = false
    let interestMatch = false
    if (datePresent) {
      if (newDateValue === startDate) {
        dateMatch = true
      }
    }
    if (termPresent) {
      if (newTermValue === mortgageTermValue) {
        termMatch = true
      }
    }
    if (principalPresent) {
      if (newPrincipalValue === principalValue) {
        termMatch = true
      }
    }
    if (interestPresent) {
      if (newInterestValue === interestValue) {
        termMatch = true
      }
    }
    if ((datePresent && !dateUpdated && dateMatch) && (termPresent && !termUpdated && termMatch) && (principalPresent && !amountUpdated && principalMatch) && (interestPresent && !interestUpdated && interestMatch)) {
      setNoChanges(true)
    } else {
      setNoChanges(false)
    }
  }

  const handleSubmit = async (actionTaken) => {
    setIsFetching(true)
    setActionTaken(actionTaken)
    let resolveError = false
    let activeDiscrepancies = mortgageData.activeDiscrepancies
    let rejectedDiscrepancies = mortgageData.rejectedDiscrepancies
    let resolvedDiscrepancies = mortgageData.resolvedDiscrepancies
    let resolvingRequiredFirst = false
    let origin = 'finalizeLead'
    let newPreviousOwner1 = ''
    let newOwner1 = ''
    let newPreviousOwner2 = ''
    let newOwner2 = ''
    let updatedOwners = []
    let onwer1Discrepancy = mortgageData.recordDetails.owner1.Owner1FullName.discrepancy
    let onwer2Discrepancy = mortgageData.recordDetails.owner2.Owner2FullName.discrepancy
    if (actionTaken === 'resolve') {
      resolvingRequiredFirst = true
      origin = 'awaitingUpdate'
    } else if (onwer1Discrepancy === 'active' || onwer1Discrepancy === 'rejected' || onwer2Discrepancy === 'active' || onwer2Discrepancy === 'rejected') {
      newPreviousOwner1 = mortgageData.recordDetails.owner1.Owner1FullName.currentValue
      newOwner1 = mortgageData.recordDetails.owner1.Owner1FullName.publicRecordValue
      newPreviousOwner2 = mortgageData.recordDetails.owner2.Owner2FullName.currentValue
      newOwner2 = mortgageData.recordDetails.owner2.Owner2FullName.publicRecordValue
      updatedOwners.push(
        {
          newPreviousOwner1: newPreviousOwner1,
          newOwner1: newOwner1,
          newPreviousOwner2: newPreviousOwner2,
          newOwner2: newOwner2,
        }
      )
    }
    if (resolvingRequiredFirst) {
      let resp = await resolveAllDiscrepancies({
        userId: userId,
        userFullName: userFullName,
        teamId: teamId,
        mortgageId: mortgageData._id,
        mission: origin
      })
      if (resp.error) {
        resolveError = true
      } else {
        activeDiscrepancies = resp.data.data.activeDiscrepancies
        rejectedDiscrepancies = resp.data.data.rejectedDiscrepancies
        resolvedDiscrepancies = resp.data.data.resolvedDiscrepancies
      }
    }
    if (!resolveError) {
      let interestUpdated = false
      let recordUpdateError = false
      let updatedFields = []
      let oldFinancials = {
        originalInterestRate: mortgageData.originalInterestRate,
        originalLoanAmount: mortgageData.originalLoanAmount,
        mortgageTerm: mortgageData.mortgageTerm,
        originationDate: mortgageData.originationDateLabel,
        originalInterestDue: mortgageData.originalInterestDue,
        monthlyPayments: mortgageData.monthlyPayments,
        endDateLabel: mortgageData.endDateLabel,
        endDate: mortgageData.endDate,
      }
      if (moment(startDate).format('MMM Do, YYYY') !== moment(mortgageData.originationDate).format('MMM Do, YYYY')) {
        updatedFields.push({
          fieldRoot: 'primaryMortgage',
          field: 'PrimaryMortgageStartDate',
          label: 'Primary Mortgage Start Date',
          originalValue: "",
          old: "",
          new: moment(startDate).format('MMM Do, YYYY'),
          selection: "custom",
          publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageStartDate.publicRecordValue,
          updateParameter: false,
        })
        let resp = await editOneMortgageDetail({
          userId: userId,
          userFullName: userFullName,
          mortgageId: mortgageData._id,
          leadId: leadData._id,
          recordField: 'PrimaryMortgageStartDate',
          customValue: startDate,
          activeDiscrepancies: activeDiscrepancies,
          resolvedDiscrepancies: resolvedDiscrepancies,
          rejectedDiscrepancies: rejectedDiscrepancies,
          publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageStartDate.publicRecordValue,
          oldDiscrepancy: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageStartDate.discrepancy,
          recordFieldRoot: 'primaryMortgage',
          dateFormat: 'MMM Do, yyyy',
          discardLead: false,
          teamId: teamId,
          selection: null,
          financialsUpdated: true,
          origin: origin,
          leadStatus: leadData.status,
          closingLead: true,
        })
        if (resp.error) {
          recordUpdateError = true
        } else {
          if (resp.data.data.rejectedAdded) {
            rejectedDiscrepancies++
          }
          if (resp.data.data.rejectedRemoved) {
            rejectedDiscrepancies--
          }
          if (resp.data.data.activeAdded) {
            activeDiscrepancies++
          }
          if (resp.data.data.activeRemoved) {
            activeDiscrepancies--
          }
        }
      }
      if (!mortgageData.mortgageTerm || mortgageTermValue !== mortgageData.mortgageTerm) {
        updatedFields.push({
          fieldRoot: 'primaryMortgage',
          field: 'PrimaryMortgageTerm',
          label: 'Primary Mortgage Term',
          originalValue: "",
          old: "",
          new: parseFloat(mortgageTermValue),
          selection: "custom",
          publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageTerm.publicRecordValue,
          updateParameter: false,
        })
        let resp = await editOneMortgageDetail({
          userId: userId,
          userFullName: userFullName,
          mortgageId: mortgageData._id,
          leadId: leadData._id,
          recordField: 'PrimaryMortgageTerm',
          customValue: mortgageTermValue.toString(),
          activeDiscrepancies: activeDiscrepancies,
          resolvedDiscrepancies: resolvedDiscrepancies,
          rejectedDiscrepancies: rejectedDiscrepancies,
          publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageTerm.publicRecordValue,
          oldDiscrepancy: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageTerm.discrepancy,
          recordFieldRoot: 'primaryMortgage',
          dateFormat: null,
          discardLead: false,
          teamId: teamId,
          selection: null,
          financialsUpdated: true,
          origin: origin,
          leadStatus: leadData.status,
          closingLead: true,
        })
        if (resp.error) {
          recordUpdateError = true
        } else {
          if (resp.data.data.rejectedAdded) {
            rejectedDiscrepancies++
          }
          if (resp.data.data.rejectedRemoved) {
            rejectedDiscrepancies--
          }
          if (resp.data.data.activeAdded) {
            activeDiscrepancies++
          }
          if (resp.data.data.activeRemoved) {
            activeDiscrepancies--
          }
        }
      }
      if (!mortgageData.originalLoanAmount || principalValue !== mortgageData.originalLoanAmount) {
        updatedFields.push({
          fieldRoot: 'primaryMortgage',
          field: 'PrimaryMortgageAmount',
          label: 'Primary Mortgage Amount',
          originalValue: "",
          old: "",
          new: parseFloat(principalValue),
          selection: "custom",
          publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageAmount.publicRecordValue,
          updateParameter: false,
        })
        let resp = await editOneMortgageDetail({
          userId: userId,
          userFullName: userFullName,
          mortgageId: mortgageData._id,
          leadId: leadData._id,
          recordField: 'PrimaryMortgageAmount',
          customValue: principalValue.toString(),
          activeDiscrepancies: activeDiscrepancies,
          resolvedDiscrepancies: resolvedDiscrepancies,
          rejectedDiscrepancies: rejectedDiscrepancies,
          publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageAmount.publicRecordValue,
          oldDiscrepancy: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageAmount.discrepancy,
          recordFieldRoot: 'primaryMortgage',
          dateFormat: null,
          discardLead: false,
          teamId: teamId,
          selection: null,
          financialsUpdated: true,
          origin: origin,
          leadStatus: leadData.status,
          closingLead: true,
        })
        if (resp.error) {
          recordUpdateError = true
        } else {
          if (resp.data.data.rejectedAdded) {
            rejectedDiscrepancies++
          }
          if (resp.data.data.rejectedRemoved) {
            rejectedDiscrepancies--
          }
          if (resp.data.data.activeAdded) {
            activeDiscrepancies++
          }
          if (resp.data.data.activeRemoved) {
            activeDiscrepancies--
          }
        }
      }
      if (mortgageData.originalInterestRate || interestValue !== mortgageData.originalInterestRate) {
        interestUpdated = true
        updatedFields.push({
          fieldRoot: 'primaryMortgage',
          field: 'PrimaryMortgageInterestRate',
          label: 'Primary Mortgage Interest Rate',
          originalValue: "",
          old: "",
          new: parseFloat(interestValue),
          selection: "custom",
          publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageInterestRate.publicRecordValue,
          updateParameter: false,
        })
        let resp = await editOneMortgageDetail({
          userId: userId,
          userFullName: userFullName,
          mortgageId: mortgageData._id,
          leadId: leadData._id,
          recordField: 'PrimaryMortgageInterestRate',
          customValue: interestValue.toString(),
          activeDiscrepancies: activeDiscrepancies,
          resolvedDiscrepancies: resolvedDiscrepancies,
          rejectedDiscrepancies: rejectedDiscrepancies,
          publicRecordValue: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageInterestRate.publicRecordValue,
          oldDiscrepancy: mortgageData.recordDetails.primaryMortgage.PrimaryMortgageInterestRate.discrepancy,
          recordFieldRoot: 'primaryMortgage',
          dateFormat: null,
          discardLead: false,
          teamId: teamId,
          selection: null,
          financialsUpdated: true,
          origin: origin,
          leadStatus: leadData.status,
          closingLead: true,
        })
        if (resp.error) {
          recordUpdateError = true
        } else {
          if (resp.data.data.rejectedAdded) {
            rejectedDiscrepancies++
          }
          if (resp.data.data.rejectedRemoved) {
            rejectedDiscrepancies--
          }
          if (resp.data.data.activeAdded) {
            activeDiscrepancies++
          }
          if (resp.data.data.activeRemoved) {
            activeDiscrepancies--
          }
          if (resp.data.data.resolvedAdded) {
            resolvedDiscrepancies++
          }
          if (resp.data.data.resolvedRemoved) {
            resolvedDiscrepancies--
          }
        }
      }
  
      if (!recordUpdateError) {
        let reqObj = {
          userId: userId,
          leadId: leadData._id,
          teamId: teamId,
          updatedTerm: mortgageTermValue,
          updatedPrincipal: principalValue,
          updatedInterestRate: interestValue,
          updatedStartDate: startDate,
          updatedFields: updatedFields,
          userFullName: userFullName,
          interestUpdated: interestUpdated,
          oldFinancials: oldFinancials,
          rejectedDiscrepancies: rejectedDiscrepancies,
          resolvedDiscrepancies: resolvedDiscrepancies,
          activeDiscrepancies: activeDiscrepancies,
          resolvingRequiredFirst: resolvingRequiredFirst,
          updatedOwners: updatedOwners,
        }
        if (leadData.targetOutcome.toUpperCase() === 'REFINANCE' && !noChanges && actionTaken !== 'close') {
          reqObj.preserveLead = true
        }
        let initialResp = await setStatusFinalized(reqObj)
        if (initialResp.error) {
          setIsError(true)
        } else {
          let reloadResp = {}
          if (leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION') {
            reloadResp = await reloadRenegotiationQuarterlyBreakdown({
              userId: userId,
              teamId: teamId,
              leadTier: leadData.tier,
              actionTaken: 'closed',
              closureType: leadData.targetOutcome,
            })
          } else {
            reloadResp = await realoadRefinanceQuarterBreakdown({
              userId: userId,
              teamId: teamId,
              leadTier: leadData.tier,
              actionTaken: 'closed',
              closureType: leadData.targetOutcome,
            })
          }
          if (reloadResp.error) {
            setIsError(true)
          } else if (reloadResp.data.message.includes('success')) {
            setIsSuccess(true)
          } else {
            setIsError(true)
          }
        }
      } else {
        setIsError(true)
      }
    } else {
      setIsError(true)
    }
    setIsFetching(false)
  }

  const [termRequired, setTermRequired] = useState(null)
  const [termPresent, setTermPresent] = useState(false)
  const [dateRequired, setDateRequired] = useState(null)
  const [datePresent, setDatePresent] = useState(false)
  const [interestRequired, setInterestRequired] = useState(null)
  const [interestPresent, setInterestPresent] = useState(false)
  const [principalRequired, setPrincipalRequired] = useState(null)
  const [principalPresent, setPrincipalPresent] = useState(false)

  useEffect(() => {
    setTermPresent(false)
    setDatePresent(false)
    setInterestPresent(false)
    setPrincipalPresent(false)
    if (mortgageData.status === 'closing' && leadData.status === 'closing') {
      let datePresent = false
      let termPresent = false
      let principalPresent = false
      let interestPresent = false
      if (mortgageData.originationDate.length > 0) {
        let today = new Date()
        if (mortgageData.originationDate.length > 20) {
          today = new Date(mortgageData.originationDate.slice(0,4)+'-'+mortgageData.originationDate.slice(5,10))
        }
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        setStartDate(tomorrow)
        setDatePresent(true)
        datePresent = true
      } else {
        if (!startDate) {
          setDateRequired(true)
        }
        setStartDate(new Date())
      }
      if (isNaN((mortgageData.originalLoanAmount))) {
        setStatusPrincipal("nonNumber")
      } else if (Math.sign(parseInt(mortgageData.originalLoanAmount)) === -1) {
        setStatusPrincipal("negative")
      } else if (Math.sign(parseInt(mortgageData.originalLoanAmount)) === 0) {
        setStatusPrincipal("zero")
      } else if (parseInt(mortgageData.originalLoanAmount) > 100000000) {
        setStatusPrincipal("large")
      }
      if (!mortgageData.originalLoanAmount || mortgageData.originalLoanAmount === 0) {
        if (principalRequired === null) {
          setPrincipalRequired(true)
        }
      } else {
        setPrincipalPresent(true)
        principalPresent = true
      }
      if (isNaN((mortgageData.mortgageTerm))) {
        setStatusTerm("nonNumber")
      } else if (Math.sign(parseFloat(mortgageData.mortgageTerm)) === -1) {
        setStatusTerm("negative")
      } else if (Math.sign(parseFloat(mortgageData.mortgageTerm)) === 0) {
        setStatusTerm("zero")
      } else if (parseFloat(mortgageData.mortgageTerm) > 100000000) {
        setStatusTerm("large")
      }
      if (!mortgageData.mortgageTerm || mortgageData.mortgageTerm === 0) {
        if (termRequired === null) {
          setTermRequired(true)
        }
      } else {
        setTermPresent(true)
        termPresent = true
      }
      if (isNaN((mortgageData.originalInterestRate))) {
        setStatusInterest("nonNumber")
      } else if (Math.sign(parseFloat(mortgageData.originalInterestRate)) === -1) {
        setStatusInterest("negative")
      } else if (Math.sign(parseFloat(mortgageData.originalInterestRate)) === 0) {
        setStatusInterest("zero")
      } else if (parseFloat(mortgageData.originalInterestRate) > 100000000) {
        setStatusInterest("large")
      }
      if (!mortgageData.originalInterestRate || mortgageData.originalInterestRate === 0) {
        if (interestRequired === null) {
          setInterestRequired(true)
        }
      } else {
        setInterestPresent(true)
        interestPresent = true
      }
      if (mortgageData.originalLoanAmount && mortgageData.originalLoanAmount > 0) {
        setPrincipalValue(parseFloat(mortgageData.originalLoanAmount))
        setPrincipalValueStr(mortgageData.originalLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0}))
      }
      if (mortgageData.originalInterestRate && mortgageData.originalInterestRate > 0) {
        setInterestValue(mortgageData.originalInterestRate)
        setInterestValueStr(mortgageData.originalInterestRate)
      }
      if (mortgageData.mortgageTerm && mortgageData.mortgageTerm > 0) {
        setMortgageTermValue(mortgageData.mortgageTerm)
        setMortgageTermValueStr(mortgageData.mortgageTerm)
      }
      if (leadData.targetOutcome.toUpperCase() === 'REFINANCE') {
        let dateUpdated = false
        let termUpdated = false
        let principalUpdated = false
        let interestUpdated = false
        if (leadData && leadData.updates) {
          dateUpdated = leadData.updates.find(update => update.field === 'PrimaryMortgageStartDate')
          if (dateUpdated) {
            setDateUpdated(true)
          }
          termUpdated = leadData.updates.find(update => update.field === 'PrimaryMortgageTerm')
          if (termUpdated) {
            setTermUpdated(true)
          }
          principalUpdated = leadData.updates.find(update => update.field === 'PrimaryMortgageAmount')
          if (principalUpdated) {
            setAmountUpdated(true)
          }
          interestUpdated = leadData.updates.find(update => update.field === 'PrimaryMortgageInterestRate')
          if (interestUpdated) {
            setInterestUpdated(true)
          }
        }
        const today = new Date()
        setStartDate(today)
        setStatusDate('discrepancy')
        let principalMatch = false
        let interestMatch = false
        let termMatch = false
        if (leadData.targetLoanAmount && leadData.targetLoanAmount > 0) {
          setPrincipalValue(parseFloat(leadData.targetLoanAmount))
          setPrincipalValueStr(leadData.targetLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0}))
          if (leadData.targetLoanAmount !== mortgageData.originalLoanAmount) {
            setStatusPrincipal('discrepancy')
          } else {
            principalMatch = true
            // if ((datePresent && !dateUpdated) && (termPresent && !termUpdated) && (principalPresent && !principalUpdated) && (interestPresent && !interestUpdated)) {
            //   setNoChanges(true)
            // }
          }
        }
        if (leadData.targetInterestRate && leadData.targetInterestRate > 0) {
          setInterestValue(leadData.targetInterestRate)
          setInterestValueStr(leadData.targetInterestRate)
          if (leadData.targetInterestRate !== mortgageData.originalInterestRate) {
            setStatusInterest('discrepancy')
          } else {
            interestMatch = true
            // if ((datePresent && !dateUpdated) && (termPresent && !termUpdated) && (principalPresent && !principalUpdated) && (interestPresent && !interestUpdated)) {
            //   setNoChanges(true)
            // }
          }
        }
        if (leadData.targetLoanTerm && leadData.targetLoanTerm > 0) {
          setMortgageTermValue(leadData.targetLoanTerm)
          setMortgageTermValueStr(leadData.targetLoanTerm)
          if (leadData.targetLoanTerm !== mortgageData.mortgageTerm) {
            setStatusTerm('discrepancy')
          } else {
            termMatch = true
            // if ((datePresent && !dateUpdated) && (termPresent && !termUpdated) && (principalPresent && !principalUpdated) && (interestPresent && !interestUpdated)) {
            //   setNoChanges(true)
            // }
          }
        }
        if ((principalMatch && interestMatch && termMatch)) {
          setNoChanges(true)
        }
      }
    }
  }, [mortgageData, leadData])

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
            <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
            Success
          </span>
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION' ?
          <ComponentHeader className="modal">Finalize Renegotiation</ComponentHeader>
        :
          <ComponentHeader className="modal">Finalize Refinance</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isFetching ?
          <FlexWrapper className="alignCenter justifyCenter">
            <CircularProgress sx={{ color: `${theme.palette.primary.dark}` }} size={53} />
          </FlexWrapper>
        : isSuccess ?
          <>
            <FlexWrapper className="column fitContentHeight" style={{margin: 'auto'}}>
              {actionTaken === 'close' ?
                <Typography className="justifyCenter small">
                  The investigation has been closed.
                </Typography>
              :
                <Typography className="alignTextCenter small" style={{margin: '2rem auto 0 auto'}}>
                  The investigation will retain an "Awaiting Public Record Update" status until the
                  <br/>
                  expected arrangements are reflected in the public records.
                </Typography>
              }
            </FlexWrapper>
          </>
        :
          <>
            {(mortgageData.activeDiscrepancies + mortgageData.rejectedDiscrepancies + (statusPrincipal === 'discrepancy' ? 1 : 0) + (statusTerm === 'discrepancy' ? 1 : 0) + (statusInterest === 'discrepancy' ? 1 : 0) + (statusDate === 'discrepancy' ? 1 : 0) > 0) &&
              <FlexWrapper className="alignCenter fitContentHeight fitContentWidth" style={{margin: '2rem 3rem 0 3rem', height: '10.5rem'}}>
                <FlexWrapper 
                  style={{
                    border: `0.2rem solid ${theme.palette.common.orangeDark}`, 
                    padding: '0.5rem', width: '2.7rem',
                    borderRadius: '1rem 0 0 1rem',
                    background: `${theme.palette.common.orange}`}}
                  >
                  <ReportProblemIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                </FlexWrapper>
                <div style={{
                    border: `0.1rem solid ${theme.palette.common.orangeDark}`,
                    padding: '0.3rem 4rem 0.3rem 1rem',
                    borderRadius: '0 1rem 1rem 0',
                    background: `${theme.palette.common.orangeTransparent}`,
                    borderLeft: 'none',
                    color: `${theme.palette.common.orangeDark}`,
                    height: '100%',
                  }}
                >
                  {leadData.targetOutcome.toUpperCase() === 'REFINANCE' ?
                    <>
                      The current configuration results in
                      <span style={{marginLeft: '0.3rem', fontWeight: '700', fontSize: '1.2rem'}}>
                        <span style={{margin: '0 0.3rem'}}>
                          {mortgageData.activeDiscrepancies + mortgageData.rejectedDiscrepancies + (statusPrincipal === 'discrepancy' ? 1 : 0) + (statusTerm === 'discrepancy' ? 1 : 0) + (statusInterest === 'discrepancy' ? 1 : 0) + (statusDate === 'discrepancy' ? 1 : 0)}
                        </span>
                        {(mortgageData.activeDiscrepancies + mortgageData.rejectedDiscrepancies + (statusPrincipal === 'discrepancy' ? 1 : 0) + (statusTerm === 'discrepancy' ? 1 : 0) + (statusInterest === 'discrepancy' ? 1 : 0) + (statusDate === 'discrepancy' ? 1 : 0)) === 1 ?
                          <span>
                            discrepancy.
                          </span>
                        :
                          <span>
                            discrepencies.
                          </span>
                        }
                      </span>
                      <FlexWrapper className="fitContentHeight" style={{margin: '0.6rem 0'}}>
                        <span>
                          The form is populated with the current Refinance Targets. To proceed, either:
                        </span>
                      </FlexWrapper>
                      <FlexWrapper className="fitContentHeight" style={{margin: '0.6rem 0'}}>
                        <div>•</div>
                        <div style={{marginLeft: '1rem'}}>
                          Update targets to match public records, or
                        </div>
                      </FlexWrapper>
                      <FlexWrapper className="fitContentHeight" style={{margin: '0.6rem 0'}}>
                        <div>•</div>
                        <div style={{marginLeft: '1rem'}}>
                          Provide the new refinance agreements and select "Await Public Records Update".<br/>
                          The new terms will be used to generate a Refinance Report and update the Internal Records.<br/>
                          Your team will be notified once changes are detected in county records.<br/>
                        </div>
                      </FlexWrapper>
                    </>
                  :
                    <>
                      The investigation currently has
                      <span style={{marginLeft: '0.3rem', fontWeight: '700', fontSize: '1.2rem'}}>
                        {mortgageData.activeDiscrepancies + mortgageData.rejectedDiscrepancies + (statusPrincipal === 'discrepancy' ? 1 : 0) + (statusTerm === 'discrepancy' ? 1 : 0) + (statusInterest === 'discrepancy' ? 1 : 0) + (statusDate === 'discrepancy' ? 1 : 0)}
                        <span style={{margin: '0 0.3rem'}}>
                          active
                        </span>
                        {(mortgageData.activeDiscrepancies + mortgageData.rejectedDiscrepancies + (statusPrincipal === 'discrepancy' ? 1 : 0) + (statusTerm === 'discrepancy' ? 1 : 0) + (statusInterest === 'discrepancy' ? 1 : 0) + (statusDate === 'discrepancy' ? 1 : 0)) === 1 ?
                          <span>
                            discrepancy.
                          </span>
                        :
                          <span>
                            discrepencies.
                          </span>
                        }
                      </span>
                      <FlexWrapper className="fitContentHeight" style={{margin: '0.6rem 0'}}>
                        <div>•</div>
                        <div style={{marginLeft: '1rem'}}>
                          If the current information in the public records reflects the anticipated conditions, select "Resolve and Close" to:<br/>
                          resolve all discrepancies, close the investigation, and place the mortgage back in the "inactive" pool.
                        </div>
                      </FlexWrapper>
                      <FlexWrapper className="fitContentHeight" style={{margin: '0.6rem 0'}}>
                        <div>•</div>
                        <div style={{marginLeft: '1rem'}}>
                          Otherwise, select "Await Public Records Update" to close the investigation as-is and place it in the "awaiting updates" pool.<br/>
                          Your team will be notified once changes are detected in county records.
                        </div>
                      </FlexWrapper>
                    </>
                  }
                </div>
              </FlexWrapper>
            }
            {(noChanges && leadData.targetOutcome.toUpperCase() === 'REFINANCE') &&
              <FlexWrapper className="alignCenter fitContentHeight fitContentWidth" style={{margin: '2rem auto 0 auto'}}>
                <FlexWrapper 
                  className="alignCenter"
                  style={{
                    border: `0.2rem solid ${theme.palette.common.orangeDark}`, 
                    padding: '0.5rem', width: '2.7rem',
                    borderRadius: '1rem 0 0 1rem',
                    background: `${theme.palette.common.orange}`
                  }}
                  >
                  <ReportProblemIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
                </FlexWrapper>
                <div style={{
                    border: `0.1rem solid ${theme.palette.common.orangeDark}`,
                    padding: '0.8rem 4rem 0.8rem 1rem',
                    borderRadius: '0 1rem 1rem 0',
                    background: `${theme.palette.common.orangeTransparent}`,
                    borderLeft: 'none',
                    color: `${theme.palette.common.orangeDark}`,
                    height: '100%',
                  }}
                >
                  <Typography className="small">
                    No changes have been made to the financial details
                  </Typography>
                  <Typography className="small">
                    Switch to a Renegotiation if the original terms are being kept.
                  </Typography>
                </div>
              </FlexWrapper>
            }
            <FinalizeForm setStartDate={setStartDate} startDate={startDate} statusTerm={statusTerm} mortgageTermValueStr={mortgageTermValueStr} setStatusTerm={setStatusTerm} setMortgageTermValueStr={setMortgageTermValueStr} setMortgageTermValue={setMortgageTermValue} statusPrincipal={statusPrincipal} principalValueStr={principalValueStr} setStatusPrincipal={setStatusPrincipal} setPrincipalValueStr={setPrincipalValueStr} setPrincipalValue={setPrincipalValue} statusInterest={statusInterest} setStatusInterest={setStatusInterest} setInterestValueStr={setInterestValueStr} setInterestValue={setInterestValue} interestValueStr={interestValueStr} termRequired={termRequired} interestRequired={interestRequired} principalRequired={principalRequired} dateRequired={dateRequired} setTermRequired={setTermRequired} setDateRequired={setDateRequired} setInterestRequired={setInterestRequired} setPrincipalRequired={setPrincipalRequired} termPresent={termPresent} datePresent={datePresent} interestPresent={interestPresent} principalPresent={principalPresent} mortgageData={mortgageData} setStatusDate={setStatusDate} statusDate={statusDate} leadOutcome={leadData.targetOutcome} handleNoChanges={handleNoChanges} dateUpdated={dateUpdated} termUpdated={termUpdated} amountUpdated={amountUpdated} interestUpdated={interestUpdated} mortgageTermValue={mortgageTermValue} principalValue={principalValue} interestValue={interestValue} leadData={leadData} />
            {/* {leadData.targetOutcome.toUpperCase() === 'REFINANCE' &&
              <FlexWrapper className="justifyCenter">
                <FlexWrapper className="column justifyBetween alignEnd fitContentHeight" style={{width: '40%'}}>
                  <Typography className="xLargeHeight">Proposed Interest Due:</Typography>
                  <Typography className="xLargeHeight">Proposed Monthly Payments:</Typography>
                  <Typography className="xLargeHeight">Change in Interest Owed:</Typography>
                  <Typography className="xLargeHeight">Change in Revenue:</Typography>
                </FlexWrapper>
                <FlexWrapper className="column justifyBetween fitContentHeight" style={{width: '40%', paddingLeft: '0.5rem'}}>
                  {leadData.targetInterestDue > 0 ?
                    <Typography className="bold xLarge">${leadData.targetInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                  :
                    <Typography className="xLarge accent thin">-N/A-</Typography>
                  }
                  {leadData.targetMonthlyPayments > 0 ?
                    <Typography className="bold xLarge">${leadData.targetMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                  :
                    <Typography className="xLarge accent thin">-N/A-</Typography>
                  }
                  {leadData.targetProfitNumber < 0 ?
                    <Typography className="bold xLarge error">
                      -${(leadData.targetProfitNumber * -1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  : leadData.targetProfitNumber >= 0 ?
                    <Typography className="bold xLarge success">${leadData.targetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                  :
                    <Typography className="xLarge accent thin">-N/A-</Typography>
                  }
                  {leadData.targetProfitPercent < 0 ?
                    <Typography className="bold xLarge error">{leadData.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Typography>
                  : leadData.targetProfitPercent >= 0 ?
                    <Typography className="bold xLarge success">{leadData.targetProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Typography>
                  :
                    <Typography className="xLarge accent thin">-N/A-</Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
            } */}
            {leadData.targetOutcome !== 'refinance' &&
              <Typography className="alignTextCenter small" style={{height: '6rem', margin: 'auto auto 5rem auto'}}>
                Finalizing the investigation will generate a report detailing the actions taken and the new arrangement.<br/>
                Make any necessary changes before continuing.
              </Typography>
            }
            <ButtonWrapper style={{margin: 'auto auto 1rem auto'}}>
              {(leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION' || ((mortgageData.activeDiscrepancies + mortgageData.rejectedDiscrepancies + (statusPrincipal === 'discrepancy' ? 1 : 0) + (statusTerm === 'discrepancy' ? 1 : 0) + (statusInterest === 'discrepancy' ? 1 : 0) + (statusDate === 'discrepancy' ? 1 : 0)) === 0)) &&
                <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.error.color}`,
                  backgroundColor: `${theme.tooltip.error.background}`,
                  border: `0.1rem solid ${theme.tooltip.error.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.error.border}`,
                  opacity: '0',
                  borderRadius: '1rem',
                  width: 'fit-content',
                  minWidth: 'fit-content',
                  padding: '0.5rem 1rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                }]}}
                title={!setInvestigationFinalizedPerm ?
                  <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Elevated authority required
                      </Typography>
                    </FlexWrapper>
                  : ((statusPrincipal && statusPrincipal !== 'discrepancy') || (statusInterest && statusPrincipal !== 'discrepancy') || (statusTerm && statusPrincipal !== 'discrepancy')) ?
                  <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Form inputs required
                      </Typography>
                    </FlexWrapper>
                  : (dateRequired || principalRequired || interestRequired || termRequired) ?
                  <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Form inputs required
                      </Typography>
                    </FlexWrapper>
                  : (noChanges && leadData.targetOutcome.toUpperCase() === 'REFINANCE') ?
                  <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Closing a refinance requires updates to terms
                      </Typography>
                    </FlexWrapper>
                  : null} 
                  placement="top"
                  arrow
                  >
                  <Button 
                    className={
                      (!setInvestigationFinalizedPerm || (statusPrincipal && statusPrincipal !== 'discrepancy') || (statusInterest && statusInterest !== 'discrepancy') || (statusTerm && statusTerm !== 'discrepancy') || dateRequired || principalRequired || interestRequired || termRequired || (noChanges && leadData.targetOutcome.toUpperCase() === 'REFINANCE')) ? 
                        "primaryDisabled"
                      : isFetching ? 
                        "primaryLoading"
                      : "primary"
                    }
                    onClick={
                      (!setInvestigationFinalizedPerm || isFetching || dateRequired || principalRequired || interestRequired || termRequired || (statusPrincipal && statusPrincipal !== 'discrepancy') || (statusInterest && statusInterest !== 'discrepancy') || (statusTerm && statusTerm !== 'discrepancy') || (noChanges && leadData.targetOutcome.toUpperCase() === 'REFINANCE')) ? 
                        null 
                      : mortgageData.activeDiscrepancies > 0 || mortgageData.rejectedDiscrepancies > 0 || statusPrincipal === 'discrepancy' || statusInterest === 'discrepancy' || statusTerm === 'discrepancy' || statusDate === 'discrepancy' ?
                        () => handleSubmit('resolve')
                      : () => handleSubmit('close')
                    }
                    style={{width: '24rem'}}
                    tabIndex={4}
                    >
                    {isFetching ?
                      <CircularProgress sx={{ color: `${theme.palette.common.cardBackground}` }} size={13} />
                      : mortgageData.activeDiscrepancies > 0 || mortgageData.rejectedDiscrepancies > 0 || statusPrincipal === 'discrepancy' || statusInterest === 'discrepancy' || statusTerm === 'discrepancy' || statusDate === 'discrepancy' ?
                      `Resolve and Close`
                      :
                      `Close Investigation`
                    }
                  </Button>
                </Tooltip>
              }
              {mortgageData.activeDiscrepancies > 0 || mortgageData.rejectedDiscrepancies > 0 || statusPrincipal === 'discrepancy' || statusInterest === 'discrepancy' || statusTerm === 'discrepancy' || statusDate === 'discrepancy' ?
                <Tooltip
                  componentsProps={{tooltip: {sx: {
                    color: `${theme.tooltip.error.color}`,
                    backgroundColor: `${theme.tooltip.error.background}`,
                    border: `0.1rem solid ${theme.tooltip.error.border}`,
                    borderBottom: `0.1rem solid ${theme.tooltip.error.border}`,
                    opacity: '0',
                    borderRadius: '1rem',
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    padding: '0.5rem 1rem',
                  }}}}
                  PopperProps={{modifiers: [{
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  }]}}
                  title={!setInvestigationFinalizedPerm ?
                    <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Elevated authority required
                      </Typography>
                    </FlexWrapper>
                  : (noChanges && leadData.targetOutcome.toUpperCase() === 'REFINANCE') ?
                    <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Provide updated terms before proceeding
                      </Typography>
                    </FlexWrapper>
                  : ((statusPrincipal && statusPrincipal !== 'discrepancy') || (statusInterest && statusInterest !== 'discrepancy') || (statusTerm && statusTerm !== 'discrepancy')) ?
                    <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Form inputs required
                      </Typography>
                    </FlexWrapper>
                  : (dateRequired || principalRequired || (interestRequired && leadData.targetOutcome !== 'refinance') || (termRequired && leadData.targetOutcome !== 'refinance')) ?
                    <FlexWrapper className="column">
                      <Typography className="bold alignTextCenter">
                        Form inputs required
                      </Typography>
                    </FlexWrapper>
                  : null} 
                  placement="top"
                  arrow
                >
                  <Button 
                    className={
                      (!setInvestigationFinalizedPerm || (statusPrincipal && statusPrincipal !== 'discrepancy') || (statusInterest && statusInterest !== 'discrepancy') || (statusTerm && statusTerm !== 'discrepancy') || dateRequired || principalRequired || (interestRequired && leadData.targetOutcome !== 'refinance') || (termRequired && leadData.targetOutcome !== 'refinance') || (noChanges && leadData.targetOutcome.toUpperCase() === 'REFINANCE')) ? "primaryDisabled" : isFetching ? "primaryLoading" : "primary"
                    }
                    onClick={
                      (!setInvestigationFinalizedPerm || isFetching || dateRequired || principalRequired || (interestRequired && leadData.targetOutcome !== 'refinance') || (termRequired && leadData.targetOutcome !== 'refinance') || (statusPrincipal && statusPrincipal !== 'discrepancy') || (statusInterest && statusInterest !== 'discrepancy') || (statusTerm && statusTerm !== 'discrepancy') || (noChanges && leadData.targetOutcome.toUpperCase() === 'REFINANCE')) ? null : () => handleSubmit('await')
                    }
                    style={{width: '24rem'}}
                    tabIndex={5}
                  >
                    {isFetching ?
                      <CircularProgress sx={{ color: `${theme.palette.common.cardBackground}` }} size={13} />
                    :
                      `Await Public Records Update`
                    }
                  </Button>
                </Tooltip>
              :<></>}
            </ButtonWrapper>
          </>
        }
      </ModalContentContainer>
    </>
  )
}

export default FinalizeLead