exports.dateConverterMMMdoyyyy = function (oldDate) {
  let months = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  let oldMonth = oldDate.substr(0,3)
  let newMonth = months.find(month => month === oldMonth)
  let newMonthInt = months.indexOf(newMonth)
  if (newMonthInt < 10) {
    newMonthInt = `0${newMonthInt}`
  }
  let oldDay = ''
  let oldYear = ''
  if (isNaN(parseInt(oldDate.charAt(5))) || oldDate.charAt(5) === 0) {
    oldDay = oldDate.substr(4,1)
    oldYear = oldDate.substr(9,4)
  } else {
    oldDay = oldDate.substr(4,2)
    oldYear = oldDate.substr(10,4)
  }

  let newDate = `${oldYear}-${newMonthInt}-${oldDay}`

  return newDate
}