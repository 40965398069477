import React, { useEffect, useState } from "react"
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from "@mui/material/CircularProgress";
import AssigneeDropdown from "../../../../DropDowns/AssigneeDropdown"
import { useAddLeadAssigneesMutation, useAddMortgageAssigneesMutation } from "../../../../../slices/api/apiSlice"
import { AccentInfo, Button, FlexWrapper, InputWrapper, Typography } from "../../../../../StyledComponents"

const AddAssigneesInput = ({ activeLead, userId, teamMembersByName, currentAssigneeIds, leadId, userFullName, leadUserNotifications, addAssigneesPerm, mortgageId, entity, setIsError, setIsSuccess, isSuccess }) => {

  const [isFetching, setIsFetching] = useState(false)
  const [statusLeadEmpty, setStatusLeadEmpty] = useState(false)
  const [saveLeadAllowed, setSaveLeadAllowed] = useState(false)

  const [newAssigneeIds, setNewAssigneeIds] = useState([])
  const [removedAssigneeIds, setRemovedAssigneeIds] = useState([])
  const [updatedAssigneeIds, setUpdatedAssigneeIds] = useState([])
  const [previousAssigneeNames, setPreviousAssigneeNames] = useState([])

  const[addLeadAssignees] = useAddLeadAssigneesMutation()
  const[addMortgageAssignees] = useAddMortgageAssigneesMutation()

  const handleSetLeadAssignee = (event) => {
    let newAssigneeIds = []
    let removedAssigneeIds = []
    let updatedAssigneeIds = event.target.value
    let previousAssigneeIds = []
    for (let i = 0; i < updatedAssigneeIds.length; i++) {
      let existing = currentAssigneeIds.find(id => id === updatedAssigneeIds[i])
      if (existing) {
        previousAssigneeIds.push(existing)
      } else {
        newAssigneeIds.push(updatedAssigneeIds[i])
      }
    }
    if (previousAssigneeIds.length < currentAssigneeIds.length) {
      for (let i = 0; i < currentAssigneeIds.length; i++) {
        let matching = previousAssigneeIds.find(id => id === currentAssigneeIds[i])
        if (!matching) {
          removedAssigneeIds.push(currentAssigneeIds[i])
        }
      }
    }
    if (newAssigneeIds.length === 0 && removedAssigneeIds.length === 0) {
      setSaveLeadAllowed(false)
    } else {
      setSaveLeadAllowed(true)
    }
    setNewAssigneeIds(newAssigneeIds)
    setRemovedAssigneeIds(removedAssigneeIds)
    setUpdatedAssigneeIds(updatedAssigneeIds)
    if (isSuccess) {
      setIsSuccess(false)
    }
    if (statusLeadEmpty) {
      setStatusLeadEmpty(false)
    }
  }

  const handleAddAssignees = async () => {
    setIsError(false)
    setIsSuccess(false)
    setIsFetching(true)
    let reqObj = {
      userId: userId,
      leadId: leadId,
      mortgageId: mortgageId,
      userFullName: userFullName,
      userNotifications: leadUserNotifications,
      newAssigneeNames: [],
      newAssigneeIds: newAssigneeIds,
      updatedAssigneeNames: [],
      updatedAssigneeIds: updatedAssigneeIds,
      removedAssigneeNames: [],
      removedAssigneeIds: removedAssigneeIds,
      previousAssigneeNames: [],
      previousAssigneeIds: currentAssigneeIds,
    }
    if (newAssigneeIds.length > 0) {
      for (let i = 0; i < newAssigneeIds.length; i++) {
        let matching = teamMembersByName.find(assignee => assignee.memberId === newAssigneeIds[i])
        if (matching) {
          reqObj.newAssigneeNames.push(matching.fullName)
        }
      }
    }
    if (removedAssigneeIds.length > 0) {
      for (let i = 0; i < removedAssigneeIds.length; i++) {
        let matching = teamMembersByName.find(assignee => assignee.memberId === removedAssigneeIds[i])
        if (matching) {
          reqObj.removedAssigneeNames.push(matching.fullName)
        }
      }
    }
    if (currentAssigneeIds.length > 0) {
      for (let i = 0; i < currentAssigneeIds.length; i++) {
        let matching = teamMembersByName.find(assignee => assignee.memberId === currentAssigneeIds[i])
        if (matching) {
          reqObj.previousAssigneeNames.push(matching.fullName)
        }
      }
    }
    if (updatedAssigneeIds.length > 0) {
      for (let i = 0; i < updatedAssigneeIds.length; i++) {
        let matching = teamMembersByName.find(assignee => assignee.memberId === updatedAssigneeIds[i])
        if (matching) {
          reqObj.updatedAssigneeNames.push(matching.fullName)
        }
      }
    }
    let resp = {}
    if (entity === 'mortgage') {
      resp = await addMortgageAssignees(reqObj)
    } else {
      resp = await addLeadAssignees(reqObj)
    }
    if (resp.error) {
      setIsError(true)
    }
    setNewAssigneeIds([])
    setRemovedAssigneeIds([])
    setIsFetching(false)
  }

  useEffect(() => {
    let assigneeNames = []
    if (activeLead) {
      for (let i = 0; i < currentAssigneeIds.length; i++) {
        let assignee = teamMembersByName.find(assignee => assignee.memberId === currentAssigneeIds[i])
        assigneeNames.push(assignee.fullName)
      }
    }
    setSaveLeadAllowed(false)
    setUpdatedAssigneeIds(currentAssigneeIds)
    setPreviousAssigneeNames(assigneeNames)
}, [activeLead, teamMembersByName, currentAssigneeIds])

  return (
    <>
      <FlexWrapper className="fitContentHeight justifyCenter">
        <Typography className="fitContentWidth">
          {previousAssigneeNames.length > 0 ?
            `Currently assigned to the ${entity === 'mortgage' ? 'mortgage' : 'lead'}:`
          :
            <AccentInfo>
              There is currently no one assigned to the {entity === 'mortgage' ? 'mortgage' : 'lead'}
            </AccentInfo>
          }
        </Typography>
          {previousAssigneeNames.map((assignee, index) => (
            <Typography key={assignee} className="fitContentWidth bold" style={{marginLeft: '0.4rem'}}>
              {index === (previousAssigneeNames.length - 1) && previousAssigneeNames.length > 1 ?
                <>
                  <span style={{fontWeight: '400', marginRight: '0.4rem'}}>and</span>
                  {assignee}
                </>
              : index === (previousAssigneeNames.length - 1) || previousAssigneeNames.length === 1 ?
                `${assignee}`
              : previousAssigneeNames.length === 2 ?
                `${assignee}`
              :
                `${assignee},`
              }
            </Typography>
          ))}
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight justifyCenter" style={{margin: '1rem 0 4rem 0'}}>
        <InputWrapper style={{width: '45rem'}}>
          <FlexWrapper>
            <Typography>Assign to {entity === 'mortgage' ? 'Mortgage' : 'Lead'}</Typography>
          </FlexWrapper>
          <AssigneeDropdown
            value={updatedAssigneeIds}
            handleChange={handleSetLeadAssignee}
            items={teamMembersByName}
          />
        </InputWrapper>
        <Button
          onClick={(saveLeadAllowed && addAssigneesPerm) ? () => handleAddAssignees() : null}
          className={(!saveLeadAllowed || !addAssigneesPerm) ? "primaryDisabled notes" : isFetching ? "primaryLoading notes" : "primary notes"}
        >
          {isFetching ?
            <CircularProgress size={18} />
          :
            <SaveIcon />
          }
        </Button>
      </FlexWrapper>
    </>
  )
}

export default AddAssigneesInput