import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    reports: [],
    viewingReports: [],
  },
  status: 'idle',
  error: null
}

export const teamReportsSlice = createSlice({
  name: 'teamReports',
  initialState,
  reducers: {
    setViewingLeadReports(state, action) {
      if (action.payload[0] && !action.payload[0].status) {
        let viewingReports = []
        for (let i = 0; i < action.payload.length; i++) {
          let match = state.data.reports.find(report => report._id === action.payload[i])
          viewingReports.push(match)
        }
        state.data.viewingReports = viewingReports
      } else {
        state.data.viewingReports = action.payload
      }
    },
    setViewingReports(state, action) { 
      if (action.payload[0] && !action.payload[0].status) {
        let viewingReports = []
        for (let i = 0; i < action.payload.length; i++) {
          let match = state.data.reports.find(report => report._id === action.payload[i])
          viewingReports.push(match)
        }
        state.data.viewingReports = viewingReports
      } else {
        state.data.viewingReports = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.reports = payload.data.teamReports
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      state.data.reports.unshift(payload.data.newReport)
      state.data.viewingReports.unshift(payload.data.newReport)
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      payload.data.newReport.notifyUser = true
      state.data.reports.unshift(payload.data.newReport)
      state.data.viewingReports.unshift(payload.data.newReport)
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateReport) {
        let thisReport = state.data.reports.find(report => report._id.toString() === payload.data.newReport._id.toString())
        if (thisReport) {
          let thisIndex = state.data.reports.indexOf(thisReport)
          state.data.reports[thisIndex] = payload.data.newReport
        }
        let thisViewingReport = state.data.viewingReports.find(report => report._id.toString() === payload.data.newReport._id.toString())
        if (thisViewingReport) {
          let thisViewingIndex = state.data.viewingReports.indexOf(thisViewingReport)
          state.data.viewingReports[thisViewingIndex] = payload.data.newReport
        }
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      if (payload.data.reportCreated) {
        state.data.reports.unshift(payload.data.newReport)
        state.data.viewingReports.unshift(payload.data.newReport)
      } else {
        let thisReport = state.data.reports.find(report => report._id === payload.data.newReport._id)
        if (thisReport) {
          let thisIndex = state.data.reports.indexOf(thisReport)
          state.data.reports[thisIndex] = payload.data.newReport
        }
        let thisViewingReport = state.data.viewingReports.find(report => report._id === payload.data.newReport._id)
        if (thisViewingReport) {
          let thisViewingIndex = state.data.viewingReports.indexOf(thisViewingReport)
          state.data.viewingReports[thisViewingIndex] = payload.data.newReport
        }
      }
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.reportIds; i++) {
        let thisReport = state.data.reports.find(report => report._id === payload.data.reportId);
        let thisIndex = state.data.reports.indexOf(thisReport)
        state.data.reports.splice(thisIndex, 1)
      }
    })
  }
})

export const { setViewingReports, setViewingLeadReports } = teamReportsSlice.actions

export default teamReportsSlice.reducer

export const selectAllTeamReports = state => state.teamReports.data.reports
export const selectFirstTeamReport = state => state.teamReports.data.reports[0]
export const selectViewingReports = state => state.teamReports.data.viewingReports