import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    leadGeneration: {
      quarterBreakdown: {},
      oneYear: [],
      twoYear: [],
      all: [],
    },
    renegotiations: {
      quarterBreakdown: {
        previousRenegotiations: 0,
        currentRenegotiations: 0,
        currentProgress: 0,
      },
      all: [],
      twoYear: [],
      oneYear: []
    },
    refinances: {
      quarterBreakdown: {
        previousGross: 0,
        previousRefinances: 0,
        currentGross: 0,
        currentRefinances: 0,
        currentProgress: 0,
      },
      all: [],
      twoYear: [],
      oneYear: []
    },
  },
  status: 'idle',
  error: null
}

export const teamMonthlyStatsSlice = createSlice({
  name: 'teamMonthlyStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.teamMonthlyStats
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      if (payload.data.returnMonthlyStat) {
        if (payload.data.targetOutcome.toUpperCase() === 'RENEGOTIATION') {
          let thisMonthlyStatsAll = state.data.renegotiations.all.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
          let thisMonthlyStatsTwoYear = state.data.renegotiations.twoYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
          let thisMonthlyStatsOneYear = state.data.renegotiations.oneYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
          let thisIndexAll = state.data.renegotiations.all.indexOf(thisMonthlyStatsAll)
          let thisIndexTwoYear = state.data.renegotiations.twoYear.indexOf(thisMonthlyStatsTwoYear)
          let thisIndexOneYear = state.data.renegotiations.oneYear.indexOf(thisMonthlyStatsOneYear)
          if (thisMonthlyStatsAll) {
            state.data.renegotiations.all[thisIndexAll] = payload.data.returnMonthlyStat
          }
          if (thisMonthlyStatsTwoYear) {
            state.data.renegotiations.twoYear[thisIndexTwoYear] = payload.data.returnMonthlyStat
          }
          if (thisMonthlyStatsOneYear) {
            state.data.renegotiations.oneYear[thisIndexOneYear] = payload.data.returnMonthlyStat
          }
        } else {
          let thisMonthlyStatsAll = state.data.refinances.all.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
          let thisMonthlyStatsTwoYear = state.data.refinances.twoYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
          let thisMonthlyStatsOneYear = state.data.refinances.oneYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
          let thisIndexAll = state.data.refinances.all.indexOf(thisMonthlyStatsAll)
          let thisIndexTwoYear = state.data.refinances.twoYear.indexOf(thisMonthlyStatsTwoYear)
          let thisIndexOneYear = state.data.refinances.oneYear.indexOf(thisMonthlyStatsOneYear)
          if (thisMonthlyStatsAll) {
            state.data.refinances.all[thisIndexAll] = payload.data.returnMonthlyStat
          }
          if (thisMonthlyStatsTwoYear) {
            state.data.refinances.twoYear[thisIndexTwoYear] = payload.data.returnMonthlyStat
          }
          if (thisMonthlyStatsOneYear) {
            state.data.refinances.oneYear[thisIndexOneYear] = payload.data.returnMonthlyStat
          }
        }
        let thisMonthlyStatsAll = state.data.leadGeneration.all.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsTwoYear = state.data.leadGeneration.twoYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsOneYear = state.data.leadGeneration.oneYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisIndexAll = state.data.leadGeneration.all.indexOf(thisMonthlyStatsAll)
        let thisIndexTwoYear = state.data.leadGeneration.twoYear.indexOf(thisMonthlyStatsTwoYear)
        let thisIndexOneYear = state.data.leadGeneration.oneYear.indexOf(thisMonthlyStatsOneYear)
        if (thisMonthlyStatsAll) {
          state.data.leadGeneration.all[thisIndexAll] = payload.data.returnMonthlyStat
        }
        if (thisMonthlyStatsTwoYear) {
          state.data.leadGeneration.twoYear[thisIndexTwoYear] = payload.data.returnMonthlyStat
        }
        if (thisMonthlyStatsOneYear) {
          state.data.leadGeneration.oneYear[thisIndexOneYear] = payload.data.returnMonthlyStat
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordUploadPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.newMonthlyStat) {
        let thisMonthlyStatsAll = state.data.leadGeneration.all.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.newMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsTwoYear = state.data.leadGeneration.twoYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.newMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsOneYear = state.data.leadGeneration.oneYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.newMonthlyStat.sessionLabelFull)
        let thisIndexAll = state.data.leadGeneration.all.indexOf(thisMonthlyStatsAll)
        let thisIndexTwoYear = state.data.leadGeneration.twoYear.indexOf(thisMonthlyStatsTwoYear)
        let thisIndexOneYear = state.data.leadGeneration.oneYear.indexOf(thisMonthlyStatsOneYear)
        if (thisMonthlyStatsAll) {
          state.data.leadGeneration.all[thisIndexAll] = payload.data.newMonthlyStat
        }
        if (thisMonthlyStatsTwoYear) {
          state.data.leadGeneration.twoYear[thisIndexTwoYear] = payload.data.newMonthlyStat
        }
        if (thisMonthlyStatsOneYear) {
          state.data.leadGeneration.oneYear[thisIndexOneYear] = payload.data.newMonthlyStat
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordQueryPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.newMonthlyStat) {
        let thisMonthlyStatsAll = state.data.leadGeneration.all.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.newMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsTwoYear = state.data.leadGeneration.twoYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.newMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsOneYear = state.data.leadGeneration.oneYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.newMonthlyStat.sessionLabelFull)
        let thisIndexAll = state.data.leadGeneration.all.indexOf(thisMonthlyStatsAll)
        let thisIndexTwoYear = state.data.leadGeneration.twoYear.indexOf(thisMonthlyStatsTwoYear)
        let thisIndexOneYear = state.data.leadGeneration.oneYear.indexOf(thisMonthlyStatsOneYear)
        if (thisMonthlyStatsAll) {
          state.data.leadGeneration.all[thisIndexAll] = payload.data.newMonthlyStat
        }
        if (thisMonthlyStatsTwoYear) {
          state.data.leadGeneration.twoYear[thisIndexTwoYear] = payload.data.newMonthlyStat
        }
        if (thisMonthlyStatsOneYear) {
          state.data.leadGeneration.oneYear[thisIndexOneYear] = payload.data.newMonthlyStat
        }
      }
    })
    .addMatcher(apiSlice.endpoints.reloadLeadGenerationQuarterlyBreakdown.matchFulfilled, (state, {payload}) => {
      state.data.leadGeneration.quarterBreakdown = payload.data.quarterBreakdown
    })
    .addMatcher(apiSlice.endpoints.reloadRenegotiationQuarterlyBreakdown.matchFulfilled, (state, {payload}) => {
      state.data.renegotiations.quarterBreakdown = payload.data.quarterBreakdown
    })
    .addMatcher(apiSlice.endpoints.reloadRefinanceQuarterlyBreakdown.matchFulfilled, (state, {payload}) => {
      state.data.refinances.quarterBreakdown = payload.data.quarterBreakdown
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      if (payload.data.establishedOutcome === 'renegotiation') {
        let thisMonthlyStatsAll = state.data.renegotiations.all.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsTwoYear = state.data.renegotiations.twoYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsOneYear = state.data.renegotiations.oneYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisIndexAll = state.data.renegotiations.all.indexOf(thisMonthlyStatsAll)
        let thisIndexTwoYear = state.data.renegotiations.twoYear.indexOf(thisMonthlyStatsTwoYear)
        let thisIndexOneYear = state.data.renegotiations.oneYear.indexOf(thisMonthlyStatsOneYear)
        if (thisMonthlyStatsAll) {
          state.data.renegotiations.all[thisIndexAll] = payload.data.returnMonthlyStat
        } else {
          state.data.renegotiations.all.unshift(payload.data.returnMonthlyStat)
        }
        if (thisMonthlyStatsTwoYear) {
          state.data.renegotiations.twoYear[thisIndexTwoYear] = payload.data.returnMonthlyStat
        } else {
          state.data.renegotiations.twoYear.unshift(payload.data.returnMonthlyStat)
        }
        if (thisMonthlyStatsOneYear) {
          state.data.renegotiations.oneYear[thisIndexOneYear] = payload.data.returnMonthlyStat
        } else {
          state.data.renegotiations.oneYear.unshift(payload.data.returnMonthlyStat)
        }
        state.data.renegotiations.quarterBreakdown.currentRenegotiations = state.data.renegotiations.quarterBreakdown.currentRenegotiations + 1
        state.data.renegotiations.quarterBreakdown.currentProgress = (Math.round((((state.data.renegotiations.quarterBreakdown.currentRenegotiations + 1)/state.data.renegotiations.quarterBreakdown.previousRenegotiations) * 100)*10)/10)
      } else {
        let thisMonthlyStatsAll = state.data.refinances.all.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsTwoYear = state.data.refinances.twoYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisMonthlyStatsOneYear = state.data.refinances.oneYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
        let thisIndexAll = state.data.refinances.all.indexOf(thisMonthlyStatsAll)
        let thisIndexTwoYear = state.data.refinances.twoYear.indexOf(thisMonthlyStatsTwoYear)
        let thisIndexOneYear = state.data.refinances.oneYear.indexOf(thisMonthlyStatsOneYear)
        if (thisMonthlyStatsAll) {
          state.data.refinances.all[thisIndexAll] = payload.data.returnMonthlyStat
        } else {
          state.data.refinances.all.unshift(payload.data.returnMonthlyStat)
        }
        if (thisMonthlyStatsTwoYear) {
          state.data.refinances.twoYear[thisIndexTwoYear] = payload.data.returnMonthlyStat
        } else {
          state.data.refinances.twoYear.unshift(payload.data.returnMonthlyStat)
        }
        if (thisMonthlyStatsOneYear) {
          state.data.refinances.oneYear[thisIndexOneYear] = payload.data.returnMonthlyStat
        } else {
          state.data.refinances.oneYear.unshift(payload.data.returnMonthlyStat)
        }
        state.data.refinances.quarterBreakdown.currentGross = (state.data.refinances.quarterBreakdown.closureGross + payload.data.newQuarterBreakdown.currentGross)
        state.data.refinances.quarterBreakdown.currentRefinances = state.data.refinances.quarterBreakdown.currentRefinances + 1
        state.data.refinances.quarterBreakdown.currentProgress = (Math.round(((payload.data.newQuarterBreakdown.currentGross/state.data.refinances.quarterBreakdown.previousGross) * 100)*10)/10)
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      let thisMonthlyStatsAll = state.data.renegotiations.all.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
      let thisMonthlyStatsTwoYear = state.data.renegotiations.twoYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
      let thisMonthlyStatsOneYear = state.data.renegotiations.oneYear.find(monthlyStat => monthlyStat.sessionLabelFull === payload.data.returnMonthlyStat.sessionLabelFull)
      let thisIndexAll = state.data.renegotiations.all.indexOf(thisMonthlyStatsAll)
      let thisIndexTwoYear = state.data.renegotiations.twoYear.indexOf(thisMonthlyStatsTwoYear)
      let thisIndexOneYear = state.data.renegotiations.oneYear.indexOf(thisMonthlyStatsOneYear)
      if (thisMonthlyStatsAll) {
        state.data.renegotiations.all[thisIndexAll] = payload.data.returnMonthlyStat
        state.data.refinances.all[thisIndexAll] = payload.data.returnMonthlyStat
      } else {
        state.data.renegotiations.all.push(payload.data.returnMonthlyStat)
        state.data.refinances.all.push(payload.data.returnMonthlyStat)
      }
      if (thisMonthlyStatsTwoYear) {
        state.data.renegotiations.twoYear[thisIndexTwoYear] = payload.data.returnMonthlyStat
        state.data.refinances.twoYear[thisIndexTwoYear] = payload.data.returnMonthlyStat
      } else {
        state.data.renegotiations.twoYear.push(payload.data.returnMonthlyStat)
        state.data.refinances.twoYear.push(payload.data.returnMonthlyStat)
      }
      if (thisMonthlyStatsOneYear) {
        state.data.renegotiations.oneYear[thisIndexOneYear] = payload.data.returnMonthlyStat
        state.data.refinances.oneYear[thisIndexOneYear] = payload.data.returnMonthlyStat
      } else {
        state.data.renegotiations.oneYear.push(payload.data.returnMonthlyStat)
        state.data.refinances.oneYear.push(payload.data.returnMonthlyStat)
      }
    })
  }
})

export default teamMonthlyStatsSlice.reducer

export const selectLeadGenerationData = state => state.teamMonthlyStats.data.leadGeneration
export const selectTeamRenegotiationQuarterBreakdown = state => state.teamMonthlyStats.data.renegotiations.quarterBreakdown
export const selectTeamRenegotiationMonthlyStatsAll = state => state.teamMonthlyStats.data.renegotiations.all
export const selectTeamRenegotiationMonthlyStatsTwoYear = state => state.teamMonthlyStats.data.renegotiations.twoYear
export const selectTeamRenegotiationMonthlyStatsOneYear = state => state.teamMonthlyStats.data.renegotiations.oneYear
export const selectTeamRefinanceQuarterBreakdown = state => state.teamMonthlyStats.data.refinances.quarterBreakdown
export const selectTeamRefinanceMonthlyStatsAll = state => state.teamMonthlyStats.data.refinances.all
export const selectTeamRefinanceMonthlyStatsTwoYear = state => state.teamMonthlyStats.data.refinances.twoYear
export const selectTeamRefinanceMonthlyStatsOneYear = state => state.teamMonthlyStats.data.refinances.oneYear
