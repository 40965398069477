import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HeaderContainer, ColumnContent, HeaderText, HorizontalContainer, ComponentContainer, VerticalContainer, MainContent, ComponentBorder, FlexWrapper } from "../../../../../StyledComponents";

const TeamStats = ({ teamLeadGenerationStats }) => {
  const theme = useTheme()

  return (
    <ComponentContainer className="justifyStart detailsContainer thinBorders" style={{gridRow: '1', gridColumn: '3', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer>
          <HeaderText>Team Stats</HeaderText>
        </HeaderContainer>
        <HorizontalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Records Swept
            </ColumnContent>
            <ColumnContent>
              <MainContent>
                {teamLeadGenerationStats.totalSuccessfulQueries}
              </MainContent>
            </ColumnContent>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Total Hits
            </ColumnContent>
            <ColumnContent>
              <MainContent>
                {teamLeadGenerationStats.totalHits}
              </MainContent>
            </ColumnContent>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Leads Generated
            </ColumnContent>
            <ColumnContent>
              <MainContent>
                {teamLeadGenerationStats.totalLeadsGenerated}
              </MainContent>
            </ColumnContent>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Hit Frequency
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.palette.common.white}`,
                  backgroundColor: `${theme.palette.common.black}`,
                  fontSize: "1.8rem",
                  margin: '0 0 0 0',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -4],
                  },
                }]}}
                title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem"}}>How often discrepancies are deteced when comparing with public records.</p></div>} 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </ColumnContent>
            <ColumnContent>
              <MainContent>
                {teamLeadGenerationStats.totalHitsAvgPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} %
              </MainContent>
            </ColumnContent>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Total Tier 1
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.palette.common.white}`,
                  backgroundColor: `${theme.palette.common.black}`,
                  fontSize: "1.8rem",
                  margin: '0 0 0 0',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -4],
                  },
                }]}}
                title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem"}}>The value shown in parentheses is the percentage of leads generated that are Tier 1.</p></div>} 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </ColumnContent>
            <ColumnContent>
              <MainContent>
                {teamLeadGenerationStats.totalTier1Leads}
                {teamLeadGenerationStats.totalLeadsGenerated === 0 ?
                  <span style={{margin: '0 0 0 0.5rem', fontWeight: "500"}}>(0%)</span>
                :
                  <span style={{margin: '0 0 0 0.5rem', fontWeight: "500"}}>({(Math.round((teamLeadGenerationStats.totalTier1Leads/teamLeadGenerationStats.totalLeadsGenerated)*100))}%)</span>
                }
              </MainContent>
            </ColumnContent>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Total Tier 2
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.palette.common.white}`,
                  backgroundColor: `${theme.palette.common.black}`,
                  fontSize: "1.8rem",
                  margin: '0 0 0 0',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -4],
                  },
                }]}}
                title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem"}}>The value shown in parentheses is the percentage of leads generated that are Tier 2.</p></div>} 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </ColumnContent>
            <ColumnContent>
              <MainContent>
                {teamLeadGenerationStats.totalTier2Leads}
                {teamLeadGenerationStats.totalLeadsGenerated === 0 ?
                  <span style={{margin: '0 0 0 0.5rem', fontWeight: "500"}}>(0%)</span>
                :
                  <span style={{margin: '0 0 0 0.5rem', fontWeight: "500"}}>({(Math.round((teamLeadGenerationStats.totalTier2Leads/teamLeadGenerationStats.totalLeadsGenerated)*100))}%)</span>
                }
              </MainContent>
            </ColumnContent>
          </VerticalContainer>
        </HorizontalContainer>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default TeamStats;