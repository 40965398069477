import { createTheme, responsiveFontSizes } from "@mui/material";

const lightTheme = createTheme({
  palette: {
    primary: { 
      main: "#00B812",
      mainTransparent: "#00B812BF",
      medium: "#00C914",
      light: "#00DB16",
      lightest: "#00EB17",
      dark: "#00940F",
      darker: "#00820D",
      darkest: "#004D08",
      darkShadow: "#00940FA5",
      transparent: "#008F0E40",
      transparentHover: "#00B81284",
      labelTransparent: "rgb(19, 194, 37, 0.1)",
      pastelDark: "#306B36",
      pastelMedium: "#4CA855",
      pastelLight: "#ADE6B2",
      buttonColor: "#fff",
    },
    secondary: { 
      main: "#B486BF",
      mainTransparent: "#B487C0BF",
      light: "#E0CAE6",
      lightest: "#F1DAF7",
      dark: "#91679C",
      labelTransparent: "#91679C12",
      pastelDark: "#806387",
      pastelMedium: "#A982B3",
      pastelLight: "#DEABEB",
    },
    tertiary: {
      pastelDark: "#556A9E",
      pastelMedium: "#6E88CC",
      pastelLight: "#A0B7F2",
    },
    common: {
      offWhite: '#F2F2F2',
      pageBackground: "#F7F7F7",
      disabledGray: '#EBEBEBE0',
      cardBackground: "#ffffff",
      grayButtonFocus: "#D4D6DB",
      grayButtonFocusLight: "#EDEFF5",
      grayBorder: "#E3E5EB",
      grayBorderLight: "#EAECF2",
      grayBorderMedium: "#D4D6DB",
      grayBorderDark: "#BDBFC4",
      grayText: "#939599",
      grayDark: "#666C78",
      grayOpaque: "#93959921",
      grayTextLight: "#AEB0B5",
      grayHover: `#F2F2F2`,
      lightGray: `#F7F7F7`,
      purpleSelected: '#4B14752B',
      purpleSelectedHover: '#4B147533',
      purpleHover: '#4B147544',
      purpleBackground: '#F4F0F7',
      black: "#1C1C1F",
      blackLight: '#5A595C',
      white: "#fff",
      error: "#c71031",
      errorDark: "#A60D29",
      errorRow: "#EBDBDE",
      errorRowDark: "#EBCCD2",
      highlight: "#FFD54D1E",
      highlightHover: "#FFD54D3F",
      blue: "#01538F",
      red: "#d7372f",
      redTransparent: "#d7372f12",
      redMediumTransparent: "#CC807C",
      grayTransparent: "rgba(215, 220, 223, 0.2)",
      redDark: "#BF3029",
      redBright: "#FF413B",
      orange: "#F89500",
      orangeDark: "#D98200",
      orangeTransparent: "#F8EBD8",
      yellow: "#e9e639",
      activeWhite: "#E6E6E6DB",
      caution: "#F89500",
      cautionDark: "#E68A00",
      cautionDarkest: "#CC7B00",
    },
    logo: {
      teal: "#bbd5d4",
    },
  },
  page: {
    background: {
      primary: "#F7F7F7",
    },
  },
  nav: {
    border: {
      inactive: '#c8cbcf',
      active: '#e0e4e8',
      activeSecondary: '#B486BF',
      activeSide: '#F7F7F7',
    },
    background: {
      hover: '#F2E4F7',
      active: '#F7F7F7',
      inactiveHover: '#4B14752B',
      activeHover: '#c8cbcf',
      activeHoverSecondary: '#EDCDF7',
      requiresAttention: '#00b8125e',
      requiresAttentionSelected: '',
    },
  },
  subNav: {
    text: {
      active: '#1C1C1F',
      inactive: '#939599',
    },
    background: {
      active: '#EED2F7',
      activeHover: '#C0C3C7',
      activeHoverSecondary: '#EBC6F7',
    },
  },
  panel: {
    background: {
      mainSubNavActive: '#4B14752B',
      primary: '#272a2e',
      secondary: '#9da1a8',
      tertiary: '#e1e4e8',
      lightest: '#fff',
    },
    border: {
      primary: '#72757a',
      secondary: '#c8cbcf',
    },
    text: {
      primary: '#fff',
      secondary: '#9da1a8',
    }
  },
  accent: {
    success: {
      primary: '#00940F',
      secondary: '#127A1D',
      primaryFullTransparent: "rgb(19, 194, 37, 0.1)",
    },
    caution: {
      primary: '#CC7B00',
      primaryTransparent: "#F7BE6D",
      primaryFullTransparent: "#FFF0DB",
    },
    error: {
      primary: '#c71031',
      primaryTransparent: '#DBBDC2',
      primaryFullTransparent: '#EBDDDF',
    },
    tertiary: {
      primary: '#bbd4d5',
      secondary: '#14424C',
    },
    quaternary: {
      primary: '#637984',
    },
  },
  //TODO: merge icon with accent
  info: {
    background: {
      caution: '#F7F4F2',
    },
    border: {
      caution: '#cc7b00',
    },
    color: {
      caution: '#D18819',
    }
  },
  text: {
    light: {
      primary: '#F2F2F2',
    },
    nav: {
      main: '#00B812',
      active: '#1C1C1F',
      inactive: '#939599',
      inactiveHover: '#414042',
    },
    primary: {
      primary: '#1C1C1F',
    },
    secondary: {
      primary: '#798393',
    },
    tertiary: {
      primary: '#bbd4d5',
      secondary: '#14424C',
    },
    header: {
      component: '#939599',
    },
    subHeader: {
      primary: '#939599',
      dark: '#1C1C1F',
    },
    required: {
      primary: '#D60E00',
    },
  },
  icon: {
    primary: {
      primary: '#00B812',
      secondary: '#00940F',
    },
    secondary: {
      primary: '#B486BF',
    },
    neutral: {
      primary: '#E3E5EB',
      secondary: '#939599',
      dark: '#1C1C1F',
    },
    tertiary: {
      primary: "#93bfc0",
    },
  },
  closure: {
    refinance: {
      primary: "#00B812",
    },
    renegotiation: {
      primary: "#6e88cc",
      fullOpacity: "#E3EBFF",
    },
    dismissal: {
      primary: '#B486BF',
    }
  },
  tier: {
    one: {
      primary: "#BF3029",
    },
    two: {
      primary: '#CAABD1',
    },
  },
  header: {
    background: {
      primary: '#F7F7F7',
    }
  },
  component: {
    border: {
      primary: '#BDBFC4',
      secondary: '#e3e5eb',
    },
    background: {
      primary: '#F3F3F5',
      secondary: '#F7F6F5',
      tertiary: '#e3e5eb',
    },
    row: {
      even: '#F7F7F7',
      odd: '#ffffff',
    }
  },
  input: {
    border: {
      primary: '#00940F',
    },
    field: {
      populated: '#e7f0fe',
      unpopulated: '#fff',
    }
  },
  report: {
    background: {
      primary: "#FFF",
      secondary: "#F4F4F4",
    },
    border: {
      primary: "#F4F4F4",
    },
  },
  tooltip: {
    label: {      
      color: "#F2F2F2",
      background: "#1C1C1F",
      border: "#AEB0B5",
    },
    info: {
      color: "#1C1C1F",
      background: "#F2F2F2",
      border: "#93bfbf",
    },
    error: {
      color: "#1C1C1F",
      background: "#E0B2AF",
      border: "#BF5650",
    },
    permission: {
      color: "#F2F2F2",
      background: "#475066",
      border: "#556A9E",
    },
    warning: {
      color: "#1C1C1F",
      background: "#F2F2F2",
      border: "#BF5650",
    },
    caution: {
      color: "#CC7B00",
      background: "#FFF0DB",
      border: "#BF5650",
    },
  },
  button: {
    //* usually the background color for focus
    shadow: {
      action: '#D4D6DB',
      actionActive: '#E1E3E8',
      neutralFocus: '#BDBFC4',
      dismissFocus: '#91679C',
      primaryDarkFocus: '#00940F',
      caution: '#CC7B00 ',
    },
    text: {
      caution: '#F5F5F5',
      cautionDisabled: '#DBCDC1',
      primaryDisabled: '#DBCDC1',
      primaryHover: '#FFFCFA',
      action: '#939599',
      actionHover: '#E68A00',
      dismiss: '#F5F5F5',
      dismissDisabled: '#DBCDC1',
      dismissFocus: '#F5F5F5',
      neutral: '#4E525C',
      neutralHover: '#1C1C1F',
      neutralFocus: '#1C1C1F',
      neutralDisabled: '#A8A8B5',
      primary: '#F5F5F5',
      primaryLoading: '#FFFCFA',
      neutralLoading: '#1C1C1F',
    },
    //* Hover: usually slightly darker than background
    border: {
      caution: '#E68A00',
      cautionHover: '#CC7B00',
      primary: '#1FA32C',
      primaryDisabled: '#214D26',
      primaryHover: '#FFFCF2',
      dismiss: '#A37AAD',
      dismissDisabled: '#214D26',
      dismissHover: '#e3e5eb',
      dismissFocus: '#fff',
      primaryLoading: '#FFFCF2',
      primaryDarkHover: '#00B812',
      neutral: '#CFD1D6',
      neutralHover: '#e3e5eb',
      neutralFocus: '#fff',
      neutralDisabled: '#A8A8B5',
      neutralLoading: '#939599',
    },
    background: {
      caution: '#F7F4F2',
      cautionHover: '#E68A00',
      cautionFocus: '#F8AD3C',
      primary: '#1FA32C',
      primaryHover: '#228A2D',
      primaryDisabled: '#214D26',
      secondary: '#BDBFC4',
      secondaryHover: '#919396',
      secondaryLight: '#D8DBE0',
      secondaryLightHover: '#AAACB0',
      dismissDisabled: '#7F498C',
      dismissFocus: '#91679C',
      dismissHover: '#91679C',
      dismiss: '#B486BF',
      action: '#E3E5EB',
      actionActive: '#F1F4FA',
      neutral: '#D4D6DB',
      neutralHover: '#BDBFC4',
      neutralFocus: '#BDBFC4',
      neutralDisabled: '#D7D7DE',
      neutralLoading: '#BDBFC4',
      primaryLoading: '#228A2D',
      tier: '#fff',
    },
  },
  dropdown: {
    text: {
      primary: "#1C1C1F",
    },
    border: {
      primary: "#BDBFC4",
    },
    icon: {
      primary: "#00B812",
    },
    paperBackground: {
      primary: "#fff",
    },
  }
});

export default responsiveFontSizes(lightTheme);
