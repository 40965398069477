import React from "react";
import Undo from "./components/Undo.js"
import String from "./components/String.js"
import DateRecord from "./components/DateRecord.js"
import { useTheme } from "@mui/styles";
import BooleanSwitch from "./components/BooleanSwitch.js"
import BooleanSelect from "./components/BooleanSelect.js"
import { FadeWrapper, FlexWrapper, Typography } from "../../../../../../StyledComponents.js";
import CircularProgress from "@mui/material/CircularProgress";
import DiscrepancySelector from "./components/DiscrepancySelector.js"
import BooleanUndoDiscrepancy from "./components/BooleanUndoDiscrepancy.js"

const EditSwitchboard = ({ recordField, workflowStage, handleWorkFlow, handleSubmitEdit, valueType, record, editIsFetching, dateFormat, rejectedDiscrepancies, leadTier }) => {
  const theme = useTheme()

  return (
    <FadeWrapper style={{gridColumn: '4', gridRow: '2', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      {(workflowStage === 'undo' && rejectedDiscrepancies === 1 && leadTier === 5 && record.discrepancy !== 'provided') &&
        <FlexWrapper className="column alignCenter fitContentHeight" style={{marginBottom: '0.5rem'}}>
          <Typography style={{padding: '0'}}>
            Reverting back will bring this mortgage in line with public records.
          </Typography>        
          <Typography>
            The investigation will be discarded and no reports will be generated.
          </Typography>           
        </FlexWrapper>
      }
      {editIsFetching ?
        <CircularProgress
          sx={{color: `${theme.button.text.primary}`}}
          size={12}
        />
      : workflowStage === 'discrepancy' ?
        <DiscrepancySelector handleWorkFlow={handleWorkFlow} handleSubmitEdit={handleSubmitEdit} valueType={valueType} recordField={recordField} />
      : workflowStage === 'undo' ?
        <Undo handleWorkFlow={handleWorkFlow} record={record} handleSubmitEdit={handleSubmitEdit} />
      : workflowStage === 'custom'
      && valueType === 'date' ?
        <DateRecord handleWorkFlow={handleWorkFlow} record={record} handleSubmitEdit={handleSubmitEdit} dateFormat={dateFormat} />
      : workflowStage === 'custom'
      && valueType !== 'boolean' ?
        <String record={record} handleWorkFlow={handleWorkFlow} valueType={valueType} handleSubmitEdit={handleSubmitEdit} />
      : workflowStage === 'custom'
      && valueType === 'boolean'
      && (record.discrepancy === 'rejected' || record.discrepancy === 'resolved') ?
        <BooleanUndoDiscrepancy handleWorkFlow={handleWorkFlow} handleSubmitEdit={handleSubmitEdit} record={record} />
      : workflowStage === 'custom'
      && valueType === 'boolean'
      && (record.currentValue === 'TRUE' || record.currentValue === 'FALSE' || record.currentValue === 'Y' || record.currentValue === 'N' || record.currentValue === 'LEASEHOLD' || record.currentValue === 'FEESIMPLE') ?
        <BooleanSwitch handleWorkFlow={handleWorkFlow} handleSubmitEdit={handleSubmitEdit} record={record} />
      : workflowStage === 'custom'
      && valueType === 'boolean'
      && record.currentValue === '' ?
        <BooleanSelect handleWorkFlow={handleWorkFlow} handleSubmitEdit={handleSubmitEdit} recordField={recordField} recordLabel={record.label} />
      :
        <></>
      }
    </FadeWrapper>
  )
}

export default EditSwitchboard