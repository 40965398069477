import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    discrepancyParameters: {
      all: [],
    },
    tier1Parameters: [''],
    tier2Parameters: [''],
    tier3Parameters: [''],
  },
  status: 'idle',
  error: null
}

export const teamSweepParametersSlice = createSlice({
  name: 'teamSweepParameters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.sweepParameters
      }
    })
    .addMatcher(apiSlice.endpoints.recordRecordSweep.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.updatedSweepParameters.length; i++) {
        let thisIndex = 0
        let thisParameter = state.data.tier1Parameters.find(parameter => parameter._id === payload.data.updatedSweepParameters[i]._id)
        if (!thisParameter) {
          thisParameter = state.data.tier2Parameters.find(parameter => parameter._id === payload.data.updatedSweepParameters[i]._id)
          if (!thisParameter) {
            thisParameter = state.data.tier3Parameters.find(parameter => parameter._id === payload.data.updatedSweepParameters[i]._id)
            thisIndex = state.data.tier3Parameters.indexOf(thisParameter)
            state.data.tier3Parameters[thisIndex] = payload.data.updatedSweepParameters[i]
          } else {
            thisIndex = state.data.tier2Parameters.indexOf(thisParameter)
            state.data.tier2Parameters[thisIndex] = payload.data.updatedSweepParameters[i]
          }
        } else {
          thisIndex = state.data.tier1Parameters.indexOf(thisParameter)
          state.data.tier1Parameters[thisIndex] = payload.data.updatedSweepParameters[i]
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateSweepParamterSettings.matchFulfilled, (state, {payload}) => {
      let thisIndex = 0
      let thisParameter = state.data.tier1Parameters.find(parameter => parameter._id === payload.data.parameterId)
      if (!thisParameter) {
        thisParameter = state.data.tier2Parameters.find(parameter => parameter._id === payload.data.parameterId)
        if (!thisParameter) {
          thisParameter = state.data.tier3Parameters.find(parameter => parameter._id === payload.data.parameterId)
          thisIndex = state.data.tier3Parameters.indexOf(thisParameter)
          thisParameter.active = payload.data.newParameter.active
          thisParameter.assignedTier = payload.data.newParameter.assignedTier
        } else {
          thisIndex = state.data.tier2Parameters.indexOf(thisParameter)
          thisParameter.active = payload.data.newParameter.active
          thisParameter.assignedTier = payload.data.newParameter.assignedTier
        }
      } else {
        thisIndex = state.data.tier1Parameters.indexOf(thisParameter)
        thisParameter.active = payload.data.newParameter.active
        thisParameter.assignedTier = payload.data.newParameter.assignedTier
      }
      if (payload.data.tierChange) {
        if (payload.data.originalTier === 'one' && payload.data.newParameter.assignedTier === 'two') {
          state.data.tier1Parameters.splice(thisIndex, 1)
          state.data.tier2Parameters.push(thisParameter)
        } else if (payload.data.originalTier === 'one' && payload.data.newParameter.assignedTier === 'three') {
          state.data.tier1Parameters.splice(thisIndex, 1)
          state.data.tier3Parameters.push(thisParameter)
        } else if (payload.data.originalTier === 'two' && payload.data.newParameter.assignedTier === 'one') {
          state.data.tier2Parameters.splice(thisIndex, 1)
          state.data.tier1Parameters.push(thisParameter)
        } else if (payload.data.originalTier === 'two' && payload.data.newParameter.assignedTier === 'three') {
          state.data.tier2Parameters.splice(thisIndex, 1)
          state.data.tier3Parameters.push(thisParameter)
        } else if (payload.data.originalTier === 'three' && payload.data.newParameter.assignedTier === 'one') {
          state.data.tier3Parameters.splice(thisIndex, 1)
          state.data.tier1Parameters.push(thisParameter)
        } else if (payload.data.originalTier === 'three' && payload.data.newParameter.assignedTier === 'two') {
          state.data.tier3Parameters.splice(thisIndex, 1)
          state.data.tier2Parameters.push(thisParameter)

        }
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      for (let i = 0; i < payload.data.newParameterStats.length; i++) {
        let thisParameter = state.data.tier1Parameters.find(parameter => parameter.apiMapping === payload.data.newParameterStats[i].apiMapping)
        if (!thisParameter) {
          thisParameter = state.data.tier2Parameters.find(parameter => parameter.apiMapping === payload.data.newParameterStats[i].apiMapping)
          if (!thisParameter) {
            thisParameter = state.data.tier3Parameters.find(parameter => parameter.apiMapping === payload.data.newParameterStats[i].apiMapping)
            let thisIndex = state.data.tier3Parameters.indexOf(thisParameter)
            state.data.tier3Parameters[thisIndex] = payload.data.newParameterStats[i]
          } else {
            let thisIndex = state.data.tier2Parameters.indexOf(thisParameter)
            state.data.tier2Parameters[thisIndex] = payload.data.newParameterStats[i]
          }
        } else {
          let thisIndex = state.data.tier1Parameters.indexOf(thisParameter)
          state.data.tier1Parameters[thisIndex] = payload.data.newParameterStats[i]
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.newParameterStats.length; i++) {
        let thisParameter = state.data.tier1Parameters.find(parameter => parameter.apiMapping === payload.data.newParameterStats[i].apiMapping)
        if (!thisParameter) {
          thisParameter = state.data.tier2Parameters.find(parameter => parameter.apiMapping === payload.data.newParameterStats[i].apiMapping)
          if (!thisParameter) {
            thisParameter = state.data.tier3Parameters.find(parameter => parameter.apiMapping === payload.data.newParameterStats[i].apiMapping)
            let thisIndex = state.data.tier3Parameters.indexOf(thisParameter)
            state.data.tier3Parameters[thisIndex] = payload.data.newParameterStats[i]
          } else {
            let thisIndex = state.data.tier2Parameters.indexOf(thisParameter)
            state.data.tier2Parameters[thisIndex] = payload.data.newParameterStats[i]
          }
        } else {
          let thisIndex = state.data.tier1Parameters.indexOf(thisParameter)
          state.data.tier1Parameters[thisIndex] = payload.data.newParameterStats[i]
        }
      }
    })
  }
})

export default teamSweepParametersSlice.reducer

export const selectTier1Parameters = state => state.teamSweepParameters.data.tier1Parameters
export const selectTier2Parameters = state => state.teamSweepParameters.data.tier2Parameters
export const selectTier3Parameters = state => state.teamSweepParameters.data.tier3Parameters
export const selectDiscrepnacyParametersAll = state => state.teamSweepParameters.data.discrepancyParameters.all
export const selectDiscrepnacyParametersRenegotiations = state => state.teamSweepParameters.data.discrepancyParameters.renegotiations
export const selectDiscrepnacyParametersRefinances = state => state.teamSweepParameters.data.discrepancyParameters.refinances
export const selectDiscrepnacyParametersDismissals = state => state.teamSweepParameters.data.discrepancyParameters.dismissals