import React, { PureComponent } from 'react';
import { useTheme } from "@mui/styles";
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  const theme = useTheme();
  
  if (active && payload && payload.length) {
    return (
      <div style={{background: `${theme.palette.common.pageBackground}`, width: '20rem', border: `0.1rem solid ${theme.palette.common.grayBorder}`, padding: '1rem', borderRadius: '1rem'}}>
        <div style={{margin: "0 auto 1rem auto", display: "flex", justifyContent: "space-between", fontWeight: "600", width: 'fit-content'}}>
          {`${payload[0].payload.name}`}
        </div>
        <div style={{margin: "0 auto", display: "flex", justifyContent: "space-between", fontWeight: "600", width: '10rem'}}>
          {`Total Closures: `}
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].value}`}
          </span>
        </div>
        <div style={{margin: "0 auto", display: "flex", justifyContent: "space-between", width: '10rem'}}>
          {`Renegotiations: `}
          <span style={{color: `${theme.palette.common.black}`, fontWeight: "600"}}>
            {`${payload[0].payload.renegotiations}`}
          </span>
        </div>
        <div style={{margin: "0 auto", display: "flex", justifyContent: "space-between", width: '10rem'}}>
          {`Refinances: `}
          <span style={{color: `${theme.palette.common.black}`, fontWeight: "600"}}>
            {`${payload[0].payload.refinances}`}
          </span>
        </div>
        <div style={{margin: "0 auto", display: "flex", justifyContent: "space-between", width: '10rem'}}>
          {`Dismissals: `}
          <span style={{color: `${theme.palette.common.black}`, fontWeight: "600"}}>
            {`${payload[0].payload.dismissals}`}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

const COLORS = ['#E38A86', '#CAABD1'];

class CustomizedContent extends PureComponent {
  render() {
    const { root, depth, x, y, width, height, index, payload, colors, rank, name } = this.props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: depth < 2 ? colors[Math.floor((index / root.children.length) * 2)] : '#ffffff00',
            stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {depth === 1 ? (
          <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
            {name}
          </text>
        ) : null}
        {depth === 1 ? (
          <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
            {index + 1}
          </text>
        ) : null}
      </g>
    );
  }
}


export default class ParamtersAllChart extends PureComponent {

  render() {
    return (
      <ResponsiveContainer width="96%" height="99%">
        <Treemap width={400} height={200} data={this.props.data} dataKey="size" aspectRatio={4 / 3} stroke="#fff" fill="#8884d8"
          content={<CustomizedContent colors={COLORS} />}
        >
          <Tooltip content={<CustomTooltip/>} cursor={{fill: `#93959921`}} />
        </Treemap>
      </ResponsiveContainer>
    );
  }
}
