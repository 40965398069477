import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HeaderContainer, ComponentContainer, ComponentBorder, Typography, GridWrapper, StatsRow, ScrollWindow, FlexWrapper } from "../../../../../StyledComponents";

const SelectedMemberStats = ({selectedMemberInfoObj, selectedMemberFirstName}) => {
  const theme = useTheme()

  return (
    <ComponentContainer className="justifyStart detailsContainer thinBorders" style={{gridRow: '1/3', gridColumn: '3', height: '100%'}}>
      <ScrollWindow className="horizontalOnly">
        <GridWrapper style={{gridTemplateRows: '2.5rem 2.5rem 2.5rem', gridTemplateColumns: '1fr', minWidth: '91.2rem'}}>
          <HeaderContainer style={{gridRow: '1', gridColumn: '1/8'}}>
            <Typography className="componentHeader">{selectedMemberFirstName}'s Closure Stats</Typography>
          </HeaderContainer>
          <StatsRow className="member" style={{gridRow: '2'}}>
            <Typography className="justifyCenter" style={{gridColumn: '1'}}>Renegotiations</Typography>
            <Typography className="justifyCenter" style={{gridColumn: '2'}}>
              Renegotiation Frequency
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.2rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                      Average day's between this member closing a renegotiation.
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </Typography>
            <Typography className="justifyCenter" style={{gridColumn: '3'}}>Refinances</Typography>
            <Typography className="justifyCenter" style={{gridColumn: '4'}}>
              Refinance Frequency
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.2rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                      Average day's between this member closing a refinance.
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </Typography>
            <Typography className="justifyCenter" style={{gridColumn: '5'}}>
              Gross Profit
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.2rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                      Value in parentheses is this member's average profit generated by all refinances.
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </Typography>
            <Typography className="justifyCenter" style={{gridColumn: '6'}}>
              Gross Revenue Increase
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.2rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                      Value in parentheses is this member's average revenue increase generated by all refinances.
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </Typography>
          </StatsRow>
          <GridWrapper className="sixColumn" style={{gridRow: '3'}}>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '1', fontSize: '1.2rem'}}>{selectedMemberInfoObj.closedRenegotiations}</Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '2', fontSize: '1.2rem'}}>
              {selectedMemberInfoObj.renegotiationFrequency}
              <span style={{color: `${theme.palette.common.grayText}`, fontWeight: '500', marginLeft: '0.4rem'}}>days</span>
            </Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '3', fontSize: '1.2rem'}}>{selectedMemberInfoObj.closedRefinances}</Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '4', fontSize: '1.2rem'}}>
              {selectedMemberInfoObj.refinanceFrequency}
              <span style={{color: `${theme.palette.common.grayText}`, fontWeight: '500', marginLeft: '0.4rem'}}>days</span>
            </Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem'}}>
              <span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', marginRight: '0.2rem', fontSize: '1.2rem'}}>$</span>
              {selectedMemberInfoObj.grossProfitNumber ?
                selectedMemberInfoObj.grossProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})
              :
                selectedMemberInfoObj.grossProfitNumber
              }
            </Typography>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '6', fontSize: '1.2rem'}}>
              {selectedMemberInfoObj.grossProfitPercent ?
                selectedMemberInfoObj.grossProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})
              :
                selectedMemberInfoObj.grossProfitPercent
              }
              <span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', marginLeft: '0.2rem', fontSize: '1.2rem'}}>%</span>
            </Typography>
          </GridWrapper>
        </GridWrapper>
      </ScrollWindow>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default SelectedMemberStats