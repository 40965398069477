import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";
import { AccentInfo } from "../../StyledComponents";
import { dropdownStyles, dropdownMenuProps } from "../../componentThemes";

const MortgageTagDropdown = ({ value, handleChange, items, disabled }) => {
  const theme = useTheme()
  const dropdown = dropdownStyles(theme)

  return (
    <FormControl className={dropdown.formControl} style={{width: "100%"}}>
      <Select
        disabled={disabled}
        value={value}
        onChange={handleChange}
        MenuProps={dropdownMenuProps}
        classes={{
          select: dropdown.select,
          icon: dropdown.selectIcon
        }}
      >
        {items.length > 0 ?
          items.map((tag) => (
            <MenuItem
              key={tag._id}
              disabled={value === tag._id}
              value={tag._id}
            >
              {tag.label}
            </MenuItem>
          ))
        :
          <MenuItem disabled>
            <AccentInfo>
              no tags to assign
            </AccentInfo>
          </MenuItem>
        }
      </Select>
    </FormControl>
  )
}

export default MortgageTagDropdown;
