import React from "react";
import ListIcon from '@mui/icons-material/List';
import { useTheme } from "@mui/styles";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const ConfigurationGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Configuration" component
      </GuideText>
      <GuideText className="medium">
        provides controls to customize and improve the system's performance over time.
      </GuideText>
      <GuideText>
        Metrics are recorded to provide a better understanding of how it can be fine-tuned.
      </GuideText>
      <GuideText>
        The system utilizes two distinct approaches that are available for customization.
      </GuideText>
      
      <Divider className="narrow" />
      
      <FlexWrapper className="alignCenter">
        <ListIcon style={{height: '1.8rem', width: '1.8rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 0'}} />
        <GuideText className="header">
          Mortgage Fields
        </GuideText>
      </FlexWrapper>
      <GuideText className="sub">
        158 pieces of information are used to track each mortgage.
      </GuideText>
      <GuideText>
        Each of these fields are used to detect discrepancies in the Public Records.
      </GuideText>
      <GuideText className="medium bold newParagraph halfIndent" >
        Customization
      </GuideText>
      <GuideText className="halfIndent">
        By default, each field is "active", meaning they will be utilized in the scan of public records.
      </GuideText>
      <GuideText className="halfIndent">
        Each one is assigned a "Tier", indicating to your team the liklihood that a contractural violation was discovered.
      </GuideText>
      <GuideText className="halfIndent">
        The following metrics are tracked for each field:
      </GuideText>
      <FlexWrapper>
        <GuideText className="bold newParagraph oneIndent left">
          * Discrepancy Frequency:
        </GuideText>
        <GuideText className="newParagraph">
          How often this field presents a discrepancy between public and internal records. 
        </GuideText>
      </FlexWrapper>
      <FlexWrapper>
        <GuideText className="bold newParagraph oneIndent left">
          * Outcome Frequency:
        </GuideText>
        <GuideText className="newParagraph">
          How often a discrepancy in this field leads to a renegotiaion, refinance, or dismissal.
        </GuideText>
      </FlexWrapper>
      <GuideText className="newParagraph halfIndent">
        When functioning ideally, the system will only notify your team of situations that lead to desireable outcomes, and ignore all others.
      </GuideText>
      <GuideText className="halfIndent" >
        Turn a field off if a discrepancy in it rarely signals a situation that requires a renegotiation or refinance. 
      </GuideText>
      <GuideText className="halfIndent">
        Conversely, heighten its tier assignment if it frequently represents scenarios that require attention.
      </GuideText>

      <Divider className="narrow" />

      <FlexWrapper className="alignCenter">
        <LocalOfferIcon style={{height: '1.8rem', width: '1.8rem', color: `${theme.palette.primary.main}`, margin: '0 0.5rem 0 0'}} />
        <GuideText className="header">
          Violation Tags
        </GuideText>
      </FlexWrapper>
      <GuideText className="sub">
        When the system discovers a potential violation, it indicates the likely nature to your team, if one is present.
      </GuideText>
      <GuideText>
        Any field can be assigned any number of tags.
      </GuideText>
      <GuideText>
        These "Violation Tags" assist your team in sorting, organizing, and easily identifing investigations currently saved in the system.
      </GuideText>
      <GuideText className="medium bold newParagraph halfIndent" >
        Customization
      </GuideText>
      <GuideText className="halfIndent">
        Any of these tags can be assigned or removed from a field.
      </GuideText>
      <GuideText className="halfIndent">
        Only tags assigned to "active" fields can be applied an investigation.
      </GuideText>
      <GuideText className="halfIndent">
        The following metrics are tracked for each tag:
      </GuideText>
      <FlexWrapper>
        <GuideText className="bold newParagraph oneIndent left">
          * Outcome Frequency:
        </GuideText>
        <GuideText className="newParagraph">
          How often an investigation with this tag results in a renegotiaion, refinance, or dismissal.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper>
        <GuideText className="bold newParagraph oneIndent left" style={{minWidth: '18.5rem'}}>
          * Prediction Accuracy:
        </GuideText>
        <FlexWrapper className="column" style={{marginTop: "1.1rem"}}>
          <GuideText className="">
            When closing an investigation, users will confirm the real-world scenario that was responsible for the violation.
          </GuideText>
          <GuideText className="">
            Each field is updated to reflect whether the tag(s) they applied accurately predicted the scenario.
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>
      <GuideText className="newParagraph halfIndent">
        If a certain tag applied by a field rarely reflects a violation, false alarms can be avoided by setting the field to "inactive".
      </GuideText>
      <GuideText className="halfIndent bottom">
        Retain all tags that reliably indicate the correct violating scenario. 
      </GuideText>
    </FlexWrapper>
  )
}

export default ConfigurationGuide