import { apiSlice } from './slices/api/apiSlice'
import { combineReducers } from '@reduxjs/toolkit'
import userInfoReducer from './slices/userInfoSlice'
import teamStatsReducer from './slices/teamStatsSlice'
import viewingLeadReducer from './slices/viewingLeadSlice'
import sessionDataReducer from './slices/sessionDataSlice'
import teamReportsReducer from './slices/teamReportsSlice'
import teamMembersReducer from './slices/teamMembersSlice'
import teamClosuresReducer from './slices/teamClosuresSlice'
import teamAdminDataReducer from './slices/teamAdminDataSlice'
import teamMortgagesReducer from './slices/teamMortgagesSlice'
import mortgageTargetsReducer from './slices/mortgageTargetsSlice'
import teamActiveLeadsReducer from './slices/teamActiveLeadsSlice'
import viewingMortgageReducer from './slices/viewingMortgageSlice'
import membersDataArrayReducer from './slices/membersDataArraySlice'
import teamMonthlyStatsReducer from './slices/teamMonthlyStatsSlice'
import userNotificationReducer from './slices/userNotificationsSlice'
import mortgageDataModelReducer from './slices/mortgageDataModelSlice'
import memberAssignmentsReducer from './slices/memberAssignmentsSlice'
import teamAndUserSettingsReducer from './slices/teamAndUserSettingsSlice'
import teamSweepParametersReducer from './slices/teamSweepParametersSlice'
import functionAvailabilityReducer from './slices/functionAvailabilitySlice'
import viewingSweepParameterReducer from './slices/viewingSweepParameterSlice'
import userActionsPermissionsReducer from './slices/userActionsPermissionsSlice'

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  teamStats: teamStatsReducer,
  sessionData: sessionDataReducer,
  teamReports: teamReportsReducer,
  viewingLead: viewingLeadReducer,
  teamMembers: teamMembersReducer,
  teamClosures: teamClosuresReducer,
  teamAdminData: teamAdminDataReducer,
  teamMortgages: teamMortgagesReducer,
  teamActiveLeads: teamActiveLeadsReducer,
  mortgageTargets: mortgageTargetsReducer,
  viewingMortgage: viewingMortgageReducer,
  teamMonthlyStats: teamMonthlyStatsReducer,
  membersDataArray: membersDataArrayReducer,
  userNotifications: userNotificationReducer,
  mortgageDataModel: mortgageDataModelReducer,
  memberAssignments: memberAssignmentsReducer,
  teamAndUserSettings: teamAndUserSettingsReducer,
  teamSweepParameters: teamSweepParametersReducer,
  functionAvailability: functionAvailabilityReducer,
  viewingSweepParameter: viewingSweepParameterReducer,
  userActionsPermissions: userActionsPermissionsReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

export default rootReducer;