import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    propertySearchResponse: {
      dataPresent: false,
      recordDetailsObj: null,
      AttomQueryAddress: null,
      CoreLogicQueryAddress: null,
      PropMixQueryAddress: null,
      propMixSuccessDate: null,
      attomSuccessDate: null,
      coreLogicSuccessDate: null,
      existingInitialSweeps: 0,
      rejectionsAttomDataExpected: 0,
      rejectionsCoreLogicDataExpected: 0,
      rejectionsAttomAndCoreLogicDataExpected: 0,
      rejectionsAttomDataNew: 0,
      rejectionsCoreLogicDataNew: 0,
      rejectionsAttomAndCoreLogicDataNew: 0,
    },
    propertySearch: {
      saveEnabled: true,
    },
    modalDisplays: {
      welcome: false,
      leadActions: false,
    },
    currentUserActionsRole: '',
    repositoryPageSize: 20,
    repositoryPage: 0,
    duplicates: [{}, []],
    auth: {
      ipError: false,
      appError: false,
      ipAcquired: false,
      userHistory: null,
      launchRequired: false,
      userAuthenticated: false,
      superAuthenticated: false,
      excludeMobileVisitor: false,
    },
    navSelection: {
      admin: 'uploads',
      guide: 'leadOverview',
      dashboard: 'guide',
      leadActions: 'compareRecords',
      uploadReport: '',
      mortgageActions: '',
      databaseActions: '',
      adminRepository: '',
    },
    theme: 'light',
    dashPerspective: 'team',
    dashMemberFullName: '',
    pageSelection: '',
    runningSweep: false,
    appLoaded: false,
    viewingLeadActions: false,
    viewingMortgageActions: false,
  },
  status: 'idle',
  error: null
}

export const sessionDataSlice = createSlice({
  name: 'sessionData',
  initialState,
  reducers: {
    setPropertySearchSaveEnabled(state, action) { state.data.propertySearch.saveEnabled = action.payload },
    setCurrentUserActionsRole(state, action) { state.data.currentUserActionsRole = action.payload },
    setExcludeMobileVisitor(state, action) { state.data.auth.excludeMobileVisitor = action.payload },
    setShowLeadActionsModal(state, action) { state.data.modalDisplays.leadActions = action.payload },
    setRepositoryPageSize(state, action) { state.data.repositoryPageSize = action.payload },
    setSuperAuthenticated(state, action) { state.data.auth.superAuthenticated = action.payload },
    setUserAuthenticated(state, action) { state.data.auth.userAuthenticated = action.payload },
    setShowWelcomeModal(state, action) { state.data.modalDisplays.welcome = action.payload },
    setLaunchRequired(state, action) { state.data.auth.launchRequired = action.payload },
    setRepositoryPage(state, action) { state.data.repositoryPage = action.payload },
    setIPAcquired(state, action) { state.data.auth.ipAcquired = action.payload },
    setAppError(state, action) { state.data.auth.appError = action.payload },
    setIPError(state, action) { state.data.auth.ipError = action.payload },
    setDashPerspective(state, action) { state.data.selected = action.payload },
    setDashUserFullName(state, action) { state.data.fullName = action.payload },
    setViewingLeadActions(state, action) { state.data.viewingLeadActions = action.payload },
    setViewingMortgageActions(state, action) { state.data.viewingMortgageActions = action.payload },
    setMortgageActionNavMenuSelection(state, action) { state.data.navSelection.mortgageActions = action.payload },
    setAdminNavSelection(state, action) { state.data.navSelection.admin = action.payload },
    setPageSelection(state, action) { state.data.pageSelection = action.payload },
    setNavSelection(state, action) { state.data.navSelection.dashboard = action.payload },
    setAppLoaded(state, action) {state.data.appLoaded = action.payload},
    setRunningSweep(state, action) {state.data.runningSweep = action.payload},
    setUploadReportNavMenuSelection(state, action) { state.data.navSelection.uploadReport = action.payload },
    setGuideNavSelection(state, action) { state.data.navSelection.guide = action.payload },
    setLeadActionNavMenuSelection(state, action) { state.data.navSelection.leadActions = action.payload },
    setDatabaseActionsNavMenuSelection(state, action) { state.data.navSelection.databaseActions = action.payload },
    setAdminRepositoryNavMenuSelection(state, action) { state.data.navSelection.adminRepository = action.payload },
    setExistingInitialSweeps(state, action) { state.data.propertySearchResponse.existingInitialSweeps = action.payload },
    setRejectionsAttomDataExpected(state, action) { state.data.propertySearchResponse.rejectionsAttomDataExpected = action.payload },
    setRejectionsCoreLogicDataExpected(state, action) { state.data.propertySearchResponse.rejectionsCoreLogicDataExpected = action.payload },
    setRejectionsAttomAndCoreLogicDataExpected(state, action) { state.data.propertySearchResponse.rejectionsAttomAndCoreLogicDataExpected = action.payload },
    setRejectionsAttomDataNew(state, action) { state.data.propertySearchResponse.rejectionsAttomDataNew = action.payload },
    setRejectionsCoreLogicDataNew(state, action) { state.data.propertySearchResponse.rejectionsCoreLogicDataNew = action.payload },
    setRejectionsAttomAndCoreLogicDataNew(state, action) { state.data.propertySearchResponse.rejectionsAttomAndCoreLogicDataNew = action.payload },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.loginReturningUser.matchFulfilled, (state, {payload}) => {
      if (payload.message.includes('successful')) {
        if (payload.data.userInfo.role === 'super') {
          state.data.auth.superAuthenticated = true
        } else {
          state.data.modalDisplays.welcome = true
        }
        state.data.auth.userAuthenticated = true
      }
    })
    .addMatcher(apiSlice.endpoints.establishRequestCredentials.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.auth.userHistory = payload.data.userHistory
      }
    })
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.currentUserActionsRole = payload.data.currentUserActionsRole
        if (payload.data.missingUser) {
          state.data.appLoaded = false
        } else {
          state.data.appLoaded = true
        }
      }
    })
    .addMatcher(apiSlice.endpoints.checkForMortgageDuplicates.matchFulfilled, (state, {payload}) => {
      state.data.duplicates = payload.data.duplicates
    })
    .addMatcher(apiSlice.endpoints.runPropertySearch.matchFulfilled, (state, {payload}) => {
      if (payload.data.recordDetailsObj) {
        state.data.propertySearchResponse.dataPresent = true
        state.data.propertySearchResponse.recordDetailsObj = payload.data.recordDetailsObj
        state.data.propertySearchResponse.AttomQueryAddress = payload.data.AttomQueryAddress
        state.data.propertySearchResponse.CoreLogicQueryAddress = payload.data.CoreLogicQueryAddress
        state.data.propertySearchResponse.PropMixQueryAddress = payload.data.PropMixQueryAddress
        state.data.propertySearchResponse.propMixSuccessDate = payload.data.propMixSuccessDate
        state.data.propertySearchResponse.attomSuccessDate = payload.data.attomSuccessDate
        state.data.propertySearchResponse.coreLogicSuccessDate = payload.data.coreLogicSuccessDate
      }
    })
  }
})

export const { setShowWelcomeModal, setCurrentUserActionsRole, setRepositoryPageSize, setRepositoryPage, setPropertySearchSaveEnabled, setLaunchRequired, setUserAuthenticated, setSuperAuthenticated, setIPError, setAppError, setIPAcquired, setDashPerspective, setDashUserFullName, setAdminNavSelection, setPageSelection, setNavSelection, setAppLoaded, setRunningSweep, setGuideNavSelection, setLeadActionNavMenuSelection, setMortgageActionNavMenuSelection, setUploadReportNavMenuSelection, setDatabaseActionsNavMenuSelection, setAdminRepositoryNavMenuSelection, setExcludeMobileVisitor, setExistingInitialSweeps, setRejectionsAttomDataExpected, setRejectionsCoreLogicDataExpected, setRejectionsAttomAndCoreLogicDataExpected, setRejectionsAttomDataNew, setRejectionsCoreLogicDataNew, setRejectionsAttomAndCoreLogicDataNew, setViewingLeadActions, setViewingMortgageActions, setShowLeadActionsModal } = sessionDataSlice.actions

export default sessionDataSlice.reducer

export const selectTheme = state => state.sessionData.data.theme
export const selectIPError = state => state.sessionData.data.auth.setIPError
export const selectAppError = state => state.sessionData.data.auth.appError
export const selectAppLoaded = state => state.sessionData.data.appLoaded
export const selectIPAcquired = state => state.sessionData.data.auth.ipAcquired
export const selectUserHistory = state => state.sessionData.data.auth.userHistory
export const selectRunningSweep = state => state.sessionData.data.runningSweep
export const selectNavSelection = state => state.sessionData.data.navSelection.dashboard
export const selectPageSelection = state => state.sessionData.data.pageSelection
export const selectLaunchRequired = state => state.sessionData.data.auth.launchRequired
export const selectRepositoryPage = state => state.sessionData.data.repositoryPage
export const selectDashPerspective = state => state.sessionData.data.dashPerspective
export const selectDashUserFullName = state => state.sessionData.data.dashMemberFullName
export const selectShowWelcomeModal = state => state.sessionData.data.modalDisplays.welcome
export const selectUserAuthenticated = state => state.sessionData.data.auth.userAuthenticated
export const selectGuideNavSelection = state => state.sessionData.data.navSelection.guide
export const selectAdminNavSelection = state => state.sessionData.data.navSelection.admin
export const selectViewingLeadActions = state => state.sessionData.data.viewingLeadActions
export const selectRepositoryPageSize = state => state.sessionData.data.repositoryPageSize
export const selectSuperAuthenticated = state => state.sessionData.data.auth.superAuthenticated
export const selectExcludeMobileVisitor = state => state.sessionData.data.auth.excludeMobileVisitor
export const selectShowLeadActionsModal = state => state.sessionData.data.modalDisplays.leadActions
export const selectExistingInitialSweeps = state => state.sessionData.data.propertySearchResponse.existingInitialSweeps
export const selectViewingMortgageActions = state => state.sessionData.data.viewingMortgageActions
export const selectDuplicateCheckResponse = state => state.sessionData.data.duplicates
export const selectCurrentUserActionsRole = state => state.sessionData.data.currentUserActionsRole
export const selectPropertySearchResponse = state => state.sessionData.data.propertySearchResponse
export const selectRejectionsAttomDataNew = state => state.sessionData.data.propertySearchResponse.rejectionsAttomDataNew
export const selectLeadActionsNavSelection = state => state.sessionData.data.navSelection.leadActions
export const selectUploadReportNavSelection = state => state.sessionData.data.navSelection.uploadReport
export const selectPropertySearchSaveEnabled = state => state.sessionData.data.propertySearch.saveEnabled
export const selectRejectionsCoreLogicDataNew = state => state.sessionData.data.propertySearchResponse.rejectionsCoreLogicDataNew
export const selectAdminRepositoryNavSelection = state => state.sessionData.data.propertySearch.adminRepository
export const selectDatabaseActionsNavSelection = state => state.sessionData.data.navSelection.databaseActions
export const selectMortgageActionsNavSelection = state => state.sessionData.data.navSelection.mortgageActions
export const selectRejectionsAttomDataExpected = state => state.sessionData.data.propertySearchResponse.rejectionsAttomDataExpected
export const selectRejectionsCoreLogicDataExpected = state => state.sessionData.data.propertySearchResponse.rejectionsCoreLogicDataExpected
export const selectRejectionsAttomAndCoreLogicDataNew = state => state.sessionData.data.propertySearchResponse.rejectionsAttomAndCoreLogicDataNew
export const selectRejectionsAttomAndCoreLogicDataExpected = state => state.sessionData.data.propertySearchResponse.rejectionsAttomAndCoreLogicDataExpected

