import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/styles";
import { dropdownStyles, dropdownMenuProps } from "../../componentThemes";

const UserRoleDropdown = ({ value, handleChange }) => {
  const theme = useTheme()
  const dropdown = dropdownStyles(theme)

  return (
    <FormControl className={dropdown.formControl}>
      <Select
        value={value}
        onChange={handleChange}
        MenuProps={dropdownMenuProps}
        classes={{
          select: dropdown.select,
          icon: dropdown.selectIcon
        }}
      >
        <MenuItem
          muiskiplisthighlight='true'
          key={'super'}
          disabled={value === "super"}
          value={"super"}
        >
          Super
        </MenuItem>
        <MenuItem
          muiskiplisthighlight='true'
          key={'admin'}
          disabled={value === "admin"}
          value={"admin"}
        >
          Admin
        </MenuItem>
        <MenuItem
          muiskiplisthighlight='true'
          key={'user'}
          disabled={value === "user"}
          value={"user"}
        >
          User
        </MenuItem>
        <MenuItem
          muiskiplisthighlight='true'
          key={'guest'}
          disabled={value === "guest"}
          value={"guest"}
        >
          Guest
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default UserRoleDropdown;
