import React from "react";
import TeamDetails from "./components/TeamDetails";
import UserSettings from "./components/UserSettings";
import DefaultTargets from "./components/DefaultTargets";
import SecuritySettings from "./components/SecuritySettings";
import { ComponentBorder, ComponentContainer, FlexWrapper, PageContent, ScrollWindow } from "../../../../StyledComponents";

const Settings = ({ teamId, userFullName }) => {

  return (
    <PageContent>
      <ComponentContainer style={{height: '100%', maxWidth: '90rem', margin: '0 auto'}}>
        <ScrollWindow className="verticalOnly">
          <FlexWrapper className="column" style={{padding: '0 5rem'}}>
            <TeamDetails />
            <SecuritySettings userFullName={userFullName} teamId={teamId} />
            <DefaultTargets teamId={teamId} />
            <UserSettings userFullName={userFullName} />
          </FlexWrapper>
        </ScrollWindow>

        <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
        <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
        <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
        <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
      </ComponentContainer>
    </PageContent>
  )    
}

export default Settings;