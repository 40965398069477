import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {},
  status: 'idle',
  error: null
}

export const viewingLeadSlice = createSlice({
  name: 'viewingLead',
  initialState,
  reducers: {
    setViewingLead(state, action) {
      state.data = action.payload
    },
    setViewingLeadTimeline(state, action) {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      if (payload.data.mission === 'stillInvestigating' || payload.data.mission === 'closeInvestigation' || payload.data.mission === 'dismissInvestigation') {
        if (payload.data.newLeads.length > 0) {
          state.data = payload.data.newLeads[0]
        }
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      if (payload.data.activeDiscrepancies > 0 || payload.data.preserveLead) {
        state.data = payload.data.newLead
      } else {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.updateMortgageTags.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.updateMortgageAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus !== 'inactive') {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newLead
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if ((payload.data.leadCreated || payload.data.leadExisting)) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLead && payload.data.newLead.status) {
        if (payload.data.discardLead) {
          state.data = {}
        } else {
          state.data = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newLead) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetChanges.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newLead) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusInvestigating.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newLead) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusClosing.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newLead) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newLead) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.provideFinancialInformation.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadUpdated) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newLead) {
        state.data = payload.data.newLead
      }
    })
    .addMatcher(apiSlice.endpoints.recordSweepQuery.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data && payload.data.isSingleScan) {
        if (payload.data.newLead) {
          state.data = payload.data.newLead
        } else if (payload.data.updatedLead) {
          state.data = payload.data.updatedLead
        }
      }
    })
  }
})

export const { setViewingLead, setViewingLeadTimeline } = viewingLeadSlice.actions

export default viewingLeadSlice.reducer

export const selectViewingLead = state => state.viewingLead.data
export const selectViewingLeadTimeline = state => state.viewingLead.data.timeline
