import React, { forwardRef, useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from 'react-datepicker';
import ReportIcon from '@mui/icons-material/Report';
import { useTheme } from "@mui/styles";
import HdrStrongIcon from '@mui/icons-material/HdrStrong';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from "@mui/material/CircularProgress";
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import {addMonths, subMonths} from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import { selectUserDefaultLocations } from "../../../../../slices/teamAndUserSettingsSlice";
import { selectSearchMortgageUploadPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { selectSessionDateParsed, setRunningSweep } from "../../../../../slices/sessionDataSlice";
import { selectNextRecordSweepDate, selectTeamRemainingMonthlyQueries } from "../../../../../slices/teamStatsSlice";
import { useRecordUploadPerformanceMutation, useReloadTagViewsMutation, useRunPropertySearchForUploadMutation, useSavePropertyMutation } from "../../../../../slices/api/apiSlice";
import { ActionIconWrapper, Button, ButtonWrapper, ComponentHeader, Divider, ErrorWrapper, FlexWrapper, InputWrapper, Label, StatusAndLabelWrapper, StyledInputElement, Typography } from "../../../../../StyledComponents";

const SingleSearchUpload = ({ userFullName, teamId, userId, userRole }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="styled-calendar-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ))

  const sessionDateParsed = useSelector(selectSessionDateParsed)
  const nextRecordSweepDate = useSelector(selectNextRecordSweepDate)
  const userDefaultLocations = useSelector(selectUserDefaultLocations)
  const remainingMonthlyQueries = useSelector(selectTeamRemainingMonthlyQueries)
  const searchMortgageUploadPerm = useSelector(selectSearchMortgageUploadPerm)

  const [runPropertySearchForUpload] = useRunPropertySearchForUploadMutation()
  const [recordUploadPerformance] = useRecordUploadPerformanceMutation()
  const [reloadTagViews] = useReloadTagViewsMutation()
  const [saveProperty] = useSavePropertyMutation()
  
  const city = useRef()
  const state = useRef()
  const ownerOne = useRef()
  const ownerTwo = useRef()
  const postalCode = useRef()
  const providedTerm = useRef()
  const streetAddress = useRef()
  const providedInterest = useRef()
  const providedPrincipal = useRef()
  
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [isNoResults, setIsNoResults] = useState(false)
  const [searchIsError, setSearchIsError] = useState(false)

  const [addressRequired, setAddressRequired] = useState(true)
  const [streetAddressValue, setStreetAddressValue] = useState(null)
  const [statusStreetAddress, setStatusStreetAddress] = useState(null)
  const [cityRequired, setCityRequired] = useState(true)
  const [cityValue, setCityValue] = useState(null)
  const [statusCity, setStatusCity] = useState(null)
  const [stateRequired, setStateRequired] = useState(true)
  const [stateValue, setStateValue] = useState(null)
  const [statusState, setStatusState] = useState(null)
  const [postalCodeRequired, setPostalCodeRequired] = useState(true)
  const [postalCodeValue, setPostalCodeValue] = useState(null)
  const [statusPostalCode, setStatusPostalCode] = useState(null)

  const [ownersRequired, setOwnersRequired] = useState(false)
  const [ownerOneRequired, setOwnerOneRequired] = useState(false)
  const [ownerOneValue, setOwnerOneValue] = useState(null)
  const [statusOwnerOne, setStatusOwnerOne] = useState(null)
  const [ownerTwoRequired, setOwnerTwoRequired] = useState(false)
  const [ownerTwoValue, setOwnerTwoValue] = useState(null)
  const [statusOwnerTwo, setStatusOwnerTwo] = useState(null)

  const [financialsRequired, setFinancialsRequired] = useState(false)
  const [startDateRequired, setStartDateRequired] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [principalRequired, setPrincipalRequired] = useState(false)
  const [principalValue, setPrincipalValue] = useState(null)
  const [principalValueStr, setPrincipalValueStr] = useState(null)
  const [statusPrincipal, setStatusPrincipal] = useState(null)
  const [termRequired, setTermRequired] = useState(false)
  const [termValue, setTermValue] = useState('')
  const [statusTerm, setStatusTerm] = useState(null)
  const [interestRequired, setInterestRequired] = useState(false)
  const [interestValue, setInterestValue] = useState(null)
  const [interestValueStr, setInterestValueStr] = useState('')
  const [statusInterest, setStatusInterest] = useState(null)

  const handlePropertyUpload = async () => {
    dispatch( setRunningSweep(true) )
    setSearchIsError(false)
    setIsDuplicate(false)
    setIsNoResults(false)
    setIsFetching(true)
    setIsSuccess(false)
    setIsError(false)
    let errorObjs = []
    let errorCount = 0
    let newLeadIds = []
    let newLeadObjs = []
    let lastIsError = false
    let isDuplicate = false
    let totalNewLeads = 0
    let duplicateObjs = []
    let noResultsObjs = []
    let ownersChecked = false
    let lastIsSuccess = false
    let lastIsNewLead = false
    let noResultsCount = 0
    let Owner2FullName = null
    let Owner1FullName = null
    let duplicatesCount = 0
    let successfulCount = 0
    let lastIsDuplicate = false
    let lastIsNoResults = false
    let recordDetailsObj = null
    let attomSuccessDate = null
    let propMixSuccessDate = null
    let coreLogicSuccessDate = null
    let totalDiscrepanciesCount = 0
    if (ownerOneValue && ownerOneValue.length > 0) {
      ownersChecked = true
      Owner1FullName = ownerOneValue
    }
    if (ownerTwoValue && ownerTwoValue.length > 0) {
      ownersChecked = true
      Owner2FullName = ownerTwoValue
    }
    let financialsChecked = false
    let InterestRate = null
    let OriginationDate = null
    let LoanAmount = null
    let MortgageTerm = null
    if (startDate || principalValue || termValue || interestValue) {
      financialsChecked = true
      InterestRate = interestValue
      OriginationDate = startDate
      LoanAmount = principalValue
      MortgageTerm = termValue
    }
    let searchResp = await runPropertySearchForUpload({
      userFullName: userFullName,
      streetAddress: streetAddressValue,
      city: cityValue,
      state: stateValue,
      postalCode: postalCodeValue,
      unitNumber: null,
    })
    if (!searchResp.error) {
      isDuplicate = false
      if (searchResp.data && searchResp.data.message === 'existing mortgage') {
        isDuplicate = true
        setIsDuplicate(true)
        lastIsDuplicate = true
        duplicatesCount = duplicatesCount + 1
        duplicateObjs.push(searchResp.data.data.failureObj)
      } else if (searchResp.data && searchResp.data.message === 'no results') {
        lastIsNoResults = true
        setIsNoResults(true)
        noResultsCount = noResultsCount + 1
        noResultsObjs.push(searchResp.data.data.failureObj)
      } else if (searchResp.data && searchResp.data.message === 'error') {
        lastIsError = true
        setSearchIsError(true)
        errorCount = errorCount + 1     
        errorObjs.push(searchResp.data.data.failureObj)
      } else if (searchResp.data.data) {
        lastIsSuccess = true
        recordDetailsObj = searchResp.data.data.recordDetailsObj
        propMixSuccessDate = searchResp.data.data.propMixSuccessDate
        attomSuccessDate = searchResp.data.data.attomSuccessDate
        coreLogicSuccessDate = searchResp.data.data.coreLogicSuccessDate
      }
      if (!isDuplicate && lastIsSuccess) {
        let saveResp = await saveProperty({
          teamId: teamId,
          userFullName: userFullName,
          fileUpload: true,
          recordDetailsObj: recordDetailsObj,
          attomSuccessDate: attomSuccessDate,
          propMixSuccessDate: propMixSuccessDate,
          coreLogicSuccessDate: coreLogicSuccessDate,
          streetAddress: streetAddressValue,
          ownersChecked: ownersChecked,
          Owner1FullName: Owner1FullName,
          Owner2FullName: Owner2FullName,
          financialsChecked: financialsChecked,
          OriginationDate: OriginationDate,
          LoanAmount: LoanAmount,
          MortgageTerm: MortgageTerm,
          InterestRate: InterestRate,
        })
        if (saveResp.data && !saveResp.data.message.includes('duplicate')) {
          successfulCount = successfulCount + 1
          if (saveResp.data.data && saveResp.data.data.newLead) {
            totalNewLeads = totalNewLeads + 1
            totalDiscrepanciesCount = totalDiscrepanciesCount + saveResp.data.data.totalDiscrepanciesCount
            newLeadIds.push(saveResp.data.data.newLead._id)
            lastIsNewLead = true
            newLeadObjs.push({
              leadId: saveResp.data.data.newLead._id,
              streetAddress: streetAddressValue.toUpperCase(),
              city: cityValue.trim().toUpperCase(),
              state: stateValue.trim().toUpperCase(),
              postalCode: postalCodeValue,
            })
          }
        } else if (!searchResp.data || (searchResp.data && searchResp.data.message !== 'error')) {     
          errorCount = errorCount + 1    
          setIsError(true) 
          errorObjs.push({
            streetAddress: streetAddressValue.toUpperCase(),
            city: cityValue.trim().toUpperCase(),
            state: stateValue.trim().toUpperCase(),
            postalCode: postalCodeValue,
          })
        }
      }
    } else {
      errorCount = errorCount + 1
    }
    let uploadType = 'Address only'
    if (ownersChecked && financialsChecked) {
      uploadType = 'Address, Owners, and Financials'
    } else if (ownersChecked) {
      uploadType = 'Address and Owners'
    } else if (financialsChecked) {
      uploadType = 'Address and Financials'

    }
    let monthlyQueriesExhausted = false
    if (remainingMonthlyQueries === 1) {
      monthlyQueriesExhausted = true
    }
    let recordUploadPerformanceResp = await recordUploadPerformance({
      userId: userId,
      teamId: teamId,
      userFullName: userFullName,
      uploadType: uploadType,
      lastIsDuplicate: lastIsDuplicate,
      lastIsError: lastIsError,
      lastIsNoResults: lastIsNoResults,
      lastIsNewLead: lastIsNewLead,
      notifyAssignees: true,
      totalNewLeads: totalNewLeads,
      recordNewMonthlyStats: true,
      uploadTimeParsed: sessionDateParsed,
      numberSuccessess: successfulCount,
      numberDuplicates: duplicatesCount,
      numberErrors: errorCount,
      numberNoResults: noResultsCount,
      errorObjs: errorObjs,
      noResultsObjs: noResultsObjs,
      duplicateObjs: duplicateObjs,
      newLeadIds: newLeadIds,
      leadObjs: newLeadObjs,
      totalDiscrepanciesCount: totalDiscrepanciesCount,
      monthlyQueriesExhausted: monthlyQueriesExhausted,
    })
    let reloadTagViewsResp = await reloadTagViews({
      teamId: teamId,
      userRole: userRole,
    })
    if (reloadTagViewsResp.error || recordUploadPerformanceResp.error) {
      setIsError(true)   
    } else {
      setIsSuccess(true)
    }
    setIsFetching(false)
    dispatch( setRunningSweep(false) )
  }

  const handleValidateStartDate = (dateInput) => {
    setIsSuccess(false)
    setIsError(false)
    setStartDate(dateInput)
    if (dateInput) {
      setStartDateRequired(false)
      setFinancialsRequired(true)
      if (!principalValue) {
        setPrincipalRequired(true)
      }
      if (!termValue) {
        setTermRequired(true)
      }
      if (!interestValue) {
        setInterestRequired(true)
      }
    } else {
      setStartDateRequired(true)
      if (principalValue || termValue || interestValue) {
        setStartDateRequired(true)
      } else {
        setStartDateRequired(false)
        setPrincipalRequired(false)
        setTermRequired(false)
        setInterestRequired(false)
        setFinancialsRequired(false)
      }
    }
  }
  const handleTermValidation = (termInput) => {
    setIsSuccess(false)
    setIsError(false)
    let integerValue = parseFloat(termInput)
    let negativeValue = Math.sign(integerValue)
    if (!/^[0-9,.]*$/.test(termInput)) {
      setStatusTerm("invalid")
    } else if (negativeValue === -1) {
      setStatusTerm('invalid')
    } else if (isNaN(parseInt(termInput))) {
      setStatusTerm('invalid')
    } else if (integerValue === 0) {
      setStatusTerm('invalid')
    } else if (integerValue > 1000) {
      setStatusTerm('invalid')
    } else if (statusTerm === "invalid") {
      setStatusTerm(null)
    }
    if (!termInput) {
      setStatusTerm(null)
      if (principalValue || interestValue || startDate) {
        setTermRequired(true)
        if (!principalValue) {
          setPrincipalRequired(true)
        }
        if (!startDate) {
          setStartDateRequired(true)
        }
        if (!interestValue) {
          setInterestRequired(true)
        }
        setFinancialsRequired(true)
      } else {
        setTermRequired(false)
        setPrincipalRequired(false)
        setStartDateRequired(false)
        setInterestRequired(false)
        setFinancialsRequired(false)
      }
    } else {
      if (!principalValue) {
        setPrincipalRequired(true)
      }
      if (!startDate) {
        setStartDateRequired(true)
      }
      if (!interestValue) {
        setInterestRequired(true)
      }
      setFinancialsRequired(true)
    }
    setTermValue(termInput)
  }
  const handlePrincipalValidation = (principalInput) => {
    setIsSuccess(false)
    setIsError(false)
    if (!/^[0-9,.]*$/.test(principalInput)) {
      setStatusPrincipal("invalid")
    } else if (Math.sign(parseInt(principalInput)) === -1) {
      setStatusPrincipal("invalid")
    } else if (Math.sign(parseInt(principalInput)) === 0) {
      setStatusPrincipal("invalid")
    } else if (parseInt(principalInput.replace(/,/g, "")) > 100000000) {
      setStatusPrincipal("invalid")
    } else if (statusPrincipal === "invalid") {
      setStatusPrincipal(null)
    }
    if (!principalInput) {
      setStatusPrincipal(null)
      if (termValue || interestValue || startDate) {
        setPrincipalRequired(true)
        if (!startDate) {
          setStartDateRequired(true)
        }
        if (!termValue) {
          setTermRequired(true)
        }
        if (!interestValue) {
          setInterestRequired(true)
        }
        setFinancialsRequired(true)
      } else {
        setPrincipalRequired(false)
        setTermRequired(false)
        setStartDateRequired(false)
        setInterestRequired(false)
        setFinancialsRequired(false)
      }
    } else {
      if (!startDate) {
        setStartDateRequired(true)
      }
      if (!termValue) {
        setTermRequired(true)
      }
      if (!interestValue) {
        setInterestRequired(true)
      }
      setPrincipalRequired(false)
      setFinancialsRequired(true)
    }
    if ((parseInt(principalInput.replace(/,/g, "")) < 100000000 || (isNaN(parseInt(principalInput.replace(/,/g, ""))))) && principalInput.length < 12) {
      if (!isNaN(parseInt(principalInput))) {
        setPrincipalValueStr(parseInt(principalInput.replace(/,/g, "")).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0}))
      } else {
        setPrincipalValueStr(principalInput)
      }
      setPrincipalValue(parseInt(principalInput.replace(/[^0-9-.]/g, '')))
    }
  }
  const handleInterestValidation = (interestInput) => {
    setIsSuccess(false)
    setIsError(false)
    if (!/^[0-9,.]*$/.test(interestInput)) {
      setStatusInterest("invalid")
    } else if (isNaN(parseFloat(interestInput))) {
      setStatusInterest("invalid")
    } else if (Math.sign(parseFloat(interestInput)) === -1) {
      setStatusInterest("invalid")
    } else if (Math.sign(parseFloat(interestInput)) === 0) {
      setStatusInterest("invalid")
    } else if (parseFloat(interestInput) > 100) {
      setStatusInterest("invalid")
    } else if (statusInterest === "invalid") {
      setStatusInterest(null)
    }
    if (!interestInput) {
      setStatusInterest(null)
      if (principalValue || termValue || startDate) {
        setInterestRequired(true)
        if (!principalValue) {
          setPrincipalRequired(true)
        }
        if (!termValue) {
          setTermRequired(true)
        }
        if (!startDate) {
          setStartDateRequired(true)
        }
        setFinancialsRequired(true)
      } else {
        setPrincipalRequired(false)
        setTermRequired(false)
        setStartDateRequired(false)
        setInterestRequired(false)
        setFinancialsRequired(false)
      }
    } else {
      if (!principalValue) {
        setPrincipalRequired(true)
      }
      if (!termValue) {
        setTermRequired(true)
      }
      if (!startDate) {
        setStartDateRequired(true)
      }
      setInterestRequired(false)
      setFinancialsRequired(true)
    }
    setInterestValueStr(interestInput)
    setInterestValue(parseFloat(interestInput))
  }
  const handlePostalCodeValidation = (postalCodeInput) => {
    setIsSuccess(false)
    setIsError(false)
    if (!/^[0-9,.]*$/.test(postalCodeInput)) {
      setStatusPostalCode("invalid")
    } else if (Math.sign(parseInt(postalCodeInput)) === -1) {
      setStatusPostalCode("invalid")
    } else if (Math.sign(parseInt(postalCodeInput)) === 0) {
      setStatusPostalCode("invalid")
    } else if (postalCodeInput.length > 5) {
      setStatusPostalCode("invalid")
    } else if (statusPostalCode === "invalid") {
      setStatusPostalCode(null)
    }
    if (postalCodeInput) {
      setPostalCodeRequired(false)
    } else {
      setPostalCodeRequired(true)
    }
    setPostalCodeValue(postalCodeInput)
  }
  const handlePlainTextValidation = (formInput, ref) => {
    setIsSuccess(false)
    setIsError(false)
    if (ref === 'owner1') {
      if (formInput.includes('<') || formInput.includes('>')) {
        setStatusOwnerOne('invalid')
      } else if (formInput.length > 100) {
        setStatusOwnerOne('invalid')
      } else if (statusOwnerOne === "invalid") {
        setStatusOwnerOne(null)
      }
      if (formInput) {
        setOwnersRequired(true)
        setOwnerOneRequired(false)
      } else {
        if (!ownerTwoValue) {
          setOwnersRequired(false)
        }
      }
      setOwnerOneValue(formInput)
    } else if (ref === 'owner2') {
      if (formInput.includes('<') || formInput.includes('>')) {
        setStatusOwnerTwo('invalid')
      } else if (formInput.length > 100) {
        setStatusOwnerTwo('invalid')
      } else if (statusOwnerTwo === "invalid") {
        setStatusOwnerTwo(null)
      }
      if (formInput) {
        setOwnersRequired(true)
        setOwnerTwoRequired(false)
      } else {
        if (!ownerOneValue) {
          setOwnersRequired(false)
        }
      }
      setOwnerTwoValue(formInput)
    } else if (ref === 'address') {
      if (formInput.includes('<') || formInput.includes('>')) {
        setStatusStreetAddress('invalid')
      } else if (formInput.length > 200) {
        setStatusStreetAddress('invalid')
      } else if (statusStreetAddress === "invalid") {
        setStatusStreetAddress(null)
      }
      if (formInput) {
        setAddressRequired(false)
      } else {
        setAddressRequired(true)
      }
      setStreetAddressValue(formInput)
    } else if (ref === 'city') {
      if (formInput.includes('<') || formInput.includes('>')) {
        setStatusCity('invalid')
      } else if (formInput.length > 200) {
        setStatusCity('invalid')
      } else if (statusCity === "invalid") {
        setStatusCity(null)
      }
      if (formInput) {
        setCityRequired(false)
      } else {
        setCityRequired(true)
      }
      setCityValue(formInput)
    } else if (ref === 'state') {
      if (formInput.includes('<') || formInput.includes('>')) {
        setStatusState('invalid')
      } else if (formInput.length > 2) {
        setStatusState('invalid')
      } else if (statusState === "invalid") {
        setStatusState(null)
      }
      if (formInput) {
        if (formInput.length === 1) {
          setStateRequired(true)
        } else {
          setStateRequired(false)
        }
      } else {
        setStateRequired(true)
      }
      setStateValue(formInput.toUpperCase())
    }
  }

  useEffect(() => {
    if (userDefaultLocations.state) {
      state.current.value = userDefaultLocations.state
      setStateValue(userDefaultLocations.state)
      setStateRequired(false)
    }
    if (userDefaultLocations.city) {
      city.current.value = userDefaultLocations.city
      setCityValue(userDefaultLocations.city)
      setCityRequired(false)
    }
  }, [userDefaultLocations])

  return (
    <FlexWrapper>
      <FlexWrapper className="column justifyCenter">
        <ComponentHeader 
          className={(isError || searchIsError ?
            'error '
          : isDuplicate || isNoResults || remainingMonthlyQueries === 0 ?
            'caution '
          : isSuccess ?
            'green '
          : ''
          ) + "subHeader noTransform"}
          style={{height: '3rem', width: '100%', margin: '0', padding: '0.4rem 0 0.2rem 0', fontSize: '1.2rem'}}
        >
          {!searchMortgageUploadPerm ?
            <>
              <span style={{marginLeft: '0.8rem'}}>
                Elevated authority required
              </span>
            </>
          : isError ?
            <>
              <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0', width: '1.5rem', height: '1.5rem', background: `${theme.palette.common.redBright}`}}>
                <ErrorIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.white}`}} />
              </FlexWrapper>
              <span style={{marginLeft: '0.8rem'}}>
                An error was encountered. Please contact your app administrator if the problem persists.
              </span>
            </>
          : isDuplicate ?
            <>
              <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.orangeDark}`, padding: '0', width: '1.5rem', height: '1.5rem', background: `${theme.palette.common.orange}`}}>
                <ContentCopyIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.white}`}} />
              </FlexWrapper>
              <span style={{marginLeft: '0.8rem'}}>
                Property already saved in the system
              </span>
            </>
          : isNoResults ?
            <>
              <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.orangeDark}`, padding: '0', width: '1.5rem', height: '1.5rem', background: `${theme.palette.common.orange}`}}>
                <SignalWifiBadIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.white}`}} />
              </FlexWrapper>
              <span style={{marginLeft: '0.8rem'}}>
                No results were found. Please ensure all information provided was accurate.
              </span>
            </>
          : searchIsError ?
            <>
              <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0', width: '1.5rem', height: '1.5rem', background: `${theme.palette.common.redBright}`}}>
                <ErrorIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.white}`}} />
              </FlexWrapper>
              <span style={{marginLeft: '0.8rem'}}>
                The property search encountered an error. Please try again.
              </span>
            </>
          : isSuccess ?
            <>
              <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0', width: '1.5rem', height: '1.5rem', background: `${theme.palette.primary.main}`}}>
                <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.white}`}} />
              </FlexWrapper>
              <span style={{marginLeft: '0.8rem'}}>
                Upload successful!
              </span>
            </>
          : remainingMonthlyQueries === 0 ?
            <>
              <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.orangeDark}`, padding: '0', width: '1.5rem', height: '1.5rem', background: `${theme.palette.common.orange}`}}>
                <ReportIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.white}`}} />
              </FlexWrapper>
              <span style={{marginLeft: '0.8rem'}}>
                No monthly queries remaining.. Uploads disabled until {nextRecordSweepDate}, or you can contact you app admin to request a higher monthly limit.
              </span>
            </>
          : ((addressRequired || cityRequired || stateRequired || postalCodeRequired) || 
          (ownersRequired && (ownerOneRequired || ownerTwoRequired)) || 
          (financialsRequired && (startDateRequired || termRequired || principalRequired || interestRequired))) ?
            <span style={{marginLeft: '0.8rem'}}>
              Provide the required information to continue
            </span>
          :
            <Typography className="fader primary exoItalic dark medium">
              READY FOR UPLOAD...
            </Typography>
          }
        </ComponentHeader>
        <FlexWrapper className="column autoMarginHorizontal fitContentHeight justifyCenter alignCenter" style={{maxWidth: '100rem', margin: '2rem auto 0 auto'}}>
          <FlexWrapper className="alignCenter">
            <ComponentHeader className={((statusStreetAddress === 'invalid' || statusCity === 'invalid' || statusState === 'invalid' || statusPostalCode === 'invalid') ? 
                'error ' 
              : (addressRequired || cityRequired || stateRequired || postalCodeRequired) ? 
                'caution ' 
              : 'green ') + "modal"} 
              style={{margin: 'auto 0 auto 5rem', padding: '0.1rem 0', fontSize: '1.4rem', background: 'none', border: 'none', width: '9rem'}}>
              Address
            </ComponentHeader>
            
            <FlexWrapper className="fitContentHeight justifyCenter" style={{margin: '0 auto'}}>
              <InputWrapper style={{width: '20rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="streetAddress">Street Address</Label>
                  {statusStreetAddress === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : (addressRequired && remainingMonthlyQueries > 0) && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </StatusAndLabelWrapper>
                <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusStreetAddress === "invalid" ? 'error ' : addressRequired ? 'caution ' : isSuccess ? 'success ' : '') + "borderBottomOnly" }
                  style={{width: '20rem'}}
                  disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                  autoComplete="off"
                  type="text"
                  name="streetAddress"
                  id="streetAddress"
                  ref={streetAddress}
                  tabIndex="1"
                  onChange={() => handlePlainTextValidation(streetAddress.current.value, 'address')}
                />
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem'}} />
              </InputWrapper>

              <InputWrapper style={{width: '12rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="city">City</Label>
                  {statusCity === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : (cityRequired && remainingMonthlyQueries > 0) && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </StatusAndLabelWrapper>
                <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusCity === "invalid" ? 'error ' : cityRequired ? 'caution ' : isSuccess ? 'success ' : '') + "borderBottomOnly" }
                  style={{width: '12rem'}}
                  disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                  autoComplete="off"
                  type="text"
                  name="city"
                  id="city"
                  ref={city}
                  tabIndex="2"
                  onChange={() => handlePlainTextValidation(city.current.value, 'city')}
                />
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem'}} />
              </InputWrapper>

              <InputWrapper style={{width: '7.5rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="state">State (XX)</Label>
                </StatusAndLabelWrapper>
                <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusState === "invalid" ? 'error ' : stateRequired ? 'caution ' : isSuccess ? 'success ' : '') + "borderBottomOnly" }
                  style={{width: '7.5rem'}}
                  disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                  autoComplete="off"
                  type="text"
                  name="state"
                  id="state"
                  ref={state}
                  tabIndex="3"
                  value={stateValue ? stateValue : ""}
                  onChange={() => handlePlainTextValidation(state.current.value, 'state')}
                />
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem'}}>
                  {statusState === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : (stateRequired && remainingMonthlyQueries > 0) && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>

              <InputWrapper style={{width: '7.5rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="postalCode">Zip Code</Label>
                </StatusAndLabelWrapper>
                <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusPostalCode === "invalid" ? 'error ' : postalCodeRequired ? 'caution ' : isSuccess ? 'success ' : '') + "borderBottomOnly" }
                  style={{width: '7.5rem'}}
                  disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                  autoComplete="off"
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  ref={postalCode}
                  tabIndex="4"
                  onChange={() => handlePostalCodeValidation(postalCode.current.value)}
                />
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem'}}>
                  {statusPostalCode === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : (postalCodeRequired && remainingMonthlyQueries > 0) && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>
            </FlexWrapper>
          </FlexWrapper>

          <FlexWrapper className="justifyCenter" style={{width: '73rem', height: '1rem', margin: '1rem auto 1rem auto', alignItems: 'center'}}>
            <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
            <Divider />
            <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.3rem', transform: 'rotateY(-180deg)'}} />
          </FlexWrapper>

          <FlexWrapper className="alignCenter">
            <ComponentHeader className={((statusOwnerOne === 'invalid' || statusOwnerTwo === 'invalid') ? 
                'error ' 
              : (ownerOneRequired || ownerTwoRequired) ? 
                'caution ' 
              : ownersRequired ? 
                'green ' 
              : '') + "modal"} 
              style={{margin: 'auto 0 auto 5rem', padding: '0.1rem 0', fontSize: '1.4rem', background: 'none', border: 'none', width: '9rem'}}>
              Owners
            </ComponentHeader>
            <FlexWrapper className="fitContentHeight justifyCenter" style={{margin: '0 auto'}}>

              <InputWrapper style={{width: '17rem', marginRight: '3rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="ownerOne">Owner One full name</Label>
                  {statusOwnerOne === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : ownerOneRequired && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </StatusAndLabelWrapper>
                <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusOwnerOne === "invalid" ? 'error ' : ownerOneRequired ? 'caution ' : (isSuccess && ownersRequired) ? 'success ' : '') + "borderBottomOnly" }
                  style={{width: '17rem'}}
                  disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                  autoComplete="off"
                  type="text"
                  name="ownerOne"
                  id="ownerOne"
                  ref={ownerOne}
                  tabIndex="5"
                  onChange={() => handlePlainTextValidation(ownerOne.current.value, 'owner1')}
                />
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem'}} />
              </InputWrapper>

              <InputWrapper style={{width: '17rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="ownerTwo">Owner Two full name</Label>
                  {statusOwnerTwo === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : ownerTwoRequired && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </StatusAndLabelWrapper>
                <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusOwnerTwo === "invalid" ? 'error ' : ownerTwoRequired ? 'caution ' : (isSuccess && ownersRequired) ? 'success ' : '') + "borderBottomOnly" }
                  style={{width: '17rem'}}
                  disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                  autoComplete="off"
                  type="text"
                  name="ownerTwo"
                  id="ownerTwo"
                  ref={ownerTwo}
                  tabIndex="6"
                  onChange={() => handlePlainTextValidation(ownerTwo.current.value, 'owner2')}
                />
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem'}} />
              </InputWrapper>

            </FlexWrapper>
          </FlexWrapper>

          <FlexWrapper className="justifyCenter" style={{width: '73rem', height: '1rem', margin: '1rem auto 1rem auto', alignItems: 'center'}}>
            <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
            <Divider />
            <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.3rem', transform: 'rotateY(-180deg)'}} />
          </FlexWrapper>

          <FlexWrapper className="alignCenter">
            <ComponentHeader className={((statusTerm === 'invalid' || statusPrincipal === 'invalid' || statusInterest === 'invalid') ? 
                'error ' 
              : (startDateRequired || termRequired || principalRequired || interestRequired) ? 
                'caution ' 
              : financialsRequired ?
                'green '
              : '') + "modal"} 
              style={{margin: 'auto 0 auto 5rem', padding: '0.1rem 0', fontSize: '1.4rem', background: 'none', border: 'none', width: '9rem'}}>
              Financials
            </ComponentHeader>
            <FlexWrapper className="fitContentHeight justifyCenter" style={{margin: '0 auto'}}>

              <InputWrapper style={{width: '10rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="city">Start Date</Label>
                </StatusAndLabelWrapper>
                <FlexWrapper className="fitContentWidth alignEnd" style={{marginRight: 'auto', height: '2.5rem'}}>
                  <DatePicker 
                    selected={startDate}
                    onChange={(date) => handleValidateStartDate(date)}
                    maxDate={addMonths(new Date(), 12)}
                    minDate={subMonths(new Date(), 360)}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={10}
                    withPortal
                    portalId="root-portal"
                    customInput={<ExampleCustomInput />}
                    dayClassName={() => "example-datepicker-day-class"}
                    calendarClassName="example-datepicker-class"
                    todayButton="CLICK FOR TODAY'S DATE"
                    disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                    tabIndex="7"
                  />
                  {startDate &&
                    <ActionIconWrapper className="hover gray">
                      <CloseIcon onClick={() => handleValidateStartDate(null)} style={{height: '2rem', width: '2rem'}} />
                    </ActionIconWrapper>
                  }
                </FlexWrapper>
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem', marginRight: 'auto'}}>
                  {startDateRequired && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>
              
              <InputWrapper style={{width: '10rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="city">Term</Label>
                </StatusAndLabelWrapper>
                <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem', marginRight: 'auto'}}>
                <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusTerm === "invalid" ? 'error ' : termRequired ? 'caution ' : (isSuccess && financialsRequired) ? 'success ' : '') + "borderBottomOnly" }
                    style={{width: '4rem', padding: '0 0.7rem 0 0', textAlign: 'end'}}
                    value={termValue ? termValue : ""}
                    type="text"
                    name="term"
                    id="term"
                    ref={providedTerm}
                    disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                    onChange={() => handleTermValidation(providedTerm.current.value)}
                    tabIndex="8"
                  />
                  <span style={{margin: 'auto 0 0.5rem 0.5rem'}}>years</span>
                </FlexWrapper>
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem', marginRight: 'auto'}}>
                  {statusTerm === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : termRequired && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>

              <InputWrapper style={{width: '10rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="city">Principal</Label>
                </StatusAndLabelWrapper>
                <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem', marginRight: 'auto'}}>
                  <span style={{color: `${theme.palette.primary.main}`, fontWeight: '600', padding: '0 0 0.5rem 0', marginRight: '0.3rem'}}>$</span>
                  <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusPrincipal === "invalid" ? 'error ' : principalRequired ? 'caution ' : (isSuccess && financialsRequired) ? 'success ' : '') + "borderBottomOnly" }
                    style={{width: '7.5rem', padding: '0 0 0 0.3rem'}}
                    value={principalValueStr ? principalValueStr : ""}
                    type="text"
                    name="principal"
                    id="principal"
                    tabIndex="9"
                    ref={providedPrincipal}
                    disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                    onChange={() => handlePrincipalValidation(providedPrincipal.current.value)}
                  />
                </FlexWrapper>
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem', margin: '0 auto 0 1rem'}}>
                  {statusPrincipal === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : principalRequired && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>

              <InputWrapper style={{width: '8rem'}}>
                <StatusAndLabelWrapper style={{height: '1.5rem'}}>
                  <Label htmlFor="city">Interest Rate</Label>
                </StatusAndLabelWrapper>
                <FlexWrapper className="alignEnd fitContentWidth" style={{height: '2.5rem', marginRight: 'auto'}}>
                <StyledInputElement className={(((remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm) ? 'disabled ' : statusInterest === "invalid" ? 'error ' : interestRequired ? 'caution ' : (isSuccess && financialsRequired) ? 'success ' : '') + "borderBottomOnly" }
                    style={{width: '4rem', padding: '0 0.7rem 0 0', textAlign: 'end'}}
                    value={interestValueStr ? interestValueStr : ''}
                    type="text"
                    name="interest"
                    id="interest"
                    tabIndex="10"
                    ref={providedInterest}
                    disabled={(remainingMonthlyQueries === 0) || isFetching || !searchMortgageUploadPerm}
                    onChange={() => handleInterestValidation(providedInterest.current.value)}
                  />
                  <span style={{margin: 'auto 0 0.5rem 0.5rem'}}>%</span>
                </FlexWrapper>
                <FlexWrapper className="fitContentWidth" style={{height: '1.5rem', margin: '0 auto 0 0'}}>
                  {statusInterest === "invalid" ? (
                    <ErrorWrapper style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      invalid
                    </ErrorWrapper>
                  ) : interestRequired && (
                    <ErrorWrapper className="caution" style={{height: '1.5rem', gap: '0.2rem', padding: '0.1rem 0.5rem 0.1rem 0.1rem'}}>
                      <ErrorIcon style={{height: '1.2rem', width: '1.2rem'}} />
                      required
                    </ErrorWrapper>
                  )}
                </FlexWrapper>
              </InputWrapper>

            </FlexWrapper>
          </FlexWrapper>
          <ButtonWrapper className="selfAlignEnd marginAuto" style={{marginTop: '2rem'}}>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.caution.color}`,
                backgroundColor: `${theme.tooltip.caution.background}`,
                border: `0.1rem solid ${theme.tooltip.caution.border}`,
                borderBottom: `0.1rem solid ${theme.tooltip.caution.border}`,
                opacity: '0',
                borderRadius: '1rem',
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: '0.5rem 1rem',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={(remainingMonthlyQueries === 0) ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    No monthly queries remaining.
                  </Typography>
                </FlexWrapper>
              : !searchMortgageUploadPerm ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Elevated authority required
                  </Typography>
                </FlexWrapper>
              : ((addressRequired || cityRequired || stateRequired || postalCodeRequired) || 
                (ownersRequired && (ownerOneRequired || ownerTwoRequired)) || 
                (financialsRequired && (startDateRequired || termRequired || principalRequired || interestRequired))) ? 
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Form inputs required
                  </Typography>
                </FlexWrapper>
              : null} 
              placement="top"
              arrow
            >
              <span>
                <Button 
                  className={isFetching ? 
                      'primaryLoading' 
                    : (remainingMonthlyQueries === 0) || 
                      (!searchMortgageUploadPerm || isError || isSuccess ||
                      (addressRequired || cityRequired || stateRequired || postalCodeRequired) || 
                      (ownersRequired && (ownerOneRequired || ownerTwoRequired)) || 
                      (financialsRequired && (startDateRequired || termRequired || principalRequired || interestRequired))) ? 
                      "primaryDisabled" 
                    : "primary"
                  }
                  disabled={(remainingMonthlyQueries === 0) || isFetching || isSuccess || !searchMortgageUploadPerm || isError || (addressRequired || cityRequired || stateRequired || postalCodeRequired) || (ownersRequired && (ownerOneRequired || ownerTwoRequired)) || (financialsRequired && (startDateRequired || termRequired || principalRequired || interestRequired))}
                  style={{height: '3.5rem', paddingRight: !isSuccess && !isFetching ? '1rem' : ''}}
                  onClick={() => handlePropertyUpload()}
                  tabIndex="11"
                >
                  {isFetching ?
                    <CircularProgress
                      sx={{ color: `${theme.button.text.primaryLoading}` }}
                      size={14}
                    />
                  :
                    <>
                      <UploadFileIcon style={{margin: '0 0.5rem 0.2rem 0', height: '1.8rem', width: '1.8rem'}} />
                      Upload
                    </>
                  }
                </Button>
              </span>
            </Tooltip>
          </ButtonWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default SingleSearchUpload