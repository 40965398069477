import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { ColumnContent, ModalContainer, ComponentBorder, ComponentSectionRow, RowsContainer, FlexWrapper, ActionIconWrapper, ScrollWindow } from "../../../../../../StyledComponents";

const SweepFailuresModal = ({ sweepFailures, failureType, closeModal }) => {

  return (
    <ModalContainer className="onePanel">
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => closeModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
      {sweepFailures.length > 0 ?
        <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)'}}>
          <ComponentSectionRow className="sweepFailures header">
            {failureType === 'failedQueries' || failureType === 'improperQueries' || failureType === 'brokenConnections' ?
              <>
                <ColumnContent className="header center" style={{gridColumn: '1'}}>
                  Index
                </ColumnContent>
                <ColumnContent className="header center" style={{gridColumn: '2'}}>
                  Vendor
                </ColumnContent>
                <ColumnContent className="header center" style={{gridColumn: '3'}}>
                  Code
                </ColumnContent>
                <ColumnContent className="header center" style={{gridColumn: '4'}}>
                  Type
                </ColumnContent>
                <ColumnContent className="header center" style={{gridColumn: '5'}}>
                  Message
                </ColumnContent>
                <ColumnContent className="header center" style={{gridColumn: '6'}}>
                  Address Attempted
                </ColumnContent>
              </>
            : 
              <>
                <ColumnContent className="header center" style={{gridColumn: '1'}}>
                  Index
                </ColumnContent>
                <ColumnContent className="header center" style={{gridColumn: '2/4'}}>
                  Type
                </ColumnContent>
                <ColumnContent className="header center" style={{gridColumn: '4/7'}}>
                  Message
                </ColumnContent>
              </>
            }
          </ComponentSectionRow>
          <RowsContainer style={{height: '100%'}}>
            {sweepFailures.map((failure, index) => (
              <ComponentSectionRow key={failure[0].AddressAttempted} className={(index%2 === 1 ? 'even ' : '') + 'sweepFailures'}>
                <ColumnContent className='detail' style={{gridColumn: '1'}}>
                  {index + 1}
                </ColumnContent>
                {failureType === 'failedQueries' || failureType === 'improperQueries' || failureType === 'brokenConnections' ?
                  failure[0].Status ?
                    <>
                      <ColumnContent className='detail' style={{gridColumn: '2'}}>
                        {failure[0].Vendor}
                      </ColumnContent>
                      <ColumnContent className='detail' style={{gridColumn: '3'}}>
                        {failure[0].Code}
                      </ColumnContent>
                      <ColumnContent className='detail' style={{gridColumn: '4'}}>
                        {failure[0].Type}
                      </ColumnContent>
                      <ColumnContent className='detail' style={{gridColumn: '5'}}>
                        {failure[0].Message}
                      </ColumnContent>
                      <ColumnContent className='detail' style={{gridColumn: '6', wordBreak: 'break-all'}}>
                        {failure[0].AddressAttempted}      
                      </ColumnContent>
                    </>
                  :
                    <>
                      <ColumnContent className='detail' style={{gridColumn: '2'}}>
                        {failure[0].Vendor}
                      </ColumnContent>
                      <ColumnContent className='detail' style={{gridColumn: '3'}}>
                        {failure[0].Code}
                      </ColumnContent>
                      <ColumnContent className='detail' style={{gridColumn: '4'}}>
                        {failure[0].Type}
                      </ColumnContent>
                      <ColumnContent className='detail' style={{gridColumn: '5'}}>
                        {failure[0].Message}
                        {failure[0].Message === "Oauth failed" ?
                          ` (keys)`
                        : 
                          ``
                        }
                      </ColumnContent>
                      <ColumnContent className='detail' style={{gridColumn: '6', wordBreak: 'break-all'}}>
                        {failure[0].AddressAttempted}      
                      </ColumnContent>
                    </>
                : failure.description && failure.description.includes('Street Suffix') ?
                  <>
                    <ColumnContent className='detail' style={{gridColumn: '2'}}>
                      Formatting error
                    </ColumnContent>
                    <ColumnContent className='detail' style={{gridColumn: '3/7', wordBreak: 'break-all'}}>
                      {failure.description}      
                    </ColumnContent>
                  </>
                :
                  <></>
                }
              </ComponentSectionRow>
            ))}
          </RowsContainer>
        </ScrollWindow>
      :
        <></>
      }
      </FlexWrapper>

      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ModalContainer>
  )
}

export default SweepFailuresModal