import React, { useState } from "react";
import Radio from '@mui/material/Radio';
import { Dialog } from "@mui/material";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import ParameterTags from "../../../../components/Configurations/ParameterTags";
import FormControlLabel from '@mui/material/FormControlLabel';
import SweepParameterModal from "../../../../components/Modals/SweepParameterModal/SweepParameterModal";
import { selectMortgageDataModel } from "../../../../slices/mortgageDataModelSlice";
import { useDispatch, useSelector } from "react-redux";
import { setViewingSweepParameter } from "../../../../slices/viewingSweepParameterSlice";
import { grayUnselectedRadioColorProps } from "../../../../componentThemes";
import ParameterQueryAndPerformanceStats from "../../../../components/Configurations/ParameterQueryAndPerformanceStats";
import { ComponentContainer, ComponentSectionRow, FlexWrapper, HeaderText, ComponentBorder } from "../../../../StyledComponents";

const DataModel = ({ userFullName }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const mortgageDataModel = useSelector(selectMortgageDataModel)

  const [openSweepParameterModal, setOpenSweepParameterModal] = useState(false)
  const [parameterView, setParameterView] = useState('query and performance stats')

  const handleOpenSweepParameterModal = (sweepParameter) => {
    dispatch( setViewingSweepParameter(sweepParameter) )
    setOpenSweepParameterModal(true)
  }
  const handleCloseSweepParameterModal = () => {
    setOpenSweepParameterModal(false)
  }

  const parameterViewToggleControlProps = (item) => ({
    checked: parameterView === item,
    onChange: handleParameterViewChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const handleParameterViewChange = (event) => {
    setParameterView(event.target.value)
  }

  return (
    <>
      <ComponentContainer className="fitWindow onePanel" style={{background: `${theme.palette.common.grayBorderLight}`}}>
        <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2', height: 'calc(100vh - 11.2rem)'}}>
          <ComponentSectionRow style={{justifyContent: 'center', padding: '1rem 0', background: `${theme.palette.common.grayBorderLight}`}}>
            <FlexWrapper className="fitContentWidth alignCenter">
              <HeaderText className="center" style={{margin: '0.2rem 0 0 0'}}>
                View Parameter Details
              </HeaderText>
              <FormControl style={{margin: '0 0 0 3rem'}}>
                <RadioGroup row>
                  <FormControlLabel
                    control={<Radio {...grayUnselectedRadioColorProps()} />}
                    {...parameterViewToggleControlProps('query and performance stats')} 
                  />
                  <FormControlLabel
                    control={<Radio {...grayUnselectedRadioColorProps()} />}
                    {...parameterViewToggleControlProps('associated tags')} 
                  />
                </RadioGroup>
              </FormControl>
            </FlexWrapper>
          </ComponentSectionRow>
          {parameterView === 'query and performance stats' ?
            <ParameterQueryAndPerformanceStats mortgageDataModel={mortgageDataModel} handleOpenSweepParameterModal={handleOpenSweepParameterModal} />
          :
            <ParameterTags mortgageDataModel={mortgageDataModel} handleOpenSweepParameterModal={handleOpenSweepParameterModal} />
          }
        </FlexWrapper>

        <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
        <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
        <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
        <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
      </ComponentContainer>

      <Dialog open={openSweepParameterModal} onClose={handleCloseSweepParameterModal} 
        PaperProps={{ sx: {width: "35rem", minWidth: "35rem", maxWidth: "35rem", height: "25rem", minHeight: "25rem", maxHeight: "25rem"}}}
      >
        <SweepParameterModal closeModal={handleCloseSweepParameterModal} userFullName={userFullName} />
      </Dialog>
    </>
  )
}

export default DataModel;