import React from "react"
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { selectTeamMacroLeadTags } from "../../slices/teamAndUserSettingsSlice";
import { CardButton, ColumnContent, ComponentSectionRow, Detail, Divider, FlexWrapper, GridWrapper, ScrollWindow, Typography } from "../../StyledComponents";

const ParameterTags = ({ mortgageDataModel }) => {
  const theme = useTheme()

  const teamMacroLeadTags = useSelector(selectTeamMacroLeadTags)

  return (
    <FlexWrapper className="column">
      <Divider className="darkGray" />
      <Typography className="subHeader medium alignTextCenter" style={{margin: '1rem auto'}}>
        This table displays the breakdown of which tags are applied when discrepancies are found in each respective field.<br/>
        Contact you App Administrator to propose new tags.
      </Typography>
      <ComponentSectionRow 
        className="header gray" 
        style={{display: 'grid', gridTemplateColumns: `22rem repeat(${teamMacroLeadTags.length}, 10.1rem)`}}
      >
        <ColumnContent className="header center" style={{gridColumn: '1', fontSize: '1rem'}}>
          label
        </ColumnContent>
        {teamMacroLeadTags.map((tag, index) => (
          <GridWrapper key={tag.label} style={{gridColumn: `${index+2}`, gridTemplateColumns: '0.1rem 10rem', height: '100%', justifyContent: 'center', overflowWrap: 'anywhere'}}>
            <Divider style={{gridColumn: '1', height: '100%', width: '100%', background: `${theme.palette.common.grayText}`}} />
            <ColumnContent className="header center" style={{gridColumn: '2', fontSize: '1rem',}}>
              {tag.label}  
            </ColumnContent>   
          </GridWrapper>
        ))}
      </ComponentSectionRow>
      <ScrollWindow className="verticalOnly configTags" style={{paddingBottom: '5rem', background: '#fff'}}>
        {mortgageDataModel.map((category, index) => (index !== 0 &&
          <div key={category.subCat}>
            <FlexWrapper className="fitContentHeight" style={{padding: '0.4rem 0 0.4rem 2rem', background: `${theme.palette.common.grayBorder}`, borderTop: index !== 1 ? `0.1rem solid ${theme.palette.common.grayText}` : 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
              <Detail className="timelineHeader">{category.subCat}</Detail>
            </FlexWrapper>
            <FlexWrapper className="column">
              {category.fields.map((sweepParameter, index) => (
                <ComponentSectionRow key={sweepParameter.label} style={{padding: index === 0 ? '0.6rem 0 0.2rem 2rem' : index === (category.fields.length - 1) ? '0.2rem 0 0.6rem 2rem' : '0.2rem 0 0.2rem 2rem', background: index%2 === 1 ? `${theme.component.row.even}` : `${theme.component.row.odd}`, display: 'grid', gridTemplateColumns: `22rem repeat(${teamMacroLeadTags.length}, 10rem)`}}>
                  <CardButton className={(sweepParameter.assignedTier === 'two' ? 'two' : sweepParameter.assignedTier === 'one' ? 'one' : 'three')} style={{width: 'fit-content', gridColumn: '1', padding: '0.1rem 0.6rem', minHeight: 'fit-content'}}>
                    {sweepParameter.label.includes('Primary') ?
                      <>
                        {sweepParameter.label.replace('Primary', '').toUpperCase()}
                      </>
                    : sweepParameter.label.includes('Secondary') ?
                      <>
                        {sweepParameter.label.replace('Secondary', '').toUpperCase()}
                      </>
                    :
                      sweepParameter.label.toUpperCase()
                    }
                  </CardButton>
                  {teamMacroLeadTags.map((leadTag, tagIndex) => (
                    sweepParameter.tags.includes(leadTag.apiMapping)  ?
                      <Detail className="center" style={{gridColumn: `${tagIndex+2}`}} key={tagIndex}>
                        <RadioButtonCheckedIcon style={{color: `${theme.palette.primary.main}`}} />
                      </Detail>
                    :
                      <Detail className="center" style={{gridColumn: `${tagIndex+2}`}} key={tagIndex}>
                        <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorderMedium}`}} />
                      </Detail>
                  ))}
                </ComponentSectionRow>
              ))}
            </FlexWrapper>
          </div>
        ))}
      </ScrollWindow>
    </FlexWrapper>
  )
}

export default ParameterTags;