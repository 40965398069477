import React from "react";
import 'react-csv-importer/dist/index.css';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useState } from "react";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useSetLeadStatusClosingMutation } from "../../../../../slices/api/apiSlice";
import { selectSetInvestigationClosingPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { Button, ButtonWrapper, ModalContentContainer, ComponentHeader, Typography } from "../../../../../StyledComponents";

const BeginClosing = ({ handleCloseModal, userId, teamId, userFullName, leadData, dashPerspective, leadUserNotifications }) => {
  const theme = useTheme();

  const [setStatusClosing] = useSetLeadStatusClosingMutation()

  const setInvestigationClosingPerm = useSelector(selectSetInvestigationClosingPerm)

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSetClosing = async () => {
    setIsLoading(true)
    setIsError(false)
    let resp = await setStatusClosing({
      leadId: leadData._id,
      teamId: teamId,
      userId: userId,
      dashPerspective: dashPerspective,
      timeline: leadData.timeline,
      userFullName: userFullName,
      userNotifications: leadUserNotifications,
    })
    if (resp.error) {
      setIsError(true)
    } else if (resp.data.message.includes('success')) {
      setIsSuccess(true)
    } else {
      setIsError(true)
    }
    setIsLoading(false)
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.</ComponentHeader>
      :
        <ComponentHeader className="modal">Initiate Closing</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <Typography className="alignTextCenter" style={{margin: '0 auto 4rem auto'}}>
            <span>This mortgage has been designating as 'closing'.</span>
          </Typography>
        :
          <Typography className="alignTextCenter" style={{margin: '0 auto 4rem auto'}}>
            Confirm this lead is in the closing<br/>
            stages of being {leadData.targetOutcome === 'renegotiation' ? 'renegotiated' : 'refinanced'}.
          </Typography>
        }
        {isSuccess || isError ?
          <ButtonWrapper>
            <Button onClick={() => handleCloseModal()} className="gray">Close</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            <Button onClick={() => handleCloseModal()} className="gray">Close</Button>
            {isLoading ?
              <Button className="primaryLoading">
                
              </Button>
            :
              <Button 
                onClick={setInvestigationClosingPerm ? () => handleSetClosing() : null} 
                className={!setInvestigationClosingPerm ? "primaryDisabled" : isLoading ? "primaryLoading" : "primary"}
              >
                {isLoading ?
                  <CircularProgress
                    sx={{ color: `${theme.palette.common.cardBackground}` }}
                    size={13}
                  />
                :
                  `Confirm`
                }
              </Button>
            }         
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default BeginClosing;