import React from "react";
import styled from "@emotion/styled";
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useDispatch, useSelector } from "react-redux";
import { NavIndex, NavText, NavigationContainer, SideBarNavHeader } from "../../../../../../StyledComponents";
import { selectDatabaseActionsNavSelection, setDatabaseActionsNavMenuSelection } from "../../../../../../slices/sessionDataSlice";

const DataBaseModalNav = () => {
  const dispatch = useDispatch()

  const navSelection = useSelector(selectDatabaseActionsNavSelection)
  
  const changeSelection = (content) => {
    dispatch( setDatabaseActionsNavMenuSelection(content))
  }

  return (
    <NavigationContainer className="modal">
      <SideBarNavHeader className="modal">Actions</SideBarNavHeader>
      {navSelection ?
        <>
          {navSelection === 'view' ?
            <ListItemNav selected>
              <NavIndex>
                <RemoveRedEyeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('view')}>
              <NavIndex>
                <RemoveRedEyeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View</NavText>
            </ListItemNav>
          }
          {navSelection === 'delete' ?
            <ListItemNav selected>
              <NavIndex>
                <DeleteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Delete</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('delete')}>
              <NavIndex>
                <DeleteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Delete</NavText>
            </ListItemNav>
          }
        </>
      :
        <>
          <ListItemNav disabled>
            <NavIndex>
              <RemoveRedEyeIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">View</NavText>
          </ListItemNav>
          <ListItemNav disabled>
            <NavIndex>
              <DeleteIcon fontSize="medium" />
            </NavIndex>
            <NavText className="disabled">Delete</NavText>
          </ListItemNav>
        </>
      }
    </NavigationContainer>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 0.3rem;
  margin: 0;
  background-color: ${(props) => props.selected && !props.disabled ? "#4B14752B" : props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor ? "default" : props.disabled ? 'not-allowed' : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  border-top: ${(props) => props.top ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  border-bottom: ${(props) => props.bottom ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  &:hover {
    background-color: ${(props) => props.selected && !props.disabled ? "#4B147533" : props.disabled ? "#E0E0E0" : "#F2F2F2"};
  }
`

export default DataBaseModalNav;