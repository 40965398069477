import React, { useState } from "react";
import ListIcon from '@mui/icons-material/List';
import GroupsIcon from '@mui/icons-material/Groups';
import SearchIcon from '@mui/icons-material/Search';
import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from "@mui/styles";
import SettingsIcon from '@mui/icons-material/Settings';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { useNavigate } from "react-router-dom";
import { selectFirstTeamReport } from "../../../../../slices/teamReportsSlice";
import { useSelector, useDispatch } from "react-redux";
import { selectTotalTeamMortgages } from "../../../../../slices/teamMortgagesSlice";
import { selectTotalTeamInvestigations } from "../../../../../slices/teamActiveLeadsSlice";
import { useRemoveReportNotifyUserMutation } from "../../../../../slices/api/apiSlice";
import { selectAppLoaded, selectNavSelection, setNavSelection } from "../../../../../slices/sessionDataSlice";
import { NavigationContainer, NavIndex, SideBarNavHeader, Typography, ListItemNav, SubListItemNav, Badge } from "../../../../../StyledComponents";
import { removeMortgageNotifcationCount, removeNewLeadNotification, removeReportNotifcationCount, selectMortgageNotifCount, selectNewAwaitingActionCount, selectNewReportRemoved, selectTotalLeadNotifCount, selectUserNewReports, setNewReportRemoved } from "../../../../../slices/userNotificationsSlice";

const Navigation = ({ userId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const appLoaded = useSelector(selectAppLoaded)
  const newReports = useSelector(selectUserNewReports)
  const firstReport = useSelector(selectFirstTeamReport)
  const navSelection = useSelector(selectNavSelection)
  const newReportRemoved = useSelector(selectNewReportRemoved)
  const mortgageNotifCount = useSelector(selectMortgageNotifCount)
  const totalTeamMortgages = useSelector(selectTotalTeamMortgages)
  const totalLeadNotifCount = useSelector(selectTotalLeadNotifCount)
  const newAwaitingActionCount = useSelector(selectNewAwaitingActionCount)
  const totalTeamInvestigations = useSelector(selectTotalTeamInvestigations)

  const [removeUserNotification] = useRemoveReportNotifyUserMutation() 
  
  const [lastOverview, setLastOverview] = useState('leadGeneration');
  const [reportsSelected, setReportsSelected] = useState(false);

  const changeSelection = (remember, content) => {
    if (remember) {
      if (content === 'refinancesOverview') {
        setLastOverview('refinance')
      } else if (content === 'renegotiationsOverview') {
        setLastOverview('renegotiation')
      } else if (content === 'renegotiationsOverview') {
        setLastOverview('renegotiation')
      }
    }
    if (content === 'investigations') {
      dispatch( removeNewLeadNotification({status: 'awaitingAction', count: newAwaitingActionCount}) )
    }
    if (content === 'mortgageRepository') {
      dispatch( removeMortgageNotifcationCount() )
    }
    navigate(`/dashboard/${content}`)
    dispatch( setNavSelection(content) )
    if (reportsSelected) {
      if (firstReport) {
        let notifyUser = firstReport.notifyUser
        if (notifyUser) {
          if (!newReportRemoved) {
            removeUserNotification({
              userId: userId,
              reportId: firstReport._id
            })
            dispatch( setNewReportRemoved(true) )
          }
        }
        setReportsSelected(false)
      }
    }
    if (content === 'reports') {
      setReportsSelected(true)
      dispatch( removeReportNotifcationCount() )
    }
  }

  const handleShowOverview = () => {
    if (lastOverview === 'refinance') {
      changeSelection(true, 'refinancesOverview')
    } else if (lastOverview === 'renegotiation') {
      changeSelection(true, 'renegotiationsOverview')
    } else {
      changeSelection(true, 'leadGeneration')
    }
  }

  return (
    <NavigationContainer>
      <SideBarNavHeader>Navigation</SideBarNavHeader>
      <ListItemNav 
        className={navSelection === 'investigations' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'investigations' || !appLoaded) ? null : () => changeSelection(false, 'investigations')}
      >
        {(totalLeadNotifCount && totalLeadNotifCount > 0) ?
          <Badge className="sideBar">
            {totalLeadNotifCount}
          </Badge>
        :<></>}
        <NavIndex>
          <FlagCircleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`, position: 'relative', right: (totalLeadNotifCount && totalLeadNotifCount > 0) ? '1.5rem' : ''}} />
        </NavIndex>
        <Typography className="primary" style={{position: 'relative', right: (totalLeadNotifCount && totalLeadNotifCount > 0) ? '1.5rem' : ''}}>
          {totalTeamInvestigations === 1 ?
            `Active Investigation`
          :
            `Active Investigations`
          }
          <span style={{marginLeft: '0.3rem'}}>
            (<span style={{padding: '0 0.05rem'}}>{totalTeamInvestigations}</span>)
          </span>
        </Typography>
      </ListItemNav>
      <ListItemNav 
        className={(navSelection === 'refinancesOverview' || navSelection === 'renegotiationsOverview' || navSelection === 'leadGeneration') ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'refinancesOverview' || navSelection === 'renegotiationsOverview' || navSelection === 'leadGeneration') ? null : () => handleShowOverview()}
      >
        <NavIndex>
          <QueryStatsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Team Performance</Typography>
      </ListItemNav>
      <SubListItemNav 
        className={navSelection === 'leadGeneration' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'leadGeneration' || !appLoaded) ? null : () => changeSelection(true, 'leadGeneration')}
      >
        <NavIndex className={navSelection === 'leadGeneration' ? 'active subNav' : 'subNav'}>
          i.
        </NavIndex>
        Lead Generation
      </SubListItemNav>
      <SubListItemNav 
        className={navSelection === 'renegotiationsOverview' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'renegotiationsOverview' || !appLoaded) ? null : () => changeSelection(true, 'renegotiationsOverview')}
      >
        <NavIndex className={navSelection === 'renegotiationsOverview' ? 'active subNav' : 'subNav'}>
          ii.
        </NavIndex>
        Renegotiations
      </SubListItemNav>
      <SubListItemNav 
        className={navSelection === 'refinancesOverview' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'refinancesOverview' || !appLoaded) ? null : () => changeSelection(true, 'refinancesOverview')}
      >
        <NavIndex className={navSelection === 'refinancesOverview' ? 'active subNav' : 'subNav'}>
          iii.
        </NavIndex>
        Refinances
      </SubListItemNav>
      <ListItemNav 
        className={navSelection === 'mortgageRepository' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'mortgageRepository' || !appLoaded) ? null : () => changeSelection(true, 'mortgageRepository')}
      >
        {(mortgageNotifCount && mortgageNotifCount > 0) ?
          <Badge className="sideBar">
            {mortgageNotifCount}
          </Badge>
        :<></>}
        <NavIndex>
          <ListIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`, position: 'relative', right: (mortgageNotifCount && mortgageNotifCount > 0) ? '1.5rem' : ''}} />
        </NavIndex>
        <Typography className="primary" style={{position: 'relative', right: (mortgageNotifCount && mortgageNotifCount > 0) ? '1.5rem' : ''}}>Mortgage Repository (<span style={{padding: '0 0.05rem'}}>{totalTeamMortgages}</span>)</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'memberPerformance' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'memberPerformance' || !appLoaded) ? null : () => changeSelection(true, 'memberPerformance')}
      >
        <NavIndex>
          <GroupsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Member Performance</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'configuration' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'configuration' || !appLoaded) ? null : () => changeSelection(true, 'configuration')}
      >
        <NavIndex>
          <BuildCircleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">System Configuration</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'propertySearch' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'propertySearch' || !appLoaded) ? null : () => changeSelection(true, 'propertySearch')}
      >
        <NavIndex>
          <SearchIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Property Search</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'reports' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'reports' || !appLoaded) ? null : () => changeSelection(true, 'reports')}
        >
        {(newReports && newReports > 0) ?
          <Badge className="sideBar">
            {newReports}
          </Badge>
        :<></>}
        <NavIndex>
          <ArticleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`, position: 'relative', right: (newReports && newReports > 0) ? '1.5rem' : ''}} />
        </NavIndex>
        <Typography className="primary" style={{position: 'relative', right: (newReports && newReports > 0) ? '1.5rem' : ''}}>Closure Reports</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'settings' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'settings' || !appLoaded) ? null : () => changeSelection(true, 'settings')}
      >
        <NavIndex>
          <SettingsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Settings</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'guide' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'guide' || !appLoaded) ? null : () => changeSelection(true, 'guide')}
      >
        <NavIndex>
          <AutoStoriesIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Information Guide</Typography>
      </ListItemNav>
    </NavigationContainer>
  )
}

export default Navigation