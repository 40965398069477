import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PendingIcon from '@mui/icons-material/Pending';
import { useTheme } from "@mui/styles";
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Divider, FlexWrapper, GuideText, MilestoneConnector, MilestonePathContainer } from "../../../../../StyledComponents";

const LeadClosingGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        Closing an Investigation
      </GuideText>
      <GuideText className="medium">
        requires that all discrepancies with public records are resolved.
      </GuideText>
      <GuideText>
        This ensures your team's mortgogage porfolio is in good standing before closing an investigation in the system.
      </GuideText>
      
      <Divider className="narrow" />
      
      <GuideText className="header">
        Standard Closing Workflow
      </GuideText>
      <FlexWrapper className="fitContentHeight" style={{padding: '1rem 0 0 0.2rem'}}>
        <MilestonePathContainer>
          <SearchIcon style={{margin: '0.4rem 0 0 0'}} />
          <MilestoneConnector style={{height: '3rem'}} />
        </MilestonePathContainer>
        <GuideText style={{paddingLeft: '0.9rem'}}>
          When an investigation leads to the new terms being established, its status should be<br/>
          changed to "closing" to indicate an agreement is in the works.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight">
        <MilestonePathContainer>
          <MilestoneConnector />
          <SportsScoreIcon style={{height: '3rem', width: '2.5rem', marginRight: '0.1rem'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
        <GuideText style={{paddingTop: '0.5rem'}}>
          When a new agreement is in place, the investigation should be "finalized".
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight" style={{paddingLeft: '0.2rem'}}>
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '1rem'}} />
          <FiberManualRecordIcon style={{height: '0.5rem'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
        <FlexWrapper className="column">
          <GuideText style={{paddingLeft: '1rem'}}>
            If a refinance was accomplished, a user is required to provide a few details on the new agreement.
          </GuideText>
          <GuideText style={{paddingLeft: '1rem'}}>
            In any situation, a user must confirm that there are no more discrepancies between internal and public records.
          </GuideText>
          <GuideText style={{paddingLeft: '1rem'}}>
            After an agreement is reached between you and your client, it may take some time before the changes are reflected in public records.
          </GuideText>
          <GuideText style={{paddingLeft: '1rem'}}>
            The turnaround is usually within days, but can vary across counties.
          </GuideText>
          <GuideText style={{paddingLeft: '1rem'}}>
            If public records match the intended state when finalizing, the investigation will be concluded.
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight">
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '1.1rem'}} />
          <PendingIcon style={{color: `${theme.icon.primary.primary}`, height: '1.9rem', width: '1.9rem'}} />
          <MilestoneConnector className="small" />
        </MilestonePathContainer>
        <GuideText className="newParagraph" style={{paddingLeft: '0.8rem'}}>
          However, finalized leads that need to wait for updates to public records will be placed in the "Awaiting Updates" pool.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight" style={{marginBottom: 'auto'}}>
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '1.1rem'}} />
          <TaskAltIcon style={{color: `${theme.icon.primary.primary}`, height: '1.9rem', width: '1.9rem'}} />
        </MilestonePathContainer>
        <FlexWrapper className="column">
          <GuideText className="newParagraph" style={{paddingLeft: '0.8rem'}}>
            Once changes are detected, assignees will be notified so that they can confirm that<br/>
            all intended updates are now reflected in public records.<br/>
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default LeadClosingGuide