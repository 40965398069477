import React from "react";
import { useTheme } from "@mui/styles";
import { FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const AssigneesUpdated = ({milestone}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="milestone" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            assigned by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        {milestone.details[0].newAssignees && milestone.details[0].newAssignees.length > 0 &&
          <FlexWrapper className='fitContentHeight'>
            {milestone.details[0].newAssignees.length === 1 ?
              <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
                added:
              </Typography>
            :
              <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
                added:
              </Typography>
            }
            <FlexWrapper className="fitContentWidth flexWrap" style={{paddingRight: '1rem'}}>
              {milestone.details[0].newAssignees.map((assignee, index) => (
                <Typography key={assignee} className="fitContentWidth bold" style={{marginLeft: index === 0 ? '0' : '0.4rem'}}>
                  {index === (milestone.details[0].newAssignees.length - 1) && milestone.details[0].newAssignees.length > 1 ?
                    <>
                      <span style={{fontWeight: '400', marginRight: '0.4rem'}}>and</span>
                      {assignee}
                    </>
                  : index === (milestone.details[0].newAssignees.length - 1) || milestone.details[0].newAssignees.length === 1 ?
                    `${assignee}`
                  : milestone.details[0].newAssignees.length === 2 ?
                    `${assignee}`
                  :
                    `${assignee},`
                  }
                </Typography>
              ))}
            </FlexWrapper>
          </FlexWrapper>
        }
        {milestone.details[0].removedAssignees && milestone.details[0].removedAssignees.length > 0 &&
          <FlexWrapper className="fitContentHeight">
            {milestone.details[0].removedAssignees.length === 1 ?
              <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
                removed:
              </Typography>
            :
              <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
                removed:
              </Typography>
            }
            <FlexWrapper className="fitContentWidth flexWrap" style={{paddingRight: '1rem'}}>
              {milestone.details[0].removedAssignees.map((assignee, index) => (
                <Typography key={assignee} className="fitContentWidth bold" style={{marginLeft: index === 0 ? '0' : '0.4rem'}}>
                  {index === (milestone.details[0].removedAssignees.length - 1) && milestone.details[0].removedAssignees.length > 1 ?
                    <>
                      <span style={{fontWeight: '400', marginRight: '0.4rem'}}>and</span>
                      {assignee}
                    </>
                  : index === (milestone.details[0].removedAssignees.length - 1) || milestone.details[0].removedAssignees.length === 1 ?
                    `${assignee}`
                  : milestone.details[0].removedAssignees.length === 2 ?
                    `${assignee}`
                  :
                    `${assignee},`
                  }
                </Typography>
              ))}
            </FlexWrapper>
          </FlexWrapper>
        }
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default AssigneesUpdated