import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { selectClearMortgageCurrentPublicRecordValuesPerm, selectClearMortgageCurrentRecordValuesPerm, selectDeleteMortgagePerm } from "../../../../slices/userActionsPermissionsSlice";
import { AccentInfo, Button, ButtonWrapper, FlexWrapper, ModalContentContainer, SubHeader } from "../../../../StyledComponents";
import { useDeleteMortgageMutation, useDeleteMortgagePublicRecordAndCurrentDetailsMutation } from "../../../../slices/api/apiSlice";

const DeleteMortgage = ({ handleCloseRepositoryModal, mortgageId, userId, teamId, userFullName, mortgageData }) => {
  const theme = useTheme()

  const [deleteMortgage, { isFetching, isSuccess, isError }] = useDeleteMortgageMutation()
  const [deletePublicAndCurrentDetails, {isSuccess: deletePRIsSuccess}] = useDeleteMortgagePublicRecordAndCurrentDetailsMutation()

  const deleteMortgagePerm = useSelector(selectDeleteMortgagePerm)
  const clearMortgageCurrentRecordValuesPerm = useSelector(selectClearMortgageCurrentRecordValuesPerm)
  const clearMortgageCurrentPublicRecordValuesPerm = useSelector(selectClearMortgageCurrentPublicRecordValuesPerm)

  const [mortgageIdToDelete, setMortgageIdToDelete] = useState(mortgageId)
  const [mortgageNotFound, setMortgageNotFound] = useState(false)

  const handleDeletePublicRecords = async () => {
    deletePublicAndCurrentDetails({
      mortgageId: mortgageId,
      userFullName: userFullName,
      recordDetails: mortgageData.recordDetails,
    })
  }

  const handleDelete = async () => {
    if (mortgageIdToDelete) {
      let response = await deleteMortgage({
        mortgageId: mortgageIdToDelete,
        userId: userId,
        teamId: teamId,
      })
      if (response.data.data.mortgageNotFound) {
        setMortgageNotFound(true)
      } else {
        setMortgageNotFound(false)
      }
    } else {
      setMortgageNotFound(true)
    }
    setMortgageIdToDelete(null)
  }

  return (
    <ModalContentContainer className="topColumn" style={{gridRow: '2/5', padding: '1rem 0.5rem 0 1rem'}}>
      <FlexWrapper>
        {deletePRIsSuccess ?
          <AccentInfo>success</AccentInfo>
        :
          <Button className={(clearMortgageCurrentRecordValuesPerm && clearMortgageCurrentPublicRecordValuesPerm) ? "primary" : "primaryDisabled"}style={{width: '15rem', height: 'fit-content', padding: '0.6rem 1rem', margin: '0 auto'}} onClick={(clearMortgageCurrentRecordValuesPerm && clearMortgageCurrentPublicRecordValuesPerm) ? () => handleDeletePublicRecords() : null}>
            Clear Public Record and Current Value Details
          </Button>
        }
      </FlexWrapper>
      <FlexWrapper className="column alignCenter justifyCenter">
        {mortgageNotFound ?
          <SubHeader className="modal">
            Mortgage not found.
          </SubHeader>
        : isSuccess ?
          <SubHeader className="modal">
            Mortgage records successfully removed.
          </SubHeader>
        : isError ?
          <SubHeader className="modal">
            A problem was encountered.
          </SubHeader>
        :
          <SubHeader className="modal">
            Remove all records for this mortgage?
          </SubHeader>
        }
        {isFetching ?
          <ButtonWrapper>
            <Button disabled className="deleteLoading">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.buttonColor}` }}
                size={12}
              />
            </Button>
              <Button disabled onClick={() => handleCloseRepositoryModal()} className="secondary">
              Close
            </Button>
          </ButtonWrapper>
        : isSuccess || mortgageNotFound ?
          <Button onClick={() => handleCloseRepositoryModal()} className="secondary">
            Close
          </Button>
        :
          <ButtonWrapper>
            <Button onClick={deleteMortgagePerm ? () => handleDelete() : null} className={deleteMortgagePerm ? "delete" : "deleteDisabled"}>
              Delete
            </Button>
            <Button onClick={() => handleCloseRepositoryModal()} className="secondary">
              Close
            </Button>
          </ButtonWrapper>
        }
      </FlexWrapper>
    </ModalContentContainer>
  )
}

export default DeleteMortgage;