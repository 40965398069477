import React, { useState } from "react";
import ChartToggle from "./components/ChartToggle";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import MonthlyAllChart from "./components/Charts/MonthlyAllChart";
import CircularProgress from "@mui/material/CircularProgress";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import MonthlyOneYearChart from "./components/Charts/MonthlyOneYearChart";
import MonthlyTwoYearChart from "./components/Charts/MonthlyTwoYearChart";
import { selectTeamClosedRenegotiations } from "../../../../../../slices/teamStatsSlice";
import { HeaderContainer, HeaderText, MainChartWrapper, ColumnContent, ComponentContainer, ComponentBorder, FlexWrapper } from "../../../../../../StyledComponents";
import { selectTeamRenegotiationMonthlyStatsAll, selectTeamRenegotiationMonthlyStatsOneYear, selectTeamRenegotiationMonthlyStatsTwoYear } from "../../../../../../slices/teamMonthlyStatsSlice";

const MainChart = () => {
  const theme = useTheme();
  const [chartTimeline, setChartTimeline] = useState('All'); 
  const handleTimelineChange = (event) => {
    setChartTimeline(event.target.value);
  };
  const [chartPerspective, setChartPerspective] = useState('Monthly Stats'); 
  const handleChartPerspectiveChange = (event) => {
    setChartPerspective(event.target.value);
    setChartTimeline('All');
  };

  const teamClosedRenegotiations = useSelector(selectTeamClosedRenegotiations)
  
  const teamRenegotiationMonthlyStatsAll = useSelector(selectTeamRenegotiationMonthlyStatsAll)
  const teamRenegotiationMonthlyStatsTwoYear = useSelector(selectTeamRenegotiationMonthlyStatsTwoYear)
  const teamRenegotiationMonthlyStatsOneYear = useSelector(selectTeamRenegotiationMonthlyStatsOneYear)


  return (
    <ComponentContainer className="detailsContainer" style={{gridRow: '3/9', gridColumn: '3', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer className="chart">
          <HeaderText className="chart" style={{gridColumn: '1', position: 'absolute'}}>
            {chartPerspective === 'Monthly Stats' ?
              `Monthly Stats`
            :
              ''
            }
          </HeaderText>
          <ChartToggle handleTimelineChange={handleTimelineChange} chartTimeline={chartTimeline} handleChartPerspectiveChange={handleChartPerspectiveChange} chartPerspective={chartPerspective} />
        </HeaderContainer>
        <MainChartWrapper>
          {teamClosedRenegotiations !== null && teamRenegotiationMonthlyStatsAll !== undefined && teamRenegotiationMonthlyStatsTwoYear !== undefined && teamRenegotiationMonthlyStatsOneYear !== undefined ?
            <>
              {teamClosedRenegotiations === 0 ?
                <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                  <TroubleshootIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '20rem', width: '20rem'}} />
                  <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no renegotiations completed</HeaderText>
                </FlexWrapper>
              : chartPerspective === 'Monthly Stats' && chartTimeline === 'All' ?
                <MonthlyAllChart monthlyStatsAll={teamRenegotiationMonthlyStatsAll} />
              : chartPerspective === 'Monthly Stats' && chartTimeline === '2 Year' ?
                <MonthlyTwoYearChart monthlyStatsTwoYear={teamRenegotiationMonthlyStatsTwoYear} />
              : chartPerspective === 'Monthly Stats' && chartTimeline === '1 Year' ?
                <MonthlyOneYearChart monthlyStatsYear={teamRenegotiationMonthlyStatsOneYear} />
              :
                <></>
              }
            </>
          :
            <ColumnContent className="single">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </ColumnContent>
          }
        </MainChartWrapper>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default MainChart;