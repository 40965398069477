import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { selectTeamRenegotiationQuarterBreakdown } from "../../../../../slices/teamMonthlyStatsSlice";
import { ColumnContent, HeaderText, HeaderContainer, HorizontalContainer, ComponentContainer, VerticalContainer, FlexWrapper, ComponentBorder } from "../../../../../StyledComponents";

const TeamQuarterlyBreakdown = () => {
  const theme = useTheme();

  const teamRenegotiationQuarterBreakdown = useSelector(selectTeamRenegotiationQuarterBreakdown)

  return (
    <ComponentContainer className='thinBorders detailsContainer' style={{gridRow: '8', gridColumn: '1', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer style={{height: '5rem', padding: '0.3rem 0.8rem'}}>
          <HeaderText>Quarterly Breakdown</HeaderText>
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "1.8rem",
              margin: '0 0 0 0',
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, -4],
              },
            }]}}
            title={<div style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem"}}>Top: This quarter's renegotiations.</p>
                <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem"}}>Bottom: Last quarter's renegotiations.</p>
              </div>} 
            placement="top"
            arrow
          >
            <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
              <HelpOutlineIcon />
            </span>
          </Tooltip>
        </HeaderContainer>
        <HorizontalContainer style={{flexDirection: 'column', justifyContent: 'space-between', height: '100%', alignItems: 'center'}}>
          {teamRenegotiationQuarterBreakdown.currentRenegotiations === 0 ?
            <VerticalContainer style={{borderBottom: `0.1rem solid ${theme.palette.common.grayText}`, padding: '1rem 0'}}>
              <ColumnContent className="quarter">
                {teamRenegotiationQuarterBreakdown.currentQuarter}
              </ColumnContent>
              <ColumnContent className="single gray column">
                <span>no renegotiations</span>
                <span>in current quarter</span>
              </ColumnContent>
            </VerticalContainer>
          :
            <VerticalContainer style={{borderBottom: `0.1rem solid ${theme.palette.common.grayText}`, padding: '1rem 0'}}>
              <ColumnContent className="quarter">
                {teamRenegotiationQuarterBreakdown.currentQuarter}
              </ColumnContent>
              {teamRenegotiationQuarterBreakdown.currentRenegotiations === 1 ?
                <ColumnContent className="highlight renegotiations">
                  {teamRenegotiationQuarterBreakdown.currentRenegotiations.toLocaleString("en-US")}
                  <span style={{color: `${theme.palette.common.black}`}}>renegotiation</span>
                </ColumnContent>
              :
                <ColumnContent className="highlight renegotiations">
                  {teamRenegotiationQuarterBreakdown.currentRenegotiations.toLocaleString("en-US")}
                  <span style={{color: `${theme.palette.common.black}`}}>renegotiations</span>
                </ColumnContent>
              }
            </VerticalContainer>
          }
          {teamRenegotiationQuarterBreakdown.currentProgress === 'N/A' ?
            <VerticalContainer style={{padding: '1rem 0'}}>
              <ColumnContent className="quarter">
                {teamRenegotiationQuarterBreakdown.previousQuarter}
              </ColumnContent>
              <VerticalContainer>
                <ColumnContent className="single gray column">
                  <span>no renegotiations</span>
                  <span>in previous quarter</span>
                </ColumnContent>
              </VerticalContainer>
              </VerticalContainer>
          :
            <VerticalContainer style={{padding: '1rem 0'}}>
              <ColumnContent className="quarter">
                {teamRenegotiationQuarterBreakdown.previousQuarter}
              </ColumnContent>
              {teamRenegotiationQuarterBreakdown.previousRenegotiations === 1 ?
                <ColumnContent className="breakdown column" style={{height: "100%"}}>
                  {teamRenegotiationQuarterBreakdown.previousRenegotiations.toLocaleString("en-US")}
                  <span style={{color: `${theme.palette.common.black}`}}>renegotiation</span>
                </ColumnContent>
              :
                <ColumnContent className="breakdown column" style={{height: "100%"}}>
                  {teamRenegotiationQuarterBreakdown.previousRenegotiations.toLocaleString("en-US")}
                  <span style={{color: `${theme.palette.common.black}`}}>renegotiations</span>
                </ColumnContent>
              }
            </VerticalContainer>
          }
        </HorizontalContainer>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default TeamQuarterlyBreakdown;