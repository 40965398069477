import React from "react";
import { useTheme } from "@mui/styles";
import { AccentInfo, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const DiscrepanciesAllResolved = ({milestone}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`, minWidth: '36.5rem', width: '36.5rem', maxWidth: '36.5rem'}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="milestone" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            performed by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight' style={{marginBottom: '0.5rem'}}>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        {milestone.details.map((detail, index) => (
          <FlexWrapper key={detail.label} className='fitContentHeight'>
            <Typography className="fitContentHeight justifyEnd" style={{width: '13rem', marginRight: '0.5rem'}}>
              {detail.label === 'Primary Mortgage Interest Rate' ?
                <span>Mortgage Interest Rate:</span>
              : detail.label === 'Primary Mortgage Start Date' ?
                <span>Mortgage Start Date:</span>
              : detail.label === 'Primary Mortgage Due Date' ?
                <span>Mortgage Due Datee:</span>
              : detail.label === 'Primary Lender Name' ?
                <span>Mortgage Type:</span>
              : detail.label === 'Primary Loan Type' ?
                <span>Mortgage Amount:</span>
              : detail.label === 'Primary Mortgage Term' ?
                <span>Mortgage Term:</span>
              : detail.label === 'Primary Mortgage Amount' ?
                <span>Mortgage Amount:</span>
              : detail.label === 'Secondary Mortgage Interest Rate' ?
                <span>2nd Mortgage Start Date:</span>
              : detail.label === 'Secondary Mortgage Start Date' ?
                <span>2nd Mortgage Start Date:</span>
              : detail.label === 'Secondary Mortgage Due Date' ?
                <span>2nd Mortgage Due Datee:</span>
              : detail.label === 'Secondary Lender Name' ?
                <span>2nd Mortgage Type:</span>
              : detail.label === 'Secondary Loan Type' ?
                <span>2nd Mortgage Amount:</span>
              : detail.label === 'Secondary Mortgage Term' ?
                <span>2nd Mortgage Term:</span>
              : detail.label === 'Secondary Mortgage Amount' ?
                <span>2nd Mortgage Amount:</span>
              : detail.label === 'Last Sale Recording Document ID' ?
                <span>Sale Recording Doc ID:</span>
              : detail.label === 'Last Sale Document Type' ?
                <span>Last Sale Doc Type:</span>
              : detail.label === 'Secondary Mortgage Start Date' ?
                <span>2nd Mortgage Start Date:</span>
              : detail.label === 'Secondary Mortgage Due Date' ?
                <span>2nd Mortgage Due Datee:</span>
              : detail.label === 'Last Sale Seller 1 Full Name' ?
                <span>Seller 1 Full Name:</span>
              : detail.label === 'Last Sale Seller 1 First Name' ?
                <span>Seller 1 First Name:</span>
              : detail.label === 'Last Sale Seller 1 Last Name' ?
                <span>Seller 1 Last Name:</span>
              : detail.label === 'Last Sale Seller 2 Full Name' ?
                <span>Seller 2 Full Name:</span>
              : detail.label === 'Last Sale Seller 2 First Name' ?
                <span>Seller 2 First Name:</span>
              : detail.label === 'Last Sale Seller 2 Last Name' ?
                <span>Seller 2 Last Name:</span>
              : detail.label === 'Last Sale Recording Date' ?
                <span>Sale Recording Date:</span>
              : detail.label === 'Assessed Improvement Value' ?
                <span>Assmt Impr Value:</span>
              : detail.label === 'Last Sale Doc Type' ?
                <span>Sale Doc Type:</span>
              : detail.label === 'Street Address One Line' ?
                <span>Street Address:</span>
              :
                <span>{detail.label}:</span>
              }
            </Typography>
            <Typography className="bold alignStart">
              {detail.new === 'Y' ?
                'YES'
              : detail.new === 'N' ?
                'NO'
              : detail.old && !detail.new ?
                <AccentInfo>-none-</AccentInfo>
              : detail.field.includes('Rate')
                || detail.field.includes('%') ?
                `${detail.old}%`
              : detail.field.includes('Price')
                || detail.field.includes('Amount')
                || detail.field.includes('Value') ?
                  typeof detail.old === 'number' ?
                  `$${detail.old.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`
                :
                  <AccentInfo style={{fontWeight: '400'}}>-none-</AccentInfo>
              :
                detail.old
              }
            </Typography>
            <span style={{margin: '0 0.5rem', fontWeight: '400', height: '100%'}}>
              <AccentInfo>
                to
              </AccentInfo>
            </span>
            <Typography className="bold alignStart">
              {detail.new === 'Y' ?
                'YES'
              : detail.new === 'N' ?
                'NO'
              : detail.field.includes('Rate') ?
                `${detail.new}%`
              : detail.field.includes('Price')
              || detail.field.includes('Amount')
              || detail.field.includes('Value') ?
                typeof detail.new === 'number' ?
                  `$${detail.new.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`
                :
                  <AccentInfo style={{fontWeight: '400'}}>-none-</AccentInfo>
              :
                detail.new
              }
            </Typography>
          </FlexWrapper>
        ))}
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default DiscrepanciesAllResolved