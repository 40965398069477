import React, { useEffect, useState } from "react"
import ClearIcon from '@mui/icons-material/Clear'
import { Button, CompareLink, FadeWrapper, FlexWrapper, Typography } from "../../../../../../../StyledComponents"

const BooleanSelect = ({ handleWorkFlow, handleSubmitEdit, recordField, recordLabel }) => {

  const [newValue1, setNewValue1] = useState('')
  const [newValue2, setNewValue2] = useState('')
  const [standIn1, setStandIn1] = useState('')
  const [standIn2, setStandIn2] = useState('')

  useEffect(() => {
    if (recordField === 'Owner1IsCorporation' || recordField === 'CorporateIndicator' || recordField === 'Owner2IsCorporation' || recordField === 'OwnerOccupied' || recordField === 'DistressYN') {
      setStandIn1('YES')
      setStandIn2('NO')
      setNewValue1('Y')
      setNewValue2('N')
    } else if (recordField === 'QuitClaimFlag' || recordField === 'REOflag') {
      setStandIn1('TRUE')
      setStandIn2('FALSE')
      setNewValue1('TRUE')
      setNewValue2('FALSE')
    } else if (recordField === 'LeaseholdOrFeeSimple') {
      setStandIn1('LEASEHOLD')
      setStandIn2('FEESIMPLE')
      setNewValue1('LEASEHOLD')
      setNewValue2('FEESIMPLE')
    }
  }, [recordField])

  return (
      <FadeWrapper>
        <FlexWrapper className="column">
          <Typography className="justifyCenter" style={{marginBottom: '1rem'}}>{recordLabel}?</Typography>
          <FlexWrapper className="fitContentHeight alignCenter justifyCenter">
            <CompareLink 
              className="small gray" 
              onClick={() => handleSubmitEdit('edit', newValue1)} 
              style={{padding: '0.4rem 0.8rem', marginLeft: '7rem'}}
            >
              {standIn1}
            </CompareLink>
            <Typography className="accent" style={{padding: '0 0.5rem'}}>or</Typography>
            <CompareLink 
              className="small gray" 
              onClick={() => handleSubmitEdit('edit', newValue2)} 
              style={{padding: '0.4rem 0.8rem'}}
            >
              {standIn2}
            </CompareLink>
            <Button 
              className="neutral input" 
              onClick={() => handleWorkFlow('', '')} 
              style={{margin: '0 2rem'}}
            >
              <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
            </Button>
          </FlexWrapper>
        </FlexWrapper>
      </FadeWrapper>
  )
}

export default BooleanSelect