import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PendingIcon from '@mui/icons-material/Pending';
import { useTheme } from "@mui/styles";
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Divider, FlexWrapper, GuideText, MilestoneConnector, MilestonePathContainer } from "../../../../../StyledComponents";

const LeadClosingGuide = () => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        Closing an Investigation
      </GuideText>
      <GuideText className="medium">
        requires that all discrepancies with public records are resolved.
      </GuideText>
      <GuideText>
        This ensures your team's mortgogage porfolio is in good standing before moving on from a case.
      </GuideText>
      
      <Divider className="narrow" />
      
      <GuideText className="header">
        Standard Closing Workflow
      </GuideText>
      <FlexWrapper className="fitContentHeight" style={{padding: '1rem 0 0 0.2rem'}}>
        <MilestonePathContainer>
          <SearchIcon style={{margin: '0.4rem 0 0 0'}} />
          <MilestoneConnector style={{height: '3rem'}} />
        </MilestonePathContainer>
        <GuideText style={{paddingLeft: '0.9rem'}}>
          Investigation confirms mitigating actions are required, change it's status<br/>
          to "closing" to indicate an agreement is in the works.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight">
        <MilestonePathContainer>
          <MilestoneConnector />
          <SportsScoreIcon style={{height: '3rem', width: '2.5rem', marginRight: '0.1rem'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
        <GuideText style={{paddingTop: '0.5rem'}}>
          When a new agreement is in place, the lead should be "finalized".
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight" style={{paddingLeft: '0.2rem'}}>
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '1rem'}} />
          <FiberManualRecordIcon style={{height: '0.5rem'}} />
          <MilestoneConnector />
        </MilestonePathContainer>
        <FlexWrapper className="column">
          <GuideText style={{paddingLeft: '1rem'}}>
            To do so, a user is required to provide updated details on the new agreement, and must confirm that there are
          </GuideText>
          <GuideText style={{paddingLeft: '1rem'}}>
            no more discrepancies between internal and public records.
          </GuideText>
          <GuideText style={{paddingLeft: '1rem'}}>
            However, after an agreement is reached, it will take some time before the changes are reflected in public records.
          </GuideText>
          <GuideText style={{paddingLeft: '1rem'}}>
            The turnaround is usually within days, but can vary across counties.
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight">
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '1.1rem'}} />
          <PendingIcon style={{color: `${theme.icon.primary.primary}`, height: '1.9rem', width: '1.9rem'}} />
          <MilestoneConnector className="small" />
        </MilestonePathContainer>
        <GuideText className="newParagraph" style={{paddingLeft: '0.8rem'}}>
          Finalized leads are therefore placed in the "Awaiting Updates" pool where they will wait for an update to public records.
        </GuideText>
      </FlexWrapper>
      <FlexWrapper className="fitContentHeight" style={{marginBottom: 'auto'}}>
        <MilestonePathContainer>
          <MilestoneConnector style={{height: '1.1rem'}} />
          <TaskAltIcon style={{color: `${theme.icon.primary.primary}`, height: '1.9rem', width: '1.9rem'}} />
        </MilestonePathContainer>
        <FlexWrapper className="column">
          <GuideText className="newParagraph" style={{paddingLeft: '0.8rem'}}>
            Once detected, assignees will be notified so that they can enter the system and confirm that all
          </GuideText>
          <GuideText style={{paddingLeft: '0.8rem'}}>
            intended updates are reflected in the public records.
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default LeadClosingGuide