import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-csv-importer/dist/index.css';
import "../../../../../styles.css";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CircularProgress from "@mui/material/CircularProgress";
import { selectResolveAllMortgageDiscrepanciesPerm, selectSetValidateLeadAwaitingUpdatePerm } from "../../../../../slices/userActionsPermissionsSlice";
import { tooltipComponentProps, tooltipPopperProps } from "../../../../../componentThemes";
import { useResolveAllDiscrepanciesMutation, useVerifyPublicRecordsMutation } from "../../../../../slices/api/apiSlice";
import { ComponentHeader, Button, ButtonWrapper, ModalContentContainer, SubHeader, FlexWrapper, DetailWrapper, Typography } from "../../../../../StyledComponents";

const ConfirmUpdate = ({ handleCloseModal, leadData, teamId, userId, userFullName, dashPerspective, mortgageData }) => {
  const theme = useTheme()

  const validateLeadAwaitingUpdatePerm = useSelector(selectSetValidateLeadAwaitingUpdatePerm)
  const resolveAllMortgageDiscrepanciesPerm = useSelector(selectResolveAllMortgageDiscrepanciesPerm)

  const [resolveAllDiscrepancies, {isFetching: resolveIsFetching, isSuccess: resolveIsSuccess, isError: resolveIsError}] = useResolveAllDiscrepanciesMutation()
  const [verifyPublicRecords, {isFetching: confirmIsFetching, isSuccess: confirmIsSuccess, isError: confirmIsError}] = useVerifyPublicRecordsMutation()


  const handleResolveAll = async () => {
    await resolveAllDiscrepancies({
      userId: userId,
      userFullName: userFullName,
      teamId: teamId,
      dashPerspective: dashPerspective,
      mortgageId: mortgageData._id,
      mission: 'confirmUpdate'
    })
  }

  const handleConfirmRecords = async () => {
    await verifyPublicRecords({
      updateReport: true,
      timeline: leadData.timeline,
      userFullName: userFullName,
      teamId: teamId,
      leadId: leadData._id,
      dashPerspective: dashPerspective,
      mortgageId: mortgageData._id,
    })
  }

  return (
    <>
      {confirmIsSuccess || resolveIsSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />
          Success
        </ComponentHeader>
      : confirmIsError || resolveIsError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        <ComponentHeader className="modal">Confirm Public Records</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {(mortgageData.activeDiscrepancies > 0 || mortgageData.rejectedDiscrepancies) && !confirmIsSuccess ?
          <FlexWrapper className="heightFitContent justifyCenter" style={{marginTop: '12rem', height: 'fit-content'}}>
            <FlexWrapper className="alignCenter justifyCenter" style={{height: 'fit-content', margin: '0.25rem 3rem'}}>
              <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.redBright}`, height: '6.8rem'}}>
                <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
              </FlexWrapper>
              <div style={{border: `0.1rem solid ${theme.palette.common.redDark}`, padding: '0.3rem 5rem 0.3rem 1rem', borderRadius: '0 1rem 1rem 0', background: `${theme.palette.common.redTransparent}`, borderLeft: 'none', color: `${theme.palette.common.redDark}`}}>
                The lead currently has active discrepencies. Confirm they have been resolved before continuing.<br/>
                <DetailWrapper className={resolveAllMortgageDiscrepanciesPerm ? "addButton" : "addButtonDisabled"} onClick={resolveAllMortgageDiscrepanciesPerm ? () => handleResolveAll() : null} style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', gridColumn: '2', margin: '1.5rem auto 1rem auto'}}>
                  Resolve All
                  <AutoFixHighIcon style={{height: '1.4rem', width: '1.4rem', color: `${theme.palette.primary.main}`, margin: '0 0 0 0.3rem'}} /> 
                </DetailWrapper>
              </div>
            </FlexWrapper>
          </FlexWrapper>
        : 
          <div style={{height: '2.7rem'}}></div>
        }
        <FlexWrapper className="column justifyCenter">
          {confirmIsSuccess ?
            <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <Typography className="small" style={{margin: 'auto'}}>The mortgage status has been confirmed.</Typography>
              <span style={{height: '1.5rem'}}></span>
              <span style={{height: '1.5rem'}}></span>
            </SubHeader>
          :
            <SubHeader className="modal" style={{margin: '0 auto 1rem auto'}}>
              <Typography className="small">Confirm that the Public Records reflect the expected state of the mortgage and property arrangements.</Typography>
              <Typography className="small">The mortgage will then return to the "inactive" pool for continued monitoring.</Typography>
              <span style={{height: '1.5rem'}}></span>
            </SubHeader>
          }
        </FlexWrapper>
        <ButtonWrapper style={{margin: '0 auto 12rem auto'}}>
          {(mortgageData.activeDiscrepancies > 0 || mortgageData.rejectedDiscrepancies) && !confirmIsSuccess ?
            <>
              <Button onClick={() => handleCloseModal()} className="gray">Close</Button>
              <Tooltip
                componentsProps={tooltipComponentProps}
                PopperProps={tooltipPopperProps}
                title={<FlexWrapper><span style={{padding: '0', margin: '0', fontWeight: '600'}}>must resolve all discrepancies</span></FlexWrapper>} 
                placement="top"
                arrow
              >
                <Button className="primaryDisabled">Confirm</Button>
              </Tooltip>
            </>
          : confirmIsFetching || resolveIsFetching ?
            <>
              <Button onClick={() => handleCloseModal()} className="gray">Close</Button>
              <Button className="primaryLoading">
                <CircularProgress sx={{ color: `${theme.palette.common.cardBackground}` }} size={13} />
              </Button>
            </>
          : confirmIsSuccess ?
            <>
              <Button onClick={() => handleCloseModal()} className="gray">Close</Button>
            </>
          :
            <>
              <Button onClick={() => handleCloseModal()} className="gray">Close</Button>
              <Button onClick={validateLeadAwaitingUpdatePerm ? () => handleConfirmRecords() : null} className={validateLeadAwaitingUpdatePerm ? "primary" : "primaryDisabled"}>Confirm</Button>
            </>
          }         
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default ConfirmUpdate;