import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from "@mui/styles";
import SweepPerformances from "../../Windows/SweepPerformances";
import { ActionIconWrapper, ModalContainer, ComponentBorder, Typography } from "../../../StyledComponents";

const SweepPerformanceModal = ({ handleCloseModal, origin }) => {
  const theme = useTheme()

  return (
    <ModalContainer className="onePanel small" style={{backgroundColor: `${theme.component.background.primary}`}}>
      <ComponentBorder className="top" style={{gridColumn: '1/4'}}>
        <Typography className="xLarge" style={{margin: '0 auto 0.5rem 3rem'}}>Monthly Sweep Summaries</Typography>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>

      <SweepPerformances origin={origin} />

      <ComponentBorder className="left" style={{gridRow:'2/4'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/4', gridColumn: '3'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'3'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default SweepPerformanceModal