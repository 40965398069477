import React, { useCallback, useEffect, useState } from "react";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useSelector } from "react-redux";
import SelectedMemberInfo from "./components/SelectedMemberInfo";
import SelectedMemberChart from "./components/SelectedMemberChart";
import SelectedMemberStats from "./components/SelectedMemberStats";
import { selectTeamMembersByName, selectTeamMembersInfo, selectTeamMembersMonthlyStats } from "../../../../slices/teamMembersSlice";
import { Divider, FlexWrapper, HeaderContainer, ListItemNav, NavIndex, NavText, PageContent, ScrollWindow, TreeContainer, Typography } from "../../../../StyledComponents";

const MemberPerformance = () => {

  const [selectedMemberId, setSelectedMemberId] = useState('')
  const [selectedMemberInfoObj, setSelectedMemberInfoObj] = useState({})
  const [selectedMemberFirstName, setSelectedMemberFirstName] = useState('')
  const [selectedMemberMonthlyStats, setSelectedMemberMonthlyStats] = useState({})

  const teamMembersMonthlyStats = useSelector(selectTeamMembersMonthlyStats)
  const teamMembersByName = useSelector(selectTeamMembersByName)
  const teamMembersInfo = useSelector(selectTeamMembersInfo)

  const handleMemberChange = useCallback((memberId) => {
    let thisMember = teamMembersByName.find(member => member.memberId === memberId)
    if (thisMember) {
      setSelectedMemberId(thisMember.memberId)
      setSelectedMemberFirstName(thisMember.firstName)
      let thisMemberInfo = teamMembersInfo.find(member => member.memberId === memberId)
      setSelectedMemberInfoObj(thisMemberInfo)
      let thisMemberMonthlyStats = teamMembersMonthlyStats.find(member => member.memberId === memberId)
      setSelectedMemberMonthlyStats(thisMemberMonthlyStats.memberMonthlyStats)
    } else {
      setSelectedMemberId(teamMembersByName[0].memberId)
      setSelectedMemberFirstName(teamMembersByName[0].firstName)
      setSelectedMemberInfoObj(teamMembersInfo[0])
      setSelectedMemberMonthlyStats(teamMembersMonthlyStats[0].memberMonthlyStats)
    }
  }, [teamMembersByName, teamMembersInfo, teamMembersMonthlyStats])

  useEffect(() => {
    handleMemberChange(teamMembersByName[0].memberId)
  }, [handleMemberChange, teamMembersByName])

  return (
    <PageContent className="grid members">
      <TreeContainer style={{gridRow: '2/5', minHeight: '41.2rem'}}>
        <FlexWrapper className="column">
          <HeaderContainer className="guide">
            <Typography className="guideHeader">Team Members</Typography>
          </HeaderContainer>
          <FlexWrapper className="column" style={{padding: '1rem 1.5rem'}}>
            <Typography>Brokers</Typography>
            <Divider className="full darkGray" />
            <ScrollWindow className="fitContentHeight verticalOnly" style={{minHeight: '3.5rem'}}>
              {teamMembersByName.map((member) => (
                member.role === 'user' &&
                <ListItemNav key={member.memberId} className={selectedMemberId === member.memberId ? 'pageActive' : ''} onClick={() => handleMemberChange(member.memberId)}>
                  <NavIndex className={member.role === 'admin' ? 'admin' : 'user'}>
                    <AccountBoxIcon />
                  </NavIndex>
                  <NavText className="main">{member.fullName}</NavText>
                </ListItemNav>
              ))}
            </ScrollWindow>
            <Divider className="full darkGray" />
            <Typography style={{paddingTop: '1rem'}}>Managers</Typography>
            <Divider className="full darkGray" />
            <ScrollWindow className="fitContentHeight verticalOnly" style={{minHeight: '3.5rem'}}>
              {teamMembersByName.map((member) => (
                member.role === 'admin' &&
                <ListItemNav key={member.memberId} className={selectedMemberId === member.memberId ? 'pageActive' : ''} onClick={() => handleMemberChange(member.memberId)}>
                  <NavIndex className={member.role === 'admin' ? 'admin' : 'user'}>
                    <AccountBoxIcon />
                  </NavIndex>
                  <NavText className="main">{member.fullName}</NavText>
                </ListItemNav>
              ))}
            </ScrollWindow>
            <Divider className="full darkGray" />
            <SelectedMemberInfo selectedMemberInfoObj={selectedMemberInfoObj} />
          </FlexWrapper>
        </FlexWrapper>
      </TreeContainer>
      <SelectedMemberStats selectedMemberInfoObj={selectedMemberInfoObj} selectedMemberFirstName={selectedMemberFirstName} />
      <SelectedMemberChart selectedMemberMonthlyStats={selectedMemberMonthlyStats} selectedMemberFirstName={selectedMemberFirstName} />
    </PageContent>
  )
}

export default MemberPerformance