import React, { useState, useRef } from "react";
import WhiteLogo from "../../Full.logo.white.png";
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@emotion/react";
import { Navigate, useNavigate } from 'react-router-dom';
import { CircularProgress } from "@mui/material";
import { selectLaunchRequired, setLaunchRequired } from "../../slices/sessionDataSlice";
import { useLaunchTeamMutation } from "../../slices/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLaunchTeamEnabled, setLaunchTeamEnabled, setLoginEnabled } from "../../slices/functionAvailabilitySlice";
import { AuthContainer, Button, Label, InputWrapper, PageContainer, StatusAndLabelWrapper, StyledInputElement, AlertWrapper, ImageElements, FlexWrapper, ComponentBorder, Typography } from "../../StyledComponents";

const Launch = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [launchTeam] = useLaunchTeamMutation()
  const launchRequired = useSelector(selectLaunchRequired)
  const launchTeamEnabled = useSelector(selectLaunchTeamEnabled)

  const newToken = useRef()
  const [tokenEmpty, setTokenEmpty] = useState(true)
  const [isFetching, setIsFetching] = useState(null)
  const [requestError, setRequestError] = useState(null)
  const [statusNewToken, setStatusNewToken] = useState(null)

  const handleSubmit = async () => {
    setIsFetching(true)
    if (newToken.current.value.includes('<') || newToken.current.value.includes('>') || newToken.current.value.length > 13) {
      setStatusNewToken('invalid')
    } else {
      let launchAttempt = await launchTeam({
        registrationToken: newToken.current.value,
      })
      if (launchAttempt.data.message.includes('Invalid')) {
        setStatusNewToken('invalid')
      } else if (launchAttempt.data.message.includes('success') || launchAttempt.data.message.includes('launched')) {
        dispatch( setLaunchTeamEnabled(false) )
        dispatch( setLaunchRequired(false) )
        dispatch( setLoginEnabled(true) )
        navigate("/login")
      } else {
        setRequestError(true)
      }
    }
    setIsFetching(false)
  }

  return (
    <PageContainer className="login">
      {launchRequired ? <Navigate to="/launch" /> : <></>}
      <ComponentBorder className="loginTop">
        <ImageElements src={WhiteLogo} className="authNav" />
      </ComponentBorder>
      <AuthContainer style={{gridColumn: '1', gridRow: '2', width: '40rem'}}>
        <FlexWrapper className="column">
          <FlexWrapper className="column fitContentHeight fitContentWidth" style={{margin: '3rem auto 0 auto'}}>
            <Typography className="fader exoItalic dark medium">
              Awaiting launch instructions...
            </Typography>
          </FlexWrapper>
          <InputWrapper style={{margin: '2rem auto 4rem auto'}}>
            <StatusAndLabelWrapper>
              <Label htmlFor="registrationToken">Registration Token</Label>
              {statusNewToken === "invalid" && (
                <AlertWrapper className="error">
                  <ErrorIcon />
                  Invalid
                </AlertWrapper>
              )}
              {requestError && (
                <AlertWrapper className="error">
                  <ErrorIcon />
                  Something went wrong
                </AlertWrapper>
              )}
            </StatusAndLabelWrapper>
            <StyledInputElement 
              autoFocus
              autoComplete="off"
              type="password"
              name="registrationToken"
              id="registrationToken"
              className={statusNewToken === "invalid" ? 'error' : tokenEmpty ? "unpopulated" : "populated"}
              ref={newToken}
              onChange={() => {
                if (requestError) {
                  setRequestError(null)
                }
                if (statusNewToken) {
                  setStatusNewToken(null)
                }
                if (newToken.current.value.length === 0) {
                  setTokenEmpty(true)
                  dispatch( setLaunchTeamEnabled(false) )
                } else if (newToken.current.value.length === 1) {
                  setTokenEmpty(false)
                  dispatch( setLaunchTeamEnabled(true) )
                }
              }}
            />
          </InputWrapper>
          <Button
            className={isFetching ? 'primaryLoading' : (!launchTeamEnabled || requestError) ? 'primaryDisabled ' : 'primary'}
            disabled={isFetching || !launchTeamEnabled || requestError} 
            onClick={handleSubmit}
            style={{marginBottom: '2.5rem'}}
          >
            {isFetching ? (
              <CircularProgress 
                sx={{color: `${theme.button.text.primary}`}}
                size={12}
              />
            ) : (
              "Launch"
            )}
          </Button>
        </FlexWrapper>
      </AuthContainer>
    </PageContainer>
  )
}

export default Launch
