import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    awaitingActionLeads: [],
    investigatingLeads: [],
    closingLeads: [],
    awaitingUpdateLeads: [],
    filteredAwaitingActionLeads: [],
    filteredInvestigatingLeads: [],
    filteredClosingLeads: [],
    filteredAwaitingUpdateLeads: [],
    leadsAwaitingUpdate: 0,
    leadsAwaitingVerification: 0,
    totalTeamInvestigations: 0,
    fwdViewingLead: null,
    bckViewingLead: null,
  },
  status: 'idle',
  error: null
}

export const teamActiveLeadsSlice = createSlice({
  name: 'teamActiveLeads',
  initialState,
  reducers: {
    setFwdBckFilteredViewingLeads(state, action) {
      if (!action.payload) {
        state.data.fwdViewingLead = null
        state.data.bckViewingLead = null
      } else {
        let thisLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id.toString() === action.payload.toString())
        if (thisLead) {
          let thisIndex = state.data.filteredAwaitingActionLeads.indexOf(thisLead)
          if (thisIndex || thisIndex === 0) {
            if (state.data.filteredAwaitingActionLeads[thisIndex+1]) {
              state.data.fwdViewingLead = state.data.filteredAwaitingActionLeads[thisIndex+1]
            } else {
              let fwdLead = state.data.filteredInvestigatingLeads[0]
              if (fwdLead) {
                state.data.fwdViewingLead = fwdLead
              } else {
                let fwdLead = state.data.filteredClosingLeads[0]
                if (fwdLead) {
                  state.data.fwdViewingLead = fwdLead
                } else {
                  let fwdLead = state.data.filteredAwaitingUpdateLeads[0]
                  if (fwdLead) {
                    state.data.fwdViewingLead = fwdLead
                  } else {
                    if (thisIndex === 0) {
                      state.data.fwdViewingLead = null
                    } else {
                      let fwdLead = state.data.filteredAwaitingActionLeads[0]
                      if (fwdLead) {
                        state.data.fwdViewingLead = fwdLead
                      } else {
                        state.data.fwdViewingLead = null
                      }
                    }
                  }
                }
              }
            }
            if (state.data.filteredAwaitingActionLeads[thisIndex-1]) {
              state.data.bckViewingLead = state.data.filteredAwaitingActionLeads[thisIndex-1]
            } else {
              let backLead = state.data.filteredAwaitingUpdateLeads[state.data.filteredAwaitingUpdateLeads.length-1]
              if (backLead) {
                state.data.bckViewingLead = backLead
              } else {
                let backLead = state.data.filteredClosingLeads[state.data.filteredClosingLeads.length-1]
                if (backLead) {
                  state.data.bckViewingLead = backLead
                } else {
                  let backLead = state.data.filteredInvestigatingLeads[state.data.filteredInvestigatingLeads.length-1]
                  if (backLead) {
                    state.data.bckViewingLead = backLead
                  } else {
                    if (thisIndex === state.data.filteredAwaitingActionLeads.length-1) {
                      state.data.bckViewingLead = null
                    } else {
                      let backLead = state.data.filteredAwaitingActionLeads[state.data.filteredAwaitingActionLeads.length-1]
                      if (backLead) {
                        state.data.bckViewingLead = backLead
                      } else {
                        state.data.bckViewingLead = null
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          let thisLead = state.data.filteredInvestigatingLeads.find(lead => lead._id.toString() === action.payload.toString())
          if (thisLead) {
            let thisIndex = state.data.filteredInvestigatingLeads.indexOf(thisLead)
            if (thisIndex || thisIndex === 0) {
              if (state.data.filteredInvestigatingLeads[thisIndex+1]) {
                state.data.fwdViewingLead = state.data.filteredInvestigatingLeads[thisIndex+1]
              } else {
                let fwdLead = state.data.filteredClosingLeads[0]
                if (fwdLead) {
                  state.data.fwdViewingLead = fwdLead
                } else {
                  let fwdLead = state.data.filteredAwaitingUpdateLeads[0]
                  if (fwdLead) {
                    state.data.fwdViewingLead = fwdLead
                  } else {
                    let fwdLead = state.data.filteredAwaitingActionLeads[0]
                    if (fwdLead) {
                      state.data.fwdViewingLead = fwdLead
                    } else {
                      if (thisIndex === 0) {
                        state.data.fwdViewingLead = null
                      } else {
                        let fwdLead = state.data.filteredInvestigatingLeads[0]
                        if (fwdLead) {
                          state.data.fwdViewingLead = fwdLead
                        } else {
                          state.data.fwdViewingLead = null
                        }
                      }
                    }
                  }
                }
              }
              if (state.data.filteredInvestigatingLeads[thisIndex-1]) {
                state.data.bckViewingLead = state.data.filteredInvestigatingLeads[thisIndex-1]
              } else {
                let backLead = state.data.filteredAwaitingActionLeads[state.data.filteredAwaitingActionLeads.length-1]
                if (backLead) {
                  state.data.bckViewingLead = backLead
                } else {
                  let backLead = state.data.filteredAwaitingUpdateLeads[state.data.filteredAwaitingUpdateLeads.length-1]
                  if (backLead) {
                    state.data.bckViewingLead = backLead
                  } else {
                    let backLead = state.data.filteredClosingLeads[state.data.filteredClosingLeads.length-1]
                    if (backLead) {
                      state.data.bckViewingLead = backLead
                    } else {
                      if (thisIndex === state.data.filteredInvestigatingLeads.length-1) {
                        state.data.bckViewingLead = null
                      } else {
                        let backLead = state.data.filteredInvestigatingLeads[state.data.filteredInvestigatingLeads.length-1]
                        if (backLead) {
                          state.data.bckViewingLead = backLead
                        } else {
                          state.data.bckViewingLead = null
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            let thisLead = state.data.filteredClosingLeads.find(lead => lead._id.toString() === action.payload.toString())
            if (thisLead) {
              let thisIndex = state.data.filteredClosingLeads.indexOf(thisLead)
              if (thisIndex || thisIndex === 0) {
                if (state.data.filteredClosingLeads[thisIndex+1]) {
                  state.data.fwdViewingLead = state.data.filteredClosingLeads[thisIndex+1]
                } else {
                  let fwdLead = state.data.filteredAwaitingUpdateLeads[0]
                  if (fwdLead) {
                    state.data.fwdViewingLead = fwdLead
                  } else {
                    let fwdLead = state.data.filteredAwaitingActionLeads[0]
                    if (fwdLead) {
                      state.data.fwdViewingLead = fwdLead
                    } else {
                      let fwdLead = state.data.filteredInvestigatingLeads[0]
                      if (fwdLead) {
                        state.data.fwdViewingLead = fwdLead
                      } else {
                        if (thisIndex === 0) {
                          state.data.fwdViewingLead = null
                        } else {
                          let fwdLead = state.data.filteredClosingLeads[0]
                          if (fwdLead) {
                            state.data.fwdViewingLead = fwdLead
                          } else {
                            state.data.fwdViewingLead = null
                          }
                        }
                      }
                    }
                  }
                }
                if (state.data.filteredClosingLeads[thisIndex-1]) {
                  state.data.bckViewingLead = state.data.filteredClosingLeads[thisIndex-1]
                } else {
                  let backLead = state.data.filteredInvestigatingLeads[state.data.filteredInvestigatingLeads.length-1]
                  if (backLead) {
                    state.data.bckViewingLead = backLead
                  } else {
                    let backLead = state.data.filteredAwaitingActionLeads[state.data.filteredAwaitingActionLeads.length-1]
                    if (backLead) {
                      state.data.bckViewingLead = backLead
                    } else {
                      let backLead = state.data.filteredAwaitingUpdateLeads[state.data.filteredAwaitingUpdateLeads.length-1]
                      if (backLead) {
                        state.data.bckViewingLead = backLead
                      } else {
                        if (thisIndex === state.data.filteredClosingLeads.length-1) {
                          state.data.bckViewingLead = null
                        } else {
                          let backLead = state.data.filteredClosingLeads[state.data.filteredClosingLeads.length-1]
                          if (backLead) {
                            state.data.bckViewingLead = backLead
                          } else {
                            state.data.bckViewingLead = null
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              let thisLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.toString())
              if (thisLead) {
                let thisIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisLead)
                if (thisIndex || thisIndex === 0) {
                  if (state.data.filteredAwaitingUpdateLeads[thisIndex+1]) {
                    state.data.fwdViewingLead = state.data.filteredAwaitingUpdateLeads[thisIndex+1]
                  } else {
                    let fwdLead = state.data.filteredAwaitingActionLeads[0]
                    if (fwdLead) {
                      state.data.fwdViewingLead = fwdLead
                    } else {
                      let fwdLead = state.data.filteredInvestigatingLeads[0]
                      if (fwdLead) {
                        state.data.fwdViewingLead = fwdLead
                      } else {
                        let fwdLead = state.data.filteredClosingLeads[0]
                        if (fwdLead) {
                          state.data.fwdViewingLead = fwdLead
                        } else {
                          if (thisIndex === 0) {
                            state.data.fwdViewingLead = null
                          } else {
                            let fwdLead = state.data.filteredAwaitingUpdateLeads[0]
                            if (fwdLead) {
                              state.data.fwdViewingLead = fwdLead
                            } else {
                              state.data.fwdViewingLead = null
                            }
                          }
                        }
                      }
                    }
                  }
                  if (state.data.filteredAwaitingUpdateLeads[thisIndex-1]) {
                    state.data.bckViewingLead = state.data.filteredAwaitingUpdateLeads[thisIndex-1]
                  } else {
                    let backLead = state.data.filteredClosingLeads[state.data.filteredClosingLeads.length-1]
                    if (backLead) {
                      state.data.bckViewingLead = backLead
                    } else {
                      let backLead = state.data.filteredInvestigatingLeads[state.data.filteredInvestigatingLeads.length-1]
                      if (backLead) {
                        state.data.bckViewingLead = backLead
                      } else {
                        let backLead = state.data.filteredAwaitingActionLeads[state.data.filteredAwaitingActionLeads.length-1]
                        if (backLead) {
                          state.data.bckViewingLead = backLead
                        } else {
                          if (thisIndex === state.data.filteredAwaitingUpdateLeads.length-1) {
                            state.data.bckViewingLead = null
                          } else {
                            let backLead = state.data.filteredAwaitingUpdateLeads[state.data.filteredAwaitingUpdateLeads.length-1]
                            if (backLead) {
                              state.data.bckViewingLead = backLead
                            } else {
                              state.data.bckViewingLead = null
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  state.data.fwdViewingLead = null
                  state.data.bckViewingLead = null
                }
              } else {
                state.data.fwdViewingLead = null
                state.data.bckViewingLead = null
              }
            }
          }
        }
      }
    },
    setFwdBckViewingLeads(state, action) {
      if (!action.payload) {
        state.data.fwdViewingLead = null
        state.data.bckViewingLead = null
      } else {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id.toString() === action.payload.toString())
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          if (thisIndex || thisIndex === 0) {
            if (state.data.awaitingActionLeads[thisIndex+1]) {
              state.data.fwdViewingLead = state.data.awaitingActionLeads[thisIndex+1]
            } else {
              let fwdLead = state.data.investigatingLeads[0]
              if (fwdLead) {
                state.data.fwdViewingLead = fwdLead
              } else {
                let fwdLead = state.data.closingLeads[0]
                if (fwdLead) {
                  state.data.fwdViewingLead = fwdLead
                } else {
                  let fwdLead = state.data.awaitingUpdateLeads[0]
                  if (fwdLead) {
                    state.data.fwdViewingLead = fwdLead
                  } else {
                    if (thisIndex === 0) {
                      state.data.fwdViewingLead = null
                    } else {
                      let fwdLead = state.data.awaitingActionLeads[0]
                      if (fwdLead) {
                        state.data.fwdViewingLead = fwdLead
                      } else {
                        state.data.fwdViewingLead = null
                      }
                    }
                  }
                }
              }
            }
            if (state.data.awaitingActionLeads[thisIndex-1]) {
              state.data.bckViewingLead = state.data.awaitingActionLeads[thisIndex-1]
            } else {
              let backLead = state.data.awaitingUpdateLeads[state.data.awaitingUpdateLeads.length-1]
              if (backLead) {
                state.data.bckViewingLead = backLead
              } else {
                let backLead = state.data.closingLeads[state.data.closingLeads.length-1]
                if (backLead) {
                  state.data.bckViewingLead = backLead
                } else {
                  let backLead = state.data.investigatingLeads[state.data.investigatingLeads.length-1]
                  if (backLead) {
                    state.data.bckViewingLead = backLead
                  } else {
                    if (thisIndex === state.data.awaitingActionLeads.length-1) {
                      state.data.bckViewingLead = null
                    } else {
                      let backLead = state.data.awaitingActionLeads[state.data.awaitingActionLeads.length-1]
                      if (backLead) {
                        state.data.bckViewingLead = backLead
                      } else {
                        state.data.bckViewingLead = null
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id.toString() === action.payload.toString())
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            if (thisIndex || thisIndex === 0) {
              if (state.data.investigatingLeads[thisIndex+1]) {
                state.data.fwdViewingLead = state.data.investigatingLeads[thisIndex+1]
              } else {
                let fwdLead = state.data.closingLeads[0]
                if (fwdLead) {
                  state.data.fwdViewingLead = fwdLead
                } else {
                  let fwdLead = state.data.awaitingUpdateLeads[0]
                  if (fwdLead) {
                    state.data.fwdViewingLead = fwdLead
                  } else {
                    let fwdLead = state.data.awaitingActionLeads[0]
                    if (fwdLead) {
                      state.data.fwdViewingLead = fwdLead
                    } else {
                      if (thisIndex === 0) {
                        state.data.fwdViewingLead = null
                      } else {
                        let fwdLead = state.data.investigatingLeads[0]
                        if (fwdLead) {
                          state.data.fwdViewingLead = fwdLead
                        } else {
                          state.data.fwdViewingLead = null
                        }
                      }
                    }
                  }
                }
              }
              if (state.data.investigatingLeads[thisIndex-1]) {
                state.data.bckViewingLead = state.data.investigatingLeads[thisIndex-1]
              } else {
                let backLead = state.data.awaitingActionLeads[state.data.awaitingActionLeads.length-1]
                if (backLead) {
                  state.data.bckViewingLead = backLead
                } else {
                  let backLead = state.data.awaitingUpdateLeads[state.data.awaitingUpdateLeads.length-1]
                  if (backLead) {
                    state.data.bckViewingLead = backLead
                  } else {
                    let backLead = state.data.closingLeads[state.data.closingLeads.length-1]
                    if (backLead) {
                      state.data.bckViewingLead = backLead
                    } else {
                      if (thisIndex === state.data.investigatingLeads.length-1) {
                        state.data.bckViewingLead = null
                      } else {
                        let backLead = state.data.investigatingLeads[state.data.investigatingLeads.length-1]
                        if (backLead) {
                          state.data.bckViewingLead = backLead
                        } else {
                          state.data.bckViewingLead = null
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            let thisLead = state.data.closingLeads.find(lead => lead._id.toString() === action.payload.toString())
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              if (thisIndex || thisIndex === 0) {
                if (state.data.closingLeads[thisIndex+1]) {
                  state.data.fwdViewingLead = state.data.closingLeads[thisIndex+1]
                } else {
                  let fwdLead = state.data.awaitingUpdateLeads[0]
                  if (fwdLead) {
                    state.data.fwdViewingLead = fwdLead
                  } else {
                    let fwdLead = state.data.awaitingActionLeads[0]
                    if (fwdLead) {
                      state.data.fwdViewingLead = fwdLead
                    } else {
                      let fwdLead = state.data.investigatingLeads[0]
                      if (fwdLead) {
                        state.data.fwdViewingLead = fwdLead
                      } else {
                        if (thisIndex === 0) {
                          state.data.fwdViewingLead = null
                        } else {
                          let fwdLead = state.data.closingLeads[0]
                          if (fwdLead) {
                            state.data.fwdViewingLead = fwdLead
                          } else {
                            state.data.fwdViewingLead = null
                          }
                        }
                      }
                    }
                  }
                }
                if (state.data.closingLeads[thisIndex-1]) {
                  state.data.bckViewingLead = state.data.closingLeads[thisIndex-1]
                } else {
                  let backLead = state.data.investigatingLeads[state.data.investigatingLeads.length-1]
                  if (backLead) {
                    state.data.bckViewingLead = backLead
                  } else {
                    let backLead = state.data.awaitingActionLeads[state.data.awaitingActionLeads.length-1]
                    if (backLead) {
                      state.data.bckViewingLead = backLead
                    } else {
                      let backLead = state.data.awaitingUpdateLeads[state.data.awaitingUpdateLeads.length-1]
                      if (backLead) {
                        state.data.bckViewingLead = backLead
                      } else {
                        if (thisIndex === state.data.closingLeads.length-1) {
                          state.data.bckViewingLead = null
                        } else {
                          let backLead = state.data.closingLeads[state.data.closingLeads.length-1]
                          if (backLead) {
                            state.data.bckViewingLead = backLead
                          } else {
                            state.data.bckViewingLead = null
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.toString())
              if (thisLead) {
                let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
                if (thisIndex || thisIndex === 0) {
                  if (state.data.awaitingUpdateLeads[thisIndex+1]) {
                    state.data.fwdViewingLead = state.data.awaitingUpdateLeads[thisIndex+1]
                  } else {
                    let fwdLead = state.data.awaitingActionLeads[0]
                    if (fwdLead) {
                      state.data.fwdViewingLead = fwdLead
                    } else {
                      let fwdLead = state.data.investigatingLeads[0]
                      if (fwdLead) {
                        state.data.fwdViewingLead = fwdLead
                      } else {
                        let fwdLead = state.data.closingLeads[0]
                        if (fwdLead) {
                          state.data.fwdViewingLead = fwdLead
                        } else {
                          if (thisIndex === 0) {
                            state.data.fwdViewingLead = null
                          } else {
                            let fwdLead = state.data.awaitingUpdateLeads[0]
                            if (fwdLead) {
                              state.data.fwdViewingLead = fwdLead
                            } else {
                              state.data.fwdViewingLead = null
                            }
                          }
                        }
                      }
                    }
                  }
                  if (state.data.awaitingUpdateLeads[thisIndex-1]) {
                    state.data.bckViewingLead = state.data.awaitingUpdateLeads[thisIndex-1]
                  } else {
                    let backLead = state.data.closingLeads[state.data.closingLeads.length-1]
                    if (backLead) {
                      state.data.bckViewingLead = backLead
                    } else {
                      let backLead = state.data.investigatingLeads[state.data.investigatingLeads.length-1]
                      if (backLead) {
                        state.data.bckViewingLead = backLead
                      } else {
                        let backLead = state.data.awaitingActionLeads[state.data.awaitingActionLeads.length-1]
                        if (backLead) {
                          state.data.bckViewingLead = backLead
                        } else {
                          if (thisIndex === state.data.awaitingUpdateLeads.length-1) {
                            state.data.bckViewingLead = null
                          } else {
                            let backLead = state.data.awaitingUpdateLeads[state.data.awaitingUpdateLeads.length-1]
                            if (backLead) {
                              state.data.bckViewingLead = backLead
                            } else {
                              state.data.bckViewingLead = null
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  state.data.fwdViewingLead = null
                  state.data.bckViewingLead = null
                }
              } else {
                state.data.fwdViewingLead = null
                state.data.bckViewingLead = null
              }
            }
          }
        }
      }
    },
    setTeamLeadDsiableScan(state, action) {
      let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
      if (thisLead) {
        let thisLeadIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
        state.data.awaitingUpdateLeads[thisLeadIndex].disableScan = true
      }
      let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
      if (thisFilteredLead) {
        let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
        state.data.filteredAwaitingUpdateLeads[thisFilteredIndex].disableScan = true
      }
    },
    removeNotificationFromLead(state, action) {
      if (action.payload.status === 'awaitingUpdates') {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
        if (thisLead) {
          let thisLeadIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisLeadIndex].notifCount = null
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex].notifCount = null
        }
      } else if (action.payload.status === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
        if (thisLead) {
          let thisLeadIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisLeadIndex].notifCount = null
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex].notifCount = null
        }
      } else if (action.payload.status === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
        if (thisLead) {
          let thisLeadIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisLeadIndex].notifCount = null
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex].notifCount = null
        }
      } else if (action.payload.status === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
        if (thisLead) {
          let thisLeadIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisLeadIndex].notifCount = null
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex].notifCount = null
        }
      }
    },
    setSortedTeamAwaitingActionLeads(state, action) { 
      state.data.awaitingActionLeads = action.payload 
    },
    setSortedTeamInvestigatingLeads(state, action) { 
      state.data.investigatingLeads = action.payload 
    },
    setSortedTeamClosingLeads(state, action) { 
      state.data.closingLeads = action.payload 
    },
    setSortedTeamAwaitingUpdateLeads(state, action) { 
      state.data.awaitingUpdateLeads = action.payload 
    },
    setFilteredTeamAwaitingActionLeads(state, action) { 
      state.data.filteredAwaitingActionLeads = action.payload 
    },
    setFilteredTeamInvestigatingLeads(state, action) { 
      state.data.filteredInvestigatingLeads = action.payload 
    },
    setFilteredTeamClosingLeads(state, action) { 
      state.data.filteredClosingLeads = action.payload 
    },
    setFilteredTeamAwaitingUpdateLeads(state, action) { 
      state.data.filteredAwaitingUpdateLeads = action.payload 
    },
    addNewLeadsFromSweep(state, action) { 
      state.data.awaitingActionLeads.unshift(...action.payload)
    },
    addUpdatedLeadsFromSweep(state, action) { 
      for (let i = 0; i < action.payload.length; i++) {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = action.payload[i]
        } else {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = action.payload[i]
          } else {
            let thisLead = state.data.closingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads[thisIndex] = action.payload[i]
            } else {
              let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
              if (thisLead) {
                let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
                state.data.awaitingUpdateLeads[thisIndex] = action.payload[i]
              }
            }
          }
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = action.payload[i]
        } else {
          let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
          if (thisFilteredLead) {
            let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
            state.data.filteredInvestigatingLeads[thisFilteredIndex] = action.payload[i]
          } else {
            let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
              state.data.filteredClosingLeads[thisFilteredIndex] = action.payload[i]
            } else {
              let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = action.payload[i]
              }
            }
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.awaitingActionLeads = payload.data.teamAwaitingActionLeads
        state.data.investigatingLeads = payload.data.teamInvestigatingLeads
        state.data.closingLeads = payload.data.teamClosingLeads
        state.data.awaitingUpdateLeads = payload.data.teamAwaitingUpdateLeads
        state.data.leadsAwaitingUpdate = payload.data.teamLeadsAwaitingUpdate
        state.data.leadsAwaitingVerification = payload.data.teamLeadsAwaitingVerification
        state.data.totalTeamInvestigations = payload.data.teamAwaitingActionLeads.length + payload.data.teamInvestigatingLeads.length + payload.data.teamClosingLeads.length + payload.data.teamLeadsAwaitingUpdate + payload.data.teamLeadsAwaitingVerification
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusInvestigating.matchFulfilled, (state, {payload}) => {
      state.data.investigatingLeads.unshift(payload.data.newLead)
      let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLead) {
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads.splice(thisIndex, 1)
        state.data.filteredInvestigatingLeads.unshift(payload.data.newLead)
      }
      let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
      }
      if (payload.data.requestOrigin === 'repository') {
        state.data.totalTeamInvestigations++
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusClosing.matchFulfilled, (state, {payload}) => {
      state.data.closingLeads.unshift(payload.data.newLead)
      let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLead) {
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads.splice(thisIndex, 1)
        state.data.filteredClosingLeads.unshift(payload.data.newLead)
      }
      let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
      let thisIndex = state.data.closingLeads.indexOf(thisLead)
      if (thisLead) {
        state.data.closingLeads.splice(thisIndex, 1)
      }
      let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
      }
      if (payload.data.activeDiscrepancies > 0 || payload.data.preserveLead) {
        state.data.leadsAwaitingUpdate++
        state.data.awaitingUpdateLeads.push(payload.data.newLead)
        state.data.filteredAwaitingUpdateLeads.push(payload.data.newLead)
      } else {
        state.data.totalTeamInvestigations--
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
      if (thisLead) {
        let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
        state.data.awaitingUpdateLeads.splice(thisIndex, 1)
      }
      let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
      }
      if (payload.data.verifyLead) {
        state.data.leadsAwaitingVerification = state.data.leadsAwaitingVerification - 1
      } else {
        state.data.leadsAwaitingUpdate = state.data.leadsAwaitingUpdate - 1
      }
      state.data.totalTeamInvestigations = state.data.totalTeamInvestigations - 1
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLead) {
        if (payload.data.leadStatus === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetChanges.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.provideFinancialInformation.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus !== 'inactive') {
        if (payload.data.leadStatus === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.newLeads.length; i++) {
        if (payload.data.newLeads[i].status === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.newLeads[i]
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLeads[i]
          }
        } else if (payload.data.newLeads[i].status === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.newLeads[i]
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLeads[i]
          }
        } else if (payload.data.newLeads[i].status === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.newLeads[i]
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLeads[i]
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === payload.data.newLeads[i]._id.toString())
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLeads[i]
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLeads[i]
          }
        }
        if (payload.data.awaitingUpdateDec) {
          state.data.leadsAwaitingUpdate = state.data.leadsAwaitingUpdate - 1
        }
        if (payload.data.awaitingVerificationInc) {
          state.data.leadsAwaitingVerification = state.data.leadsAwaitingVerification + 1
        }
      }
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      state.data.totalTeamInvestigations--
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus && payload.data.leadStatus.length > 0) {
        if (payload.data.leadStatus === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
          }
        }
      } else if (payload.data.leadCreated) {
        if (payload.data.newMortgage && payload.data.newMortgage.status) {
          if (payload.data.newMortgage.status === 'awaitingAction') {
            state.data.awaitingActionLeads.unshift(payload.data.newLead)
            state.data.filteredAwaitingActionLeads.unshift(payload.data.newLead)
          } else if (payload.data.newMortgage.status === 'investigating') {
            state.data.investigatingLeads.unshift(payload.data.newLead)
            state.data.filteredInvestigatingLeads.unshift(payload.data.newLead)
          } else if (payload.data.newMortgage.status === 'closing') {
            state.data.closingLeads.unshift(payload.data.newLead)
            state.data.filteredClosingLeads.unshift(payload.data.newLead)
          } else if (payload.data.newMortgage.status === 'awaitingUpdate') {
            state.data.awaitingUpdateLeads.unshift(payload.data.newLead)
            state.data.filteredAwaitingUpdateLeads.unshift(payload.data.newLead)
          }
        }
      }
      if (payload.data.awaitingUpdate !== null) {
        state.data.leadsAwaitingUpdate = payload.data.awaitingUpdate
      }
      if (payload.data.awaitingVerification !== null) {
        state.data.leadsAwaitingVerification = payload.data.awaitingVerification
      }
      if (payload.data.leadCreated) {
        state.data.leadsAwaitingUpdate++
        state.data.totalTeamInvestigations++
      }
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLead && payload.data.newLead.status) {
        if (!payload.data.discardLead) {
          if (payload.data.leadStatus === 'awaitingAction') {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
              state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
              state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
              state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else {
            let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
              state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
            }
          }
        } else {
          if (payload.data.leadStatus === 'awaitingAction') {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
              state.data.awaitingActionLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
              state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
              state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'awaitingUpdate') {
            let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
              state.data.awaitingUpdateLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
            }
          }
        }
      }
      if (payload.data.awaitingUpdate !== null) {
        state.data.leadsAwaitingUpdate = payload.data.awaitingUpdate
      }
      if (payload.data.awaitingVerification !== null) {
        state.data.leadsAwaitingVerification = payload.data.awaitingVerification
      }
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLead && payload.data.newLead.status) {
        if (!payload.data.discardLead) {
          if (payload.data.leadStatus === 'awaitingAction') {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
              state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
              state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
              state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'awaitingUpdate') {
            let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
              state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
            }
          }
        } else {
          if (payload.data.leadStatus === 'awaitingAction') {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
              state.data.awaitingActionLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
              state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
              state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'awaitingUpdate') {
            let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
              state.data.awaitingUpdateLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadCreated) {
            state.data.totalTeamInvestigations++
          }
        }
      }
      if (payload.data.awaitingUpdate !== null) {
        state.data.leadsAwaitingUpdate = payload.data.awaitingUpdate
      }
      if (payload.data.awaitingVerification !== null) {
        state.data.leadsAwaitingVerification = payload.data.awaitingVerification
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageTags.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordSweepQuery.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.updatedLead) {
        if (payload.data.updatedLead.status === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.updatedLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.updatedLead
          }
        } else if (payload.data.updatedLead.status === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.updatedLead
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.updatedLead
          }
        } else if (payload.data.updatedLead.status === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.updatedLead
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.updatedLead
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.updatedLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.updatedLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.saveProperty.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLead) {
        state.data.awaitingActionLeads.unshift(payload.data.newLead)
        state.data.filteredAwaitingActionLeads.unshift(payload.data.newLead)
      }
    })
    .addMatcher(apiSlice.endpoints.recordUploadPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.totalNewLeads) {
        state.data.totalTeamInvestigations = state.data.totalTeamInvestigations + payload.data.totalNewLeads
      }
    })
    .addMatcher(apiSlice.endpoints.recordQueryPerformance.matchFulfilled, (state, {payload}) => {
      if (payload.data.userNotification && payload.data.userNotification.newLeadIds) {
        for (let i = 0; i < payload.data.userNotification.newLeadIds.length; i++) {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id.toString() === payload.data.userNotification.newLeadIds[i].toString())
          if (thisLead) {
            thisLead.newLeadLabel = true
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = thisLead
          } else {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id.toString() === payload.data.userNotification.newLeadIds[i].toString())
            if (thisLead) {
              thisLead.newLeadLabel = true
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads[thisIndex] = thisLead
            } else {
              let thisLead = state.data.closingLeads.find(lead => lead._id.toString() === payload.data.userNotification.newLeadIds[i].toString())
              if (thisLead) {
                thisLead.newLeadLabel = true
                let thisIndex = state.data.closingLeads.indexOf(thisLead)
                state.data.closingLeads[thisIndex] = thisLead
              } else {
                let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === payload.data.userNotification.newLeadIds[i].toString())
                if (thisLead) {
                  thisLead.newLeadLabel = true
                  let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
                  state.data.awaitingUpdateLeads[thisIndex] = thisLead
                }
              }
            }
          }
        }
      }
      if (payload.data) {
        state.data.totalTeamInvestigations = state.data.totalTeamInvestigations + payload.data.newLeadCount
        state.data.leadsAwaitingUpdate = payload.data.newTeamLeadsAwaitingUpdate
        state.data.leadsAwaitingVerification = payload.data.newTeamLeadsAwaitingVerification
      }
    })
  }
})

export const { removeNotificationFromLead, setTeamLeadDsiableScan, setSortedTeamAwaitingActionLeads, setFilteredTeamAwaitingActionLeads, setSortedTeamInvestigatingLeads, setSortedTeamClosingLeads, setFilteredTeamInvestigatingLeads, setFilteredTeamClosingLeads, setSortedTeamAwaitingUpdateLeads, setFilteredTeamAwaitingUpdateLeads, addNewLeadsFromSweep, addUpdatedLeadsFromSweep, setFwdBckViewingLeads, setFwdBckFilteredViewingLeads } = teamActiveLeadsSlice.actions

export default teamActiveLeadsSlice.reducer

//* ActiveLeads
export const selectTeamClosingLeads = state => state.teamActiveLeads.data.closingLeads
export const selectForwardViewingLead = state => state.teamActiveLeads.data.fwdViewingLead
export const selectBackwardViewingLead = state => state.teamActiveLeads.data.bckViewingLead
export const selectTeamInvestigatingLeads = state => state.teamActiveLeads.data.investigatingLeads
export const selectTeamAwaitingActionLeads = state => state.teamActiveLeads.data.awaitingActionLeads
export const selectTeamAwaitingUpdateLeads = state => state.teamActiveLeads.data.awaitingUpdateLeads
export const selectTeamLeadsAwaitingUpdate = state => state.teamActiveLeads.data.leadsAwaitingUpdate
export const selectTotalTeamInvestigations = state => state.teamActiveLeads.data.totalTeamInvestigations
export const selectFilteredTeamClosingLeads = state => state.teamActiveLeads.data.filteredClosingLeads
export const selectTeamLeadsAwaitingVerification = state => state.teamActiveLeads.data.leadsAwaitingVerification
export const selectFilteredTeamInvestigatingLeads = state => state.teamActiveLeads.data.filteredInvestigatingLeads
export const selectFilteredTeamAwaitingUpdateLeads = state => state.teamActiveLeads.data.filteredAwaitingUpdateLeads
export const selectFilteredTeamAwaitingActionLeads = state => state.teamActiveLeads.data.filteredAwaitingActionLeads