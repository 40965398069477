import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {},
  status: 'idle',
  error: null
}

export const viewingMortgageSlice = createSlice({
  name: 'viewingMortgage',
  initialState,
  reducers: {
    setViewingMortgage(state, action) {
      state.data = action.payload
    },
    setMortgageFromSweep(state, action) {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.updateMortgageTags.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.provideFinancialInformation.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.updateMortgageAssignees.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      state.data = payload.data.newMortgage
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      if (payload.data.mission === 'stillInvestigating' || payload.data.mission === 'closeInvestigation' || payload.data.mission === 'dismissInvestigation') {
        if (payload.data.newMortgages.length > 0) {
          state.data = payload.data.newMortgages[0]
        }
      }
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newMortgage) {
        state.data = payload.data.newMortgage
      }
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newMortgage) {
        state.data = payload.data.newMortgage
      }
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newMortgage) {
        state.data = payload.data.newMortgage
      }
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      state.data._id = null
    })
    .addMatcher(apiSlice.endpoints.setStatusInvestigating.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newMortgage) {
        state.data = payload.data.newMortgage
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusClosing.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newMortgage) {
        state.data = payload.data.newMortgage
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data && payload.data.newMortgage) {
        state.data = payload.data.newMortgage
      }
    })
    .addMatcher(apiSlice.endpoints.recordSweepQuery.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data && payload.data.isSingleScan) {
        state.data = payload.data.newMortgage
      }
    })
  }
})

export const { setViewingMortgage, setMortgageFromSweep } = viewingMortgageSlice.actions

export default viewingMortgageSlice.reducer

export const selectViewingMortgage = state => state.viewingMortgage.data