import React, { useState } from "react";
import Radio from '@mui/material/Radio';
import { Dialog } from "@mui/material";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useTheme } from "@mui/styles";
import ParameterTags from "../../../../components/Configurations/ParameterTags";
import FormControlLabel from '@mui/material/FormControlLabel';
import LeadTagPerformance from "../../../../components/Configurations/LeadTagPerformance";
import SweepParameterModal from "../../../../components/Modals/SweepParameterModal/SweepParameterModal";
import { selectUserFullName } from "../../../../slices/userInfoSlice";
import ParameterPerformanceStats from "../../../../components/Configurations/ParameterPerformanceStats";
import { selectMortgageDataModel } from "../../../../slices/mortgageDataModelSlice";
import { useDispatch, useSelector } from "react-redux";
import { setViewingSweepParameter } from "../../../../slices/viewingSweepParameterSlice";
import { grayUnselectedRadioColorProps } from "../../../../componentThemes";
import { ComponentContainer, ComponentSectionRow, FlexWrapper, HeaderText, ComponentBorder } from "../../../../StyledComponents";

const Configuration = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const userFullName = useSelector(selectUserFullName)
  const mortgageDataModel = useSelector(selectMortgageDataModel)

  const [configurationView, seetConfigurationView] = useState('Parameter Performance')
  const [openSweepParameterModal, setOpenSweepParameterModal] = useState(false)

  const configurationViewToggleControlProps = (item) => ({
    checked: configurationView === item,
    onChange: handleConfigurationViewChange,
    value: item,
    label: item,
    labelPlacement: "end",
    sx: {
      color: `${theme.palette.common.black}`,
    }
  });
  const handleConfigurationViewChange = (event) => {
    seetConfigurationView(event.target.value)
  }

  const handleOpenSweepParameterModal = (sweepParameter) => {
    dispatch( setViewingSweepParameter(sweepParameter) )
    setOpenSweepParameterModal(true)
  }
  const handleCloseSweepParameterModal = () => {
    setOpenSweepParameterModal(false)
  }

  return (
    <>
      <FlexWrapper style={{padding: '1rem'}}>
        <ComponentContainer className="fitWindow onePanel" style={{background: `${theme.palette.common.grayBorderLight}`}}>
          {/* <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2', maxHeight: 'calc(100vh - 11.2rem)', minHeight: 'none'}}> */}
          <FlexWrapper className="column fitContentHeight" style={{gridRow: '2', gridColumn: '2'}}>
            <ComponentSectionRow style={{justifyContent: 'center', padding: '1rem 0', background: `${theme.palette.common.grayBorderLight}`}}>
              <FlexWrapper className="fitContentWidth alignCenter">
                <HeaderText className="center" style={{margin: '0.2rem 0 0 0'}}>
                  Entity Details:
                </HeaderText>
                <FormControl style={{margin: '0.1rem 0 0 2.5rem'}}>
                  <RadioGroup row>
                    <FormControlLabel
                      control={<Radio {...grayUnselectedRadioColorProps()} />}
                      {...configurationViewToggleControlProps('Parameter Performance')} 
                    />
                    <FormControlLabel
                      control={<Radio {...grayUnselectedRadioColorProps()} />}
                      {...configurationViewToggleControlProps('Parameter Lead Tags')} 
                    />
                    <FormControlLabel
                      control={<Radio {...grayUnselectedRadioColorProps()} />}
                      {...configurationViewToggleControlProps('Lead Tag Performance')} 
                    />
                  </RadioGroup>
                </FormControl>
              </FlexWrapper>
            </ComponentSectionRow>
            {configurationView === 'Parameter Performance' ?
              <ParameterPerformanceStats mortgageDataModel={mortgageDataModel} handleOpenSweepParameterModal={handleOpenSweepParameterModal} />
            : configurationView === 'Parameter Lead Tags' ?
              <ParameterTags mortgageDataModel={mortgageDataModel} handleOpenSweepParameterModal={handleOpenSweepParameterModal} />
            :
              <LeadTagPerformance mortgageDataModel={mortgageDataModel} handleOpenSweepParameterModal={handleOpenSweepParameterModal} />
            }
          </FlexWrapper>
          <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
          <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
          <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
          <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
        </ComponentContainer>
      </FlexWrapper>

      <Dialog open={openSweepParameterModal} onClose={handleCloseSweepParameterModal} 
        PaperProps={{ sx: {width: "35rem", minWidth: "35rem", maxWidth: "35rem", height: "25rem", minHeight: "25rem", maxHeight: "25rem"}}}
      >
        <SweepParameterModal closeModal={handleCloseSweepParameterModal} userFullName={userFullName} />
      </Dialog>
    </>
  )
}

export default Configuration