import React from "react"
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectTeamLeadTags } from "../../slices/teamAndUserSettingsSlice";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ComponentSectionRow, Detail, Divider, FlexWrapper, ScrollWindow, TierButton, Typography } from "../../StyledComponents";

const ParameterTags = ({ mortgageDataModel, handleOpenSweepParameterModal }) => {
  const theme = useTheme()

  const teamLeadTags = useSelector(selectTeamLeadTags)

  return (
    <FlexWrapper className="column">
      <Divider className="darkGray" />
      <Typography className="subHeader medium alignTextCenter" style={{width: '80rem', margin: '1rem auto'}}>
        The system assigns Tags to assist your team in organizing investigations.<br/>
        The table below shows which Tags are applied when a Discrepancy is discovered in each respective Parameter.
      </Typography>
      <ComponentSectionRow className="header gray" style={{height: '5.7rem', display: 'grid', gridTemplateColumns: `24rem repeat(${teamLeadTags.length}, 10rem) 0.6rem`, borderTop: `0.1rem solid ${theme.palette.common.grayText}`}}>
        <Typography className="header alignCenter justifyCenter" style={{height: '5.4rem', gridColumn: '1', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
          label
        </Typography>
        {teamLeadTags.map((tag, index) => (
          <Typography key={tag.label} className="header alignCenter" style={{height: '5.4rem', gridColumn: `${index+2}`, borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, paddingLeft: '0.8rem'}}>
            {tag.label}  
          </Typography>   
        ))}
      </ComponentSectionRow>
      <ScrollWindow className="verticalOnly configParams" style={{paddingBottom: '5rem', background: '#fff'}}>
        {mortgageDataModel.map((category, index) => (index !== 0 &&
          <div key={category.subCat}>
            <FlexWrapper className="fitContentHeight" style={{padding: '0.4rem 0 0.4rem 2rem', background: `${theme.palette.common.grayBorder}`, borderTop: index !== 1 ? `0.1rem solid ${theme.palette.common.grayText}` : 'none', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
              <Detail className="timelineHeader">{category.subCat}</Detail>
            </FlexWrapper>
            <FlexWrapper className="column">
              {category.fields.map((sweepParameter, index) => (
                <ComponentSectionRow key={sweepParameter.label} style={{padding: index === 0 ? '0.6rem 0 0.2rem 2rem' : index === (category.fields.length - 1) ? '0.2rem 0 0.6rem 2rem' : '0.2rem 0 0.2rem 2rem', background: index%2 === 1 ? `${theme.component.row.even}` : `${theme.component.row.odd}`, display: 'grid', gridTemplateColumns: `22rem repeat(${teamLeadTags.length}, 10rem)`}}>
                  <TierButton className={sweepParameter.assignedTier === 'two' ? 'two' : sweepParameter.assignedTier === 'one' ? 'one' : 'three'} style={{width: 'fit-content', gridColumn: '1', margin: '0 0 0 3rem'}} onClick={() => handleOpenSweepParameterModal(sweepParameter)}>
                    {sweepParameter.label.includes('Primary') ?
                      <>
                        {sweepParameter.label.replace('Primary', '').toUpperCase()}
                      </>
                    : sweepParameter.label.includes('Secondary') ?
                      <>
                        {sweepParameter.label.replace('Secondary', '').toUpperCase()}
                      </>
                    :
                      sweepParameter.label.toUpperCase()
                    }
                  </TierButton>
                  {teamLeadTags.map((leadTag, tagIndex) => (
                    sweepParameter.tags.includes(leadTag.apiMapping)  ?
                      <Detail className="center" style={{gridColumn: `${tagIndex+2}`}} key={tagIndex}>
                        <RadioButtonCheckedIcon style={{color: `${theme.palette.primary.main}`}} />
                      </Detail>
                    :
                      <Detail className="center" style={{gridColumn: `${tagIndex+2}`}} key={tagIndex}>
                        <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayBorderMedium}`}} />
                      </Detail>
                  ))}
                </ComponentSectionRow>
              ))}
            </FlexWrapper>
          </div>
        ))}
      </ScrollWindow>
    </FlexWrapper>
  )
}

export default ParameterTags;