import React from "react"
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@mui/styles";
import { CompareLink, FadeWrapper, FlexWrapper, Typography } from "../../../../../../../StyledComponents"

const Undo = ({ handleWorkFlow, record, handleSubmitEdit }) => {
  const theme = useTheme()

  return (
    <FadeWrapper>
      <Typography>
        {record.lastValue ?
          record.lastValue === record.currentValue || (record.discrepancy === 'resolved' && record.lastValue === record.originalValue) ?
            `Reactivate the discrepancy?`
          : record.discrepancy === 'resolved' && record.lastValue !== record.originalValue ?
            `Reject the discrepancy?`
          : record.discrepancy === 'rejected' && record.lastValue === record.publicRecordValue && record.originalDiscrepancy ?
            `Resolve the discrepancy?`
          : record.discrepancy === 'rejected' && record.lastValue === record.publicRecordValue && !record.originalDiscrepancy ?
            `Return to matching the public record?`
          :
            <>
              Revert to <Typography className="italic" style={{marginLeft: '0.5rem'}}>{record.lastValue}</Typography> ?
            </>
        :
          <>
            Revert to <Typography className="accent" style={{marginLeft: '0.5rem'}}>-empty-</Typography> ?
          </>
        }
      </Typography>
      <FlexWrapper className="fitContentHeight justifyCenter" style={{marginTop: '1rem'}}>
        <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{fontSize: '1rem', marginRight: '0.3rem'}}>
          <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
        </CompareLink>
        <CompareLink className="small green" onClick={() => handleSubmitEdit('undo')} style={{fontSize: '1rem', margin: '0'}}>
          <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
        </CompareLink>
      </FlexWrapper>
    </FadeWrapper>
  )
}

export default Undo