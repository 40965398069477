import React from "react";
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const ReportGenerationGuide = () => {

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Closure Reports" component
      </GuideText>
      <GuideText className="medium">
        stores exportable versions of documents that summarize completed investigations.
      </GuideText>

      <Divider className="narrow" />
      
      <GuideText className="halfIndent">
        Reports are meant to be easily digestible historical references to each investigation that your team completes using the system.
      </GuideText>
      <GuideText className="halfIndent newParagraph">
        These can be customized to be as simple or as thorough as yourteam desires, so please contact your<br/>
        app administrator to request any changes you may have.
      </GuideText>
    </FlexWrapper>
  )
}

export default ReportGenerationGuide