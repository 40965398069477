import React from "react";
import { useTheme } from "@mui/styles";
import { AccentInfo, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const DiscrepancyEditedOrRejected = ({ milestone }) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="new" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      {(milestone.activeDiscrepancies && milestone.activeDiscrepancies > 0 ) ?
        <Typography style={{fontWeight: milestone.activeDiscrepancies === 0 ? '700' : ''}}>
          <span style={{fontWeight: '700', marginRight: '0.3rem'}}>
            {milestone.activeDiscrepancies}
          </span>
          {milestone.activeDiscrepancies === 1 ? `Discrepancy remaining` : `Discrepancies remaining`}
        </Typography>
      : <></>}
      {milestone.milestone === 'Internal Records Updated' ?
        <Typography>
          • The Mortgage is now waiting for the Public Records to update
        </Typography>
      :<></>}
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            updated by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: 'fit-content', marginRight: '0.5rem'}}>
            {milestone.milestone === 'Internal Records Updated' ?
              `"${milestone.details.label}" changed:`
            :
              `Internal record changed:`
            }
          </Typography>
          <FlexWrapper className="fitContentHeight fitContentWidth column">
            <Typography className="bold">
              <span style={{marginRight: '0.4rem', fontWeight: '400', width: '2.4rem', textAlign: 'end'}}>
                from
              </span>
              {milestone.details.old === 'Y' ?
                'YES'
              : milestone.details.old === 'N' ?
                'NO'
              : !milestone.details.old && milestone.details.new ?
                <AccentInfo>none</AccentInfo>
              : milestone.details.field.includes('Rate')
                || milestone.details.field.includes('%') ?
                `${milestone.details.old}%`
              : milestone.details.field.includes('Price')
                || milestone.details.field.includes('Amount')
                || milestone.details.field.includes('Value') ?
                `$${milestone.details.old}`
              :
                milestone.details.old
              }
            </Typography>
            <Typography className="bold">
              <span style={{marginRight: '0.4rem', fontWeight: '400', width: '2.4rem', textAlign: 'end'}}>
                to
              </span>
              {milestone.details.new === 'Y' ?
                'YES'
              : milestone.details.new === 'N' ?
                'NO'
              : milestone.details.old && !milestone.details.new ?
                <AccentInfo>none</AccentInfo>
              : milestone.details.field.includes('Rate')
                || milestone.details.field.includes('%') ?
                `${milestone.details.new}%`
              : milestone.details.field.includes('Price')
                || milestone.details.field.includes('Amount')
                || milestone.details.field.includes('Value') ?
                `$${milestone.details.new}`
              :
                milestone.details.new
              }
            </Typography>
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default DiscrepancyEditedOrRejected