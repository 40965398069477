import React from "react";
import EditTags from "../LeadActions/Components/EditTags/EditTags.js";
import Timeline from "../../Timeline/Timeline";
import CancelIcon from '@mui/icons-material/Cancel';
import NotesModal from "../LeadActions/Components/Notes.js";
import { useTheme } from "@mui/styles";
import ReportsModal from "../LeadActions/Components/ReportsModal.js";
import AddAssignees from "../LeadActions/Components/AddAssignees/AddAssignees.js";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";
import InvestigateLead from "../LeadActions/Components/LeadStatus/InvestigateLead.js";
import PropertyDetails from "../LeadActions/Components/PropertyDetails.js";
import ProvideFinancials from "../LeadActions/Components/ProvideFinancials.js";
import MortgageActionsNav from "./MortgageActionsNav";
import LeadCompareRecords from "../LeadActions/Components/CompareRecords.js";
import AdminCompareRecords from "../AdminMortgageActions/components/CompareRecords/CompareRecords.js";
import { selectViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { selectTeamMembersByName } from "../../../slices/teamMembersSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { selectViewingLead, setViewingLead } from "../../../slices/viewingLeadSlice.js";
import { selectMortgageActionsNavSelection, setNavSelection, setViewingLeadActions } from "../../../slices/sessionDataSlice.js";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer, ComponentHeader, Typography, DetailWrapper } from "../../../StyledComponents";
import ConfirmUpdate from "../LeadActions/Components/LeadStatus/ConfirmUpdate.js";

const MortgageActionsModal = ({ handleCloseModal, userId, teamId, userFullName, dashPerspective, reports }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const leadData = useSelector(selectViewingLead)
  const mortgageData = useSelector(selectViewingMortgage)
  const navSelection = useSelector(selectMortgageActionsNavSelection)
  const teamMembersByName = useSelector(selectTeamMembersByName)

  const handleGoToLeadActions = () => {
    dispatch( setViewingLead(leadData) )
    dispatch( setNavSelection('activeLeads') )
    dispatch( setViewingLeadActions(true) )
    navigate(`/dashboard/investigations/${mortgageData._id}`)
  }

  return (
    <ModalContainer className="threePanel">
      <ComponentBorder className="top" style={{gridColumn: '1/6'}}>
        <ComponentHeader className="areaTitle">
          <Typography className="medium" style={{marginRight: '0.5rem'}}>mortgage for:</Typography>
          <Typography className="xLarge">
            {mortgageData.recordDetails.address.StreetAddressOneLine.currentValue ?
              `${mortgageData.recordDetails.address.StreetAddressOneLine.currentValue}`
            :
              `${mortgageData.streetAddress}`
            }
          </Typography>
          {mortgageData.status !== 'inactive' &&
            <Typography className="xLarge" style={{margin: '0 0.6rem'}}>-</Typography>
          }
          {mortgageData.status === 'awaitingAction' ?
            <Typography className="large" style={{color: `${theme.accent.caution.primary}`, marginRight: '0.6rem'}}>
              Current Violation Awaiting Action
            </Typography>
          : mortgageData.status === 'investigating' ?
            <Typography className="large" style={{color: `${theme.accent.caution.primary}`, marginRight: '0.6rem'}}>
              Currently Under Investigation
            </Typography>
          : mortgageData.status === 'closing' ?
            <Typography className="large" style={{color: `${theme.accent.success.primary}`, marginRight: '0.6rem'}}>
              Currently Closing an Investigation
            </Typography>
          : mortgageData.status === 'awaitingUpdate' ?
            <Typography className="large" style={{color: `${theme.accent.success.primary}`, marginRight: '0.6rem'}}>
              Currently Awaiting Public Record Updates
            </Typography>
          :
            <></>
          }
          {(mortgageData.status === 'awaitingAction' || mortgageData.status === 'investigating' || mortgageData.status === 'closing' || mortgageData.status === 'awaitingUpdate') &&
            <DetailWrapper 
              className="addButton" onClick={() => handleGoToLeadActions()} style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', marginLeft: '0.5rem'}}
            >
              {mortgageData.status === 'awaitingAction' ?
                'View Violation'
              : mortgageData.status === 'awaitingUpdate' ?
                'View in Awaiting Update pool'
              :
                'View Investigation'
              }
              <OpenInNewIcon style={{height: '1.4rem', width: '1.4rem', color: `${theme.palette.primary.main}`, margin: '0 0 0 0.3rem'}} /> 
            </DetailWrapper>
          }
        </ComponentHeader>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <MortgageActionsNav mortgageData={mortgageData} reportsNumber={mortgageData.reports.length} handleCloseModal={handleCloseModal} />
      {navSelection === 'reports' ?
        <ReportsModal reports={reports} />
      : navSelection === 'notes' ?
        <NotesModal handleCloseModal={handleCloseModal} userId={userId} userFullName={userFullName} leadData={leadData} dashPerspective={dashPerspective} mortgageData={mortgageData} />
      : navSelection === 'compareRecords' && leadData ?
        <LeadCompareRecords mortgageData={mortgageData} dashPerspective={dashPerspective} userFullName={userFullName} leadData={leadData} teamId={teamId} location={'repository'} leadUserNotifications={0} />
      : navSelection === 'compareRecords' ?
        <AdminCompareRecords mortgageData={mortgageData} />
      : navSelection === 'propertyDetails' ?
        <PropertyDetails handleCloseModal={handleCloseModal} mortgageData={mortgageData} />
      : navSelection === 'investigateLead' ?
        <InvestigateLead handleCloseModal={handleCloseModal} userId={userId} teamId={teamId} userFullName={userFullName} teamMembersByName={teamMembersByName} mortgageId={mortgageData._id} leadData={{assigneeIds: []}} dashPerspective={dashPerspective} requestOrigin={'repository'} />
      : navSelection === 'editTags' ?
        <EditTags leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={mortgageData.activeLead} mortgageData={mortgageData} />
      : navSelection === 'addAssignees' ?
        <AddAssignees teamMembersByName={teamMembersByName} handleCloseModal={handleCloseModal} activeLead={mortgageData.activeLead} leadData={leadData} mortgageData={mortgageData} userFullName={userFullName} />
      : navSelection === 'provideFinancialsModal' || navSelection === 'provideFinancialsCard' || navSelection === 'provideTerms' ?
        <ProvideFinancials mortgageData={mortgageData} userFullName={userFullName} leadData={leadData} dashPerspective={dashPerspective} userId={userId} navSelection={navSelection} handleCloseModal={handleCloseModal} teamId={teamId} />
      : navSelection === 'confirmUpdate' ?
        <ConfirmUpdate handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} teamId={teamId} mortgageData={mortgageData} />
      :
        <></>
      }
      <ComponentHeader className="modal timeline">Timeline</ComponentHeader>
      <TimelineContainer className="actionsModal">
        <Timeline timeline={mortgageData.timeline} origin={'modal'} />
      </TimelineContainer>
      <ComponentBorder className="left" style={{gridRow:'2/6', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/6', gridColumn: '5'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'6', gridColumn: '1/6'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default MortgageActionsModal;