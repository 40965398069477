import React from "react";
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AddButton, Badge, FlexWrapper, Typography } from "../../../../StyledComponents";

const StandardNotification = ({ notification, leadNotificationsRead, handleGoToLeadActions }) => {
  const theme = useTheme()

  return (
      <FlexWrapper className="fitContentHeight" style={{padding: '0.7rem 1rem 0.7rem 3rem', }}>
        <FlexWrapper className='fitContentHeight fitContentWidth'>
          <Typography className="column alignStart">
            <FlexWrapper className="alignEnd">
              <Typography className="fitContentHeight" style={{marginRight: '0.4rem'}}>
                property:
              </Typography>
              <Typography className="fitContentHeight justifyStart bold">
                {notification.address}
              </Typography>
              <Badge className={leadNotificationsRead ? "flex readTransparent" : "flex one"} style={{height: notification.notifCount < 100 ? '1.8rem' : '2rem', width: notification.notifCount < 100 ? '1.8rem' : '2.8rem', margin: '0 0 0 0.6rem'}}>
                {notification.notifCount}
              </Badge>
            </FlexWrapper>
            <Typography className="fitContentHeight" style={{marginTop: '0.4rem'}}>
              owner: {notification.owner1}
            </Typography>
          </Typography>
        </FlexWrapper>
        <FlexWrapper className="alignCenter" style={{width: '16.5rem', margin: '0 auto'}}>
          <Typography className="accent alignCenter" style={{marginRight: '0.4rem'}}>
            {notification.status === 'awaitingAction' ?
              'currently awaiting action'
            : notification.status === 'investigating' ?
              'currently under investigation'
            : notification.status === 'closing' ?
              'currently closing'
            :
              'currently awaiting updates'
            }
          </Typography>
          <AddButton className="dark" onClick={() => handleGoToLeadActions(notification.leadStatus, notification.mortgageId, notification.leadId)}>
            <OpenInNewIcon style={{height: '1.5rem', width: '1.5rem', cursor: 'pointer', marginTop: '0.2rem'}} />
          </AddButton>
        </FlexWrapper>
      </FlexWrapper>
  )
}

export default StandardNotification