import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    renegotiations: [],
    refinances: [],
  },
  status: 'idle',
  error: null
}

export const userClosingLeadsSlice = createSlice({
  name: 'teamClosures',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.renegotiations = payload.data.teamRenegotiationClosures
        state.data.refinances = payload.data.teamRefinanceClosures
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      if (payload.data.establishedOutcome === 'renegotiation') {
        state.data.renegotiations.splice(payload.data.teamClosureIndex, 0, payload.data.newTeamClosure)
      } else {
        state.data.refinances.splice(((payload.data.teamClosureIndex - state.data.refinances.length)*-1 ), 0, payload.data.newTeamClosure)
          //* ** updated proceeding monthly stats
        for (let j = 0; j < payload.data.updatedTeamClosures.length; j++) {
          let thisClosure = state.data.refinances.find(closure => closure._id === payload.data.updatedTeamClosures[j]._id)
          let thisIndex = state.data.refinances.indexOf(thisClosure)
          state.data.refinances[thisIndex] = payload.data.updatedTeamClosures[j]
        }
      }
    })
  }
})

export default userClosingLeadsSlice.reducer

export const selectTeamRenegotiationClosures = state => state.teamClosures.data.renegotiations
export const selectTeamRefinanceClosures = state => state.teamClosures.data.refinances