import React from "react";
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const RepositoryGuide = () => {

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Mortgage Repository" Component
      </GuideText>
      <GuideText className="medium">
        holds all of your team's mortgages that are saved in the system.
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        Public Record Scans will be performed on a monthly basis for every mortgage in your team's repository.
      </GuideText>
      <GuideText className="halfIndent">
        This component is your go-to for searching through and sorting your portfolio.
      </GuideText>
      {/* <GuideText className="halfIndent">
        Any team member can be assigned to a mortgage if they wished to receive email notifications when discrepancies are first discovered or whenever there are official changes made to Internal Records.
      </GuideText> */}

      <Divider className="narrow" />
      
      <GuideText className="header">
        Mortgage Actions
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        Mortgages share information with the actions that are taken on an Investigation. The same reports, notes, and records can be<br/>
        viewed from either entity, but the Mortgage Actions will dsiplay a timeline that covers the entire history of a mortgage, not just<br/>
        the history of events that occurred during the current investigation.
      </GuideText>

      <Divider className="narrow" />
      
      <GuideText className="header">
        Saving a new mortgage
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        When your team originates a new mortgage, go to either the Repository or Propery Search to save the property in the system.
      </GuideText>
      <GuideText className="halfIndent">
        Just provide the address and a new entry will be populated with the current information found in the public records.
      </GuideText>
      <GuideText className="halfIndent">
        If the agreement was recently made and the changes aren't yet reflected in the public records, the mortgage can be set so that
      </GuideText>
      <GuideText className="halfIndent bottom">
        assignees will be alerted once detected changes require verification.
      </GuideText>
    </FlexWrapper>
  )
}

export default RepositoryGuide