import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HeaderContainer, ColumnContent, HeaderText, HorizontalContainer, ComponentContainer, VerticalContainer, MainContent, ComponentBorder, FlexWrapper, Typography } from "../../../../../StyledComponents";

const TeamStats = ({ teamLeadGenerationStats }) => {
  const theme = useTheme()

  return (
    <ComponentContainer className="justifyStart detailsContainer thinBorders" style={{gridRow: '1', gridColumn: '3', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer>
          <HeaderText>Complete Lead Generation Statistics</HeaderText>
        </HeaderContainer>
        <HorizontalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Records Swept
            </ColumnContent>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
              {teamLeadGenerationStats.totalSuccessfulQueries}
            </Typography>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Total Hits
            </ColumnContent>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
              {teamLeadGenerationStats.totalHits}
            </Typography>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Leads Generated
            </ColumnContent>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
              {teamLeadGenerationStats.totalLeadsGenerated}
            </Typography>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Hit Frequency
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.2rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                      How often a sweep of public records detects one or more discrepancies on any property.
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </ColumnContent>
            <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
              {teamLeadGenerationStats.totalHitsAvgPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
              <span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', margin: '0 0 0 0.2rem', fontSize: '1.2rem'}}>%</span>
            </Typography>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Total Tier 1
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.2rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                      The value shown in parentheses is the percentage of leads generated that are Tier 1.
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </ColumnContent>
            <FlexWrapper className="justifyCenter">
              <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem'}}>
                {teamLeadGenerationStats.totalTier1Leads}
              </Typography>
              <Typography className="justifyCenter alignCenter" style={{gridColumn: '5', fontSize: '1rem', margin: '0 0 0 1rem'}}>
                <span style={{margin: '0', fontWeight: '400', fontSize: '1rem'}}>(</span>
                {teamLeadGenerationStats.totalTier1Leads === 0 ?
                  `0`
                :
                  (Math.round((teamLeadGenerationStats.totalTier1Leads/teamLeadGenerationStats.totalLeadsGenerated)*100))
                }
                <span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', margin: '0 0 0 0.2rem', fontSize: '1rem'}}>%</span>
                <span style={{margin: '0', fontWeight: '400', fontSize: '1rem'}}>)</span>
              </Typography>
            </FlexWrapper>
          </VerticalContainer>
          <VerticalContainer className="noGap">
            <ColumnContent>
              Total Tier 2
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.info.color}`,
                  backgroundColor: `${theme.tooltip.info.background}`,
                  border: `0.2rem solid ${theme.tooltip.info.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                  opacity: '0',
                  width: 'fit-content',
                  padding: '1rem 1.5rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                }]}}
                title={
                  <FlexWrapper className="column">
                    <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                      The value shown in parentheses is the percentage of leads generated that are Tier 2.
                    </Typography>
                  </FlexWrapper>
                } 
                placement="top"
                arrow
              >
                <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                  <HelpOutlineIcon />
                </span>
              </Tooltip>
            </ColumnContent>
            <FlexWrapper className="justifyCenter">
              <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem'}}>
                {teamLeadGenerationStats.totalTier2Leads}
              </Typography>
              <Typography className="justifyCenter alignCenter" style={{gridColumn: '5', fontSize: '1rem', margin: '0 0 0 1rem'}}>
                <span style={{margin: '0', fontWeight: '400', fontSize: '1rem'}}>(</span>
                {teamLeadGenerationStats.totalTier2Leads === 0 ?
                  `0`
                :
                  (Math.round((teamLeadGenerationStats.totalTier2Leads/teamLeadGenerationStats.totalLeadsGenerated)*100))
                }
                <span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', margin: '0 0 0 0.2rem', fontSize: '1rem'}}>%</span>
                <span style={{margin: '0', fontWeight: '400', fontSize: '1rem'}}>)</span>
              </Typography>
            </FlexWrapper>
          </VerticalContainer>
        </HorizontalContainer>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default TeamStats;