import React from "react";
import { CompareDetail, DetailWrapper, AccentInfo, ComponentSectionRow, ListRow } from "../../../../../StyledComponents";

const AdminRow = ({ background, record }) => {

  return (
    <ComponentSectionRow className={background === 'gray' ? 'even adminRepository' : 'adminRepository'}>
      <div style={{gridColumn: '1', width: '100%'}}></div>
      <CompareDetail style={{gridColumn: '2', justifyContent: 'flex-end'}}>
        <DetailWrapper className={record.status === 'initial' ? 'initial' : record.discrepancy === 'active' ? `${record.assignedTier}` : ''}>
          {record.label.includes('Primary') ?
            <>
              {record.label.replace('Primary', '')}
            </>
          : record.label.includes('Secondary') ?
            <>
              {record.label.replace('Secondary', '')}
            </>
          :
            record.label
          }
        </DetailWrapper>
      </CompareDetail>
      <CompareDetail style={{gridColumn: '3', justifyContent: 'center'}}>
        {record.currentValue ?
          <DetailWrapper className={record.discrepancy === 'active' ? "active" : record.discrepancy === 'rejected' ? "rejected" : record.discrepancy === 'resolved' ? 'resolved' : record.discrepancy === 'inactive' ? 'inactive' : ''}>
            {record.label === 'Assessed Value' || record.label === 'Land Value' || record.label === 'Improvements Value' || record.label === 'Close Price' || record.label === 'Last Sale Price' || record.label === 'Tax Annual Amount' || record.label === 'Assessed Land Value' || record.label === 'Assessed Improvement Value' ?
              <>
                ${parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
              </>
            : record.label === 'Tax Annual Amount' ?
              <>
                ${parseFloat(record.currentValue.substring(1)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
              </>
            : record.label === 'Latitude' || record.label === 'Longitude' || record.label === 'Rooms' || record.label === 'Bedrooms' || record.label === 'Bathrooms Full' || record.label === 'Bathrooms Total' ?
              <>
                {parseFloat(record.currentValue)}
              </>
            : record.label === 'Living Area' || record.label === 'Garage Area' || record.label === 'Building Area Total' || record.label === 'Gross Area' || record.label === 'Lot Size Square Feet' ?
              <>
                {parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
              </>
            :
              record.currentValue
            }
          </DetailWrapper>
        :
          <DetailWrapper className={record.discrepancy === 'active' ? "active fine empty" : record.discrepancy === 'rejected' ? "rejected fine empty" : record.discrepancy === 'resolved' ? 'resolved fine empty' : record.discrepancy === 'inactive' ? 'gray fine empty' : ''} style={{fontWeight: '400'}}>
            -empty-
          </DetailWrapper>
        }
      </CompareDetail>
      {record.publicRecordValue ?
        <CompareDetail style={{gridColumn: '4', justifyContent: 'center'}}>
          {record.label === 'Assessed Value' || record.label === 'Land Value' || record.label === 'Improvements Value' || record.label === 'Close Price' || record.label === 'Last Sale Price' || record.label === 'Tax Annual Amount' || record.label === 'Assessed Land Value' || record.label === 'Assessed Improvement Value' ?
            <>
              ${parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
            </>
          : record.label === 'Lot Size Square Feet' ?
            <>
              {parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
            </>
          : record.label === 'Living Area' || record.label === 'Garage Area' ?
            <>
              {parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
            </>
          :
            record.publicRecordValue
          }
        </CompareDetail>
      :
        <CompareDetail style={{gridColumn: '4'}}>
          <AccentInfo>-empty-</AccentInfo>
        </CompareDetail>
      }
    </ComponentSectionRow>
  )
}

export default AdminRow;