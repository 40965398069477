import React, { useState } from "react";
import 'react-csv-importer/dist/index.css';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import AssigneeDropdown from "../../../../DropDowns/AssigneeDropdown";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { StyledToggleButton } from "../../../../../componentThemes";
import { selectOpenLeadInvestigationPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { useSetStatusInvestigatingMutation } from "../../../../../slices/api/apiSlice";
import { selectTeamDefaultInterestRate, selectTeamDefaultTargetTerm } from "../../../../../slices/teamAndUserSettingsSlice";
import { Button, ButtonWrapper, FlexWrapper, Label, ModalContentContainer, ComponentHeader, SubHeader, Typography } from "../../../../../StyledComponents";
import { setLeadActionNavMenuSelection } from "../../../../../slices/sessionDataSlice";

const InvestigateLead = ({ handleCloseModal, userId, teamId, userFullName, teamMembersByName, mortgageId, leadData, dashPerspective, requestOrigin }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const defaultTargetLoanTerm = useSelector(selectTeamDefaultTargetTerm)
  const defaultTargetInterestRate = useSelector(selectTeamDefaultInterestRate)
  const openLeadInvestigationPerm = useSelector(selectOpenLeadInvestigationPerm)

  const [assigneeIds, setAssigneeIds] = useState(leadData.assigneeIds)
  const [targetOutcome, setTargetOutcome] = useState('renegotiation')
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSetAssignee = (event) => {
    setAssigneeIds(event.target.value)
  };

  const [setStatusInvestigating] = useSetStatusInvestigatingMutation()
  
  let handleSetInvestigating = async () => {
    setIsLoading(true)
    let assigneeNames = []
    for (let i = 0; i < assigneeIds.length; i++) {
      let teamMember = teamMembersByName.find(selected => selected.memberId === assigneeIds[i])
      assigneeNames.push(teamMember.fullName)
    }
    let resp = await setStatusInvestigating({
      userId: userId,
      mortgageId: mortgageId,
      userFullName: userFullName,
      assigneeNames: assigneeNames,
      leadId: leadData._id,
      assigneeIds: assigneeIds,
      targetOutcome: targetOutcome,
      teamId: teamId,
      defaultTargetLoanTerm: defaultTargetLoanTerm,
      defaultTargetInterestRate: defaultTargetInterestRate,
      existingTargetInterestRate: leadData.targetInterestRate,
      requestOrigin: requestOrigin,
    })
    if (resp.error) {
      setIsError(true)
    } else if (resp.data.message.includes('success')) {
      setIsSuccess(true)
    } else {
      setIsError(true)
    }
    setIsLoading(false)
  }

  const handleTargetOutcome = (event) => {
    setTargetOutcome(event.target.value)
  }
  const handleGoToClosing = () => {
    dispatch( setLeadActionNavMenuSelection('beginClosingLead'))
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
            <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
            Success
          </span>
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        <ComponentHeader className="modal">Open Investigation</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto 1.5rem auto'}}>
            {targetOutcome === "renegotiation" ?
              <Typography className="fitContentHeight justifyEnd small">
                Renegotiating this mortgage is now under investigation.
              </Typography>
            :
              <Typography className="fitContentHeight justifyEnd small">
                Refinancing this mortgage is now under investigation.
              </Typography>
            }
          </SubHeader>
        :
          <>
            <SubHeader className="modal" style={{margin: '0 auto 1.5rem auto'}}>
              <span>Select the target outcome and assign</span>
              <span>one or more team members to the investigation.</span>
            </SubHeader>
            <FlexWrapper style={{gridColumn: '1', gridRow: '3', height: 'fit-content', margin: '1rem 0'}}>
              <Stack style={{margin: '0 auto'}}>
                <ToggleButtonGroup
                  value={targetOutcome}
                  exclusive
                  onChange={handleTargetOutcome}
                  style={{height: '3rem', margin: '0 auto 1.5rem auto'}}
                >
                  <StyledToggleButton value="renegotiation">
                    RENEGOTIATION
                  </StyledToggleButton>
                  <StyledToggleButton value="refinance">
                    REFINANCE
                  </StyledToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </FlexWrapper>
            <FlexWrapper className="column justifyStart alignStart fitContentWidth fitContentHeight" style={{width: '25rem', margin: '0 auto 1.5rem auto'}}>
              <Label style={{margin: '0 0 0 2rem'}}>Assignee(s)</Label>
              <AssigneeDropdown
                value={assigneeIds}
                handleChange={handleSetAssignee}
                items={teamMembersByName}
              />
            </FlexWrapper>
          </>
        }
        {isSuccess || isError ?
          <ButtonWrapper style={{marginTop: '3rem'}}>
            <Button className="neutral" onClick={() => handleCloseModal()}>close</Button>
            <Button className="primary" onClick={() => handleGoToClosing()} style={{width: '15rem'}}>go to Closing</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper style={{marginTop: '3rem'}}>
            {/* <Button className="neutral" onClick={() => handleCloseModal()}>Cancel</Button> */}
            <Button
              className={!openLeadInvestigationPerm ? "primaryDisabled" : isLoading ? "primaryLoading" : "primary"} 
              onClick={openLeadInvestigationPerm ? () => handleSetInvestigating() : null} 
            >
              {isLoading ?
                <CircularProgress
                  sx={{ color: `${theme.palette.common.white}` }}
                  size={18}
                />
              :
                `Confirm`
              }
            </Button>
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default InvestigateLead;