import React from "react";
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const RepositoryGuide = () => {

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <GuideText className="header">
        The "Mortgage Repository" Component
      </GuideText>
      <GuideText className="medium">
        holds all of your team's mortgages that are saved in the system.
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        All mortgages, whether they currently have an active investigation or not, can have team members assigned, allowing them to<br/>
        receive email notifications when discrepancies are first discovered and whenever there are changes to the Internal Records.
      </GuideText>

      <Divider className="narrow" />
      
      <GuideText className="header">
        Mortgage Actions
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        Mortgages share information with the actions that are taken on an Investigation.<br/>
        The same reports, notes, and records can be viewed from either entity, but the Mortgage Actions will dsiplay a<br/>
        timeline that covers the entire history of a mortgage, not just that for a given investigations.
      </GuideText>

      <Divider className="narrow" />
      
      <GuideText className="header">
        Saving a new mortgage
      </GuideText>
      <GuideText className="newParagraph halfIndent">
        When your team originates a new mortgage, come to the repository to save the property in the system.
      </GuideText>
      <GuideText className="halfIndent">
        Just provide the address and a new entry will be populated with the current information found in the public records.
      </GuideText>
      <GuideText className="halfIndent">
        If the agreement was recently made and the changes aren't yet reflected in the public records,
      </GuideText>
      <GuideText className="halfIndent bottom">
        The mortgage can be set to alert assignees once changes are detected.
      </GuideText>
    </FlexWrapper>
  )
}

export default RepositoryGuide