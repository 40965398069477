export default async function handleFilterMortgages(eventValue, mortgages, userId, newMortgageIds) {
  let filteredMortgages = []
  if (eventValue === 'all') {

  } else if (eventValue === 'entityOwned') {
    for (let i = 0; i < mortgages.length; i++) {
      let matchingMortgage = mortgages[i].tags.find(tag => tag.apiMapping === 'ownerEntityType')
      if (matchingMortgage) {
        filteredMortgages.push(mortgages[i])
      }
    }
  } else if (eventValue === 'quitClaim') {
    for (let i = 0; i < mortgages.length; i++) {
      let matchingMortgage = mortgages[i].tags.find(tag => tag.apiMapping === 'quitClaim')
      if (matchingMortgage) {
        filteredMortgages.push(mortgages[i])
      }
    }
  } else if (eventValue === 'secondMortgage') {
    for (let i = 0; i < mortgages.length; i++) {
      let matchingMortgage = mortgages[i].tags.find(tag => tag.apiMapping === 'secondMortgage')
      if (matchingMortgage) {
        filteredMortgages.push(mortgages[i])
      }
    }
  } else if (eventValue === 'ownerOccupied') {
    for (let i = 0; i < mortgages.length; i++) {
      let matchingMortgage = mortgages[i].tags.find(tag => tag.apiMapping === 'ownerOccupied')
      if (matchingMortgage) {
        filteredMortgages.push(mortgages[i])
      }
    }
  } else if (eventValue === 'assigned') {
    for (let i = 0; i < mortgages.length; i++) {
      if (mortgages[i].userAssignment) {
        filteredMortgages.push(mortgages[i])
      }
    }
  } else if (eventValue === 'user') {
    for (let i = 0; i < mortgages.length; i++) {
      if (mortgages[i].activeLead && mortgages[i].activeLead.assigneeIds) {
        let match = mortgages[i].activeLead.assigneeIds.find(user => user === userId)
        if (match) {
          filteredMortgages.push(mortgages[i])
        }
      }
    }
  } else if (eventValue === 'customTagViewCombination') {
    for (let i = 0; i < newMortgageIds.length; i++) {
      let match = mortgages.find(mortgage => mortgage._id.toString() === newMortgageIds[i].toString())
      if (match) {
        filteredMortgages.push(match)
      }
    }
  }
  return filteredMortgages
}