import React from "react";
import Timeline from "../../Timeline/Timeline";
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteMortgage from "./components/DeleteMortgage";
import CompareRecords from "./components/CompareRecords/CompareRecords";
import { useSelector } from "react-redux";
import RepositoryModalNav from "./RepositoryModalNav";
import SingleScanControls from "../../SweepControls/SingleScanControls";
import { selectViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { selectAdminRepositoryNavSelection } from "../../../slices/sessionDataSlice";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer, ComponentHeader } from "../../../StyledComponents";

const RepositoryModal = ({ handleCloseRepositoryModal, userId, teamId, userFullName }) => {

  const navSelection = useSelector(selectAdminRepositoryNavSelection)
  const mortgageData = useSelector(selectViewingMortgage)

  return (
    <ModalContainer className="threePanel">
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/6'}}>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseRepositoryModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <RepositoryModalNav />
      {navSelection === 'compare' ?
        <CompareRecords mortgageData={mortgageData} />
      : navSelection === 'scan' ?
        <SingleScanControls mortgageId={mortgageData._id} userId={userId} teamId={teamId} existingScans={mortgageData.recordSweepss} />
      : navSelection === 'delete' ?
        <DeleteMortgage handleCloseRepositoryModal={handleCloseRepositoryModal} mortgageId={mortgageData._id} userId={userId} teamId={teamId} userFullName={userFullName} mortgageData={mortgageData} />
      :
        <></>
      }
      <ComponentHeader className="modal timeline" style={{gridColumn: '4'}}>Timeline</ComponentHeader>
      <TimelineContainer className="actionsModal">
        <Timeline timeline={mortgageData.timeline} />
      </TimelineContainer>

      <ComponentBorder className="left" style={{gridRow:'2/5', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right" style={{gridRow:'2/5', gridColumn: '5'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'5', gridColumn: '1/6'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default RepositoryModal;