import React from "react";
import GavelIcon from '@mui/icons-material/Gavel';
import GroupsIcon from '@mui/icons-material/Groups';
import GridViewIcon from '@mui/icons-material/GridView';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import CopyrightIcon from '@mui/icons-material/Copyright';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ConstructionIcon from '@mui/icons-material/Construction';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Divider, FlexWrapper, GuideText } from "../../../../../StyledComponents";

const LeadOverviewGuide = () => {

  return (
    <FlexWrapper className="column overflowVerticalContainer">
      <FlexWrapper>
        <GuideText className="header">
          Violation Detection with Lancaster Sweep
        </GuideText>
        {/* <div style={{height: '2rem'}}>
          <CopyrightIcon />
        </div> */}
      </FlexWrapper>
      <GuideText className="medium">
        Monitor your mortgages by providing your team with early notice of contractual violations.
      </GuideText>
      <GuideText>
        Your entire mortgage portfolio is compared to current public records on a monthly basis.<br/>
        The system detects discrepancies, analyzes the scenario, and notifies your team of potential violations.
      </GuideText>

      <GuideText className="header newParagraph">
        Maintain property oversight with up to date information.
      </GuideText>
      <GuideText className="medium">
        All changes found in the public records are reflected in your mortgage data.
      </GuideText>
      <GuideText>
        Discrepancies detected on any mortgage will initiate an "Investigation".<br/>
        Notification emails are dispatched to detail the count and urgency of the cases discovered.<br/>
        Your team can then utilize the application to manage the resolution process.
      </GuideText>
      <GuideText className="newParagraph">
        A two-tiered system is used to denote urgency, and tags are assigned to easily identify and group investigations.
      </GuideText>

      <Divider className="narrow" />

      <GuideText className="header one">
        Tier 1
      </GuideText>
      <GuideText className="oneIndent">
        Tier 1 investigations are created when a likely violation is detected, including:
      </GuideText>
      <FlexWrapper className="justifyAround" style={{padding: '1rem 4rem'}}>
        <FlexWrapper className="alignCenter justifyCenter">
          <GroupsIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} /> 
          <GuideText className="medium">
            Ownership changes
          </GuideText>
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter">
          <LocalOfferIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} />
          <GuideText className="medium">
            Listed for Sale
          </GuideText>
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter">
          <AccountBalanceIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} />
          <GuideText className="medium">
            New Mortgage
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper className="justifyAround"  style={{padding: '1rem 14rem'}}>
        <FlexWrapper className="alignCenter justifyCenter">
          <DomainAddIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} />
          <GuideText className="medium">
            Units Added/Removed
          </GuideText>
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter">
          <GridViewIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} />
          <GuideText className="medium">
            Subdivision
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>

      <GuideText className="header large two">
        Tier 2
      </GuideText>
      <GuideText className="oneIndent">
        Tier 2 investigations are created when detected changes show a potential for significant changes in property value or risk.
      </GuideText>
      <FlexWrapper className="justifyAround" style={{padding: '1rem 4rem 0 1rem', marginBottom: 'auto'}}>
        <FlexWrapper className="alignCenter justifyCenter">
          <ArchitectureIcon style={{height: '4rem', width: '4rem', margin: '0 1rem 0 0'}} />
          <GuideText className="medium">
            Rezoning
          </GuideText>
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter">
          <GavelIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} />
          <GuideText className="medium">
            Tax Exemption Status Changed
          </GuideText>
        </FlexWrapper>
        <FlexWrapper className="alignCenter justifyCenter">
          <ConstructionIcon style={{height: '4rem', width: '4rem', margin: '0 1rem'}} />
          <GuideText className="medium">
            Improvements
          </GuideText>
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default LeadOverviewGuide