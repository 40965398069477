import React from "react";
import Tier2 from "./components/PieCharts/Tier2";
import Tier1 from "./components/PieCharts/Tier1";
import Manual from "./components/PieCharts/Manual";
import TeamStats from "./components/TeamStats";
import MainChart from "./components/MainChart/MainChart";
import ActionType from "./components/PieCharts/ActionType";
import { PageContent } from "../../../../StyledComponents";
import { useSelector } from "react-redux";
import { selectLeadGenerationData } from "../../../../slices/teamMonthlyStatsSlice";
import { selectTeamLeadGenerationStats } from "../../../../slices/teamStatsSlice";

const LeadGenerationOverview = () => {

  const leadGenerationData = useSelector(selectLeadGenerationData)
  const teamLeadGenerationStats = useSelector(selectTeamLeadGenerationStats)

  return (
    <PageContent className="grid leadGeneration">
      <MainChart leadGenerationData={leadGenerationData} />
      <TeamStats teamLeadGenerationStats={teamLeadGenerationStats} />
      <div style={{gridRow: '1/4', gridColumn: '1', height: '100%', width: '100%', display: 'grid', gridTemplateRows: '1fr 0.5rem 1fr 0.5rem 1fr 0.5rem 1fr'}}>
        <ActionType />
        <Tier1 />
        <Tier2 />
        <Manual />
      </div>
    </PageContent>
  )
}

export default LeadGenerationOverview;