import React from "react"
import NoteAdded from "./components/NoteAdded";
import Finalized from "./components/Finalized";
import Unassigned from "./components/Unassigned";
import TagAssigned from "./components/TagAssigned";
import { useTheme } from "@mui/styles";
import ScanPerformed from "./components/ScanPerformed";
import TargetsUpdated from "./components/TargetsUpdated";
import { useSelector } from "react-redux";
import AssigneesUpdated from "./components/AssigneesUpdated";
import LeadPathConnectors from "./components/PathConnectors/LeadPathConnectors";
import UnderInvestigation from "./components/UnderInvestigation";
import FieldValueProvided from "./components/FieldValueProvided";
import DiscrepancyResolvedOrRejected from "./components/DiscrepancyResolvedOrRejected";
import ManualInvestigation from "./components/ManualInvestigation";
import TargetOutcomeUpdated from "./components/TargetOutcomeUpdated";
import FinancialInfoProvided from "./components/FinancialInfoProvided";
import PublicRecordsConfirmed from "./components/PublicRecordsConfirmed";
import DiscrepanciesAllResolved from "./components/DiscrepanciesAllResolved";
import DiscrepancyEditedOrRejected from "./components/DiscrepancyEditedOrCreated";
import { FlexWrapper, ScrollWindow } from "../../StyledComponents";
import { selectViewingLeadTimeline } from "../../slices/viewingLeadSlice";

const LeadTimeline = ({ origin }) => {
  const theme = useTheme()

  const reversedTimeline = [...useSelector(selectViewingLeadTimeline)].reverse()

  return (
    <ScrollWindow className="verticalOnly">
      {reversedTimeline.map((milestone, index) => (
        <div key={`${milestone.milestone}${index}`} style={{padding: index === 0 ? '0.3rem 0 0 0.5rem' : '0 0 0 0.5rem'}}>
          <FlexWrapper style={{marginBottom: '0.3rem', boxShadow: `0rem 0rem 0.5rem ${theme.palette.common.grayText}`, border: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <LeadPathConnectors milestone={milestone} mapIndex={index} timeline={reversedTimeline} />
            {milestone.milestone === 'Discrepancies Detected' || milestone.milestone === 'New Discrepancies Discovered' || milestone.milestone === 'Public Records Updated' || milestone.milestone === 'Record Scanned Successfully' ?
              <ScanPerformed milestone={milestone} />
            : milestone.milestone === 'Refinance Targets Updated' ?
              <TargetsUpdated milestone={milestone} />
            : milestone.milestone.includes('Target Outcome') ?
              <TargetOutcomeUpdated milestone={milestone} />
            : milestone.milestone === 'Refinance Investigation Opened' || milestone.milestone === 'Renegotiation Investigation Opened' ?
              <UnderInvestigation milestone={milestone} />
            : milestone.milestone === 'Lead Tag Created & Assigned' || milestone.milestone === 'Lead Tag Assigned' || milestone.milestone === 'Mortgage Tag Created & Assigned' || milestone.milestone === 'Mortgage Tag Assigned' ?
              <TagAssigned milestone={milestone} />
            : milestone.milestone === 'Manual Refinance Investigation Opened' || milestone.milestone === 'Manual Renegotiation Investigation Opened' ? 
              <ManualInvestigation milestone={milestone} />
            : milestone.milestone.includes('Lead Assignees Updated') || milestone.milestone.includes('Mortgage Assignees Updated') ?
              <AssigneesUpdated milestone={milestone} />
            : milestone.milestone === 'Note Added' ?
              <NoteAdded milestone={milestone} origin={origin} />
            : milestone.milestone.includes('Record Provided') ?
              <FieldValueProvided milestone={milestone} />
            : milestone.milestone.includes('Discrepancy Resolved') || milestone.milestone.includes('Discrepancy Rejected') ?
              <DiscrepancyResolvedOrRejected milestone={milestone} />
            : milestone.milestone.includes('Discrepancy Edited') || milestone.milestone.includes('Discrepancy Created') || milestone.milestone === 'Internal Records Updated' ?
              <DiscrepancyEditedOrRejected milestone={milestone} />
            : milestone.milestone.includes('Finalized') ?
              <Finalized milestone={milestone} origin={origin} />
            : milestone.milestone === 'All Discrepancies Resolved' ?
              <DiscrepanciesAllResolved milestone={milestone} />
            : milestone.milestone === 'Public Records Verified' ?
              <PublicRecordsConfirmed milestone={milestone} />
            : milestone.milestone === 'Financial Information Provided' ?
              <FinancialInfoProvided milestone={milestone} />
            :
              <Unassigned milestone={milestone} />
            }
          </FlexWrapper>
        </div>
      ))}
    </ScrollWindow>
  )
}

export default LeadTimeline;