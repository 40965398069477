import React from "react";
import ClosingCard from "./ClosingCard";
import { CardContainer } from "../../../../../../StyledComponents";
import InvestigatingCard from "./InvestigatingCard";
import AwaitingActionCard from "./AwaitingActionCard";
import AwaitingUpdateCard from "./AwaitingUpdateCard";

const Card = ({ lead, leadStatusFilter, cardIndex, userId, userFullName, dashPerspective, handleOpenLeadActionsModal, teamId }) => {

  return (
    <CardContainer className="shadow">
      {leadStatusFilter === "Awaiting Action" ?
        <AwaitingActionCard lead={lead} handleOpenLeadActionsModal={handleOpenLeadActionsModal} cardIndex={cardIndex} />
      : leadStatusFilter === "Investigating" ?
        <InvestigatingCard lead={lead} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} cardIndex={cardIndex} />
      : leadStatusFilter === "Closing" ?
        <ClosingCard lead={lead} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} handleOpenLeadActionsModal={handleOpenLeadActionsModal} cardIndex={cardIndex} />
      :
        <AwaitingUpdateCard lead={lead} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} handleOpenLeadActionsModal={handleOpenLeadActionsModal} cardIndex={cardIndex} teamId={teamId} />
      }
    </CardContainer>
  )
}

export default Card;