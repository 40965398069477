import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    memberId: '',
    fullName: '',
    role: '',
    email: '',
    assignedMortgages: [],
    awaitingActionLeads: [],
    investigatingLeads: [],
    closingLeads: [],
    awaitingUpdateLeads: [],
    filteredAwaitingActionLeads: [],
    filteredInvestigatingLeads: [],
    filteredClosingLeads: [],
    filteredAwaitingUpdateLeads: [],
  },
  status: 'idle',
  error: null
}

export const memberAssignmentsSlice = createSlice({
  name: 'memberAssignments',
  initialState,
  reducers: {
    setMemberLeadDsiableScan(state, action) {
      let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
      if (thisLead) {
        let thisLeadIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
        state.data.awaitingUpdateLeads[thisLeadIndex].disableScan = true
      }
      let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
      if (thisFilteredLead) {
        let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
        state.data.filteredAwaitingUpdateLeads[thisFilteredIndex].disableScan = true
      }
    },
    setMemberAssignments(state, action) {
      state.data.memberId = action.payload.memberId
      state.data.assignedMortgages = action.payload.assignedMortgages
      state.data.awaitingActionLeads = action.payload.awaitingActionLeads
      state.data.investigatingLeads = action.payload.investigatingLeads
      state.data.closingLeads = action.payload.closingLeads
      state.data.awaitingUpdateLeads = action.payload.awaitingUpdateLeads
    },
    setSortedMemberAwaitingActionLeads(state, action) { 
      state.data.awaitingActionLeads = action.payload 
    },
    setSortedMemberInvestigatingLeads(state, action) { 
      state.data.investigatingLeads = action.payload 
    },
    setSortedMemberClosingLeads(state, action) { 
      state.data.closingLeads = action.payload 
    },
    setSortedMemberAwaitingUpdateLeads(state, action) { 
      state.data.awaitingUpdateLeads = action.payload 
    },
    setFilteredMemberAwaitingActionLeads(state, action) { 
      state.data.filteredAwaitingActionLeads = action.payload 
    },
    setFilteredMemberInvestigatingLeads(state, action) { 
      state.data.filteredInvestigatingLeads = action.payload 
    },
    setFilteredMemberClosingLeads(state, action) { 
      state.data.filteredClosingLeads = action.payload 
    },
    setFilteredMemberAwaitingUpdateLeads(state, action) { 
      state.data.filteredAwaitingUpdateLeads = action.payload 
    },
    addMemberUpdatedLeadsFromSweep(state, action) { 
      for (let i = 0; i < action.payload.length; i++) {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = action.payload[i]
        } else {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = action.payload[i]
          } else {
            let thisLead = state.data.closingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads[thisIndex] = action.payload[i]
            } else {
              let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
              if (thisLead) {
                let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
                state.data.awaitingUpdateLeads[thisIndex] = action.payload[i]
              }
            }
          }
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = action.payload[i]
        } else {
          let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
          if (thisFilteredLead) {
            let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
            state.data.filteredInvestigatingLeads[thisFilteredIndex] = action.payload[i]
          } else {
            let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
              state.data.filteredClosingLeads[thisFilteredIndex] = action.payload[i]
            } else {
              let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = action.payload[i]
              }
            }
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.memberAssignments
      }
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigating
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closing
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'awaitingUpdate') {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadCreated) {
      }
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigating
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closing
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'awaitingUpdate') {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigating
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closing
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'awaitingUpdate') {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigating
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closing
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageTags.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigating
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closing
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigating
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closing
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.updateMortgageAssignees.matchFulfilled, (state, {payload}) => {
      let thisMortgage = state.data.assignedMortgages.find(mortgage => mortgage._id === payload.data.newMortgage._id)
      if (thisMortgage) {
        let thisIndex = state.data.assignedMortgages.indexOf(thisMortgage)
        state.data.assignedMortgages[thisIndex] = payload.data.newMortgage
      } else {
        state.data.assignedMortgages.unshift(payload.data.newMortgage)
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        } else {
          state.data.awaitingActionLeads.unshift(payload.data.newLead)
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        } else {
          state.data.filteredAwaitingActionLeads.unshift(payload.data.newLead)
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigatingLeads
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        } else {
          state.data.investigatingLeads.unshift(payload.data.newLead)
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        } else {
          state.data.filteredInvestigatingLeads.unshift(payload.data.newLead)
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closingLeads
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        } else {
          state.data.closingLeads.unshift(payload.data.newLead)
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        } else {
          state.data.filteredClosingLeads.unshift(payload.data.newLead)
        }
      } else {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        } else {
          state.data.awaitingUpdateLeads.unshift(payload.data.newLead)
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        } else {
          state.data.filteredAwaitingUpdateLeads.unshift(payload.data.newLead)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetChanges.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigating
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closing
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.provideFinancialInformation.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigating
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closing
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
        }
      } else {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (payload.data.newLead ) {
        if (payload.data.leadStatus === 'awaitingAction') {
          //* awaitingAction
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          }
          let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
            state.data.filteredAwaitingActionLeads[thisFilteredIndex] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'investigating') {
          //* investigatingLeads
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.newLead
          }
          let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
            state.data.filteredInvestigatingLeads[thisFilteredIndex] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'closing') {
          //* closingLeads
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.newLead
          }
          let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
            state.data.filteredClosingLeads[thisFilteredIndex] = payload.data.newLead
          }
        } else {
          //* awaitingUpdate
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
          }
          let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
            state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = payload.data.newLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads.splice(thisFilteredIndex, 1)
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigatingLeads
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads.splice(thisFilteredIndex, 1)
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closingLeads
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads.splice(thisFilteredIndex, 1)
        }
      } else {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads.splice(thisIndex, 1)
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads.splice(thisFilteredIndex, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        //* awaitingAction
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads.splice(thisFilteredIndex, 1)
        }
      } else if (payload.data.leadStatus === 'investigating') {
        //* investigatingLeads
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
        }
        let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
          state.data.filteredInvestigatingLeads.splice(thisFilteredIndex, 1)
        }
      } else if (payload.data.leadStatus === 'closing') {
        //* closingLeads
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
        }
        let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
          state.data.filteredClosingLeads.splice(thisFilteredIndex, 1)
        }
      } else {
        //* awaitingUpdate
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads.splice(thisIndex, 1)
        }
        let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingUpdateLeads.splice(thisFilteredIndex, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.setStatusInvestigating.matchFulfilled, (state, {payload}) => {
      //* awaitingAction
      let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLead) {
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads.splice(thisIndex, 1)
      }
      let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisFilteredLead) {
        let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
        state.data.filteredAwaitingActionLeads.splice(thisFilteredIndex, 1)
      }
      //* investigatingLeads
      state.data.investigatingLeads.unshift(payload.data.newLead)
      state.data.filteredInvestigatingLeads.unshift(payload.data.newLead)
    })
    .addMatcher(apiSlice.endpoints.setStatusClosing.matchFulfilled, (state, {payload}) => {
      //* investigatingLeads
      let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLead) {
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads.splice(thisIndex, 1)
      }
      let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisFilteredLead) {
        let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
        state.data.filteredInvestigatingLeads.splice(thisFilteredIndex, 1)
      }
      //* closingLeads
      state.data.closingLeads.unshift(payload.data.newLead)
      state.data.filteredClosingLeads.unshift(payload.data.newLead)
    })
    .addMatcher(apiSlice.endpoints.setStatusFinalized.matchFulfilled, (state, {payload}) => {
      //* closingLeads
      let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
      if (thisLead) {
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads.splice(thisIndex, 1)
      }
      let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
      if (thisFilteredLead) {
        let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
        state.data.filteredClosingLeads.splice(thisFilteredIndex, 1)
      }
      if (payload.data.activeDiscrepancies > 0 || payload.data.preserveLead) {
        state.data.awaitingUpdateLeads.unshift(payload.data.newLead)
        state.data.filteredAwaitingUpdateLeads.unshift(payload.data.newLead)
      }
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      //* awaitingUpdateLeads
      let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
      if (thisLead) {
        let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
        state.data.awaitingUpdateLeads.splice(thisIndex, 1)
      }
      let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
      if (thisFilteredLead) {
        let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
        state.data.filteredAwaitingUpdateLeads.splice(thisFilteredIndex, 1)
      }
      state.data.leadsAwaitingVerification--
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      for (let i = 0; i < payload.data.newLeads.length; i++) {
        if (payload.data.newLeads[i].status === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads.splice(thisIndex, 1)
          }
          let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisFilteredLead) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
            state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
          }
        } else if (payload.data.newLeads[i].status === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads.splice(thisIndex, 1)
          }
          let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisFilteredLead) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
            state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
          }
        } else if (payload.data.newLeads[i].status === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads.splice(thisIndex, 1)
          }
          let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisFilteredLead) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
            state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLeads[i]
          }
          let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisFilteredLead) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLeads[i]
          }
        }
      }
    })
  }
})

export const { setMemberLeadDsiableScan, setMemberAssignments, setSortedMemberInvestigatingLeads, setSortedMemberAwaitingActionLeads, setFilteredMemberAwaitingActionLeads, setSortedMemberClosingLeads, setFilteredMemberInvestigatingLeads, setFilteredMemberClosingLeads, setSortedMemberAwaitingUpdateLeads, setFilteredMemberAwaitingUpdateLeads, addMemberUpdatedLeadsFromSweep } = memberAssignmentsSlice.actions

export default memberAssignmentsSlice.reducer

export const selectMemberClosingLeads = state => state.memberAssignments.data.closingLeads
export const selectMemberInvestigatingLeads = state => state.memberAssignments.data.investigatingLeads
export const selectMemberAwaitingActionLeads = state => state.memberAssignments.data.awaitingActionLeads
export const selectMemberAwaitingUpdateLeads = state => state.memberAssignments.data.awaitingUpdateLeads
export const selectFilteredMemberClosingLeads = state => state.memberAssignments.data.filteredClosingLeads
export const selectFilteredMemberInvestigatingLeads = state => state.memberAssignments.data.filteredInvestigatingLeads
export const selectFilteredMemberAwaitingActionLeads = state => state.memberAssignments.data.filteredAwaitingActionLeads
export const selectFilteredMemberAwaitingUpdateLeads = state => state.memberAssignments.data.filteredAwaitingUpdateLeads