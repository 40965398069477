import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { selectTeamRenegotiations } from "../../../../../slices/teamStatsSlice";
import { HeaderContainer, ColumnContent, HeaderText, HorizontalContainer, ComponentContainer, VerticalContainer, FlexWrapper, ComponentBorder, Typography } from "../../../../../StyledComponents";

const TeamStats = () => {
  const theme = useTheme();

  const teamRenegotiations = useSelector(selectTeamRenegotiations)

  return (
    <ComponentContainer className="justifyStart detailsContainer thinBorders" style={{gridRow: '1', gridColumn: '3', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer>
          <HeaderText>Team Stats</HeaderText>
        </HeaderContainer>
        <HorizontalContainer style={{margin: 'auto'}}>
          {teamRenegotiations !== null ?
            <>
              <VerticalContainer className="noGap">
                <ColumnContent>
                  Closed Renegotiations
                </ColumnContent>
                <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
                  {teamRenegotiations.closedRenegotiations}
                </Typography>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent>
                  Last Renegotiation
                </ColumnContent>
                <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
                  {teamRenegotiations.lastRenegotiation ?
                    teamRenegotiations.lastRenegotiation
                  :
                    `N/A`
                  }
                </Typography>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent>
                  Renegotiation Frequency
                  <Tooltip
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.tooltip.info.color}`,
                      backgroundColor: `${theme.tooltip.info.background}`,
                      border: `0.2rem solid ${theme.tooltip.info.border}`,
                      borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                      opacity: '0',
                      width: 'fit-content',
                      padding: '1rem 1.5rem',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    }]}}
                    title={
                      <FlexWrapper className="column">
                        <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                          Average day's between the team closing a renegotiation.
                        </Typography>
                      </FlexWrapper>
                    } 
                    placement="top"
                    arrow
                  >
                    <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                      <HelpOutlineIcon />
                    </span>
                  </Tooltip>
                </ColumnContent>
                <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
                  {teamRenegotiations.renegotiationFrequency} <span style={{fontWeight: "500", marginLeft: '0.3rem'}}>days</span>
                </Typography>
              </VerticalContainer>
            </>
          :
            <ColumnContent className="single">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={18}
              />
            </ColumnContent>    
          }
        </HorizontalContainer>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default TeamStats;