import React from "react";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import 'react-csv-importer/dist/index.css';
import { useSelector } from "react-redux";
import { selectUserNewInAppNotifications } from "../../../slices/userNotificationsSlice";
import { useDeleteNotificationMutation } from "../../../slices/api/apiSlice";
import { ModalContainer, Button, ButtonWrapper, Divider , ComponentHeader, HeaderContainer, ModalHeaderMessage, FlexWrapper, SubHeader } from "../../../StyledComponents";

const DeleteNotificationModel = ({ userId, notification, handleCloseModal }) => {
  const theme = useTheme();

  const userNewInAppNotifications = useSelector(selectUserNewInAppNotifications)
  const [deleteNotification, {isFetching, isSuccess, isError}] = useDeleteNotificationMutation()

  const handleDelete = async () => {
    deleteNotification({
      notificationId: notification._id,
      userId: userId,
      status: notification.status,
      newInAppNotifications: userNewInAppNotifications
    })
  }

  return (
    <ModalContainer>
      <HeaderContainer className="modal">
        {isSuccess ?
          <>
            <FlexWrapper className="alignCenter">
              <ComponentHeader className="modal">Delete Notification?</ComponentHeader>
              <ModalHeaderMessage className="green">success</ModalHeaderMessage>
            </FlexWrapper>
            <Divider className='green' />
          </>
        : isError ?
          <>
            <FlexWrapper className="alignCenter">
              <ComponentHeader className="modal">Delete Notification?</ComponentHeader>
              <ModalHeaderMessage className="red">failed</ModalHeaderMessage>
            </FlexWrapper>
            <Divider className='red' />
          </>
        :
          <>
            <FlexWrapper className="alignCenter">
              <ComponentHeader className="modal">Delete Notification?</ComponentHeader>
            </FlexWrapper>
            <Divider />
          </>
        }
      </HeaderContainer>
      {isSuccess ?
        <SubHeader className="modal">
          <span>The notification has been deleted.</span>
        </SubHeader>
      : isFetching ?
        <SubHeader className="modal">
          <span>Working on it...</span>
        </SubHeader>
      : isError ?
        <SubHeader className="modal">
          <span>Something has gone wrong.</span>
          <span>Please reach out to your app admin if the problem persists</span>
        </SubHeader>
      :
        <SubHeader className="modal">
          <span>Deleting a notification is permanent.</span>
          <span style={{margin: "1.5rem 0 0 0"}}>Would you like to continue?</span>
        </SubHeader>
      }

      <ButtonWrapper className="justifyCenter" style={{margin: '2rem auto 0 auto'}}>
        {isSuccess || isError ?
          <Button autoFocus className="gray" onClick={() => handleCloseModal()}>Continue</Button>
        : isFetching ?
          <Button disabled className="redLoading">
            <CircularProgress
              sx={{ color: `${theme.palette.common.white}` }}
              size={18}
            />
          </Button>
        :
          <Button autoFocus className="red" onClick={() => handleDelete()}>Delete</Button>
        }
      </ButtonWrapper>

    </ModalContainer>
  )
}

export default DeleteNotificationModel;