import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    awaitingActionLeads: [],
    investigatingLeads: [],
    closingLeads: [],
    awaitingUpdateLeads: [],
    filteredAwaitingActionLeads: [],
    filteredInvestigatingLeads: [],
    filteredClosingLeads: [],
    filteredAwaitingUpdateLeads: [],
    leadsAwaitingUpdate: 0,
    leadsAwaitingVerification: 0,
  },
  status: 'idle',
  error: null
}

export const teamActiveLeadsSlice = createSlice({
  name: 'teamActiveLeads',
  initialState,
  reducers: {
    setTeamLeadDsiableScan(state, action) {
      let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
      if (thisLead) {
        let thisLeadIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
        state.data.awaitingUpdateLeads[thisLeadIndex].disableScan = true
      }
      let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload.leadId.toString())
      if (thisFilteredLead) {
        let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
        state.data.filteredAwaitingUpdateLeads[thisFilteredIndex].disableScan = true
      }
    },
    setSortedTeamAwaitingActionLeads(state, action) { 
      state.data.awaitingActionLeads = action.payload 
    },
    setSortedTeamInvestigatingLeads(state, action) { 
      state.data.investigatingLeads = action.payload 
    },
    setSortedTeamClosingLeads(state, action) { 
      state.data.closingLeads = action.payload 
    },
    setSortedTeamAwaitingUpdateLeads(state, action) { 
      state.data.awaitingUpdateLeads = action.payload 
    },
    setFilteredTeamAwaitingActionLeads(state, action) { 
      state.data.filteredAwaitingActionLeads = action.payload 
    },
    setFilteredTeamInvestigatingLeads(state, action) { 
      state.data.filteredInvestigatingLeads = action.payload 
    },
    setFilteredTeamClosingLeads(state, action) { 
      state.data.filteredClosingLeads = action.payload 
    },
    setFilteredTeamAwaitingUpdateLeads(state, action) { 
      state.data.filteredAwaitingUpdateLeads = action.payload 
    },
    addNewLeadsFromSweep(state, action) { 
      state.data.awaitingActionLeads.push(...action.payload)
    },
    addUpdatedLeadsFromSweep(state, action) { 
      for (let i = 0; i < action.payload.length; i++) {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = action.payload[i]
        } else {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = action.payload[i]
          } else {
            let thisLead = state.data.closingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads[thisIndex] = action.payload[i]
            } else {
              let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
              if (thisLead) {
                let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
                state.data.awaitingUpdateLeads[thisIndex] = action.payload[i]
              }
            }
          }
        }
        let thisFilteredLead = state.data.filteredAwaitingActionLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
        if (thisFilteredLead) {
          let thisFilteredIndex = state.data.filteredAwaitingActionLeads.indexOf(thisFilteredLead)
          state.data.filteredAwaitingActionLeads[thisFilteredIndex] = action.payload[i]
        } else {
          let thisFilteredLead = state.data.filteredInvestigatingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
          if (thisFilteredLead) {
            let thisFilteredIndex = state.data.filteredInvestigatingLeads.indexOf(thisFilteredLead)
            state.data.filteredInvestigatingLeads[thisFilteredIndex] = action.payload[i]
          } else {
            let thisFilteredLead = state.data.filteredClosingLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
            if (thisFilteredLead) {
              let thisFilteredIndex = state.data.filteredClosingLeads.indexOf(thisFilteredLead)
              state.data.filteredClosingLeads[thisFilteredIndex] = action.payload[i]
            } else {
              let thisFilteredLead = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === action.payload[i]._id.toString())
              if (thisFilteredLead) {
                let thisFilteredIndex = state.data.filteredAwaitingUpdateLeads.indexOf(thisFilteredLead)
                state.data.filteredAwaitingUpdateLeads[thisFilteredIndex] = action.payload[i]
              }
            }
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data.awaitingActionLeads = payload.data.teamAwaitingActionLeads
        state.data.investigatingLeads = payload.data.teamInvestigatingLeads
        state.data.closingLeads = payload.data.teamClosingLeads
        state.data.awaitingUpdateLeads = payload.data.teamAwaitingUpdateLeads
        state.data.leadsAwaitingUpdate = payload.data.teamLeadsAwaitingUpdate
        state.data.leadsAwaitingVerification = payload.data.teamLeadsAwaitingVerification
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusInvestigating.matchFulfilled, (state, {payload}) => {
      state.data.investigatingLeads.unshift(payload.data.newLead)
      let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLead) {
        let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
        state.data.awaitingActionLeads.splice(thisIndex, 1)
        state.data.filteredInvestigatingLeads.unshift(payload.data.newLead)
      }
      let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusClosing.matchFulfilled, (state, {payload}) => {
      state.data.closingLeads.unshift(payload.data.newLead)
      let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLead) {
        let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
        state.data.investigatingLeads.splice(thisIndex, 1)
        state.data.filteredClosingLeads.unshift(payload.data.newLead)
      }
      let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
        state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
      }
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
      let thisIndex = state.data.closingLeads.indexOf(thisLead)
      if (thisLead) {
        state.data.closingLeads.splice(thisIndex, 1)
      }
      let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
        state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
      }
      if (payload.data.activeDiscrepancies > 0) {
        state.data.leadsAwaitingUpdate++
        state.data.awaitingUpdateLeads.push(payload.data.newLead)
        state.data.filteredAwaitingUpdateLeads.push(payload.data.newLead)
      }
    })
    .addMatcher(apiSlice.endpoints.continueAwaitingUpdate.matchFulfilled, (state, {payload}) => {
      let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === payload.data.leadId.toString())
      if (thisLead) {
        let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
        state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
      }
      let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id.toString() === payload.data.leadId.toString())
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
      }
      state.data.leadsAwaitingUpdate++
      state.data.leadsAwaitingVerification--
    })
    .addMatcher(apiSlice.endpoints.verifyPublicRecords.matchFulfilled, (state, {payload}) => {
      let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
      if (thisLead) {
        let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
        state.data.awaitingUpdateLeads.splice(thisIndex, 1)
      }
      let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
      if (thisLeadFiltered) {
        let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
        state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
      }
      state.data.leadsAwaitingVerification--
    })
    .addMatcher(apiSlice.endpoints.addMortgageNote.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.saveLeadTargetUpdates.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.provideMortgageFinancialInformation.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus.length > 0) {
        if (payload.data.leadStatus === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadAssignees.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.resolveAllDiscrepancies.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      for (let i = 0; i < payload.data.newLeads.length; i++) {
        if (payload.data.newLeads[i].status === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads.splice(thisIndex, 1)
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
          }
        } else if (payload.data.newLeads[i].status === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads.splice(thisIndex, 1)
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
          }
        } else if (payload.data.newLeads[i].status === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads.splice(thisIndex, 1)
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id.toString() === payload.data.newLeads[i]._id.toString())
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLeads[i]
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLeads[i]._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLeads[i]
          }
        }
        console.log(payload.data.awaitingUpdateDec)
        console.log(payload.data.awaitingVerificationInc)
        state.data.leadsAwaitingUpdate = state.data.leadsAwaitingUpdate + payload.data.awaitingUpdateDec
        state.data.leadsAwaitingVerification = state.data.leadsAwaitingVerification + payload.data.awaitingVerificationInc
      }
    })
    .addMatcher(apiSlice.endpoints.deleteMortgage.matchFulfilled, (state, {payload}) => {
      if (payload.data.updateState) {
        if (payload.data.leadStatus === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads.splice(thisIndex, 1)
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
          }
        } else if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads.splice(thisIndex, 1)
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
          }
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads.splice(thisIndex, 1)
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads.splice(thisIndex, 1)
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
          }
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads.splice(thisIndex, 1)
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.leadId)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
        }
      }
    })
    .addMatcher(apiSlice.endpoints.editOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.leadStatus.length > 0) {
        if (payload.data.leadStatus === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else if (payload.data.leadStatus === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
          }
        }
      }
      if (payload.data.awaitingUpdate !== null) {
        state.data.leadsAwaitingUpdate = payload.data.awaitingUpdate
      }
      if (payload.data.awaitingVerification !== null) {
        state.data.leadsAwaitingVerification = payload.data.awaitingVerification
      }
    })
    .addMatcher(apiSlice.endpoints.undoOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.newLead && payload.data.newLead.status) {
        if (!payload.data.discardLead) {
          if (payload.data.leadStatus === 'awaitingAction') {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
              state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
              state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
              state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else {
            let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
              state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
            }
          }
        } else {
          if (payload.data.leadStatus === 'awaitingAction') {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
              state.data.awaitingActionLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
              state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
              state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
            }
          } else {
            let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
              state.data.awaitingUpdateLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
            }
          }
        }
      }
      if (payload.data.awaitingUpdate !== null) {
        state.data.leadsAwaitingUpdate = payload.data.awaitingUpdate
      }
      if (payload.data.awaitingVerification !== null) {
        state.data.leadsAwaitingVerification = payload.data.awaitingVerification
      }
    })
    .addMatcher(apiSlice.endpoints.resolveOneMortgageDetail.matchFulfilled, (state, {payload}) => {
      console.log(payload.data)
      if (payload.data.newLead && payload.data.newLead.status) {
        if (!payload.data.discardLead) {
          if (payload.data.leadStatus === 'awaitingAction') {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
              state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
              state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else if (payload.data.leadStatus === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
              state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
            }
          } else {
            let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
              state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
            }
            let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
            }
          }
        } else {
          if (payload.data.leadStatus === 'awaitingAction') {
            let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
              state.data.awaitingActionLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingActionLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'investigating') {
            let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
              state.data.investigatingLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
              state.data.filteredInvestigatingLeads.splice(thisIndexFiltered, 1)
            }
          } else if (payload.data.leadStatus === 'closing') {
            let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.closingLeads.indexOf(thisLead)
              state.data.closingLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
              state.data.filteredClosingLeads.splice(thisIndexFiltered, 1)
            }
          } else {
            let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLead) {
              let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
              state.data.awaitingUpdateLeads.splice(thisIndex, 1)
            }
            let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
            if (thisLeadFiltered) {
              let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
              state.data.filteredAwaitingUpdateLeads.splice(thisIndexFiltered, 1)
            }
          }
        }
      }
      if (payload.data.awaitingUpdate !== null) {
        state.data.leadsAwaitingUpdate = payload.data.awaitingUpdate
      }
      if (payload.data.awaitingVerification !== null) {
        state.data.leadsAwaitingVerification = payload.data.awaitingVerification
      }
    })
    .addMatcher(apiSlice.endpoints.changeTargetOutcome.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.closingLeads.indexOf(thisLead)
          state.data.closingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addLeadTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.addMortgageTag.matchFulfilled, (state, {payload}) => {
      if (payload.data.leadStatus === 'awaitingAction') {
        let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
          state.data.awaitingActionLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'investigating') {
        let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
          state.data.investigatingLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
          state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else if (payload.data.leadStatus === 'closing') {
        let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.newLead._id)
        let thisIndex = state.data.closingLeads.indexOf(thisLead)
        state.data.closingLeads[thisIndex] = payload.data.newLead
        let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
          state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.newLead
        }
      } else {
        let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLead) {
          let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
          state.data.awaitingUpdateLeads[thisIndex] = payload.data.newLead
        }
        let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.newLead._id)
        if (thisLeadFiltered) {
          let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
          state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.newLead
        }
      }
    })
    .addMatcher(apiSlice.endpoints.recordRecordSweep.matchFulfilled, (state, {payload}) => {
      state.data.leadsAwaitingUpdate = payload.data.newTeamLeadsAwaitingUpdate
      state.data.leadsAwaitingVerification = payload.data.newTeamLeadsAwaitingVerification
    })
    .addMatcher(apiSlice.endpoints.recordSweepQuery.matchFulfilled, (state, {payload}) => {
      console.log(payload.data.updatedLead)
      console.log(payload.data.updatedLead.status)
      if (payload.data.updatedLead) {
        if (payload.data.updatedLead.status === 'awaitingAction') {
          let thisLead = state.data.awaitingActionLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLead) {
            let thisIndex = state.data.awaitingActionLeads.indexOf(thisLead)
            state.data.awaitingActionLeads[thisIndex] = payload.data.updatedLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingActionLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingActionLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingActionLeads[thisIndexFiltered] = payload.data.updatedLead
          }
        } else if (payload.data.updatedLead.status === 'investigating') {
          let thisLead = state.data.investigatingLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLead) {
            let thisIndex = state.data.investigatingLeads.indexOf(thisLead)
            state.data.investigatingLeads[thisIndex] = payload.data.updatedLead
          }
          let thisLeadFiltered = state.data.filteredInvestigatingLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredInvestigatingLeads.indexOf(thisLeadFiltered)
            state.data.filteredInvestigatingLeads[thisIndexFiltered] = payload.data.updatedLead
          }
        } else if (payload.data.updatedLead.status === 'closing') {
          let thisLead = state.data.closingLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLead) {
            let thisIndex = state.data.closingLeads.indexOf(thisLead)
            state.data.closingLeads[thisIndex] = payload.data.updatedLead
          }
          let thisLeadFiltered = state.data.filteredClosingLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredClosingLeads.indexOf(thisLeadFiltered)
            state.data.filteredClosingLeads[thisIndexFiltered] = payload.data.updatedLead
          }
        } else {
          let thisLead = state.data.awaitingUpdateLeads.find(lead => lead._id === payload.data.updatedLead._id)
          console.log(thisLead)
          if (thisLead) {
            let thisIndex = state.data.awaitingUpdateLeads.indexOf(thisLead)
            state.data.awaitingUpdateLeads[thisIndex] = payload.data.updatedLead
          }
          let thisLeadFiltered = state.data.filteredAwaitingUpdateLeads.find(lead => lead._id === payload.data.updatedLead._id)
          if (thisLeadFiltered) {
            let thisIndexFiltered = state.data.filteredAwaitingUpdateLeads.indexOf(thisLeadFiltered)
            state.data.filteredAwaitingUpdateLeads[thisIndexFiltered] = payload.data.updatedLead
          }
        }
      }
    })
  }
})

export const { setTeamLeadDsiableScan, setSortedTeamAwaitingActionLeads, setFilteredTeamAwaitingActionLeads, setSortedTeamInvestigatingLeads, setSortedTeamClosingLeads, setFilteredTeamInvestigatingLeads, setFilteredTeamClosingLeads, setSortedTeamAwaitingUpdateLeads, setFilteredTeamAwaitingUpdateLeads, addNewLeadsFromSweep, addUpdatedLeadsFromSweep } = teamActiveLeadsSlice.actions

export default teamActiveLeadsSlice.reducer

//* ActiveLeads
export const selectTeamAwaitingActionLeads = state => state.teamActiveLeads.data.awaitingActionLeads
export const selectTeamInvestigatingLeads = state => state.teamActiveLeads.data.investigatingLeads
export const selectTeamClosingLeads = state => state.teamActiveLeads.data.closingLeads
export const selectTeamAwaitingUpdateLeads = state => state.teamActiveLeads.data.awaitingUpdateLeads
export const selectFilteredTeamAwaitingActionLeads = state => state.teamActiveLeads.data.filteredAwaitingActionLeads
export const selectFilteredTeamInvestigatingLeads = state => state.teamActiveLeads.data.filteredInvestigatingLeads
export const selectFilteredTeamClosingLeads = state => state.teamActiveLeads.data.filteredClosingLeads
export const selectFilteredTeamAwaitingUpdateLeads = state => state.teamActiveLeads.data.filteredAwaitingUpdateLeads
export const selectTeamLeadsAwaitingUpdate = state => state.teamActiveLeads.data.leadsAwaitingUpdate
export const selectTeamLeadsAwaitingVerification = state => state.teamActiveLeads.data.leadsAwaitingVerification