import React from "react";
import Notes from "./Components/Notes.js";
import EditTags from "./Components/EditTags/EditTags.js";
import CancelIcon from '@mui/icons-material/Cancel';
import EditTargets from "./Components/EditTargets.js";
import DismissLead from "./Components/LeadStatus/DismissLead.js";
import { useTheme } from "@mui/styles";
import ReportsModal from "./Components/ReportsModal.js";
import AddAssignees from "./Components/AddAssignees/AddAssignees.js";
import LeadTimeline from "../../Timeline/LeadTimeline";
import BeginClosing from "./Components/LeadStatus/BeginClosing";
import FinalizeLead from "./Components/LeadStatus/FinalizeLead.js";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ConfirmUpdate from "./Components/LeadStatus/ConfirmUpdate.js";
import CompareRecords from "./Components/CompareRecords.js";
import LeadActionsNav from "./LeadActionsNav";
import { useNavigate } from "react-router-dom";
import PropertyDetails from "./Components/PropertyDetails.js";
import InvestigateLead from "./Components/LeadStatus/InvestigateLead.js";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ProvideFinancials from "./Components/ProvideFinancials.js";
import ChangeTargetOutcome from "./Components/ChangeTargetOutcome.js";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { selectViewingLead, setViewingLead } from "../../../slices/viewingLeadSlice";
import { selectViewingReports, setViewingLeadReports } from "../../../slices/teamReportsSlice.js";
import { selectViewingMortgage, setViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { selectTeamMembersByName } from "../../../slices/teamMembersSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { removeNotificationFromMortgage, selectMortgagesFilteredBoolean, setFwdBckViewingFilteredMortgages, setFwdBckViewingMortgages } from "../../../slices/teamMortgagesSlice.js";
import { decrementMortgageNotificationsCount } from "../../../slices/userNotificationsSlice.js";
import { selectBackwardViewingLead, selectForwardViewingLead, setFwdBckFilteredViewingLeads, setFwdBckViewingLeads } from "../../../slices/teamActiveLeadsSlice.js";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer , ComponentHeader, Typography, DetailWrapper, FlexWrapper } from "../../../StyledComponents";
import { selectLeadActionsNavSelection, setLeadStatusFilter, setMortgageActionNavMenuSelection, setNavSelection, setViewingLeadActions, setViewingMortgageActions } from "../../../slices/sessionDataSlice.js";

const LeadActionsModal = ({ handleCloseModal, userId, userFullName, dashPerspective, teamId, leadsFiltered }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const leadData = useSelector(selectViewingLead)
  const leadReports = useSelector(selectViewingReports)
  const mortgageData = useSelector(selectViewingMortgage)
  const navSelection = useSelector(selectLeadActionsNavSelection)
  const teamMembersByName = useSelector(selectTeamMembersByName)
  const forwardViewingLead = useSelector(selectForwardViewingLead)
  const backwardViewingLead = useSelector(selectBackwardViewingLead)
  const mortgagesFilteredBoolean = useSelector(selectMortgagesFilteredBoolean)

  const handleGoToMortgageActions = () => {
    if (mortgageData.notifCount && mortgageData.notifCount !== 0) {
      dispatch( decrementMortgageNotificationsCount(1) )
      dispatch( removeNotificationFromMortgage({mortgageId: mortgageData._id.toString()}) )
    }
    if (mortgagesFilteredBoolean) {
      dispatch( setFwdBckViewingFilteredMortgages(mortgageData._id) )
    } else {
      dispatch( setFwdBckViewingMortgages(mortgageData._id) )
    }
    dispatch( setNavSelection('repository') )
    dispatch( setMortgageActionNavMenuSelection('compareRecords') )
    navigate(`/dashboard/mortgageRepository/${mortgageData._id.toString()}`)
    dispatch( setViewingMortgageActions(true) )
    dispatch( setViewingLeadActions(false) )
  }

  const handleFwdBckViewingLead = (direction) => {
    if (direction === 'fwd') {
      dispatch( setViewingLead(forwardViewingLead) )
      dispatch( setViewingMortgage(forwardViewingLead.belongsToMortgage) )
      dispatch( setViewingLeadReports(forwardViewingLead.reports) )
      if (leadsFiltered || dashPerspective !== 'team') {
        dispatch( setFwdBckFilteredViewingLeads(forwardViewingLead._id) )
      } else {
        dispatch( setFwdBckViewingLeads(forwardViewingLead._id) )
      }
      navigate(`/dashboard/investigations/${forwardViewingLead._id}`)
      if (forwardViewingLead.status === 'awaitingAction') {
        dispatch( setLeadStatusFilter('Awaiting Action') )
      } else if (forwardViewingLead.status === 'investigating') {
        dispatch( setLeadStatusFilter('Investigating') )
      } else if (forwardViewingLead.status === 'closing') {
        dispatch( setLeadStatusFilter('Closing') )
      } else {
        dispatch( setLeadStatusFilter('Awaiting PR Updates') )
      }
    } else {
      dispatch( setViewingLead(backwardViewingLead) )
      dispatch( setViewingMortgage(backwardViewingLead.belongsToMortgage) )
      dispatch( setViewingLeadReports(backwardViewingLead.reports) )
      if (leadsFiltered || dashPerspective !== 'team') {
        dispatch( setFwdBckFilteredViewingLeads(backwardViewingLead._id) )
      } else {
        dispatch( setFwdBckViewingLeads(backwardViewingLead._id) )
      }
      navigate(`/dashboard/investigations/${backwardViewingLead._id}`)
      if (backwardViewingLead.status === 'awaitingAction') {
        dispatch( setLeadStatusFilter('Awaiting Action') )
      } else if (backwardViewingLead.status === 'investigating') {
        dispatch( setLeadStatusFilter('Investigating') )
      } else if (backwardViewingLead.status === 'closing') {
        dispatch( setLeadStatusFilter('Closing') )
      } else {
        dispatch( setLeadStatusFilter('Awaiting PR Updates') )
      }
    }
  }

  return (
    <ModalContainer className="threePanel" style={{backgroundColor: `${theme.component.background.quartenary}`}}>
      <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/6'}}>
        <ComponentHeader className="areaTitle" style={{paddingLeft: '1.5rem'}}>
          <FlexWrapper className="fitContentWidth alignCenter">
            <DetailWrapper 
              className={backwardViewingLead ? 'addButton' : 'addButtonDisabled'}
              onClick={backwardViewingLead ? () => handleFwdBckViewingLead('bck') : null}
              style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', padding: '0.1rem 0.5rem 0.1rem 0.5rem'}}
            >
              <ArrowBackIosIcon style={{height: '1rem', width: '1rem', color: `${theme.palette.primary.main}`}} /> 
              prev
            </DetailWrapper>
            <DetailWrapper 
              className={forwardViewingLead ? 'addButton' : 'addButtonDisabled'}
              onClick={forwardViewingLead ? () => handleFwdBckViewingLead('fwd') : null}
              style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', padding: '0.1rem 0.3rem 0.1rem 0.5rem', marginLeft: '0.5rem'}}
            >
              next
              <ArrowForwardIosIcon style={{height: '1rem', width: '1rem', color: `${theme.palette.primary.main}`}} /> 
            </DetailWrapper>
          </FlexWrapper>
          <Typography className="xLarge" style={{marginLeft: '1rem'}}>{mortgageData.recordDetails.address.StreetAddressOneLine.currentValue}</Typography>
          <Typography className="xLarge" style={{margin: '0 0.6rem'}}>-</Typography>
          {leadData && leadData.status === 'awaitingAction' ?
            <Typography className="xLarge" style={{color: `${theme.palette.common.redMediumTransparent}`, marginRight: '0.6rem'}}>
              Potential Violation
            </Typography>
          : leadData && leadData.status === 'investigating' ?
            <Typography className="xLarge" style={{color: `${theme.accent.caution.primary}`, marginRight: '0.6rem'}}>
              <Typography className="xLarge" style={{color: `${theme.accent.caution.primary}`, margin: '0 0.7rem 0 0'}}>
                {leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION' ?
                  'Renegotiation'
                :
                  'Refinance'
                }
              </Typography>
              Investigation
            </Typography>
          : leadData && leadData.status === 'awaitingUpdate' ?
            <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`, marginRight: '0.6rem'}}>
              Awaiting Updates to Public Record
            </Typography>
          :
            <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`, marginRight: '0.6rem'}}>
              Closing Investigation
            </Typography>
          }
          <DetailWrapper 
            className="addButton"
            onClick={() => handleGoToMortgageActions()} 
            style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', marginLeft: '0.5rem'}}
          >
            View in Mortgage Repository
            <OpenInNewIcon style={{height: '1.4rem', width: '1.4rem', color: `${theme.palette.primary.main}`, margin: '0 0 0 0.3rem'}} /> 
          </DetailWrapper>
        </ComponentHeader>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      {leadData &&
        <>
          <LeadActionsNav mortgageData={mortgageData} leadOutcome={leadData.targetOutcome} reportsNumber={leadData.reports.length} leadStatus={leadData.status} handleCloseModal={handleCloseModal} leadAssigneesCount={leadData.assigneeIds.length} />
          {navSelection === 'reports' ?
            <ReportsModal reports={leadReports} />
          : navSelection === 'notes' ?
            <Notes userId={userId} userFullName={userFullName} leadData={leadData} mortgagestatus={mortgageData.status} />
          : navSelection === 'compareRecords' ?
            <CompareRecords mortgageData={mortgageData} userId={userId} userFullName={userFullName} leadData={leadData} teamId={teamId} location={'activeLead'} />
          : navSelection === 'propertyDetails' ?
            <PropertyDetails handleCloseModal={handleCloseModal} mortgageData={mortgageData} />
          : navSelection === 'editTargets' ?
            <EditTargets handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} mortgageData={mortgageData} />
          : navSelection === 'editTags' ?
            <EditTags leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={mortgageData.activeLead} mortgageData={mortgageData} />
          : navSelection === 'addAssignees' ?
            <AddAssignees  teamMembersByName={teamMembersByName} handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} activeLead={mortgageData.activeLead} mortgageData={mortgageData} />
          : navSelection === 'investigateLead' ?
            <InvestigateLead handleCloseModal={handleCloseModal} userId={userId} teamId={teamId} userFullName={userFullName} teamMembersByName={teamMembersByName} mortgageId={mortgageData._id} leadData={leadData} dashPerspective={dashPerspective} requestOrigin={'activeLeads'} />
          : navSelection === 'dismissLead' ?
            <DismissLead handleCloseModal={handleCloseModal} userFullName={userFullName} teamId={teamId} userId={userId} dashPerspective={dashPerspective} leadData={leadData} />
          : navSelection === 'targetOutcome' ?
            <ChangeTargetOutcome handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} />
          : navSelection === 'beginClosingLead' ?
            <BeginClosing handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} teamId={teamId} />
          : navSelection === 'finalizeLead' ?
            <FinalizeLead handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} teamId={teamId} mortgageData={mortgageData} />
          : navSelection === 'confirmUpdate' ?
            <ConfirmUpdate handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} teamId={teamId} mortgageData={mortgageData} />
          : navSelection === 'provideFinancialsModal' || navSelection === 'provideFinancialsCard' || navSelection === 'provideTerms' ?
            <ProvideFinancials mortgageData={mortgageData} userFullName={userFullName} leadData={leadData} userId={userId} navSelection={navSelection} handleCloseModal={handleCloseModal} teamId={teamId} />
          :
            <></>
          }
        </>
      }
      <ComponentHeader className="modal timeline">Timeline</ComponentHeader>
      <TimelineContainer className="actionsModal">
        {leadData &&
          <LeadTimeline origin={'modal'} />
        }
      </TimelineContainer>
      <ComponentBorder className="left" style={{gridRow:'2/6', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/6', gridColumn: '5'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'6', gridColumn: '1/6'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default LeadActionsModal;