import React, { useEffect, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import CircleIcon from '@mui/icons-material/Circle';
import { Dialog } from "@mui/material";
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";
import { GridToolbar } from '@mui/x-data-grid';
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { setViewingLead } from "../../../../slices/viewingLeadSlice";
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { StripedDataGrid } from "../../../../componentThemes";
import MortgageActionsModal from "../../../../components/Modals/MortgageActions/MortgageActionsModal";
import { selectUserFullName } from "../../../../slices/userInfoSlice";
import { selectViewingMortgage, setViewingMortgage } from "../../../../slices/viewingMortgageSlice";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { selectTeamMortgages } from "../../../../slices/teamMortgagesSlice";
import { selectAllTeamReports } from "../../../../slices/teamReportsSlice";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useSelector, useDispatch } from "react-redux";
import { ActionIconWrapper, ComponentBorder, ComponentContainer, FlexWrapper, HeaderText } from "../../../../StyledComponents";
import { selectDashPerspective, selectRepositoryPage, selectRepositoryPageSize, selectViewingMortgageActions, setMortgageActionNavMenuSelection, setRepositoryPage, setRepositoryPageSize, setViewingMortgageActions } from "../../../../slices/sessionDataSlice";
import { selectTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads } from "../../../../slices/teamActiveLeadsSlice";

const MortgageRepository = ({ userId, teamId }) => {
  const theme = useTheme()
  const navigate = useNavigate()  
  const dispatch = useDispatch()

  const pageSize = useSelector(selectRepositoryPageSize)
  const teamReports = useSelector(selectAllTeamReports)
  const userFullName = useSelector(selectUserFullName)
  const closingLeads = useSelector(selectTeamClosingLeads)
  const teamMortgages = useSelector(selectTeamMortgages)
  const currentPageSize = useSelector(selectRepositoryPage)
  const dashPerspective = useSelector(selectDashPerspective)
  const viewingMortgage = useSelector(selectViewingMortgage)
  const investigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const awaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const awaitingUpdateLeads = useSelector(selectTeamAwaitingUpdateLeads)
  const viewingMortgageActions = useSelector(selectViewingMortgageActions)

  const [populatedReports, setPopulatedReports] = useState(false);
  const [openMortgageActionsModal, setOpenMortgageActionsModal] = useState(false);
  const handleOpenActionModal = (mortgage, destination) => {
    let populatedReports = []
    for (let i = 0; i < mortgage.reports.length; i++) {
      let match = teamReports.find(report => report._id === mortgage.reports[i])
      populatedReports.push(match)
    }
    if (mortgage.activeLead) {
      let thisLead = {}
      thisLead = awaitingActionLeads.find(lead => lead._id === mortgage.activeLead.toString())
      if (!thisLead) {
        thisLead = investigatingLeads.find(lead => lead._id === mortgage.activeLead.toString())
        if (!thisLead) {
          thisLead = closingLeads.find(lead => lead._id === mortgage.activeLead.toString())
          if (!thisLead) {
            thisLead = awaitingUpdateLeads.find(lead => lead._id === mortgage.activeLead.toString())
          }
        }
      }
      dispatch( setViewingLead(thisLead) )
    }
    if (destination === 'investigateLead') {
      dispatch( setMortgageActionNavMenuSelection('investigateLead') )
    } else {
      dispatch( setMortgageActionNavMenuSelection('compareRecords') )
    }
    setPopulatedReports(populatedReports)
    dispatch( setViewingMortgage(mortgage) )
    setOpenMortgageActionsModal(true)
    navigate(`/dashboard/mortgageRepository/${mortgage._id}`)
  }
  const handleCloseModal = () => {
    setOpenMortgageActionsModal(false)
    dispatch( setViewingMortgageActions(false) )
    navigate(`/dashboard/mortgageRepository/`)
  }

  const renderActionButtons = (cellValues) => {
    return (
      <FlexWrapper className="alignCenter justifyCenter">
        <ActionIconWrapper onClick={() => handleOpenActionModal(cellValues.row)} className='hover' >
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Open Actions Window</p></div>} 
            placement="top"
            arrow
          >
            <OpenInNewIcon style={{height: '1.6rem', width: '1.6rem'}} />
          </Tooltip>
        </ActionIconWrapper>
      </FlexWrapper>
    )
  }
  const renderStatusButton = (cellValues) => {
    return (
      <FlexWrapper className="alignCenter justifyCenter">
        {cellValues.row.status === 'inactive' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Open Investigation</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper onClick={() => handleOpenActionModal(cellValues.row, 'investigateLead')} className="hover">
              <RadioButtonUncheckedIcon style={{color: `${theme.palette.common.grayText}`}} />
              <ArrowForwardIcon style={{height: '1.2rem', width: '1.2rem'}} />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingAction' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>currently <span style={{fontWeight: '900', fontSize: '1.2rem'}}>Awaiting Action</span></p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <RadioButtonUncheckedIcon />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'investigating' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Investigating</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <RadioButtonCheckedIcon />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingUpdate' && cellValues.row.publicRecordsUpdated ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Public Records were updated.<br/> Please review new arrangements.</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <CheckCircleOutlineIcon />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingUpdate' && cellValues.row.awaitingUpdates ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Awaiting Public Records to reflect<br/> expected arrangements.</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <PendingIcon />
            </ActionIconWrapper>
          </Tooltip>
        : cellValues.row.status === 'awaitingUpdate' ?
          <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Awaiting Public Records to reflect<br/> expected arrangements.</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <TaskAltIcon />
            </ActionIconWrapper>
          </Tooltip>
        :
           <Tooltip
            componentsProps={{tooltip: {sx: {
              color: `${theme.palette.common.white}`,
              backgroundColor: `${theme.palette.common.black}`,
              fontSize: "2rem",
              margin: '3rem 0 0 0'
            }}}}
            PopperProps={{modifiers: [{
              name: "offset",
              options: {
                offset: [0, 0],
              },
            }]}}
            title={<div style={{textAlign: "center"}}><p style={{fontSize: "1rem", fontWeight: "600", padding: "0"}}>Closing</p></div>} 
            placement="top"
            arrow
          >
            <ActionIconWrapper className="noHover">
              <CircleIcon />
            </ActionIconWrapper>
          </Tooltip>
        }
      </FlexWrapper>
    )
  }

  const columns = [
    {
      field: 'id' , 
      headerName: '#', 
      filterable: false,
      renderCell:(index) => (index.api.getRowIndex(index.row._id) + 1),
      width: 50,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 50,
      renderCell: renderActionButtons,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 50,
      renderCell: renderStatusButton,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
    },
    {field: "recordSweeps", headerName: "Scans", align: 'center', width: 50, headerAlign: 'center'},
    {field: "propertyType", headerName: "Property Type", width: 90},
    {field: "streetAddress", headerName: "Street Address", width: 175},
    {field: "city", headerName: "City", width: 110},
    {field: "state", headerName: "State", width: 15},
    {field: "originationDateLabel", headerName: "Origination Date", width: 90},
    {field: "endDateLabel", headerName: "End Date", width: 90},
    {field: "monthsRemaining", headerName: "Months Remaining", align: 'center', width: 100},
    {field: "owner1", headerName: "Primary Owner", width: 140},
    {field: "owner2", headerName: "Co-Owner", width: 140},
  ]

  return (
    <>
      <FlexWrapper style={{padding: '1rem'}}>
        <ComponentContainer className="fitWindow">
          <FlexWrapper className="column" style={{gridRow: '2', gridColumn: '2'}}>
            {teamMortgages.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1/3', gridRow: '1/3'}}>
                <DomainDisabledIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no mortgages have been uploaded</HeaderText>
              </FlexWrapper>
            : teamMortgages !== undefined ?
              <StripedDataGrid
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'primary.main',
                  },
                  '& .MuiTablePagination-select': {
                    color: 'primary.main',
                  },
                  '& .MuiDataGrid-virtualScrollerContent': {
                    overflow: 'scroll'
                  },
                  '& .MuiDataGrid-row': {
                    background: `#fff`
                  },
                  '&.MuiDataGrid-root': {
                    background: `#fff`
                  },
                }}
                style={{width: '100%', height: '100%', minHeight: '20rem', borderRadius: '0', background: `${theme.component.background.primary}`}}
                rows={teamMortgages}
                rowHeight={40}
                columns={columns}
                getRowId={(row) =>  row._id}
                components={{
                  Toolbar: GridToolbar,
                }}
                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => dispatch( setRepositoryPageSize(newPageSize))}
                rowsPerPageOptions={[20, 40, 80]}
                page={currentPageSize}
                onPageChange={(newPage) => dispatch( setRepositoryPage(newPage) )}
                pagination
              />
            :
              <CircularProgress
              sx={{ color: `${theme.palette.primary.main}`}}
              size={48}
              />
            }
            </FlexWrapper>
            <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
            <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
            <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
            <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
          </ComponentContainer>
        </FlexWrapper>

        <Dialog open={openMortgageActionsModal} onClose={handleCloseModal} 
          PaperProps={{ sx: {width: "95%", minWidth: "100rem", maxWidth: "150rem", height: "fit-content", minHeight: "66rem", maxHeight: "95%"}}}
        >
          <MortgageActionsModal handleCloseModal={handleCloseModal} userId={userId} teamId={teamId} userFullName={userFullName} dashPerspective={dashPerspective} reports={populatedReports} />
        </Dialog>
    </>
  )
}

export default MortgageRepository;