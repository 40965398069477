import React, { PureComponent } from 'react';
import { useTheme } from "@mui/styles";
import { Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart} from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <div style={{backgroundColor: `${theme.palette.common.cardBackground}`, padding: "10px", width: "200px", borderRadius: ".5rem", border: `0.1rem solid ${theme.palette.common.black}`}}>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          {`Successful Queries: `}
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.totalHits}`}
          </span>
        </div>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          {`Total Hits: `}
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.totalHits}`}
          </span>
        </div>

        <hr />

        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Tier 1 New: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.tier1New}`}
          </span>
        </div>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Tier 2 New: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.tier2New}`}
          </span>
        </div>

        <hr />

        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Tier 2 Upgraded: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.tier2Upgraded}`}
          </span>
        </div>

        <hr />

        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Tier 1 Updated: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.recordsVerified}`}
          </span>
        </div>
        {/* <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Tier 1 Updated: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.tier1Updated}`}
          </span>
        </div>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Tier 2 Updated: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.tier2Updated}`}
          </span>
        </div> */}
      </div>
    );
  }

  return null;
};


export default class MonthlyTwoYearChart extends PureComponent {
  
  render() 
  {
    return (
      <ResponsiveContainer width="99%" height="99%">
        <ComposedChart
          data={this.props.monthlyStatsTwoYear}
          layout="horizontal"
          margin={{
            top: 0,
            right: 5,
            left: 15,
            bottom: 0,
          }}
        >
          <XAxis dataKey="sessionLabel" />
          <YAxis label={{ value: 'Lead Discoveries', angle: -90, position: '' }} dataKey="totalNewLeads" type="number" domain={[0, dataMax => (dataMax * 2)]} yAxisId="left" orientation="left" stroke="#5a5c68" />
          <YAxis label={{ value: 'Records Scanned Successfully', angle: 90, position: 'top', dy: 80}} dataKey="successfulQueries" type="number" domain={[0, dataMax => (Math.round(dataMax + (dataMax * 0.1)))]} yAxisId="right" orientation="right" stroke="#5a5c68" />
          <Tooltip content={<CustomTooltip/>} cursor={{fill: `#93959921`}} />
          <Legend />
          <Line yAxisId="right" type="monotone" dataKey="successfulQueries" stroke="#414042" />
          <Bar yAxisId="left" dataKey="tier2New" fill="#4CA855" stackId="a" name="New Tier 2" />
          <Bar yAxisId="left" dataKey="tier1New" fill="#9AD99F" stackId="a" name="New Tier 1" />
          <Bar yAxisId="left" dataKey="tier2Upgraded" fill="#DEABEB" stackId="b" name="Upgraded Tier 2" />
          <Bar yAxisId="left" dataKey="tier2Updated" fill="#6E88CC" stackId="c" name="Updated Tier 2" />
          <Bar yAxisId="left" dataKey="tier1Updated" fill="#A0B7F2" stackId="c" name="Updated Tier 1" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}