import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { selectTeamRefinances } from "../../../../../slices/teamStatsSlice";
import { HeaderContainer, ColumnContent, HeaderText, HorizontalContainer, ComponentContainer, VerticalContainer, FlexWrapper, ComponentBorder, Typography } from "../../../../../StyledComponents";

const TeamStats = () => {
  const theme = useTheme();

  const teamRefinances = useSelector(selectTeamRefinances)

  return (
    <ComponentContainer className="justifyStart detailsBackground thinBorders" style={{gridRow: '1', gridColumn: '3', height: '100%'}}>
      <FlexWrapper className="column">
        <HeaderContainer>
          <HeaderText>Team Stats</HeaderText>
        </HeaderContainer>
        <HorizontalContainer style={{margin: 'auto'}}>
          {teamRefinances !== null ?
            <>
              <VerticalContainer className="noGap">
                <ColumnContent>
                  Closed Refinances
                </ColumnContent>
                <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
                  {teamRefinances.closedRefinances}
                </Typography>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent>
                  Gross Profit
                  <Tooltip
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.tooltip.info.color}`,
                      backgroundColor: `${theme.tooltip.info.background}`,
                      border: `0.2rem solid ${theme.tooltip.info.border}`,
                      borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                      opacity: '0',
                      width: 'fit-content',
                      padding: '1rem 1.5rem',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    }]}}
                    title={
                      <FlexWrapper className="column">
                        <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                          Value in parentheses is the average profit generated by all refinances.
                        </Typography>
                      </FlexWrapper>
                    } 
                    placement="top"
                    arrow
                  >
                    <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                      <HelpOutlineIcon />
                    </span>
                  </Tooltip>
                </ColumnContent>
                <FlexWrapper className="justifyCenter">
                  <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem'}}>
                    <span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', marginRight: '0.2rem', fontSize: '1.2rem'}}>$</span>
                    {teamRefinances.grossProfitNumber ?
                      teamRefinances.grossProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})
                    :
                      teamRefinances.grossProfitNumber
                    }
                  </Typography>
                  <Typography className="justifyCenter alignCenter" style={{gridColumn: '5', fontSize: '1rem', margin: '0 0 0 1rem'}}>
                    (<span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', margin: '0 0.2rem 0 0', fontSize: '1rem'}}>$</span>
                    {teamRefinances.averageProfitNumber ?
                      teamRefinances.averageProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})
                    :
                      teamRefinances.averageProfitNumber
                    })
                  </Typography>
                </FlexWrapper>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent>
                  Gross Revenue Increase
                  <Tooltip
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.tooltip.info.color}`,
                      backgroundColor: `${theme.tooltip.info.background}`,
                      border: `0.2rem solid ${theme.tooltip.info.border}`,
                      borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                      opacity: '0',
                      width: 'fit-content',
                      padding: '1rem 1.5rem',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    }]}}
                    title={
                      <FlexWrapper className="column">
                        <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                          Value in parentheses is the average revenue increase generated by all refinances.
                        </Typography>
                      </FlexWrapper>
                    } 
                    placement="top"
                    arrow
                  >
                    <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                      <HelpOutlineIcon />
                    </span>
                  </Tooltip>
                </ColumnContent>
                <FlexWrapper className="justifyCenter">
                  <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem'}}>
                    {teamRefinances.grossProfitPercent ?
                      teamRefinances.grossProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})
                      :
                      teamRefinances.grossProfitPercent
                    }
                    <span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', marginRight: '0.2rem', fontSize: '1.2rem'}}>%</span>
                  </Typography>
                  <Typography className="justifyCenter alignCenter" style={{gridColumn: '5', fontSize: '1rem', margin: '0 0 0 1rem'}}>
                    <span style={{margin: '0', fontWeight: '400', fontSize: '1rem'}}>(</span>
                    {teamRefinances.averageProfitPercent ?
                      teamRefinances.averageProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})
                    :
                      teamRefinances.averageProfitPercent
                    }
                    <span style={{color: `${theme.palette.primary.pastelMedium}`, fontWeight: '600', margin: '0 0 0 0.2rem', fontSize: '1rem'}}>%</span>
                    <span style={{margin: '0', fontWeight: '400', fontSize: '1rem'}}>)</span>
                  </Typography>
                </FlexWrapper>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent>
                  Last Refinance
                </ColumnContent>
                <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
                  {teamRefinances.lastRefinance ?
                    teamRefinances.lastRefinance
                  :
                    `N/A`
                  }
                </Typography>
              </VerticalContainer>
              <VerticalContainer className="noGap">
                <ColumnContent>
                  Refinance Frequency
                  <Tooltip
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.tooltip.info.color}`,
                      backgroundColor: `${theme.tooltip.info.background}`,
                      border: `0.2rem solid ${theme.tooltip.info.border}`,
                      borderBottom: `0.1rem solid ${theme.tooltip.info.border}`,
                      opacity: '0',
                      width: 'fit-content',
                      padding: '1rem 1.5rem',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, -8],
                      },
                    }]}}
                    title={
                      <FlexWrapper className="column">
                        <Typography className="justifyCenter alignCenter" style={{textAlign: 'center'}}>
                          Average day's between the team closing a refinance.
                        </Typography>
                      </FlexWrapper>
                    } 
                    placement="top"
                    arrow
                  >
                    <span style={{color: `${theme.palette.common.grayText}`, margin: "0 0 0 0.3rem", height: "1.5rem", width: "1.5rem"}}>
                      <HelpOutlineIcon />
                    </span>
                  </Tooltip>
                </ColumnContent>
                <Typography className="justifyCenter alignCenter bold" style={{gridColumn: '5', fontSize: '1.2rem', margin: '0'}}>
                  {teamRefinances.refinanceFrequency} <span style={{fontWeight: "500", marginLeft: '0.3rem'}}>days</span>
                </Typography>
              </VerticalContainer>
            </>
          :
            <ColumnContent className="single">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={18}
              />
            </ColumnContent>    
          }
        </HorizontalContainer>
      </FlexWrapper>
      <ComponentBorder className="top" style={{gridRow:'1', gridColumn: '1/4'}} />
      <ComponentBorder className="left" style={{gridRow:'2', gridColumn: '1'}} />
      <ComponentBorder className="right"  style={{gridRow:'2', gridColumn: '3'}} />
      <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}} />
    </ComponentContainer>
  )
}

export default TeamStats;