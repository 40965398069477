import React from "react"
import IconLogo from '../icon.logo.black.png';
import LoadingLogo from '../Name.logo.white.png';
import { useTheme } from "@mui/styles";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { useSelector } from "react-redux";
import GreenLogo from "../icon.logo.green.png";
import { selectIPError } from "../slices/sessionDataSlice";
import { FlexWrapper, ImageElements, PageContainer, ScannerOverlay, ScreenSaverContainer } from "../StyledComponents"
import { Navigate, useNavigate } from "react-router-dom";

const ScreenSaverBanner = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const ipError = useSelector(selectIPError)

  const handleTokenValidation = async () => {
    navigate('/login')
  }

  return (
    <PageContainer className="screenSaver" onClick={() => handleTokenValidation()}>
      <FlexWrapper className="column" style={{margin: 'auto', padding: '0 0 10rem 0', height: 'fit-content', width: 'fit-content', zIndex: '100000'}}>
        <span style={{fontSize: '4rem', fontFamily: 'exo-bold', fontWeight: '600', color: `${theme.palette.common.grayText}`}}>
          Welcome to
        </span>
        <FlexWrapper style={{margin: '1rem 0 4.5rem 0'}}>
          <ImageElements src={GreenLogo} className="centerLogo" style={{height: '7rem', width: '7rem', marginLeft: '47rem'}} />
          <ImageElements src={LoadingLogo} className="centerLogo" style={{height: '5rem', width: '40rem', marginLeft: '55.7rem'}} />
          <span style={{opacity: '0.8', fontSize: '3rem', color: `#DFDFEB`, fontWeight: '300', fontFamily: 'exo-bold', margin: '10rem 0 0 60.5rem'}}>
            Sweep
          </span>
        </FlexWrapper>
      </FlexWrapper>
      <ScreenSaverContainer className="screenSaver fadeIn">
        <ImageElements src={IconLogo} className="centerLogo" style={{height: '40rem', width: '40rem'}} />
      </ScreenSaverContainer>
    </PageContainer>
  )
}

export default ScreenSaverBanner