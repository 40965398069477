import React from "react";
import 'react-csv-importer/dist/index.css';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useState } from "react";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { useSetStatusClosingMutation } from "../../../../../slices/api/apiSlice";
import { setLeadActionNavMenuSelection } from "../../../../../slices/sessionDataSlice";
import { selectSetInvestigationClosingPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { Button, ButtonWrapper, ModalContentContainer, ComponentHeader, Typography, FlexWrapper } from "../../../../../StyledComponents";

const BeginClosing = ({ handleCloseModal, userId, teamId, userFullName, leadData }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [setStatusClosing] = useSetStatusClosingMutation()

  const setInvestigationClosingPerm = useSelector(selectSetInvestigationClosingPerm)

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSetClosing = async () => {
    setIsLoading(true)
    setIsError(false)
    let resp = await setStatusClosing({
      leadId: leadData._id,
      teamId: teamId,
      userId: userId,
      timeline: leadData.timeline,
      userFullName: userFullName,
      mortgageId: leadData.belongsToMortgage._id,
    })
    if (resp.error) {
      setIsError(true)
    } else if (resp.data.message.includes('success')) {
      setIsSuccess(true)
    } else {
      setIsError(true)
    }
    setIsLoading(false)
  }

  const handleGoToFinalize = () => {
    dispatch( setLeadActionNavMenuSelection('finalizeLead'))
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.primary.darkest}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.primary.main}`}}>
            <CheckIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0 0 1rem', fontSize: '2rem'}}>
            Success
          </span>
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red">
          <FlexWrapper style={{border: `0.2rem solid ${theme.palette.common.redDark}`, padding: '0.5rem', width: '2.7rem', background: `${theme.palette.common.redBright}`}}>
            <ErrorIcon style={{height: '2.5rem', width: '2.5rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span style={{padding: '0 0.5rem', fontSize: '1.4rem'}}>
            Failed... please try again. Reach out to support@lancastersweep.com if the problem persists.
          </span>
        </ComponentHeader>
      :
        <ComponentHeader className="modal">Initiate Closing</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <Typography className="alignTextCenter small" style={{margin: '0 auto 4rem auto'}}>
            The {leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION' ? 'renegotiation' : 'refinance'} has been moved to closing.
          </Typography>
        :
        <Typography className="alignTextCenter small" style={{margin: '0 auto 4rem auto'}}>
            Move the {leadData.targetOutcome.toUpperCase() === 'RENEGOTIATION' ? 'renegotiation' : 'refinance'} to closing?
          </Typography>
        }
        {isSuccess ?
          <ButtonWrapper>
            <Button className="neutral" onClick={() => handleCloseModal()}>close</Button>
            <Button className="primary" onClick={() => handleGoToFinalize()} style={{width: '15rem'}}>go to Finalize</Button>
          </ButtonWrapper>
        :
          <ButtonWrapper>
            <Button 
              onClick={setInvestigationClosingPerm ? () => handleSetClosing() : null} 
              className={!setInvestigationClosingPerm ? "primaryDisabled" : isLoading ? "primaryLoading" : "primary"}
            >
              {isLoading ?
                <CircularProgress
                  sx={{ color: `${theme.palette.common.cardBackground}` }}
                  size={13}
                />
              :
                `Confirm`
              }
            </Button> 
          </ButtonWrapper>
        }
      </ModalContentContainer>
    </>
  )
}

export default BeginClosing;