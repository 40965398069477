import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import MuiMenu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import QueryNotification from "./components/QueryNotification";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { setViewingLead } from "../../../slices/viewingLeadSlice";
import UploadNotification from "./components/UploadNotification";
import StandardNotification from "./components/StandardNotification";
import { setViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { useDispatch, useSelector } from "react-redux";
import { removeNotificationFromMortgage, selectTeamMortgages } from "../../../slices/teamMortgagesSlice";
import { Badge, ComponentBorder, Divider, FlexWrapper, GridWrapper, HeaderContainer, Typography } from "../../../StyledComponents";
import { selectDashPerspective, selectLeadsFilteredBoolean, setLeadActionNavMenuSelection, setLeadStatusFilter, setNavSelection, setViewingLeadActions } from "../../../slices/sessionDataSlice";
import { removeNotificationFromLead, selectTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectTeamClosingLeads, selectTeamInvestigatingLeads, selectTeamLeadsAwaitingVerification, setFwdBckFilteredViewingLeads, setFwdBckViewingLeads } from "../../../slices/teamActiveLeadsSlice";
import { decrementMortgageNotificationsCount, removeLeadNotification, removeQueryNotification, removeUploadNotification, selectLastUserLogin, selectLeadNotificationsRead, selectNotificationsCount, selectNotificationsPresent, selectNotificationsRead, selectQueryNotificationPresent, selectUploadNotificationPresent, selectUserLeadAssignedNotifications, selectUserLeadDismissedNotifications, selectUserLeadFinalizedNotifications, selectUserLeadNotifications, selectUserLeadRemovedNotifications, selectUserMortgageAssignedNotifications, selectUserMortgageNotifications, selectUserMortgageRemovedNotifications, selectUserQueryNotification, selectUserUploadNotification, setLeadNotificationsRead, setNotificationsRead } from "../../../slices/userNotificationsSlice";

const Notifications = ({ runningSweep }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const lastUserLogin = useSelector(selectLastUserLogin)
  const teamMortgages = useSelector(selectTeamMortgages)
  const dashPerspective = useSelector(selectDashPerspective)
  const teamLeadsClosing = useSelector(selectTeamClosingLeads)
  const notificationsRead = useSelector(selectNotificationsRead)
  const notificationsCount = useSelector(selectNotificationsCount)
  const notificationsPresent = useSelector(selectNotificationsPresent)
  const leadsFilteredBoolean = useSelector(selectLeadsFilteredBoolean)
  const userLeadNotifications = useSelector(selectUserLeadNotifications)
  const userQueryNotification = useSelector(selectUserQueryNotification)
  const leadNotificationsRead = useSelector(selectLeadNotificationsRead)
  const userUploadNotification = useSelector(selectUserUploadNotification)
  const teamLeadsInvestigating = useSelector(selectTeamInvestigatingLeads)
  const teamLeadsAwaitingUpdate = useSelector(selectTeamAwaitingUpdateLeads)
  const teamLeadsAwaitingAction = useSelector(selectTeamAwaitingActionLeads)
  const queryNotificationPresent = useSelector(selectQueryNotificationPresent)
  const uploadNotificationPresent = useSelector(selectUploadNotificationPresent)
  const userMortgageNotifications = useSelector(selectUserMortgageNotifications)
  const userLeadRemovedNotifications = useSelector(selectUserLeadRemovedNotifications)
  const teamLeadsAwaitingVerification = useSelector(selectTeamLeadsAwaitingVerification)
  const userLeadAssignedNotifications = useSelector(selectUserLeadAssignedNotifications)
  const userLeadDismissedNotifications = useSelector(selectUserLeadDismissedNotifications)
  const userLeadFinalizedNotifications = useSelector(selectUserLeadFinalizedNotifications)
  const userMortgageRemovedNotifications = useSelector(selectUserMortgageRemovedNotifications)
  const userMortgageAssignedNotifications = useSelector(selectUserMortgageAssignedNotifications)
  
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    if (notificationsPresent) {
      setAnchorEl(event.currentTarget)
      dispatch( setNotificationsRead() )
    }
  }
  const handleClose = () => {
    if (queryNotificationPresent) {
      dispatch( removeQueryNotification() )
    }
    if (uploadNotificationPresent) {
      dispatch( removeUploadNotification() )
    }
    dispatch( setLeadNotificationsRead() )
    setAnchorEl(null)
  }

  const handleGoToLeadActions = (leadStatus, mortgageId, leadId) => {
    let leadMatch = null
    if (leadStatus === 'awaitingAction') {
      dispatch( setLeadStatusFilter('Awaiting Action') )
      leadMatch = teamLeadsAwaitingAction.find(lead => lead._id.toString() === leadId.toString())
      if (leadMatch) {
        if (leadsFilteredBoolean || dashPerspective !== 'team') {
          dispatch( setFwdBckFilteredViewingLeads(leadMatch._id) )
        } else {
          dispatch( setFwdBckViewingLeads(leadMatch._id) )
        }
        dispatch( setViewingLead(leadMatch) )
      }
    } else if (leadStatus === 'investigating') {
      dispatch( setLeadStatusFilter('Investigating') )
      leadMatch = teamLeadsInvestigating.find(lead => lead._id.toString() === leadId.toString())
      if (leadMatch) {
        if (leadsFilteredBoolean || dashPerspective !== 'team') {
          dispatch( setFwdBckFilteredViewingLeads(leadMatch._id) )
        } else {
          dispatch( setFwdBckViewingLeads(leadMatch._id) )
        }
        dispatch( setViewingLead(leadMatch) )
      }
    } else if (leadStatus === 'closing') {
      dispatch( setLeadStatusFilter('Closing') )
      leadMatch = teamLeadsClosing.find(lead => lead._id.toString() === leadId.toString())
      if (leadMatch) {
        if (leadsFilteredBoolean || dashPerspective !== 'team') {
          dispatch( setFwdBckFilteredViewingLeads(leadMatch._id) )
        } else {
          dispatch( setFwdBckViewingLeads(leadMatch._id) )
        }
        dispatch( setViewingLead(leadMatch) )
      }
    } else {
      dispatch( setLeadStatusFilter('Awaiting PR Updates') )
      leadMatch = teamLeadsAwaitingUpdate.find(lead => lead._id.toString() === leadId.toString())
      if (leadMatch) {
        if (leadsFilteredBoolean || dashPerspective !== 'team') {
          dispatch( setFwdBckFilteredViewingLeads(leadMatch._id) )
        } else {
          dispatch( setFwdBckViewingLeads(leadMatch._id) )
        }
        dispatch( setViewingLead(leadMatch) )
      } else {
        leadMatch = teamLeadsAwaitingVerification.find(lead => lead._id.toString() === leadId.toString())
        if (leadMatch) {
          if (leadsFilteredBoolean || dashPerspective !== 'team') {
            dispatch( setFwdBckFilteredViewingLeads(leadMatch._id) )
          } else {
            dispatch( setFwdBckViewingLeads(leadMatch._id) )
          }
          dispatch( setViewingLead(leadMatch) )
        }
      }
    }
    let mortgageMatch = teamMortgages.find(mortgage => mortgage._id.toString() === mortgageId.toString())
    if (mortgageMatch) {
      dispatch( setViewingMortgage(mortgageMatch) )
      if (mortgageMatch.notifCount && mortgageMatch.notifCount !== 0) {
        dispatch( decrementMortgageNotificationsCount(1) )
        dispatch( removeNotificationFromMortgage({mortgageId: mortgageId}) )
      }
    }
    if (leadMatch) {
      if (leadMatch.notifCount && leadMatch.notifCount !== 0) {
        if (leadMatch.status === 'awaitingAction') {
          dispatch( removeLeadNotification({status: 'awaitingAction', count: 1}) )
          dispatch( removeNotificationFromLead({status: 'awaitingAction', leadId: leadId}) )
        } else if (leadMatch.status === 'investigating') {
          dispatch( removeLeadNotification({status: 'investigating', count: 1}) )
          dispatch( removeNotificationFromLead({status: 'investigating', leadId: leadId}) )
        } else if (leadMatch.status === 'closing') {
          dispatch( removeLeadNotification({status: 'closing', count: 1}) )
          dispatch( removeNotificationFromLead({status: 'closing', leadId: leadId}) )
        } else {
          dispatch( removeLeadNotification({status: 'awaitingUpdates', count: 1}) )
          dispatch( removeNotificationFromLead({status: 'awaitingUpdates', leadId: leadId}) )
        }
      }
      dispatch( setNavSelection('investigations') )
      dispatch( setViewingLeadActions(true) )
      dispatch( setLeadActionNavMenuSelection('compareRecords') )
      navigate(`/dashboard/investigations/${mortgageId}`)
    }
  }

  return (
    <FlexWrapper className="justifyCenter">
      {!runningSweep ?
        <Tooltip 
          title={
            !notificationsPresent ? <p style={{fontSize: "1.2rem", margin: "0px"}}>- no notifications -</p> 
            : <p style={{fontSize: "1.2rem", margin: "0px"}}>Notifications</p> 
          }
        >
          <IconButton
            onClick={handleClick}
            size="small"
          >
            <Avatar sx={{
              width: 30,
              height: 30,
              fontSize: "1.6rem",
              fontWeight: "600",
              background: anchorEl ? 
                `radial-gradient(${theme.menuIcon.background.light}, ${theme.menuIcon.background.light}, ${theme.menuIcon.background.active})`
              : 
                `radial-gradient(${theme.menuIcon.background.light}, ${theme.menuIcon.background.mid}, ${theme.menuIcon.background.dark})`,
              color: notificationsRead ? `${theme.menuIcon.color.inactive}` : `${theme.menuIcon.color.active}`,
              border: anchorEl ? `0.2px solid ${theme.menuIcon.border.active}` : `0.2px solid ${theme.menuIcon.border.inactive}`,
              '&:hover': {
                background: `radial-gradient(${theme.menuIcon.background.light}, ${theme.menuIcon.background.light}, ${theme.menuIcon.background.active})`,
              }
            }}>
              <NotificationsIcon style={{width: '2rem', height: '2rem', position: notificationsCount === 0 ? '' : 'relative', right: '-0.5rem'}} />
              {notificationsCount !== 0 &&
                <Badge className={notificationsRead ? "navMenu read" : "navMenu unread"} style={{height: '1.5rem', width: '1.5rem'}}>
                  {notificationsCount}
                </Badge>
              }
            </Avatar>
          </IconButton>
        </Tooltip>
      :
        <Tooltip title={<p style={{fontSize: "1.2rem", margin: "0px"}}>Page is loading...</p>}>
          <IconButton
            size="small"
          >
          <Avatar sx={{ width: 30, height: 30, fontSize: "1.6rem", backgroundColor: `${theme.panel.border.secondary}`, fontWeight: "600", color: `${theme.text.light.primary}` }}>
            <NotificationsIcon style={{width: '2rem', height: '2rem'}} />
          </Avatar>
          </IconButton>
        </Tooltip>
      }
      {!runningSweep ?
        <MuiMenu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0.5,
              border: `2px solid ${theme.palette.common.grayText}`,
              "& .MuiList-root": {
                width: '43rem',
                height: `fit-content`,
                padding: 0,
              },
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 0,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <GridWrapper style={{width: '100%', height: 'fit-content', gridTemplateColumns: '1rem 1fr 1rem', gridTemplateRows: '1rem 1fr 1rem'}}>
            <FlexWrapper className="column">
              <HeaderContainer className="notifications" style={{background: `${theme.panel.background.tertiary}`}}>
                <Typography className="guideHeader">Notifications</Typography>
                <FlexWrapper className="fitContentHeight justifyEnd" style={{marginTop: '0.4rem', color: `${theme.palette.common.grayText}`}}>
                  <Typography className="fitContentHeight thin" style={{marginRight: '0.3rem'}}>last login:</Typography>
                  <Typography className="fitContentHeight">{lastUserLogin}</Typography>
                </FlexWrapper>
              </HeaderContainer>
              {userQueryNotification &&
                <>
                  <QueryNotification notification={userQueryNotification} queryNotificationPresent={queryNotificationPresent} key={'query'} />
                  <Divider style={{backgroundColor: `${theme.palette.common.grayBorderDark}`}} />
                </>
              }
              {userUploadNotification &&
                <>
                  <UploadNotification notification={userUploadNotification} uploadNotificationPresent={uploadNotificationPresent} key={'query'} />
                  <Divider style={{backgroundColor: `${theme.palette.common.grayBorderDark}`}} />
                </>
              }
              {userLeadAssignedNotifications.length > 0 &&
                <>
                  <FlexWrapper className="fitContentHeight column" style={{paddingBottom: '0.8rem'}}>
                    <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
                      New Investigation Assignments
                    </Typography>
                    {userLeadAssignedNotifications.map((notification, index) => (
                      <FlexWrapper className="column fitContentHeight fitContentWidth" key={notification.mortgageId}>
                        <StandardNotification notification={notification} leadNotificationsRead={leadNotificationsRead} key={'query'} handleGoToLeadActions={handleGoToLeadActions} />
                        {index !== userLeadAssignedNotifications.length - 1 &&
                          <Divider style={{width: '70%', margin: '0.3rem auto 0 auto'}} />
                        }
                      </FlexWrapper>
                    ))}
                  </FlexWrapper>
                </>
              }
              {userLeadRemovedNotifications.length > 0 &&
                <>
                  <FlexWrapper className="fitContentHeight column" style={{paddingBottom: '0.8rem'}}>
                    <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
                      Removed from Investigations
                    </Typography>
                    {userLeadRemovedNotifications.map((notification, index) => (
                      <FlexWrapper className="column fitContentHeight fitContentWidth" key={notification.mortgageId}>
                        <StandardNotification notification={notification} leadNotificationsRead={leadNotificationsRead} key={'query'} />
                        {index !== userLeadRemovedNotifications.length - 1 &&
                          <Divider style={{width: '70%', margin: '0.3rem auto 0 auto'}} />
                        }
                      </FlexWrapper>
                    ))}
                  </FlexWrapper>
                </>
              }
              {userLeadNotifications.length > 0 &&
                <>
                  <FlexWrapper className="fitContentHeight column" style={{paddingBottom: '0.8rem'}}>
                    <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
                      Investigation Progress
                    </Typography>
                    {userLeadNotifications.map((notification, index) => (
                      <FlexWrapper className="column fitContentHeight fitContentWidth" key={notification.mortgageId}>
                        <StandardNotification notification={notification} leadNotificationsRead={leadNotificationsRead} key={'query'} />
                        {index !== userLeadNotifications.length - 1 &&
                          <Divider style={{width: '70%', margin: '0.3rem auto 0 auto'}} />
                        }
                      </FlexWrapper>
                    ))}
                  </FlexWrapper>
                </>
              }
              {userLeadDismissedNotifications.length > 0 &&
                <>
                  <FlexWrapper className="fitContentHeight column" style={{paddingBottom: '0.8rem'}}>
                    <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
                      Dismissed Investigations
                    </Typography>
                    {userLeadDismissedNotifications.map((notification, index) => (
                      <FlexWrapper className="column fitContentHeight fitContentWidth" key={notification.mortgageId}>
                        <StandardNotification notification={notification} leadNotificationsRead={leadNotificationsRead} key={'query'} />
                        {index !== userLeadDismissedNotifications.length - 1 &&
                          <Divider style={{width: '70%', margin: '0.3rem auto 0 auto'}} />
                        }
                      </FlexWrapper>
                    ))}
                  </FlexWrapper>
                </>
              }
              {userLeadFinalizedNotifications.length > 0 &&
                <>
                  <FlexWrapper className="fitContentHeight column" style={{paddingBottom: '0.8rem'}}>
                    <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
                      Completed Investigations
                    </Typography>
                    {userLeadFinalizedNotifications.map((notification, index) => (
                      <FlexWrapper className="column fitContentHeight fitContentWidth" key={notification.mortgageId}>
                        <StandardNotification notification={notification} leadNotificationsRead={leadNotificationsRead} key={'query'} />
                        {index !== userLeadFinalizedNotifications.length - 1 &&
                          <Divider style={{width: '70%', margin: '0.3rem auto 0 auto'}} />
                        }
                      </FlexWrapper>
                    ))}
                  </FlexWrapper>
                </>
              }
              {userMortgageNotifications.length > 0 &&
                <>
                  <FlexWrapper className="fitContentHeight column" style={{paddingBottom: '0.8rem'}}>
                    <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
                      Mortgages
                    </Typography>
                    {userMortgageNotifications.map((notification, index) => (
                      <FlexWrapper className="column fitContentHeight fitContentWidth" key={notification.mortgageId}>
                        <StandardNotification notification={notification} leadNotificationsRead={leadNotificationsRead} key={'query'} />
                        {index !== userMortgageNotifications.length - 1 &&
                          <Divider style={{width: '70%', margin: '0.3rem auto 0 auto'}} />
                        }
                      </FlexWrapper>
                    ))}
                  </FlexWrapper>
                </>
              }
              {userMortgageAssignedNotifications.length > 0 &&
                <>
                  <FlexWrapper className="fitContentHeight column" style={{paddingBottom: '0.8rem'}}>
                    <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
                      New Mortgage Assignments
                    </Typography>
                    {userMortgageAssignedNotifications.map((notification, index) => (
                      <FlexWrapper className="column fitContentHeight fitContentWidth" key={notification.mortgageId}>
                        <StandardNotification notification={notification} leadNotificationsRead={leadNotificationsRead} key={'query'} />
                        {index !== userMortgageAssignedNotifications.length - 1 &&
                          <Divider style={{width: '70%', margin: '0.3rem auto 0 auto'}} />
                        }
                      </FlexWrapper>
                    ))}
                  </FlexWrapper>
                </>
              }
              {userMortgageRemovedNotifications.length > 0 &&
                <>
                  <FlexWrapper className="fitContentHeight column" style={{paddingBottom: '0.8rem'}}>
                    <Typography className="sans subHeader large" style={{margin: '0.5rem 0 0 1rem'}}>
                      Removed Mortgage Assignment
                    </Typography>
                    {userMortgageRemovedNotifications.map((notification, index) => (
                      <FlexWrapper className="column fitContentHeight fitContentWidth" key={notification.mortgageId}>
                        <StandardNotification notification={notification} leadNotificationsRead={leadNotificationsRead} key={'query'} />
                        {index !== userMortgageRemovedNotifications.length - 1 &&
                          <Divider style={{width: '70%', margin: '0.3rem auto 0 auto'}} />
                        }
                      </FlexWrapper>
                    ))}
                  </FlexWrapper>
                </>
              }
            </FlexWrapper>
            <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/4'}}></ComponentBorder>
            <ComponentBorder className="left" style={{gridRow:'2/3', gridColumn: '1'}}></ComponentBorder>
            <ComponentBorder className="right"  style={{gridRow:'2/3', gridColumn: '3'}}></ComponentBorder>
            <ComponentBorder className="bottom" style={{gridRow:'3', gridColumn: '1/4'}}></ComponentBorder>
          </GridWrapper>
        </MuiMenu>
      :
        <></>
      }
    </FlexWrapper>
  )
}

export default Notifications