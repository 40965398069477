import React from "react";
import InfoIcon from '@mui/icons-material/Info';
import Timeline from "../../../../../components/Timeline/Timeline";
import CompanyLogo from "../../../../../Full.logo.white.png";
import { useTheme } from "@mui/styles";
import HdrStrongIcon from '@mui/icons-material/HdrStrong';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AccentInfo, CardContainer, DetailWrapper, Divider, FlexWrapper, LoadingBar, LoadingBarWrapper, ScrollWindow } from "../../../../../StyledComponents";

export const s = {
  main: {
    padding: '0.5rem',
  },
  reportHead: {
    backgroundColor: "#F4F4F4",
    padding: '5px 15px',
  },

  headerContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    position: 'relative',
  },

  companyLogo: {
    height: 100,
    top: 20,
    position: 'absolute',
    zIndex: 1,
    // left: '-15rem',
    opacity: '0.6',
  },

  reportId: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Courier",
    marginLeft: "auto",
  },

  closeDate: {
    textAlign: "right",
    fontSize: 12,
    fontFamily: "Courier",
    marginTop: 2,
  },

  reportTypeContainer: {
    margin: 0,
    zIndex: 10,
  },

  reportType: {
    fontFamily: "Courier",
    fontWeight: 700,
    fontSize: 18,
    color: "#8eaeb3",
    textTransform: "uppercase",
    margin: 0,
  },

  addressContainer: {
    marginTop: 20,
    maxWidth: 400,
    zIndex: 10,
  },

  addressContent: {
    fontSize: 12,
    fontFamily: "Courier",
    margin: 0,
    position: 'relative',
    zIndex: 10,
  },

  infoContent: {
    fontSize: 10,
    fontFamily: "Courier",
    margin: "0.2rem 1rem 0 0",
    position: 'relative',
    zIndex: 10,
  },

  borrowerContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    fontFamily: "Courier",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  borrowerCardCaption: {
    fontSize: 14,
    fontFamily: "Courier",
    marginBottom: 5,
    zIndex: 10,
    position: 'relative',
  },

  borrowerName: {
    fontWeight: 700,
    fontFamily: "Courier",
    fontSize: 20,
    color: "#637984",
    margin: 0,
    zIndex: 10,
    position: 'relative',
  },

  reportBody: {
    backgroundColor: "#FFFFFF",
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    fontFamily: "Courier",
  },

  reportSection: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  reportSectionUpdates: {
    paddingTop: 0,
    paddingBottom: 30,
  },

  reportSectionTitle: {
    fontSize: 16,
    fontFamily: "Courier",
    fontWeight: 700,
    color: "#14424C",
    marginBottom: 10,
  },

  reportSectionContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 5,
    gap: 25,
    width: 'fit-content',
  },
  reportSectionContentContainerPadded: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    margin: '3rem 0 1rem 0',
    gap: 25,
    width: 'fit-content',
  },
  reportSectionContentContainerClosing: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 5,
    gap: 25,
    width: '25rem'
  },

  reportSectionColumn: {
    flexGrow: 1,
  },

  reportRowTitle: {
    fontSize: 12,
    fontFamily: "Courier",
    fontStyle: "oblique",
    margin: '0',
    width: '8rem',
  },
  reportRowTitleLarge: {
    fontSize: 12,
    fontFamily: "Courier",
    fontStyle: "oblique",
    margin: '0',
    width: '14rem',
  },
  reportRowTitleXLarge: {
    fontSize: 12,
    fontFamily: "Courier",
    fontStyle: "oblique",
    margin: '0',
    width: '17rem',
  },
  reportRowTitleXXLarge: {
    fontSize: 12,
    fontFamily: "Courier",
    fontStyle: "oblique",
    margin: '0',
    width: '26rem',
  },

  reportRowValue: {
    fontSize: 12,
    fontFamily: "Courier",
    fontWeight: 700,
    margin: '0',
  },
  reportRowValueLarge: {
    fontSize: 12,
    fontFamily: "Courier",
    fontWeight: 700,
    margin: '0',
    width: '7rem'
  },

  reportRowSub: {
    fontSize: 10,
    fontFamily: "Courier",
    fontWeight: 400,
    margin: '0 0 0 0',
  },

  timeLineContainer: {
    borderLeft: "1px solid #e4e4e4",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 20,
    paddingBottom: 25,
    fontFamily: "Courier",
    position: "relative",
  },

  timeLineTitle: {
    fontSize: 14,
    color: "#3A724F",
    fontFamily: "Courier",
    fontWeight: 700,
    marginBottom: 5,
  },

  timeLineDetail: {
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },

  timeLineDetailValue: {
    fontFamily: "Courier",
    fontWeight: 700,
  },

  updateContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  updateWrapper: {
    width: "50%",
  },
  updateBox: {
    width: "fit-content",
    margin: "0 auto",
  },
}

export default function ReportView({ report, origin, handleGoToMortgage }) {
  const theme = useTheme()

  return (
    <CardContainer className={origin === 'modal' ? "report modal" : "report"}>
      <ScrollWindow className="verticalOnly">
      <div style={s.main}>
        <div style={s.reportHead}>
          <div style={s.headerContainer}>
            <div style={s.reportTypeContainer}>
              <p style={s.reportType}>{report.outcome}</p>
            </div>
            
            <img
              alt="lancaster report"
              src={CompanyLogo}
              style={s.companyLogo}
            />

            <div>
              <p style={s.closeDate}>
                Closed: {report.dateGeneratedFileLabel}
              </p>
            </div>
          </div>

          <div style={s.headerContainer}>
            <div style={s.addressContainer}>
              <p style={s.addressContent}>
                Address: {report.streetAddress}
              </p>
              <p style={s.addressContent}>{report.City}, {report.StateOrProvince} {report.PostalCode}</p>
            </div>
          </div>

          <div style={s.borrowerContainer}>
            <div>
              <p style={s.borrowerCardCaption}>
                {report.Owner2FullName && report.Owner1FullName && (report.PreviousOwner1FullName || report.PreviousOwner2FullName) ? 
                  'New Primary Owner' 
                : (report.Owner2FullName || report.Owner1FullName) && (report.PreviousOwner1FullName || report.PreviousOwner2FullName) ? 
                  'New Owner' 
                : report.Owner2FullName && report.Owner1FullName && !report.PreviousOwner1FullName && !report.PreviousOwner2FullName ?
                  'Primary Owner'
                : (report.Owner2FullName || report.Owner1FullName) && !report.PreviousOwner1FullName && !report.PreviousOwner2FullName ? 
                  'Owner' 
                : report.PreviousOwner1FullName && report.PreviousOwner2FullName ? 
                  'Previous Primary Owner'
                : report.PreviousOwner1FullName || report.PreviousOwner2FullName ? 
                  'Previous Owner'
                : 
                  'Owner'
                }
              </p>
              {report.Owner1FullName ?
                <p style={s.borrowerName}>{report.Owner1FullName}</p>
              : report.Owner2FullName ?
                <p style={s.borrowerName}>{report.Owner2FullName}</p>
              : report.PreviousOwner1FullName ?
                <p style={s.borrowerName}>{report.PreviousOwner1FullName}</p>
              : report.PreviousOwner2FullName ?
                <p style={s.borrowerName}>{report.PreviousOwner2FullName}</p>
              :
                <p style={s.borrowerName}><AccentInfo>-empty-</AccentInfo></p>
              }
            </div>
            {report.Owner1FullName && report.Owner2FullName ?
              <div>
                <p style={s.borrowerCardCaption}>Co-Owner</p>
                <p style={s.borrowerName}>{report.Owner2FullName}</p>
              </div>
            : report.PreviousOwner1FullName && !report.PreviousOwner2FullName && ((report.Owner1FullName && !report.Owner2FullName) || (!report.Owner1FullName && report.Owner2FullName)) ?
              <div>
                <p style={s.borrowerCardCaption}>Previous Owner</p>
                <p style={s.borrowerName}>{report.PreviousOwner1FullName}</p>
              </div>
            : report.PreviousOwner2FullName && !report.PreviousOwner1FullName && ((report.Owner1FullName && !report.Owner2FullName) || (!report.Owner1FullName && report.Owner2FullName))  ?
              <div>
                <p style={s.borrowerCardCaption}>Previous Owner</p>
                <p style={s.borrowerName}>{report.PreviousOwner2FullName}</p>
              </div>
            :
              <></>
            }
          </div>
          {(report.Owner1FullName && report.Owner2FullName && (report.PreviousOwner1FullName || report.PreviousOwner2FullName)) || ((report.Owner1FullName || report.Owner2FullName) && ((report.PreviousOwner1FullName && report.PreviousOwner2FullName))) ?
            <div style={s.borrowerContainer}>
              <div>
                <p style={s.borrowerCardCaption}>{report.PreviousOwner2FullName ? 'Previous Primary Owner' : 'Previous Owner'}</p>
                <p style={s.borrowerName}>{report.PreviousOwner1FullName}</p>
              </div>
              {report.PreviousOwner2FullName &&
                <div>
                  <p style={s.borrowerCardCaption}>Previous Co-Owner</p>
                  <p style={s.borrowerName}>{report.PreviousOwner2FullName}</p>
                </div>
              }
            </div>
          :
            <></>
          }
        </div>


        <div style={s.reportBody}>
          <FlexWrapper className="column">
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <InfoIcon style={{height: '1.3rem', margin: '0.1rem 0.2rem 0 0', color: '#939599'}} />
              <p style={s.infoContent}>
                This report was generated at the time of closing
              </p>
              <DetailWrapper 
                onClick={() => handleGoToMortgage(report.belongsToMortgage, report.Owner1FullName)}
                className="addButton" style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', margin: '0', zIndex: '100'}}
              >
                View Current Mortgage Details
                <OpenInNewIcon style={{height: '1.4rem', width: '1.4rem', color: `#00B812`, margin: '0 0 0 0.3rem'}} /> 
              </DetailWrapper>
            </div>
            {report.awaitingUpdate &&
              <FlexWrapper className="alignCenter" style={{height: 'fit-content', paddingRight: '2rem', margin: '1rem 0 0 0'}}>
                <FlexWrapper 
                  style={{border: `0.2rem solid ${theme.palette.common.grayDark}`, padding: '0.2rem 0.2rem 0.2rem 0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.grayText}`}}
                >
                  <InfoIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.common.white}`}} />
                </FlexWrapper>
                <span 
                  style={{
                    border: `0.1rem solid ${theme.palette.common.grayDark}`,
                    padding: origin === 'report' ? '1rem' : '0.3rem 1rem',
                    borderRadius: '0 1rem 1rem 0',
                    background: `${theme.palette.common.grayBorder}`,
                    borderLeft: 'none', color: `${theme.palette.common.grayDark}`,
                    fontSize: origin === 'report' ? '1.2rem' : '1rem',
                    width: '60rem',
                  }}
                >
                  Currently awaiting the expected changes to be reflected in public records and verified.
                </span>
              </FlexWrapper>
            }
          </FlexWrapper>

          <FlexWrapper style={{width: '90%', height: '1rem', margin: '1rem auto 0rem auto', alignItems: 'center'}}>
            <Divider />
          </FlexWrapper>

          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>ORIGINAL MORTGAGE</p>
            <div style={s.reportSectionContentContainer}>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Start Date</p>
                  <p style={s.reportRowValue}>{report.originalOriginationDate}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>End Date</p>
                  <p style={s.reportRowValue}>{report.originalEndDate}</p>
                </div>
              </div>

              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Loan Amount</p>
                  {report.originalLoanAmount ?
                    <p style={s.reportRowValue}>${report.originalLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                  :
                    <AccentInfo>-unavailable-</AccentInfo>
                  }
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Interest Owed</p>
                  <p style={s.reportRowValue}>
                    {report.originalInterestDue ?
                      `$${report.originalInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`
                    :
                      <AccentInfo>-unavailable-</AccentInfo>
                    }
                  </p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Interest Rate</p>
                  <p style={s.reportRowValue}>
                    {report.originalInterestRate ?
                      `${report.originalInterestRate}%`
                    :
                      <AccentInfo>-empty-</AccentInfo>
                    }
                  </p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitleLarge}>Monthly Payments</p>
                  <p style={s.reportRowValue}>
                    {report.originalMonthlyPayments ?
                      `$${report.originalMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}`
                    :
                      <AccentInfo>-unavailable-</AccentInfo>
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>

          <FlexWrapper style={{width: '90%', height: '1rem', margin: '1rem auto 0.2rem auto', alignItems: 'center'}}>
            <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
            <Divider />
            <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.3rem', transform: 'rotateY(-180deg)'}} />
          </FlexWrapper>

          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>DUE AT CLOSING</p>
            {report.principalPaidAtClosing ?   
              <div style={s.reportSectionContentContainerClosing}>
                <div style={s.reportSectionColumn}>
                  <div style={s.reportSectionContentContainer}>
                    <FlexWrapper className="alignCenter justifyBetween fitContentWidth" style={{height: '3rem', margin: '0 0 0.5rem 0', gap: '2rem'}}>
                      <p style={s.reportRowTitleLarge}>Principal Paid</p>
                      <p style={s.reportRowValueLarge}>${report.principalPaidAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                      <FlexWrapper className="column fitContentWidth justifyCenter">
                        <p style={s.reportRowSub}>({((report.principalPaidAtClosing/report.originalLoanAmount)*100).toFixed(1)}% of total)</p>
                        <LoadingBarWrapper className="small" style={{height: '0.5rem', width: '10rem'}}>
                          <LoadingBar className="blue" style={{height: '0.5rem', width: `${Math.round((report.principalPaidAtClosing/report.originalLoanAmount)*100)}%`}} />
                        </LoadingBarWrapper>
                      </FlexWrapper>
                    </FlexWrapper>
                  </div>
                  <div style={s.reportSectionContentContainer}>
                    <FlexWrapper className="alignCenter justifyBetween fitContentWidth" style={{height: '3rem', margin: '0 0 0.5rem 0', gap: '2rem'}}>
                      <p style={s.reportRowTitleLarge}>Interest Paid</p>
                      <p style={s.reportRowValueLarge}>${report.interestPaidAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                      <FlexWrapper className="column fitContentWidth justifyCenter">
                        <p style={s.reportRowSub}>({((report.interestPaidAtClosing/report.originalInterestDue)*100).toFixed(1)}% of total)</p>
                        <LoadingBarWrapper className="small" style={{height: '0.5rem', width: '10rem'}}>
                          <LoadingBar className="blue" style={{height: '0.5rem', width: `${Math.round((report.interestPaidAtClosing/report.originalInterestDue)*100)}%`}} />
                        </LoadingBarWrapper>
                      </FlexWrapper>
                    </FlexWrapper>
                  </div>
                  <Divider style={{margin: '0.6rem 0', width: '35rem'}} />
                  <div style={s.reportSectionContentContainer}>
                    <p style={s.reportRowTitleLarge}>Term Remaining</p>
                    <p style={s.reportRowValue}>{report.remainingTermAtClosing}</p>
                  </div>
                  <div style={s.reportSectionContentContainer}>
                    <FlexWrapper className="alignCenter justifyBetween fitContentWidth" style={{height: '3rem', margin: '0 0 0.5rem 0', gap: '2rem'}}>
                      <p style={s.reportRowTitleLarge}>Principal Remaining</p>
                      <p style={s.reportRowValueLarge}>${report.principalRemainingAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                      <FlexWrapper className="column fitContentWidth justifyCenter">
                        <p style={s.reportRowSub}>({((report.principalRemainingAtClosing/report.originalLoanAmount)*100).toFixed(1)}% of total)</p>
                        <LoadingBarWrapper  className="small" style={{height: '0.5rem', width: '10rem'}}>
                          <LoadingBar className="light" style={{height: '0.5rem', width: `${Math.round((report.principalRemainingAtClosing/report.originalLoanAmount)*100)}%`}} />
                        </LoadingBarWrapper>
                      </FlexWrapper>
                    </FlexWrapper>
                  </div>
                  <div style={s.reportSectionContentContainer}>
                    <FlexWrapper className="alignCenter justifyBetween fitContentWidth" style={{height: '3rem', margin: '0 0 0.5rem 0', gap: '2rem'}}>
                      <p style={s.reportRowTitleLarge}>Interest Remaining</p>
                      <p style={s.reportRowValueLarge}>${report.interestRemainingAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                      <FlexWrapper className="column fitContentWidth justifyCenter">
                        <p style={s.reportRowSub}>({((report.interestRemainingAtClosing/report.originalInterestDue)*100).toFixed(1)}% of total)</p>
                        <LoadingBarWrapper className="small" style={{height: '0.5rem', width: '10rem'}}>
                          <LoadingBar className="light" style={{height: '0.5rem', width: `${Math.round((report.interestRemainingAtClosing/report.originalInterestDue)*100)}%`}} />
                        </LoadingBarWrapper>
                      </FlexWrapper>
                    </FlexWrapper>
                  </div>
                </div>

                <div style={s.reportSectionColumn}>
                </div>
              </div>
            :
              <p style={s.reportRowValue}>
                <AccentInfo>Previous financial information not provided</AccentInfo>
              </p>
            }
          </div>

          <FlexWrapper style={{width: '90%', height: '1rem', margin: '1rem auto 0.2rem auto', alignItems: 'center'}}>
            <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
            <Divider />
            <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.3rem', transform: 'rotateY(-180deg)'}} />
          </FlexWrapper>

          {(report.outcome === 'refinance' && report.type !== 'dismissed') &&
            <>
              <div style={s.reportSection}>
                <p style={s.reportSectionTitle}>NEW MORTGAGE</p>
                <div style={s.reportSectionContentContainer}>
                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Start Date</p>
                      <p style={s.reportRowValue}>{report.newStartDate}</p>
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>End Date</p>
                      {report.newEndDate ?
                        <p style={s.reportRowValue}>{report.newEndDate}</p>
                      :
                        <AccentInfo>-not available-</AccentInfo>
                      }
                    </div>
                  </div>

                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Loan Amount</p>
                      {report.newLoanAmount ?
                        <p style={s.reportRowValue}>${report.newLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                      :
                        <AccentInfo>-not provided-</AccentInfo>
                      }
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Interest Rate</p>
                      <p style={s.reportRowValue}>{report.newInterestRate}%</p>
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Interest Due</p>
                      {report.newInterestDue ?
                        <p style={s.reportRowValue}>${report.newInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                      :
                        <AccentInfo>-not available-</AccentInfo>
                      }
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleLarge}>Monthly Payments</p>
                      {report.newMonthlyPayments ?
                        <p style={s.reportRowValue}>${report.newMonthlyPayments.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</p>
                      :
                        <AccentInfo>-not available-</AccentInfo>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <FlexWrapper style={{width: '90%', height: '1rem', margin: '1rem auto 0.2rem auto', alignItems: 'center'}}>
                <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
                <Divider />
                <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.3rem', transform: 'rotateY(-180deg)'}} />
              </FlexWrapper>

              <div
                style={{
                  ...s.reportSection,
                }}
              >
                <p style={s.reportSectionTitle}>FINANCIALS</p>
                <div style={s.reportSectionContentContainer}>
                  <div style={s.reportSectionColumn}>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleXLarge}>New Interest Due:</p>
                      {report.newInterestDue ?
                        <p style={s.reportRowValue}>${report.newInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                      :
                        <AccentInfo>-not available-</AccentInfo>
                      }
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleXLarge}>Previous Interest Due:</p>
                      <p style={s.reportRowValue}>
                        {report.interestRemainingAtClosing ?
                          `$${report.interestRemainingAtClosing.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`
                        :
                          <AccentInfo>-unavailable-</AccentInfo>
                        }
                      </p>
                    </div>

                    <Divider />

                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleXLarge}>Change:</p>
                      {report.profitAmount ?
                        <p style={s.reportRowValue}>${report.profitAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</p>
                      :
                        <AccentInfo>-not available-</AccentInfo>
                      }
                    </div>
                    <div style={s.reportSectionContentContainer}>
                      <p style={s.reportRowTitleXLarge}>Difference:</p>
                      <p style={s.reportRowValue}>
                        {report.profitPercent ? `${report.profitPercent.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%` : <></>}
                      </p>
                    </div>
                    <div style={s.reportSectionContentContainerPadded}>
                      <p style={s.reportRowTitleXXLarge}>Total Team Interest Change to date:</p>
                      <p style={s.reportRowValue}>
                        {report.teamTotalProfitAmount ? `$${report.teamTotalProfitAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}` : <></>}
                      </p>
                    </div>
                  </div>

                </div>
              </div>

              <FlexWrapper style={{width: '90%', height: '1rem', margin: '1rem auto 0.2rem auto', alignItems: 'center'}}>
                <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
                <Divider />
                <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.3rem', transform: 'rotateY(-180deg)'}} />
              </FlexWrapper>
            </>
          }
        </div>
      </div>

      <div size={"A4"} style={{ ...s.main, padding: '0 3.5rem 3.5rem 3.5rem', paddingTop: 0 }}>
        {/* DISCREPANCIES START */}
        {report.updates && report.updates.length > 0 &&
          <div style={{ marginBottom: '3rem' }}>
            <div style={s.reportSectionUpdates}>
              <p style={s.reportSectionTitle}>RECORDS UPDATED</p>
              <div style={s.updateContainer}>
                {report.updates.map((update, index) => (
                  update.old !== update.new &&
                  <div key={update.field} style={s.updateWrapper}>
                    <div style={s.updateBox}>
                      <p
                        style={{
                          ...s.reportSectionTitle,
                          fontSize: 12,
                          marginBottom: 5,
                          textDecoration: "underline",
                        }}
                      >
                        {update.label}
                      </p>
                      <div style={s.reportSectionColumn}>
                        <div style={s.reportSectionContentContainer}>
                          <p style={s.reportRowTitle}>Original</p>
                          <div style={s.reportRowValue}>
                            {!update.old ?
                              <AccentInfo>-empty-</AccentInfo>
                            : update.old === 'Y' ?
                              'YES'
                            : update.old === 'N' ?
                              'NO'
                            : update.field.includes('Rate') ?
                              `${update.old}%`
                            : update.field.includes('Price')
                            || update.field.includes('Amount')
                            || update.field.includes('Value') ?
                              `$${update.old}`
                            : update.field.includes('PrimaryMortgageTerm') ?
                              `${update.new} years`
                            :
                              update.old
                            }
                          </div>
                        </div>
                      </div>
                      <div style={s.reportSectionColumn}>
                        <div style={s.reportSectionContentContainer}>
                          <p style={s.reportRowTitle}>New</p>
                          <div style={s.reportRowValue}>
                            {!update.new ?
                              <AccentInfo>-empty-</AccentInfo>
                            : update.new === 'Y' ?
                              'YES'
                            : update.new === 'N' ?
                              'NO'
                            : update.field.includes('Rate') ?
                              `${update.new}%`
                            : update.field.includes('Price')
                            || update.field.includes('Amount')
                            || update.field.includes('Value') ?
                              `$${update.new}`
                            : update.field.includes('PrimaryMortgageTerm') ?
                              `${update.new} years`
                            :
                              update.new
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {((index !== report.updates.length - 2) && (index !== report.updates.length - 1)) &&
                      <FlexWrapper style={{width: '60%', height: '1rem', margin: '1rem auto 0.2rem auto', alignItems: 'center'}}>
                        <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
                        <Divider />
                        <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.3rem', transform: 'rotateY(-180deg)'}} />
                      </FlexWrapper>
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        {/* DISCREPANCIES END */}

        <FlexWrapper style={{width: '90%', height: '1rem', margin: '1rem auto 0.2rem auto', alignItems: 'center'}}>
          <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginRight: '0.3rem'}} />
          <Divider />
          <HdrStrongIcon style={{height: '0.7rem', width: '0.7rem', color: `${theme.palette.common.grayText}`, marginLeft: '0.3rem', transform: 'rotateY(-180deg)'}} />
        </FlexWrapper>

        {/* TIMELINE START */}
        <div>
          <p style={{ ...s.reportSectionTitle, marginBottom: 20 }}>INVESTIGATION TIMELINE</p>
          <Timeline timeline={report.timeline} origin={'report'} />
        </div>
        {/* TIMELINE END */}
      </div>
      </ScrollWindow>
    </CardContainer>
  )
}
