import React from "react";
import Notes from "./Components/Notes.js";
import EditTags from "./Components/EditTags/EditTags.js";
import CancelIcon from '@mui/icons-material/Cancel';
import EditTargets from "./Components/EditTargets.js";
import DismissLead from "./Components/LeadStatus/DismissLead.js";
import { useTheme } from "@mui/styles";
import ReportsModal from "./Components/ReportsModal.js";
import AddAssignees from "./Components/AddAssignees/AddAssignees.js";
import LeadTimeline from "../../Timeline/LeadTimeline";
import BeginClosing from "./Components/LeadStatus/BeginClosing";
import FinalizeLead from "./Components/LeadStatus/FinalizeLead.js";
import ConfirmUpdate from "./Components/LeadStatus/ConfirmUpdate.js";
import CompareRecords from "./Components/CompareRecords.js";
import LeadActionsNav from "./LeadActionsNav";
import { useSelector } from "react-redux";
import PropertyDetails from "./Components/PropertyDetails.js";
import InvestigateLead from "./Components/LeadStatus/InvestigateLead.js";
import ProvideFinancials from "./Components/ProvideFinancials.js";
import ChangeTargetOutcome from "./Components/ChangeTargetOutcome.js";
import { selectViewingLead } from "../../../slices/viewingLeadSlice";
import { selectViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { selectTeamMembersByName } from "../../../slices/teamMembersSlice.js";
import { selectViewingLeadReports } from "../../../slices/viewingLeadReportsSlice";
import { selectLeadActionsNavSelection } from "../../../slices/sessionDataSlice.js";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer , ComponentHeader, Typography } from "../../../StyledComponents";

const LeadActionsModal = ({ handleCloseModal, userId, userFullName, dashPerspective, leadUserNotifications, setLeadUserNotifications, teamId, leadStatusFilter }) => {
  const theme = useTheme()

  const teamMembersByName = useSelector(selectTeamMembersByName)
  const mortgageData = useSelector(selectViewingMortgage)
  const navSelection = useSelector(selectLeadActionsNavSelection)
  const leadReports = useSelector(selectViewingLeadReports)
  const leadData = useSelector(selectViewingLead)

  return (
    <ModalContainer className="threePanel">
      <ComponentBorder className="top" style={{gridRow: '1', gridColumn: '1/6'}}>
        <ComponentHeader className="areaTitle">
          <Typography className="xLarge">{mortgageData.recordDetails.address.StreetAddressOneLine.currentValue}</Typography>
          <Typography className="xLarge" style={{margin: '0 0.6rem'}}>-</Typography>
          {leadData.status === 'awaitingAction' ?
            <Typography className="xLarge" style={{color: `${theme.palette.common.redMediumTransparent}`, marginRight: '0.6rem'}}>
              Potential Violation
            </Typography>
          : leadData.status === 'investigating' ?
            <Typography className="xLarge" style={{color: `${theme.accent.caution.primary}`, marginRight: '0.6rem'}}>
              Active Investigation
            </Typography>
          : leadData.status === 'awaitingUpdate' ?
            <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`, marginRight: '0.6rem'}}>
              Awaiting Updates to Public Record
            </Typography>
          :
            <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`, marginRight: '0.6rem'}}>
              Closing Investigation
            </Typography>
          }
        </ComponentHeader>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <LeadActionsNav mortgageData={mortgageData} leadOutcome={leadData.targetOutcome} reportsNumber={mortgageData.reports.length} leadStatus={leadData.status} handleCloseModal={handleCloseModal} leadAssignees={leadData.assigneeIds} />
      {navSelection === 'reports' ?
        <ReportsModal reports={leadReports} />
      : navSelection === 'notes' ?
        <Notes handleCloseModal={handleCloseModal} userId={userId} userFullName={userFullName} leadData={leadData} dashPerspective={dashPerspective} mortgageData={mortgageData} />
      : navSelection === 'compareRecords' ?
        <CompareRecords mortgageData={mortgageData} userId={userId} dashPerspective={dashPerspective} userFullName={userFullName} leadUserNotifications={leadUserNotifications} setLeadUserNotifications={setLeadUserNotifications} leadData={leadData} teamId={teamId} location={'activeLead'} />
      : navSelection === 'propertyDetails' ?
        <PropertyDetails handleCloseModal={handleCloseModal} mortgageData={mortgageData} />
      : navSelection === 'editTargets' ?
        <EditTargets handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} mortgageData={mortgageData} />
      : navSelection === 'editTags' ?
        <EditTags leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={mortgageData.activeLead} mortgageData={mortgageData} />
      : navSelection === 'addAssignees' ?
        <AddAssignees  teamMembersByName={teamMembersByName} handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} leadUserNotifications={leadUserNotifications} activeLead={mortgageData.activeLead} mortgageData={mortgageData} />
      : navSelection === 'investigateLead' ?
        <InvestigateLead handleCloseModal={handleCloseModal} userId={userId} teamId={teamId} userFullName={userFullName} teamMembersByName={teamMembersByName} mortgageId={mortgageData._id} leadData={leadData} dashPerspective={dashPerspective} requestOrigin={'activeLeads'} />
      : navSelection === 'dismissLead' ?
        <DismissLead handleCloseModal={handleCloseModal} userFullName={userFullName} leadStatusFilter={leadStatusFilter} teamId={teamId} userId={userId} dashPerspective={dashPerspective} leadData={leadData} leadUserNotifications={leadUserNotifications} />
      : navSelection === 'targetOutcome' ?
        <ChangeTargetOutcome handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} />
      : navSelection === 'beginClosingLead' ?
        <BeginClosing handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} teamId={teamId} leadUserNotifications={leadUserNotifications} />
      : navSelection === 'finalizeLead' ?
        <FinalizeLead handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} teamId={teamId} leadUserNotifications={leadUserNotifications} mortgageData={mortgageData} />
      : navSelection === 'confirmUpdate' ?
        <ConfirmUpdate handleCloseModal={handleCloseModal} leadData={leadData} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} teamId={teamId} mortgageData={mortgageData} />
      : navSelection === 'provideFinancialsModal' || navSelection === 'provideFinancialsCard' || navSelection === 'provideTerms' ?
        <ProvideFinancials mortgageData={mortgageData} userFullName={userFullName} leadData={leadData} dashPerspective={dashPerspective} userId={userId} navSelection={navSelection} handleCloseModal={handleCloseModal} teamId={teamId} />
      :
        <></>
      }
      <ComponentHeader className="modal timeline">Timeline</ComponentHeader>
      <TimelineContainer className="actionsModal">
        <LeadTimeline origin={'modal'} />
      </TimelineContainer>
      <ComponentBorder className="left" style={{gridRow:'2/6', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/6', gridColumn: '5'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'6', gridColumn: '1/6'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default LeadActionsModal;