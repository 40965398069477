import React, { useEffect, useState } from "react"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setViewingLead } from "../../../../../slices/viewingLeadSlice";
import { setViewingMortgage } from "../../../../../slices/viewingMortgageSlice";
import { ComponentSectionRow, Typography, ActionIconWrapper } from "../../../../../StyledComponents";
import { setFwdBckFilteredViewingLeads, setFwdBckViewingLeads } from "../../../../../slices/teamActiveLeadsSlice";
import { setLeadActionNavMenuSelection, setLeadStatusFilter, setNavSelection, setViewingLeadActions, setViewingMortgageActions, setViewingUploadWindow } from "../../../../../slices/sessionDataSlice";

const NewInvestigationRow = ({ row, dashPerspective, teamClosingLeads, teamInvestigatingLeads, teamAwaitingActionLeads, teamAwaitingUpdateLeads, leadsFilteredBoolean }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [leadObj, setLeadObj] = useState({})

  const handleGoToInvestigationWidnow = async (leadData) => {
    if (leadsFilteredBoolean || dashPerspective !== 'team') {
      dispatch( setFwdBckFilteredViewingLeads(leadData._id) )
    } else {
      dispatch( setFwdBckViewingLeads(leadData._id) )
    }
    dispatch( setViewingLead(leadData) )
    dispatch( setViewingMortgage(leadData.belongsToMortgage) )
    dispatch( setNavSelection('investigations') )
    if (leadData.status === 'awaitingAction') {
      dispatch( setLeadStatusFilter('Awaiting Action') )
    } else if (leadData.status === 'investigating') {
      dispatch( setLeadStatusFilter('Investigating') )
    } else if (leadData.status === 'closing') {
      dispatch( setLeadStatusFilter('Closing') )
    } else {
      dispatch( setLeadStatusFilter('Awaiting PR Updates') )
    }
    dispatch( setViewingLeadActions(true) )
    dispatch( setViewingMortgageActions(false) )
    navigate(`/dashboard/investigations/${leadData.belongsToMortgage._id}`)
    dispatch( setLeadActionNavMenuSelection('compareRecords') )
    dispatch( setViewingUploadWindow(false) )
  }

  useEffect(() => {
    if (row) {
      let newLeadObj = {}
      let match = teamAwaitingActionLeads.find(lead => lead._id.toString() === row.leadId.toString())
      if (match) {
        newLeadObj = {
          streetAddress: row.streetAddress,
          city: row.city,
          state: row.state,
          postalCode: row.postalCode,
          leadObj: match,
          leadClosed: false,
        }
      } else {
        let match = teamInvestigatingLeads.find(lead => lead._id.toString() === row.leadId.toString())
        if (match) {
          newLeadObj = {
            streetAddress: row.streetAddress,
            city: row.city,
            state: row.state,
            postalCode: row.postalCode,
            leadObj: match,
            leadClosed: false,
          }
        } else {
          let match = teamClosingLeads.find(lead => lead._id.toString() === row.leadId.toString())
          if (match) {
            newLeadObj = {
              streetAddress: row.streetAddress,
              city: row.city,
              state: row.state,
              postalCode: row.postalCode,
              leadObj: match,
              leadClosed: false,
            }
          } else {
            let match = teamAwaitingUpdateLeads.find(lead => lead._id.toString() === row.leadId.toString())
            if (match) {
              newLeadObj = {
                streetAddress: row.streetAddress,
                city: row.city,
                state: row.state,
                postalCode: row.postalCode,
                leadObj: match,
                leadClosed: false,
              }
            }
          }
        }
      }
      if (!match) {
        newLeadObj = {
          streetAddress: row.streetAddress,
          city: row.city,
          state: row.state,
          postalCode: row.postalCode,
          leadClosed: true,
        }
      }

      setLeadObj(newLeadObj)
    }
  }, [row])

  return (
    <>
      {leadObj &&
        <ComponentSectionRow 
          key={leadObj.streetAddress} 
          className={leadObj.leadClosed ? 'noPointHover' : "hover hoverActivate"} style={{justifyContent: 'center'}} 
          onClick={leadObj.leadClosed ? null : () => handleGoToInvestigationWidnow(leadObj.leadObj)}
        >
          {!leadObj.leadClosed &&
            <ActionIconWrapper style={{color: 'inherit'}}>
              <VisibilityIcon style={{height: '1.4rem', width: '1.4rem', margin: '0 0.3rem 0 0', transform: 'rotate(180deg)'}} /> 
            </ActionIconWrapper>
          }
          <Typography className="primary" style={{width: 'fit-content'}}>{leadObj.streetAddress},</Typography>
          <Typography className="primary" style={{width: 'fit-content', marginLeft: '0.3rem'}}>{leadObj.city},</Typography>
          <Typography className="primary" style={{width: 'fit-content', marginLeft: '0.3rem'}}>{leadObj.state}</Typography>
          <Typography className="primary" style={{width: 'fit-content', marginLeft: '0.3rem'}}>{leadObj.postalCode}</Typography>
        </ComponentSectionRow>
      }
    </>
  )
}

export default NewInvestigationRow