import React from "react";
import FullLogo from './../../../../../Full.logo.teal.png';
import { useSelector } from "react-redux";
import { APP_VERSION } from "../../../../../config";
import { selectTeamDetails } from "../../../../../slices/teamAndUserSettingsSlice";
import { ColumnContent, ComponentSectionRow, Detail, DetailContainer, DetailInfoContainer, DetailListContainer, FlexWrapper, Typography } from "../../../../../StyledComponents";

const TeamDetails = () => {

  const teamDetails = useSelector(selectTeamDetails)

  return (
      <FlexWrapper className="column fitContentHeight autoMarginHorizontal" style={{width: '50rem', padding: '1rem 0 4rem 0'}}>
        <ComponentSectionRow className="">
          <Typography className="detail small lowercase thin" style={{width: 'fit-content', paddingLeft: '2rem'}}>
            my team:
          </Typography>
        </ComponentSectionRow>
        <img alt="my company logo" src={FullLogo} style={{width: '45rem', height: 'auto', margin: 'auto'}} />
        <DetailContainer style={{width: 'fit-content', padding: '1.5rem 0 0 2rem'}}>
          <DetailListContainer>
            <DetailInfoContainer className="item">
              <Detail className="thin">date joined:</Detail>
              <Detail className="thin">team accounts:</Detail>
              <Detail className="thin">app admin:</Detail>
              <Detail className="thin">app version:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer>
              <Detail>{teamDetails.dateCreated}</Detail>
              <Detail>{teamDetails.numberOfUsers}</Detail>
              <Detail>{teamDetails.appAdminName}, {teamDetails.appAdminEmail}</Detail>
              <Detail>{APP_VERSION}</Detail>
            </DetailInfoContainer>
          </DetailListContainer>
        </DetailContainer>
        <ComponentSectionRow style={{padding: '2rem 0 0.5rem 0'}}>
          <ColumnContent className="detail small lowercase thin" style={{width: 'fit-content', paddingLeft: '2rem'}}>
            <span style={{fontWeight: '400', marginRight: '0.5rem'}}>Have questions or feature requests?</span>
            Contact your App Admin or reach out to our IT department at solutions@lancastersweep.com
          </ColumnContent>
        </ComponentSectionRow>
      </FlexWrapper>
  )
}

export default TeamDetails