import React, { useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from "@mui/icons-material/Error";
import ReportIcon from '@mui/icons-material/Report';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import MortgageDropdown from "../../../../../components/DropDowns/MortgageDropdown";
import CircularProgress from "@mui/material/CircularProgress";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { selectTeamMortgages } from "../../../../../slices/teamMortgagesSlice";
import { selectRunMortgageScanPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { selectUserDefaultLocations } from "../../../../../slices/teamAndUserSettingsSlice";
import { selectNextRecordSweepDate, selectTeamRemainingMonthlyQueries } from "../../../../../slices/teamStatsSlice";
import { Button, ButtonWrapper, Divider, ErrorWrapper, FlexWrapper, HeaderText, InputWrapper, Label, StatusAndLabelWrapper, StyledInputElement, Typography } from "../../../../../StyledComponents";

const SearchForm = ({ allFieldsPresent, searchFetching, handlePropertySearch, searchForPropertyPerm, responseError, propertyNotFound, setNullForm, nullForm, setPropertyNotFound, setResponseError, handleMortgageScan, scanResponseError }) => {
  const theme = useTheme()
  
  const teamMortgages = useSelector(selectTeamMortgages)
  const nextRecordSweepDate = useSelector(selectNextRecordSweepDate)
  const runMortgageScanPerm = useSelector(selectRunMortgageScanPerm)
  const userDefaultLocations = useSelector(selectUserDefaultLocations)
  const remainingMonthlyQueries = useSelector(selectTeamRemainingMonthlyQueries)

  const [mortgageToScan, setMortgageToScan] = useState('empty')
  const [statusStreetAddress, setStatusStreetAddress] = useState(null)
  const streetAddress = useRef()
  const [statusCity, setStatusCity] = useState(null)
  const city = useRef()
  const [statusState, setStatusState] = useState(null)
  const state = useRef()
  const [statusPostalCode, setStatusPostalCode] = useState(null)
  const postalCode = useRef()

  const validateSearch = () => {
    setResponseError(false)
    setPropertyNotFound(false)
    let newPostalCode = parseFloat(postalCode.current.value)
    let negativePostalCode = Math.sign(newPostalCode)
    if (streetAddress.current.value.includes('<') || streetAddress.current.value.includes('>') || city.current.value.includes('<') || city.current.value.includes('>') || state.current.value.includes('<') || state.current.value.includes('>') || newPostalCode > 99999 || postalCode.current.value.includes('>') || postalCode.current.value.includes('<') || negativePostalCode === -1 || (isNaN(postalCode.current.value) && postalCode.current.value.length !== 0) || (streetAddress.current.value.length === 0 && city.current.value.length === 0 && state.current.value.length === 0 && postalCode.current.value.length === 0)) {
      if (streetAddress.current.value.includes('<') || streetAddress.current.value.includes('>')) {
        setStatusStreetAddress('invalid')
      }
      if (city.current.value.includes('<') || city.current.value.includes('>')) {
        setStatusCity('invalid')
      }
      if (state.current.value.includes('<') || state.current.value.includes('>')) {
        setStatusState('invalid')
      }
      if (newPostalCode > 99999 || postalCode.current.value.includes('>') || postalCode.current.value.includes('<') || negativePostalCode === -1 || isNaN(postalCode.current.value)) {
        setStatusPostalCode('invalid')
      }
      if ((streetAddress.current.value.length === 0 && city.current.value.length === 0 && state.current.value.length === 0 && postalCode.current.value.length === 0)) {
        setNullForm(true)
      }
    } else {
      handlePropertySearch(streetAddress.current.value, city.current.value, state.current.value, postalCode.current.value)
    }
  }

  const handleSetMortgageToScan = (event) => {
    setMortgageToScan(event.target.value)
  }

  useEffect(() => {
    if (userDefaultLocations.state) {
      state.current.value = userDefaultLocations.state
    }
    if (userDefaultLocations.city) {
      city.current.value = userDefaultLocations.city
    }
  }, [userDefaultLocations])

  return (
    <FlexWrapper className="column" style={{maxWidth: '100rem', margin: '0 auto'}}>
      <FlexWrapper className="fitContentHeight" style={{maxWidth: '100rem', margin: '0 auto'}}>
        <FlexWrapper className="column justifyCenter" style={{height: '60rem'}}>
          <TravelExploreIcon style={{margin: '0 auto', width: '20rem', height: '20rem', color: `${theme.palette.common.grayBorder}`}} />
          <FlexWrapper className="column alignCenter" style={{height: 'fit-content'}}>
            <HeaderText style={{margin: '0 auto', fontSize: '2rem'}}>
              Run Property Search
            </HeaderText>
            <HeaderText style={{margin: '0 auto'}}>
              Provide all info to ensure a positive match.
            </HeaderText>
            {responseError || propertyNotFound || nullForm ?
              <StatusAndLabelWrapper style={{width: 'fit-content'}}>
                <ErrorWrapper>
                  {scanResponseError ?
                    <>
                      <ErrorIcon />
                      The scan encountered an error. Please try again.
                    </>
                  : responseError ?
                    <>
                      <ErrorIcon />
                      Your search encountered an error. Please try again.
                    </>
                  : nullForm ?
                    <>
                      <ErrorIcon />
                      Empty form.
                    </>
                  : allFieldsPresent ?
                    <>
                      <SignalWifiBadIcon />
                      No results found. 
                    </>
                  :
                    <>
                      <SignalWifiBadIcon />
                      No results found. Try providing more information.
                    </>
                  }
                </ErrorWrapper>
              </StatusAndLabelWrapper>
            :
              <div style={{height: '3rem'}}></div>
            }
          </FlexWrapper>
          <FlexWrapper className="column autoMarginHorizontal fitContentHeight">
            <InputWrapper style={{width: '35rem', margin: '0 auto'}}>
              <StatusAndLabelWrapper className="justifyStart">
                <Label htmlFor="streetAddress">Street Address</Label>
                {(statusStreetAddress === "invalid" || statusCity === "invalid" || statusState === "invalid" || statusPostalCode === "invalid") && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Invalid Entries
                  </ErrorWrapper>
                )}
              </StatusAndLabelWrapper>
              <StyledInputElement
                style={{width: '35rem'}}
                disabled={searchFetching || !searchForPropertyPerm}
                autoComplete="off"
                type="text"
                name="streetAddress"
                id="streetAddress"
                ref={streetAddress}
                className={statusStreetAddress === "invalid" ? 'error' : searchForPropertyPerm ? '' : 'disabled'}
                onChange={() => {
                  if (statusStreetAddress === "invalid") {
                    setStatusStreetAddress(null)
                  }
                  if (nullForm) {
                    setNullForm(false)
                  }
                  if (responseError) {
                    setResponseError(false)
                  }
                }}
              />
            </InputWrapper>
            <FlexWrapper style={{margin: '0 auto', width: 'fit-content'}}>
              <InputWrapper style={{width: '14rem'}}>
                <StatusAndLabelWrapper className="column">
                  <Label htmlFor="city">City</Label>
                </StatusAndLabelWrapper>
                <StyledInputElement
                  style={{width: '14rem'}}
                  disabled={searchFetching || !searchForPropertyPerm}
                  autoComplete="off"
                  type="text"
                  name="city"
                  id="city"
                  ref={city}
                  className={statusCity === "invalid" ? 'error' : searchForPropertyPerm ? '' : 'disabled'}
                  onChange={() => {
                    if (statusCity === "invalid") {
                      setStatusCity(null)
                    }
                    if (nullForm) {
                      setNullForm(false)
                    }
                    if (responseError) {
                      setResponseError(false)
                    }
                  }}
                />
              </InputWrapper>
              <InputWrapper style={{width: '6rem'}}>
                <StatusAndLabelWrapper className="column">
                  <Label htmlFor="state">State (XX)</Label>
                </StatusAndLabelWrapper>
                <StyledInputElement
                  style={{width: '6rem'}}
                  disabled={searchFetching || !searchForPropertyPerm}
                  autoComplete="off"
                  type="text"
                  name="state"
                  id="state"
                  ref={state}
                  className={statusState === "invalid" ? 'error' : searchForPropertyPerm ? '' : 'disabled'}
                  onChange={() => {
                    if (statusState === "invalid") {
                      setStatusState(null)
                    }
                    if (nullForm) {
                      setNullForm(false)
                    }
                    if (responseError) {
                      setResponseError(false)
                    }
                  }}
                />
              </InputWrapper>
              <InputWrapper style={{width: '8rem'}}>
                <StatusAndLabelWrapper className="column">
                  <Label htmlFor="postalCode">Zip Code</Label>
                </StatusAndLabelWrapper>
                <StyledInputElement
                  style={{width: '8rem'}}
                  disabled={searchFetching || !searchForPropertyPerm}
                  autoComplete="off"
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  ref={postalCode}
                  className={statusPostalCode === "invalid" ? 'error' : searchForPropertyPerm ? '' : 'disabled'}
                  onChange={() => {
                    if (statusPostalCode === "invalid") {
                      setStatusPostalCode(null)
                    }
                    if (nullForm) {
                      setNullForm(false)
                    }
                  }}
                />
              </InputWrapper>
            </FlexWrapper>
            <ButtonWrapper className="selfAlignEnd marginAuto" style={{marginTop: '3rem'}}>
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.caution.color}`,
                  backgroundColor: `${theme.tooltip.caution.background}`,
                  border: `0.1rem solid ${theme.tooltip.caution.border}`,
                  borderBottom: `0.1rem solid ${theme.tooltip.caution.border}`,
                  opacity: '0',
                  borderRadius: '1rem',
                  width: 'fit-content',
                  minWidth: 'fit-content',
                  padding: '0.5rem 1rem',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                }]}}
                title={(remainingMonthlyQueries === 0) ?
                  <FlexWrapper className="column">
                    <Typography className="bold alignTextCenter">
                      No monthly queries remaining.
                    </Typography>
                  </FlexWrapper>
                : !searchForPropertyPerm ?
                  <FlexWrapper className="column">
                    <Typography className="bold alignTextCenter">
                      Elevated authority required
                    </Typography>
                  </FlexWrapper>
                : null} 
                placement="top"
                arrow
              >
                <span>
                  <Button 
                    className={searchFetching ? 'neutralLoading' : (!searchForPropertyPerm || nullForm || responseError || remainingMonthlyQueries === 0) ? "neutralDisabled" : "neutral"}
                    disabled={searchFetching || !searchForPropertyPerm || nullForm || responseError || remainingMonthlyQueries === 0}
                    onClick={(searchFetching || !searchForPropertyPerm || nullForm || responseError || remainingMonthlyQueries === 0) ? null : () => validateSearch()}
                  >
                    {searchFetching ?
                      <CircularProgress
                        sx={{ color: `${theme.button.text.primaryLoading}` }}
                        size={12}
                      />
                    :
                      <>
                        <SearchIcon style={{marginRight: '0.2rem'}} />
                        Search
                      </>
                    }
                  </Button>
                </span>
              </Tooltip>
            </ButtonWrapper>
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper className="autoMarginHorizontal fitContentWidth column justifyCenter" style={{padding: '3rem 0'}}>
          <Divider style={{height: '17rem', width: '0.2rem'}} />
          <Typography className="accent xxLarge" style={{padding: '0 2rem'}}>or</Typography>
          <Divider style={{height: '32rem', width: '0.2rem'}} />
        </FlexWrapper>
        <FlexWrapper className="column justifyCenter" style={{height: '60rem'}}>
          <DocumentScannerIcon style={{margin: '1.5rem auto', width: '17rem', height: '17rem', color: `${theme.palette.common.grayBorder}`}} />
          <HeaderText style={{margin: '0 auto', fontSize: '2rem'}}>
            Run Property Scan
          </HeaderText>
          <HeaderText style={{margin: '0 auto'}}>
            Select an existing mortgage to run a scan
          </HeaderText>
          <HeaderText style={{margin: '0 auto', fontSize: '1rem'}}>
            An investigation will be opened if discrepancies are detected
          </HeaderText>
          <MortgageDropdown
            value={mortgageToScan}
            handleChange={handleSetMortgageToScan}
            items={teamMortgages}
            disabled={!runMortgageScanPerm}
          />
          <ButtonWrapper className="selfAlignEnd" style={{marginTop: '3rem'}}>
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.tooltip.caution.color}`,
                backgroundColor: `${theme.tooltip.caution.background}`,
                border: `0.1rem solid ${theme.tooltip.caution.border}`,
                borderBottom: `0.1rem solid ${theme.tooltip.caution.border}`,
                opacity: '0',
                borderRadius: '1rem',
                width: 'fit-content',
                minWidth: 'fit-content',
                padding: '0.5rem 1rem',
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, 0],
                },
              }]}}
              title={(remainingMonthlyQueries === 0) ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    No monthly queries remaining.
                  </Typography>
                </FlexWrapper>
              : !runMortgageScanPerm ?
                <FlexWrapper className="column">
                  <Typography className="bold alignTextCenter">
                    Elevated authority required
                  </Typography>
                </FlexWrapper>
              : null} 
              placement="top"
              arrow
            >
              <span>
                <Button 
                  className={searchFetching ? 'neutralLoading' : (!runMortgageScanPerm || !mortgageToScan || remainingMonthlyQueries === 0) ? "neutralDisabled" : "neutral"}
                  disabled={searchFetching || !runMortgageScanPerm || !mortgageToScan || remainingMonthlyQueries === 0}
                  onClick={(searchFetching || !runMortgageScanPerm || !mortgageToScan || remainingMonthlyQueries === 0) ? null : () => handleMortgageScan(mortgageToScan)}
                >
                  {searchFetching ?
                    <CircularProgress
                      sx={{ color: `${theme.button.text.primaryLoading}` }}
                      size={12}
                    />
                  :
                    'Scan'
                  }
                </Button>
              </span>
            </Tooltip>
          </ButtonWrapper>
        </FlexWrapper>
      </FlexWrapper>
      {remainingMonthlyQueries === 0 &&
        <FlexWrapper className="alignCenter fitContentHeight fitContentWidth" style={{paddingRight: '2rem', margin: '1rem auto'}}>
          <FlexWrapper 
            style={{border: `0.2rem solid ${theme.palette.common.orangeDark}`, padding: '0.2rem 0.2rem 0.2rem 0.5rem', width: '2.7rem', borderRadius: '1rem 0 0 1rem', background: `${theme.palette.common.orange}`, height: '5.6rem'}}
          >
            <ReportIcon style={{height: '1.8rem', width: '1.8rem', color: `${theme.palette.common.white}`}} />
          </FlexWrapper>
          <span 
            style={{
              border: `0.1rem solid ${theme.palette.common.orangeDark}`,
              padding: '0.4rem 1rem 0.4rem 1rem',
              borderRadius: '0 1rem 1rem 0',
              background: `${theme.palette.common.orangeTransparent}`,
              borderLeft: 'none',
              color: `${theme.palette.common.orangeDark}`,
              fontSize: '1rem',
              width: 'fit-content',
            }}
          >
            No monthly queries remaining..<br/>
            Searches and scans are disabled until {nextRecordSweepDate}.<br/>
            Contact you app admin to request a higher monthly limit.
          </span>
        </FlexWrapper>
      }
    </FlexWrapper>
  )
}

export default SearchForm;