import React from "react";
import { useTheme } from "@mui/styles";
import { FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const ManualInvestigation = ({milestone}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
        {milestone.notify ? 
          <NewLabel className="milestone" style={{marginLeft: '0.5rem'}}>
            new
          </NewLabel> 
        : 
          <></>
        }
      </FlexWrapper>
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            initiated by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        {milestone.details.length > 0 &&
          <FlexWrapper className='fitContentHeight'>
            <Typography className="fitContentHeight justifyEnd" style={{width: '7rem', marginRight: '0.5rem'}}>
              {milestone.details.length === 1 ? `assignee:` : `assignees:`}
            </Typography>
            <FlexWrapper className="fitContentWidth flexWrap" style={{paddingRight: '1rem'}}>
              {milestone.details.map((assignee, index) => (
                <Typography key={assignee} className="fitContentWidth bold" style={{marginLeft: index === 0 ? '0' : '0.4rem'}}>
                  {index === (milestone.details.length - 1) && milestone.details.length > 1 ?
                    <>
                      <span style={{fontWeight: '400', marginRight: '0.4rem'}}>and</span>
                      {assignee}
                    </>
                  : index === (milestone.details.length - 1) || milestone.details.length === 1 ?
                    `${assignee}`
                  : milestone.details.length === 2 ?
                    `${assignee}`
                  :
                    `${assignee},`
                  }
                </Typography>
              ))}
            </FlexWrapper>
          </FlexWrapper>
        }
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default ManualInvestigation