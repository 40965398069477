import React from "react";
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CompareLink, Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../../../../StyledComponents";

const LeadDismissed = ({ notification, handleStatusChange, handleOpenNotesModal, handleOpenDeleteModal, cardIndex }) => {
  const theme = useTheme();

  return (
    <>
      <DetailContainer className="notificationTitle">
        <div style={{width: '6rem', margin: '0 0 0 0.5rem', display: 'flex', justifyContent: 'space-between'}}>
          {cardIndex === 0 ?
            <CompareLink autoFocus onClick={() => handleOpenDeleteModal()} style={{margin: '0'}} className="notification">
              <DeleteIcon style={{height: '1rem'}} />
            </CompareLink>
          :
            <CompareLink onClick={() => handleOpenDeleteModal()} style={{margin: '0'}} className="notification">
              <DeleteIcon style={{height: '1rem'}} />
            </CompareLink>
          }
          {notification.status !== "new" ?
            <Tooltip
              componentsProps={{tooltip: {sx: {color: `${theme.palette.common.white}`, backgroundColor: `${theme.palette.common.black}`}}}}
              title={<div><p style={{margin: "0.2rem"}}>Mark as "New"</p></div>} 
              placement="top"
              arrow
            >
              <CompareLink onClick={() => handleStatusChange(notification.status, true)} style={{margin: '0'}} className="notification">
                <EmailIcon style={{height: '1rem'}} />
              </CompareLink>
            </Tooltip>
          :
            <></>
          }
        </div>
        <div style={{display: 'flex', margin: '0.5rem 0 0 0'}}>
          <DetailInfoContainer className="notificationTitle">
            <Detail className="subject">subject:</Detail>
          </DetailInfoContainer>
          <DetailInfoContainer className="notificationTitle">
            <Detail className="notificationTitle">Lead Dismissed</Detail>
          </DetailInfoContainer>
        </div>
        <div style={{display: 'flex'}}>
          <DetailInfoContainer className="notificationTitle">
            <Detail className="date">date:</Detail>
          </DetailInfoContainer>
          <DetailInfoContainer className="notificationTitle">
            <Detail className="date">{notification.date}</Detail>
          </DetailInfoContainer>
        </div>
        <CompareLink onClick={() => handleOpenNotesModal(notification.belongsToMortgage)} style={{margin: '0'}} >
          View Notes <OpenInNewIcon style={{height: '1rem'}} />
        </CompareLink>
      </DetailContainer>
      <div style={{display: 'flex', justifyContent: 'space-between', gridColumn: '3', width: '100%'}}>
        <DetailContainer style={{width: 'fit-content', minWidth: '20rem'}}>
          <div style={{height: 'fit-content'}}>
            <Detail className="header">Property Details</Detail>
          </div>
          <DetailListContainer>
            <DetailInfoContainer className="item">
              {notification.Owner2FullName ?
                <>
                  <Detail className="item">Borrowers:</Detail>
                  <Detail></Detail>
                </>
              :
                <Detail className="item">Borrower:</Detail>
              }
              <Detail className="item">Address:</Detail>
              <Detail></Detail>
              <Detail className="item">Target Outcome:</Detail>
              <Detail className="item">Updated By:</Detail>
              <Detail className="item">Assignees:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer>
              <Detail>{notification.Owner1FullName}</Detail>
              {notification.Owner2FullName ?
                <Detail>{notification.Owner2FullName}</Detail>
                :
                <></>
              }
              <Detail>{notification.StreetAddres}</Detail>
              <Detail>{notification.City}, {notification.StateOrProvince}</Detail>
              <Detail>{notification.targetOutcome}</Detail>
              <Detail>{notification.updatedBy}</Detail>
              {notification.assignees.map((fullName) => (
                <Detail key={fullName}>{fullName}</Detail>
              ))}
            </DetailInfoContainer>
          </DetailListContainer>
        </DetailContainer>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', gridColumn: '3', width: '100%'}}>
        <DetailContainer style={{width: 'fit-content', minWidth: '20rem'}}>
          <div style={{height: 'fit-content'}}>
            <Detail className="header">Investigation Details</Detail>
          </div>
          <DetailListContainer>
            <DetailInfoContainer className="item">
              <Detail className="item">Investigation Date</Detail>
              <Detail className="item">Target Outcome:</Detail>
              {notification.assignees.length === 1 ?
                <Detail className="item">Assignee:</Detail>
              :
                <>
                  <Detail className="item">Assignee:</Detail>
                  {notification.assignees.map((index) => (
                    index > 0 ?
                      <Detail key={index}></Detail>
                    :
                      <></>
                  ))}
                </>
              }
              <Detail className="item">Dismissed By:</Detail>
            </DetailInfoContainer>
            <DetailInfoContainer>
              <Detail>{notification.dateInvestigating}</Detail>
              <Detail>{notification.targetOutcome}</Detail>
              {notification.assignees.map((fullName) => (
                <Detail key={fullName}>{fullName}</Detail>
                ))}
              <Detail>{notification.updatedBy}</Detail>
            </DetailInfoContainer>
          </DetailListContainer>
        </DetailContainer>
      </div>
    </>
  )
}

export default LeadDismissed;