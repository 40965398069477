import React from "react";
import { useTheme } from "@mui/styles";
import { AccentInfo, FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const DiscrepanciesAllResolved = ({milestone}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="new" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            performed by:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        {milestone.details.map((detail, index) => (
          <FlexWrapper key={detail.label} className='fitContentHeight'>
            <Typography className="fitContentHeight justifyEnd" style={{width: '15rem', marginRight: '0.5rem'}}>
              {detail.label}:
            </Typography>
            <Typography className="bold">
              {detail.new === 'Y' ?
                'YES'
              : detail.new === 'N' ?
                'NO'
              : detail.field.includes('Rate') ?
                `${detail.new}%`
              : detail.field.includes('Price')
              || detail.field.includes('Amount')
              || detail.field.includes('Value') ?
                `$${detail.new}`
              :
                detail.new
              }
              <span style={{margin: '0 0.4rem', fontWeight: '400', textAlign: 'end'}}>
                to
              </span>
              {detail.new === 'Y' ?
                'YES'
              : detail.new === 'N' ?
                'NO'
              : detail.old && !detail.new ?
                <AccentInfo>none</AccentInfo>
              : detail.field.includes('Rate')
                || detail.field.includes('%') ?
                `${detail.old}%`
              : detail.field.includes('Price')
                || detail.field.includes('Amount')
                || detail.field.includes('Value') ?
                `$${detail.old}`
              :
                detail.old
              }
            </Typography>
          </FlexWrapper>
        ))}
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default DiscrepanciesAllResolved