import React, { useState } from "react";
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import ArticleIcon from '@mui/icons-material/Article';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import SettingsIcon from '@mui/icons-material/Settings';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { useDispatch } from "react-redux";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { setGuideNavSelection } from "../../../../../slices/sessionDataSlice";
import { FlexWrapper, ListItemNav, NavIndex, Typography, SubListItemNav } from "../../../../../StyledComponents";

const GuideNavigation = ({navSelection}) => {
  const dispatch = useDispatch()

  const [lastLeadGuide, setLastLeadGuide] = useState('overview');

  const changeSelection = (remember, content) => {
    if (remember) {
      if (content === 'leadOverview') {
        setLastLeadGuide('overview')
      } else if (content === 'investigatingLeads') {
        setLastLeadGuide('investigating')
      } else if (content === 'closingLeads') {
        setLastLeadGuide('closing')
      }
    }
    dispatch( setGuideNavSelection(content) )
  }
  const handleShowLeadGuide = () => {
    if (lastLeadGuide === 'overview') {
      changeSelection(true, 'leadOverview')
    } else if (lastLeadGuide === 'investigating') {
      changeSelection(true, 'investigatingLeads')
    } else if (lastLeadGuide === 'closing') {
      changeSelection(true, 'closingLeads')
    }
  }

  return (
    <FlexWrapper className="column" style={{padding: '1rem 1.5rem 4rem 1.5rem'}}>
      <ListItemNav 
        className={navSelection === 'introduction' ? 'pageActive' : ''}
        onClick={navSelection === 'introduction' ? null : () => changeSelection(false, 'introduction')}
      >
        <NavIndex>
          <LooksOneIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">Introduction</Typography>
      </ListItemNav>
      <ListItemNav 
        className={(navSelection === 'leadOverview' || navSelection === 'leadOverview' || navSelection === 'investigatingLeads' || navSelection === 'closingLeads') ? 'pageActive' : ''}
        onClick={(navSelection === 'leadOverview' || navSelection === 'leadOverview' || navSelection === 'investigatingLeads' || navSelection === 'closingLeads') ? null : () => handleShowLeadGuide()}
      >
        <NavIndex>
          <FlagCircleIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">Investigation Management</Typography>
      </ListItemNav>
      <SubListItemNav 
        className={navSelection === 'leadOverview' ? 'pageActive subList' : 'subList'}
        onClick={navSelection === 'leadOverview' ? null : () => changeSelection(true, 'leadOverview')}
      >
        <NavIndex className={navSelection === 'leadOverview' ? 'subNav active' : 'subNav'}>
          i.
        </NavIndex>
        <Typography>Overview</Typography>
      </SubListItemNav>
      <SubListItemNav 
        className={navSelection === 'investigatingLeads' ? 'pageActive subList' : 'subList'}
        onClick={navSelection === 'investigatingLeads' ? null : () => changeSelection(true, 'investigatingLeads')}
      >
        <NavIndex className={navSelection === 'investigatingLeads' ? 'subNav active' : 'subNav'}>
          ii.
        </NavIndex>
        <Typography>Investigations</Typography>
      </SubListItemNav>
      <SubListItemNav 
        className={navSelection === 'closingLeads' ? 'pageActive subList' : 'subList'}
        onClick={navSelection === 'closingLeads' ? null : () => changeSelection(true, 'closingLeads')}
      >
        <NavIndex className={navSelection === 'closingLeads' ? 'subNav active' : 'subNav'}>
          iii.
        </NavIndex>
        <Typography>Closing Investigations</Typography>
      </SubListItemNav>
      <ListItemNav 
        className={navSelection === 'performance' ? 'pageActive' : ''}
        onClick={navSelection === 'performance' ? null : () => changeSelection(false, 'performance')}
      >
        <NavIndex>
          <QueryStatsIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">Performance Metrics</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'repository' ? 'pageActive' : ''}
        onClick={navSelection === 'repository' ? null : () => changeSelection(false, 'repository')}
      >
        <NavIndex>
          <ListIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">Mortgage Repository</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'configuration' ? 'pageActive' : ''}
        onClick={navSelection === 'configuration' ? null : () => changeSelection(false, 'configuration')}
      >
        <NavIndex>
          <BuildCircleIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">System Configuration</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'propertySearch' ? 'pageActive' : ''}
        onClick={navSelection === 'propertySearch' ? null : () => changeSelection(false, 'propertySearch')}
      >
        <NavIndex>
          <SearchIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">Property Search</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'reports' ? 'pageActive' : ''}
        onClick={navSelection === 'reports' ? null : () => changeSelection(false, 'reports')}
      >
        <NavIndex>
          <ArticleIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">Report Generation</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'notifications' ? 'pageActive' : ''}
        onClick={navSelection === 'notifications' ? null : () => changeSelection(false, 'notifications')}
      >
        <NavIndex>
          <NotificationsIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">Notification System</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'settings' ? 'pageActive' : ''}
        onClick={navSelection === 'settings' ? null : () => changeSelection(false, 'settings')}
      >
        <NavIndex>
          <SettingsIcon fontSize="medium" />
        </NavIndex>
        <Typography className="primary">Settings</Typography>
      </ListItemNav>
    </FlexWrapper>
  )
}

export default GuideNavigation