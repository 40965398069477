import React from "react";
import { useTheme } from "@mui/styles";
import { FlexWrapper, NewLabel, Typography } from "../../../StyledComponents";

const NoteAdded = ({milestone, origin}) => {
  const theme = useTheme()

  return (
    <FlexWrapper className="column fitContentHeight" style={{background: `${theme.panel.background.lightest}`}}>
      <FlexWrapper className="fitContentWidth" style={{marginTop: '0.4rem'}}>
        <Typography className="small bold noTextWrap">
          {milestone.milestone}
        </Typography>
          {milestone.notify ? 
            <NewLabel className="new" style={{marginLeft: '0.5rem'}}>
              new
            </NewLabel> 
          : 
            <></>
          }
      </FlexWrapper>
      <FlexWrapper className='fitContentHeight column' style={{margin: '0.5rem 0 1rem 0.5rem'}}>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            author:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.contributor}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            date:
          </Typography>
          <Typography className="fitContentHeight bold">
            {milestone.date}
          </Typography>
        </FlexWrapper>
        <FlexWrapper className='fitContentHeight'>
          <Typography className="fitContentHeight justifyEnd" style={{width: '8rem', marginRight: '0.5rem'}}>
            note:
          </Typography>
          <Typography className="fitContentHeight bold" style={{width: origin === 'modal' ? '26rem' : '45rem'}}>
            {milestone.details}
          </Typography>
        </FlexWrapper>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default NoteAdded