import React from "react";
import { useTheme } from "@mui/styles";
import { ColumnContent, ComponentSectionRow, RowsContainer, ScrollWindow } from "../../../../../../../../StyledComponents";

const UploadErrors = ({ rows }) => {
  const theme = useTheme()

  return (
    <ScrollWindow className="horizontalOnly" style={{maxWidth: 'calc(100vw - 23.3rem)', borderTop: `0.1rem solid ${theme.palette.common.grayText}`, gridRow: '2/4'}}>
      <ComponentSectionRow className="header uploadErrors">
        <ColumnContent className="header" style={{gridColumn: '1'}}>
          index
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '2'}}>
          Row Index
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '3'}}>
          Error
        </ColumnContent>
      </ComponentSectionRow>
      <RowsContainer style={{height: '100%'}}>
        {rows.toReversed().map((row, index) => (
          <ComponentSectionRow key={row._id}className={(index%2 === 1 ? 'even ' : '') + 'uploadErrors'}>
            <ColumnContent className="detail" style={{gridColumn: '1'}}>
              {index + 1}
            </ColumnContent>
            <ColumnContent className="detail" style={{gridColumn: '2'}}>
              {row.index}
            </ColumnContent>
              {row.errors.map((details, index) => (
                <ColumnContent className="detail" key={row._id}style={{gridColumn: '3'}}>
                  {details.description}
                </ColumnContent>
              ))}
          </ComponentSectionRow>
        ))}
      </RowsContainer>
    </ScrollWindow>
  )
}

export default UploadErrors;