import styled from "@emotion/styled";

export const PageContainer = styled.div(({ theme }) => `
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  display: grid;
  background-color: ${theme.palette.common.pageBackground};
  grid-template-rows: 4.5rem 1fr;
  grid-template-columns: 19rem 1fr;
  overflow-x: hidden;
  
  &.dashboard {
    overflow-y: hidden;
    height: 100vh;
  }
  &.login {
    grid-template-rows: 8rem 1fr;
    grid-template-columns: 1fr;
    background-color: ${theme.page.background.primary};
    overflow: hidden;
  }
  &.fadeOut {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: ${theme.palette.common.black};

    animation: fadeOutAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @keyframes fadeOutAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.fadeIn {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: ${theme.palette.common.black};

    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.screenSaver {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    background-color: ${theme.palette.common.black};
  }
`)
export const PageContentContainer = styled.div(({ theme }) => `
  grid-row: 2;
  grid-column: 2;
  position: relative;
  min-height: 100%;
  max-height: calc(100vh - 4.5rem);
  max-width: calc(100vw - 19rem);
  background: ${theme.palette.common.pageBackground};
`)
export const PageContentWrapper = styled.div(`
  height: calc(100vh - 7rem);
  min-width: 100%;
  overflow: scroll;
  max-width: calc(100vw - 21rem);

  &.noScroll {
    overflow: hidden;
  }
`)
export const PageContent = styled.div(`
  height: 100%;
  width: 100%;
  padding: 1rem;

  &.flex {
    height: fit-content;
    
    &.justifyCenter {
      justify-content: center;
    }
    &.reports {
      height: 100%;
      justify-content: center;
      max-height: 68rem;
    }
  }
  &.grid {
    display: grid;
    
    &.activeLeads {
      grid-template-rows: 3rem 1fr;
      grid-template-columns: 1fr 16.5rem;
    }
    &.closure {
      grid-template-rows: 8.2rem 0.3rem 3.4rem 2rem 0.5rem 17.2rem 0.5rem 1fr;
      grid-template-columns: 15rem 1rem 2fr;
    }
    &.leadGeneration {
      grid-template-rows: 8.2rem 0.3rem 1fr;
      grid-template-columns: 15rem 1rem 2fr;
    }
    &.members {
      overflow: hidden;
      grid-template-columns: 22rem 1rem 1fr;
      grid-template-rows: 4rem 5.5rem 0.3rem 1fr 3rem;
    }
    &.reports {
      overflow: hidden;
      grid-template-columns: 34rem 1rem 70.8rem 1fr;
      grid-template-rows: 3rem 8rem 1fr;
    }
    &.guide {
      grid-template-columns: 25rem 1rem 1fr 1rem;
      grid-template-rows: 4rem 1fr;
      justify-items: center;
      padding: 1rem 1rem 1rem 3rem;
    }
    &.notifications {
      grid-template-columns: 1fr 15rem;
      grid-template-rows: 3rem 1fr;
    }
    &.settings {
      grid-template-columns: 1fr 1rem 1fr;
      grid-template-rows: 1fr 1rem 1fr 2rem 1fr 1rem 1fr;
    }
  }
`)
//TODO: change grid-template values with 1fr to minmax by establishing minimum widths and heights
export const Footer = styled.div(({ theme }) => `
  height: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.common.grayText};
  margin: auto 0;
  position: absolute;
  bottom: 0;
  border-top: 0.1rem solid ${theme.palette.common.grayBorder};
  box-shadow: 0rem -0.1rem 1rem ${theme.palette.common.grayText};
`)
export const NavBarContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 2;
    display: grid;
    grid-template-columns: 1fr 10rem;
    align-items: center;
    justify-items: center;
    background-color: ${theme.panel.background.primary};
    border-bottom: 0.3rem solid ${theme.panel.border.primary};
`)
export const SideBarContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    grid-row: 1/4;
    grid-column: 1;
    display: grid;
    grid-template-rows: 4.5rem 49.2rem 1fr;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    background: #fff;
    background-color: ${theme.panel.background.tertiary};
    z-index: 100;
`)
export const ComponentContainer = styled.div(({ theme }) => `
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: 0.5rem 1fr 0.5rem;
  grid-template-rows: 1.5rem 1fr 0.5rem;
  justify-content: center;
  align-items: center;
  background: ${theme.component.background.primary};
  border: 0.1rem solid ${theme.component.border.primary};
  border-radius: 1rem;
  overflow: hidden;

  &.detailsContainer {
    background: ${theme.component.background.secondary};
  }
  &.thinBorders {
    grid-template-columns: 0.5rem 1fr 0.5rem;
    grid-template-rows: 1rem 1fr 0.5rem;
  }
  &.fitWindow {
    height: 100%;
  }
  &.first {
    grid-row: 1;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.alignStart {
    align-items: flex-start;
  }
  &.second {
    grid-row: 2;
    margin: 1rem auto;
  }
  &.overviewStats {
    grid-column: 3;
  }
  &.noBorder {
    border: none;
  }
  &.scroll {
    overflow: scroll;
  }
`)
export const ComponentWrapper = styled.div(`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.grid {
    grid-column: 2;
    display: grid;
    height: fit-content;
    grid-template-columns: 1fr;
  }
  &.first {
    grid-row: 2;
    grid-template-rows: 3rem 1fr;
  }
  &.second {
  }
  &.third {
  }
  &.fourth {
  }
`)
export const ComponentHeader = styled.div(({ theme }) => `
  font-size: 1.4rem;
  color: ${theme.palette.common.grayText};
  font-weight: 600;
  padding: 0.9rem 1.3rem;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-top: 0.1rem solid ${theme.palette.common.grayText};
  border-bottom: 0.2rem solid ${theme.palette.common.grayText};
  background: ${theme.palette.common.grayBorderMedium};
  justify-content: center;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif;
  
  &.subHeader {
    background: ${theme.palette.common.grayBorderLight};
    border-bottom: 0.2rem solid ${theme.palette.common.grayBorderDark};
  }
  &.green {
    border-bottom: 0.2rem solid ${theme.palette.primary.dark};
    padding: 0.2rem 0 0 3.5rem;
    color: ${theme.palette.primary.main}; 
    background: ${theme.palette.primary.labelTransparent}; 
    padding: 0.9rem 3.7rem 0.9rem 0;

    &.subHeader {
      border-top: 0.1rem solid ${theme.palette.primary.dark};
    }
  }
  &.error {
    font-weight: 400;
    color: ${theme.palette.common.redDark};
    border-top: 0.1rem solid ${theme.palette.common.redDark};
    border-bottom: 0.2rem solid ${theme.palette.common.redDark};
    background: ${theme.palette.common.redTransparent};
    padding: 0.9rem 3.7rem 0.9rem 0;

    &.subHeader {
      border-top: 0.1rem solid ${theme.palette.common.redDark};
    }
  }
  &.caution {
    font-weight: 400;
    color: ${theme.palette.common.orangeDark};
    border-top: 0.1rem solid ${theme.palette.common.orangeDark};
    border-bottom: 0.2rem solid ${theme.palette.common.orangeDark};
    background: ${theme.palette.common.orangeTransparent};
    padding: 0.9rem 3.7rem 0.9rem 0;
    text-transform: uppercase;
  }
  &.listSection {
    background: ${theme.palette.common.grayOpaque};
    border-top: 0.1rem solid ${theme.palette.common.grayBorderDark};
    border-bottom: 0.1rem solid ${theme.palette.common.grayBorderDark};
    color: ${theme.palette.common.black};
  }
  &.gray {
    color: ${theme.palette.common.grayText}; 
  }
  &.fontLarge {
    font-size: 2.0rem;
  }
  &.colorSecondary {
    color: ${theme.palette.common.grayText};
  }
  &.colorLight {
    color: ${theme.palette.common.grayBorder};
  }
  &.section {
    width: fit-content;
    padding: 5rem 1rem 0 1rem;
    border-bottom: none;
    font-family: exo-mediumItalic;
    color: ${theme.palette.common.grayText};
  }
  &.bottom {
    padding: 0 0 2rem 0;
  }
  &.top {
    padding: 0 1rem;
  }
  &.main {
    display: flex;
    grid-row: 1;
    grid-column: 2;
    align-items: center;
  }
  &.list {
    border-bottom: none;
    font-size: 1.0rem;
    font-weight: 400;
  }
  &.success {
    border-bottom: 0.1rem solid ${theme.palette.primary.main};
  }
  &.failed {
    border-bottom: 0.1rem solid ${theme.palette.common.orange};
  }
  &.areaTitle {
    border-top: none;
    border-bottom: none;
    padding: 0.2rem 0 0 3.5rem;
    font-size: 2rem;
    color: ${theme.text.subHeader.primary};
    justify-content: flex-start;
    background: none;
  }
  &.modal {
    justify-content: flex-start;
    border-top: none;
    font-size: 2rem;
    align-self: flex-start;
    height: 100%;
    font-family: exo-bold;

    &.red {
      font-size: 1.2rem;
      text-transform: none;
      font-weight: 400;
      color: ${theme.palette.common.redDark};
      border-top: 0.1rem solid ${theme.palette.common.redDark};
      border-bottom: 0.2rem solid ${theme.palette.common.redDark};
      background: ${theme.palette.common.redTransparent};
      padding: 0.9rem 3.7rem 0.9rem 0;

      &.subHeader {
        border-top: 0.1rem solid ${theme.palette.common.redDark};
      }
    }
    &.main {
      border-right: 0.05rem solid ${theme.palette.common.grayText};
    }
    &.timeline {
      grid-column: 4;
      padding: 1rem 0 0 2rem;
      border-left: 0.1rem solid ${theme.palette.common.grayText};
      box-shadow: 0 0 0.5rem ${theme.palette.common.grayText};
      z-index: 1;
    }
  }
  &.noTransform {
    text-transform: none;
  }
`)
export const HeaderText = styled.div(({ theme }) => `
  font-size: 1.4rem;
  width: fit-content;
  color: ${theme.palette.common.grayText};
  display: flex;

  &.chart {
    margin: 0 auto;
    font-size: 1.6rem;
    padding: 0 0 0 2rem;
  }
  &.center {
    justify-content: center;
  }
  &.borderRight {
    border-right: 0.1rem solid ${theme.palette.common.grayBorder};
  }
  &.main {
    font-size: 2rem;
    font-weight: 600;
    color: ${theme.palette.common.black};
  }
  &.noPad {
    padding: 0
  }
  &.center {
    justify-content: center;
  }
  &.sub {
    padding: 0 0 0 2%;
  }
  &.green {
    color: ${theme.palette.primary.main}; 
  }
  &.orange {
    color: ${theme.palette.common.orange}; 
  }
  &.red {
    color: ${theme.palette.common.red}; 
  }
  &.gray {
    color: ${theme.palette.common.grayText}; 
  }
  &.modal {
    margin: 1rem 0 0.5rem 0.5rem;
  }
  &.small {
    font-size: 1.4rem;
    align-self: flex-end;
    font-weight: 300;
    margin: 0 0 0 1rem;
  }
`)

export const ScrollWindow = styled.div(({ theme }) => `
  height: 100%;
  max-height: fit-content;
  overflow: auto;  
  max-width: 100%;

  &.shadow {
    box-shadow: 0rem 0rem 2rem ${theme.palette.common.grayText};
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.fitContentHeight {
    height: fit-content;
  }
  &.fitContentWidth {
    width: fit-content;
  }
  &.report {
    grid-row: 1/4;
    grid-column: 3;
    padding: 0.4rem 0.4rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &.horizontalOnly {
    overflow-x: auto;
    overflow-y: hidden;
  }
  &.verticalOnly {
    overflow-x: hidden;
    overflow-y: auto;
  }
  &.column {
    display: flex;
    flex-direction: column;
  }
  &.guide {
    overflow-x: hidden;
    overflow-y: auto;
    grid-column: 3;
    flex-direction: column;
    padding: 0.5rem 0.5rem 2.5rem 0.5rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-height: calc(100vh - 19rem);
    background: ${theme.palette.common.cardBackground};
  }
  &.config {
    max-height: calc(100vh - 31.8rem);
  }
  &.configParamTags {
    max-height: calc(100vh - 28rem);
  }
  &.configParams {
    max-height: calc(100vh - 28.2rem);
  }
  &.configTags {
    height: calc(100vh - 29.7rem);
    max-height: calc(100vh - 29.7rem);
  }

  ::-webkit-scrollbar-thumb {
    border: 0.1rem solid ${theme.palette.common.grayText};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    border: 0.05rem solid ${theme.palette.common.grayBorderDark};
    background-color: ${theme.palette.common.pageBackground};
  }
  &.show {
    ::-webkit-scrollbar {
      display: block;
    }
  }
  ::-webkit-scrollbar:vertical {
    width: 0.4rem;
  }
  ::-webkit-scrollbar:horizontal {
    height: 0.4rem;
  }
`)
export const RowsContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: fit-content;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    background: ${theme.component.background.secondary};
    
    &.noBackground {
      background: none;
    }
    &.noBorder {
      background-image: none;
      background-clip: border-box;
    }
    &.noScroll {
      overflow: hidden;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 0.1rem solid ${theme.palette.common.grayText};
      background-color: ${theme.palette.common.grayText};
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
      width: 0.5rem;
    }
    ::-webkit-scrollbar:horizontal {
      width: 0rem;
    }
`)
export const ComponentSectionRow = styled.div(({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: fit-content;

  &.caution {
    background: ${theme.palette.common.orangeTransparent};
  }
  &.evenDark {
    background: ${theme.palette.common.grayBorderLight};
    
    &.caution {
      background: ${theme.palette.common.orangeMediumTransparent};
    }
    &.error {
      background: ${theme.palette.common.errorRowDark};
    }
  }
  &.hover {
    cursor: pointer;
    &.hoverActivate {
      color: ${theme.palette.common.grayBorder};
    }

    &:hover {
      background: ${theme.accent.success.primaryFullTransparent};

      &.hoverActivate {
        color: ${theme.palette.primary.main};
      }
      &.caution {
        background: ${theme.palette.common.orangeTransparentHover};
      }
    }    
    &.even {
      &:hover {
        background: ${theme.accent.success.primaryFullTransparent};
        
        &.caution {
          background: ${theme.palette.common.orangeMediumTransparentHover};
        }
      }
    }
    &.evenDark {
      &:hover {
        background: ${theme.accent.success.primaryTransparent};
        
        &.caution {
          background: ${theme.palette.common.orangeMediumTransparentHover};
        }
      }
    }
  }
  &.noPointHover {
    cursor: default;

    &.even {
      &:hover {        
        &.caution {
          background: ${theme.palette.common.orangeTransparentHover};
        }
      }
    }
    &.evenDark {
      &:hover {        
        &.caution {
          background: ${theme.palette.common.orangeMediumTransparentHover};
        }
      }
    }

    &:hover {
      background: ${theme.accent.success.primaryFullTransparent};      

      &.caution {
        background: ${theme.palette.common.orangeMediumTransparentHover};
      }
    }
  }
  &.list {
    &:hover {
      background: ${theme.accent.success.primaryFullTransparent};
    }
    &.even {
      background: ${theme.palette.common.grayBorderLight};
    }
    &.evenDark {
      background: ${theme.palette.common.grayBorderMedium};
    }
    &.odd {
      background: ${theme.component.background.primary};
    }
  }
  &.reportBody {
    flex-direction: column;
    background: ${theme.report.background.primary};
    padding: 1rem 3.5rem 3.5rem 3.5rem;

    &.section {
      padding: 1rem 0;
      border-bottom: 0.1rem solid ${theme.report.border.primary};
    }
  }
  &.alignStart {
    align-items: flex-start;
  }
  &.justifyCenter {
    justify-content: center;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.column {
    flex-direction: column;
  }
  &.reportHead {
    padding: 1rem 0 0.5rem 0;
    align-items: stretch;
    background: ${theme.report.background.secondary};
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.error {
    background: ${theme.palette.common.errorRow};
  }
  &.caution {
    background: ${theme.palette.common.orangeTransparent};
  }
  &.header {
    border-top: 0.1rem solid ${theme.palette.common.grayText};
    border-bottom: 0.2rem solid ${theme.palette.common.grayText};
    background: ${theme.palette.common.grayBorderMedium};
    
    &.subHeader {
      background: ${theme.palette.common.grayBorderLight};
      border-bottom: 0.2rem solid ${theme.palette.common.grayBorderDark};
    }
    &.section {
      border-top: 0.2rem solid ${theme.palette.common.grayText};
      background: ${theme.palette.common.grayOpaque};
    }
    &.thinBorder {
      border-bottom: 0.1rem solid ${theme.palette.common.grayText};
    }
  }
  &.bottom {
    padding: 0 1rem 1rem 1rem;
  }
  &.even {
    background: ${theme.component.background.primary};
    &.error {
      background: ${theme.palette.common.errorRowDark};
    }
  }
  &.gray {
    background: ${theme.palette.common.grayBorder};
  }
  &.twoColumn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.5rem 4rem 1fr;
    width: 100%;
  }
  &.parameterTags {
    display: grid;
    grid-template-columns: 5rem 22rem 5rem repeat(auto-fit, minmax(7rem,10rem));
    justify-content: flex-start
  }
  &.uploadSuccess {
    display: grid;
    grid-template-columns: 12rem 1fr 1fr;
  }
  &.uploadErrors {
    display: grid;
    grid-template-columns: 5rem 8rem 1fr;
  }
  &.actionsAndErrorsLog {
    display: grid;
    grid-template-columns: 5rem 4rem 16rem 20rem 10rem 25rem 1fr;
  }
  &.sweepFailures {
    display: grid;
    grid-template-columns: 4rem 8rem 4rem 12rem 20rem 1fr;
  }
  &.recordSweeps {
    width: 100%;
    display: grid;
    grid-template-columns: 4rem 10rem 6rem 5.5rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 3rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 4rem 9rem 1fr;
    padding: 0.3rem 0;
    
    &.clientView {
      grid-template-columns: 4rem 10rem 6rem 5.5rem 4rem 4rem 4rem 4rem 4rem 4rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 5rem 9rem 1fr;
    }
  }
  &.duplicateCheck {
    display: grid;
    grid-template-columns: 4rem 14rem 1fr;
  }
  &.adminRepository {
    display: grid;
    grid-template-columns: 1fr 19rem 26rem 23rem 1fr;
  }
  &.listMembers {
    display: grid;
    grid-template-columns: 1fr 10rem 30rem 30rem 1fr;
    &.closed {
      grid-template-rows: 2.5rem 0;
      transition: grid-template-rows 0.7s ease-out;
      &.open {
        grid-template-rows: 2.5rem 5rem;
      }
    }
  }
  &.mortgageSchedules {
    display: grid;
    grid-template-columns: 7rem 30rem 1fr;
    &.closed {
      grid-template-rows: 2.5rem 0;
      transition: grid-template-rows 0.7s ease-out;
      &.open {
        grid-template-rows: 2.5rem 5rem;
      }
    }
  }
  &.addTags {
    display: grid;
    grid-template-columns: 1fr;
    border-top: 0.3rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 0.8rem ${theme.palette.common.grayBorderDark};
    
    &.closed {
      grid-template-rows: 9.3rem 0;
      transition: grid-template-rows 0.7s ease-out;

      &.open {
        grid-template-rows: 9.3rem 25rem;
      }
    }
  }
  &.hidden {
    z-index: -1;
    font-size: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    max-height: 0;
    padding: 0;
    transition: opacity .20s,
    font-size .20s,
    margin .20s,
    padding .20s; 
  }
  &.onDisplay {
    z-index: 1;
    transition: 
      font-size .50s,
      margin .30s .50s,
      padding .30s .50s,
      opacity .30s .50s
    ;
  }
  &.heightHidden {
    z-index: -1;
    opacity: 0;
    height: 1rem;
    transition: height 0.6s, z-index 0.30s, opacity 1s;
  }
  &.heightOnDisplay {
    z-index: 1;
    opacity: 1;
    height: 10rem;
    transition: height 1s, z-index 0.30s, opacity 1s;
  }
`)
export const Typography = styled.div(({ theme }) => `
  display: flex;
  align-items: flex-end;
  margin: 0;
  z-index: 10;
  font-family: -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 400;

  &.column {
    flex-direction: column;
  }
  &.justifyBetween {
    justify-content: space-between;
  }
  &.hidden {
    z-index: -1;
    font-size: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    max-height: 0;
    padding: 0;
    transition: 
      font-size .50s,
      padding .30s .50s,
      opacity 0s
    ;
  }
  &.onDisplay {
    z-index: 1;
    transition:
      font-size .50s,
      padding .30s .50s,
      opacity 0s
    ;
  }
  &.underline {
    text-decoration: underline;
  }
  &.noTextWrap {
    text-wrap: nowrap;
  }
  &.flexWrap {
    flex-wrap: wrap;
  }
  &.fitContentHeight {
    height: fit-content;
  }
  &.alignTextCenter {
    align-text: center;
  }
  &.sans {
    font-family: Gill Sans, sans-serif;
  }
  &.justifyCenter {
    justify-content: center;
  }
  &.outcome {
    font-weight: 600;
    font-size: 1.8rem;
    text-decoration: underline;
    text-decoration-thickness: 0.2rem;
  }
  &.refinance {
    text-decoration-color: ${theme.palette.primary.main};
  }
  &.renegotiation {
    text-decoration-color: ${theme.closure.renegotiation.primary};
  }
  &.accent {
    color: ${theme.palette.common.grayText};
    font-style: italic;
  }
  &.italic {
    font-style: italic;
  }
  &.subHeader {
    font-size: 1.8rem;
    color: ${theme.text.subHeader.primary};
    
    &.dark {
      color: ${theme.text.subHeader.dark};
    }
  }
  &.wrapped {
    margin: 0 0.5rem 0 0;
    padding: 0 1rem;
    font-weight: 600;
    border-radius: 1rem;
    border: 1px solid ${theme.palette.common.cardBackground};
    color: ${theme.palette.primary.dark};
    border: 0.1rem solid ${theme.palette.primary.medium};
  }
  &.wrappedRed {
    margin: 0 0.5rem 0 0;
    padding: 0 1rem;
    font-weight: 600;
    border-radius: 1rem;
    border: 1px solid ${theme.palette.common.cardBackground};
    color: ${theme.palette.common.redDark};
    border: 0.1rem solid ${theme.palette.common.red};
  }
  &.autoMarginVertical {
    margin-top: auto;
    margin-bottom: auto;
  }
  &.autoMarginHorizontal {
    margin-left: auto;
    margin-right: auto;
  }
  &.alignCenter {
    align-items: center;
  }
  &.alignStart {
    align-items: flex-start;
  }
  &.alignTextCenter {
    text-align: center;
  }
  &.justifyEnd {
    justify-content: flex-end;
  }
  &.justifyAround {
    justify-content: space-around;
  }
  &.italics {
    font-style: oblique;
  }
  &.thin {
    font-weight: 300;
  }
  &.normal {
    font-weight: 400;
  }
  &.bold {
    font-weight: 600;
  }
  &.tiny {
    font-size: 1rem;
  }
  &.small {
    font-size: 1.2rem;
  }
  &.medium {
    font-size: 1.4rem;
  }
  &.large {
    font-size: 1.6rem;
  }
  &.xLarge {
    font-size: 1.8rem;
  }
  &.tinyHeight {
    height: 1.5rem;
    min-height: 1.5rem;
    align-items: center;
  }
  &.smallHeight {
    height: 1.8rem;
    min-height: 1.8rem;
    align-items: center;
  }
  &.largeHeight {
    height: 2.4rem;
    min-height: 2.4rem;
    align-items: center;
  }
  &.xLargeHeight {
    height: 2.7rem;
    min-height: 2.7rem;
    align-items: center;
  }
  &.xxLargeHeight {
    height: 3rem;
    min-height: 2.7rem;
    align-items: center;
  }
  &.xxLarge {
    font-size: 2rem;
  }
  &.xxxLarge {
    font-size: 2.4rem;
  }
  &.xxxLargeHeight {
    height: 3.6rem;
  }
  &.fitCOntentHeight {
    height: fit-content;
  }
  &.componentHeader {
    font-size: 1.4rem;
    color: ${theme.text.header.component};
    font-family: apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif;
  }
  &.guideHeader {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${theme.text.header.component};
    font-family: apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", 'Inter', "Helvetica Neue", sans-serif;
  }
  &.primary {
    color: ${theme.text.primary.primary};
  }
  &.secondary {
    color: ${theme.text.secondary.primary};
  }
  &.tertiary {
    color: ${theme.text.tertiary.primary};
  }
  &.tertiaryDark {
    color: ${theme.text.tertiary.secondary};
  }
  &.quaternary {
    color: ${theme.accent.quaternary.primary};
  }
  &.success {
    color: ${theme.accent.success.primary};
  }
  &.caution {
    color: ${theme.accent.caution.primary};
  }
  &.error {
    color: ${theme.accent.error.primary};
  }
  &.required {
    color: ${theme.text.required.primary};
  }
  &.info {
    color: ${theme.palette.common.grayText};
  }
  &.header {
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.palette.common.grayText};

    &.sectionTitle {
      margin: 0 0 1rem 0;
    }
  }
  &.indent {
    padding-left: 0.5rem;
  }
  &.dark {
    color: ${theme.palette.common.black};
  }
  &.exoItalic {
    font-family: exo-mediumItalic;
  }
  &.exoBold {
    font-family: exo-bold;
  }
  &.medium {
    font-size: 1.4rem;
  }
  &.fader {
    animation: typeographyFader linear infinite 2s;
    @keyframes typeographyFader {
      0% {
        opacity: 0.4;
      }
      50% {
        opacity: 0.9;
      }
      100% {
        opacity: 0.4;
      }
    }
    &.primary {
      animation: typeographyFaderPrimary linear infinite 1s;
      animation-direction: alternate-reverse;
      @keyframes typeographyFaderPrimary {
        0% {
          color: ${theme.palette.common.grayText};
        }
        100% {
          color: ${theme.palette.primary.main};
        }
      }
    }
  }
`)
export const SelectionContainer = styled.div(`
  width: 100%;
  padding: 0 5%;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem,1fr));
`)
export const Button = styled.button(
  ({ theme }) => `
    height: 3rem;
    width: 10rem;
    margin: 1rem 0.5rem;
    align-self: center;
    outline: none;
    border: none;
    border-radius: 1rem;
    font-size: 1.4rem;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;

    &.round {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 2.5rem;
    }
    &.small {
      height: 1.5rem;
      width: 1.5rem;
      margin: 0;
    }
    &.blank {
      height: fit-content;
      width: fit-content;
      margin: 0;
      outline: none;
      border: none;
      background: none;
    }
    &.blank:hover {
      cursor: pointer;
    }
    &.fit {
      width: fit-content;
    }
    &.dismiss {
      color: ${theme.button.text.dismiss};
      border: 0.1rem solid ${theme.button.border.dismiss};
      background-color: ${theme.button.background.dismiss};

      :hover {
        color: ${theme.palette.common.white};
        border: 0.1rem solid ${theme.button.border.dismissHover};
        background-color: ${theme.button.background.dismissHover};
      }
      :focus {
        color: ${theme.button.text.dismissFocus};
        border: 0.05rem solid ${theme.button.border.dismissFocus};
        background-color: ${theme.button.background.dismissFocus};
        box-shadow: 0 0 0.5rem ${theme.button.shadow.dismissFocus};
      }
    }
    &.dismissFocus {
      color: ${theme.button.text.dismissFocus};
      border: 0.05rem solid ${theme.button.border.dismissFocus};
      background-color: ${theme.button.background.dismissFocus};
      box-shadow: 0 0 0.5rem ${theme.button.shadow.dismissFocus};
    }
    &.dismissLoading {
      background-color: ${theme.palette.secondary.dark};
      color: ${theme.palette.common.grayBorder};
    }
    &.dismissDisabled {
      color: ${theme.button.text.dismissDisabled};
      border: 0.05rem solid ${theme.button.border.dismissDisabled};
      background-color: ${theme.button.background.dismissDisabled};

      :hover {
        cursor: not-allowed;
      }
    }
    &.neutral {
      color: ${theme.button.text.neutral};
      border: 0.05rem solid ${theme.button.border.neutral};
      background-color: ${theme.button.background.neutral};
      
      :hover {
        color: ${theme.button.text.neutralHover};
        border: 0.05rem solid ${theme.button.border.neutralHover};
        background-color: ${theme.button.background.neutralHover};
      }
      :focus {
        color: ${theme.button.text.neutralFocus};
        border: 0.05rem solid ${theme.button.border.neutralFocus};
        background-color: ${theme.button.background.neutralFocus};
        box-shadow: 0 0 0.5rem ${theme.button.shadow.neutralFocus};
      }
    }
    &.neutralDisabled {
      color: ${theme.button.text.neutralDisabled};
      border: 0.05rem solid ${theme.button.border.neutralDisabled};
      background-color: ${theme.button.background.neutralDisabled};

      :hover {
        cursor: not-allowed;
      }
    }
    &.neutralLoading {
      color: ${theme.button.text.neutralLoading};
      border: 0.05rem solid ${theme.button.border.neutralLoading};
      background-color: ${theme.button.background.neutralLoading};

      :hover {
        cursor: wait;
      }
    }
    &.primary {
      color: ${theme.button.text.primary};
      border: 0.05rem solid ${theme.button.border.primary};
      background-color: ${theme.button.background.primary};
      
      :hover {
        color: ${theme.button.text.primaryHover};
        border: 0.05rem solid ${theme.button.border.primaryHover};
        background-color: ${theme.button.background.primaryHover};
      }
      :focus {
        color: ${theme.button.text.primaryFocus};
        border: 0.05rem solid ${theme.button.border.primaryFocus};
        background-color: ${theme.button.background.primaryFocus};
        box-shadow: 0 0 0.5rem ${theme.button.shadow.primaryFocus};
  
        :hover {
          color: ${theme.button.text.primaryFocusHover};
          border: 0.05rem solid ${theme.button.border.primaryFocusHover};
        }
      }
    }
    &.primaryActive {
      color: ${theme.button.text.primaryFocus};
      border: 0.05rem solid ${theme.button.border.primaryFocus};
      background-color: ${theme.button.background.primaryFocus};
      box-shadow: 0 0 0.5rem ${theme.button.shadow.primaryFocus};

      :hover {
        border: 0.05rem solid ${theme.button.border.primaryFocusHover};
        
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
    &.primaryDisabled {
      color: ${theme.button.text.primaryDisabled};
      border: 0.05rem solid ${theme.button.border.primaryDisabled};
      background-color: ${theme.button.background.primaryDisabled};

      :hover {
        cursor: not-allowed;
      }
      :focus {
        border: 0.05rem solid ${theme.button.border.primaryDisabledFocus};
        background-color: ${theme.button.background.primaryDisabledFocus};
        box-shadow: 0 0 0.5rem ${theme.button.shadow.primaryDisabledFocus};
      }
    }
    &.primaryLoading {
      color: ${theme.button.text.primaryLoading};
      border: 0.05rem solid ${theme.button.border.primaryLoading};
      background-color: ${theme.button.background.primaryLoading};

      :hover {
        cursor: wait;
      }
    }
    &.caution {
      color: ${theme.button.text.caution};
      border: 0.05rem solid ${theme.button.border.caution};
      background-color: ${theme.button.background.caution};
      
      :hover {
        color: ${theme.button.text.caution};
        border: 0.05rem solid ${theme.button.border.cautionHover};
        background-color: ${theme.button.background.cautionHover};
      }
    }
    &.caution:focus {
      color: ${theme.button.text.caution};
      border: 0.05rem solid ${theme.palette.common.white};
      background-color: ${theme.button.background.cautionFocus};
      box-shadow: 0 0 0.5rem ${theme.button.background.caution};

      :hover {
        color: ${theme.button.text.caution};
        background-color: ${theme.button.background.cautionHover};
            }
    }
    &.cautionDisabled {
      color: ${theme.button.text.cautionDisabled};
      border: 0.05rem solid ${theme.button.border.cautionDisabled};
      background-color: ${theme.button.background.cautionDisabled};

      :hover {
        cursor: not-allowed;
      }
    }
    &.cautionLoading {
      color: ${theme.button.text.primaryLoading};
      border: 0.05rem solid ${theme.button.border.primaryLoading};
      background-color: ${theme.button.background.primaryLoading};

      :hover {
        cursor: wait;
      }
    }
    &.delete {
      background-color: ${theme.palette.common.red};
      color: ${theme.palette.common.grayBorder};
      border: 0.05rem solid ${theme.palette.common.red};

      :focus {
        background-color: ${theme.palette.common.redBright};
        box-shadow: 0 0 0.5rem ${theme.palette.common.red};
        color: ${theme.palette.common.white};
        border: 0.05rem solid ${theme.palette.common.white};
      }
      :hover {
        background-color: ${theme.palette.common.redBright};
        color: ${theme.palette.common.white};
        border: 0.05rem solid ${theme.palette.common.redDark};
      }
    }
    &.deleteLoading {
      color: ${theme.palette.common.grayBorder};
      background-color: ${theme.palette.common.redDark};

      :hover {
        cursor: wait;
      }
    }
    &.deleteDisabled {
      background-color: ${theme.palette.common.redDark};
      color: ${theme.palette.common.offWhite};
      border: 0.05rem solid ${theme.palette.common.redDark};

      :hover {
        cursor: not-allowed;
      }
    }
    &.secondary {
      color: ${theme.palette.common.black};
      background-color: ${theme.button.background.secondary};

      :focus {
        background-color: ${theme.palette.common.grayButtonFocus};
        box-shadow: 0 0 0.5rem ${theme.palette.common.black};
        border: 0.05rem solid ${theme.palette.common.white};
        color: ${theme.palette.common.black};
      }
      :hover {
        background-color: ${theme.button.background.secondaryHover};
      }
    }
    &.secondaryLight {
      color: ${theme.palette.common.black};
      background-color: ${theme.button.background.secondaryLight};

      :hover {
        background-color: ${theme.button.background.secondaryLightHover};
      }
      :focus {
        background-color: ${theme.button.background.secondaryLightHover};
        box-shadow: 0 0 0.5rem ${theme.button.shadow.neutralFocus};
        border: 0.05rem solid ${theme.button.border.neutralFocus};
        color: ${theme.palette.common.black};
      }
    }
    &.secondaryLoading {
      color: ${theme.palette.common.blackLight};
      background-color: ${theme.palette.common.grayBorderDark};

      :hover {
        cursor: wait;
      }
    }
    &.orange {
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.common.caution};

      :hover {
        background-color: ${theme.palette.common.cautionDark};
      }
    }
    &.secondaryDisabled {
      background-color: ${theme.palette.common.grayBorderDark};
      color: ${theme.palette.common.grayBorder};

      &.thin {
        font-weight: 400;
      }
      :hover {
        cursor: not-allowed;
      }
    }
    &.confirm {
      width: 3rem;
      height: 3rem;
      margin: 0;
      align-self: flex-end;
      border-radius: 0.6rem;
    }
    &.black {
      color: ${theme.palette.common.black};
    }
    &.fit {
      width: fit-content;
    }
    &.notes {
      width: 3rem;
      height: 2.5rem;
      margin: 0;
      align-self: flex-end;
      border-radius: 0.6rem;
    }
    &.input {
      width: 3rem;
      height: 2.5rem;
    }
    &.prompt {
      width: 3rem;
      height: 3rem;
      margin: 0;
      align-self: flex-end;
      border-radius: 0.6rem;
    }
    &.smallMargin {
      margin: 1rem 0;
    }
`)
export const FadeWrapper = styled.div(({ theme }) => `
animation: fadeIn 5s;
-webkit-animation: fadeIn 0.8s;
-moz-animation: fadeIn 5s;
-o-animation: fadeIn 5s;
-ms-animation: fadeIn 5s;

@keyframes fadeIn {
  0% { opacity: 0; }
  40% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  40% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  40% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  40% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  40% { opacity: 0; }
  100% { opacity: 1; }
}
`)
export const GridWrapper = styled.div(({ theme }) => `
  width: 100%;
  display: grid;

  &.twoRow {
    grid-template-rows: 1fr 1fr;
  }
  &.threeColumn {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &.fourColumn {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &.fiveColumn {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  &.sixColumn {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`)
export const InfoBubble = styled.div(({ theme }) => `
  border-radius: 1rem;
  height: fit-content;
  display: flex;
  padding: 0.5rem 1rem;
  font-weight: 600;

  &.primary {
    border: 0.1rem solid ${theme.component.border.primary};
    background: ${theme.component.background.tertiary};
  }
  &.caution {
    background: ${theme.info.background.caution};
    color: ${theme.info.color.caution};
    border: 0.1rem solid ${theme.info.border.caution};
  }
`)
export const Shadow = styled.div(({ theme }) => `
  display: flex;
  width: 100%;
  height: fit-content;
  border-bottom: 0.2rem solid ${theme.palette.common.grayBorder};
  z-index: 1;
  box-shadow: 0 0 1rem ${theme.palette.common.grayText};
`)
export const PageComponent = styled.div(({ theme }) => `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90rem;
  min-height: fit-content;
  border: 0.2rem solid ${theme.palette.common.grayBorder};
`)
export const FlexWrapper = styled.div(({ theme }) => `
  width: 100%;
  min-width: fit-content;
  height: 100%;
  min-height: fit-content;
  display: flex;

  &.hoverPointer {
    &:hover {
      cursor: pointer;
    }
  }
  &.hidden {
    z-index: -1;
    font-size: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    max-height: 0;
    padding: 0;
    transition: 
      font-size .50s,
      margin .30s .50s,
      padding .30s .50s,
      opacity .40s .30s
    ;
  }
  &.onDisplay {
    z-index: 1;
    transition:
      font-size .50s,
      margin .30s .50s,
      padding .30s .50s,
      opacity .40s .30s
    ;
  }

  &.hiddenHeight {
    height: 0;
    max-height: 0;
    transition: 
      height .30s .50s
    ;
  }
  &.onDisplayHeight {
    transition:
      height .30s .50s
    ;
  }
  &.flexWrap {
    flex-wrap: wrap;
  }
  &.shadow {
    box-shadow: 0rem 0rem 1rem ${theme.palette.common.grayText};
    z-index: 1;
  }
  &.alignTextCenter {
    text-align: center
  }
  &.autoMarginVertical {
    margin-top: auto;
    margin-bottom: auto;
  }
  &.autoMarginHorizontal {
    margin-left: auto;
    margin-right: auto;
  }
  &.overflowVerticalContainer {
    height: fit-content;
    min-height: 100%;
    padding: 2rem 4rem;
  }
  &.center {
    margin: 0 auto;
  }
  &.quarterWidth {
    width: 25%;
  }
  &.fitContentWidth {
    width: fit-content;
  }
  &.fitContentHeight {
    height: fit-content;
  }
  &.column {
    flex-direction: column;
  }
  &.alignCenter {
    align-items: center;
  }
  &.alignBetween {
    align-items: space-between;
  }
  &.alignStart {
    align-items: flex-start;
  }
  &.alignEnd {
    align-items: flex-end;
  }
  &.justifyCenter {
    justify-content: center;
  }
  &.justifyAround {
    justify-content: space-around;
  }
  &.justifyBetween {
    justify-content: space-between;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.justifyEnd {
    justify-content: flex-end;
  }
  &.borderBottom {
    border-bottom: 0.1rem solid ${theme.palette.common.grayText};
  }
`)
export const TitleContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.common.white};
    font-size: 1.8rem;
    border-bottom: 0.3rem solid ${theme.panel.border.primary};
    background:  ${theme.panel.background.primary};
`)
export const NavigationContainer = styled.div(({ theme }) => `
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    &.modal {
      background: ${theme.palette.common.grayBorder};
      height: 100%;
      box-shadow: 0 0 1rem ${theme.palette.common.grayTextLight};
      border-right: 0.1rem solid ${theme.palette.common.grayText};
      z-index: 10;
      margin: 0;
    }
`)
export const SideBarNavHeader = styled.div(({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0.5rem 0;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 800;
  padding: 5rem 0 0.3rem 1.5rem;
  margin: 0 0 0 0;
  color: ${theme.panel.background.secondary};
  border-bottom: 0.1rem solid ${theme.panel.border.secondary};
  border-right: 0.1rem solid ${theme.panel.border.secondary};
  
  &.modal {
    color: ${theme.palette.common.grayText};
    border-right: none;
    padding: 2.2rem 0 0.3rem 1.5rem;
    margin: 3rem 0 0 0;
    border-bottom: 0.2rem solid ${theme.panel.border.secondary};
  }
`)
export const NavIndex = styled.p(({ theme }) => `
  margin: 0.4rem 0;
  width: 3.5rem;
  font-size: 1.2rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;

  &.user {
    color: ${theme.icon.primary.primary};
  }
  &.admin {
    color: ${theme.icon.secondary.primary};
  }
  &.subNav {
    font-weight: 500;

    &.active {
      color: ${theme.subNav.text.active};
    }
  }
  &.disabled {
    color: ${theme.palette.common.grayText}
  }
`)
export const NavText = styled.p(({ theme }) => `
  margin: 0.4rem 0;
  font-size: 1rem;
  font-weight: 500;

  &.main {
    color: ${theme.palette.common.black};
  }
  &.label {
    color: #00940F;
  }
  &.disabled {
    color: ${theme.palette.common.grayBorderDark}
  }
`)
export const PieChartContainer = styled.div(
  ({ theme }) => `
    grid-column: 1;
    grid-row: 1/5;
    width: 100%;
    height: 100%;
    border: 0.1rem solid ${theme.palette.common.grayBorder};
    background-color: ${theme.palette.common.cardBackground};
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.actionType {
      grid-row: 1;
    }
    &.tier1 {
      grid-row: 3;
    }
    &.tier2 {
      grid-row: 5;
    }
    &.tier3 {
      grid-row: 7;
    }
`)
export const HeaderContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 2.5rem;
  font-weight: 600;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.palette.common.black};
  border-bottom: 0.1rem solid ${theme.palette.common.grayText};
  background: ${theme.palette.common.grayBorder};

  &.alignEnd {
    align-items: flex-end;
  }
  &.page {
    height: fit-content;
    background: ${theme.header.background.primary};
    border-bottom: 0.2rem solid ${theme.palette.common.grayBorder};
    padding: 0;
  }
  &.guide {
    height: 3.5rem;
    padding: 0.5rem 1.5rem 0 1.5rem;
    justify-content: flex-start;
    background-color: ${theme.header.background.primary};
    border-bottom: 0.3rem solid ${theme.palette.common.grayBorder};
  }
  &.notifications {
    height: 3.5rem;
    padding: 0.5rem 1.5rem 0 1.5rem;
    justify-content: flex-start;
    background-color: ${theme.header.background.dark};
    border-bottom: 0.3rem solid ${theme.palette.common.grayBorderDark};
  }
  &.reports {
    height: 4rem;
    padding: 0.5rem 0 0 1.5rem;
    justify-content: flex-start;
    background-color: ${theme.header.background.primary};
    border-bottom: 0.3rem solid ${theme.palette.common.grayBorder};
  }
  &.chart {
    padding-left: 2rem;
    height: 3.5rem;
    display: grid;
    grid-template-columns: 22rem 1fr 22rem;
    justify-content: flex-start;
  }
  &.modal {
    height: 4rem;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 0.1rem solid ${theme.palette.common.grayText};

    &.actionsModal {
      grid-row: 1/3;
      grid-column: 3;
    }
  }
`)
export const HorizontalContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: fit-content;
    display: flex;
    overflow: scroll;
    
    &.grid {
      display: grid;
      
      &.notificationSchedules {
        grid-template-columns: 4rem 20rem 20rem 1fr;
      }
      &.uploadStats {
        width: fit-content;
        grid-template-columns: 6rem 5rem 20rem 10rem 10rem;
      }
      &.uploadErrors {
        grid-template-columns: 6rem 6rem 1fr;
      }
    }
    &.fitContent {
      height: fit-content;
    }
    &.targetFormGrid {
      align-items: center;
      justify-content: center;
      gap: 3rem;
      padding: 0 4rem;
      height: fit-content;
      grid-row: 2;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 5rem 4.5rem;
      column-gap: 3rem;
      row-gap: 0;
    }
  }
`)
export const VerticalContainer = styled.div(({ theme }) => `
  height: 100%;
  max-width: 100%;
  width: 100%;
  min-width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  gap: 0.5rem;

  &.noGap {
    gap: 0;
  }
  &.fitContent {
    height: fit-content;
  }
  &.breakdown {
    justify-content: center;
  }
`)
export const ColumnContent = styled.div(({ theme }) => `
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.header {
    height: 100%;
    padding: 0.6rem;
    text-transform: uppercase;
    font-weight: 600;
    color: ${theme.palette.common.grayText};
    justify-content: flex-start;

    &.large {
      font-size: 1.4rem;
    }
    &.section {
      font-size: 1.2rem;
      grid-column: 3/5;
    }
    &.center {
      justify-content: center;
    }
  }
  &.detail {
    font-size: 1.3rem;
    text-wrap: pretty;
    text-align: center;
    text-transform: capitalize;
    flex-wrap: wrap;
    height: 100%;

    &.small {
      font-size: 1.0rem;
    }
  }
  &.lowercase {
    text-transform: none;
  }
  &.thin {
    font-weight: 300;
  }
  &.even {
    background: ${theme.palette.common.lightGray};
  }
  &.single {
    height: 100%;
    text-align: center;
  }
  &.bottomBorder {
    border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
    padding: 0 0.3rem;
  }
  &.gray {
    color: ${theme.palette.common.grayText};
  }
  &.breakdown {
    font-size: 2.4rem;
    font-weight: 600;
    color: ${theme.palette.common.black};
    height: fit-content;
  }
  &.breakdownSmall {
    font-weight: 600;
    color: ${theme.palette.common.black};
    height: 100%;
    flex-direction: column;
  }
  &.sub {
    height: fit-content;
  }
  &.highlight {
    font-size: 2.4rem;
    font-weight: 600;
    color: ${theme.palette.primary.main};
    flex-direction: column;
    height: 100%;

    &.refinance {
      font-size: 1.8rem;
    }
    &.profit {
      font-size: 1.4rem;
    }
  }
  &.secondary {
    color: ${theme.palette.secondary.main};
  }
  &.renegotiations {
    color: ${theme.closure.renegotiation.primary};
  }
  &.column {
    flex-direction: column;
  }
  &.quarter {
    justify-content: flex-start;
    font-size: 1.6rem;
    font-weight: 600;
    height: 3rem;
    overflow: hidden;
    padding: 0 0 0 1rem;
  }
`)
export const MainContent = styled.div(
  ({ theme }) => `
    font-size: 1.3rem;
    text-wrap: pretty;
    text-align: center;
    text-transform: capitalize;
    
    &.large {
      font-size: 1.6rem;
    }
`)
export const SubContent = styled.div(
  ({ theme }) => `
    font-size: 1.0rem;
    margin: 0 0 0 0.5rem;
`)
export const MainChartWrapper = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 90%;
    grid-row: 3;
    grid-column: 1;
    padding-top: 1rem;
    border-top: none;
    border-radius: 0 0 .5rem .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`)
export const ListContainer = styled.div(({ theme }) => `
  grid-column: 1;
  grid-row: 2;
  height: 100%;
  width: 100%;
  display: block;
  padding: 0 2rem 0 1rem;
  overflow: scroll;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
`)
export const CardListHeader = styled.div(({ theme }) => `
  grid-row: 1;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 10rem 1fr;
  text-wrap: nowrap;
  align-items: center;
  padding: 0 0 0 2rem;
  font-size: 1.6rem;
  border-bottom: 0.3rem solid ${theme.palette.common.grayBorder};
  color: ${theme.palette.common.grayText};

  &.reports {
    display: flex;
    height: fit-content;
    padding: 0.5rem 0 0 1rem;
    background-color: ${theme.header.background.primary};
  }
  &.flex {
    display: flex;
  }
`)
export const CardListDetail = styled.div(({ theme }) => `
  height: 100%;
  font-size: 1.2rem;
  color: ${theme.palette.common.grayText};
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.3rem;
`)
export const CardListContainer = styled.div(({ theme }) => `
  grid-column: 1;
  grid-row: 2;
  height: 100%;
  width: 100%;
  display: block;
  padding: 0 2rem;
  overflow: scroll;

  &.reports {
    grid-column: 4;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    margin: 0 0 6rem 0;
  }
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  &.reportsLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    grid-column: 2;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
`)
export const CardControlsContainer = styled.div(
  ({ theme }) => `
    grid-column: 2;
    grid-row: 1/3;
    height: 100%;
    width: 100%;
    border-left: 0.1rem solid ${theme.palette.common.grayBorder};
    padding: 1rem;
`)
export const CardContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    min-width: 95rem;
    height: fit-content;
    display: flex;
    border: 0.1rem solid ${theme.palette.common.grayBorderDark};
    background: ${theme.palette.common.cardBackground};
    border-radius: 1rem; 
    margin: 1rem 0;
    padding: 0.5rem;
    transition: box-shadow 0.3s ease;

    &.singleRow {
      padding: 1rem 0;
    }
    &.report {
      height: calc(100vh - 10rem);
      flex-direction: column;
      border-radius: 0rem;
      min-width: 70rem;
      max-width: 70rem;
      margin: auto 0 auto 0;
      padding: 0;

      &.modal {
        height: 100%;
      }
    }
    &.activeCard {
      margin: 1rem 3%;
      width: 94%;
      padding: 0 2.5rem 1.5rem 2.5rem;
      box-shadow: -0.1rem 0.1rem 0.3rem ${theme.palette.primary.darkShadow};
    }
    &.activeCard:hover {
      box-shadow: -0.1rem 0.1rem 0.3rem ${theme.palette.primary.main};
    }
    &.inactiveCard {
      margin: 1rem 3%;
      width: 94%;
      padding: 0 2.5rem 1.5rem 2.5rem;
      box-shadow: -0.1rem 0.1rem 0.3rem ${theme.palette.common.grayBorder};
    }
    &.inactiveCard:hover {
      box-shadow: -0.3rem 0.13em 0.9rem ${theme.palette.common.grayBorderDark};
    }
    &.shadow {
      box-shadow: 0 0 0.8rem ${theme.palette.common.grayBorderDark}
    }
`)
export const CardIndex = styled.div(({ theme }) => `
  color: ${theme.palette.common.black};
  padding: 0 0 0 0.3rem;
`)
export const TierButton = styled.button(({ theme }) => `
  height 100%;
  color: ${theme.palette.primary.main};
  font-weight: 600;
  background: ${theme.button.background.tier};
  border: 0.1rem solid ${theme.palette.primary.main};
  border-radius: 1rem;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.8rem;
  transition: background 0.3s ease;
  transition: box-shadow 0.3s ease;

  &:hover {
    cursor: pointer;
  }
  &.one {
    color: ${theme.palette.common.red};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.red};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.common.red};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.two {
    color: ${theme.palette.secondary.dark};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.secondary.dark};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.secondary.dark};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.three {
    color: ${theme.palette.primary.dark};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.primary.dark};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.primary.dark};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.disabled {
    :hover {
      cursor: not-allowed;
    }
  }
  &.noHover {
    :hover {
      cursor: default;
    }
  }
`)
export const CardButton = styled.button(({ theme }) => `
  height 100%;
  min-height: 6rem;
  width: 3rem;
  padding: 0;
  font-size: 1rem;
  color: ${theme.palette.primary.main};
  font-weight: 600;
  background: ${theme.palette.common.pageBackground};
  border: 0.1rem solid ${theme.palette.primary.main};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.6rem;
  transition: background 0.3s ease;
  transition: box-shadow 0.3s ease;
  
  &.horizontal {
    width: fit-content;
    min-height: fit-content;
    height: fit-content;
    margin: 0.2rem;
  }
  &.hidden {
    z-index: -1;
    opacity: 0;
    padding: 0 0.6rem;
    height: 0;
    font-size: 0;
    transition: 
      opacity .5s,
      height .20s,
      font-size .20s,
      padding .20s
    ; 
  }
  &.onDisplay {
    z-index: 1;
    transition: 
      padding .30s,
      opacity .2s
    ;
  }
  &.fitContentWidth {
    width: fit-content;
  }
  &.thin {
    padding: 0.1rem 0.6rem;
  }
  &.primaryDark {
    color: ${theme.palette.primary.dark};

    :hover {
      background: ${theme.button.background.primaryDarkHover};
    }
  }
  &.cursorResize {
    cursor: e-resize;
  }
  &.iconButton {
    border: none;
    height: fit-content;
    background: none;
    transition: color 0.2s ease;
    
    &.primary {
      color: ${theme.palette.primary.dark};
      
      &:hover {
        cursor: pointer;
        color: ${theme.palette.primary.main};
        
      }
    }
    &.primaryDisabled {
      color: ${theme.button.background.primaryDisabled};

      &:hover {
        cursor: not-allowed;
      }
    }
    &.cancel {
      color: ${theme.palette.common.grayText};
    
      &:hover {
        cursor: pointer;
        color: ${theme.palette.common.grayTextLight};
      }
    }
  }
  &.actions {
    :hover {
      box-shadow: 0 0 0.5rem ${theme.palette.primary.main};
    }
  }
  &.mortgageTag {
    background: ${theme.info.background.caution};
    color: ${theme.info.color.caution};
    border: 0.1rem solid ${theme.info.border.caution};
  }
  &.manualInvestigation {
    background: ${theme.palette.common.grayBorder};
    color: ${theme.palette.primary.dark};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.primary.dark};

    &:hover {
      cursor: hover;
      background: ${theme.palette.common.pageBackground};
      box-shadow: 0 0 0.5rem ${theme.palette.primary.dark};
    }
  }
  &.active {
    box-shadow: 0 0 0.5rem ${theme.palette.primary.main};
    background: ${theme.palette.common.cardBackground};
  }
  &.tier {
    width: 2rem;
  }
  &.one {
    color: ${theme.palette.common.red};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.red};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.common.red};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.two {
    color: ${theme.palette.secondary.dark};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.secondary.dark};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.secondary.dark};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.three {
    color: ${theme.palette.primary.dark};
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.primary.dark};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.palette.primary.dark};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.five {
    color: ${theme.button.border.caution};
    font-weight: 600;
    border: 0.1rem solid ${theme.button.border.caution};
    background: 0.1rem solid ${theme.button.background.caution};

    &:hover {
      box-shadow: 0 0 0.5rem ${theme.button.shadow.caution};
    }
    &.noHover {
      &:hover {
        box-shadow: none;
      }
    }
  }
  &:hover {
    cursor: pointer;
    background: ${theme.palette.common.cardBackground};
    
    &.disabled {
      cursor: not-allowed;
      background: ${theme.palette.common.pageBackground};
      box-shadow: none;
    }
  }
  &.noHover {
    cursor: default;
    background: ${theme.palette.common.pageBackground};
  }
`)
  export const ActionIconWrapper = styled.div(
    ({ theme }) => `
    color: ${theme.palette.common.grayBorder};
    fill: ${theme.palette.common.grayBorder};
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease;
    transition: background 0.3s ease;

    &.gray {
      transition: color 0.3s ease;
      color: ${theme.menuIcon.background.neutral};
    }
    &.dark {
      transition: color 0.3s ease;
      color: ${theme.palette.common.grayText};
    }
    &.successDark {
      color: ${theme.palette.primary.dark};
    }
    &.autoMarginHorizontal {
      margin: 0 auto;
    }
    &.reportHeader {
      background: ${theme.report.background.secondary};
    }
    &.column {
      flex-direction: column;
    }
    &.even {
      color: ${theme.palette.common.cardBackground};
    }
    &.active {
      color: ${theme.palette.primary.main};
    }
    &.first {
      margin: auto 0.5rem auto 0;
    }
    &.medium {
      height: 3rem;
      width: 3rem;
    }
    &.grayText {
      color: ${theme.palette.common.grayText};
    }
    &.circle {
      border-radius: 2rem;
    }
    &.primary {
      color: ${theme.palette.primary.transparentHover};
      
      &.full {
        color: ${theme.palette.primary.dark};
        border: 0.1rem solid ${theme.palette.primary.dark};
        background: ${theme.palette.primary.labelTransparent};
        
        :hover {
          color: ${theme.palette.primary.darker};
          background: ${theme.palette.primary.transparent};
        }
      }
      
      :hover {
        cursor: pointer;
        color: ${theme.palette.primary.dark};
      }
    }
    &.neutral {
      color: ${theme.icon.neutral.secondary};
      
      :hover {
        cursor: pointer;
        color: ${theme.palette.primary.dark};
      }
    }
    &.wrapped {
      width: fit-content;
      padding: 0.3rem 1rem;
      border-radius: 1rem;
      font-weight: 600;
    
      &.success {
        border: 0.1rem solid ${theme.accent.success.primary};
      }
      &.disabled {
        border: 0.1rem solid ${theme.palette.common.grayText};
      }
      &.caution {
        color: ${theme.accent.caution.primary};
        border: 0.1rem solid ${theme.accent.caution.primary};
      }
      &.error {
        color: ${theme.accent.error.primary};
        border: 0.1rem solid ${theme.accent.error.primary};

        &.filled {
          background: ${theme.accent.error.primaryFullTransparent};
        }
      }
    }

    &.hover {

      :hover {
        cursor: pointer;
  
        &.disabled {
          cursor: not-allowed;
        }
        &.hoverBorder {
          border: 0.1rem solid ${theme.palette.common.grayText};
        }
        &.success {
          color: ${theme.accent.success.primary};
          background: ${theme.accent.success.primaryFullTransparent};
        }
        &.caution {
          color: ${theme.accent.caution.primary};
          background: ${theme.accent.caution.primaryFullTransparent};
        }
        &.dark {
          color: ${theme.palette.common.black};
        }
        &.gray {
          color: ${theme.menuIcon.background.neutralHover};
        }
      }
    }
    &.noHover {

      &:hover {
        cursor: default;
      }
    }
`)
export const ActionMenuItem = styled.div(({ theme}) => `
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background 0.2s ease;

  &.noHover {
    background: ${theme.palette.common.disabledGray};

    :hover {
      cursor: not-allowed;
    }
  }
  &.advance {
    background: ${theme.palette.primary.dark};
    height: 2.5rem;
    border-radius: 1.0rem;
    margin: auto;
    justify-content: center;
    cursor: pointer;
    padding: 0 0.5rem;
    
    &.focus {
      background: ${theme.palette.primary.main};
      cursor: default;
    }
    :hover {
      background: ${theme.palette.primary.main};
    }
  }
  &.dismiss {
    background: ${theme.palette.secondary.main};
    height: 2.5rem;
    border-radius: 1.0rem;
    margin: 0 auto;
    justify-content: center;
    cursor: pointer;
    padding: 0 0.5rem;

    &.focus {
      background: ${theme.palette.secondary.dark};
      cursor: default;
    }
  }
  &.dismiss:hover {
    background: ${theme.palette.secondary.dark};
  }
`)
export const ActionMenuTitle = styled.div(({ theme }) => `
  color: ${theme.palette.common.grayText};
  font-weight: 600;
  font-size: 1.1rem;
  height: fit-content;
  margin: 0 0 0 1rem;

  &.white {
    color: ${theme.palette.common.white};
    margin: 0;
  }
`)
export const DetailHeaderWrapper = styled.div(`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6rem 1fr 10rem 1fr 6rem 1fr;
  justify-items: center;

  &.simple {
    grid-template-columns: 1fr;
  }
`)
export const DetailContainer = styled.div(({ theme }) => `
  display: grid;
  height: fit-content;
  align-items: center;
  justify-content: center;

  &.column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.bigHeader {
    grid-template-rows: 4rem 1fr;
  }
  &.fitContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: ${theme.palette.common.white};
  }
  &.target {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
  }
  &.single {
    display: flex;
    justify-content: center;
  }
  &.large {
    width: fit-content;
  }
  &.singleRow {
    display: flex;
    height: fit-content;
  }
  &.thirds {
    width: 33%
  }
  &.mortgage {
    grid-template-rows: none;
  }
  &.memberPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 0 0 3rem;
  }
  &.member {
    margin: 0 2rem;
    display: flex;
    width: fit-content;
    line-height: 2.5rem;
  }
`)
export const DetailListContainer = styled.div(`
  display: flex;
  height: fit-content;
  justify-content: center;

  &.performance {
    height: 90%;
  }
  &.timeline {
    width: 100%;
    margin: 0 0 1rem 0;
    justify-content: flex-start;
  }
  &.column {
    flex-direction: column;
    align-items: center;
  }
`)
export const DetailTagContainer = styled.div(`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: fit-content;
  align-items: center;
  margin: 0 0 0 1rem;
  padding: 0.5rem;
`)
export const AddButton = styled.div(({ theme }) => `
  color: ${theme.palette.common.grayBorder};
  height: 2rem;
  width: 2rem;
  transition: color 0.3s ease;
  margin: 0 0.6rem;

  &:hover {
    cursor: pointer;
    color: ${theme.palette.primary.transparentHover};

    &.disabled {
      cursor: not-allowed;
    }
  }

  &.dark {
    color: ${theme.palette.common.grayText};

    &:hover {
      cursor: pointer;
      color: ${theme.palette.primary.medium};
    }
  }
`)
export const DetailInfoContainer = styled.div(`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.timeline {
    min-width: fit-content;
  }
  &.space {
    justify-content: space-around;
  }
  &.item {
    align-items: flex-end;
    margin: auto 0.5rem auto 0
  }
  &.potential {
    justify-content: space-around;
  }
  &.notificationTitle {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &.performance {
    justify-content: space-between
  }
`)
export const Badge = styled.div(
  ({ theme }) => `
    height: fit-content;
    padding: 0.1rem 0.4rem;
    border-radius: 1rem;
    color: ${theme.palette.common.white};
    position: relative;
    top: -0.1rem;
    right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;

    &.one {
      background-color: ${theme.palette.common.red}
    }
    &.two {
      background-color: ${theme.palette.secondary.dark}
    }
    &.readPastel {
      background-color: ${theme.palette.secondary.pastelMedium};
      color: #fff;
    }
    &.readTransparent {
      background-color: ${theme.palette.secondary.mainTransparent};
      color: #fff;
    }
    &.three {
      background-color: ${theme.palette.primary.dark}
    }
    &.awaiting {
      background-color: ${theme.button.background.cautionHover};
      top: -0.2rem;
      left: 0.1rem;
    }
    &.cardNotif {
      top: -0.5rem;
      left: -0.7rem;
      background-color: ${theme.palette.common.red}
    }
    &.repository {
      top: -0.8rem;
      left: -0.3rem;
      z-index: 100;
      padding: 0rem 0.5rem;
      background-color: ${theme.palette.common.red}
    }
    &.sideBar {
      top: -0.5rem;
      left: 0rem;
      z-index: 100;
      padding: 0rem 0.6rem;
      background-color: ${theme.palette.common.red}
    }
    &.navMenu {
      top: -0.5rem;
      left: -0.5rem;

      &.unread {
        background-color: ${theme.palette.common.red}
      }
      &.read {
        background-color: ${theme.palette.secondary.pastelMedium}
      }
    }
    &.flex {
      display: flex;
    }
`)
export const Detail = styled.div(
  ({ theme }) => `
    font-size: 1rem;
    display: flex;
    justify-content: flex-end;
    width: fit-content;

    &.center {
      margin: 0 auto;
    }
    &.thin {
      font-weight: 300;
    }
    &.timeline {
      width: 20rem
    }
    &.timelineHeader {
      font-size: 1.2rem;
      font-weight: 600;
      justify-self: flex-start;
      margin: 0.2rem 0 0 0;
    }
    &.header {
      font-size: 1.4rem;
      font-weight: 600;
    }
    &.outcome {
      text-decoration: underline;
      text-decoration-thickness: 0.2rem;
    }
    &.refinance {
      text-decoration-color: ${theme.palette.primary.main};
    }
    &.renegotiation {
      text-decoration-color: ${theme.closure.renegotiation.primary};
    }

    &.item {
      font-weight: 600;
      height: 1.5rem;
    }
    &.highlight {
      color: ${theme.palette.common.grayText};
    }
    &.timelineItem {
      font-weight: 600;
      overflow-wrap: anywhere;
      padding: 0 0 0 0;
      justify-content: flex-start;

      &.green {
        font-size: 1.4rem;
      }
      &.red {
        font-size: 1.4rem;
      }
    }
    &.column {
      flex-direction: column;
      height: 3.2rem;
    }
    &.note {
      height: fit-content
    }
    &.bold {
      font-weight: 600;
    }
    &.notificationTitle {
      font-size: 1.4rem;
      font-weight: 600;
      color: ${theme.palette.common.black};
      height: fit-content;
    }
    &.potential {
      font-weight: 600;
      height: 2rem;
    }
    &.subject {
      font-size: 1.2rem;
      color: ${theme.palette.common.black};
      margin: 0.2rem 0.2rem 0 0;
      font-weight: 400;
    }
    &.memberName {
      font-size: 2.6rem;
      height: fit-content;
      color: ${theme.palette.common.black};
      font-weight: 600;
    }
    &.date {
      font-size: 1rem;
      color: ${theme.palette.common.black};
      margin: 0.2rem 0.2rem 0 0;
      font-weight: 400;
    }
    &.role {
      line-height: 1.5rem;
      height: 1rem;
    }
    &.target {
      font-weight: 600;
      color: ${theme.palette.primary.main};
      height: 2rem;
      font-size: 1.5rem;
    }
    &.red {
      color: ${theme.palette.common.red};
      font-weight: 600;
    }
    &.green {
      color: ${theme.palette.primary.main};
      font-weight: 600;
    }
    &.one {
      color: ${theme.palette.common.red};
      border: 0.1rem solid ${theme.palette.common.red};
      background: ${theme.palette.common.redTransparent};
    }
    &.two {
      color: ${theme.palette.secondary.dark};
      border: 0.1rem solid ${theme.palette.secondary.dark};
      background: ${theme.palette.secondary.labelTransparent};
    }
    &.three {
      color: ${theme.palette.primary.dark};
      border: 0.1rem solid ${theme.palette.primary.dark};
      background: ${theme.palette.primary.labelTransparent};
    }
    &.success {
      color: ${theme.palette.primary.medium};
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
    }
    &.wrapped {
      margin: 0 0.5rem 0 0;
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 1rem;
      border: 1px solid ${theme.palette.common.cardBackground};
      color: ${theme.palette.primary.dark};
      border: 0.1rem solid ${theme.palette.primary.medium};
    }
    &.wrappedRed {
      margin: 0 0.5rem 0 0;
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 1rem;
      border: 1px solid ${theme.palette.common.cardBackground};
      color: ${theme.palette.common.redDark};
      border: 0.1rem solid ${theme.palette.common.red};
    }
    &.wrappedGray {
      margin: 0 0.5rem 0 0;
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 1rem;
      border: 1px solid ${theme.palette.common.cardBackground};
      color: ${theme.palette.common.grayText};
      border: 0.1rem solid ${theme.palette.common.grayText};
    }
    &.targetPreview {
      height: 4rem;
      align-items: center;
      margin: 0;
    }
    &.targetAdjust {
      height: 2rem;
      align-items: center;
      margin: 0;
    }
    &.gray {
      border: 0.1rem solid ${theme.palette.common.grayBorder};
    }
    &.timelineDetail {
      justify-content: flex-end;
      min-width: 10rem;
      color: ${theme.palette.common.black};
    }
    &.tier {
      margin: 0.3rem 0;
      padding: 0 1rem;
      font-weight: 600;
      border-radius: 1rem;
      justify-content: center;
    }
`)
export const CompareLink = styled.button(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.common.grayBorder};
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
    transition: color 0.1s ease;
    padding: 0.1rem 2.5rem;

    &.small {
      min-width: fit-content;
      padding: 0.1rem 0.5rem;
    }
    &.secondary {
      color: ${theme.palette.secondary.main};
      :hover {
        color: ${theme.palette.secondary.main};
        background-color: ${theme.palette.common.pageBackground};
        border: 0.1rem solid ${theme.palette.common.grayBorder};
      }
      :focus {
        background-color: ${theme.palette.common.grayButtonFocusLight};
        box-shadow: 0 0 0.3rem ${theme.palette.secondary.main};
        border: 0.1rem solid ${theme.palette.secondary.light};
        outline: none;
        
        :hover {
          color: ${theme.palette.secondary.main};
          background-color: ${theme.palette.common.pageBackground};
          border: 0.1rem solid ${theme.palette.common.grayBorder};
        }
      }
    }
    &:hover {
      background-color: ${theme.palette.common.pageBackground};
      border: 0.1rem solid ${theme.palette.common.grayBorder};
    }
    &:focus {
      background-color: ${theme.palette.common.grayButtonFocusLight};
      border: 0.1rem solid ${theme.palette.common.grayText};
      outline: none;
      color: ${theme.palette.common.black};
    }
    &.notification {
      height: 1.5rem;
      min-width: 4rem;
      color: ${theme.palette.common.grayText};
    }
    &.gray {
      background-color: ${theme.palette.common.grayBorder};
      color: ${theme.palette.common.black};

      &.incative {
        color: ${theme.palette.common.grayText};
      }
      
      :hover {
        color: ${theme.palette.common.black};
        background-color: ${theme.palette.common.grayBorderLight};
        border: 0.1rem solid ${theme.palette.common.grayTextLight};
      }
    }
    &.grayFocus {
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.common.pageBackground};
      border: 0.1rem solid ${theme.palette.common.grayBorder};
      box-shadow: 0 0 0.3rem ${theme.palette.primary.main};
      cursor: default;
    }
    &.tooltip:hover {
      cursor: alias;
    }
    &.disabled {
      &.gray {
        color: ${theme.palette.common.grayTextLight};
      }
      :hover {
        cursor: not-allowed;
      }
    }
  `
)
export const ControlContainer = styled.div(`
  width: 100%;
  padding: 0rem 0 0 2rem;
  gap: 1rem;

  &.flex {
    padding: 0;
    display: flex;
    width: fit-content;
  }
`)
export const ControlHeader = styled.div(
  ({ theme }) => `
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0 0 0.5rem 0;
`)
export const SubHeader = styled.div(({ theme }) => `
    font-size: 1.0rem;
    color: ${theme.palette.common.grayText};
    font-weight: 400;
    margin: 0 auto;

    &.dark {
      color: ${theme.text.subHeader.medium}
    }
    &.secondary {
      color: ${theme.palette.secondary.main};
      font-weight: 600;
    }
    &.modal {
      display: flex;
      width: 90%;
      flex-direction: column;
      text-align: center;
      align-items: center;
      color: ${theme.palette.common.black}; 
      padding: 2rem 0 0 0;
      font-size: 1.2rem;
    
      &.xs {
        width: 100%;
      }
      &.border {
        border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
      }
      &.verticalCenter {
        margin: 2rem 0 6.5rem 0;
        height: 100%;
        justify-content: center;
      }
    }
`)
export const StyledInputElement = styled.input(({ theme, disabled }) => `
  width: 20rem;
  font-size: 1rem;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.palette.common.textWhite};
  // background: ${theme.palette.common.cardBackground};
  border: none;
  // border-radius: 0.6rem;
  padding: 0.4rem;
  height: 2.5rem;
  outline: none;
  // border: 0.1rem solid ${theme.palette.common.grayText};

  background: #fff;
  border-left: 0.05rem solid ${theme.palette.common.grayBorderDark};
  border-right: 0.05rem solid ${theme.palette.common.grayBorderDark};
  border-top: 0.05rem solid ${theme.palette.common.grayBorderDark};
  border-bottom: 0.05rem solid ${theme.palette.common.grayBorderDark};
  border-radius: 0.3rem 0.5rem;
  
  &.disabled {
    background: ${theme.palette.common.grayOpaque};

    :hover {
      cursor: not-allowed;
    }
  }
  &.required {
    border: 0.1rem solid ${theme.accent.error.primary};
    
    :focus {
      border: 0.1rem solid ${theme.accent.success.primary};
    }
  }
  &.error {
    border: 0.1rem solid ${theme.accent.error.primary};
    background: ${theme.accent.error.primaryTransparent};
    box-shadow: 0 0 0.3rem ${theme.accent.error.primary};
    
    &:focus {
      border: 0.05rem solid ${theme.palette.common.grayBorder};
      box-shadow: 0 0 0.3rem ${theme.accent.error.primaryMedium};
    }
    &:hover {
      border: 0.05rem solid ${theme.palette.common.grayBorder};
      box-shadow: 0 0 0.3rem ${theme.accent.error.primaryMedium};
    }
    &.disabled {
      :hover {
        cursor: not-allowed;
      }
    }
  }
  &.caution {
    box-shadow: 0 0 0.3rem ${theme.accent.caution.primary};

    &:hover {
      border: 0.05rem solid ${theme.palette.common.grayBorder};
      box-shadow: 0 0 0.3rem ${theme.accent.caution.primaryMedium};
    }
    &:focus {
      border: 0.05rem solid ${theme.palette.common.grayBorder};
      box-shadow: 0 0 0.3rem ${theme.accent.caution.primaryMedium};
    }
  }
  &.success {
    box-shadow: 0 0 0.3rem ${theme.accent.success.primary};
  }
  &:hover {
    border: 0.05rem solid ${theme.palette.primary.dark};
  }
  
  &:focus {
    background: ${theme.input.field.populated};
    border: 0.05rem solid ${theme.palette.primary.dark};
  }

  &.unpopulated {
    background: ${theme.input.field.unpopulated};
    
    :focus {
      border: 0.1rem solid ${theme.button.background.primary};
    }
  }
  &.populated {
    background: ${theme.input.field.populated};
    
    :focus {
      border: 0.1rem solid ${theme.button.background.primary};
    }
  }
  &.auth {
    background: ${theme.palette.common.white};
    color: ${theme.palette.common.black};
    font-size: 1.2rem;
    padding: 1.6rem 0.8rem;
    font-weight: 400;
  }
  &.true {
    background: ${theme.palette.common.grayOpaque};
    cursor: not-allowed;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: 0.1rem solid ${theme.button.background.primary};

    :hover {
      cursor: text;
    }
  }
`)
export const ListItem = styled.div(({ theme }) => `
  width: 100%;
  height: 3rem;
  display: grid;
  grid-template-columns: 6rem 6rem 1fr 1fr;
  border-right: 0.1rem solid ${theme.palette.common.grayBorder};
  border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
  border-left: 0.1rem solid ${theme.palette.common.grayBorder};
  color: ${theme.palette.common.black};
  text-align: center;
  align-items: center;
  justify-items: center;
  padding: 0.2rem;

  &.header {
    border: 0.3rem solid ${theme.palette.common.grayBorder};
    font-weight: 600;
    color: ${theme.palette.common.grayText};
    padding: 0;
  }
  &.list:hover {
    background: ${theme.palette.common.grayHover};
  }
  &.user {
    background: ${theme.palette.common.highlight};
  }
  &.user:hover {
    background: ${theme.palette.common.highlightHover};
  }
`)
export const ActionContainer = styled.div(({ theme }) => `
  display: flex;
  align-items: center;
  padding-right: 0.2rem;
  color: ${theme.palette.common.grayText};
  transition: all .25s ease-in-out;

  &.edit {
    justify-content: center;
    border: 0.05rem solid ${theme.palette.common.grayText};
    border-radius: 2rem;
    height: 2rem;
    width: 2.5rem;
    padding: 0 0 0 0.1rem;
    opacity: 0.8;
    background: linear-gradient(${theme.button.background.action}, ${theme.button.background.action}, ${theme.button.background.action}, ${theme.button.background.action}, ${theme.button.shadow.action}, ${theme.button.shadow.action});
    
    :hover {
      color: ${theme.button.text.actionHover};
      cursor: pointer;
      opacity: 1;
    }
  }
  &.delete:hover {
    color: ${theme.palette.common.error};
    cursor: pointer;
  }
  &.disabled:hover {
    color: ${theme.palette.common.grayText};
    cursor: not-allowed;
  }
`)
export const AccentInfo = styled.div(({ theme }) => `
  color: ${theme.palette.common.grayText};
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  margin: 0 auto;
`)
export const ParameterControlWrapper = styled.div(`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  &.disabled {
    
    &:hover {
      cursor: not-allowed;
    }
  }
`)
export const ReportContainer = styled.div(({ theme }) => `
  height: fit-content;
  padding: 0 1rem;
  margin: 0 auto;
  overflow: scroll;
  grid-row: 1;
  grid-column: 4;

  height: 55rem;

  ::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    border: 0.1rem solid ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 11px;
  }
`)
export const ReportsContentContainer = styled.div(({ theme }) => `
  grid-row: 3;
  grid-column: 4;
  width: 100%;
  height: fit-content;
  padding: 0 1rem;
  overflow-y: scroll;
  border-bottom: 0.1rem solid ${theme.palette.common.grayText};
  background: ${theme.palette.common.grayBorder};
`)
export const ReportRow = styled.div(`
  display: grid;
  grid-template-columns: 20rem 20rem;
`)
export const ReportDetail = styled.div(`
  display: flex;

  &.single {
    grid-column: 1/3;
  }
  &.one {
    grid-column: 1;
  }
  &.two {
    grid-column: 2;
  }
`)
export const ReportDetailHeader = styled.div(`
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0.4rem 0 0;
`)
export const ReportDetailText = styled.div(`
  font-size: 1rem;
  font-weight: 400;
`)
export const TreeContainer = styled.div(({ theme }) => `
  width: 100%;
  height: 100%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.5rem solid ${theme.palette.common.grayBorder};
  border-radius: 1rem;
  background-color: ${theme.palette.common.cardBackground};
  overflow: hidden;

  &.fitContentHeight {
    height: fit-content;
  }
`)
export const ReportTreeListContainer = styled.div(({ theme }) => `
  margin: 0.5rem 0 2rem 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0.3rem solid ${theme.palette.common.grayBorder};
  border-bottom: 0.3rem solid ${theme.palette.common.grayBorder};
  background-color: ${theme.palette.common.cardBackground};
  overflow-y: scroll;
  width: 30rem;
  padding: 0 0.4rem;
`)
export const NewLabel = styled.div(({ theme }) => `
  width: 100%;
  height: fit-content;
  text-align: center;
  align-self: center;
  border: 0.1rem solid #fff;
  border-radius: 1rem;
  box-shadow: 0 0 0.3rem #00940F;
  background: ${theme.palette.primary.transparentHover};
  color: ${theme.palette.common.black};
  margin: 0;
  padding: 0.1rem 0.3rem;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: flex;
  
  &.new {
    width: fit-content;
    padding: 0.2rem 0.6rem 0.2rem 0.3rem;
    justify-content: space-between;
  }
  &.milestone {
    width: fit-content;
    padding: 0.2rem 0.6rem 0.2rem 0.6rem;
    color: ${theme.accent.text.primaryLight};
  }
  &.notification {
    width: fit-content;
    box-shadow: 0 0 0.3rem ${theme.accent.error.primary};
    padding: 0.1rem 0.5rem 0.1rem 0.3rem;
    background: ${theme.palette.common.pageBackground};
    
    &.inactive {
      box-shadow: 0 0 0.3rem ${theme.notification.shadow.inactive};
    }
  }
  &.reportTree {
    grid-column: 2;
  }
  &.leadCard {
    width: 5rem;
  }
  &.white {
    border: 0.1rem solid ${theme.palette.primary.transparentHover};
    background: ${theme.palette.common.cardBackground};
  }
  &.hover {
    :hover {
      cursor: pointer;

      &.new {
        border: 0.1rem solid ${theme.palette.primary.main};
        background: ${theme.palette.primary.transparentMid};
      }
      &.notification {
        border: 0.1rem solid ${theme.accent.error.primaryTransparent};
        background: ${theme.accent.error.primaryFullTransparent};

        &.inactive {
          border: 0.1rem solid ${theme.notification.border.inactiveHover};
          background: ${theme.notification.background.inactiveHover};
        }
      }
    }
  }
`)
export const ModalContainer = styled.div(({theme}) => `
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${theme.palette.common.cardBackground};
  height: 100%;
  
  &.threePanel {
    display: grid;
    height: 66rem;
    max-height: 66rem;
    grid-template-columns: 1rem 20rem 1fr 40rem 1rem;
    grid-template-rows: 4rem 4rem 1.5rem 4.1rem 1fr 2rem;
  }
  &.twoPanel {
    display: grid;
    height: 66rem;
    max-height: 66rem;
    grid-template-rows: 4rem 4rem 1fr 2rem;
    grid-template-columns: 1rem 20rem 1fr 1rem;
  }
  &.onePanel {
    display: grid;
    grid-template-rows: 4rem 1fr 1rem;
    grid-template-columns: 1rem 1fr 1rem;
  }
  &.uploadModal {
    display: grid;
    grid-template-rows: 4rem 1fr 1rem;
    grid-template-columns: 1rem 1fr 45rem 1rem;
  }
`)
export const ComponentBorder = styled.div(({theme}) => `
  width: 100%;
  height: 100%;
  z-index: 100;
  
  &.top {
    color: ${theme.palette.common.grayText};
    display: flex;
    justify-content: flex-end;
    border-bottom: 0.1rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 1rem ${theme.palette.common.grayTextLight};
    background-image: linear-gradient(${theme.palette.common.grayBorderMedium}, ${theme.palette.common.grayBorderMedium}, ${theme.palette.common.grayBorderMedium}, ${theme.palette.common.grayTextLight});
  }
  &.bottom {
    border-top: 0.1rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 0.5rem ${theme.palette.common.grayTextLight};
    background-image: linear-gradient(${theme.palette.common.grayBorder}, ${theme.palette.common.grayTextLight});
  }
  &.bottomPanel {
    border-top: 0.1rem solid ${theme.palette.common.grayBorderDark};
    box-shadow: 0 0.3rem 0.5rem ${theme.palette.common.grayTextLight};
    background-image: linear-gradient(${theme.palette.common.grayBorder}, ${theme.palette.common.grayTextLight});
  }
  &.left {
    border-right: 0.1rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 0.5rem ${theme.palette.common.grayTextLight};
    background: ${theme.palette.common.grayBorder};
  }
  &.right {
    border-left: 0.1rem solid ${theme.palette.common.grayText};
    box-shadow: 0 0 0.5rem ${theme.palette.common.grayTextLight};
    background: ${theme.palette.common.grayBorder};

    &.border {
      border-left: 0.1rem solid ${theme.palette.common.grayText};
    }
  }
  &.loginTop {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(${theme.palette.common.black}, ${theme.palette.common.black}, ${theme.palette.common.black}, ${theme.palette.common.black}, ${theme.palette.common.black}, ${theme.palette.common.pageBackground});
  }
`)
export const ModalContentContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    grid-row: 3/6;
    grid-column: 3;

    &.darkBackground {
      background: ${theme.palette.common.grayBorder};
    }
    &.leadActions {
      grid-row: 3/6;
    }
    &.centerColumn {
      padding: 1rem 0 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow-x: hidden;
    }
    &.topColumn {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
    &.propertyDetails {
      grid-row: 2/6;
    }
    &.discrepancies {
      display: grid;
      grid-template-columns: 55rem 45rem;
    }
    &.noScroll {
      overflow: hidden;
    }
    &.column {
      flex-direction: column;
      justify-content: flex-start;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0.6rem;
      border: 0.1rem solid red ${theme.palette.common.pageBackground};
      background-color: ${theme.palette.common.grayText};
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
      width: 0.6rem;
    }
`)
export const ModalHeaderMessage = styled.div(({ theme }) => `
  font-size: 1.4rem;
  font-weight: 300;
  margin: 0 0 0 1rem;
  text-transform: none;
   
  &.green {
    color: ${theme.palette.primary.main}; 
  }
  &.success {
    color: ${theme.palette.primary.main}; 
  }
  &.red {
    color: ${theme.palette.common.red}; 
  }
  &.error {
    color: ${theme.palette.common.red}; 
  }
  &.gray {
    color: ${theme.palette.common.grayText}; 
  }
`)
export const Divider = styled.div(({ theme }) => `
  height: 0.1rem;
  width: 95%;
  align-self: center;
  background-color: ${theme.palette.common.grayBorder};

  &.green {
    background-color: ${theme.palette.primary.main};
  }
  &.red {
    background-color: ${theme.palette.common.red};
  }
  &.gray {
    background-color: ${theme.palette.common.grayBorder};
  }
  &.darkGray {
    background-color: ${theme.panel.border.secondary};
  }
  &.thick {
    border: 0.3rem solid ${theme.palette.common.grayBorder};
    width: 100%;
  }
  &.inScroll {
    border: 0.1rem solid ${theme.palette.common.grayBorder};
    width: 100%;
  }
  &.full {
    width: 100%;
  }
  &.narrow {
    width: 80%;
    margin: 3rem 0 2rem 0;
  }
`)
export const Label = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    color: ${theme.palette.common.black};
    white-space: nowrap;
    margin: 0 1rem 0 0;
    font-family: "Courier";

    &.count {
      margin: 0 0 0 1rem;
    }
    &.colorMedium {
      color: ${theme.palette.common.grayText};
    }
    &.colorLight {
      color: ${theme.palette.common.grayBorder};
    }
    &.justifyEnd {
      justify-content: flex-end;
    }
`)
export const ButtonWrapper = styled.div(`
  width: 20rem;
  display: flex;
  margin: 0 auto;
  width: fit-content;
  justify-content: space-between;
  align-items: center;

  &.justifyCenter {
    justify-content: center;
  }
  &.justifyBetween {
    justify-content: space-between;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.selfAlignEnd {
    align-self: flex-end;
  }
  &.marginAuto {
    width: fit-content;
    margin: auto;
  }
  &.static {
    position: static;
  }
`)
export const InputWrapper = styled.div(`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: center;
  width: 20rem;
  margin: 0 1rem;

  &.noGap {
    gap: 0;
  }
  &.alignStart {
    align-items: flex-start;
  }
`)
export const DetailText = styled.div(`
  font-weight: 600;
  font-size: 1.4rem;
`)
export const StatusAndLabelWrapper = styled.div(`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  height: 3rem;
  
  &.justifyBetween {
    justify-content: space-between;
  }
  &.center {
    justify-content: center;
  }
  &.notes {
    min-height: fit-content;
    height: 2rem;
    align-items: center;
  }
  &.column {
    flex-direction: column;
    align-items: flex-start;
    height: 2.5rem;
    justify-content: flex-end;
  }
  &.small {
    height: fit-content;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
`)
export const ErrorWrapper = styled.div(({ theme }) => `
  display: flex;
  padding: 0.5rem;
  border-radius: 1.0rem;
  color: ${theme.accent.error.primary};
  border: 0.1rem solid ${theme.accent.error.primary};
  background: ${theme.accent.error.primaryFullTransparent};
  align-items: center;
  gap: 0.5rem;
  height: 2.0rem;
  font-size: 1.0rem;
  white-space: nowrap;
  font-family: "Courier";
  
  &.caution {
    color: ${theme.accent.caution.primary};
    background: ${theme.accent.caution.primaryFullTransparent};
    border: 0.1rem solid ${theme.accent.caution.primary};
  }
  &.fitContentHeight {
    height: fit-content;
  }
  &.fitContentWidth {
    width: fit-content;
  }
  &.column {
    border: none;
    padding: 0;
    gap: 0.2rem;
  }
`)
export const SuccessWrapper = styled.div(({ theme }) => `
  display: flex;
  padding: 0.5rem;
  border-radius: 1.0rem;
  color: ${theme.accent.success.primary};
  border: 0.1rem solid ${theme.accent.success.primary};
  background: ${theme.accent.success.primaryFullTransparent};
  align-items: center;
  gap: 0.5rem;
  height: 2.0rem;
  font-size: 1.0rem;
  white-space: nowrap;
  font-family: "Courier";
`)

export const AlertWrapper = styled.div(({ theme }) => `
  display: flex;
  padding: 0.5rem;
  border-radius: 1.0rem;
  color: red;
  border: 0.1rem solid red;
  align-items: center;
  gap: 0.5rem;
  height: 2.0rem;
  font-size: 1.0rem;
  white-space: nowrap;
  width: fit-content;

  &.success {
    border: 0.1rem solid ${theme.accent.success.secondary};
    color: ${theme.accent.success.secondary};
  }
  &.caution {
    border: 0.1rem solid ${theme.accent.caution.primary};
    color: ${theme.accent.caution.primary};
  }
  &.error {
    border: 0.1rem solid ${theme.accent.error.primary};
    color: ${theme.accent.error.primary};
  }
  &.medium {
    height: 2.5rem;
    padding: 0.9rem;
    font-size: 1.4rem;
  }
  &.thin {
    font-weight: 300;
  }
  &.column {
    border: none;
    padding: 0;
    gap: 0.2rem;
  }
  &.twoLine {
    height: 4rem;
  }
`)
export const NoteContainer = styled.div(({ theme }) => `
  background: ${theme.palette.common.cardBackground};
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0.5rem;
  overflow: scroll;

  &.center {
    align-items: center;
    justify-content: center;
  }
  &.actionModal {
    width: 90%;
    margin: 1rem auto;
    border: 0.3rem solid ${theme.palette.common.grayText};
  }
`)
export const NoteContentContainer = styled.div(({ theme }) => `
  padding: 0rem 2rem 1rem 2rem;
  border-bottom: 0.1rem solid ${theme.palette.common.grayBorder};
  border-top: 0.1rem solid ${theme.palette.common.grayBorder};
  
  &.list {
    height: 100%;
    display: flex;
    justify-content: fllex-start;
    align-items: flex-start;
    overflow: scroll;

    ::-webkit-scrollbar-thumb {
      border-radius: 0.8rem;
      border: 0.1rem solid red ${theme.palette.common.pageBackground};
      background-color: ${theme.palette.common.grayText};
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:vertical {
      width: 0.5rem;
    }
  }
  &.input {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

`)
export const TimelineContainer = styled.div(({ theme }) => `
  width: 40rem;
  margin: 1rem 5rem 0 5rem;
  overflow: scroll;
  border-top: 1rem solid ${theme.palette.common.grayBorder};
  border-left: 1rem solid ${theme.palette.common.grayBorder};
  background-color: ${theme.palette.common.grayBorder};
  
  &.uploadsModal {
    height: 100%;
    border-top: 0;
    border-left: 0.1rem solid ${theme.palette.common.grayText};
    background-color: ${theme.palette.common.grayBorder};
    grid-row: 2/3;
    grid-column: 3;
    margin: 0 auto;
    overflow: hidden;
    width: 45rem;
  }
  &.actionsModal {
    height: 100%;
    border-top: 0;
    border-left: 0.1rem solid ${theme.palette.common.grayText};
    background-color: ${theme.palette.common.grayBorder};
    grid-row: 3/6;
    grid-column: 4;
    margin: 0 auto;
    overflow: hidden;
  }
  &.report {
    width: 100%;
    margin: 0;
    height: fit-content;
    background: ${theme.palette.common.cardBackground};
    border: 1rem solid ${theme.palette.common.grayBorder};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    border-top: none;
    border-left: none;
    border: 0.1rem solid red ${theme.palette.common.pageBackground};
    background-color: ${theme.palette.common.grayText};
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 0.5rem;
  }
`)
export const MilestonePathContainer = styled.div(({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem 0 0.5rem;
  background-color: ${theme.palette.common.white}
`)
export const MilestoneConnector = styled.div(({ theme }) => `
  width: 0.1rem;
  height: 100%;
  background-color: ${theme.palette.common.black};

  &.small {
    height: 0.5rem;
    min-height: 0.5rem;
  }
  &.notify {
    background-color: ${theme.palette.primary.main};
  }
`)
export const ListRow = styled.div(({ theme }) => `
  display: grid;
  width: 100%;
  grid-template-columns: 1fr mresolveinmax(14rem,18rem) 19rem 21rem 18rem 1fr;
  position: sticky;
  
  &.compareRecordsOpen {
    display: grid;
    grid-template-columns: 0rem 20rem 1fr 1fr 5rem;
    grid-template-rows: 2.5rem 13rem;
    transition: grid-template-rows 0.7s ease-out;
  }
  &.compareRecordsClosed {
    display: grid;
    grid-template-columns: 0rem 20rem 1fr 1fr 5rem;
    grid-template-rows: 2.5rem 0;
    transition: grid-template-rows 0.7s ease-out;
  }
  &.overview {
    grid-template-columns: 1fr 19rem 26rem 23rem 1fr;
  }
  &.resolve {
    grid-template-rows: auto 0rem;
    height: 10rem;
    min-height: 10rem;
  }
  &.compareLegend {
    width: 100%;
    grid-template-rows: 1.5rem 1.5rem;
    grid-template-columns: 1fr 11rem 24rem 12rem 1fr;

    &.noGrid {
      display: flex;
    }
  }
  &.discrepancy {
    background: ${theme.component.row.discrepancyHighlight};
  }
  &.error {
    background: ${theme.accent.error.primaryFullTransparent};
  }
  &.success {
    background: ${theme.accent.success.primaryFullestTransparent};
  }
`)
export const CompareDetail = styled.div(({theme}) => `
  width: 100%;
  display: flex;
  margin: 0.1rem 0;
  height: 100%;
  align-items: center;
  text-align: center;

  &.recordRow {
    border-top: 0.1rem solid ${theme.palette.common.grayBorder};
  }
  &.resolve {
    align-items: center;
    grid-row: 2;
    grid-column: 4;
    z-index: 1;
    position: relative;
    flex-direction: column;
    height: fit-content;
  }
  &.header {
    font-weight: 600;
    justify-content: center;
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
    border-top: none;
    border-bottom: none;
  }
  &.section {
    grid-column: 3/6;
    background: ${theme.palette.common.grayOpaque};
    border-top: 0.1rem solid ${theme.palette.common.grayBorderDark};
    border-bottom: 0.1rem solid ${theme.palette.common.grayBorderDark};
  }
`)
export const ActionButton = styled.button(({theme}) => `
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  padding: 0 1rem;
  color: ${theme.palette.common.black};
  font-weight: 600;
  transition: background 0.1s ease;
  transition: border 0.3s ease;

  &.main {
    border: 0.1rem solid ${theme.palette.primary.main};
  }
  &.provided {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.grayBorderDark};
    background: ${theme.palette.common.grayOpaque};
  }
  &.one {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.red};
    background: ${theme.palette.common.redTransparent};
  }
  &.two {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.secondary.dark};
    background: ${theme.palette.secondary.labelTransparent};
  }
  &.three {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.primary.dark};
    background: ${theme.palette.primary.labelTransparent};
  }
  &.hover {
    :hover {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      cursor: pointer;

      &.repo {
        background: ${theme.palette.common.white};
      }
    }
  }
  &.active {
    color: ${theme.palette.common.redDark};
  }
  &.rejected {
    color: ${theme.palette.common.orange};
  }
  &.resolved {
    color: ${theme.palette.primary.dark};
  }
  &.gray {
    color: ${theme.palette.common.grayText};
  }
  &.inactive {
    color: ${theme.palette.common.black};
  }
  &.empty {
    font-style: italic;
  }
  &.fine {
    font-weight: 400;
  }
  &.addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 0.1rem 1.5rem;
    font-weight: 600;
    border-radius: 1rem;
    border: 0.1rem solid ${theme.palette.common.grayText};
    background: ${theme.palette.common.offWhite};
    transition: background 0.2s ease;
    
    :hover {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      cursor: pointer;
    }
    &.active {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      color: ${theme.palette.common.black};
    }
    :focus {
      border: 0.1rem solid ${theme.button.border.primaryFocus};
      background: ${theme.button.background.primaryTransparent};
      cursor: pointer;
      outline: 0;
      box-shadow: 0 0 0.5rem ${theme.button.shadow.primaryFocus};
    }
  }
  &.addButtonDisabled {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 0.1rem 1.5rem;
    font-weight: 600;
    border-radius: 1rem;
    border: 0.1rem solid ${theme.palette.common.grayBorderLight};
    background: ${theme.palette.common.grayTransparent};
    color: ${theme.palette.common.grayText};

    &.dark {
      border: 0.1rem solid ${theme.palette.common.grayText};
    }
  
    :hover {
      cursor: not-allowed;  
      border: 0.1rem solid ${theme.button.border.hoverDisabled};
      background: ${theme.button.background.hoverDisabled};
    }
  }
`)
export const DetailWrapper = styled.div(({theme}) => `
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  padding: 0 1rem;
  color: ${theme.palette.common.black};
  font-weight: 600;
  transition: background 0.1s ease;
  transition: border 0.3s ease;

  &.main {
    border: 0.1rem solid ${theme.palette.primary.main};
  }
  &.provided {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.grayBorderDark};
    background: ${theme.palette.common.grayOpaque};
  }
  &.one {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.common.red};
    background: ${theme.palette.common.redTransparent};
  }
  &.two {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.secondary.dark};
    background: ${theme.palette.secondary.labelTransparent};
  }
  &.three {
    font-weight: 600;
    border: 0.1rem solid ${theme.palette.primary.dark};
    background: ${theme.palette.primary.labelTransparent};
  }
  &.hover {
    :hover {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      cursor: pointer;

      &.repo {
        background: ${theme.palette.common.white};
      }
    }
  }
  &.active {
    color: ${theme.palette.common.redDark};
  }
  &.rejected {
    color: ${theme.palette.common.orange};
  }
  &.resolved {
    color: ${theme.palette.primary.dark};
  }
  &.gray {
    color: ${theme.palette.common.grayText};
  }
  &.inactive {
    color: ${theme.palette.common.black};
  }
  &.empty {
    font-style: italic;
  }
  &.fine {
    font-weight: 400;
  }
  &.addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 0.1rem 1.5rem;
    font-weight: 600;
    border-radius: 1rem;
    border: 0.1rem solid ${theme.palette.common.grayText};
    background: ${theme.palette.common.offWhite};
    transition: background 0.2s ease;
    
    :hover {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      cursor: pointer;
    }
    &.active {
      border: 0.1rem solid ${theme.palette.primary.main};
      background: ${theme.palette.primary.labelTransparent};
      color: ${theme.palette.common.black};
    }
  }
  &.addButtonDisabled {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: 0.1rem 1.5rem;
    font-weight: 600;
    border-radius: 1rem;
    border: 0.1rem solid ${theme.palette.common.grayBorderLight};
    background: ${theme.palette.common.grayTransparent};
    color: ${theme.palette.common.grayText};

    &.dark {
      border: 0.1rem solid ${theme.palette.common.grayText};
    }
  
    :hover {
      cursor: not-allowed;  
      border: 0.1rem solid ${theme.button.border.hoverDisabled};
      background: ${theme.button.background.hoverDisabled};
    }
  }
`)
export const InlineLink = styled.button(({theme}) => `
  padding: 0 0.3rem;
  border: none;
  background: none;
  color: ${theme.palette.primary.dark};

  &.pageNav {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0.3rem;

    &.left {
      border-right: 0.05rem solid ${theme.palette.common.grayBorder};
    }
    &.right {
      border-left: 0.05rem solid ${theme.palette.common.grayBorder};
    }
    
    :hover {
      background-color: #fff;

      &.disabled {
        background-color: none;
      }
    }
  }
  :hover {
    color: ${theme.palette.primary.main};
    cursor: pointer;
  }
  &.disabled {
    color: ${theme.palette.common.grayTextLight};

    :hover {
      cursor: not-allowed;
    }
  }
`)
export const AuthContainer = styled.div(
  ({ theme }) => `
    grid-column: 1;
    grid-row: 2;
    width: 38rem;
    height: fit-content;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.component.background.primary};
    border-radius: 3rem;
    border: 0.3rem solid ${theme.component.border.primary};
`)
export const ImageElements = styled.img(({theme}) => `
  width: 100vw;
  margin: auto;
  height: auto;
  
  &.centerLogo {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 100;
    margin: auto;
    left: 0;
    right: 0;
  }
  &.loading {
    width: 2rem;
    animation: fadeInAnimation ease-in-out 5.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    &.one {
      animation-delay: 0s, 0.3s;
      right: 10rem;
    }
    &.two {
      animation-delay: 0.05s, 0.4s;
      right: 5rem;
    }
    &.three {
      animation-delay: 0.2s, 0.5s;
    }
    &.four {
      animation-delay: 0.25s, 0.6s;
      left: 5rem;
    }
    &.five {
      animation-delay: 0.4s, 0.7s;
      left: 10rem;
    }
    @keyframes fadeInAnimation {
      0% {opacity: 0;}
      50% {opacity: 0.5;}
      100% {opacity: 0;}
    }
  }
  &.loaded {
    width: 2rem;
    animation-name: spin, fadeIn;
    animation: spin 3.5s ease-in, fadeIn 3.0s linear;
    animation-iteration-count: 1, 1;
    animation-fill-mode: forwards, forwards;
    &.one {
      animation-delay: 1s, 0.3s;
      right: 10rem;
    }
    &.two {
      animation-delay: 1.05s, 0.3s;
      right: 10rem;
    }
    &.three {
      animation-delay: 1.2s, 0.4s;
      right: 5rem;
    }
    &.four {
      animation-delay: 1.25s, 0.4s;
      right: 5rem;
    }
    &.five {
      animation-delay: 1.4s, 0.5s;
    }
    &.six {
      animation-delay: 1.45s, 0.5s;
    }
    &.seven {
      animation-delay: 1.6s, 0.6s;
      left: 5rem;
    }
    &.eight {
      animation-delay: 1.65s, 0.6s;
      left: 5rem;
    }
    &.nine {
      animation-delay: 1.8s, 0.7s;
      left: 10rem;
    }
    &.ten {
      animation-delay: 1.85s, 0.7s;
      left: 10rem;
    }
    @keyframes spin {
      from {transform:rotate(0deg);}
      to {transform:rotate(1440deg);}
    }
    @keyframes fadeIn {
      0% {opacity: 0;}
      40% {opacity: 0;}
      100% {opacity: 1;}
    }
  }
  &.sacredGeometry {
    width: 4rem;

    &.one {
      animation-name: emerge;
      animation: emerge 5s ease-in-out;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      @keyframes emerge {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
      }
    }
    &.two {
      animation-name: emerge;
      animation: emerge 4.5s ease-in-out;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      animation-delay: 2.12s;
      @keyframes emerge {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
      }
    }
  }
  &.loginBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
  }
  &.opaque {
    opacity: 0.9;
  }
  &.authNav {
    width: 30rem;
    position: absolute;
    top: 1rem;
  }
`) 
export const ScreenSaverLogo = styled.img(({theme}) => `
  width: 100vw;
  margin: auto;
  height: auto;
  top: 4rem;
  position: absolute;

  &.opaque {
    opacity: 0.05;
  }
  &.dashboard {
    animation: spin 3s linear;
    animation-timing-function: ease-in;
    @keyframes spin {
      from {transform:rotate(0deg);}
      to {transform:rotate(360deg);}
    }
  }
`)
export const ScreenSaverContainer = styled.div(({theme}) => `
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  &.screenSaver {
    background: ${theme.palette.common.black};
    grid-column: 1;
    grid-row: 1;
  }
  
  &.login {
    grid-column: 1/4;
    grid-row: 2;
  }
  &.fadeOut {
    background: ${theme.palette.common.black};
  
    animation: fadeOut 2.5s;
    animation-fill-mode: forwards;
    animation-delay: 2.0s;
    &.quick {
      animation-delay: 0s;
    }
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 0.9;
      }
      100% {
        opacity: 0;
      }
    }
  }
  &.fadeIn {
    background: ${theme.palette.common.black};
    animation-delay: 0s;

    animation: fadeIn 2.5s;
    animation-fill-mode: forwards;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &.dashboard {
    background: ${theme.panel.background.primary};

    animation: fadeOut 2.5s;
    animation-fill-mode: forwards;
    animation-delay: 1.0s;
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`)

export const ScannerOverlay = styled.div(({theme}) => `
  z-index: 100;
  width: 100%;
  height: 100%;
  
  &.cover {
    z-index: 101;
    background: ${theme.palette.common.black};
  }
  &.fadeOverlay {
    background: ${theme.palette.common.black};
    animation: fader 5s ease-in-out infinite;
    animation-delay: 1.5s;
    opacity: 1;

    @keyframes fader {
      0% {
        opacity 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.radial {
    background: radial-gradient(
      rgb(28, 28, 31, 0.2) 0%,
      rgb(28, 28, 31, 0.5) 5%,
      rgb(28, 28, 31, 0.7) 15%,
      rgb(28, 28, 31, 0.9) 40%,
      rgb(28, 28, 31, 0.99) 50%,
      rgb(28, 28, 31, 0.99) 100%
    );
  }
  &.linearScan {
    z-index: 99;
    background: linear-gradient(to right, 
      rgb(28, 28, 31, 0) 0%,
      rgb(28, 28, 31, 0) 48.1%,
      rgb(0, 255, 26, 0.4) 50%,
      rgb(28, 28, 31, 0) 51.9%,
      rgb(28, 28, 31, 0) 100%
    );
    &.thin {
      background: linear-gradient(to right, 
        rgb(28, 28, 31, 0) 0%,
        rgb(28, 28, 31, 0) 49.9%,
        rgb(227, 255, 230, 0.9) 50%,
        rgb(28, 28, 31, 0) 50.1%,
        rgb(28, 28, 31, 0) 100%
      );
    }

    background-size: 100vw;
    animation: bg 1.3s linear infinite;

    @keyframes bg {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: 100vw;
      }
    }
  }
`)

export const GuideText = styled.div(({theme}) => `
  color: ${theme.palette.common.black};
  font-family: Gill Sans, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  
  &.header {
    font-size: 2.0rem;
    font-weight: 600;

    &.one {
      color: ${theme.palette.common.red};
    }
    &.two {
      color: ${theme.palette.secondary.main};
    }
  }
  &.sub {
    font-size: 1.6rem;
  }
  &.medium {
    font-size: 1.8rem;
  }
  &.newParagraph {
    padding-top: 1rem;
  }
  &.halfIndent {
    padding-left: 2rem;    
    padding-right: 2rem;   
    &.left {
      padding-right: 0.5rem;
    } 
  }
  &.oneIndent {
    padding-left: 4rem;    
    padding-right: 4rem;  
    &.left {
      padding-right: 0.5rem;
    }
  }
  &.twoIndent {
    padding-left: 6rem;    
    padding-right: 6rem;  
    &.left {
      padding-right: 0.5rem;
    }
  }
  &.bottom {
    margin-bottom: auto;
  }
  &.bold {
    font-weight: 600;
  }
`)

export const LoadingWrapper = styled.div(({ theme }) => `
  display: flex;
  align-items: center;

  animation-name: backgroundColorPalette;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear; 
  @keyframes backgroundColorPalette {
    0% {
      color: #ee6055;
    }
    25% {
      color: #ee6055;
    }
    50% {
      color: #aaf683;
    }
    75% {
      color: #ee6055;
    }
    100% {
      color: #aaf683;
    }
  }
`)

export const ListItemNav = styled.a(({ theme }) => `
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  height: 3.5rem;
  border-bottom: 0.1rem solid ${theme.nav.border.inactive};
  padding: 0.5rem 0 0.5rem 0.4rem;
  margin: 0;
  background-color: none;
  cursor: pointer;
  color: ${theme.text.nav.main};
  
  :hover {
    background-color: ${theme.nav.background.hover};
  }
  &.requiresAttention {
    background: ${theme.nav.background.requiresAttention};

    &.selected {
    
    }
  }
  &.mainInactive {
    padding: 0.5rem 0 0.5rem 1.2rem;
    border-right: 0.1rem solid ${theme.nav.border.inactive};
    border-top: 0.1rem solid ${theme.nav.border.active};
    border-bottom: 0.1rem solid ${theme.nav.border.active};

    :hover {
      background-color: ${theme.nav.background.inactiveHover};
    }
  }
  &.mainActive {
    padding: 0.5rem 0 0.5rem 1.2rem;
    background-color: ${theme.nav.background.active};
    border-right: 0.1rem solid ${theme.nav.border.activeSide};
    border-top: 0.1rem solid ${theme.nav.border.inactive};
    border-bottom: 0.1rem solid ${theme.nav.border.inactive};
    cursor: default;

    :hover {
      background-color: ${theme.nav.background.activeHover};
    }
  }
  &.pageActive {
    border-bottom: 0.1rem solid ${theme.nav.border.activeSecondary};
    background-color: ${theme.nav.background.hover};
    cursor: default;

    :hover {
      background-color: ${theme.nav.background.activeHoverSecondary};
    }
  }
`)
export const SubListItemNav = styled.a(({ theme }) => `
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  height: 3.5rem;
  padding: 0.5rem 0 0.5rem 2.5rem;
  margin: 0;
  background-color: none;
  cursor: pointer;
  color: ${theme.subNav.text.inactive};
  
  :hover {
    color: ${theme.subNav.text.active};
  }
  &.mainInactive {
    padding: 0.5rem 0 0.5rem 3rem;
    border-right: 0.1rem solid ${theme.nav.border.inactive};
  }
  &.mainActive {
    cursor: default;
    padding: 0.5rem 0 0.5rem 3rem;
    color: ${theme.subNav.text.active};
    background-color: ${theme.nav.background.activeHover};
    
    :hover {
      background-color: ${theme.subNav.background.activeHover};
    }
  }
  &.pageActive {
    cursor: default;
    color: ${theme.subNav.text.active};
    background-color: ${theme.subNav.background.active};
    
    :hover {
      background-color: ${theme.subNav.background.activeHoverSecondary};
    }
  }
`)

export const StatsRow = styled.div(({ theme }) => `
  display: grid;
  
  &.member {
    border-bottom: 0.1rem solid ${theme.panel.border.secondary};
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`)

export const LoadingBar = styled.div(({ theme }) => `
  -webkit-transition: width 0.8s ease-out;
  -moz-transition: width 0.8s ease-out;
  -o-transition: width 0.8s ease-out;
  transition: width 0.8s ease-out;

  width: 100%;
  height: 1.5rem;
  border: 0.05rem solid ${theme.component.border.primary};
  background: linear-gradient(to right,
    ${theme.palette.primary.darkest},
    ${theme.palette.primary.darker},
    ${theme.palette.primary.lightest}
  );
  &.light {
    background: linear-gradient(to right,
      ${theme.palette.primary.darker},
      ${theme.palette.primary.dark},
      ${theme.palette.primary.lightest}
    );
  }
  &.blue {
    background: linear-gradient(to right,
      ${theme.palette.tertiary.pastelDark},
      ${theme.palette.tertiary.pastelMedium},
      ${theme.palette.tertiary.pastelLight}
    );
  };
`)
export const LoadingBarWrapper = styled.div(({ theme }) => `
  width: 100%;
  height: 1.5rem;
  background: linear-gradient(to right, 
    ${theme.palette.common.grayText} 0%,
    ${theme.palette.common.grayTransparentMedium} 0.6%,
    ${theme.palette.common.grayTransparentMedium} 24.8%,
    ${theme.palette.common.blackLight} 25%,
    ${theme.palette.common.grayTransparentMedium} 25.2%,
    ${theme.palette.common.grayTransparentMedium} 49.8%,
    ${theme.palette.common.blackLight} 50%,
    ${theme.palette.common.grayTransparentMedium} 50.2%,
    ${theme.palette.common.grayTransparentMedium} 74.8%,
    ${theme.palette.common.blackLight} 75%,
    ${theme.palette.common.grayTransparentMedium} 75.2%,
    ${theme.palette.common.grayTransparentMedium} 99.4%,
    ${theme.palette.common.grayText} 100%
  );
  &.small {
    background: linear-gradient(to right, 
      ${theme.palette.common.blackLight} 0%,
      ${theme.palette.common.grayTransparentMedium} 0.6%,
      ${theme.palette.common.grayTransparentMedium} 24.6%,
      ${theme.palette.common.grayText} 25%,
      ${theme.palette.common.grayTransparentMedium} 25.4%,
      ${theme.palette.common.grayTransparentMedium} 49.6%,
      ${theme.palette.common.grayText} 50%,
      ${theme.palette.common.grayTransparentMedium} 50.4%,
      ${theme.palette.common.grayTransparentMedium} 74.6%,
      ${theme.palette.common.grayText} 75%,
      ${theme.palette.common.grayTransparentMedium} 75.4%,
      ${theme.palette.common.grayTransparentMedium} 99.4%,
      ${theme.palette.common.blackLight} 100%
    );
  }
`)