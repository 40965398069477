import React from "react";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectUserEmail, selectUserRole } from "../../../../../slices/userInfoSlice";
import { FlexWrapper, ComponentSectionRow, ColumnContent, ComponentHeader, Typography } from "../../../../../StyledComponents";
import DefaultLocations from "./DefaultLocations";

const UserSettings = ({ userFullName, userId }) => {
  const theme = useTheme()

  const userRole = useSelector(selectUserRole)
  const userEmail = useSelector(selectUserEmail)

  return (
      <FlexWrapper className="column fitContentHeight" style={{paddingBottom: '4rem'}}>
        <ComponentHeader>
          My Account
        </ComponentHeader>
        <ComponentSectionRow className="listMembers header subHeader">
          <ColumnContent className="header" style={{gridColumn: '2'}}>
            <Typography style={{margin: '0 auto'}}>
              Name
            </Typography>
          </ColumnContent>  
          <ColumnContent className="header" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <Typography style={{margin: '0 auto'}}>
              Role
            </Typography>
          </ColumnContent>  
          <ColumnContent className="header" style={{gridColumn: '4', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <Typography style={{margin: '0 auto'}}>
              eMail
            </Typography>
          </ColumnContent>  
        </ComponentSectionRow>
        <ComponentSectionRow className='listMembers' style={{padding: '0.5rem 0'}}>
          <ColumnContent className="detail small" style={{gridColumn: '2'}}>
            {userFullName}
          </ColumnContent>
          <ColumnContent className="detail small" style={{gridColumn: '3'}}>
            {userRole === 'super' ?
              'App Admin'
            : userRole === 'admin' ?
              'Manager'
            :
              'User'
            }
          </ColumnContent>
          <ColumnContent className="detail small lowercase" style={{gridColumn: '4'}}>
            {userEmail}
          </ColumnContent>
        </ComponentSectionRow>
        <DefaultLocations userId={userId} userFullName={userFullName} />
      </FlexWrapper>
  )
}

export default UserSettings